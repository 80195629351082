import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { videoQueryKeys } from '@videos/api/videoQueryKeys';
import { IVideo } from '@videos/types/IVideo';

type IVideoRequest = { id: number };
type IVideoResponse = AxiosResponse<IVideo>;

export const videoRequest = async (params: IVideoRequest): Promise<IVideoResponse> =>
  await axios.get(`videos/${params.id}`);

export function useQueryVideo(params: IVideoRequest) {
  return useQuery<IVideoResponse, AxiosError>(
    [videoQueryKeys.video, params],
    () => videoRequest(params),
    { enabled: params.id > 0 },
  );
}
