import type { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { Modal } from 'antd';

import { useQueryBoard } from '@collections/boards/api/useQueryBoard';
import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { Description } from '@components/atoms/Description';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { TitleAndText } from '@components/atoms/TitleAndText';

type ViewBoardModalProps = {
  id?: number;
  isOpen: boolean;
  onClose: () => void;
};

export const ViewBoardModal: FC<ViewBoardModalProps> = ({ id = 0, isOpen, onClose }) => {
  const boardQuery = useQueryBoard({ id });
  const board = boardQuery.data?.data;

  return (
    <Modal footer={null} onCancel={onClose} open={isOpen} width={720}>
      {boardQuery.isFetching && <BaseSkeleton />}

      {boardQuery.isFetching ? (
        <BaseSkeleton />
      ) : !board ? (
        <NotFoundResult title="Доска не найдена" />
      ) : (
        <>
          <div className="flex flex-col sm:flex-row gap-4">
            <h2 className="text-2xl">Доска #{board.id}</h2>
          </div>
          <div className="pl-3 pb-4">
            <Description
              description={
                <CountryImageText src="ru" text={board.localizations?.ru?.name || '-'} />
              }
              title="Название на русском"
            />
            <Description
              description={
                <CountryImageText src="en" text={board.localizations?.en?.name || '-'} />
              }
              title="Название на английском"
            />
          </div>
          <TitleAndText
            text={<ReactSVG className="max-w-[300px] max-h-[300px]" src={board.imgUrl} />}
            title="Доска"
          />
          <TitleAndText
            text={<ReactSVG className="max-w-[300px] max-h-[300px]" src={board.lightPieceImgUrl} />}
            title="Белые поля"
          />
          <TitleAndText
            text={<ReactSVG className="max-w-[300px] max-h-[300px]" src={board.darkPieceImgUrl} />}
            title="Черные поля"
          />
        </>
      )}
    </Modal>
  );
};
