import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Table, Typography } from 'antd';
import moment from 'moment';

import { TitleAndText } from '@components/atoms/TitleAndText';
import { useQueryBrainstorm } from '@users/api/userQueryUserBrainstorm';
import { brainstormExerciseColumns } from '@users/consts/columns/userBrainstormExerciseColumn';
import { BrainstormLevelEnum } from '@users/types/enums/BrainstormLevelEnum';

type Props = { id: number; isOpen: boolean; onCancel: () => void };

export const UserBrainstormModal: FC<Props> = ({ id, isOpen, onCancel }) => {
  const brainStormQuery = useQueryBrainstorm({ id });
  const brainstorm = brainStormQuery.data?.data;

  const formatSeconds = (totalSeconds) => {
    const duration = moment.duration(totalSeconds, 'seconds');
    return moment.utc(duration.asMilliseconds()).format('mm:ss');
  };
  const navigate = useNavigate();

  return (
    <Modal
      bodyStyle={{ paddingTop: 24 }}
      className="px-4 top-5"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={`Мозговой штурм #${brainstorm?.id ?? ''}`}
      width={850}
    >
      <Typography className="font-bold">Общие данные</Typography>
      <TitleAndText
        text={brainstorm?.mode ? BrainstormLevelEnum[brainstorm?.mode] ?? '-' : '-'}
        title="Режим игры"
      />
      <TitleAndText text={brainstorm?.type == 'MIN3' ? '3 минуты' : '5 минут'} title="Время" />
      <TitleAndText
        text={
          brainstorm?.passedTimeInSeconds ? formatSeconds(brainstorm?.passedTimeInSeconds) : '-'
        }
        title="Фактическое время"
      />
      <TitleAndText text={brainstorm?.problemsCount ?? '-'} title="Количество упражнений" />
      <TitleAndText text={brainstorm?.solvedCount ?? '-'} title="Правильно решенные" />
      <TitleAndText text={brainstorm?.useType ?? '-'} title="Статус использования игры" />
      <TitleAndText text={brainstorm?.createdDate ?? '-'} title="Дата игры " />
      <TitleAndText text={brainstorm?.streak ?? '-'} title="Ударный режим" />

      <TitleAndText
        text={
          brainstorm?.avgPassedTimeInSeconds
            ? formatSeconds(brainstorm?.avgPassedTimeInSeconds)
            : '-'
        }
        title="Среднее время потраченное на игру"
      />
      <Typography className="font-bold my-8">Задачи</Typography>
      <Table
        columns={brainstormExerciseColumns({
          onView: (id) => navigate(`/exercises?action=view&actionId=${id}`),
        })}
        dataSource={brainstorm?.problems ?? []}
        loading={brainStormQuery.isLoading}
        pagination={false}
        rowKey="key"
        scroll={{ x: '100%' }}
      />
    </Modal>
  );
};
