import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, number, object, ObjectSchema, string } from 'yup';

import { fenQueryKeys } from '@exercises/fens/api/fenQueryKeys';
import { FenStatusEnumKeys } from '@exercises/fens/types/enums/FenStatusEnum';
import { IFen } from '@exercises/fens/types/IFen';
import validateFEN from '@utils/fenValidator';

export type IFenCreateRequest = Pick<IFen, 'localizations' | 'name' | 'status' | 'value'> & {
  videoIds: number[];
};

export const fenCreateInitial: IFenCreateRequest = {
  localizations: { en: { messageFromKaissa: '' }, ru: { messageFromKaissa: '' } },
  name: '',
  status: 'ACTIVE',
  value: '',
  videoIds: [],
};

type IFenCreateResponse = AxiosResponse<void>;

export const fenCreateValidation: ObjectSchema<IFenCreateRequest> = object().shape({
  localizations: object({
    en: object({ messageFromKaissa: string() }),
    ru: object({ messageFromKaissa: string() }),
  }),
  name: string().required(),
  status: string<FenStatusEnumKeys>().required(),
  value: string()
    .required()
    .test('test-value ', 'Некорректный формат FEN-а', (value) => validateFEN(value)),
  videoIds: array().of(number().required()).required(),
});

export const fenCreateRequest = async (payload: IFenCreateRequest): Promise<IFenCreateResponse> =>
  await axios.post('/fen-records', payload);

export function useMutateFenCreate() {
  const queryClient = useQueryClient();

  return useMutation<IFenCreateResponse, AxiosError, IFenCreateRequest>(fenCreateRequest, {
    onSuccess: () => queryClient.invalidateQueries(fenQueryKeys.fens),
  });
}
