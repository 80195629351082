import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { avatarCommonKeys } from '@collections/avatars/api/avatarCommonKeys';
import { IAvatar } from '@collections/avatars/types/IAvatar';

import { avatarGroupQueryKeys } from './avatarGroupQueryKeys';

export type IAvatarGroupStatusUpdateRequest = Pick<IAvatar, 'id' | 'status'>;

type IAvatarGroupStatusUpdateResponse = AxiosResponse<void>;

export const avatarGroupStatusUpdateRequest = async ({
  id,
  ...payload
}: IAvatarGroupStatusUpdateRequest): Promise<IAvatarGroupStatusUpdateResponse> =>
  await axios.patch(`/avatar-groups/${id}`, payload);

export function useMutateAvatarGroupStatusUpdate() {
  const queryClient = useQueryClient();

  return useMutation<IAvatarGroupStatusUpdateResponse, AxiosError, IAvatarGroupStatusUpdateRequest>(
    avatarGroupStatusUpdateRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(avatarGroupQueryKeys.avatarGroup);
        queryClient.invalidateQueries(avatarGroupQueryKeys.avatarsGroups);
        queryClient.invalidateQueries(avatarCommonKeys.avatars);
        queryClient.invalidateQueries(avatarCommonKeys.avatar);
      },
    },
  );
}
