import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IBoard } from '@collections/boards/types/IBoard';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

import { boardCommonKeys } from './boardCommonKeys';

export type IBoardsRequest = IPageAbleRequest & {
  groupId?: number;
};

type IBoardsResponse = IPageAbleResponse<IBoard>;

export const boardsGetRequest = async (params?: IBoardsRequest) =>
  axios.get<IBoardsResponse>(`/chess-boards`, { params });

export function useQueryBoards(params?: IBoardsRequest) {
  return useQuery<AxiosResponse<IBoardsResponse>, AxiosError>(
    [boardCommonKeys.boards, params],
    () => boardsGetRequest(params),
  );
}
