import { FC } from 'react';
import { Chessboard } from 'react-chessboard';
import { Button, Switch as AntSwitch } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, InputNumber, Select } from 'formik-antd';

import { CountryImageText } from '@components/atoms/CountryImageText';
import {
  assessmentExerciseProblemEditValidation,
  IAssessmentExerciseProblemEditRequest,
  useMutateAssessmentExerciseProblemEdit,
} from '@exercises/levelTesting/api/useMutateAssessmentExerciseProblemEdit';
import { IAssessmentExerciseProblem } from '@exercises/levelTesting/types/IAssessmentExerciesProblem';
import { IAssessmentExercise } from '@exercises/levelTesting/types/IAssessmentExercise';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';
import { notify } from '@utils/notify';

export const AssessmentProblemEditFormik: FC<{
  exercise: IAssessmentExercise & { problem: IAssessmentExerciseProblem };
  onCancel: () => void;
}> = ({ exercise, onCancel }) => {
  const problemEdit = useMutateAssessmentExerciseProblemEdit();

  assessmentExerciseProblemEditValidation;
  return (
    <Formik<IAssessmentExerciseProblemEditRequest>
      enableReinitialize
      initialValues={{
        ...exercise.problem,
        status: exercise.problem.status || 'ACTIVE',
      }}
      onSubmit={(values, { resetForm }) => {
        problemEdit.mutateAsync(values).then((data) => {
          if (data.status == 201 || data.status == 200) {
            notify('success', 'Задача изменена');
            onCancel();
            resetForm();
          }
        });
      }}
      validationSchema={assessmentExerciseProblemEditValidation}
    >
      {({ setFieldValue, values }) => (
        <Form className="space-y-5" layout="vertical">
          <div className="grid place-items-center overflow-x-auto">
            <div className="shadow-md rounded-sm">
              <Chessboard
                arePiecesDraggable={false}
                boardWidth={360}
                position={exercise.problem.boardState || ''}
              />
            </div>

            <p className="mt-2 mb-0 text-center" style={{ maxWidth: 360 }}>
              {exercise.problem.moveSequence}
            </p>
          </div>

          <FormItem
            label={<CountryImageText src="ru" text="Текст в приложении на русском" width={15} />}
            name="localizations.ru.name"
          >
            <Input
              name="localizations.ru.name"
              placeholder="Текст в приложении на русском"
              suffix
            />
          </FormItem>
          <FormItem
            label={<CountryImageText src="en" text="Текст в приложении на английском" width={15} />}
            name="localizations.en.name"
          >
            <Input
              name="localizations.en.name"
              placeholder="Текст в приложении на английском"
              showCount
              suffix
            />
          </FormItem>

          <FormItem label="Уровень" name="playLevel">
            <Select
              name="playLevel"
              options={Object.keys(userPlayLevels)
                .map(Number)
                .map((playLevel) => ({
                  label: userPlayLevels[playLevel],
                  value: playLevel,
                }))}
              showSearch={false}
            />
          </FormItem>

          <div className="grid grid-cols-2 place-items-start text-start">
            <FormItem label="Порядковый номер" name="priority">
              <InputNumber className="w-full" controls={false} name="priority" />
            </FormItem>
            <Form.Item
              label={<span className="text-center">Запустить немедленно</span>}
              name="status"
            >
              <Input hidden name="status" suffix />
              <AntSwitch
                checked={values.status == 'ACTIVE'}
                onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
              />
            </Form.Item>
          </div>

          <div className="grid md:grid-cols-2 gap-5">
            <Button block onClick={onCancel}>
              Отменить
            </Button>

            <Button block htmlType="submit" loading={problemEdit.isLoading} type="primary">
              <span className="max-w-full overflow-ellipsis overflow-hidden">
                {values['status'] == 'ACTIVE' ? 'Изменить и запустить' : 'Сохранить как черновик'}
              </span>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
