import type { FC } from 'react';
import { Button, Modal, Radio, Switch as AntSwitch, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, InputNumber } from 'formik-antd';

import {
  createAvatarInitialValue,
  createAvatarValidation,
  IAvatarCreateRequest,
  useMutateAvatarCreate,
} from '@collections/avatars/api/useMutateAvatarCreate';
import { AvatarTypeEnum } from '@collections/avatars/types/enums/avatarTypeEnum';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { FileUpload } from '@components/molecules/files/FileUpload';

type CreateAvatarModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateAvatarModal: FC<CreateAvatarModalProps> = ({ isOpen, onClose }) => {
  const createAvatarMutation = useMutateAvatarCreate();

  return (
    <Modal footer={null} onCancel={onClose} open={isOpen} title="Создать аватар">
      <Formik<IAvatarCreateRequest>
        enableReinitialize
        initialValues={createAvatarInitialValue}
        onSubmit={(values) =>
          createAvatarMutation
            .mutateAsync({ ...values, price: values.type != 'PAID' ? 0 : values.price })
            .then(() => onClose())
        }
        validateOnBlur={false}
        validationSchema={createAvatarValidation}
      >
        {({ setFieldValue, values: { status, type } }) => (
          <Form className="gap-4 md:gap-6" layout="vertical">
            <FormItem
              label={<CountryImageText src="ru" text="Название на русском" />}
              name="localizations.ru.name"
            >
              <Input name="localizations.ru.name" prefix />
            </FormItem>
            <FormItem
              label={<CountryImageText src="en" text="Название на английском" />}
              name="localizations.en.name"
            >
              <Input name="localizations.en.name" prefix />
            </FormItem>
            <div>
              <FormItem label="Тип" name="type">
                <Radio.Group
                  className="w-full"
                  onChange={(e) => {
                    setFieldValue('type', e.target.value);
                  }}
                  options={Object.entries(AvatarTypeEnum).map(([key, value]) => ({
                    label: value,
                    value: key,
                  }))}
                />
              </FormItem>
            </div>

            {type === 'PAID' && (
              <FormItem label="Стоимость" name="price">
                <InputNumber
                  className="w-full"
                  controls={false}
                  max={999999}
                  min={1}
                  name="price"
                  prefix
                />
              </FormItem>
            )}
            <Typography className="mb-4">Картинка</Typography>
            <div className="grid grid-cols-2 gap-6">
              <FormItem className="upload-form-item" label="SVG" name="imgUrl">
                <FileUpload
                  accept=".jpeg,.png,.jpg,.svg"
                  folderName="imgUrl"
                  isSvg={true}
                  maxCount={1}
                  onChange={(info, linkInfo) => {
                    if (!info.file.status || info.file.status == 'removed')
                      setFieldValue('imgUrl', '');
                    if (info.file.status == 'done') setFieldValue('imgUrl', linkInfo.downloadUrl);
                  }}
                />
              </FormItem>
              <FormItem className="upload-form-item" label="PNG" name="imgUrlPng">
                <FileUpload
                  accept=".jpeg,.png,.jpg,.svg"
                  folderName="imgUrlPng"
                  maxCount={1}
                  onChange={(info, linkInfo) => {
                    if (!info.file.status || info.file.status == 'removed')
                      setFieldValue('imgUrlPng', '');
                    if (info.file.status == 'done')
                      setFieldValue('imgUrlPng', linkInfo.downloadUrl);
                  }}
                />
              </FormItem>
            </div>
            <div className="flex justify-center text-center">
              <FormItem label={<span className="text-center">Запустить</span>} name="status">
                <Input hidden name="status" suffix />

                <AntSwitch
                  checked={status == 'ACTIVE'}
                  onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
                />
              </FormItem>
            </div>

            <div className="flex gap-4 justify-end">
              <Button onClick={onClose}>Отмена</Button>
              <Button htmlType="submit" loading={createAvatarMutation.isLoading} type="primary">
                <span className="max-w-full overflow-ellipsis overflow-hidden">
                  {status == 'ACTIVE' ? 'Создать и запустить' : 'Сохранить как черновик'}
                </span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
