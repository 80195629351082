import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { versionsQueryKeys } from '@settings/versions/api/versionsQueryKeys';
import { VersionsPlatformEnumKeys } from '@settings/versions/types/enums/VersionsPlatformEnum';
import { IVersion } from '@settings/versions/types/IVersion';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

export type VersionsRequest = IPageAbleRequest & {
  platform?: VersionsPlatformEnumKeys;
};

type VersionsResponse = AxiosResponse<IPageAbleResponse<IVersion>>;

export const versionsRequestRequest = async (params?: VersionsRequest): Promise<VersionsResponse> =>
  await axios.get('/v2/mobile-versions', { params });

export function useQueryVersions(params?: VersionsRequest) {
  return useQuery<VersionsResponse, AxiosError>([versionsQueryKeys.versions, params], () =>
    versionsRequestRequest(params),
  );
}
