import { useMutation } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

type IUserExportRequest = {
  //
};
type IUserExportResponse = AxiosResponse<Blob>;

export const userExportRequest = async (): Promise<IUserExportResponse> => {
  return await axios.get('/stats-xlsx/customers', { responseType: 'blob' });
};

export function useMutateUserExport() {
  return useMutation<IUserExportResponse, AxiosError, IUserExportRequest>(userExportRequest, {
    onSuccess: (data) => {
      saveAs(
        data.data,
        data.headers['content-disposition']?.split('"')[1] ?? `Chess-legends-users.xlsx`,
      );
    },
  });
}
