import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  CaretRightOutlined,
  CopyOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import { PencilIcon } from '@components/atoms/icons';
import { IFen } from '@exercises/fens/types/IFen';
import {
  MapStageStatusEnum,
  MapStageStatusEnumKeys,
} from '@exercises/map-stages/types/enums/MapStageStatus';
import { MapStageTypeEnum } from '@exercises/map-stages/types/enums/MapStageType';
import { IMapStage, mapStageKeys } from '@exercises/map-stages/types/IMapStage';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';

export const mapStagesColumns = ({
  canEdit,
  onClickDelete,
  onClickDuplicate,
  onClickEdit,
  onClickExerciseView,
  onClickFenView,
  onClickPause,
  onClickVideoView,
  onClickView,
}: {
  canEdit: boolean;
  onClickDelete;
  onClickDuplicate?: (stage: IMapStage) => void;
  onClickEdit;
  onClickExerciseView?: ({ exerciseId }: { exerciseId: number }) => void;
  onClickFenView: (fen: IFen) => void;
  onClickPause?: ({ id, status }: { id: number; status: MapStageStatusEnumKeys }) => void;
  onClickVideoView: ({ id }: { id: number }) => void;
  onClickView?: ({ id }: { id: number }) => void;
}): ColumnsType<IMapStage> => {
  const columns: ColumnsType<IMapStage> = [
    {
      dataIndex: mapStageKeys.id,
      render: (id: IMapStage['id']) =>
        onClickView ? (
          <Button onClick={() => onClickView({ id })} type="link">
            {id}
          </Button>
        ) : (
          id
        ),
      title: 'ID',
    },
    {
      dataIndex: mapStageKeys.stageNumber,
      title: 'Шаг на карте',
    },
    {
      dataIndex: mapStageKeys.playLevel,
      render: (playLevel: IMapStage['playLevel']) => userPlayLevels[playLevel],
      title: 'Уровень',
    },

    {
      dataIndex: mapStageKeys.type,
      render: (type: IMapStage['type']) => MapStageTypeEnum[type],
      title: 'Тип',
    },
    {
      dataIndex: mapStageKeys.exercises,
      render: (exercises: IMapStage['exercises']) =>
        exercises
          ? exercises.map((ex) => (
              <div key={ex.id}>
                {onClickExerciseView ? (
                  <Button
                    key={ex.id}
                    onClick={() => onClickExerciseView({ exerciseId: ex.id })}
                    type="link"
                  >
                    {ex.id}
                  </Button>
                ) : (
                  ex.id
                )}
              </div>
            ))
          : '-',
      title: 'Задачи',
    },
    {
      dataIndex: mapStageKeys.fenRecord,
      render: (fen: IMapStage['fenRecord']) =>
        fen ? (
          <Button key={fen.id} onClick={() => onClickFenView(fen)} type="link">
            {fen.id}
          </Button>
        ) : (
          '-'
        ),
      title: 'FEN',
    },
    {
      dataIndex: mapStageKeys.video,
      render: (video: IMapStage['video']) =>
        video ? (
          <Button key={video.id} onClick={() => onClickVideoView({ id: video.id })} type="link">
            {video.id}
          </Button>
        ) : (
          '-'
        ),
      title: 'Видео',
    },
    {
      dataIndex: mapStageKeys.status,
      render: (status: IMapStage['status']) => (status ? MapStageStatusEnum[status] : '-'),
      title: 'Статус',
    },
  ];

  if (!canEdit) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: (stage: IMapStage) => (
        <div className="flex gap-x-1.5">
          {onClickDuplicate && (
            <Popconfirm
              cancelText="Отменить"
              icon={<CopyOutlined />}
              okText="Продублировать"
              okType="primary"
              onConfirm={() => onClickDuplicate(stage)}
              placement="topLeft"
              title={
                <p className="text-sm mb-0">
                  Вы уверены, что хотите
                  <span className="font-semibold">продублировать предыдущий шаг?</span>
                </p>
              }
            >
              <Button ghost icon={<CopyOutlined />} type="primary" />
            </Popconfirm>
          )}
          {onClickPause && (
            <Popconfirm
              cancelText="Отменить"
              icon={
                stage.status == 'ACTIVE' ? (
                  <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                ) : (
                  <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                )
              }
              okText={stage.status == 'ACTIVE' ? 'Приостановить' : 'Запустить'}
              okType="primary"
              onConfirm={() =>
                onClickPause({
                  id: stage.id,
                  status: stage.status == 'ACTIVE' ? 'DRAFT' : 'ACTIVE',
                })
              }
              placement="topLeft"
              title={
                <p className="text-sm mb-0">
                  Вы уверены, что хотите
                  <span className="font-semibold">
                    {stage.status == 'ACTIVE' ? ' приостановить ' : ' запустить '}
                    {stage.id}?
                  </span>
                </p>
              }
            >
              <Button
                ghost
                icon={stage.status == 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
                type="primary"
              />
            </Popconfirm>
          )}
          {onClickDelete && (
            <Popconfirm
              cancelText="Отменить"
              icon={<DeleteOutlined className="text-ant-red-5" />}
              okText="Удалить"
              okType="danger"
              onConfirm={() => onClickDelete({ id: stage.id })}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что хотите
                  <span className="font-bold"> удалить {stage.id}</span>?
                </span>
              }
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
          {onClickEdit && (
            <Button
              ghost
              icon={<PencilIcon />}
              onClick={() => onClickEdit({ id: stage.id })}
              type="primary"
            />
          )}
        </div>
      ),
      title: 'Действия',
    },
  ];
};
