import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';
import { userQueryKeys } from '@users/api/userQueryKeys';
import { UserGenderEnumKeys } from '@users/types/enums/UserGenderEnum';
import { UserLanguageEnumKeys } from '@users/types/enums/UserLanguageEnum';

import { IUser } from '../types/IUser';

export type IUsersRequest = IPageAbleRequest & {
  ageFrom?: number;
  ageTo?: number;
  birthDate?: string;
  coinsFrom?: number;
  coinsTo?: number;
  countryIds?: number[];
  email?: string;
  firstName?: string;
  gender?: UserGenderEnumKeys;
  language?: UserLanguageEnumKeys;
  nickname?: string;
  ratingFrom?: number;
  ratingTo?: number;
  systemRatingFrom?: number;
  systemRatingTo?: number;
};

type IUsersResponse = AxiosResponse<IPageAbleResponse<IUser>>;

export const usersRequest = async (params?: IUsersRequest): Promise<IUsersResponse> => {
  return await axios.get('/customers', { params });
};

export function useQueryUsers(params?: IUsersRequest) {
  return useQuery<IUsersResponse, AxiosError>([userQueryKeys.users, params], () =>
    usersRequest(params),
  );
}
