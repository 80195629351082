import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';

import { PageMeta } from '@context/PageMetaContext';

import {
  ILoginRequest,
  loginInitial,
  loginValidation,
  useMutateLogin,
} from '../../api/useMutateLogin';

const LoginPage: FC = () => {
  const login = useMutateLogin();

  return (
    <>
      <PageMeta title="Авторизация" />

      <Formik<ILoginRequest>
        initialValues={loginInitial}
        onSubmit={(values, { setFieldError }) => {
          login.mutateAsync(values).catch((e: AxiosError) => {
            if (e.response?.status == 401) setFieldError('username', 'Неверный логин или пароль');
          });
        }}
        validationSchema={loginValidation}
      >
        {({ values }) => (
          <Form className="mt-2 sm:mt-8 space-y-8" layout="vertical">
            <h2 className="text-center text-3xl sm:text-4xl text-gray-900 truncate">Авторизация</h2>

            <FormItem label="Логин" name="username">
              <Input name="username" placeholder="example@example.com" suffix />
            </FormItem>

            <FormItem label="Пароль" name="password">
              <Input.Password
                maxLength={30}
                name="password"
                placeholder="example"
                showCount={values.password.length > 19}
              />
            </FormItem>

            <div>
              <Button block htmlType="submit" loading={login.isLoading} size="large" type="primary">
                Войти
              </Button>

              <Link to="/reset/email">
                <Button block className="mt-3" size="large">
                  Забыл пароль
                </Button>
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginPage;
