import { FC } from 'react';
import { Button, Tag, Tooltip } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, InputNumber, Select, Switch } from 'formik-antd';
import { FileOutlined, LoadingOutlined } from '@ant-design/icons';

import { ExerciseGamePhaseEnum } from '@exercises/exercises/types/enums/ExerciseGamePhaseEnum';
import { useQueryTags } from '@tags/api/useQueryTags';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';
import { notify } from '@utils/notify';
import {
  IVideoStepTwoRequest,
  useMutateVideoStepTwo,
  videoStepTwoValidation,
} from '@videos/api/useMutateVideoStepTwo';
import { IVideo } from '@videos/types/IVideo';
import { AllowedSteps } from '@videos/utils/checkAllowedSteps';

type StepTwoFormType = {
  changeStep: (step: 0 | 1 | 2) => void;
  onCancel: () => void;
  steps: AllowedSteps;
  video: IVideo;
};

export const StepTwo: FC<StepTwoFormType> = ({ changeStep, onCancel, steps, video }) => {
  const videoTwoUpdate = useMutateVideoStepTwo();
  const tagsTopic = useQueryTags({ type: 'TOPIC' });

  const submitDraft = (values: IVideoStepTwoRequest) => {
    videoTwoUpdate.mutateAsync({ ...values, status: 'DRAFT' }).then((data) => {
      if (data.status == 201 || data.status == 200) {
        notify('success', 'Видео сохранено как черновик');
        onCancel();
      }
    });
  };

  return (
    <Formik<IVideoStepTwoRequest>
      enableReinitialize
      initialValues={{
        gamePhase: video.gamePhase,
        id: video.id,
        isCapitalization: !!video.isCapitalization,
        isTactic: !!video.isTactic,
        playLevel: video.playLevel,
        price: video.price,
        sequence: video.sequence,
        status: video.status,
        tagIds: video.tags?.map((tag) => tag.id) || [],
      }}
      onReset={() => {
        changeStep(0);
      }}
      onSubmit={(values) => {
        videoTwoUpdate
          .mutateAsync(values)
          .then((data) => {
            if (data.status == 201 || data.status == 200) {
              notify('success', 'Шаг 2 сохранен');
            }
          })
          .finally(() => {
            changeStep(2);
          });
      }}
      validationSchema={videoStepTwoValidation}
    >
      {({ dirty, values }) => (
        <Form className="space-y-4 md:space-y-8" layout="vertical">
          <h1 className="text-2xl">Дополнительные параметры видео</h1>
          <div className="sm:grid sm:grid-cols-2 gap-4">
            <FormItem label="Уровень" name="playLevel">
              <Select
                name="playLevel"
                optionFilterProp="label"
                options={Object.keys(userPlayLevels)
                  .map(Number)
                  .map((key) => ({
                    label: userPlayLevels[key],
                    value: key,
                  }))}
                placeholder="Уровень"
              />
            </FormItem>

            {[1, 2].includes(values.playLevel || 0) && (
              <FormItem label="Последовательность" name="sequence">
                <InputNumber
                  className="w-full"
                  name="sequence"
                  placeholder="Последовательность видео"
                  prefix
                />
              </FormItem>
            )}

            <FormItem label="Стадия игры" name="gamePhase">
              <Select
                allowClear
                name="gamePhase"
                options={Object.entries(ExerciseGamePhaseEnum).map(([value, label]) => ({
                  label,
                  value,
                }))}
                placeholder="Стадия игры"
              />
            </FormItem>
            <FormItem label="Стоимость видео" name="price">
              <InputNumber
                className="w-full"
                controls={false}
                maxLength={6}
                min={1}
                name="price"
                placeholder="100"
                prefix
              />
            </FormItem>
            <FormItem
              className={[1, 2].includes(values.playLevel || 0) ? 'col-span-full' : ''}
              label="Тема видео"
              name="tagIds"
            >
              <Select
                loading={tagsTopic.isFetching}
                mode="multiple"
                name="tagIds"
                optionFilterProp="label"
                options={
                  tagsTopic.data?.data.content.map((tag) => ({
                    label: tag.localizations.en,
                    value: tag.id,
                  })) || []
                }
                placeholder="Тема"
                tagRender={
                  tagsTopic.isFetching
                    ? () => (
                        <Tag>
                          <LoadingOutlined />
                        </Tag>
                      )
                    : undefined
                }
              />
            </FormItem>
          </div>
          <div className="md:grid items-center md:grid-cols-2">
            <FormItem
              className="flex flex-col items-center justify-center text-center"
              label="Капитализация преимущества"
              name="isCapitalization"
            >
              <Switch name="isCapitalization" />
            </FormItem>

            <FormItem
              className="flex flex-col items-center justify-center text-center"
              label="Тактика"
              name="isTactic"
            >
              <Switch name="isTactic" />
            </FormItem>
          </div>
          <div className="grid sm:grid-cols-2 gap-5">
            <Button block htmlType="reset">
              Назад
            </Button>
            <div className="flex flex-row gap-3">
              <Button
                block
                htmlType="submit"
                loading={videoTwoUpdate.isLoading}
                onClick={() => !steps['2'].disabled && !dirty && changeStep(2)}
                type="primary"
              >
                <span className="max-w-full overflow-ellipsis overflow-hidden">Следующий шаг</span>
              </Button>
              <Tooltip title="Сохранить как черновик">
                <Button
                  className="px-2 border-dashed border-ant-gray-6 hover:border-ant-blue-5 shadow-none"
                  ghost
                  icon={<FileOutlined />}
                  onClick={() => submitDraft(values)}
                  type="primary"
                />
              </Tooltip>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
