import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IOnboardingItem } from '../types/IOnboardingItem';
import { onboardingItemsKeys } from './onboardingItemsKeys';

type IOnboardingImageRequest = { id: number };
type IOnboardingImageResponse = AxiosResponse<IOnboardingItem>;

export const onboardingImageGetRequest = async ({
  id,
}: IOnboardingImageRequest): Promise<IOnboardingImageResponse> =>
  await axios.get(`/onboarding/${id}`);

export function useQueryOnboardingItem(params: IOnboardingImageRequest) {
  return useQuery<IOnboardingImageResponse, AxiosError>(
    [onboardingItemsKeys.onboardingItem, params],
    () => onboardingImageGetRequest(params),
    {
      enabled: !!params.id,
    },
  );
}
