import { VersionsPlatformEnumKeys } from './enums/VersionsPlatformEnum';

export type IVersion = {
  createdDate?: Date;
  id: number;
  isActive?: boolean;
  isForced: boolean;
  localizations: Record<'en' | 'ru', { message?: string; title?: string }>;
  platform: VersionsPlatformEnumKeys;
  updatedDate?: Date;
  version: string;
};

export const versionKeys: Record<keyof IVersion, string> = {
  createdDate: 'createdDate',
  id: 'id',
  isActive: 'isActive',
  isForced: 'isForced',
  localizations: 'localizations',
  platform: 'platform',
  updatedDate: 'updatedDate',
  version: 'version',
};
