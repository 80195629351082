import { Button, Popconfirm, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  CaretRightOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import { CountryImageText } from '@components/atoms/CountryImageText';
import { TbVideoIcon, VscPlayIcon } from '@components/atoms/icons';
import { PencilIcon } from '@components/atoms/icons/PencilIcon';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';
import { IVideoStatusUpdateRequest } from '@videos/api/useMutateVideoStatusUpdate';
import { VideoStatusEnum } from '@videos/types/enums/VideoStatusEnum';
import { IVideo, videoKeys } from '@videos/types/IVideo';
import { checkAllowedSteps } from '@videos/utils/checkAllowedSteps';

export const videosColumns = ({
  canEdit,
  onClickDelete,
  onClickEdit,
  onClickPause,
  onClickView,
}: {
  canEdit: boolean;
  onClickDelete: ({ id }: { id: number }) => void;
  onClickEdit: ({ id }: { id: number }) => void;
  onClickPause: (payload: IVideoStatusUpdateRequest) => void;
  onClickView: ({ id }: { id: number }) => void;
}): ColumnsType<IVideo> => {
  const columns: ColumnsType<IVideo> = [
    {
      dataIndex: videoKeys.id,
      render: (id: IVideo['id']) => (
        <Button onClick={() => onClickView({ id })} type="link">
          {id}
        </Button>
      ),
      title: 'ID',
    },
    {
      dataIndex: videoKeys.localizations,
      render: (localizations: IVideo['localizations'], { id }) => (
        <Button onClick={() => onClickView({ id })} type="link">
          {localizations.en?.title}
        </Button>
      ),
      title: 'Краткое название видео',
    },
    {
      dataIndex: videoKeys.localizations,
      render: (localizations: IVideo['localizations'], { id }) => (
        <Button onClick={() => onClickView({ id })} type="link">
          {localizations.en?.fullTitle}
        </Button>
      ),
      title: 'Полное название видео',
    },
    {
      dataIndex: videoKeys.isCartoon,
      render: (isCartoon: IVideo['isCartoon']) =>
        isCartoon ? (
          <span className="flex flex-col items-center gap-1 text-ant-magenta-6">
            <VscPlayIcon className="text-base" />
            Мультфильм
          </span>
        ) : (
          <span className="flex flex-col items-center gap-1 text-ant-purple-6">
            <TbVideoIcon className="text-base" />
            Видеоурок
          </span>
        ),
      title: 'Тип',
    },
    {
      dataIndex: videoKeys.tags,
      render: (tags?: IVideo['tags']) => (
        <div className="space-y-2.5">
          {tags?.map((tag) => (
            <p className="mb-0 first-letter:capitalize" key={`tag${tag.id}`}>
              {tag.localizations.en}
            </p>
          ))}
        </div>
      ),
      title: 'Тема видео',
    },
    {
      dataIndex: videoKeys.playLevel,
      render: (playLevel?: IVideo['playLevel']) => (
        <p className="mb-0">{playLevel && userPlayLevels[playLevel]}</p>
      ),
      title: 'Уровень',
    },

    {
      dataIndex: videoKeys.tagMastering,
      title: 'Усвоение темы',
    },
    {
      dataIndex: videoKeys.viewTotal,
      title: 'Кол-во просмотров',
    },
    {
      dataIndex: videoKeys.author,
      key: videoKeys.author,
      render: (_, video) => (
        <div className="space-y-2">
          {Object.entries(video.localizations).map(
            ([locale, localization]) =>
              localization.author?.localizations.en && (
                <div className="whitespace-nowrap mb-0 flex items-center gap-1" key={locale}>
                  <CountryImageText src={locale} width={15} />
                  {localization.author?.localizations.en}
                </div>
              ),
          )}
        </div>
      ),
      title: 'Автор',
    },
    {
      dataIndex: videoKeys.price,
      title: 'Стоимость',
    },
    {
      dataIndex: videoKeys.sequence,
      title: 'Последовательность',
    },
    {
      dataIndex: videoKeys.status,
      render: (status: IVideo['status']) => VideoStatusEnum[status],
      title: 'Статус',
    },
  ];

  if (!canEdit) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'actions',
      render: (video: IVideo) => {
        const allowed = checkAllowedSteps(video);
        const {
          id,
          localizations: { en },
          status,
        } = video;
        const disabled = status != 'ACTIVE' && allowed['2'].status != 'finish';

        return (
          <div className="flex gap-x-1.5">
            <Popconfirm
              cancelText="Отменить"
              disabled={disabled}
              icon={
                status == 'ACTIVE' ? (
                  <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                ) : (
                  <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                )
              }
              okText={status == 'ACTIVE' ? 'Приостановить' : 'Запустить'}
              okType="primary"
              onConfirm={() =>
                onClickPause({
                  id: id,
                  status: status == 'ACTIVE' ? 'PAUSED' : 'ACTIVE',
                })
              }
              placement="topLeft"
              title={
                <p className="text-sm mb-0">
                  Вы уверены, что хотите
                  <span className="font-semibold">
                    {status == 'ACTIVE' ? ' приостановить ' : ' запустить '}
                    видео #{id}?
                  </span>
                </p>
              }
            >
              <Tooltip title={disabled && 'Заполните все поля'}>
                <Button
                  disabled={disabled}
                  ghost
                  icon={status == 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
                  type="primary"
                />
              </Tooltip>
            </Popconfirm>

            <Button
              ghost
              icon={<PencilIcon />}
              onClick={() => onClickEdit({ id })}
              type="primary"
            />

            <Popconfirm
              cancelText="Отменить"
              icon={<DeleteOutlined className="text-ant-red-5" />}
              okText="Удалить"
              okType="danger"
              onConfirm={() => onClickDelete({ id })}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что хотите удалить видео
                  <span className="font-bold"> &quot;{en?.title}&quot;</span>?
                </span>
              }
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        );
      },
      title: 'Действия',
    },
  ];
};
