import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { videoQueryKeys } from '@videos/api/videoQueryKeys';
import { IVideoFile } from '@videos/types/IVideoFile';

type IVideoFileRequest = { uuid: string };
type IVideoFileResponse = AxiosResponse<IVideoFile>;

export const videoFileRequest = async (params: IVideoFileRequest): Promise<IVideoFileResponse> =>
  await axios.get(`videos/files/${params.uuid}`);

export function useQueryVideoFile(params: IVideoFileRequest) {
  return useQuery<IVideoFileResponse, AxiosError>(
    [videoQueryKeys.videoFile, params],
    () => videoFileRequest(params),
    { enabled: !!params.uuid },
  );
}
