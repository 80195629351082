import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { NotFoundResult } from '@components/atoms/NotFoundResult';

export const QueryModalWrapper = ({
  children,
  isFound,
  isLoading,
  notFoundTitle = 'Результат не найден',
}: {
  children?: JSX.Element | false;
  isFound: boolean;
  isLoading?: boolean;
  notFoundTitle?: string;
}) => {
  if (isLoading) {
    return <BaseSkeleton />;
  }
  if (!isFound) {
    return <NotFoundResult title={notFoundTitle} />;
  }
  return children || <div />;
};
