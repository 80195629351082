import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, number, object, ObjectSchema, string } from 'yup';

import { appSettingsQueryKeys } from '@settings/appSettings/api/appSettingsQueryKeys';

import { AppSettingNameEnum, AppSettingsNameEnumKeys } from '../types/enums/AppSettingNameEnum';
import { IAppSetting } from '../types/IAppSetting';

export type IAppSettingUpdateRequest = IAppSetting;
type IAppSettingUpdateResponse = AxiosResponse<IAppSetting>;

export const appSettingUpdateValidation: ObjectSchema<IAppSettingUpdateRequest> = object({
  name: string<AppSettingsNameEnumKeys>().required(),
  number: number().nullable(),
  string: string()
    .nullable()
    .when('name', {
      is: (name: AppSettingsNameEnumKeys) =>
        Object.keys(AppSettingNameEnum).includes(name) &&
        name != 'PLAY_LEVEL_ENGINE_LEVEL_MAP' &&
        name != 'PLAY_LEVEL_ENGINE_RATING_MAP' &&
        name != 'BATTLE_ENGINE_PLAY_LEVEL_MAP',
      then: (s) => s.required(),
    }),
  variables: object()
    .nullable()
    .optional()
    .when('name', {
      is: (name: AppSettingsNameEnumKeys) => name == 'PLAY_LEVEL_ENGINE_RATING_MAP',
      then: (s) =>
        s.shape(
          Array.from(Array(10).keys())
            .map(() =>
              number()
                .min(1, 'Минимум 1 символ')
                .max(9999, 'Максимум 4 символа')
                .required()
                .nullable()
                .test({
                  message: 'Рейтинг следующего уровня должен быть больше предыдущего',
                  name: '1-3000',
                  test: function (value, context) {
                    const index = Number.parseInt(
                      context.path.substring(context.path.length - 3).replace(/\D/g, ''),
                    );
                    return (
                      index == 1 || (this.parent?.[index] || 1) > (this.parent?.[index - 1] || 1)
                    );
                  },
                }),
            )
            .reduce((a, v, currentIndex) => ({ ...a, [currentIndex + 1]: v }), {}),
        ),
    })
    .when('name', {
      is: (name: AppSettingsNameEnumKeys) => name == 'PLAY_LEVEL_ENGINE_LEVEL_MAP',
      then: (s) =>
        s.shape(
          Array.from(Array(10).keys())
            .map(() =>
              array()
                .required()
                .of(
                  number()
                    .required()
                    .nullable()
                    .test({
                      message: 'Введите актуальное значение от 1 до 3000',
                      name: '1-3000',
                      test: (value, context) => {
                        if (context.path.substring(context.path.length - 2) == '2]') {
                          const testValue = value || 0;
                          return testValue > 0 && testValue < 3000;
                        }
                        return true;
                      },
                    })
                    .test({
                      message: 'Значение должно быть между -999 и 99',
                      name: '1-3000',
                      test: (value, context) => {
                        if (context.path.substring(context.path.length - 2) != '2]') {
                          const testValue = value || 0;
                          return testValue > -1000 && testValue < 100;
                        }
                        return true;
                      },
                    }),
                ),
            )
            .reduce((a, v, currentIndex) => ({ ...a, [currentIndex + 1]: v }), {}),
        ),
    }),
});

export const appSettingUpdateRequest = async (
  payload: IAppSettingUpdateRequest,
): Promise<IAppSettingUpdateResponse> => await axios.patch('/app-settings', payload);

export function useMutateAppSettingUpdate() {
  const queryClient = useQueryClient();

  return useMutation<IAppSettingUpdateResponse, AxiosError, IAppSettingUpdateRequest>(
    appSettingUpdateRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(appSettingsQueryKeys.appSettings);
        queryClient.invalidateQueries(appSettingsQueryKeys.appSetting);
      },
    },
  );
}
