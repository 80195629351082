import { FC, useState } from 'react';
import { Button, DatePicker, Typography } from 'antd';
import { DatePickerProps } from 'antd/lib';
import dayjs, { Dayjs } from 'dayjs';
import { UploadOutlined } from '@ant-design/icons';

import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useMutatePurchaseEventsExport } from '@dashboards/api/useMutateExportMarketingDashboards';
import { IPayingUsersRequest, useQueryPayingUsers } from '@dashboards/api/useQueryPayingUsers';
import { payingUsersColumns } from '@dashboards/consts/columns/payingUsersColumn';
import { payingUsersFilters } from '@dashboards/consts/filters/payingUsersFilters';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';

import { DashboardTabs } from '../molecules/DashboardTabs';

const PayingUsersPage: FC = () => {
  const dateFormat = 'YYYY-MM-DD';

  const { filtered, onFilter } = useUrlFilteredActioned<IPayingUsersRequest>(payingUsersFilters);
  const exportStats = useMutatePurchaseEventsExport();

  const [startDate, setStartDate] = useState<Dayjs>(dayjs('2022/09/26', dateFormat));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const query = useQueryPayingUsers({ ...filtered });

  const disabledStartDate: DatePickerProps['disabledDate'] = (current) => {
    return (
      current &&
      (current <= dayjs('2022/09/25').endOf('day') || current > dayjs(endDate).endOf('day'))
    );
  };

  const disabledEndDate: DatePickerProps['disabledDate'] = (current) => {
    return current && (current <= dayjs(startDate).endOf('day') || current > dayjs().endOf('day'));
  };

  return (
    <>
      <PageMeta selectedMenuKeys={['paying-users']} title="Аналитика" />
      <DashboardTabs active="paying-users" />
      <div className="flex flex-row justify-between items-center mb-6">
        <div className="flex flex-row items-center mb-6">
          <DatePicker
            defaultValue={startDate}
            disabledDate={disabledStartDate}
            onChange={(v) => v && setStartDate(v)}
            placeholder="from"
          />
          <Typography className="text-gray-700 mx-2">—</Typography>
          <DatePicker
            defaultValue={endDate}
            disabledDate={disabledEndDate}
            onChange={(v) => v && setEndDate(v)}
            placeholder="to"
          />
        </div>
        <Button
          className="sm:px-12"
          icon={<UploadOutlined />}
          loading={exportStats.isLoading}
          onClick={() => exportStats.mutateAsync(filtered)}
          size="large"
          type="primary"
        >
          Выгрузить в excel
        </Button>
      </div>
      <AdminTable
        columns={payingUsersColumns()}
        query={query}
        tableFilter={{ filtered, filters: payingUsersFilters, onFilter }}
      />
    </>
  );
};

export default PayingUsersPage;
