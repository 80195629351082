import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Skeleton } from 'antd';

import LoginPage from '@auth/components/pages/LoginPage';
import RegisterPage from '@auth/components/pages/RegisterPage';
import ResetPasswordPage from '@auth/components/pages/ResetPasswordPage';
import { CollectionRouter } from '@collections/CollectionRouter';
import IndexRoute from '@components/molecules/routes/IndexRoute';
import { DashboardRouter } from '@dashboards/DashboardRouter';
import { ExerciseRouter } from '@exercises/ExerciseRouter';
import AdminLayout from '@layouts/AdminLayout';
import AuthLayout from '@layouts/AuthLayout';
import NotFoundPage from '@pages/errors/NotFoundPage';
import { SettingRouter } from '@settings/SettingRouter';
import { ShopRouter } from '@shop/ShopRouter';
import { StaffRouter } from '@staff/StaffRouter';
import { TagRouter } from '@tags/TagRouter';
import { UserRouter } from '@users/UserRouter';
import { VideoRouter } from '@videos/VideoRouter';

import { MatchRouter } from './modules/matches/MatchRouter';

const ResetEmailPage = lazy(() => import('@auth/components/pages/ResetEmailPage'));

const App = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route element={<LoginPage />} path="login" />
        <Route
          element={
            <Suspense fallback={<Skeleton active />}>
              <ResetEmailPage />
            </Suspense>
          }
          path="/reset/email"
        />
        <Route element={<ResetPasswordPage />} path="reset/:link" />
        <Route element={<RegisterPage />} path="register/:link" />
      </Route>

      <Route element={<AdminLayout />} path="*">
        <Route element={<IndexRoute />} index />

        <Route element={<UserRouter />} path="users/*" />
        <Route element={<MatchRouter />} path="matches/*" />
        <Route element={<StaffRouter />} path="staff/*" />
        <Route element={<VideoRouter />} path="videos/*" />
        <Route element={<ExerciseRouter />} path="exercises/*" />
        <Route element={<TagRouter />} path="tags/*" />
        <Route element={<ShopRouter />} path="shop/*" />
        <Route element={<SettingRouter />} path="settings/*" />
        <Route element={<CollectionRouter />} path="collections/*" />
        <Route element={<DashboardRouter />} path="dashboards/*" />

        <Route element={<NotFoundPage />} path="*" />
      </Route>
    </Routes>
  );
};

export default App;
