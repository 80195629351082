import { IFilters } from '@typess/IFilters';
import { userMapStageKeys } from '@users/types/IUserMapStage';

export const userMapStagesFilters: IFilters = {
  [userMapStageKeys.stageNumber]: { sort: true },
  page: {},
  pageSize: {},
  sortBy: {},
  sortDirection: {},
};
