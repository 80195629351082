import { FC } from 'react';
import { Button, Modal, Switch as AntSwitch, Tag } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  createLecturerCardInitialValue,
  createLecturerCardValidation,
  ILecturerCardCreateRequest,
  useMutateLecturerCardCreate,
} from '@collections/lecturer-cards/api/useMutateLecturerCardCreate';
import { FileUpload } from '@components/molecules/files/FileUpload';
import { useQueryTags } from '@tags/api/useQueryTags';

import { CreateLecturerLocaleFormCard } from '../molecules/LecturerCardLocaleForm';

type CreateLecturerCardModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateLecturerCardModal: FC<CreateLecturerCardModalProps> = ({ isOpen, onClose }) => {
  const createCardMutation = useMutateLecturerCardCreate();
  const tagsAuthor = useQueryTags({ type: 'AUTHOR' });

  return (
    <Modal
      className="px-4 md:px-0 w-full md:w-4/5 lg:w-3/4"
      footer={null}
      onCancel={onClose}
      open={isOpen}
      title="Создать карточку лектора"
    >
      <Formik<ILecturerCardCreateRequest>
        enableReinitialize
        initialValues={createLecturerCardInitialValue}
        onSubmit={(values) => createCardMutation.mutateAsync(values).then(() => onClose())}
        validateOnBlur={false}
        validationSchema={createLecturerCardValidation}
      >
        {({ setFieldValue, values }) => (
          <Form className="gap-4 md:gap-6" layout="vertical">
            <FormItem label="Лектор" name="authorTagId">
              <Select
                loading={tagsAuthor.isFetching}
                name="authorTagId"
                optionFilterProp="label"
                options={
                  tagsAuthor.data?.data.content.map((tag) => ({
                    label: tag.localizations.en,
                    value: tag.id,
                  })) || []
                }
                placeholder="Лектор"
                tagRender={
                  tagsAuthor.isFetching
                    ? () => (
                        <Tag>
                          <LoadingOutlined />
                        </Tag>
                      )
                    : undefined
                }
              />
            </FormItem>

            <FormItem className="upload-form-item" label="Фоновая картинка" name="imgUrl">
              <FileUpload
                accept=".jpeg,.png,.jpg,.svg"
                folderName="imgUrl"
                maxCount={1}
                onChange={(info, linkInfo) => {
                  if (!info.file.status || info.file.status == 'removed')
                    setFieldValue('imgUrl', '');
                  if (info.file.status == 'done') setFieldValue('imgUrl', linkInfo.downloadUrl);
                }}
              />
            </FormItem>
            <div className="grid sm:grid-cols-2 gap-6">
              <CreateLecturerLocaleFormCard localization="en" />
              <CreateLecturerLocaleFormCard localization="ru" />
            </div>
            <div className="flex justify-center text-center">
              <FormItem label={<span className="text-center">Запустить</span>} name="status">
                <Input hidden name="status" suffix />

                <AntSwitch
                  checked={values.status == 'ACTIVE'}
                  onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
                />
              </FormItem>
            </div>

            <div className="flex gap-4 justify-end">
              <Button onClick={onClose}>Отмена</Button>
              <Button htmlType="submit" loading={createCardMutation.isLoading} type="primary">
                <span className="max-w-full overflow-ellipsis overflow-hidden">
                  {values.status == 'ACTIVE' ? 'Создать и запустить' : 'Сохранить как черновик'}
                </span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
