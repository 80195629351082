import type { FC } from 'react';
import { Button, Modal } from 'antd';
import SkeletonImage from 'antd/es/skeleton/Image';
import { Formik } from 'formik';
import { Form, FormItem } from 'formik-antd';

import { updateAvatarValidation } from '@collections/avatars/api/useMutateAvatarUpdate';
import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { FileUpload } from '@components/molecules/files/FileUpload';
import {
  IOnboardingItemUpdateRequest,
  useMutateOnboardingItemUpdate,
} from '@settings/onboarding/api/useMutateOnboardingItemUpdate';
import { useQueryOnboardingItem } from '@settings/onboarding/api/useQueryOnboardingItem';

type EditOnboardingItemModalProps = {
  id?: number;
  isOpen: boolean;
  onClose: () => void;
};

export const EditOnboardingItemModal: FC<EditOnboardingItemModalProps> = ({
  id = 0,
  isOpen,
  onClose,
}) => {
  const updateOnboardingItemMutation = useMutateOnboardingItemUpdate();
  const onBoardingItemQuery = useQueryOnboardingItem({ id });
  const onboardingItem = onBoardingItemQuery.data?.data;

  return (
    <Modal footer={null} onCancel={onClose} open={isOpen} title="Редактировать изображение">
      {onBoardingItemQuery.isFetching && <BaseSkeleton />}

      {onBoardingItemQuery.isFetching ? (
        <SkeletonImage />
      ) : !onBoardingItemQuery ? (
        <NotFoundResult title="Изображение не найдено" />
      ) : (
        <Formik<IOnboardingItemUpdateRequest>
          enableReinitialize
          initialValues={{
            id,
            imgUrl: onboardingItem?.imgUrl ?? '',
          }}
          onSubmit={(values) =>
            updateOnboardingItemMutation.mutateAsync(values).then(() => {
              onClose();
            })
          }
          validateOnBlur={false}
          validationSchema={updateAvatarValidation}
        >
          {({ setFieldValue, values }) => (
            <Form className="gap-4 md:gap-6" layout="vertical">
              <FormItem className="upload-form-item" label="" name="imgUrl">
                <FileUpload
                  accept=".jpeg,.png,.jpg,.svg"
                  defaultFileList={
                    onboardingItem?.imgUrl
                      ? [
                          {
                            name: '',
                            uid: onboardingItem.imgUrl,
                            url: onboardingItem.imgUrl,
                          },
                        ]
                      : undefined
                  }
                  folderName="onboarding"
                  maxCount={1}
                  onChange={(info, linkInfo) => {
                    if (!info.file.status || info.file.status == 'removed')
                      setFieldValue('imgUrl', '');
                    if (info.file.status == 'done') setFieldValue('imgUrl', linkInfo.downloadUrl);
                  }}
                />
              </FormItem>

              <div className="flex gap-4 justify-end">
                <Button onClick={onClose}>Отмена</Button>
                <Button
                  disabled={!values.imgUrl}
                  htmlType="submit"
                  loading={updateOnboardingItemMutation.isLoading}
                  onClick={() =>
                    updateOnboardingItemMutation
                      .mutateAsync({
                        ...values,
                        order: onboardingItem?.order,
                        visible: onboardingItem?.visible,
                      })
                      .then(() => {
                        onClose();
                      })
                  }
                  type="primary"
                >
                  Сохранить
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
