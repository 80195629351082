import { FC } from 'react';
import { Button } from 'antd';

import { useQueryMe } from '@auth/api/useQueryMe';
import { FaChessKnightIcon } from '@components/atoms/icons';
import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { useMutateStaffDelete } from '@staff/api/useMutateStaffDelete';
import { IStaffsRequest, useQueryStaffs } from '@staff/api/useQueryStaffs';
import { StaffEditModal } from '@staff/components/organisms/StaffEditModal';
import { StaffInviteModal } from '@staff/components/organisms/StaffInviteModal';
import { staffsColumns } from '@staff/consts/columns/staffsColumns';
import { staffsFilters } from '@staff/consts/filters/staffsFilters';
import {
  IStaffsPageActions,
  staffsPageActions,
} from '@staff/consts/pageActions/IStaffsPageActions';
import { staffPermissions } from '@typess/permissions/IStaffPermission';
import { checkEditPermission } from '@utils/checkEditPermission';

const StaffsPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IStaffsRequest,
    IStaffsPageActions
  >(staffsFilters, staffsPageActions);
  const meQuery = useQueryMe();

  const staffs = useQueryStaffs(filtered);
  const staffDelete = useMutateStaffDelete();

  const canEditStaff = checkEditPermission(staffPermissions);

  return (
    <div>
      <PageMeta
        selectedMenuKeys={['staff']}
        subTitle={
          actioned.action == 'invite'
            ? 'Новый персонал'
            : actioned.action == 'edit'
            ? `Изменить персонал #${actioned.actionId}`
            : ''
        }
        title="Персонал"
      />

      {canEditStaff && (
        <div className="flex justify-end">
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'invite' })}
            size="large"
            type="primary"
          >
            Новый персонал
          </Button>
        </div>
      )}

      <div className="flex items-center gap-1">
        <FaChessKnightIcon />
        <h2 className="mb-0"> {meQuery.data?.email}</h2>
        <FaChessKnightIcon />
      </div>

      <AdminTable
        columns={staffsColumns({
          canEdit: canEditStaff,
          onClickDelete: staffDelete.mutateAsync,
          onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
        })}
        emptyDescription={
          <div>
            <p>Персонал не найден.</p>
            <p>Вы можете создать персонал, нажав на кнопку “Новый персонал”</p>
          </div>
        }
        query={staffs}
        tableFilter={{ filtered, filters: staffsFilters, onFilter }}
      />

      <StaffInviteModal isOpen={actioned.action == 'invite'} onCancel={clearParams} />

      <StaffEditModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
      />
    </div>
  );
};

export default StaffsPage;
