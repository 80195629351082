import { DateRangeFilter } from '@components/molecules/filters/static/DateRangeFilter';
import { InputFilter } from '@components/molecules/filters/static/InputFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { staffKeys } from '@staff/types/IStaff';
import { IFilters } from '@typess/IFilters';

export const staffsFilters: IFilters = {
  ...paginationBaseFilters,

  [staffKeys.email]: {
    input: <InputFilter placeholder="Введите почту" />,
    sort: true,
  },
  [staffKeys.fullName]: {
    input: <InputFilter placeholder="Введите имя" />,
    sort: true,
  },
  [staffKeys.lastActivity]: {
    input: <DateRangeFilter placeholderMax="Макс" placeholderMin="Мин" />,
    queries: [{ key: 'lastActivityFrom' }, { key: 'lastActivityTo' }],
  },
  [staffKeys.state]: {
    sort: true,
  },
};
