import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { staffsQueryKeys } from '@staff/api/staffsQueryKeys';
import { StaffStateEnumKeys } from '@staff/types/enums/StaffStateEnum';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

import { IStaff } from '../types/IStaff';

export type IStaffsRequest = IPageAbleRequest & {
  email?: string;
  fullName?: string;
  lastActivityFrom?: string;
  lastActivityTo?: string;
  role?: 'ROLE_CUSTOMER' | 'ROLE_STAFF';
  state?: StaffStateEnumKeys;
};

type IStaffsResponse = AxiosResponse<IPageAbleResponse<IStaff>>;

export const staffsRequest = async (params?: IStaffsRequest): Promise<IStaffsResponse> => {
  return await axios.get('/staff', { params });
};

export function useQueryStaffs(params?: IStaffsRequest) {
  return useQuery<IStaffsResponse, AxiosError>([staffsQueryKeys.staffs, params], () =>
    staffsRequest(params),
  );
}
