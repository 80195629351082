import React, { FC, useState } from 'react';
import { Card, Col, DatePicker, List, Row, Segmented, Typography } from 'antd';
import { DatePickerProps } from 'antd/lib';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { ColumnConfig } from '@ant-design/charts';
import { Column } from '@ant-design/plots';

import { PageMeta } from '@context/PageMetaContext';
import { useQueryPayingUsersStats } from '@dashboards/api/useQueryPayingUsersStats';
import { useQueryPaymentStatsByDay } from '@dashboards/api/useQueryPaymentStatsByDay';
import { IPaymentStatByDay } from '@dashboards/types/IPaymentStatByDay';
import { PAYMENT_STAT_PERIOD } from '@dashboards/types/PaymentStatPeriods';

import { DashboardTabs } from '../molecules/DashboardTabs';

const PayingUsersTotalDashboardPage: FC = () => {
  const [startDate, setStartDate] = useState<Dayjs>(dayjs('2023/10/20'));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const [period, setPeriod] = useState<PAYMENT_STAT_PERIOD>(PAYMENT_STAT_PERIOD.BY_WEEK);

  const { data: payingUsersStats } = useQueryPayingUsersStats();
  const { data: paymentStatsByDay, isLoading: isPaymentStatsByDayLoading } =
    useQueryPaymentStatsByDay({
      from: startDate?.format('YYYY-MM-DD'),
      period,
      to: endDate?.format('YYYY-MM-DD'),
    });

  const disabledStartDate: DatePickerProps['disabledDate'] = (current) => {
    return (
      current &&
      (current <= dayjs('2022/09/25').endOf('day') || current > dayjs(endDate).endOf('day'))
    );
  };

  const disabledEndDate: DatePickerProps['disabledDate'] = (current) => {
    return current && (current <= dayjs(startDate).endOf('day') || current > dayjs().endOf('day'));
  };

  const getPaymentsStats = (data: IPaymentStatByDay[]) => {
    if (period == PAYMENT_STAT_PERIOD.BY_DAY) {
      return data.reverse();
    } else if (period == PAYMENT_STAT_PERIOD.BY_WEEK) {
      return data
        .map((item) => ({
          ...item,
          date: moment()
            .day(1)
            .week(item?.weekNum ?? 1)
            .format('DD-MM-YYYY'),
        }))
        .reverse();
    } else if (period == PAYMENT_STAT_PERIOD.BY_MONTH) {
      return data
        .map((item) => ({
          ...item,
          date: `${item?.monthNum}-${item?.yearNum}`,
        }))
        .reverse();
    } else {
      return [];
    }
  };

  const data = [
    {
      count: payingUsersStats?.data.payingCustomers,
      title: 'All Customers',
    },
    {
      count: payingUsersStats?.data.activeSubscriptions,
      title: 'Active Subs',
    },
    {
      count: payingUsersStats?.data.activeTrialSubscriptions,
      title: 'Active Trials',
    },
  ];

  const config: ColumnConfig = {
    data: getPaymentsStats(paymentStatsByDay?.data ?? []),
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    meta: {
      sales: {
        alias: 'пользователи',
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    xField: 'date',
    yField: 'payingCustomers',
  };

  const config2: ColumnConfig = {
    data: getPaymentsStats(paymentStatsByDay?.data ?? []),
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    meta: {
      sales: {
        alias: 'пользователи',
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    xField: 'date',
    yField: 'newPayingCustomers',
  };

  const config3: ColumnConfig = {
    data: getPaymentsStats(paymentStatsByDay?.data ?? []),
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    meta: {
      sales: {
        alias: 'пользователи',
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    xField: 'date',
    yField: 'payingSubscriptions',
  };

  return (
    <>
      <PageMeta selectedMenuKeys={['dashboards']} title="Аналитика" />
      <DashboardTabs active="paying-users-stats" />
      <div className="flex flex-row justify-between">
        <Typography className="font-bold text-[20px] mb-6">Now | Total analysis</Typography>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row items-start mb-6">
          <List
            bordered
            className="bg-white w-[380px]"
            dataSource={data}
            itemLayout="horizontal"
            renderItem={(item) => (
              <List.Item className="w-full">
                <Row className="w-full">
                  <Col span={12}>{item.title}</Col>
                  <Col span={12}>{item.count}</Col>
                </Row>
              </List.Item>
            )}
          />
        </div>
        <div className="flex flex-row items-center mb-6">
          <DatePicker
            defaultValue={startDate}
            disabledDate={disabledStartDate}
            format="DD-MM-YYYY"
            onChange={(v) => v && setStartDate(v)}
            placeholder="from"
          />
          <Typography className="text-gray-700 mx-2">—</Typography>
          <DatePicker
            className="mr-4"
            defaultValue={endDate}
            disabledDate={disabledEndDate}
            format="DD-MM-YYYY"
            onChange={(v) => v && setEndDate(v)}
            placeholder="to"
          />
          <Segmented
            className="bg-white [&_.ant-segmented-item-selected]:bg-blue-500 [&_.ant-segmented-item-selected]:text-white"
            onChange={(v) => setPeriod(v as PAYMENT_STAT_PERIOD)}
            options={[
              { label: 'Daily', value: PAYMENT_STAT_PERIOD.BY_DAY },
              { label: 'Weekly', value: PAYMENT_STAT_PERIOD.BY_WEEK },
              { label: 'Monthly', value: PAYMENT_STAT_PERIOD.BY_MONTH },
            ]}
            value={period}
          />
        </div>
        <Card className="w-[100%] mb-6" title="All customers">
          <Column {...config} loading={isPaymentStatsByDayLoading} />
        </Card>
        <Card className="w-[100%] mb-6" title="Новый платящий">
          <Column {...config2} loading={isPaymentStatsByDayLoading} />
        </Card>
        <Card className="w-[100%] mb-6" title="Кол-во уникальных подписчиков (платящих)">
          <Column {...config3} loading={isPaymentStatsByDayLoading} />
        </Card>
      </div>
    </>
  );
};

export default PayingUsersTotalDashboardPage;
