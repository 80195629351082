import { FC, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form, FormItem, InputNumber } from 'formik-antd';
import { FileOutlined } from '@ant-design/icons';

import { notify } from '@utils/notify';
import {
  IVideoStepOneUpdateRequest,
  useMutateVideoStepOneUpdate,
  videoStepOneUpdateInitial,
  videoStepOneUpdateValidation,
} from '@videos/api/useMutateVideoStepOneUpdate';
import { VideoLocaleFormCard } from '@videos/components/molecules/VideoLocaleFormCard';
import { IVideo } from '@videos/types/IVideo';
import { AllowedSteps } from '@videos/utils/checkAllowedSteps';

export const StepOne: FC<{
  changeStep: (step: 0 | 1 | 2) => void;
  onCancel: () => void;
  steps: AllowedSteps;
  video: IVideo;
}> = ({ changeStep, onCancel, steps, video }) => {
  const videoOneUpdate = useMutateVideoStepOneUpdate();
  const [descriptionsNum, setDescriptionsNum] = useState(
    video.localizations.ru?.descriptions?.length ?? 1,
  );

  const submitDraft = ({
    resetForm,
    setErrors,
    setTouched,
    values,
  }: Pick<
    FormikProps<IVideoStepOneUpdateRequest>,
    'values' | 'setTouched' | 'setErrors' | 'resetForm'
  >) => {
    if (!values.localizations.en) {
      setErrors({ localizations: { en: { title: 'Обязательно для заполнения' } } });
      setTouched({ localizations: { en: { title: true } } });
    } else {
      videoOneUpdate.mutateAsync({ ...values, status: 'DRAFT' }).then((data) => {
        if (data.status == 201 || data.status == 200) {
          notify('success', 'Видео сохранено как черновик');
          onCancel();
          resetForm();
        }
      });
    }
  };

  return (
    <Formik<IVideoStepOneUpdateRequest>
      enableReinitialize
      initialValues={{
        id: video.id,
        isCartoon: video.isCartoon,
        localizations: {
          en: {
            ...(video.localizations.en || videoStepOneUpdateInitial.localizations.en),
            ...{ authorTagId: video.localizations.en?.author?.id },
          },
          ru: {
            ...(video.localizations.ru || videoStepOneUpdateInitial.localizations.ru),
            ...{ authorTagId: video.localizations.ru?.author?.id },
          },
        },
        // previewUrl: video.previewUrl,
        price: video.price,
        status: video.status,
      }}
      onSubmit={(values) => {
        videoOneUpdate.mutateAsync(values).then((data) => {
          if (data.status == 201 || data.status == 200) {
            if (values.isCartoon) {
              notify('success', 'Мультфильм сохранен');
              onCancel();
            } else {
              notify('success', 'Шаг 1 сохранен');
              changeStep(1);
            }
          }
        });
      }}
      validationSchema={videoStepOneUpdateValidation}
    >
      {({ dirty, resetForm, setErrors, setFieldValue, setTouched, values }) => (
        <Form className="space-y-4 md:space-y-6" layout="vertical">
          <h1 className="text-2xl">
            Изменение
            {values.isCartoon ? (
              <span className="text-ant-magenta-6"> мультфильма #{video.id}</span>
            ) : (
              <span className="text-ant-purple-6"> видеоурока #{video.id}</span>
            )}
          </h1>

          <div className="grid sm:grid-cols-2 gap-6">
            <VideoLocaleFormCard
              descriptionsNum={descriptionsNum}
              isCartoon={video.isCartoon}
              localization="en"
              setDescriptionsNum={setDescriptionsNum}
              setFieldValue={setFieldValue}
              test={values.localizations.en}
              video={video}
            />
            <VideoLocaleFormCard
              descriptionsNum={descriptionsNum}
              isCartoon={video.isCartoon}
              localization="ru"
              setDescriptionsNum={setDescriptionsNum}
              setFieldValue={setFieldValue}
              test={values.localizations.ru}
              video={video}
            />
          </div>
          <div className="grid grid-flow-col text-center items-center justify-items-center">
            {values.isCartoon && (
              <FormItem name="price">
                <p className="mb-2">Цена</p>
                <InputNumber controls={false} maxLength={6} min={0} name="price" prefix />
              </FormItem>
            )}
          </div>

          <div className="grid sm:grid-cols-2 gap-5">
            <Button block onClick={onCancel}>
              Отменить
            </Button>
            <div className="flex flex-row gap-3">
              <Button
                block
                htmlType="submit"
                loading={videoOneUpdate.isLoading}
                onClick={() => !steps['1'].disabled && !dirty && changeStep(1)}
                type="primary"
              >
                <span className="max-w-full overflow-ellipsis overflow-hidden">
                  {values.isCartoon ? 'Изменить и запустить' : 'Следующий шаг'}
                </span>
              </Button>
              <Tooltip title="Сохранить как черновик">
                <Button
                  className="px-2 border-dashed border-ant-gray-6 hover:border-ant-blue-5 shadow-none"
                  ghost
                  icon={<FileOutlined />}
                  onClick={() => submitDraft({ resetForm, setErrors, setTouched, values })}
                  type="primary"
                />
              </Tooltip>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
