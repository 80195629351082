import { FC, useMemo } from 'react';
import { Button } from 'antd';

import { useMutateAvatarDelete } from '@collections/avatars/api/useMutateAvatarDelete';
import { useMutateAvatarStatusUpdate } from '@collections/avatars/api/useMutateAvatarStatusUpdate';
import { IAvatarsRequest, useQueryAvatars } from '@collections/avatars/api/useQueryAvatars';
import { avatarColumns } from '@collections/avatars/consts/columns/avatarColumns';
import {
  avatarPageActions,
  IAvatarsPageActions,
} from '@collections/avatars/consts/pageActions/IAvatarsPageActions';
import { avatarFilters } from '@collections/avatars/filters/avatarFilters';
import { CollectionTabs } from '@collections/components/molecules/CollectionTabs';
import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';

import { CreateAvatarModal } from '../organisms/CreateAvatarModal';
import { EditAvatarModal } from '../organisms/EditAvatarModal';
import { ViewAvatarModal } from '../organisms/ViewAvatarModal';

export const AvatarsPage: FC = () => {
  const deleteAvatarMutation = useMutateAvatarDelete();
  const avatarStatusUpdate = useMutateAvatarStatusUpdate();

  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IAvatarsRequest,
    IAvatarsPageActions
  >(avatarFilters, avatarPageActions);

  const avatarsQuery = useQueryAvatars(filtered);

  const title = useMemo(() => {
    if (actioned.action === 'create') {
      return 'Создать аватар';
    } else if (actioned.action === 'view') {
      return `Аватар ${actioned.actionId}`;
    }

    return 'Аватары';
  }, [actioned.action]);

  return (
    <>
      <PageMeta selectedMenuKeys={['avatars']} title={title} />
      <CollectionTabs active="avatars" />
      <div className="flex justify-end gap-4 mb-4">
        <Button
          className="sm:px-12"
          onClick={() => setActioned({ action: 'create' })}
          size="large"
          type="primary"
        >
          Создать аватар
        </Button>
      </div>

      <CreateAvatarModal isOpen={actioned.action == 'create'} onClose={clearParams} />

      <EditAvatarModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onClose={() => {
          clearParams();
        }}
      />

      <ViewAvatarModal
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onClose={() => {
          clearParams();
        }}
      />

      <AdminTable
        columns={avatarColumns({
          onClickDelete: deleteAvatarMutation.mutateAsync,
          onClickPause: avatarStatusUpdate.mutateAsync,
          onEditAvatar: (avatar) => {
            setActioned({ action: 'edit', actionId: avatar.id });
          },
          onOpenAvatar: (avatar) => {
            setActioned({ action: 'view', actionId: avatar.id });
          },
        })}
        query={avatarsQuery}
        tableFilter={{ filtered, filters: avatarFilters, onFilter }}
      />
    </>
  );
};
