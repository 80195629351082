import { FC } from 'react';
import { Button } from 'antd';

import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { videoPermissions } from '@typess/permissions/IVideoPermission';
import { checkEditPermission } from '@utils/checkEditPermission';
import { useMutateVideoFileDelete } from '@videos/api/useMutateVideoFileDelete';
import { IVideoFilesRequest, useQueryVideoFiles } from '@videos/api/useQueryVideoFiles';
import { VideoTabs } from '@videos/components/molecules/VideoTabs';
import { VideoFileUploadModal } from '@videos/components/organisms/VideoFileUploadModal';
import { VideoFileViewModal } from '@videos/components/organisms/VideoFileViewModal';
import { videoFilesColumns } from '@videos/consts/columns/videoFilesColumns';
import { videoFilesFilters } from '@videos/consts/filters/videoFilesFilters';
import {
  IVideoFilesPageActions,
  videoFilesPageActions,
} from '@videos/consts/pageActions/IVideoFilesPageActions';

export const VideoFilesPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IVideoFilesRequest,
    IVideoFilesPageActions
  >(videoFilesFilters, videoFilesPageActions);

  const videoFiles = useQueryVideoFiles(filtered);

  const videoFileDelete = useMutateVideoFileDelete();
  const canEditVideo = checkEditPermission(videoPermissions);

  return (
    <div>
      <PageMeta
        selectedMenuKeys={['videos']}
        subTitle={
          actioned.action == 'view' && actioned.actionId ? `Видеофайл #${actioned.actionId}` : ''
        }
        title="Видеофайлы"
      />

      <VideoTabs active="video-files" />

      {canEditVideo && (
        <div className="flex justify-end gap-4">
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'create' })}
            size="large"
            type="default"
          >
            Новый видеофайл
          </Button>
        </div>
      )}

      <AdminTable
        columns={videoFilesColumns({
          canEdit: canEditVideo,
          onClickDelete: videoFileDelete.mutateAsync,
          onClickView: ({ uuid }) => setActioned({ action: 'view', actionId: uuid }),
        })}
        emptyDescription={
          <div>
            <p>Видеофайлы не найдены.</p>
            <p>Вы можете создать видеофайл, нажав на кнопку “Новый видеофайл”</p>
          </div>
        }
        query={videoFiles}
        rowKey="uuid"
        tableFilter={{ filtered, filters: videoFilesFilters, onFilter }}
      />

      <VideoFileViewModal
        isOpen={actioned.action == 'view'}
        onCancel={clearParams}
        uuid={actioned.actionId}
      />

      <VideoFileUploadModal isOpen={actioned.action == 'create'} onCancel={clearParams} />
    </div>
  );
};
