import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IBoard } from '@collections/boards/types/IBoard';

import { boardCommonKeys } from './boardCommonKeys';

type IBoardRequest = { id: number };
type IBoardResponse = AxiosResponse<IBoard>;

export const boardGetRequest = async ({ id }: IBoardRequest): Promise<IBoardResponse> =>
  await axios.get(`/chess-boards/${id}`);

export function useQueryBoard(params: IBoardRequest) {
  return useQuery<IBoardResponse, AxiosError>(
    [boardCommonKeys.board, params],
    () => boardGetRequest(params),
    {
      enabled: params.id > 0,
    },
  );
}
