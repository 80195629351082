import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';

export const ExerciseTabs: FC<{
  active: 'exercises' | 'groups' | 'fens' | 'level-testing' | 'map-stages';
}> = ({ active }) => {
  const navigate = useNavigate();

  return (
    <Tabs
      activeKey={active}
      centered
      items={[
        { key: 'exercises', label: 'Задачи' },
        { key: 'groups', label: 'Группы упражнений' },
        { key: 'fens', label: 'FEN' },
        { key: 'level-testing', label: 'Тестирование уровня' },
        { key: 'map-stages', label: 'Конструктор уровней' },
      ]}
      onChange={(tab) => navigate(tab == 'exercises' ? '/exercises' : `/exercises/${tab}`)}
    />
  );
};
