import { Button, Image, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  CaretRightOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import {
  LecturerCardStatusEnum,
  LecturerCardStatusEnumKeys,
} from '@collections/lecturer-cards/types/enums/LecturerCardStatusEnum';
import { ILecturerCard, lecturerCardKeys } from '@collections/lecturer-cards/types/ILecturerCard';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { PencilIcon } from '@components/atoms/icons';

type LecturerCardColumnsProps = {
  onClickDelete: ({ id }: { id: number }) => void;
  onClickPause: ({ id, status }: { id: number; status: LecturerCardStatusEnumKeys }) => void;
  onEditCard: (card: ILecturerCard) => void;
  onOpenCard: (card: ILecturerCard) => void;
};

export const lecturerCardColumns = ({
  onClickDelete,
  onClickPause,
  onEditCard,
  onOpenCard,
}: LecturerCardColumnsProps): ColumnsType<ILecturerCard> => {
  return [
    {
      dataIndex: lecturerCardKeys.id,
      render: (id: ILecturerCard['id'], lc) => {
        return (
          <Button onClick={() => onOpenCard(lc)} type="link">
            {id}
          </Button>
        );
      },
      title: 'ID',
    },
    {
      dataIndex: lecturerCardKeys.authorTag,
      render: (authorTag: ILecturerCard['authorTag']) => {
        return (
          <div className="flex flex-row items-center">
            <Image className="rounded-full shadow-lg" src={authorTag.imgUrl} width={100} />
            <div className="ml-2">
              <CountryImageText src="en" text={authorTag?.localizations.en || '-'} />
              <CountryImageText src="ru" text={authorTag?.localizations.ru || '-'} />
            </div>
          </div>
        );
      },
      title: 'Лектор',
    },
    {
      dataIndex: lecturerCardKeys.imgUrl,
      render: (imgUrl: ILecturerCard['imgUrl']) => <Image src={imgUrl} width={100} />,

      title: 'Фоновая картинка',
    },
    {
      dataIndex: lecturerCardKeys.status,
      render: (status: ILecturerCard['status']) => {
        return status ? LecturerCardStatusEnum[status] : '-';
      },
      title: 'Статус',
    },
    {
      key: 'action',
      render: ({ id, status }: ILecturerCard, card) => {
        return (
          <div className="flex items-center space-x-2">
            <Popconfirm
              cancelText="Отменить"
              icon={
                status === 'ACTIVE' ? (
                  <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                ) : (
                  <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                )
              }
              okText={status === 'ACTIVE' ? 'Приостановить' : 'Запустить'}
              okType="primary"
              onConfirm={() =>
                onClickPause({
                  id: id,
                  status: status === 'ACTIVE' ? 'DRAFT' : 'ACTIVE',
                })
              }
              placement="topLeft"
              title={
                <p className="text-sm mb-0">
                  Вы уверены, что хотите
                  <span className="font-semibold">
                    {status === 'ACTIVE' ? ' приостановить ' : ' запустить '}
                    {id}?
                  </span>
                </p>
              }
            >
              <Button
                ghost
                icon={status === 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
                type="primary"
              />
            </Popconfirm>

            <Button ghost icon={<PencilIcon />} onClick={() => onEditCard(card)} type="primary" />

            <Popconfirm
              cancelText="Отменить"
              icon={<DeleteOutlined className="text-ant-red-5" />}
              okText="Удалить"
              okType="danger"
              onConfirm={() => onClickDelete({ id })}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что хотите удалить карточку лектора
                  <span className="font-bold"> &quot;{card.localizations?.ru?.title}&quot;</span>?
                </span>
              }
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        );
      },
      title: 'Действия',
    },
  ];
};
