import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { object, ObjectSchema, string } from 'yup';

import { useQueryMe } from '@auth/api/useQueryMe';

export type ILoginRequest = {
  password: string;
  username: string;
};

type ILoginResponse = {
  token: string;
};

export const loginInitial: ILoginRequest = {
  password: '',
  username: '',
};

export const loginValidation: ObjectSchema<ILoginRequest> = object().shape({
  password: string()
    .required('Пароль обязателен для заполнения')
    .max(30, 'Пароль должен быть не более 30 символов'),
  username: string().required('Логин обязателен для заполнения'),
});

export const loginRequest = async (
  payload: ILoginRequest,
): Promise<AxiosResponse<ILoginResponse>> => {
  return await axios.post<ILoginResponse>('/auth/login', payload);
};

export function useMutateLogin() {
  const navigate = useNavigate();

  const me = useQueryMe();

  return useMutation<AxiosResponse<ILoginResponse>, AxiosError, ILoginRequest>(
    'login',
    loginRequest,
    {
      onSuccess: (response) => {
        if (response.data.token) {
          localStorage.setItem('token', response.data.token);
          me.remove();
          navigate('/');
        }
      },
    },
  );
}
