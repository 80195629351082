import { ITag } from '@tags/types/ITag';

export type IVideoFile = {
  author?: ITag;
  createdDate: Date;
  hlsUrl: string;
  isUploading: boolean;
  mediaUrl: string;
  title: string;
  updatedDate?: Date;
  uuid: string;
  videoFileName: string;
};

export const videoFileKeys: Record<keyof IVideoFile, string> = {
  author: 'author',
  createdDate: 'createdDate',
  hlsUrl: 'hlsUrl',
  isUploading: 'isUploading',
  mediaUrl: 'mediaUrl',
  title: 'title',
  updatedDate: 'updatedDate',
  uuid: 'uuid',
  videoFileName: 'videoFileName',
};
