import { FC, useEffect } from 'react';
import React, { useState } from 'react';
import { Table } from 'antd';

import { avatarPageActions } from '@collections/avatars/consts/pageActions/IAvatarsPageActions';
import { DraggableTableRow } from '@components/organisms/adminTable/organismComponents/DraggableTableRow';
import { PageMeta } from '@context/PageMetaContext';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { arrayMove } from '@dnd-kit/sortable';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { SettingTabs } from '@settings/appSettings/components/molecules/SettingTabs';
import { useMutateOnboardingItemUpdate } from '@settings/onboarding/api/useMutateOnboardingItemUpdate';
import {
  IOnboardingItemsRequest,
  useQueryOnboardingItems,
} from '@settings/onboarding/api/useQueryOnboardingItems';
import { onboardingColumns } from '@settings/onboarding/consts/columns/onboardingColumns';
import { IOnboardingPageActions } from '@settings/onboarding/consts/pageActions/IOnboardingPageActions';
import { IOnboardingItem } from '@settings/onboarding/types/IOnboardingItem';

import { EditOnboardingItemModal } from '../organisms/EditOnboardingItemModal';

const OnboardingPage: FC = () => {
  const onboardingItems = useQueryOnboardingItems();
  const updateOnboardingItemMutation = useMutateOnboardingItemUpdate();

  const [dataSource, setDataSource] = useState<(IOnboardingItem & { key: number })[]>([]);

  useEffect(() => {
    if (onboardingItems.data?.data) {
      setDataSource(
        onboardingItems.data.data.map((item) => ({
          ...item,
          key: item.order,
        })),
      );
    }
  }, [onboardingItems.data?.data]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const changedItem = previous.find((i) => i.order === active?.id);
        const overIndex = previous.findIndex((i) => i.order === over?.id);
        const activeIndex = previous.findIndex((i) => i.order === active.id);
        changedItem &&
          updateOnboardingItemMutation.mutateAsync({ ...changedItem, order: overIndex + 1 });
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const { actioned, clearParams, setActioned } = useUrlFilteredActioned<
    IOnboardingItemsRequest,
    IOnboardingPageActions
  >(undefined, avatarPageActions);

  return (
    <>
      <PageMeta selectedMenuKeys={['onboarding']} title="Onboarding" />

      <SettingTabs active="onboarding" />

      <EditOnboardingItemModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onClose={() => {
          clearParams();
        }}
      />

      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource?.map((i) => i.order)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            columns={onboardingColumns({
              onEditOnboardingItem: (onboardingItem) => {
                setActioned({ action: 'edit', actionId: onboardingItem.id });
              },
              onHideOnboardingItem: updateOnboardingItemMutation.mutateAsync,
            })}
            components={{
              body: {
                row: DraggableTableRow,
              },
            }}
            dataSource={dataSource}
            loading={onboardingItems.isLoading}
            pagination={false}
            rowKey="key"
          />
        </SortableContext>
      </DndContext>
    </>
  );
};

export default OnboardingPage;
