import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import { PencilIcon } from '@components/atoms/icons';
import { IOnboardingItem } from '@settings/onboarding/types/IOnboardingItem';

type OnboardingColumnsProps = {
  onEditOnboardingItem: (item: IOnboardingItem) => void;
  onHideOnboardingItem: (item: IOnboardingItem) => void;
};

export const onboardingColumns = ({
  onEditOnboardingItem,
  onHideOnboardingItem,
}: OnboardingColumnsProps): ColumnsType<IOnboardingItem> => {
  return [
    {
      align: 'center' as const,
      key: 'sort',
      width: 150,
    },
    {
      align: 'center' as const,
      dataIndex: 'order',
      width: 50,
    },
    {
      align: 'center' as const,
      dataIndex: 'imgUrl',
      render: (imgUrl: string) => <img className="max-w-[300px] max-h-[300px]" src={imgUrl} />,
      title: 'Изображение',
    },
    {
      render: (
        { id, imgUrl, order, visible }: IOnboardingItem,
        onboardingItem: IOnboardingItem,
      ) => {
        return (
          <div className="flex items-center space-x-2">
            <Button
              ghost
              icon={<PencilIcon />}
              onClick={() => onEditOnboardingItem(onboardingItem)}
              type="primary"
            />

            <Popconfirm
              cancelText="Отменить"
              icon={false}
              okText={onboardingItem.visible ? 'Скрыть' : 'Показать'}
              okType="primary"
              onConfirm={() =>
                onHideOnboardingItem({
                  id,
                  imgUrl,
                  order,
                  visible: visible ? false : true,
                })
              }
              placement="topLeft"
              title={
                <span className="text-sm">
                  {onboardingItem.visible
                    ? 'Вы уверены, что хотите скрыть изображение?'
                    : 'Вы уверены, что хотите показать изображение?'}
                </span>
              }
            >
              <Button
                ghost
                icon={onboardingItem.visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                type="primary"
              />
            </Popconfirm>
          </div>
        );
      },
      title: 'Действия',
    },
  ];
};
