import { FC } from 'react';
import { Button } from 'antd';

import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { ExerciseTabs } from '@exercises/exercises/components/molecules/ExerciseTabs';
import { useMutateAssessmentExerciseDelete } from '@exercises/levelTesting/api/useMutateAssessmentExerciseDelete';
import { useMutateAssessmentExerciseStatusUpdate } from '@exercises/levelTesting/api/useMutateAssessmentExerciseStatusUpdate';
import { IAssessmentExerciseRequest } from '@exercises/levelTesting/api/useQueryAssessmentExercise';
import { useQueryAssessmentExercises } from '@exercises/levelTesting/api/useQueryAssessmentExercises';
import { assessmentExerciseColumns } from '@exercises/levelTesting/consts/columns/assessmentExerciseColumns';
import { assessmentExerciseFilters } from '@exercises/levelTesting/consts/filters/assessmentExerciseFilters';
import {
  AssessmentExercisesPageActions,
  assessmentExercisesPageActions,
} from '@exercises/levelTesting/consts/pageActions/AssessmentExercisesPageActions';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { exercisePermissions } from '@typess/permissions/IExercisePermission';
import { checkEditPermission } from '@utils/checkEditPermission';

import { AssessmentExerciseTestCreateModal } from '../organisms/ AssessmentExerciseTestCreateModal';
import { AssessmentExerciseEditModal } from '../organisms/AssessmentExerciseEditModal';
import { AssessmentExerciseProblemCreateModal } from '../organisms/AssessmentExerciseProblemCreateModal';
import { AssessmentExerciseViewModal } from '../organisms/AssessmentExerciseViewModal';

const LevelTestingPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IAssessmentExerciseRequest,
    AssessmentExercisesPageActions
  >(assessmentExerciseFilters, assessmentExercisesPageActions);
  const canEditExercises = checkEditPermission(exercisePermissions);

  const exercises = useQueryAssessmentExercises(filtered);
  const exercisePause = useMutateAssessmentExerciseStatusUpdate();
  const exerciseDelete = useMutateAssessmentExerciseDelete();

  return (
    <div>
      <PageMeta selectedMenuKeys={['exercises']} title="Тестирование уровня" />

      <ExerciseTabs active="level-testing" />
      {canEditExercises && (
        <div className="flex justify-end gap-3">
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'createTest' })}
            size="large"
          >
            Создать тест
          </Button>

          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'createProblem' })}
            size="large"
            type="primary"
          >
            Загрузить PGN
          </Button>
        </div>
      )}

      <AdminTable
        columns={assessmentExerciseColumns({
          canEdit: canEditExercises,
          onClickDelete: exerciseDelete.mutateAsync,
          onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
          onClickPause: exercisePause.mutateAsync,
          onClickView: ({ id }) => setActioned({ action: 'view', actionId: id }),
        })}
        emptyDescription={
          <div>
            <p>Задачи не найдены.</p>
            <p>Вы можете создать задачу, нажав на кнопку “Загрузить PGN” или “Создать тест”</p>
          </div>
        }
        query={exercises}
        tableFilter={{ filtered, filters: assessmentExerciseFilters, onFilter }}
      />

      <AssessmentExerciseTestCreateModal
        isOpen={actioned.action == 'createTest'}
        onCancel={clearParams}
      />

      <AssessmentExerciseProblemCreateModal
        isOpen={actioned.action == 'createProblem'}
        onCancel={clearParams}
      />

      <AssessmentExerciseViewModal
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onCancel={clearParams}
        openEdit={() => setActioned({ action: 'edit', actionId: actioned.actionId })}
      />

      <AssessmentExerciseEditModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
        openView={() => setActioned({ action: 'view', actionId: actioned.actionId })}
      />
    </div>
  );
};

export default LevelTestingPage;
