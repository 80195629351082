import { Dispatch, FC, SetStateAction } from 'react';
import { Button, Card, Empty, Pagination, Popconfirm, Select, Skeleton, Tag } from 'antd';
import Search from 'antd/lib/input/Search';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';

import { CountryImageText } from '@components/atoms/CountryImageText';
import { TagsPageActions } from '@tags/consts/TagsPageActions';
import { AuthorRankEnum } from '@tags/types/AuthorRankEnum';
import { tagPermissions } from '@typess/permissions/ITagPermission';
import { checkEditPermission } from '@utils/checkEditPermission';

import { useMutateTagDelete } from '../../api/useMutateTagDelete';
import { ITagsRequest, useQueryTags } from '../../api/useQueryTags';
import { TagTypeEnumKeys } from '../../types/TagTypeEnum';
import { TagAuthorCreateModal } from './TagAuthorCreateModal';
import { TagTopicCreateModal } from './TagTopicCreateModal';
import { TagViewModal } from './TagViewModal';

type TagsTabType = {
  filtered: Partial<ITagsRequest>;
  onFilter: (key) => void;
  paramed: Partial<TagsPageActions>;
  setParamed: Dispatch<SetStateAction<Partial<TagsPageActions>>>;
  type: TagTypeEnumKeys;
};

export const TabTags: FC<TagsTabType> = ({ filtered, onFilter, paramed, setParamed, type }) => {
  const tags = useQueryTags({
    ...filtered,
    authorRank: type == 'AUTHOR' ? filtered['authorRank'] : undefined,
    type,
  });
  const tagDelete = useMutateTagDelete();

  const canEditTags = checkEditPermission(tagPermissions);

  const tagBlocks = () =>
    tags.data?.data.content.map((tag) => (
      <Tag
        className={`${canEditTags ? 'pr-0 inline-flex' : ''}`}
        closable={canEditTags}
        closeIcon={
          <Popconfirm
            cancelText="Отменить"
            icon={<DeleteOutlined className="text-ant-red-5" color="red" />}
            okText="Удалить"
            okType="danger"
            onConfirm={() => tagDelete.mutateAsync({ id: tag.id })}
            title={
              <>
                <p className="text-sm">
                  Вы уверены, что хотите удалить тег {type == 'AUTHOR' ? 'автора' : 'темы'}?
                </p>
                <p className="mb-0">
                  Тег <span className="font-bold"> &quot;{tag.localizations.en}&quot;</span> будет
                  удален со всех мест, где были привязки по тегам.
                </p>
              </>
            }
          >
            <CloseOutlined className="px-1" />
          </Popconfirm>
        }
        key={tag.id}
        onClick={() => setParamed({ action: 'view', actionId: tag.id, type })}
        onClose={(e) => {
          e.preventDefault();
        }}
        style={{ cursor: 'pointer' }}
      >
        <div className="grid">
          {['en', 'ru'].map((locale) => (
            <div key={locale}>
              <CountryImageText className="inline-block pr-1" src={locale} />
              {type == 'AUTHOR' && tag.authorRank && (
                <span className="font-bold text-ant-red-7 mr-1">{tag.authorRank}</span>
              )}
              {tag.localizations[locale]}
            </div>
          ))}
        </div>
      </Tag>
    ));

  return (
    <div className={`grid grid-cols-1 md:grid-cols-${canEditTags ? '3' : '1'} gap-3 max-h`}>
      <div>
        {canEditTags && (
          <Card bordered={false}>
            <h2 className="font-semibold text-xl">
              Создание {type == 'AUTHOR' ? 'автора' : 'темы'}
            </h2>

            <div className="text-center mt-8">
              <Button
                block
                className="max-w-sm"
                disabled={!canEditTags}
                onClick={() => setParamed({ action: 'create', type: type })}
                size="large"
                type="primary"
              >
                Создать
              </Button>
            </div>
          </Card>
        )}
        <div className={canEditTags ? 'md:text-center mt-4' : ''}>
          <Pagination
            defaultCurrent={
              typeof filtered.page == 'string' ? parseInt(filtered.page) : filtered.page
            }
            onChange={(page) => onFilter({ page: page })}
            pageSize={filtered.pageSize}
            responsive
            showSizeChanger={false}
            total={tags.data?.data.totalElements || 1}
          />
        </div>
      </div>
      <div className="md:col-span-2 gap-y-16">
        <Card bordered={false}>
          <h2 className="font-semibold text-xl">{type == 'AUTHOR' ? 'Авторы' : 'Темы'}</h2>

          <Search
            addonBefore={
              type == 'AUTHOR' && (
                <Select
                  allowClear
                  onChange={(rank) => onFilter({ authorRank: rank })}
                  options={Object.keys(AuthorRankEnum).map((rank) => ({
                    label: rank,
                    value: rank,
                  }))}
                  placeholder="Звание"
                  value={filtered['authorRank']}
                />
              )
            }
            defaultValue={filtered['name']}
            onSearch={(search) => {
              onFilter({ name: search, page: 1 });
            }}
            placeholder={type == 'AUTHOR' ? 'Имя автора' : 'Название темы'}
          />

          <div className="mt-4">
            {tags.isLoading ? (
              <Skeleton active paragraph={{ rows: 3 }} />
            ) : tags.data?.data.totalElements ? (
              <div className="space-y-2"> {tagBlocks()}</div>
            ) : (
              <Empty
                description={
                  filtered['name'] || filtered['authorRank'] ? (
                    <p>По вашему поиску ничего не найдено</p>
                  ) : (
                    <p>
                      Здесь пока ничего нет. <br />
                      Вы можете создать {type == 'AUTHOR' ? 'автора' : 'тему'}, нажав на кнопку
                      “Создать”
                    </p>
                  )
                }
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
          </div>
        </Card>
      </div>

      {type == 'AUTHOR' ? (
        <TagAuthorCreateModal
          isOpen={paramed.action == 'create' && paramed.type == 'AUTHOR'}
          onCancel={() => setParamed({ type })}
        />
      ) : (
        <TagTopicCreateModal
          isOpen={paramed.action == 'create' && paramed.type == 'TOPIC'}
          onCancel={() => setParamed({ type })}
        />
      )}

      <TagViewModal
        id={paramed.actionId}
        isOpen={paramed.action == 'view'}
        onCancel={() => setParamed({ type })}
        type={type}
      />
    </div>
  );
};
