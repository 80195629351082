import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum AvatarGroupsPageActionEnum {
  'edit',
  'view',
  'createAvatar',
  'createAvatarGroup',
}

export type IAvatarGroupsPageActions = {
  action: keyof typeof AvatarGroupsPageActionEnum;
  actionId: number;
};

export const avatarGroupsPageActions: IPageActions<IAvatarGroupsPageActions> = {
  action: {
    allowed: (value) => Object.keys(AvatarGroupsPageActionEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
};
