import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { versionsQueryKeys } from '@settings/versions/api/versionsQueryKeys';
import { notify } from '@utils/notify';

type VersionDeleteRequest = { id: number };

type VersionDeleteResponse = AxiosResponse<void>;

export const versionDeleteRequest = async ({
  id,
}: VersionDeleteRequest): Promise<VersionDeleteResponse> => {
  return await axios.delete(`/v2/mobile-versions/${id}`);
};

export function useMutateVersionDelete() {
  const queryClient = useQueryClient();

  return useMutation<VersionDeleteResponse, AxiosError, VersionDeleteRequest>(
    versionDeleteRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(versionsQueryKeys.versions);

        notify('error', 'Версия удалена');
      },
    },
  );
}
