import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, number, object, ObjectSchema, string } from 'yup';

import { chessPiecesQueryKeys } from '@collections/chess-pieces/api/chessPiecesQueryKeys';
import { ChessPiecesSetStatusEnumKeys } from '@collections/chess-pieces/types/enums/ChessPiecesSetEnum';

import { chessPiecesGroupQueryKeys } from './chessPiecesGroupQueryKeys';
import { IChessPiecesGroupCreateRequest } from './useMutateChessPiecesGroupCreate';

export type IChessPiecesGroupEditRequest = Partial<IChessPiecesGroupCreateRequest> & {
  id: number;
  priority: number;
};

type IChessPiecesGroupEditResponse = AxiosResponse<void>;

export const chessPiecesGroupEditValidation: ObjectSchema<IChessPiecesGroupEditRequest> = object({
  id: number().required(),
  items: array(),
  localizations: object({
    en: object({ name: string().required() }),
    ru: object({ name: string().required() }),
  }),
  priority: number().required(),
  status: string<ChessPiecesSetStatusEnumKeys>().nullable().required(),
});

export const chessPiecesGroupEditRequest = async (
  payload: IChessPiecesGroupEditRequest,
): Promise<IChessPiecesGroupEditResponse> =>
  await axios.patch(`/chess-piece-set-groups/${payload.id}`, payload);

export function useMutateChessPiecesGroupEdit() {
  const queryClient = useQueryClient();

  return useMutation<IChessPiecesGroupEditResponse, AxiosError, IChessPiecesGroupEditRequest>(
    chessPiecesGroupEditRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(chessPiecesGroupQueryKeys.chessPiecesGroup);
        queryClient.invalidateQueries(chessPiecesGroupQueryKeys.chessPiecesGroups);
        queryClient.invalidateQueries(chessPiecesQueryKeys.chessPieces);
        queryClient.invalidateQueries(chessPiecesQueryKeys.chessPiecesById);
      },
    },
  );
}
