import { FC, useMemo } from 'react';
import { Button } from 'antd';

import { useMutateBoardDelete } from '@collections/boards/api/useMutateBoardDelete';
import { useMutateBoardStatusUpdate } from '@collections/boards/api/useMutateBoardStatusUpdate';
import { IBoardsRequest, useQueryBoards } from '@collections/boards/api/useQueryBoards';
import { boardColumns } from '@collections/boards/consts/columns/boardColumns';
import {
  boardPageActions,
  IBoardsPageActions,
} from '@collections/boards/consts/pageActions/IBoardsPageActions';
import { boardFilters } from '@collections/boards/filters/boardFilters';
import { CollectionTabs } from '@collections/components/molecules/CollectionTabs';
import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';

import { CreateBoardModal } from '../organisms/CreateBoardModal';
import { EditBoardModal } from '../organisms/EditBoardModal';
import { ViewBoardModal } from '../organisms/ViewBoardModal';

export const BoardsPage: FC = () => {
  const deleteBoardMutation = useMutateBoardDelete();
  const boardStatusUpdate = useMutateBoardStatusUpdate();

  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IBoardsRequest,
    IBoardsPageActions
  >(boardFilters, boardPageActions);

  const boardsQuery = useQueryBoards(filtered);

  const title = useMemo(() => {
    if (actioned.action === 'create') {
      return 'Создать доску';
    } else if (actioned.action === 'view') {
      return `Доска ${actioned.actionId}`;
    }

    return 'Доска';
  }, [actioned.action]);

  return (
    <>
      <PageMeta selectedMenuKeys={['collections']} title={title} />
      <CollectionTabs active="board" />
      <div className="flex justify-end gap-4 mb-4">
        <Button
          className="sm:px-12"
          onClick={() => setActioned({ action: 'create' })}
          size="large"
          type="primary"
        >
          Создать доску
        </Button>
      </div>

      <CreateBoardModal isOpen={actioned.action == 'create'} onClose={clearParams} />

      <EditBoardModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onClose={() => {
          clearParams();
        }}
      />

      <ViewBoardModal
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onClose={() => {
          clearParams();
        }}
      />
      <AdminTable
        columns={boardColumns({
          onClickDelete: deleteBoardMutation.mutateAsync,
          onClickPause: boardStatusUpdate.mutateAsync,
          onEditBoard: (board) => {
            setActioned({ action: 'edit', actionId: board.id });
          },
          onOpenBoard: (board) => {
            setActioned({ action: 'view', actionId: board.id });
          },
        })}
        query={boardsQuery}
        tableFilter={{ filtered, filters: boardFilters, onFilter }}
      />
    </>
  );
};
