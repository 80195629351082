import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { tagQueryKeys } from '@tags/api/tagQueryKeys';
import { notify } from '@utils/notify';

type ITagDeleteRequest = { id: number };

type ITagDeleteResponse = AxiosResponse<void>;

export const tagDeleteRequest = async (params: ITagDeleteRequest): Promise<ITagDeleteResponse> =>
  await axios.delete(`/tags/${params.id}`);

export function useMutateTagDelete() {
  const queryClient = useQueryClient();
  return useMutation<ITagDeleteResponse, AxiosError, ITagDeleteRequest>(tagDeleteRequest, {
    onSuccess: async (data, variables) => {
      notify('success', 'Тег успешно удален');

      await queryClient.invalidateQueries(tagQueryKeys.tags);
      await queryClient.invalidateQueries([tagQueryKeys.tag, { id: variables.id }]);
    },
  });
}
