import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { exerciseGroupQueryKeys } from '@exercises/exerciseGroups/api/exerciseGroupQueryKeys';
import { IExerciseGroup } from '@exercises/exerciseGroups/types/IExerciseGroup';

export type IExerciseGroupRequest = { id: number };
type IExerciseGroupResponse = AxiosResponse<IExerciseGroup>;

export const exerciseGroupRequest = async (
  params: IExerciseGroupRequest,
): Promise<IExerciseGroupResponse> => await axios.get(`/exercise-groups/${params.id}`);

export function useQueryExerciseGroup(params: IExerciseGroupRequest) {
  return useQuery<IExerciseGroupResponse, AxiosError>(
    [exerciseGroupQueryKeys.exerciseGroup, params],
    () => exerciseGroupRequest(params),
    { enabled: params.id > 0 },
  );
}
