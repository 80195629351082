import { FC, useState } from 'react';
import { Col, DatePicker, List, Row, Typography } from 'antd';
import { DatePickerProps } from 'antd/lib';
import dayjs, { Dayjs } from 'dayjs';

import { PageMeta } from '@context/PageMetaContext';
import { useQueryPayingUsersStats } from '@dashboards/api/useQueryPayingUsersStats';

import { DashboardTabs } from '../molecules/DashboardTabs';

const PayingUsersCohortDashboardPage: FC = () => {
  const [startDate, setStartDate] = useState<Dayjs>(dayjs('2023/10/20'));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const { data: payingUsersStats } = useQueryPayingUsersStats({
    from: startDate?.format('YYYY-MM-DD HH:mm:ss'),
    to: endDate?.format('YYYY-MM-DD HH:mm:ss'),
  });

  const disabledStartDate: DatePickerProps['disabledDate'] = (current) => {
    return (
      current &&
      (current <= dayjs('2022/09/25').endOf('day') || current > dayjs(endDate).endOf('day'))
    );
  };

  const disabledEndDate: DatePickerProps['disabledDate'] = (current) => {
    return current && (current <= dayjs(startDate).endOf('day') || current > dayjs().endOf('day'));
  };

  const data = [
    {
      count: payingUsersStats?.data.payingCustomers,
      title: 'All Customers',
    },
    {
      count: payingUsersStats?.data.registeredUsers,
      title: 'Registered users',
    },
    {
      count: payingUsersStats?.data.activeSubscriptions,
      title: 'Active Subs',
    },
    {
      count: payingUsersStats?.data.activeTrialSubscriptions,
      title: 'Trials',
    },
    {
      count: payingUsersStats?.data.coinPurchases,
      title: 'Coin Purchases',
    },
    {
      count: payingUsersStats?.data.revenue,
      title: 'Revenue',
    },
    {
      count: payingUsersStats?.data.arppu,
      title: 'ARPPU',
    },
  ];

  return (
    <>
      <PageMeta selectedMenuKeys={['dashboards']} title="Аналитика" />
      <DashboardTabs active="paying-users-stats" />
      <Typography className="font-bold text-[20px] mb-6">Cohort analysis</Typography>
      <div className="flex flex-row items-center mb-6">
        <DatePicker
          defaultValue={startDate}
          disabledDate={disabledStartDate}
          onChange={(v) => v && setStartDate(v)}
          placeholder="from"
        />
        <Typography className="text-gray-700 mx-2">—</Typography>
        <DatePicker
          defaultValue={endDate}
          disabledDate={disabledEndDate}
          onChange={(v) => v && setEndDate(v)}
          placeholder="to"
        />
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row items-start mb-6">
          <List
            bordered
            className="bg-white w-[380px] mr-6"
            dataSource={data}
            itemLayout="horizontal"
            renderItem={(item, index) => (
              <List.Item className="w-full">
                <Row className="w-full">
                  <Col className={`${index === 0 ? 'font-bold' : 'font-normal'}`} span={12}>
                    {item.title}
                  </Col>
                  <Col className={`${index === 0 ? 'font-bold' : 'font-normal'}`} span={12}>
                    {item.count}
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default PayingUsersCohortDashboardPage;
