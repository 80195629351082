import { FC } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';

import {
  IStaffInviteRequest,
  staffInviteInitial,
  staffInviteValidation,
  useMutateStaffInvite,
} from '@staff/api/useMutateStaffInvite';
import { StaffAuthoritySelects } from '@staff/components/molecules/StaffAuthoritySelects';
import { staffKeys } from '@staff/types/IStaff';
import { IBackendError } from '@typess/IBackendError';

export const StaffInviteModal: FC<{ isOpen: boolean; onCancel: () => void }> = ({
  isOpen,
  onCancel,
}) => {
  const invite = useMutateStaffInvite();

  return (
    <Modal
      className="px-4 md:px-0 w-full md:w-3/4 lg:w-2/3 top-8"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={<h1 className="text-xl m-0">Новый персонал</h1>}
    >
      <Formik<IStaffInviteRequest>
        enableReinitialize
        initialValues={staffInviteInitial}
        onSubmit={(values, { resetForm, setErrors }) => {
          invite
            .mutateAsync({
              authorities: values[staffKeys.authorities].filter(
                (authority) => authority != 'block',
              ),
              email: values.email,
            })
            .then(() => {
              onCancel();
              resetForm();
            })
            .catch((error: AxiosError<IBackendError>) => {
              if (
                error.response?.status == 422 &&
                error.response.data.message == 'User.alreadyExist'
              ) {
                setErrors({ email: 'Персонал с данным E-mail уже существует' });
              }
            });
        }}
        validationSchema={staffInviteValidation}
      >
        {({ values }) => {
          const disabledSubmit = !values[staffKeys.authorities].filter(
            (authority) => authority && authority != 'block',
          ).length;

          return (
            <Form className="space-y-8" layout="vertical" noValidate>
              <Form.Item initialValue="block" label="Почта" name={staffKeys.email}>
                <Input
                  name={staffKeys.email}
                  placeholder="example@example.com"
                  suffix
                  type="email"
                />
              </Form.Item>

              <StaffAuthoritySelects />

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                <Button block onClick={onCancel}>
                  Отменить
                </Button>

                <Tooltip
                  className="text-center"
                  title={disabledSubmit && 'Персонал должен иметь минимум 1 роль'}
                >
                  <Button
                    block
                    disabled={disabledSubmit}
                    htmlType="submit"
                    loading={invite.isLoading}
                    type="primary"
                  >
                    Отправить приглашение
                  </Button>
                </Tooltip>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
