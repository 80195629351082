import {
  ChessPiecesSetStatusEnumKeys,
  ChessPiecesSetTypeEnumKeys,
} from './enums/ChessPiecesSetEnum';
import { IChessPiece } from './IChessPiece';

export interface IChessPiecesSet {
  chessPieces: IChessPiece[];
  createdDate?: string;
  id: number;
  imgUrl: string;
  imgUrlPng: string;
  localizations: Record<'en' | 'ru', { name: string }>;
  price?: number;
  status: ChessPiecesSetStatusEnumKeys;
  type: ChessPiecesSetTypeEnumKeys;
  updatedDate?: string;
}

export const chessPiecesSetKeys: Record<keyof IChessPiecesSet, string> = {
  chessPieces: 'chessPieces',
  createdDate: 'createdDate',
  id: 'id',
  imgUrl: 'imgUrl',
  imgUrlPng: 'imgUrlPng',
  localizations: 'localizations',
  price: 'price',
  status: 'status',
  type: 'type',
  updatedDate: 'updatedDate',
};
