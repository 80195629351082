import { Description } from '@components/atoms/Description';
import { AdminTableSelfManaged } from '@components/organisms/adminTableSelfManaged/AdminTable';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { Filter } from '@typess/IFilters';
import { useQueryUserBrainstorms } from '@users/api/useQueryUserBrainstorms';
import { useQueryUserCoinPurchases } from '@users/api/useQueryUserCoinPurchases';
import { useQueryUserGameMatches } from '@users/api/useQueryUserGameMatches';
import { useQueryUserMapStages } from '@users/api/useQueryUserMapStages';
import { userBrainStormColumns } from '@users/consts/columns/userBrainStormColumns';
import { userGameMatchesColumns } from '@users/consts/columns/userGameMatchesColumns';
import { userMapStagesColumns } from '@users/consts/columns/userMapStagesColumns';
import { userPurchasesColumns } from '@users/consts/columns/userPurchasesColumns';
import { userGameMatchesFilters } from '@users/consts/filters/userGameMatchesFilters';
import { userMapStagesFilters } from '@users/consts/filters/userMapStagesFilters';
import { userPurchasesFilters } from '@users/consts/filters/userPurchasesFilters';
import { UserPageActions, userPageActions } from '@users/consts/pageActions/UserPageActions';
import { UserSkillTypeEnum } from '@users/types/enums/UserSkillTypeEnum';
import { IUser } from '@users/types/IUser';
import { IUserGameMatch } from '@users/types/IUserGameMatch';
import { IUserStats } from '@users/types/IUserStats';

import { UserBrainstormModal } from '../organisms/UserBrainstormModal';

export const UserPurchasesTab = ({ userId }: { userId: number }) => (
  <AdminTableSelfManaged
    columns={userPurchasesColumns}
    filters={userPurchasesFilters}
    query={useQueryUserCoinPurchases}
    queryData={{
      id: userId,
      sortBy: 'id',
      sortDirection: 'DESC',
    }}
  />
);

export const UserMapStagesTab = ({ userId }: { userId: number }) => (
  <AdminTableSelfManaged
    columns={userMapStagesColumns}
    filters={userMapStagesFilters}
    query={useQueryUserMapStages}
    queryData={{
      id: userId,
      sortBy: 'stageNumber',
      sortDirection: 'DESC',
    }}
    rowKey="stageNumber"
  />
);

export const UserGamesTab = ({
  onOpenHistoryModal,
  stats,
  userId,
}: {
  onOpenHistoryModal: (record: IUserGameMatch) => void;
  stats?: IUserStats;
  userId: number;
}) => {
  return (
    <>
      <div className="grid grid-cols-3">
        <Description description={stats?.gameMatchWinCount} title="Победа" vertical />
        <Description description={stats?.gameMatchDrawCount} title="Ничья" vertical />
        <Description description={stats?.gameMatchLossCount} title="Поражение" vertical />
      </div>

      <AdminTableSelfManaged
        columns={userGameMatchesColumns({
          onOpenHistoryModal: onOpenHistoryModal,
        })}
        filters={userGameMatchesFilters}
        query={useQueryUserGameMatches}
        queryData={{
          id: userId,
          sortBy: 'id',
          sortDirection: 'DESC',
        }}
      />
    </>
  );
};

export const UserSkillsTab = ({ skillStats }: { skillStats: IUser['skillStats'] }) => {
  return (
    <div className="grid grid-cols-3 gap-5">
      {skillStats.map(({ practiceScore, skillType, theoryScore, totalScore }) => (
        <div className="p-3 border rounded-lg border-ant-blue-4 grid gap-3" key={skillType}>
          <h2 className="border-b text-base text-center">{UserSkillTypeEnum[skillType]}</h2>
          <span>Теория - {theoryScore}</span>
          <span>Практика - {practiceScore}</span>
          <span>Общий - {totalScore}</span>
        </div>
      ))}
    </div>
  );
};

export const UserBrainstormTab = ({ userId }: { userId: number }) => {
  const { actioned, clearParams, setActioned } = useUrlFilteredActioned<Filter, UserPageActions>(
    paginationBaseFilters,
    userPageActions,
  );
  return (
    <>
      <AdminTableSelfManaged
        columns={userBrainStormColumns({
          onClickView: ({ id }) => setActioned({ action: 'viewBrainstorm', actionId: id }),
        })}
        filters={userGameMatchesFilters}
        query={useQueryUserBrainstorms}
        queryData={{
          customerId: userId,
          sortBy: 'id',
          sortDirection: 'DESC',
        }}
      />
      <UserBrainstormModal
        id={actioned.actionId ?? 0}
        isOpen={actioned.action == 'viewBrainstorm'}
        onCancel={clearParams}
      />
    </>
  );
};
