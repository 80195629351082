import { useMutation } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

type IPromocodeGroupDownloadRequest = { id: number };

type IPromocodeGroupDownloadResponse = AxiosResponse<Blob>;

export const promocodeGroupDownloadRequest = async (
  payload: IPromocodeGroupDownloadRequest,
): Promise<IPromocodeGroupDownloadResponse> => {
  return await axios.get(`/promocode-groups/${payload.id}/export`, {
    responseType: 'blob',
  });
};

export function useMutatePromocodeGroupDownload() {
  return useMutation<IPromocodeGroupDownloadResponse, AxiosError, IPromocodeGroupDownloadRequest>(
    promocodeGroupDownloadRequest,
    {
      onSuccess: (data, variables) => {
        saveAs(
          data.data,
          data.headers['content-disposition']?.split('"')[1] ??
            `Promocodes group #${variables.id}.xlsx`,
        );
      },
    },
  );
}
