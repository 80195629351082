import { useMutation, useQueryClient } from 'react-query';
import { RcFile } from 'antd/lib/upload';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, boolean, mixed, number, object, ObjectSchema, string } from 'yup';

import { exerciseGroupQueryKeys } from '@exercises/exerciseGroups/api/exerciseGroupQueryKeys';
import { exerciseQueryKeys } from '@exercises/exercises/api/exerciseQueryKeys';
import { ExerciseGamePhaseEnumKeys } from '@exercises/exercises/types/enums/ExerciseGamePhaseEnum';
import { ExerciseStatusEnumKeys } from '@exercises/exercises/types/enums/ExerciseStatusEnum';
import { IUserPlayLevelNumber } from '@users/types/enums/UserPlayLevel';

export type IExerciseProblemCreateRequest = {
  file?: Partial<RcFile>;
  json: {
    checked: boolean;
    gamePhase: ExerciseGamePhaseEnumKeys;
    groupName: string;
    isCapitalization?: boolean;
    isPawnStructure: boolean;
    isTactic: boolean;
    playLevels: IUserPlayLevelNumber[];
    status: ExerciseStatusEnumKeys;
    useForPuzzleRush: boolean;
    videoId?: number | null;
  };
};

export const exerciseProblemCreateInitial: IExerciseProblemCreateRequest = {
  file: undefined,
  json: {
    checked: false,
    gamePhase: 'MIDDLEGAME',
    groupName: '',
    isCapitalization: false,
    isPawnStructure: false,
    isTactic: false,
    playLevels: [],
    status: 'ACTIVE',
    useForPuzzleRush: true,
  },
};

type IExerciseProblemCreateResponse = AxiosResponse<void>;

export const exerciseProblemCreateValidation: ObjectSchema<IExerciseProblemCreateRequest> = object({
  file: mixed<RcFile>().required('PGN обязателен для заполнения'),
  json: object({
    checked: boolean().required(''),
    gamePhase: string<ExerciseGamePhaseEnumKeys>().required(),
    groupName: string().nullable().required(),
    isCapitalization: boolean(),
    isPawnStructure: boolean().required(''),
    isTactic: boolean().required(''),
    playLevels: array().required('').min(1, 'Должно содержать минимум 1 уровень'),
    status: string<ExerciseStatusEnumKeys>().required('Статус обязателен для заполнения'),
    useForPuzzleRush: boolean().required(),
    videoId: number().nullable(),
  }),
});

export const exerciseProblemCreateRequest = async (
  payload: IExerciseProblemCreateRequest,
): Promise<IExerciseProblemCreateResponse> => {
  const formData = new FormData();
  formData.append('file', payload.file as Blob);
  formData.append(
    'json',
    new Blob([JSON.stringify(payload.json)], {
      type: 'application/json',
    }),
  );

  return await axios.post(`/exercises/problems/upload`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export function useMutateExerciseProblemCreate() {
  const queryClient = useQueryClient();

  return useMutation<IExerciseProblemCreateResponse, AxiosError, IExerciseProblemCreateRequest>(
    exerciseProblemCreateRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(exerciseQueryKeys.exercises);
        queryClient.invalidateQueries(exerciseGroupQueryKeys.exercisesGroups);
      },
    },
  );
}
