export const paginationBaseFilters = {
  page: {
    queries: [{ default: 1 }],
  },
  pageSize: {
    queries: [
      {
        allowed: (value) => {
          return ['10', '20', '50', '100'].includes(value);
        },
        default: 10,
      },
    ],
  },
  sortBy: { queries: [{ default: 'id' }] },
  sortDirection: { queries: [{ default: 'DESC' }] },
};
