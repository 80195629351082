import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema } from 'yup';

import { chessPiecesQueryKeys } from '@collections/chess-pieces/api/chessPiecesQueryKeys';
import { notify } from '@utils/notify';

import { chessPiecesGroupQueryKeys } from './chessPiecesGroupQueryKeys';

export type IChessPiecesGroupAddItemRequest = { chessPieceSetId?: number; id: number };

type IChessPiecesGroupAddItemResponse = AxiosResponse<void>;

export const chessPiecesGroupAddItemInitialValue: IChessPiecesGroupAddItemRequest = {
  chessPieceSetId: undefined,
  id: 0,
};

export const chessPiecesGroupAddItemValidation: ObjectSchema<IChessPiecesGroupAddItemRequest> =
  object({
    chessPieceSetId: number().required(),
    id: number().required(),
  });
export const chessPiecesGroupAddItemRequest = async ({
  chessPieceSetId,
  id,
}: IChessPiecesGroupAddItemRequest): Promise<IChessPiecesGroupAddItemResponse> =>
  await axios.post(`/chess-piece-set-groups/${id}/item/${chessPieceSetId}`);

export function useMutateChessPiecesGroupAddItem() {
  const queryClient = useQueryClient();

  return useMutation<IChessPiecesGroupAddItemResponse, AxiosError, IChessPiecesGroupAddItemRequest>(
    chessPiecesGroupAddItemRequest,
    {
      onSuccess: () => {
        notify('success', 'Фигуры добавлены в группу');
        queryClient.invalidateQueries(chessPiecesGroupQueryKeys.chessPiecesGroup);
        queryClient.invalidateQueries(chessPiecesQueryKeys.chessPieces);
      },
    },
  );
}
