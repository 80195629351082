export type IPreSubscriber = {
  createdDate: Date;
  email: string;
  updatedDate?: Date;
};

export const preSubscriberKeys: Record<keyof IPreSubscriber, string> = {
  createdDate: 'createdDate',
  email: 'email',
  updatedDate: 'updatedDate',
};
