import { FC } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useMutateExerciseDelete } from '@exercises/exercises/api/useMutateExerciseDelete';
import { useMutateExerciseStatusUpdate } from '@exercises/exercises/api/useMutateExerciseStatusUpdate';
import { IExercisesRequest, useQueryExercises } from '@exercises/exercises/api/useQueryExercises';
import { ExerciseTabs } from '@exercises/exercises/components/molecules/ExerciseTabs';
import { ExerciseEditModal } from '@exercises/exercises/components/organisms/ExerciseEditModal';
import { ExerciseProblemCreateModal } from '@exercises/exercises/components/organisms/ExerciseProblemCreateModal';
import { ExerciseTestCreateModal } from '@exercises/exercises/components/organisms/ExerciseTestCreateModal';
import { ExerciseViewModal } from '@exercises/exercises/components/organisms/ExerciseViewModal';
import { exerciseColumns } from '@exercises/exercises/consts/columns/exerciseColumns';
import { exerciseFilters } from '@exercises/exercises/consts/filters/exerciseFilters';
import {
  ExercisesPageActions,
  exercisesPageActions,
} from '@exercises/exercises/consts/pageActions/ExercisesPageActions';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { exercisePermissions } from '@typess/permissions/IExercisePermission';
import { checkEditPermission } from '@utils/checkEditPermission';

import { ExerciseCustomGameModal } from '../organisms/ExerciseCustomGameModal';

const ExercisesPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IExercisesRequest,
    ExercisesPageActions
  >(exerciseFilters, exercisesPageActions);

  const exercises = useQueryExercises(filtered);
  const exercisePause = useMutateExerciseStatusUpdate();
  const exerciseDelete = useMutateExerciseDelete();

  const canEditExercises = checkEditPermission(exercisePermissions);

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setActioned({ action: 'createTest' })}>
        Создать тест
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setActioned({ action: 'createCustomGame' })}>
        Кастомное упражнение
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <PageMeta
        selectedMenuKeys={['exercises']}
        subTitle={
          actioned.action == 'createTest'
            ? 'Создать тест'
            : actioned.action == 'createProblem'
            ? 'Загрузить PGN'
            : actioned.action == 'view'
            ? `Просмотр задачи #${actioned.actionId}`
            : actioned.action == 'edit'
            ? `Изменить задачу #${actioned.actionId}`
            : ''
        }
        title="Задачи"
      />

      <ExerciseTabs active="exercises" />

      {canEditExercises && (
        <div className="flex justify-end gap-3">
          <Dropdown overlay={menu} trigger={['click']}>
            <Button className="sm:px-12" size="large">
              Создать <DownOutlined />
            </Button>
          </Dropdown>
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'createProblem' })}
            size="large"
            type="primary"
          >
            Загрузить PGN
          </Button>
        </div>
      )}

      <AdminTable
        columns={exerciseColumns({
          canEdit: canEditExercises,
          onClickDelete: exerciseDelete.mutateAsync,
          onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
          onClickPause: exercisePause.mutateAsync,
          onClickView: ({ id }) => setActioned({ action: 'view', actionId: id }),
        })}
        emptyDescription={
          <div>
            <p>Задачи не найдены.</p>
            <p>Вы можете создать задачу, нажав на кнопку “Загрузить PGN” или “Создать тест”</p>
          </div>
        }
        query={exercises}
        tableFilter={{ filtered, filters: exerciseFilters, onFilter }}
      />

      <ExerciseTestCreateModal isOpen={actioned.action == 'createTest'} onCancel={clearParams} />

      <ExerciseProblemCreateModal
        isOpen={actioned.action == 'createProblem'}
        onCancel={clearParams}
      />

      <ExerciseCustomGameModal
        isOpen={actioned.action == 'createCustomGame'}
        onCancel={clearParams}
      />

      <ExerciseViewModal
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onCancel={clearParams}
        openEdit={() => setActioned({ action: 'edit', actionId: actioned.actionId })}
      />

      <ExerciseEditModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
        openView={() => setActioned({ action: 'view', actionId: actioned.actionId })}
      />
    </div>
  );
};

export default ExercisesPage;
