import { FC } from 'react';
import { FormItem, Select } from 'formik-antd';

import { staffPermissionOptions } from '@staff/utils/staffPermissionOptions';
import { dashboardsPermission } from '@typess/permissions/IAnalitycsPermission';
import { exercisePermissions } from '@typess/permissions/IExercisePermission';
import { mapStagePermissions } from '@typess/permissions/IMapStagePermission';
import { settingPermissions } from '@typess/permissions/ISettingPermission';
import { shopPermissions } from '@typess/permissions/IShopPermission';
import { staffPermissions } from '@typess/permissions/IStaffPermission';
import { tagPermissions } from '@typess/permissions/ITagPermission';
import { userPermissions } from '@typess/permissions/IUserPermission';
import { videoPermissions } from '@typess/permissions/IVideoPermission';

export const StaffAuthoritySelects: FC = () => {
  return (
    <div className="sm:grid sm:grid-cols-2 gap-x-5">
      <FormItem label="Пользователи" name="authorities.0">
        <Select name="authorities.0" {...staffPermissionOptions(userPermissions)} />
      </FormItem>

      <FormItem label="Персонал" name="authorities.1">
        <Select name="authorities.1" {...staffPermissionOptions(staffPermissions)} />
      </FormItem>

      <FormItem label="Видео" name="authorities.2">
        <Select name="authorities.2" {...staffPermissionOptions(videoPermissions)} />
      </FormItem>

      <FormItem label="Задачи" name="authorities.3">
        <Select name="authorities.3" {...staffPermissionOptions(exercisePermissions)} />
      </FormItem>

      <FormItem label="Теги" name="authorities.4">
        <Select name="authorities.4" {...staffPermissionOptions(tagPermissions)} />
      </FormItem>

      <FormItem label="Магазин" name="authorities.5">
        <Select name="authorities.5" {...staffPermissionOptions(shopPermissions)} />
      </FormItem>

      <FormItem label="Настройки" name="authorities.6">
        <Select name="authorities.6" {...staffPermissionOptions(settingPermissions)} />
      </FormItem>
      <FormItem label="Аналитика" name="authorities.7">
        <Select name="authorities.7" {...staffPermissionOptions(dashboardsPermission)} />
      </FormItem>
      <FormItem label="Конструктор уровней" name="authorities.8">
        <Select name="authorities.8" {...staffPermissionOptions(mapStagePermissions)} />
      </FormItem>
    </div>
  );
};
