import type { FC } from 'react';
import { Button, Modal, Tag } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Select } from 'formik-antd';
import { LoadingOutlined } from '@ant-design/icons';

import { IAvatar } from '@collections/avatars/types/IAvatar';
import {
  avatarGroupAddItemInitialValue,
  avatarGroupAddItemValidation,
  IAvatarGroupAddItemRequest,
  useMutateAvatarGroupAddItem,
} from '@collections/avatars-groups/api/useMutateAvatarGroupAddItem';

type Props = {
  avatarGroupId: number;
  avatarGroupName: string;
  avatars: IAvatar[];
  isGroupNameDisabled?: boolean;
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
};

export const AvatarGroupAddItemModal: FC<Props> = ({
  avatarGroupId,
  avatarGroupName,
  avatars,
  isLoading,
  isOpen,
  onClose,
}) => {
  const avatarGroupAddItemMutation = useMutateAvatarGroupAddItem();

  return (
    <Modal
      footer={null}
      onCancel={onClose}
      open={isOpen}
      title={`Добавить аватар в группу "${avatarGroupName}"`}
    >
      <Formik<IAvatarGroupAddItemRequest>
        enableReinitialize
        initialValues={avatarGroupAddItemInitialValue}
        onSubmit={(values) =>
          avatarGroupAddItemMutation.mutateAsync({ ...values, id: avatarGroupId }).then(() => {
            onClose();
          })
        }
        validateOnBlur={false}
        validationSchema={avatarGroupAddItemValidation}
      >
        {() => (
          <Form className="gap-4 md:gap-6" layout="vertical">
            <FormItem label="Аватары" name="items">
              <Select
                loading={isLoading}
                name="avatarId"
                optionFilterProp="label"
                options={
                  avatars.map((tag) => ({
                    label: tag.localizations.en.name,
                    value: tag.id,
                  })) || []
                }
                placeholder="Аватары"
                tagRender={
                  isLoading
                    ? () => (
                        <Tag>
                          <LoadingOutlined />
                        </Tag>
                      )
                    : undefined
                }
              />
            </FormItem>
            <div className="flex gap-4 justify-end">
              <Button onClick={onClose}>Отмена</Button>
              <Button
                htmlType="submit"
                loading={avatarGroupAddItemMutation.isLoading}
                type="primary"
              >
                <span className="max-w-full overflow-ellipsis overflow-hidden">Добавить</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
