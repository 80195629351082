import { IChessPiecesSet } from '@collections/chess-pieces/types/IChessPiecesSet';
import { ILocalization } from '@typess/ILocalization';

export type IChessPiecesGroup = {
  createdDate: Date;
  id: number;
  items: { chessPieceSet: IChessPiecesSet; priority: number }[];
  itemsCount?: number;
  localizations: Partial<Record<ILocalization, { name: string }>>;
  priority: number;
  status: 'DRAFT' | 'ACTIVE';
  updatedDate: Date;
};

export const chessPiecesGroupKeys: Record<keyof IChessPiecesGroup, string> = {
  createdDate: 'createdDate',
  id: 'id',
  items: 'items',
  itemsCount: 'itemsCount',
  localizations: 'localizations',
  priority: 'priority',
  status: 'status',
  updatedDate: 'updatedDate',
};
