import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema } from 'yup';

import { notify } from '@utils/notify';

import { tagQueryKeys } from './tagQueryKeys';
import { ITagAuthorCreateRequest, tagAuthorCreateValidation } from './useMutateTagAuthorCreate';

export type IUpdateTagAuthorRequest = ITagAuthorCreateRequest & {
  id: number;
};

export const updateTagAuthorValidation: ObjectSchema<IUpdateTagAuthorRequest> =
  tagAuthorCreateValidation.concat(
    object({
      id: number().required(),
    }),
  );

export const updateTagAuthorRequest = ({ id, ...payload }: IUpdateTagAuthorRequest) =>
  axios.patch(`/tags/${id}`, payload);

export const useMutateTagAuthorUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, IUpdateTagAuthorRequest>(
    updateTagAuthorRequest,
    {
      onError: () => {
        notify('error', 'Повторите попытку позже');
      },
      onSuccess: () => {
        notify('success', 'Тег автора успешно обновлен');

        queryClient.invalidateQueries(tagQueryKeys.tags);
        queryClient.invalidateQueries(tagQueryKeys.tag);
      },
    },
  );
};
