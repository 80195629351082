import { FunctionComponent } from 'react';

import {
  ChessPieceBishopIcon,
  ChessPieceKingIcon,
  ChessPieceKnight2Icon,
  ChessPiecePawnIcon,
  ChessPieceQueenIcon,
  ChessPieceRookIcon,
  ChessPieceWhiteBishopIcon,
  ChessPieceWhiteKingIcon,
  ChessPieceWhiteKnight2Icon,
  ChessPieceWhitePawnIcon,
  ChessPieceWhiteQueenIcon,
  ChessPieceWhiteRookIcon,
} from '@components/atoms/icons';

export enum ChessPiecesEnum {
  BBISHOP = 'BBISHOP',
  BKING = 'BKING',
  BKNIGHT = 'BKNIGHT',
  BPAWN = 'BPAWN',
  BQUEEN = 'BQUEEN',
  BROOK = 'BROOK',
  WBISHOP = 'WBISHOP',
  WKING = 'WKING',
  WKNIGHT = 'WKNIGHT',
  WPAWN = 'WPAWN',
  WQUEEN = 'WQUEEN',
  WROOK = 'WROOK',
}
export type ChessPiecesEnumKeys = keyof typeof ChessPiecesEnum;

export const chessPiecesSymbol: Record<ChessPiecesEnumKeys, string> = {
  BBISHOP: '♝',
  BKING: '♚',
  BKNIGHT: '♞',
  BPAWN: '♟',
  BQUEEN: '♛',
  BROOK: '♜',
  WBISHOP: '♗',
  WKING: '♔',
  WKNIGHT: '♘',
  WPAWN: '♙',
  WQUEEN: '♕',
  WROOK: '♖',
};

export const chessPiecesIcon: Record<
  ChessPiecesEnumKeys,
  FunctionComponent<React.ComponentPropsWithoutRef<'span'>>
> = {
  BBISHOP: ChessPieceBishopIcon,
  BKING: ChessPieceKingIcon,
  BKNIGHT: ChessPieceKnight2Icon,
  BPAWN: ChessPiecePawnIcon,
  BQUEEN: ChessPieceQueenIcon,
  BROOK: ChessPieceRookIcon,
  WBISHOP: ChessPieceWhiteBishopIcon,
  WKING: ChessPieceWhiteKingIcon,
  WKNIGHT: ChessPieceWhiteKnight2Icon,
  WPAWN: ChessPieceWhitePawnIcon,
  WQUEEN: ChessPieceWhiteQueenIcon,
  WROOK: ChessPieceWhiteRookIcon,
};

export enum ChessPiecesTranslationsEnum {
  BBISHOP = 'Черный Слон',
  BKING = 'Черный Король',
  BKNIGHT = 'Черный Конь',
  BPAWN = 'Черная Пешка',
  BQUEEN = 'Черный Ферзь',
  BROOK = 'Черная Ладья',
  WBISHOP = 'Белый Слон',
  WKING = 'Белый Король',
  WKNIGHT = 'Белый Конь',
  WPAWN = 'Белая Пешка',
  WQUEEN = 'Белый Ферзь',
  WROOK = 'Белая Ладья',
}
