import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';

export const SettingTabs: FC<{
  active: 'settings' | 'pre-subscribers' | 'promocodes' | 'classifier' | 'versions' | 'onboarding';
}> = ({ active }) => {
  const navigate = useNavigate();

  return (
    <Tabs
      activeKey={active}
      centered
      items={[
        { key: 'settings', label: 'Общие' },
        { key: 'pre-subscribers', label: 'Пререгистрация' },
        { key: 'promocodes', label: 'Промокоды' },
        { key: 'classifier', label: 'Классификатор' },
        { key: 'versions', label: 'Версионность приложения' },
        { key: 'onboarding', label: 'Onboarding' },
      ]}
      onChange={(tab) =>
        navigate(
          tab == 'pre-subscribers'
            ? '/settings/pre-subscribers'
            : tab == 'promocodes'
            ? '/settings/promocodes'
            : tab == 'classifier'
            ? '/settings/classifier'
            : tab == 'versions'
            ? '/settings/versions'
            : tab === 'onboarding'
            ? '/settings/onboarding'
            : '/settings',
        )
      }
    >
      <Tabs.TabPane key="settings" tab="Общие" />
      <Tabs.TabPane key="pre-subscribers" tab="Пререгистрация" />
      <Tabs.TabPane key="promocodes" tab="Промокоды" />
      <Tabs.TabPane key="classifier" tab="Классификатор" />
      <Tabs.TabPane key="versions" tab="Версионность приложения" />
      <Tabs.TabPane key="onboarding" tab="Onboarding" />
    </Tabs>
  );
};
