import { FC, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Tooltip, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';
import { LeftOutlined } from '@ant-design/icons';

import {
  chessPiecesIcon,
  ChessPiecesTranslationsEnum,
} from '@collections/chess-pieces/types/enums/ChessPiecesEnum';
import { ChessPiecesSetTypeEnum } from '@collections/chess-pieces/types/enums/ChessPiecesSetEnum';
import { CollectionTabs } from '@collections/components/molecules/CollectionTabs';
import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { FileUpload } from '@components/molecules/files/FileUpload';
import { PageMeta } from '@context/PageMetaContext';

import {
  IChessPiecesSetUpdateRequest,
  useMutateChessPiecesSetUpdate,
} from '../../api/useMutateChessPiecesSetUpdate';
import { useQueryChessPiecesSetById } from '../../api/useQueryChessPiecesSetById';

export const FiguresSetEditPage: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const chessPiecesSetById = useQueryChessPiecesSetById(id ?? '');
  const updateChessPiecesSetMutation = useMutateChessPiecesSetUpdate();

  const chessPiecesSet = useMemo(() => chessPiecesSetById.data?.data, [chessPiecesSetById]);

  return (
    <>
      <PageMeta selectedMenuKeys={['collections']} title="Коллекция" />
      <CollectionTabs active="figures" />

      <Link to="/collections/figures">
        <Button className="mb-4 text-sm" icon={<LeftOutlined size={10} />} size="large" />
      </Link>

      <div className="border bg-white rounded-lg p-4 lg:p-6 video-modal">
        {chessPiecesSetById.isLoading ? (
          <BaseSkeleton />
        ) : !chessPiecesSet ? (
          <NotFoundResult title="Коллекция фигур не найдена" />
        ) : (
          <Formik<IChessPiecesSetUpdateRequest>
            enableReinitialize
            initialValues={{
              chessPieces: chessPiecesSet.chessPieces,
              id: chessPiecesSet.id,
              localizations: chessPiecesSet.localizations,
              price: chessPiecesSet.price,
              status: chessPiecesSet.status,
              type: chessPiecesSet.type,
            }}
            onSubmit={(values) =>
              updateChessPiecesSetMutation
                .mutateAsync(values)
                .then(() => navigate('/collections/figures'))
            }
            validateOnBlur={false}
            // validationSchema={updateChessPiecesSetValidation}
          >
            {({ setFieldValue, values: { chessPieces, type } }) => {
              return (
                <Form className="gap-4 md:gap-6" layout="vertical">
                  <div className="grid sm:grid-cols-2 gap-4">
                    <FormItem
                      label={<CountryImageText src="ru" text="Название на русском" />}
                      name="localizations.ru.name"
                    >
                      <Input name="localizations.ru.name" prefix />
                    </FormItem>
                    <FormItem
                      label={<CountryImageText src="en" text="Название на английском" />}
                      name="localizations.en.name"
                    >
                      <Input name="localizations.en.name" prefix />
                    </FormItem>
                  </div>

                  <div className="flex flex-row items-baseline">
                    <FormItem className="mr-6 w-[30%]" label="Тип коллекции" name="type">
                      <Select
                        defaultValue="FREE"
                        name="type"
                        onChange={(value) => {
                          setFieldValue('type', value);
                          setFieldValue('price', 0);
                        }}
                        optionFilterProp="label"
                        options={
                          Object.entries(ChessPiecesSetTypeEnum).map(([key, value]) => ({
                            label: value,
                            value: key,
                          })) || []
                        }
                        placeholder="Тип доски"
                      />
                    </FormItem>
                    {type === 'PAID' && (
                      <FormItem className="w-[30%] mr-6" label="Стоимость" name="price">
                        <Input name="price" prefix type="number" />
                      </FormItem>
                    )}
                    <div className="flex flex-col">
                      <Typography className="font-bold mb-4">Картинка коллекции</Typography>
                      <div className="grid grid-cols-2 gap-6">
                        <FormItem className="upload-form-item w-max " label="SVG" name="imgUrl">
                          <FileUpload
                            accept=".svg"
                            defaultFileList={
                              chessPiecesSet.imgUrl
                                ? [
                                    {
                                      name: '',
                                      thumbUrl: chessPiecesSet.imgUrl,
                                      uid: chessPiecesSet.imgUrl,
                                      url: chessPiecesSet.imgUrl,
                                    },
                                  ]
                                : undefined
                            }
                            folderName="imgUrl"
                            isSvg
                            maxCount={1}
                            onChange={(info, linkInfo) => {
                              if (!info.file.status || info.file.status == 'removed')
                                setFieldValue('imgUrl', '');
                              if (info.file.status == 'done')
                                setFieldValue('imgUrl', linkInfo.downloadUrl);
                            }}
                          />
                        </FormItem>
                        <FormItem className="upload-form-item w-max " label="PNG" name="imgUrlPng">
                          <FileUpload
                            accept=".png"
                            defaultFileList={
                              chessPiecesSet.imgUrlPng
                                ? [
                                    {
                                      name: '',
                                      thumbUrl: chessPiecesSet.imgUrlPng,
                                      uid: chessPiecesSet.imgUrlPng,
                                      url: chessPiecesSet.imgUrlPng,
                                    },
                                  ]
                                : undefined
                            }
                            folderName="imgUrlPng"
                            isSvg
                            maxCount={1}
                            onChange={(info, linkInfo) => {
                              if (!info.file.status || info.file.status == 'removed')
                                setFieldValue('imgUrlPng', '');
                              if (info.file.status == 'done')
                                setFieldValue('imgUrlPng', linkInfo.downloadUrl);
                            }}
                          />
                        </FormItem>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-3  gap-4 piece-uploads">
                    {chessPiecesSet?.chessPieces.map((piece, index) => {
                      const translation = ChessPiecesTranslationsEnum[piece.type];
                      const isWhite = piece.type.startsWith('W');
                      const Icon = chessPiecesIcon[piece.type];
                      return (
                        <div className="flex flex-col" key={`${index}`}>
                          <Typography className="font-bold mb-4">{translation}</Typography>
                          <Tooltip
                            color={isWhite ? 'white' : 'black'}
                            key={`${piece.id}`}
                            overlayInnerStyle={{
                              color: isWhite ? 'black' : 'white',
                              fontSize: 16,
                              padding: '5px 15px',
                            }}
                            placement="bottom"
                            title={translation}
                          >
                            <div className="flex flex-row items-start">
                              <FormItem
                                className="upload-form-item custom-picture text-center w-16 md:w-24 lg:w-32 mr-4"
                                key={`${index}-svg`}
                                name={`chessPieces.${index}.imgUrl`}
                              >
                                <FileUpload
                                  accept=".jpeg,.png,.jpg,.svg"
                                  defaultFileList={
                                    piece.imgUrl
                                      ? [
                                          {
                                            name: '',
                                            status: 'done',
                                            uid: piece.imgUrl,
                                            url: piece.imgUrl,
                                          },
                                        ]
                                      : undefined
                                  }
                                  folderName="chessPieces"
                                  icon={<Icon className="text-6xl text-black" />}
                                  isSvg
                                  label="SVG"
                                  listType="picture-card"
                                  maxCount={1}
                                  onChange={(info, linkInfo) => {
                                    if (!info.file.status || info.file.status == 'removed')
                                      setFieldValue(`chessPieces.${index}`, {
                                        ...chessPiecesSet.chessPieces[index],
                                        id: null,
                                        imgUrl: null,
                                        type: chessPiecesSet.chessPieces[index].type,
                                      });
                                    if (info.file.status == 'done')
                                      setFieldValue(`chessPieces.${index}`, {
                                        ...chessPiecesSet.chessPieces[index],
                                        id: null,
                                        imgUrl: linkInfo.downloadUrl,
                                        type: chessPiecesSet.chessPieces[index].type,
                                      });
                                  }}
                                />
                              </FormItem>
                              <FormItem
                                className="upload-form-item custom-picture text-center w-16 md:w-24 lg:w-32"
                                key={`${index}-png`}
                                name="PNG"
                              >
                                <FileUpload
                                  accept=".png"
                                  defaultFileList={
                                    piece.imgUrlPng
                                      ? [
                                          {
                                            name: '',
                                            status: 'done',
                                            uid: piece.imgUrlPng,
                                            url: piece.imgUrlPng,
                                          },
                                        ]
                                      : undefined
                                  }
                                  folderName="chessPieces"
                                  icon={<Icon className="text-6xl text-black" />}
                                  label={`PNG`}
                                  listType="picture-card"
                                  maxCount={1}
                                  onChange={(info, linkInfo) => {
                                    if (!info.file.status || info.file.status == 'removed')
                                      setFieldValue(`chessPieces.${index}`, {
                                        ...chessPieces[index],
                                        id: null,
                                        imgUrlPng: null,
                                        type: chessPiecesSet.chessPieces[index].type,
                                      });
                                    if (info.file.status == 'done')
                                      setFieldValue(`chessPieces.${index}`, {
                                        ...chessPieces[index],
                                        id: null,
                                        imgUrlPng: linkInfo.downloadUrl,
                                        type: chessPiecesSet.chessPieces[index].type,
                                      });
                                  }}
                                />
                              </FormItem>
                            </div>
                          </Tooltip>
                        </div>
                      );
                    })}
                  </div>

                  <div className="flex gap-4 justify-end">
                    <Button onClick={() => navigate('/collections/figures')}>Отмена</Button>
                    <Button htmlType="submit" type="primary">
                      Сохранить
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </>
  );
};
