import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPageAbleResponse } from '@typess/IPageAbleResponse';
import { userQueryKeys } from '@users/api/userQueryKeys';
import { IUserGameRating } from '@users/types/IUserGameRating';

export type IUserGameRatingRequest = {
  customerId: number;
  pageSize: number;
  sortBy: string;
  sortDirection: 'ASC' | 'DESC';
};

type IUserGameRatingQueryResponse = AxiosResponse<IPageAbleResponse<IUserGameRating>>;

export const userGameRatingRequest = async (
  params: IUserGameRatingRequest,
): Promise<IUserGameRatingQueryResponse> => {
  return await axios.get(`/customers/${params.customerId}/game-matches`, { params });
};

export function useQueryUserGameRating(params: IUserGameRatingRequest) {
  return useQuery<IUserGameRatingQueryResponse, AxiosError>(
    [userQueryKeys.userSysRating, params],
    () => userGameRatingRequest(params),
  );
}
