export enum IEnumMatchSubType {
  EACH_TURN_1MIN = 'Ход 1 минута',
  EACH_TURN_2MIN = 'Ход 2 минуты',
  MIN1 = 'Минута',
  MIN10 = '10 минут',
  MIN10_5 = '10 минут + 5',
  MIN15 = '15 минут',
  MIN15_10 = '15 минут + 10',
  MIN1_1 = 'Минута + 1',
  MIN2_1 = '2 минуты + 1',
  MIN3 = '3 минуты',
  MIN3_2 = '3 минуты + 2',
  MIN5 = '5 минут',
}

export type IEnumMatchSubTypeKeys = keyof typeof IEnumMatchSubType;
