import { UserGameMatchOutcomeEnumKeys } from '@users/types/enums/UserGameMatchOutcomeEnum';

export type IUserGameMatch = {
  computerRatingElo: number;
  customerId: number;
  id: number;
  mode: number;
  newRating: number;
  oldRating: number;
  outcome: UserGameMatchOutcomeEnumKeys;
  pgn: string;
  pgnFormat: string;
  userIsWhite: boolean;
};

export const userGameMatchesKeys: Record<keyof IUserGameMatch, string> = {
  computerRatingElo: 'computerRatingElo',
  customerId: 'customerId',
  id: 'id',
  mode: 'mode',
  newRating: 'newRating',
  oldRating: 'oldRating',
  outcome: 'outcome',
  pgn: 'pgn',
  pgnFormat: 'pgnFormat',
  userIsWhite: 'userIsWhite',
};
