import { FC } from 'react';

import { SelectMultipleFilter } from '@components/molecules/filters/static/SelectMultipleFilter';
import { FilterRender } from '@typess/IFilters';
import { useQueryVideos } from '@videos/api/useQueryVideos';

export const VideosSelectMultipleFilter: FC<{
  filterRender?: FilterRender;
  isCartoon?: boolean;
}> = ({ filterRender, isCartoon }) => {
  const videos = useQueryVideos({ isCartoon });
  const videoData = videos.data?.data;

  const options =
    videoData?.content.map((video) => ({
      label: video.localizations?.en?.fullTitle,
      value: video.id,
    })) || [];

  return (
    <SelectMultipleFilter
      filterRender={filterRender}
      loading={videos.isLoading}
      options={options}
      placeholder="Выберите видеоурок"
    />
  );
};
