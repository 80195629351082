import { TagsSelectMultiple } from '@components/molecules/filters/dynamic/TagsSelectMultipleFilter';
import { InputFilter } from '@components/molecules/filters/static/InputFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { IFilters } from '@typess/IFilters';
import { videoFileKeys } from '@videos/types/IVideoFile';

export const videoFilesFilters: IFilters = {
  ...paginationBaseFilters,
  author: {
    input: <TagsSelectMultiple placeholder="Выберите автора" type="AUTHOR" />,
    queries: [{ key: 'authorIds' }],
    sort: true,
  },
  [videoFileKeys.title]: {
    input: <InputFilter placeholder="Напишите название" />,
    sort: true,
  },
  [videoFileKeys.createdDate]: {
    sort: true,
  },
  sortBy: { queries: [{ default: 'createdDate' }] },
};
