import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';

import { IPreSubscriber, preSubscriberKeys } from '@settings/preSubscribers/types/IPreSubscriber';

export const preSubscribersColumns: ColumnsType<IPreSubscriber> = [
  {
    dataIndex: preSubscriberKeys.email,
    title: 'Почта',
  },
  {
    dataIndex: preSubscriberKeys.createdDate,
    render: (createdDate: IPreSubscriber['createdDate']) =>
      createdDate ? moment(createdDate).format('LLL') : '-',
    title: 'Дата создания',
  },
];
