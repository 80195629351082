export enum AuthorRankEnum {
  'CM' = 'CM',
  'FM' = 'FM',
  'GM' = 'GM',
  'IM' = 'IM',
  'WCM' = 'WCM',
  'WFM' = 'WFM',
  'WGM' = 'WGM',
  'WIM' = 'WIM',
}

export type AuthorRankEnumKeys = keyof typeof AuthorRankEnum;
