import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Switch as AntSwitch, Table } from 'antd';
import { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';

import { useMutateChessPiecesSetStatusUpdate } from '@collections/chess-pieces/api/useMutateChessPiecesSetStatusUpdate';
import { useQueryChessPiecesSet } from '@collections/chess-pieces/api/useQueryChessPiecesSet';
import { chessPiecesColumns } from '@collections/chess-pieces/consts/columns/chessPiecesColumns';
import { IChessPiecesSet } from '@collections/chess-pieces/types/IChessPiecesSet';
import {
  chessPiecesGroupEditValidation,
  IChessPiecesGroupEditRequest,
  useMutateChessPiecesGroupEdit,
} from '@collections/chess-pieces-groups/api/useMutateChessPiecesGroupEdit';
import { useQueryChessPiecesGroup } from '@collections/chess-pieces-groups/api/useQueryChessPiecesGroup';
import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { DraggableTableRow } from '@components/organisms/adminTable/organismComponents/DraggableTableRow';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { ChessPiecesGroupAddItemModal } from './ChessPiecesGroupAddItemModal';

export const ChessPiecesGroupEditModal: FC<{
  id?: number;
  isOpen: boolean;
  onCancel: () => void;
  onItemDelete: ({
    chessPiecesSetId,
    id,
  }: {
    chessPiecesSetId: number;
    id: number;
  }) => Promise<AxiosResponse>;
}> = ({ id = 0, isOpen, onCancel, onItemDelete }) => {
  const navigate = useNavigate();

  const chessPiecesGroupQuery = useQueryChessPiecesGroup({ id });
  const chessPiecesGroup = chessPiecesGroupQuery.data?.data;
  const [chessPieceSets, setChessPieceSets] = useState<(IChessPiecesSet & { priority: number })[]>(
    [],
  );

  const allChessPiecesSets = useQueryChessPiecesSet();
  const filteredChessPiecesSets = useMemo(
    () =>
      allChessPiecesSets.data?.data.content.filter(
        (item) => !chessPieceSets.some((refItem) => refItem.id === item.id),
      ),
    [allChessPiecesSets, chessPieceSets],
  );

  const [addChessPiecesSetModalOpen, setAddChessPiecesSetModalOpen] = useState(false);

  const chessPiecesSetStatusUpdate = useMutateChessPiecesSetStatusUpdate();
  const editChessPiecesGroup = useMutateChessPiecesGroupEdit();

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setChessPieceSets((previous) => {
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    if (chessPiecesGroup) {
      setChessPieceSets(
        chessPiecesGroup.items
          .sort((a, b) => a.priority - b.priority)
          .map((item) => ({
            ...item.chessPieceSet,
            priority: item.priority,
          })),
      );
    }
  }, [chessPiecesGroup]);

  return (
    <Modal
      className="px-4 md:px-0 md:w-5/6 lg:w-4/5 pt-0 mt-0 top-8"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <div className="flex flex-row gap-4">
          <h2 className="text-2xl">
            Редактировать группу фигур - {chessPiecesGroup?.localizations.ru?.name ?? id}
          </h2>
        </div>
      }
    >
      {chessPiecesGroupQuery.isLoading ? (
        <BaseSkeleton />
      ) : !chessPiecesGroup ? (
        <NotFoundResult title="Группа фигур не найдена" />
      ) : (
        <div>
          <Formik<IChessPiecesGroupEditRequest>
            enableReinitialize
            initialValues={{
              id: chessPiecesGroup.id,
              items: chessPieceSets.map((item, index) => ({
                chessPieceSetId: item.id,
                priority: index + 1,
              })),
              localizations: chessPiecesGroup.localizations,
              priority: chessPiecesGroup.priority,
              status: chessPiecesGroup.status,
            }}
            onSubmit={(values) =>
              editChessPiecesGroup.mutateAsync(values).then(() => {
                onCancel();
              })
            }
            validateOnBlur={false}
            validationSchema={chessPiecesGroupEditValidation}
          >
            {({ setFieldValue, values }) => (
              <Form className="gap-4 md:gap-6" layout="vertical">
                <FormItem
                  label={<CountryImageText src="ru" text="Название на русском" />}
                  name="localizations.ru.name"
                >
                  <Input name="localizations.ru.name" prefix />
                </FormItem>
                <FormItem
                  label={<CountryImageText src="en" text="Название на английском" />}
                  name="localizations.en.name"
                >
                  <Input name="localizations.en.name" prefix />
                </FormItem>
                <div className="flex justify-start text-center">
                  <FormItem label={<span className="text-center">Статус</span>} name="status">
                    <Input hidden name="status" suffix />

                    <AntSwitch
                      checked={values.status == 'ACTIVE'}
                      onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
                    />
                  </FormItem>
                </div>
                <div className="flex justify-end gap-3">
                  <Button
                    className="md:block mb-4"
                    onClick={() => setAddChessPiecesSetModalOpen(true)}
                    type="primary"
                  >
                    Добавить коллекцию фигур
                  </Button>
                </div>

                <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                  <SortableContext
                    items={chessPieceSets?.map((i) => i.id)}
                    strategy={verticalListSortingStrategy}
                  >
                    <Table
                      columns={chessPiecesColumns({
                        isSortable: true,
                        onClickDelete: ({ id: chessPiecesSetId }) =>
                          onItemDelete({ chessPiecesSetId, id }),
                        onClickEdit: (chessPiecesSetId) =>
                          navigate(`/collections/figures?action=edit&actionId=${chessPiecesSetId}`),
                        onClickPause: chessPiecesSetStatusUpdate.mutateAsync,
                      })}
                      components={{
                        body: {
                          row: DraggableTableRow,
                        },
                      }}
                      dataSource={chessPieceSets}
                      loading={chessPiecesGroupQuery.isLoading}
                      pagination={false}
                      rowKey="id"
                      scroll={{ x: '100%' }}
                    />
                  </SortableContext>
                </DndContext>

                <ChessPiecesGroupAddItemModal
                  chessPiecesGroupId={id}
                  chessPiecesGroupName={chessPiecesGroup.localizations.ru?.name ?? ''}
                  chessPiecesSets={filteredChessPiecesSets ?? []}
                  isLoading={allChessPiecesSets.isFetching}
                  isOpen={addChessPiecesSetModalOpen}
                  onClose={() => setAddChessPiecesSetModalOpen(false)}
                />
                <div className="flex gap-4 justify-end mt-4">
                  <Button onClick={onCancel}>Отмена</Button>
                  <Button htmlType="submit" loading={editChessPiecesGroup.isLoading} type="primary">
                    <span className="max-w-full overflow-ellipsis overflow-hidden">
                      {values.status == 'ACTIVE' ? 'Сохранить' : 'Сохранить как черновик'}
                    </span>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Modal>
  );
};
