import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema } from 'yup';

import {
  IVideoStepOneCreateRequest,
  videoStepOneCreateValidation,
} from '@videos/api/useMutateVideoStepOneCreate';
import { videoQueryKeys } from '@videos/api/videoQueryKeys';
import { IVideo } from '@videos/types/IVideo';

export type IVideoStepOneUpdateRequest = IVideoStepOneCreateRequest & { id: number };

type IVideoStepOneUpdateResponse = AxiosResponse<IVideo>;

export const videoStepOneUpdateInitial: IVideoStepOneUpdateRequest = {
  id: 0,
  isCartoon: false,
  localizations: {
    en: {
      fullTitle: '',
      previewUrl: '',
      subtitlesUrl: '',
      title: '',
      uuid: '',
    },
    ru: {
      fullTitle: '',
      previewUrl: '',
      subtitlesUrl: '',
      title: '',
      uuid: '',
    },
  },
  price: 1,
  status: 'DRAFT',
};

export const videoStepOneUpdateValidation: ObjectSchema<IVideoStepOneUpdateRequest> = object({
  id: number().required(),
}).concat(videoStepOneCreateValidation);

export const videoStepOneUpdateRequest = async (
  payload: IVideoStepOneUpdateRequest,
): Promise<IVideoStepOneUpdateResponse> => {
  return await axios.patch('videos/1', payload);
};

export function useMutateVideoStepOneUpdate() {
  const queryClient = useQueryClient();

  return useMutation<IVideoStepOneUpdateResponse, AxiosError, IVideoStepOneUpdateRequest>(
    videoStepOneUpdateRequest,
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(videoQueryKeys.videos);
        queryClient.invalidateQueries([videoQueryKeys.video, { id: variables.id }]);
        queryClient.invalidateQueries([videoQueryKeys.video, { id: variables.id.toString() }]);
      },
    },
  );
}
