import { FC, useState } from 'react';
import { Button } from 'antd';

import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { ExerciseTabs } from '@exercises/exercises/components/molecules/ExerciseTabs';
import { ExerciseViewModal } from '@exercises/exercises/components/organisms/ExerciseViewModal';
import { FenViewModal } from '@exercises/fens/components/organisms/FenViewModal';
import { IFen } from '@exercises/fens/types/IFen';
import { mapStageCreateInitial } from '@exercises/map-stages/api/useMutateMapStageCreate';
import { useMutateMapStageDelete } from '@exercises/map-stages/api/useMutateMapStageDelete';
import { useMutateMapStageStatusUpdate } from '@exercises/map-stages/api/useMutateMapStageStatusUpdate';
import { IMapStagesRequest, useQueryMapStages } from '@exercises/map-stages/api/useQueryMapStages';
import { mapStagesColumns } from '@exercises/map-stages/consts/columns/mapStagesColumns';
import { mapStageFilters } from '@exercises/map-stages/consts/filters/mapStageFilters';
import {
  MapStagesPageActions,
  mapStagesPageActions,
} from '@exercises/map-stages/consts/pageActions/MapStagesPageActions';
import { IMapStage } from '@exercises/map-stages/types/IMapStage';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { mapStagePermissions } from '@typess/permissions/IMapStagePermission';
import { checkEditPermission } from '@utils/checkEditPermission';
import { VideoViewModal } from '@videos/components/organisms/VideoViewModal';

import { MapStageCreateModal } from '../organisms/MapStageCreateModal';
import { MapStageEditMoal } from '../organisms/MapStageEditModal';
import { MapStageViewModal } from '../organisms/MapStageViewModal';

const MapStagesPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IMapStagesRequest,
    MapStagesPageActions
  >(mapStageFilters, mapStagesPageActions);
  const canEditMapStage = checkEditPermission(mapStagePermissions);
  const stageDelete = useMutateMapStageDelete();
  const [fen, setFen] = useState<undefined | IFen>();
  const stagePause = useMutateMapStageStatusUpdate();
  const [initialValues, setInitialValues] = useState<Partial<IMapStage>>(mapStageCreateInitial);

  const handleClickDuplicate = (stage: IMapStage) => {
    setActioned({ action: 'createStage' });
    setInitialValues({
      ...initialValues,
      exerciseIds: stage.exercises?.map((ex) => ex.id),
      fenRecordId: stage.fenRecord?.id,
      playLevels: [stage.playLevel],
      stageNumber: stage.stageNumber,
      status: stage.status,
      type: stage.type,
      videoId: stage.video?.id,
    });
  };

  const mapStages = useQueryMapStages(filtered);

  return (
    <div>
      <PageMeta selectedMenuKeys={['map-stages']} title="Конструктор уровней" />

      <ExerciseTabs active="map-stages" />
      {canEditMapStage && (
        <div className="flex justify-end gap-3">
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'createStage' })}
            size="large"
          >
            Создать шаг на карте
          </Button>
        </div>
      )}

      <AdminTable
        columns={mapStagesColumns({
          canEdit: canEditMapStage,
          onClickDelete: stageDelete.mutateAsync,
          onClickDuplicate: (stage) => handleClickDuplicate(stage),
          onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
          onClickExerciseView: ({ exerciseId }) =>
            setActioned({ action: 'viewExercise', exerciseId: exerciseId }),
          onClickFenView: (fen) => {
            setFen(fen);
            setActioned({ action: 'viewFen', fenId: fen.id });
          },
          onClickPause: stagePause.mutateAsync,
          onClickVideoView: ({ id }) => setActioned({ action: 'viewVideo', videoId: id }),

          onClickView: ({ id }) => setActioned({ action: 'view', actionId: id }),
        })}
        emptyDescription={
          <div>
            <p>Уровни не найдены.</p>
            <p>Вы можете создать уровень, нажав на кнопку “Создать уровень</p>
          </div>
        }
        query={mapStages}
        tableFilter={{ filtered, filters: mapStageFilters, onFilter }}
      />

      <MapStageCreateModal
        initialValues={initialValues}
        isOpen={actioned.action == 'createStage'}
        onCancel={clearParams}
      />
      <MapStageEditMoal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
        openView={() => setActioned({ action: 'view', actionId: actioned.actionId })}
      />
      <MapStageViewModal
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onCancel={clearParams}
        openEdit={() => setActioned({ action: 'edit', actionId: actioned.actionId })}
      />
      <ExerciseViewModal
        id={actioned.exerciseId}
        isOpen={actioned.action == 'viewExercise'}
        onCancel={clearParams}
      />
      <FenViewModal
        fen={fen}
        isOpen={actioned.action == 'viewFen' && !!fen}
        onCancel={() => {
          setFen(undefined);
          clearParams();
        }}
      />

      <VideoViewModal
        id={actioned.videoId}
        isOpen={actioned.action == 'viewVideo'}
        onCancel={clearParams}
      />
    </div>
  );
};

export default MapStagesPage;
