import { FC } from 'react';
import { Button, Modal, Switch as AntSwitch } from 'antd';
import { Formik } from 'formik';
import { DatePicker, Form, FormItem, Input, InputNumber, Select } from 'formik-antd';
import moment from 'moment';

import { CountryImageText } from '@components/atoms/CountryImageText';
import { useQueryAppProducts } from '@shop/appProducts/api/useQueryAppProducts';
import { IAppProduct } from '@shop/appProducts/types/IAppProduct';
import {
  appPromotionCreateInitial,
  appPromotionCreateValidation,
  IAppPromotionCreateRequest,
  useMutateAppPromotionsCreate,
} from '@shop/appPromotions/api/useMutateAppPromotionCreate';
import { notify } from '@utils/notify';

type PromotionCreateModalType = {
  isOpen: boolean;
  onCancel: () => void;
};

export const PromotionCreateModal: FC<PromotionCreateModalType> = ({ isOpen, onCancel }) => {
  const promotionCreate = useMutateAppPromotionsCreate();

  const appProducts = useQueryAppProducts();

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={<h1 className="text-xl m-0">Новая кампания</h1>}
    >
      <Formik<IAppPromotionCreateRequest>
        initialValues={appPromotionCreateInitial}
        onSubmit={(values, { resetForm }) => {
          promotionCreate.mutateAsync(values).then((data) => {
            if (data.status == 201 || data.status == 200) {
              notify('success', 'Кампания создана');
              onCancel();
              resetForm();
            }
          });
        }}
        validationSchema={appPromotionCreateValidation}
      >
        {({ setFieldValue, values }) => (
          <Form className="space-y-8" layout="vertical">
            <div className="md:grid md:grid-cols-2 md:gap-x-5">
              <FormItem
                label={<CountryImageText src="en" text="Название кампании" />}
                name="localizations.en.title"
              >
                <Input name="localizations.en.title" placeholder="Title" suffix />
              </FormItem>

              <FormItem
                label={<CountryImageText src="ru" text="Название кампании" />}
                name="localizations.ru.title"
              >
                <Input name="localizations.ru.title" placeholder="Название" suffix />
              </FormItem>
            </div>
            <div className="md:grid md:grid-cols-3 md:gap-x-5">
              <FormItem label="Начало периода" name="startDate">
                <DatePicker
                  className="w-full"
                  disabledDate={(current) =>
                    moment().startOf('day') >= current.startOf('day') ||
                    moment(values.endDate).startOf('day') <= current.startOf('day')
                  }
                  name="startDate"
                  placeholder="Начало"
                />
              </FormItem>

              <FormItem label="Конец периода" name="endDate">
                <DatePicker
                  className="w-full"
                  disabledDate={(current) =>
                    moment().add(1, 'day').startOf('day') >= current.startOf('day') ||
                    moment(values.startDate).startOf('day') >= current.startOf('day')
                  }
                  name="endDate"
                  placeholder="Конец"
                />
              </FormItem>

              <FormItem label="Скидка %" name="discountPercent">
                <InputNumber
                  className="w-full"
                  controls={false}
                  name="discountPercent"
                  placeholder="55%"
                  prefix
                />
              </FormItem>
            </div>

            <FormItem label="Товары" name="productIds">
              <Select
                mode="multiple"
                name="productIds"
                optionFilterProp="label"
                options={appProducts.data?.data.content.map((product: IAppProduct) => ({
                  label: `${product.coins}CC - ${product.price}KZT`,
                  value: product.id,
                }))}
              />
            </FormItem>

            <div className="md:flex md:justify-center">
              <Form.Item
                className="md:text-center"
                label={<span className="text-center">Запустить немедленно</span>}
                name="status"
              >
                <Input hidden name="status" suffix />
                <AntSwitch
                  checked={values.status == 'APPROVED'}
                  onChange={(checked) => setFieldValue('status', checked ? 'APPROVED' : 'DRAFT')}
                />
              </Form.Item>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
              <Button block onClick={onCancel}>
                Закрыть
              </Button>

              <Button block htmlType="submit" loading={promotionCreate.isLoading} type="primary">
                {values.status == 'APPROVED' ? 'Создать и запустить' : 'Сохранить как черновик'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
