/**
 * ⚠️ DONT EDIT MANUALLY
 * This file is generated with `./src/cli/generateIcons.js`, check it for the detailed information
 */
import { FC } from 'react';

import { ReactComponent as ChessPieceWhiteKnight2IconSvg } from '@assets/icons/chess-piece-white-knight2.svg';

import BaseIcon from './_BaseIcon';

type Props = React.ComponentPropsWithoutRef<'span'>;

export const ChessPieceWhiteKnight2Icon: FC<Props> = (props) => {
  return (
    <BaseIcon component={ChessPieceWhiteKnight2IconSvg} style={{ fontSize: '1.5em' }} {...props} />
  );
};
