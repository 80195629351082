import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { boardCommonKeys } from '@collections/boards/api/boardCommonKeys';
import { IBoard } from '@collections/boards/types/IBoard';

import { boardGroupQueryKeys } from './boardGroupQueryKeys';

export type IBoardGroupStatusUpdateRequest = Pick<IBoard, 'id' | 'status'>;

type IBoardGroupStatusUpdateResponse = AxiosResponse<void>;

export const boardGroupStatusUpdateRequest = async ({
  id,
  ...payload
}: IBoardGroupStatusUpdateRequest): Promise<IBoardGroupStatusUpdateResponse> =>
  await axios.patch(`/chess-board-groups/${id}`, payload);

export function useMutateBoardGroupStatusUpdate() {
  const queryClient = useQueryClient();

  return useMutation<IBoardGroupStatusUpdateResponse, AxiosError, IBoardGroupStatusUpdateRequest>(
    boardGroupStatusUpdateRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(boardGroupQueryKeys.boardGroup);
        queryClient.invalidateQueries(boardGroupQueryKeys.boardsGroups);
        queryClient.invalidateQueries(boardCommonKeys.boards);
        queryClient.invalidateQueries(boardCommonKeys.board);
      },
    },
  );
}
