import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

import { IAvatarGroup } from '../types/IAvatarGroup';
import { avatarGroupQueryKeys } from './avatarGroupQueryKeys';

export type IAvatarGroupsRequest = IPageAbleRequest & { name?: string };

type IAvatarGroupsResponse = AxiosResponse<IPageAbleResponse<IAvatarGroup>>;

export const avatarGroupsRequest = async (
  params?: IAvatarGroupsRequest,
): Promise<IAvatarGroupsResponse> => await axios.get('/avatar-groups', { params });

export function useQueryAvatarGroups(params?: IAvatarGroupsRequest) {
  return useQuery<IAvatarGroupsResponse, AxiosError>(
    [avatarGroupQueryKeys.avatarsGroups, params],
    () => avatarGroupsRequest(params),
  );
}
