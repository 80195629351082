import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { exerciseGroupQueryKeys } from '@exercises/exerciseGroups/api/exerciseGroupQueryKeys';
import { exerciseQueryKeys } from '@exercises/exercises/api/exerciseQueryKeys';
import { IExercise } from '@exercises/exercises/types/IExercise';

export type IExerciseGroupStatusUpdateRequest = Pick<IExercise, 'id' | 'status'>;

type IExerciseGroupStatusUpdateResponse = AxiosResponse<void>;

export const exerciseGroupStatusUpdateRequest = async ({
  id,
  ...payload
}: IExerciseGroupStatusUpdateRequest): Promise<IExerciseGroupStatusUpdateResponse> =>
  await axios.patch(`/exercise-groups/${id}`, payload);

export function useMutateExerciseGroupStatusUpdate() {
  const queryClient = useQueryClient();

  return useMutation<
    IExerciseGroupStatusUpdateResponse,
    AxiosError,
    IExerciseGroupStatusUpdateRequest
  >(exerciseGroupStatusUpdateRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(exerciseGroupQueryKeys.exercisesGroups);
      queryClient.invalidateQueries(exerciseGroupQueryKeys.exerciseGroup);
      queryClient.invalidateQueries(exerciseQueryKeys.exercises);
      queryClient.invalidateQueries(exerciseQueryKeys.exercise);
    },
  });
}
