import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema } from 'yup';

import { notify } from '@utils/notify';

import { avatarCommonKeys } from './avatarCommonKeys';
import { createAvatarValidation, IAvatarCreateRequest } from './useMutateAvatarCreate';

export type IAvatarUpdateRequest = IAvatarCreateRequest & {
  id: number;
};

export const updateAvatarValidation: ObjectSchema<IAvatarUpdateRequest> =
  createAvatarValidation.concat(
    object({
      id: number().required(),
    }),
  );

export const updateAvatarRequest = ({ id, ...payload }: IAvatarUpdateRequest) =>
  axios.patch(`/avatars/${id}`, payload);

export const useMutateAvatarUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, IAvatarUpdateRequest>(updateAvatarRequest, {
    onError: () => {
      notify('error', 'Повторите попытку позже');
    },
    onSuccess: () => {
      notify('success', 'Аватар успешно обновлен');

      queryClient.invalidateQueries(avatarCommonKeys.avatars);
    },
  });
};
