import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, boolean, number, object, ObjectSchema, string } from 'yup';

import { exerciseQueryKeys } from '@exercises/exercises/api/exerciseQueryKeys';
import { ExerciseGamePhaseEnumKeys } from '@exercises/exercises/types/enums/ExerciseGamePhaseEnum';
import { ExerciseStatusEnumKeys } from '@exercises/exercises/types/enums/ExerciseStatusEnum';
import { IExerciseProblem } from '@exercises/exercises/types/IExerciseProblem';

export type IExerciseProblemEditRequest = Pick<
  IExerciseProblem,
  | 'gamePhase'
  | 'isPawnStructure'
  | 'isTactic'
  | 'playLevels'
  | 'status'
  | 'id'
  | 'localizations'
  | 'isCapitalization'
> & {
  tagIds: number[];
  useForPuzzleRush: boolean;
  videoId?: number | null;
};

type IExerciseProblemEditResponse = AxiosResponse<void>;

export const exerciseProblemEditValidation: ObjectSchema<IExerciseProblemEditRequest> = object({
  gamePhase: string<ExerciseGamePhaseEnumKeys>().required(),
  id: number().required(),
  isCapitalization: boolean(),
  isPawnStructure: boolean().required(''),
  isTactic: boolean().required(''),
  localizations: object().required(),
  playLevels: array().required('').min(1, 'Должно содержать минимум 1 уровень'),
  status: string<ExerciseStatusEnumKeys>().required('Статус обязателен для заполнения'),
  tagIds: array().required().min(1, 'Должно содержать минимум 1 тему'),
  useForPuzzleRush: boolean().required(),
  videoId: number().nullable(),
});

export const exerciseProblemEditRequest = async (
  payload: IExerciseProblemEditRequest,
): Promise<IExerciseProblemEditResponse> =>
  await axios.patch(`/exercises/problem/${payload.id}`, payload);

export function useMutateExerciseProblemEdit() {
  const queryClient = useQueryClient();

  return useMutation<IExerciseProblemEditResponse, AxiosError, IExerciseProblemEditRequest>(
    exerciseProblemEditRequest,
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(exerciseQueryKeys.exercises);
        queryClient.invalidateQueries([exerciseQueryKeys.exercise, { id: variables.id }]);
      },
    },
  );
}
