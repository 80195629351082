import { useMutation } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

type IVideoUploadLinkRequest = { videoName: string };

type IVideoUploadLinkResponse = AxiosResponse<{ id: string; url: string }>;

export const videoUploadLinkRequest = async (
  params: IVideoUploadLinkRequest,
): Promise<IVideoUploadLinkResponse> => await axios.get('videos/video-upload-link', { params });
export function useMutateVideoUploadLink() {
  return useMutation<IVideoUploadLinkResponse, AxiosError, IVideoUploadLinkRequest>(
    videoUploadLinkRequest,
  );
}
