import { FC } from 'react';

import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { UsersTabs } from '@users/components/molecules/UsersTabs';

import { IMatchesAllRequest, useQueryMatchesAll } from '../../api/userQueryMatchesAll';
import { matchesPvpColumns } from '../../consts/columns/matchesPvpColumns';
import { matchesPvpFilters } from '../../consts/filters/matchesPvpFilters';
import {
  IMatchesPvpActions,
  matchesPvpPageParams,
} from '../../consts/pageActions/IMatchesPvpActions';
import ViewMatchPvpModal from '../organisms/ViewMatchPvpModal';

export const MatchesAllPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IMatchesAllRequest,
    IMatchesPvpActions
  >(matchesPvpFilters, matchesPvpPageParams);
  const matches = useQueryMatchesAll(filtered);

  return (
    <>
      <PageMeta selectedMenuKeys={['users']} title="История игр" />
      <UsersTabs active="matches" />
      <AdminTable
        columns={matchesPvpColumns({
          onView: (actionId) => setActioned({ action: 'view', actionId }),
        })}
        emptyDescription={<p>История игр не найдена</p>}
        query={matches}
        tableFilter={{ filtered, filters: matchesPvpFilters, onFilter }}
      />
      <ViewMatchPvpModal
        id={actioned.actionId}
        isOpen={actioned.action == 'view' && !!actioned.actionId}
        onClose={clearParams}
      />
    </>
  );
};
