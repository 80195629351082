import { TagsSelectMultiple } from '@components/molecules/filters/dynamic/TagsSelectMultipleFilter';
import { VideosSelectMultipleFilter } from '@components/molecules/filters/dynamic/VideosSelectMultipleFilter';
import { CheckboxFilter } from '@components/molecules/filters/static/CheckboxFilter';
import { InputFilter } from '@components/molecules/filters/static/InputFilter';
import { RadioFilter } from '@components/molecules/filters/static/RadioFilter';
import { RangeFilter } from '@components/molecules/filters/static/RangeFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { ExerciseStatusEnum } from '@exercises/exercises/types/enums/ExerciseStatusEnum';
import { ExerciseTypeEnum } from '@exercises/exercises/types/enums/ExerciseTypeEnum';
import { exerciseKeys } from '@exercises/exercises/types/IExercise';
import { IFilters } from '@typess/IFilters';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';

export const exerciseFilters: IFilters = {
  ...paginationBaseFilters,

  action: {
    input: <InputFilter placeholder="Введите часть FEN" />,
    queries: [{ key: 'boardState' }],
  },
  id: {
    input: <InputFilter placeholder="Введите ID" />,
    sort: true,
  },
  [exerciseKeys.playLevels]: {
    input: (
      <CheckboxFilter
        options={Object.entries(userPlayLevels).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    queries: [{ key: 'playLevels' }],
    sort: true,
    sortOrderReversed: true,
  },
  [exerciseKeys.status]: {
    input: (
      <CheckboxFilter
        options={Object.entries(ExerciseStatusEnum).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },
  [exerciseKeys.problemChecked]: {
    input: (
      <RadioFilter
        options={[
          { label: 'Проверенные', value: true },
          { label: 'Не проверенные', value: false },
        ]}
      />
    ),
    queries: [{ key: 'checked' }],
  },
  [exerciseKeys.solvedUsersCount]: {
    input: <RangeFilter placeholderMax="Максимум" placeholderMin="Минимум" />,
    queries: [{ key: 'solvedUsersCountFrom' }, { key: 'solvedUsersCountTo' }],
    sort: true,
  },
  [exerciseKeys.solvedUsersPercentage]: {
    input: <RangeFilter placeholderMax="Максимум" placeholderMin="Минимум" />,
    queries: [{ key: 'solvedUsersPercentageFrom' }, { key: 'solvedUsersPercentageTo' }],
    sort: true,
  },
  [exerciseKeys.tags]: {
    input: <TagsSelectMultiple type="TOPIC" />,
    queries: [{ key: 'tagIds' }],
  },
  [exerciseKeys.video]: {
    input: <VideosSelectMultipleFilter isCartoon={false} />,
    queries: [{ key: 'videoIds' }],
  },
  [exerciseKeys.type]: {
    input: (
      <RadioFilter
        options={Object.entries(ExerciseTypeEnum).map(([value, label]) => ({ label, value }))}
      />
    ),
    sort: true,
  },
  [exerciseKeys.useForPuzzleRush]: {
    input: (
      <RadioFilter
        options={[
          { label: 'Да', value: true },
          { label: 'Нет', value: false },
        ]}
      />
    ),
    sort: true,
  },
};
