import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';

export const UsersTabs: FC<{ active: 'users' | 'matches' }> = ({ active }) => {
  const navigate = useNavigate();

  return (
    <Tabs
      activeKey={active}
      centered
      items={[
        { key: 'users', label: 'Пользователи' },
        { key: 'matches', label: 'История игр' },
      ]}
      onChange={(tab) => navigate(tab == 'matches' ? '/matches' : '/users')}
    />
  );
};
