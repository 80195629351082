import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum FensPageActionEnum {
  'view',
  'create',
  'edit',
}

export type FensPageActions = {
  action: keyof typeof FensPageActionEnum;
  actionId: number;
};

export const fensPageActions: IPageActions<FensPageActions> = {
  action: {
    allowed: (value) => Object.keys(FensPageActionEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
};
