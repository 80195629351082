import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { IFilters } from '@typess/IFilters';

export const lecturerCardFilters: IFilters = {
  ...paginationBaseFilters,

  // [lecturerCardKeys.status]: {
  //   input: (
  //     <CheckboxFilter
  //       options={Object.entries(LecturerCardStatusEnum).map(([value, label]) => ({
  //         label,
  //         value,
  //       }))}
  //     />
  //   ),
  //   sort: true,
  // },
  // [lecturerCardKeys.authorTag]: {
  //   input: <TagsSelectMultiple placeholder="Выберите лектора" type="AUTHOR" />,
  //   queries: [{ key: 'authorTagIds' }],
  // },
  // [lecturerCardKeys.id]: {
  //   input: <InputFilter placeholder="Введите ID" />,
  //   queries: [{ key: 'id' }],
  //   sort: true,
  // },
};
