export const userQueryKeys = {
  brainstorm: 'brainstorm',
  user: 'user',
  userCoinPurchases: 'userCoinPurchases',
  userGameMatches: 'userGameMatches',
  userGameRating: 'userGameRating',
  userMapStages: 'userMapStages',
  userSysRating: 'userSysRating',
  users: 'users',
};
