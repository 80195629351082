import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Tabs } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

export const DashboardTabs: FC<{
  active:
    | 'dashboards'
    | 'by-country'
    | 'by-play-level'
    | 'changes'
    | 'paying-users'
    | 'paying-users-stats';
}> = ({ active }) => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="relative w-max m-auto">
      <Tabs
        activeKey={active}
        centered
        className="w-max"
        items={[
          { key: 'dashboards', label: 'Общая' },
          { key: 'by-country', label: 'По странам' },
          { key: 'by-play-level', label: 'По уровню' },
          { key: 'changes', label: 'Изменения' },
          { key: 'paying-users', label: 'Платящие пользователи' },
          {
            key: 'paying-users-stats',
            label: (
              <span>
                Marketing dashboards
                {showMenu ? <UpOutlined className="ml-1" /> : <DownOutlined className="ml-1" />}
              </span>
            ),
          },
        ]}
        onChange={(tab) =>
          tab === 'paying-users-stats'
            ? setShowMenu(!showMenu)
            : navigate(
                tab == 'by-country'
                  ? '/dashboards/by-country'
                  : tab == 'by-play-level'
                  ? '/dashboards/by-play-level'
                  : tab == 'changes'
                  ? '/dashboards/changes'
                  : tab === 'paying-users'
                  ? '/dashboards/paying-users'
                  : '/dashboards',
              )
        }
        onTabClick={(tab) => tab === 'paying-users-stats' && setShowMenu(!showMenu)}
      />

      {showMenu && (
        <Menu className="w-max right-0 absolute top-[42px] z-10 shadow-lg" mode="vertical">
          <Menu.Item key="1" onClick={() => navigate('/dashboards/paying-users-stats/cohort')}>
            Cohort analysis
          </Menu.Item>
          <Menu.Item key="2" onClick={() => navigate('/dashboards/paying-users-stats/financial')}>
            Financial analysis
          </Menu.Item>
          <Menu.Item key="3" onClick={() => navigate('/dashboards/paying-users-stats/total')}>
            Now | Total marketing
          </Menu.Item>
        </Menu>
      )}
    </div>
  );
};
