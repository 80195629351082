import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema } from 'yup';

import { notify } from '@utils/notify';

import { lecturerCardCommonKeys } from './lecturerCardKeys';
import {
  createLecturerCardValidation,
  ILecturerCardCreateRequest,
} from './useMutateLecturerCardCreate';

export type ILecturerCardUpdateRequest = ILecturerCardCreateRequest & {
  id: number;
};

export const updateLecturerCardValidation: ObjectSchema<ILecturerCardUpdateRequest> =
  createLecturerCardValidation.concat(
    object({
      id: number().required(),
    }),
  );

export const updateLecturerCardRequest = ({ id, ...payload }: ILecturerCardUpdateRequest) =>
  axios.patch(`/admin/author-cards/${id}`, payload);

export const useMutateLecturerCardUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, ILecturerCardUpdateRequest>(
    updateLecturerCardRequest,
    {
      onError: () => {
        notify('error', 'Повторите попытку позже');
      },
      onSuccess: () => {
        notify('success', 'Карточка лектора успешно обновлена');

        queryClient.invalidateQueries(lecturerCardCommonKeys.lecturerCards);
      },
    },
  );
};
