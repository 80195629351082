import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { exerciseQueryKeys } from '@exercises/exercises/api/exerciseQueryKeys';
import { IExercise } from '@exercises/exercises/types/IExercise';

export type IExerciseRequest = { id: number };
type IExerciseResponse = AxiosResponse<IExercise>;

export const exerciseRequest = async ({ id }: IExerciseRequest): Promise<IExerciseResponse> =>
  await axios.get(`/exercises/${id}`);

export function useQueryExercise(params: IExerciseRequest) {
  return useQuery<IExerciseResponse, AxiosError>(
    [exerciseQueryKeys.exercise, params],
    () => exerciseRequest(params),
    { enabled: params.id > 0 },
  );
}
