import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IAvatar } from '../types/IAvatar';
import { avatarCommonKeys } from './avatarCommonKeys';

type IAvatarRequest = { id: number };
type IAvatarResponse = AxiosResponse<IAvatar>;

export const avatarGetRequest = async ({ id }: IAvatarRequest): Promise<IAvatarResponse> =>
  await axios.get(`/avatars/${id}`);

export function useQueryAvatar(params: IAvatarRequest) {
  return useQuery<IAvatarResponse, AxiosError>(
    [avatarCommonKeys.avatar, params],
    () => avatarGetRequest(params),
    {
      enabled: params.id > 0,
    },
  );
}
