import { IAppProduct } from '@shop/appProducts/types/IAppProduct';
import { AppPromotionStatusEnumKeys } from '@shop/appPromotions/types/enums/AppPromotionStatusEnum';
import { IAppPromotionLocalizations } from '@shop/appPromotions/types/IAppPromotionLocalizations';

export type IAppPromotion = {
  discountPercent?: number | null;
  endDate?: Date | null;
  id: number;
  localizations: IAppPromotionLocalizations;
  products?: IAppProduct[];
  startDate?: Date | null;
  status?: AppPromotionStatusEnumKeys;
};
export const appPromotionKeys: Record<keyof IAppPromotion, string> = {
  discountPercent: 'discountPercent',
  endDate: 'endDate',
  id: 'id',
  localizations: 'localizations',
  products: 'products',
  startDate: 'startDate',
  status: 'status',
};
