import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { appSettingsQueryKeys } from '@settings/appSettings/api/appSettingsQueryKeys';

import { AppSettingsNameEnumKeys } from '../types/enums/AppSettingNameEnum';
import { IAppSetting } from '../types/IAppSetting';

type IAppSettingRequest = {
  name?: AppSettingsNameEnumKeys;
};

type IAppSettingResponse = AxiosResponse<IAppSetting>;

export const appSettingRequest = async ({
  name,
}: IAppSettingRequest): Promise<IAppSettingResponse> => await axios.get(`/app-settings/${name}`);

export function useQueryAppSetting(params: IAppSettingRequest) {
  return useQuery<IAppSettingResponse, AxiosError>(
    [appSettingsQueryKeys.appSetting, params],
    () => appSettingRequest(params),
    { enabled: !!params.name },
  );
}
