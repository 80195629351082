import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { fenQueryKeys } from '@exercises/fens/api/fenQueryKeys';
import { IFen } from '@exercises/fens/types/IFen';

export type IFenStatusUpdateRequest = Pick<IFen, 'id' | 'status'>;

type IFenStatusUpdateResponse = AxiosResponse<void>;

export const fenStatusUpdateRequest = async ({
  id,
  ...payload
}: IFenStatusUpdateRequest): Promise<IFenStatusUpdateResponse> =>
  await axios.patch(`/fen-records/${id}`, payload);

export function useMutateFenStatusUpdate() {
  const queryClient = useQueryClient();

  return useMutation<IFenStatusUpdateResponse, AxiosError, IFenStatusUpdateRequest>(
    fenStatusUpdateRequest,
    {
      onSuccess: () => queryClient.invalidateQueries(fenQueryKeys.fens),
    },
  );
}
