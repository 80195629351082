import { message } from 'antd';
import axios, { AxiosError } from 'axios';
import qs from 'qs';
import { Maybe } from 'yup';

import { IBackendError } from '@typess/IBackendError';

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token && config.headers && !config.url?.includes('http')) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.baseURL = process.env.REACT_APP_API_URL;
  config.paramsSerializer = {
    serialize: function (params) {
      return qs.stringify(params, { arrayFormat: 'comma' });
    },
  };
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError<Maybe<IBackendError>>) => {
    if (error && error.response) {
      if (
        error.response.status === 401 &&
        error.response?.data?.path != '/auth/login' &&
        error.request.responseURL.includes(process.env.REACT_APP_API_URL)
      ) {
        localStorage.removeItem('token');
        message.warning({
          content: 'Срок действия авторизации истек',
          key: 'authorizationErrorMessage',
        });
      }

      if (error.response.status >= 500) {
        message.error({
          content: 'Серверная ошибка. Попробуйте перезагрузить страницу',
          key: 'serverErrorMessage',
        });
      }

      return Promise.reject(error);
    }
  },
);
