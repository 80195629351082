import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import { array, date, mixed, number, object, ObjectSchema, string } from 'yup';

import { appPromotionsQueryKeys } from '@shop/appPromotions/api/appPromotionsQueryKeys';
import {
  AppPromotionStatusEnum,
  AppPromotionStatusEnumKeys,
} from '@shop/appPromotions/types/enums/AppPromotionStatusEnum';
import { IAppPromotion } from '@shop/appPromotions/types/IAppPromotion';

export type IAppPromotionCreateRequest = Pick<
  IAppPromotion,
  'discountPercent' | 'endDate' | 'startDate' | 'status'
> & { localizations: Record<'ru' | 'en', { title: string }>; productIds: number[] };

type IAppPromotionCreateResponse = AxiosResponse<void>;

export const appPromotionCreateInitial: IAppPromotionCreateRequest = {
  discountPercent: null,
  endDate: null,
  localizations: { en: { title: '' }, ru: { title: '' } },
  productIds: [],
  startDate: null,
  status: 'APPROVED',
};

export const appPromotionWhenStatusApprovedValidation = {
  is: (status) => status == 'APPROVED',
  then: (s) =>
    s.shape({
      discountPercent: number()
        .nullable()
        .required('Скидка обязательна для заполнения')
        .min(1, 'Минимальная скидка - 1%')
        .max(99, 'Максимальная скидка - 99%'),
      endDate: date()
        .nullable()
        .required('Конец периода кампании обязателен для заполнения')
        .test(
          'end_more_than_now_and_two_days',
          `Конец периода должен быть минимум ${moment()
            .add(2, 'days')
            .startOf('day')
            .format('LL')}`,
          (endDate) => moment(endDate).startOf('day') > moment().add(1, 'day').startOf('day'),
        )
        .test(
          'end_more_than_start',
          'Конец периода должен быть позже чем начало',
          (endDate, promotion) =>
            moment(endDate).startOf('day') > moment(promotion.parent.startDate).startOf('day'),
        ),
      localizations: object({
        en: object({
          title: string().required(),
        }),
        ru: object({
          title: string().required(),
        }),
      }),
      productIds: array().of(number().required()).min(1, 'Минимум 1 товар'),
      startDate: date()
        .nullable()
        .required('Начало периода кампании обязательно для заполнения')
        .test(
          'start_more_than_now_and_one_day',
          `Начало периода должно быть минимум ${moment()
            .add(1, 'day')
            .startOf('day')
            .format('LL')}`,
          (value) => moment(value).startOf('day') > moment().startOf('day'),
        )
        .test(
          'start_lower_than_start',
          'Начало периода должно быть раньше чем конец',
          (value, promotion) =>
            moment(promotion.parent.endDate).startOf('day') > moment(value).startOf('day'),
        ),
      status: mixed()
        .oneOf(Object.keys(AppPromotionStatusEnum), '')
        .required('Статус обязателен для заполнения'),
    }),
};

export const appPromotionCreateValidation: ObjectSchema<IAppPromotionCreateRequest> = object({
  discountPercent: number()
    .nullable()
    .min(1, 'Минимальная скидка - 1%')
    .max(99, 'Максимальная скидка - 99%'),
  endDate: date().nullable(),
  localizations: object({
    en: object({
      title: string().required(),
    }),
    ru: object({
      title: string().required(),
    }),
  }),
  productIds: array().of(number().required()).required(),
  startDate: date().nullable().min(moment().add(1, 'day').startOf('day')),
  status: string<AppPromotionStatusEnumKeys>().required('Статус обязателен для заполнения'),
}).when('.status', appPromotionWhenStatusApprovedValidation);

export const appPromotionCreateRequest = async (
  payload: IAppPromotionCreateRequest,
): Promise<IAppPromotionCreateResponse> => {
  return await axios.post('iap-promotions', {
    ...payload,
    endDate: payload.endDate ? moment(payload.endDate).format('YYYY-MM-DD') : payload.endDate,
    startDate: payload.startDate
      ? moment(payload.startDate).format('YYYY-MM-DD')
      : payload.startDate,
  });
};

export function useMutateAppPromotionsCreate() {
  const queryClient = useQueryClient();

  return useMutation<IAppPromotionCreateResponse, AxiosError, IAppPromotionCreateRequest>(
    appPromotionCreateRequest,
    {
      onSuccess: () => queryClient.invalidateQueries(appPromotionsQueryKeys.appPromotions),
    },
  );
}
