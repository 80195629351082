import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { notify } from '@utils/notify';

import { assessmentExerciseQueryKeys } from './assessmentExerciseQueryKeys';

type IAssessmentExerciseDeleteRequest = { id: number };

type IAssessmentExerciseDeleteResponse = AxiosResponse<void>;

export const AssessmentExerciseDeleteRequest = async ({
  id,
}: IAssessmentExerciseDeleteRequest): Promise<IAssessmentExerciseDeleteResponse> =>
  await axios.delete(`/admin/assessment-exercises/${id}`);

export function useMutateAssessmentExerciseDelete() {
  const queryClient = useQueryClient();

  return useMutation<
    IAssessmentExerciseDeleteResponse,
    AxiosError,
    IAssessmentExerciseDeleteRequest
  >(AssessmentExerciseDeleteRequest, {
    onSuccess: () => {
      notify('error', 'Задача удалена');

      queryClient.invalidateQueries(assessmentExerciseQueryKeys.assessmentExercises);
    },
  });
}
