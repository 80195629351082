import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum AvatarPageActionEnum {
  'create',
  'edit',
  'view',
}

export interface IAvatarsPageActions {
  action: keyof typeof AvatarPageActionEnum;
  actionId: number;
}

export const avatarPageActions: IPageActions<IAvatarsPageActions> = {
  action: {
    allowed: (value) => Object.keys(AvatarPageActionEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
};
