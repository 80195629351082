import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { object, ObjectSchema, string } from 'yup';

import { notify } from '@utils/notify';
import { videoQueryKeys } from '@videos/api/videoQueryKeys';
import { IVideoFile } from '@videos/types/IVideoFile';

export type IVideoUploadFinishRequest = {
  title: string;
  uuid: string;
  videoFileName: string;
};

type IVideoUploadFinishResponse = AxiosResponse<IVideoFile>;

export const videoUploadFinishInitial: IVideoUploadFinishRequest = {
  title: '',
  uuid: '',
  videoFileName: '',
};

export const videoUploadFinishValidation: ObjectSchema<IVideoUploadFinishRequest> = object({
  title: string().nullable().required('Название видео обязательно для заполнения'),
  uuid: string().nullable().required('Видеофайл обязателен для заполнения'),
  videoFileName: string().nullable().required(' '),
});

export const videoUploadFinishRequest = async (
  payload: IVideoUploadFinishRequest,
): Promise<IVideoUploadFinishResponse> => await axios.post('/videos/video-upload-finish', payload);

export function useMutateVideoUploadFinish() {
  const queryClient = useQueryClient();

  return useMutation<IVideoUploadFinishResponse, AxiosError, IVideoUploadFinishRequest>(
    videoUploadFinishRequest,
    {
      onSuccess: () => {
        notify(
          'success',
          'Видеофайл успешно загружен, через некоторое время оно будет доступно для выбора',
        );
        queryClient.refetchQueries(videoQueryKeys.videoFiles);
      },
    },
  );
}
