import { FC } from 'react';
import { FormItem, InputNumber } from 'formik-antd';

export const AppSettingEngineRatingFormik: FC<{ variables: object }> = ({ variables }) => {
  return (
    <div className="space-y-8">
      {Object.entries(variables).map(([playLevel]) => (
        <FormItem
          key={`ratingFormik${playLevel}`}
          label={`Уровень игры - ${playLevel}`}
          name={`variables.${playLevel}`}
        >
          <InputNumber
            className="w-full"
            name={`variables.${playLevel}`}
            placeholder="Рейтинг"
            prefix
          />
        </FormItem>
      ))}
    </div>
  );
};
