import { ExerciseGamePhaseEnumKeys } from '@exercises/exercises/types/enums/ExerciseGamePhaseEnum';
import { ExerciseStatusEnumKeys } from '@exercises/exercises/types/enums/ExerciseStatusEnum';
import { ExerciseTypeEnumKeys } from '@exercises/exercises/types/enums/ExerciseTypeEnum';
import { IExerciseProblem } from '@exercises/exercises/types/IExerciseProblem';
import { IExerciseTest } from '@exercises/exercises/types/IExerciseTest';
import { IStaff } from '@staff/types/IStaff';
import { ITag } from '@tags/types/ITag';
import { IUserPlayLevelNumber } from '@users/types/enums/UserPlayLevel';
import { IVideo } from '@videos/types/IVideo';

import { IExerciseCustomGame } from './IExerciseCustomGame';

export interface IExercise {
  createdBy: IStaff;
  customProblem?: IExerciseCustomGame;
  gamePhase: ExerciseGamePhaseEnumKeys;
  id: number;
  isCapitalization: boolean;
  isPawnStructure: boolean;
  isTactic: boolean;
  playLevels: IUserPlayLevelNumber[];
  problem?: IExerciseProblem;
  problemChecked?: boolean;
  solvedUsersCount: number;
  solvedUsersPercentage: number;
  status: ExerciseStatusEnumKeys;
  tags: ITag[];
  test?: IExerciseTest;
  type: ExerciseTypeEnumKeys;
  useForPuzzleRush: boolean;
  video?: IVideo;
}

export const exerciseKeys: Record<keyof IExercise, string> = {
  createdBy: 'createdBy',
  customProblem: 'customProblem',
  gamePhase: 'gamePhase',
  id: 'id',
  isCapitalization: 'isCapitalization',
  isPawnStructure: 'isPawnStructure',
  isTactic: 'isTactic',
  playLevels: 'playLevels',
  problem: 'problem',
  problemChecked: 'problemChecked',
  solvedUsersCount: 'solvedUsersCount',
  solvedUsersPercentage: 'solvedUsersPercentage',
  status: 'status',
  tags: 'tags',
  test: 'test',
  type: 'type',
  useForPuzzleRush: 'useForPuzzleRush',
  video: 'video',
};
