import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IUserStats } from '@users/types/IUserStats';

export type IUserStatsRequest = IPageAbleRequest & { id: number };

type IUserStatsResponse = AxiosResponse<IUserStats>;

export const userStatsRequest = async ({ id }: IUserStatsRequest): Promise<IUserStatsResponse> => {
  return await axios.get(`/customers/${id}/stats`);
};

export function useQueryUserStats(params: IUserStatsRequest) {
  return useQuery<IUserStatsResponse, AxiosError>(['userStats', params], () =>
    userStatsRequest(params),
  );
}
