import React from 'react';
import { Space } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

export const DraggableTableRow = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    isDragging,
    listeners,
    setActivatorNodeRef,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child, index) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <Space>
                <MenuOutlined
                  key={index}
                  ref={setActivatorNodeRef}
                  style={{ cursor: 'grab' }}
                  {...listeners}
                />
              </Space>
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};
