import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { object, ObjectSchema, ref, string } from 'yup';

export type IRegisterRequest = {
  fullName: string;
  link: string;
  password: string;
};
type IRegisterResponse = void;

export type IRegisterConfirmPasswordType = IRegisterRequest & { confirmPassword: string };

export const registerInitial: IRegisterConfirmPasswordType = {
  confirmPassword: '',
  fullName: '',
  link: '',
  password: '',
};

export const registerValidation: ObjectSchema<IRegisterConfirmPasswordType> = object().shape({
  confirmPassword: string()
    .oneOf([ref('password')], 'Пароли должны совпадать')
    .required(' '),
  fullName: string()
    .required('Имя обязательно для заполнения')
    .max(60, 'ФИО должны быть не более 60 символов'),
  link: string().required(' '),
  password: string()
    .required('Пароль обязателен для заполнения')
    .max(30, 'Пароль должен быть не более 30 символов')
    .min(6, 'Пароль должен быть не менее 6 символов'),
});

export const registerRequest = async (
  payload: IRegisterRequest,
): Promise<AxiosResponse<IRegisterResponse>> => {
  return await axios.post<IRegisterResponse>('/auth/link/finish', payload);
};

export function useMutateRegister() {
  const navigate = useNavigate();

  return useMutation<AxiosResponse<IRegisterResponse>, AxiosError, IRegisterRequest>(
    'register',
    registerRequest,
    {
      onError: (error) => {
        if (error.response?.status == 422) {
          message.warning(
            {
              className: 'font-bold',
              content: 'Срок действия истек или неверный формат, попробуйте снова позже',
            },
            3,
          );
          navigate('/login');
        }
      },
      onSuccess: () => {
        message.success('Успешная регистрация');
        navigate('/login');
      },
    },
  );
}
