import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { IFilters, IFiltersPropsDefault } from '@typess/IFilters';
import { IPageActions } from '@typess/IPageActions';
import { initActioned, initFiltered, toUrlParams } from '@utils/actionFilterService';
import { decodeUrlSearch } from '@utils/decodeUrlSearch';

export function useUrlFilteredActioned<
  FilterProps extends IFiltersPropsDefault | undefined = IFiltersPropsDefault,
  ActionProps extends object | undefined = object,
>(filters?: IFilters, actions?: IPageActions) {
  const [search, setSearch] = useSearchParams();

  const [filtered, setFiltered] = useState(initFiltered<FilterProps>(filters));
  const [actioned, setActioned] = useState(initActioned<ActionProps>(actions));

  const onFilter = (keys: Partial<FilterProps>) => {
    setFiltered((prevState) => ({
      ...prevState,
      ...keys,
    }));
  };

  const clearParams = () => {
    setActioned({});
  };

  useEffect(() => {
    const urlParams = toUrlParams({
      actioned,
      actions,
      filtered,
      filters,
    });

    if (urlParams != search.toString()) {
      setSearch(urlParams);
    }
  }, [filtered, actioned]);

  useEffect(() => {
    const urlParams = toUrlParams({
      actioned,
      actions,
      filtered,
      filters,
    });

    if (urlParams != decodeUrlSearch(search.toString())) {
      setFiltered(initFiltered<FilterProps>(filters));
      setActioned(initActioned<ActionProps>(actions));
    }
  }, [search]);

  return {
    actioned,
    clearParams,
    filtered,
    onFilter,
    setActioned,
    setFiltered,
  };
}
