import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { classifierQueryKeys } from '@settings/classifierSkillTypes/api/classifierQueryKeys';
import { IClassifierSkillType } from '@settings/classifierSkillTypes/types/IClassifierSkillType';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';

type IClassifierRequest = IPageAbleRequest;

type IClassifierResponse = IClassifierSkillType[];

export const classifierRequest = async (
  params?: IClassifierRequest,
): Promise<AxiosResponse<IClassifierResponse>> => {
  return await axios.get<IClassifierResponse>('/skill-types', { params });
};

export function useQueryClassifier(params?: IClassifierRequest) {
  return useQuery<AxiosResponse<IClassifierResponse>, AxiosError>(
    [classifierQueryKeys.classifierSkillTypes, params],
    () => classifierRequest(params),
  );
}
