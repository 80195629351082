import { Button, Popconfirm, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';

import { CountryImageText } from '@components/atoms/CountryImageText';
import { IVideoFile, videoFileKeys } from '@videos/types/IVideoFile';

export const videoFilesColumns = ({
  canEdit,
  onClickDelete,
  onClickView,
}: {
  canEdit: boolean;
  onClickDelete: ({ uuid }: { uuid: string }) => void;
  onClickView: ({ uuid }: { uuid: string }) => void;
}): ColumnsType<IVideoFile> => {
  const columns: ColumnsType<IVideoFile> = [
    {
      dataIndex: videoFileKeys.title,
      render: (title: IVideoFile['title'], { isUploading, uuid }) => (
        <Tooltip title={isUploading ? 'Видео еще не переведено в hls формат' : ''}>
          <Button danger={isUploading} onClick={() => onClickView({ uuid })} type="link">
            {title ?? '-'}
          </Button>
        </Tooltip>
      ),
      title: 'Название видео',
    },

    {
      dataIndex: videoFileKeys.uuid,
      title: 'UUID',
    },
    {
      dataIndex: videoFileKeys.createdDate,
      render: (createdDate: IVideoFile['createdDate']) =>
        createdDate ? `${moment(createdDate).format('LLL')}` : '-',
      title: 'Дата создания',
    },
    {
      dataIndex: videoFileKeys.author,
      key: videoFileKeys.author,
      render: (_, videoFile) => (
        <div className="space-y-2">
          {videoFile.author?.localizations.ru && (
            <div className="whitespace-nowrap mb-0 flex items-center gap-1" key="ru">
              <CountryImageText src="ru" width={15} />
              {videoFile.author?.localizations.ru}
            </div>
          )}
        </div>
      ),
      title: 'Автор',
    },
  ];

  if (!canEdit) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: (videoFile: IVideoFile) => (
        <div className="flex gap-x-1.5">
          <Popconfirm
            cancelText="Отменить"
            icon={<DeleteOutlined className="text-ant-red-5" />}
            okText="Удалить"
            okType="danger"
            onConfirm={() => onClickDelete({ uuid: videoFile.uuid })}
            placement="topLeft"
            title={
              <span className="text-sm">
                Вы уверены, что хотите удалить видеофайл
                <span className="font-bold"> &quot;{videoFile.title}&quot;</span>?
              </span>
            }
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
      title: 'Действия',
    },
  ];
};
