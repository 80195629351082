import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema } from 'yup';

import { notify } from '@utils/notify';

import { avatarGroupQueryKeys } from './avatarGroupQueryKeys';

export type IAvatarGroupAddItemRequest = { avatarId: number; id: number };

type IAvatarGroupAddItemResponse = AxiosResponse<void>;

export const avatarGroupAddItemInitialValue: IAvatarGroupAddItemRequest = {
  avatarId: 0,
  id: 0,
};

export const avatarGroupAddItemValidation: ObjectSchema<IAvatarGroupAddItemRequest> = object({
  avatarId: number().required(),
  id: number().required(),
});
export const avatarGroupAddItemRequest = async ({
  avatarId,
  id,
}: IAvatarGroupAddItemRequest): Promise<IAvatarGroupAddItemResponse> =>
  await axios.post(`/avatar-groups/${id}/item/${avatarId}`);

export function useMutateAvatarGroupAddItem() {
  const queryClient = useQueryClient();

  return useMutation<IAvatarGroupAddItemResponse, AxiosError, IAvatarGroupAddItemRequest>(
    avatarGroupAddItemRequest,
    {
      onSuccess: () => {
        notify('success', 'Доска добавлена в группу');
        queryClient.invalidateQueries(avatarGroupQueryKeys.avatarGroup);
        queryClient.invalidateQueries(avatarGroupQueryKeys.avatarsGroups);
      },
    },
  );
}
