import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IUserAgeStat } from '@dashboards/types/IUserAgeStat';

import { dashboardQueryKeys } from './dashboardQueryKeys';

export type IUsersAgeCategoryStatsRequest = {
  from?: string;
  to?: string;
};

type IUsersAgeCategoryStatsResponse = AxiosResponse<Record<string, IUserAgeStat>>;

export const usersAgeCategoryStatsRequest = async (
  params?: IUsersAgeCategoryStatsRequest,
): Promise<IUsersAgeCategoryStatsResponse> => {
  return await axios.get('/stats/age-category', { params });
};

export function useQueryUsersAgeCategoryStats(params?: IUsersAgeCategoryStatsRequest) {
  return useQuery<IUsersAgeCategoryStatsResponse, AxiosError>(
    [dashboardQueryKeys.usersAgeCategory, params],
    () => usersAgeCategoryStatsRequest(params),
  );
}
