import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, number, object, ObjectSchema, string } from 'yup';

import { notify } from '@utils/notify';

import { ChessPiecesEnum } from '../types/enums/ChessPiecesEnum';
import {
  ChessPiecesSetStatusEnumKeys,
  ChessPiecesSetTypeEnumKeys,
} from '../types/enums/ChessPiecesSetEnum';
import { IChessPiecesSet } from '../types/IChessPiecesSet';
import { chessPiecesQueryKeys } from './chessPiecesQueryKeys';

export type IChessPiecesSetCreateRequest = Pick<
  IChessPiecesSet,
  'status' | 'chessPieces' | 'price' | 'type' | 'localizations'
>;

export const createChessPiecesSetInitialValue: IChessPiecesSetCreateRequest = {
  chessPieces: [],
  localizations: { en: { name: '' }, ru: { name: '' } },
  price: 0,
  status: 'DRAFT',
  type: 'FREE',
};

export const createChessPiecesSetValidation: ObjectSchema<IChessPiecesSetCreateRequest> = object({
  chessPieces: array()
    .of(
      object().shape({
        imgUrl: string().required(''),
        type: string<ChessPiecesEnum>().required(),
      }),
    )
    .required(),
  localizations: object({
    en: object({ name: string().required() }),
    ru: object({ name: string().required() }),
  }),
  price: number().when('type', {
    is: (type: ChessPiecesSetTypeEnumKeys) => type == 'PAID',
    then: (s) => s.required(),
  }),
  status: string<ChessPiecesSetStatusEnumKeys>().required(),
  type: string<ChessPiecesSetTypeEnumKeys>().required(),
});

export const createChessPiecesSetRequest = (payload: IChessPiecesSetCreateRequest) =>
  axios.post(`/chess-piece-sets`, payload);

export const useMutateChessPiecesSetCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, IChessPiecesSetCreateRequest>(
    createChessPiecesSetRequest,
    {
      onError: () => {
        notify('error', 'Повторите попытку позже');
      },
      onSuccess: () => {
        notify('success', 'Коллекция фигур успешно создана');

        queryClient.invalidateQueries(chessPiecesQueryKeys.chessPieces);
      },
    },
  );
};
