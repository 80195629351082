import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IMapStage } from '../types/IMapStage';
import { mapStageQueryKeys } from './mapStagesQueryKeys';

export type IMapStageDetailsRequest = { id: number };
type IMapStageDetailsResponse = AxiosResponse<IMapStage>;

export const mapStageDetailsRequest = async ({
  id,
}: IMapStageDetailsRequest): Promise<IMapStageDetailsResponse> =>
  await axios.get(`/map-stage-definitions/${id}`);

export function useQueryMapStageDetails(params: IMapStageDetailsRequest) {
  return useQuery<IMapStageDetailsResponse, AxiosError>(
    [mapStageQueryKeys.mapStage, params],
    () => mapStageDetailsRequest(params),
    { enabled: params.id > 0 },
  );
}
