import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';

export const VideoTabs: FC<{ active: 'videos' | 'video-files' }> = ({ active }) => {
  const navigate = useNavigate();

  return (
    <Tabs
      activeKey={active}
      centered
      items={[
        { key: 'videos', label: 'Видео' },
        { key: 'video-files', label: 'Видеофайлы' },
      ]}
      onChange={(tab) => navigate(tab == 'video-files' ? '/videos/files' : '/videos')}
    />
  );
};
