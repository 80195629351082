import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { object, ObjectSchema, string } from 'yup';

import { tagQueryKeys } from '@tags/api/tagQueryKeys';
import { ITag } from '@tags/types/ITag';

import { AuthorRankEnumKeys } from '../types/AuthorRankEnum';
import { ITagTopicCreateRequest, tagTopicCreateValidation } from './useMutateTagTopicCreate';

export type ITagAuthorCreateRequest = ITagTopicCreateRequest & {
  authorRank: AuthorRankEnumKeys;
};
type ITagAuthorCreateResponse = AxiosResponse<ITag>;

export const tagAuthorCreateInitial: ITagAuthorCreateRequest = {
  authorRank: 'GM',
  localizations: { en: '', ru: '' },
  type: 'AUTHOR',
};

export const tagAuthorCreateValidation: ObjectSchema<ITagAuthorCreateRequest> = object({
  authorRank: string<AuthorRankEnumKeys>().required('Звание автора обязательно для заполнения'),
}).concat(tagTopicCreateValidation);

export const tagAuthorCreateRequest = async (
  payload: ITagAuthorCreateRequest,
): Promise<ITagAuthorCreateResponse> => await axios.post('/tags', payload);

export function useMutateTagAuthorCreate() {
  const queryClient = useQueryClient();

  return useMutation<ITagAuthorCreateResponse, AxiosError, ITagAuthorCreateRequest>(
    tagAuthorCreateRequest,
    { onSuccess: () => queryClient.invalidateQueries(tagQueryKeys.tags) },
  );
}
