import type { FC } from 'react';
import { Button, Modal, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, InputNumber, Select } from 'formik-antd';

import {
  IUpdateBoardRequest,
  useMutateBoardUpdate,
} from '@collections/boards/api/useMutateBoardUpdate';
import { updateBoardValidation } from '@collections/boards/api/useMutateBoardUpdate';
import { useQueryBoard } from '@collections/boards/api/useQueryBoard';
import { BoardTypeEnum } from '@collections/boards/types/enums/boardTypeEnum';
import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { FileUpload } from '@components/molecules/files/FileUpload';

type Props = {
  id?: number;
  isOpen: boolean;
  onClose: () => void;
};

export const EditBoardModal: FC<Props> = ({ id = 0, isOpen, onClose }) => {
  const updateBoardMutation = useMutateBoardUpdate();
  const boardQuery = useQueryBoard({ id });
  const board = boardQuery.data?.data;

  return (
    <Modal footer={null} onCancel={onClose} open={isOpen} title="Редактировать доску">
      {boardQuery.isFetching && <BaseSkeleton />}

      {boardQuery.isFetching ? (
        <BaseSkeleton />
      ) : !board ? (
        <NotFoundResult title="Доска не найдена" />
      ) : (
        <Formik<IUpdateBoardRequest>
          enableReinitialize
          initialValues={{
            ...board,
            darkPieceImgUrl: board.darkPieceImgUrl,
            id: board.id,
            imgUrl: board.imgUrl,
            lightPieceImgUrl: board.lightPieceImgUrl,
            localizations: board.localizations,
            status: board.status,
            type: board.type,
          }}
          onSubmit={(values) =>
            updateBoardMutation.mutateAsync(values).then(() => {
              onClose();
            })
          }
          validateOnBlur={false}
          validationSchema={updateBoardValidation}
        >
          {({ setFieldValue, values: { type } }) => (
            <Form className="gap-4 md:gap-6" layout="vertical">
              <FormItem
                label={<CountryImageText src="ru" text="Название на русском" />}
                name="localizations.ru.name"
              >
                <Input name="localizations.ru.name" prefix />
              </FormItem>
              <FormItem
                label={<CountryImageText src="en" text="Название на английском" />}
                name="localizations.en.name"
              >
                <Input name="localizations.en.name" prefix />
              </FormItem>
              <FormItem label="Тип доски" name="type">
                <Select
                  defaultValue="FREE"
                  name="type"
                  optionFilterProp="label"
                  options={
                    Object.entries(BoardTypeEnum).map(([key, value]) => ({
                      label: value,
                      value: key,
                    })) || []
                  }
                  placeholder="Тип доски"
                />
              </FormItem>
              {type === 'PAID' && (
                <FormItem label="Стоимость" name="price">
                  <InputNumber
                    className="w-full"
                    controls={false}
                    max={999999}
                    min={1}
                    name="price"
                    prefix
                  />
                </FormItem>
              )}
              <Typography className="font-bold"> Чёрные поля</Typography>
              <div className="grid grid-cols-2 gap-6 mt-2">
                <FormItem className="upload-form-item" label="SVG" name="darkPieceImgUrl">
                  <FileUpload
                    accept=" .svg"
                    defaultFileList={
                      board.darkPieceImgUrl
                        ? [
                            {
                              name: '',
                              thumbUrl: board.darkPieceImgUrl,
                              uid: board.darkPieceImgUrl,
                              url: board.darkPieceImgUrl,
                            },
                          ]
                        : undefined
                    }
                    folderName="darkPieceImgUrl"
                    isSvg={true}
                    maxCount={1}
                    onChange={(info, linkInfo) => {
                      if (!info.file.status || info.file.status == 'removed')
                        setFieldValue('darkPieceImgUrl', '');
                      if (info.file.status == 'done')
                        setFieldValue('darkPieceImgUrl', linkInfo.downloadUrl);
                    }}
                  />
                </FormItem>
                <FormItem className="upload-form-item" label="PNG" name="darkPieceImgUrl">
                  <FileUpload
                    accept=" .png"
                    defaultFileList={
                      board.darkPieceImgUrl
                        ? [
                            {
                              name: '',
                              thumbUrl: board.darkPieceImgUrlPng,
                              uid: board.darkPieceImgUrlPng,
                              url: board.darkPieceImgUrlPng,
                            },
                          ]
                        : undefined
                    }
                    folderName="darkPieceImgUrlPng"
                    maxCount={1}
                    onChange={(info, linkInfo) => {
                      if (!info.file.status || info.file.status == 'removed')
                        setFieldValue('darkPieceImgUrlPng', '');
                      if (info.file.status == 'done')
                        setFieldValue('darkPieceImgUrlPng', linkInfo.downloadUrl);
                    }}
                  />
                </FormItem>
              </div>
              <Typography className="font-bold">Белые поля</Typography>
              <div className="grid grid-cols-2 gap-6 mt-2">
                <FormItem className="upload-form-item" label="SVG" name="lightPieceImgUrl">
                  <FileUpload
                    accept=".svg"
                    defaultFileList={
                      board.lightPieceImgUrl
                        ? [
                            {
                              name: '',
                              thumbUrl: board.lightPieceImgUrl,
                              uid: board.lightPieceImgUrl,
                              url: board.lightPieceImgUrl,
                            },
                          ]
                        : undefined
                    }
                    folderName="lightPieceImgUrl"
                    isSvg={true}
                    maxCount={1}
                    onChange={(info, linkInfo) => {
                      if (!info.file.status || info.file.status == 'removed')
                        setFieldValue('lightPieceImgUrl', '');
                      if (info.file.status == 'done')
                        setFieldValue('lightPieceImgUrl', linkInfo.downloadUrl);
                    }}
                  />
                </FormItem>{' '}
                <FormItem className="upload-form-item" label="PNG" name="lightPieceImgUrl">
                  <FileUpload
                    accept=".png"
                    defaultFileList={
                      board.lightPieceImgUrl
                        ? [
                            {
                              name: '',
                              thumbUrl: board.lightPieceImgUrlPng,
                              uid: board.lightPieceImgUrlPng,
                              url: board.lightPieceImgUrlPng,
                            },
                          ]
                        : undefined
                    }
                    folderName="lightPieceImgUrlPng"
                    maxCount={1}
                    onChange={(info, linkInfo) => {
                      if (!info.file.status || info.file.status == 'removed')
                        setFieldValue('lightPieceImgUrlPng', '');
                      if (info.file.status == 'done')
                        setFieldValue('lightPieceImgUrlPng', linkInfo.downloadUrl);
                    }}
                  />
                </FormItem>
              </div>
              <Typography className="font-bold">Картинка доски</Typography>
              <div className="grid grid-cols-2 gap-6 mt-2">
                <FormItem className="upload-form-item" label="SVG" name="imgUrl">
                  <FileUpload
                    accept=".svg"
                    defaultFileList={
                      board.imgUrl
                        ? [
                            {
                              name: '',
                              thumbUrl: board.imgUrl,
                              uid: board.imgUrl,
                              url: board.imgUrl,
                            },
                          ]
                        : undefined
                    }
                    folderName="imgUrl"
                    isSvg
                    maxCount={1}
                    onChange={(info, linkInfo) => {
                      if (!info.file.status || info.file.status == 'removed')
                        setFieldValue('imgUrl', '');
                      if (info.file.status == 'done') setFieldValue('imgUrl', linkInfo.downloadUrl);
                    }}
                  />
                </FormItem>
                <FormItem className="upload-form-item" label="PNG" name="imgUrl">
                  <FileUpload
                    accept=".png"
                    defaultFileList={
                      board.imgUrl
                        ? [
                            {
                              name: '',
                              thumbUrl: board.imgUrlPng,
                              uid: board.imgUrlPng,
                              url: board.imgUrlPng,
                            },
                          ]
                        : undefined
                    }
                    folderName="imgUrlPng"
                    maxCount={1}
                    onChange={(info, linkInfo) => {
                      if (!info.file.status || info.file.status == 'removed')
                        setFieldValue('imgUrlPng', '');
                      if (info.file.status == 'done')
                        setFieldValue('imgUrlPng', linkInfo.downloadUrl);
                    }}
                  />
                </FormItem>
              </div>
              <div className="flex gap-4 justify-end">
                <Button onClick={onClose}>Отмена</Button>
                <Button htmlType="submit" loading={updateBoardMutation.isLoading} type="primary">
                  Сохранить
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
