import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import AuthRoute from '@components/molecules/routes/AuthRoute';
import { Navbar } from '@components/organisms/navbar/Navbar';

const { Content } = Layout;

const AdminLayout: FC = () => {
  return (
    <AuthRoute auth>
      <Layout className="pb-10 min-h-full">
        <Navbar />
        <Layout className="space-y-6 mt-5">
          <Content className="px-4 md:px-16">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </AuthRoute>
  );
};

export default AdminLayout;
