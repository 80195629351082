export enum SubscriptionType {
  DISCOUNT_MONTH = 'subscription:monthly-promo',
  DISCOUNT_YEAR = 'subscription:annual-promo',
  MONTH = 'subscription:monthly',
  YEAR = 'subscription:annual',
}

export const getSubscriptions = () => [
  {
    label: 'Годовая подписка',
    value: SubscriptionType.YEAR,
  },
  {
    label: 'Скидочная годовая подписка',
    value: SubscriptionType.DISCOUNT_YEAR,
  },
  {
    label: 'Месячная подпсика',
    value: SubscriptionType.MONTH,
  },
  {
    label: 'Скидочная месячная подписка',
    value: SubscriptionType.DISCOUNT_MONTH,
  },
];
