import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import AuthRoute from '@components/molecules/routes/AuthRoute';
import { animated, useSpring } from '@react-spring/web';

const AuthLayout: FC = () => {
  const styles = useSpring({
    config: { duration: 500 },
    from: { color: 'red', opacity: 0 },
    to: { color: 'red', opacity: 1 },
  });

  return (
    <AuthRoute auth={false}>
      <Layout className="min-h-full flex items-center justify-center py-6 md:py-12 px-4 sm:px-6 lg:px-8">
        <Content className="max-w-lg w-full items-center flex">
          <div className="container">
            <div className="py-6 sm:py-9 px-7 sm:px-14 bg-white rounded-2xl">
              <animated.div style={styles}>{<Outlet />}</animated.div>
            </div>
          </div>
        </Content>
      </Layout>
    </AuthRoute>
  );
};

export default AuthLayout;
