import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {
  CalendarOutlined,
  CaretRightOutlined,
  CloseOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import { ChessPieceImageBox } from '@collections/chess-pieces/components/atoms/ChessPieceImageBox';
import {
  ChessPiecesSetStatusEnum,
  ChessPiecesSetStatusEnumKeys,
  ChessPiecesSetTypeEnum,
} from '@collections/chess-pieces/types/enums/ChessPiecesSetEnum';
import {
  chessPiecesSetKeys,
  IChessPiecesSet,
} from '@collections/chess-pieces/types/IChessPiecesSet';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { PencilIcon } from '@components/atoms/icons';

export const chessPiecesColumns = ({
  isSortable,
  onClickDelete,
  onClickEdit,
  onClickPause,
}: {
  isSortable?: boolean;
  onClickDelete?: ({ id }: { id: number }) => void;
  onClickEdit?: (id: number) => void;
  onClickPause?: ({ id, status }: { id: number; status: ChessPiecesSetStatusEnumKeys }) => void;
}): ColumnsType<IChessPiecesSet> => {
  const columns: ColumnsType<IChessPiecesSet> = [
    isSortable
      ? {
          align: 'center' as const,
          key: 'sort',
          width: 50,
        }
      : {},
    {
      dataIndex: chessPiecesSetKeys.id,
      render: (id: IChessPiecesSet['id'], chessPiecesSet) => {
        return <Link to={`/collections/figures/${chessPiecesSet.id}`}>{id}</Link>;
      },
      title: 'ID',
    },
    {
      dataIndex: chessPiecesSetKeys.localizations,
      render: (localizations: IChessPiecesSet['localizations']) => {
        return (
          <div>
            <CountryImageText src="en" text={localizations?.en?.name || '-'} />
            <CountryImageText src="ru" text={localizations?.ru?.name || '-'} />
          </div>
        );
      },
      title: 'Название коллекции',
    },
    {
      dataIndex: chessPiecesSetKeys.imgUrl,
      render: (imgUrl: IChessPiecesSet['imgUrl']) => {
        return <ReactSVG src={imgUrl} />;
      },
      title: 'Доска',
    },
    {
      dataIndex: chessPiecesSetKeys.chessPieces,
      render: (chessPieces: IChessPiecesSet['chessPieces']) => (
        <div className="grid grid-cols-3 xl:grid-cols-6 gap-x-4 gap-y-8 p-3 place-items-center min-w-max">
          {chessPieces?.map((piece) => (
            <ChessPieceImageBox key={piece.id} piece={piece} size="sm" />
          ))}
        </div>
      ),
      title: 'Фигуры',
    },
    {
      dataIndex: chessPiecesSetKeys.price,
      title: 'Цена',
    },
    {
      dataIndex: chessPiecesSetKeys.type,
      render: (type: IChessPiecesSet['type']) => {
        return type ? ChessPiecesSetTypeEnum[type] : '-';
      },
      title: 'Тип',
    },
    {
      dataIndex: chessPiecesSetKeys.createdDate,
      render: (createdDate: IChessPiecesSet['createdDate']) =>
        createdDate ? (
          <div>
            <p className="mb-0">
              <CalendarOutlined /> {moment(createdDate).format('LL')}
            </p>
          </div>
        ) : (
          <span>-</span>
        ),
      title: 'Дата создания',
    },
    {
      dataIndex: chessPiecesSetKeys.updatedDate,
      render: (createdDate: IChessPiecesSet['updatedDate']) =>
        createdDate ? (
          <div>
            <p className="mb-0">
              <CalendarOutlined /> {moment(createdDate).format('LL')}
            </p>
          </div>
        ) : (
          <span>-</span>
        ),
      title: 'Дата обновления',
    },
    {
      dataIndex: chessPiecesSetKeys.status,
      render: (status: IChessPiecesSet['status']) => {
        return status ? ChessPiecesSetStatusEnum[status] : '-';
      },
      title: 'Статус',
    },
  ];

  return [
    ...columns,
    {
      key: 'action',
      render: ({ id, localizations, status }: IChessPiecesSet) => (
        <div className="flex gap-1.5">
          {onClickPause && (
            <Popconfirm
              cancelText="Отменить"
              icon={
                status === 'ACTIVE' ? (
                  <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                ) : (
                  <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                )
              }
              okText={status === 'ACTIVE' ? 'Приостановить' : 'Запустить'}
              okType="primary"
              onConfirm={() =>
                onClickPause({
                  id: id,
                  status: status === 'ACTIVE' ? 'DRAFT' : 'ACTIVE',
                })
              }
              placement="topLeft"
              title={
                <p className="text-sm mb-0">
                  Вы уверены, что хотите
                  <span className="font-semibold">
                    {status === 'ACTIVE' ? ' приостановить ' : ' запустить '}
                    {id}?
                  </span>
                </p>
              }
            >
              <Button
                ghost
                icon={status === 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
                type="primary"
              />
            </Popconfirm>
          )}

          {onClickEdit && (
            <Button ghost icon={<PencilIcon />} onClick={() => onClickEdit(id)} type="primary" />
          )}
          {onClickDelete && (
            <Popconfirm
              cancelText="Отменить"
              icon={isSortable ? <CloseOutlined /> : <DeleteOutlined className="text-ant-red-5" />}
              okText="Удалить"
              okType="danger"
              onConfirm={() => onClickDelete({ id })}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что хотите {isSortable ? 'убрать' : 'удалить'} коллекцию
                  <span className="font-bold"> &quot;{localizations?.ru?.name || '-'}&quot;</span>?
                </span>
              }
            >
              <Button danger icon={isSortable ? <CloseOutlined /> : <DeleteOutlined />} />
            </Popconfirm>
          )}
        </div>
      ),
      title: 'Действия',
    },
  ];
};
