import { TagsSelectMultiple } from '@components/molecules/filters/dynamic/TagsSelectMultipleFilter';
import { CheckboxFilter } from '@components/molecules/filters/static/CheckboxFilter';
import { InputFilter } from '@components/molecules/filters/static/InputFilter';
import { RadioFilter } from '@components/molecules/filters/static/RadioFilter';
import { RangeFilter } from '@components/molecules/filters/static/RangeFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { IFilters } from '@typess/IFilters';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';
import { VideoStatusEnum } from '@videos/types/enums/VideoStatusEnum';
import { videoKeys } from '@videos/types/IVideo';

export const videosFilters: IFilters = {
  ...paginationBaseFilters,
  author: {
    input: <TagsSelectMultiple placeholder="Выберите автора" type="AUTHOR" />,
    queries: [{ key: 'authorIds' }],
    sort: true,
  },
  [videoKeys.playLevel]: {
    input: (
      <CheckboxFilter
        options={Object.entries(userPlayLevels).map(([value, label]) => ({ label, value }))}
      />
    ),
    queries: [{ key: 'playLevels' }],
    sort: true,
  },
  [videoKeys.id]: {
    input: <InputFilter placeholder="Введите ID" />,
    queries: [{ key: 'id' }],
  },
  [videoKeys.localizations]: {
    input: <InputFilter placeholder="Введите название" />,
    queries: [{ key: 'title' }],
    sort: true,
  },
  [videoKeys.localizations]: {
    input: <InputFilter placeholder="Введите название" />,
    queries: [{ key: 'fullTitle' }],
    sort: true,
  },
  [videoKeys.isCartoon]: {
    input: (
      <RadioFilter
        options={[
          { label: 'Мультфильм', value: 'true' },
          { label: 'Видеоурок', value: 'false' },
        ]}
      />
    ),
  },

  [videoKeys.price]: {
    input: <RangeFilter placeholderMax="Максимум" placeholderMin="Минимум" />,
    queries: [{ key: 'priceFrom' }, { key: 'priceTo' }],
    sort: true,
  },
  [videoKeys.status]: {
    input: (
      <CheckboxFilter
        options={Object.entries(VideoStatusEnum).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },
  [videoKeys.tagMastering]: {
    input: <RangeFilter placeholderMax="Максимум" placeholderMin="Минимум" />,
    queries: [{ key: 'tagMasteringFrom' }, { key: 'tagMasteringTo' }],
    sort: true,
  },
  [videoKeys.sequence]: {
    input: <RangeFilter placeholderMax="Максимум" placeholderMin="Минимум" />,
    queries: [{ key: 'sequenceFrom' }, { key: 'sequenceTo' }],
    sort: true,
  },
  [videoKeys.tags]: {
    input: <TagsSelectMultiple placeholder="Выберите тему" type="TOPIC" />,
    queries: [{ key: 'tagIds' }],
  },
  [videoKeys.viewTotal]: {
    input: <RangeFilter placeholderMax="Максимум" placeholderMin="Минимум" />,
    queries: [{ key: 'viewCountFrom' }, { key: 'viewCountTo' }],
    sort: true,
  },
};
