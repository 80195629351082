import { FC, useState } from 'react';
import { Button, Modal, Steps, Switch as AntSwitch } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Radio, Select, Switch } from 'formik-antd';

import { CountryImageText } from '@components/atoms/CountryImageText';
import {
  exerciseCustomGameCreateInitial,
  exerciseCustomGameCreateValidation,
  IExerciseCustomGameCreateRequest,
  useMutateExerciseCustomGameCreate,
} from '@exercises/exercises/api/useMutateExerciseCustomGameCreate';
import { ExerciseFigureTypeEnum } from '@exercises/exercises/types/enums/ExerciseFigureTypeEnum';
import { IUserPlayLevelNumber, userPlayLevels } from '@users/types/enums/UserPlayLevel';
import { notify } from '@utils/notify';
import { useQueryVideos } from '@videos/api/useQueryVideos';

export const ExerciseCustomGameModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
}> = ({ isOpen, onCancel }) => {
  const videos = useQueryVideos({ isCartoon: false });
  const [step, setStep] = useState(0);
  const customGameCreate = useMutateExerciseCustomGameCreate();
  const [indicateMoveCount, setIndicateMoveCount] = useState(false);

  return (
    <Modal
      className="px-4 w-[720px]"
      footer={null}
      onCancel={() => {
        onCancel();
      }}
      open={isOpen}
      title={
        <h1 className="text-xl m-0 space-x-1">
          <span>Создать кастомное упражнение</span>
        </h1>
      }
    >
      <Formik<IExerciseCustomGameCreateRequest>
        enableReinitialize
        initialValues={{ ...exerciseCustomGameCreateInitial }}
        onSubmit={(values, { resetForm }) => {
          customGameCreate.mutateAsync(values).then((data) => {
            if (data.status == 201 || data.status == 200) {
              notify('success', 'Кастомное упражнение создано');
              onCancel();
              resetForm();
            }
          });
        }}
        validationSchema={exerciseCustomGameCreateValidation}
      >
        {({ setFieldValue, values }) => {
          return (
            <Form className="space-y-6" layout="vertical" validateTrigger="submit">
              <Steps
                className="my-8 w-[70%] m-auto"
                current={step}
                items={[
                  {
                    title: 'Детали кастомной игры',
                  },
                  {
                    title: 'Общие настройки',
                  },
                ]}
                size="small"
              />
              {step == 0 && (
                <>
                  <FormItem label="FEN" name="boardState">
                    <Input maxLength={100} name="boardState" placeholder="Введите FEN" suffix />
                  </FormItem>
                  <FormItem label="Звезды/Фигуры" name="type">
                    <Radio.Group
                      name="type"
                      options={Object.entries(ExerciseFigureTypeEnum).map(([key, value]) => ({
                        label: value,
                        value: key,
                      }))}
                    />
                  </FormItem>
                  {values.type == 'FIGURE' && (
                    <FormItem
                      label={<span className="text-center">Может ли фигура соперника есть?</span>}
                      name="opponentCanTake"
                    >
                      <Switch name="isCapitalization" />
                    </FormItem>
                  )}
                  <Form.Item
                    label={<span className="text-center">Указать кол-во ходов</span>}
                    name="status"
                  >
                    <Input hidden name="status" suffix />
                    <AntSwitch
                      checked={indicateMoveCount}
                      onChange={(checked) => setIndicateMoveCount(checked)}
                    />
                  </Form.Item>
                  {indicateMoveCount && (
                    <FormItem
                      label={<span className="text-center">Указать кол-во</span>}
                      name="moveCount"
                    >
                      <Input name="moveCount" />
                    </FormItem>
                  )}
                </>
              )}
              {step == 1 && (
                <>
                  <FormItem
                    className="col-span-2 mb-2"
                    label={<CountryImageText src="en" width={15} />}
                    name={`localizations.name.en`}
                  >
                    <Input
                      maxLength={250}
                      name={`localizations.en.name`}
                      placeholder="Сообщение Каиссы"
                      showCount
                    />
                  </FormItem>
                  <FormItem
                    className="col-span-2 mb-2"
                    label={<CountryImageText src="ru" width={15} />}
                    name={`localizations.name.ru`}
                  >
                    <Input
                      maxLength={250}
                      name={`localizations.ru.name`}
                      placeholder="Сообщение Каиссы"
                      showCount
                    />
                  </FormItem>
                  <FormItem label="Выберите видео" name="videoId">
                    <Select
                      allowClear
                      className="gap-y-2"
                      name="videoId"
                      optionFilterProp="label"
                      options={
                        videos.data?.data.content.map((video) => ({
                          label: video.localizations.en?.fullTitle,
                          value: video.id,
                        })) || []
                      }
                      placeholder="Видео"
                      showSearch
                    />
                  </FormItem>
                  <FormItem label="Уровень" name="playLevels">
                    <Select
                      mode="multiple"
                      name={'playLevels'}
                      options={Object.keys(userPlayLevels)
                        .map(Number)
                        .map((playLevel) => ({
                          disabled:
                            !!values.playLevels?.length &&
                            !values.playLevels?.includes(playLevel as IUserPlayLevelNumber),
                          label: userPlayLevels[playLevel],
                          value: playLevel,
                        }))}
                      placeholder="Уровень"
                      showSearch={false}
                    />
                  </FormItem>
                  <Form.Item
                    label={<span className="text-center">Запустить немедленно</span>}
                    name="status"
                  >
                    <Input hidden name="status" suffix />
                    <AntSwitch
                      checked={values.status == 'ACTIVE'}
                      onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
                    />
                  </Form.Item>
                </>
              )}
              {step == 0 && (
                <div className="grid md:grid-cols-2 gap-5">
                  <Button block onClick={onCancel}>
                    Отменить
                  </Button>
                  <Button block onClick={() => setStep(1)} type="primary">
                    <span className="max-w-full overflow-ellipsis overflow-hidden">Далее</span>
                  </Button>
                </div>
              )}
              {step == 1 && (
                <div className="grid md:grid-cols-2 gap-5">
                  <Button block onClick={() => setStep(0)}>
                    Назад
                  </Button>
                  <Button
                    block
                    htmlType="submit"
                    // loading={testCreate.isLoading}
                    type="primary"
                  >
                    <span className="max-w-full overflow-ellipsis overflow-hidden">
                      {values['status'] == 'ACTIVE'
                        ? 'Создать и запустить'
                        : 'Сохранить как черновик'}
                    </span>
                  </Button>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
