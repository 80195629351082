import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { notify } from '@utils/notify';

import { IVersion } from '../types/IVersion';
import { versionsQueryKeys } from './versionsQueryKeys';

type VersionActivateRequest = { id: number };

type VersionActivateResponse = AxiosResponse<IVersion>;

export const versionActivateRequest = async ({
  id,
}: VersionActivateRequest): Promise<VersionActivateResponse> => {
  return await axios.post(`/v2/mobile-versions/${id}/activate`);
};

export function useMutateVersionActivate() {
  const queryClient = useQueryClient();

  return useMutation<VersionActivateResponse, AxiosError, VersionActivateRequest>(
    versionActivateRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(versionsQueryKeys.versions);
        notify('success', 'Версия теперь активна');
      },
    },
  );
}
