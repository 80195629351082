import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { promocodesQueryKeys } from '@settings/promocodes/api/promocodesQueryKeys';
import { IPromocodeGroup } from '@settings/promocodes/types/IPromocodeGroup';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

export type IPromocodeGroupsRequest = IPageAbleRequest & {
  code?: string;
};

type IPromocodeGroupsResponse = AxiosResponse<IPageAbleResponse<IPromocodeGroup>>;

export const promocodeGroupsRequest = async (
  params?: IPromocodeGroupsRequest,
): Promise<IPromocodeGroupsResponse> => {
  return await axios.get('/promocode-groups', { params });
};

export function useQueryPromocodeGroups(params?: IPromocodeGroupsRequest) {
  return useQuery<IPromocodeGroupsResponse, AxiosError>(
    [promocodesQueryKeys.promocodeGroups, params],
    () => promocodeGroupsRequest(params),
  );
}
