import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { fenQueryKeys } from '@exercises/fens/api/fenQueryKeys';
import { FenStatusEnumKeys } from '@exercises/fens/types/enums/FenStatusEnum';
import { IFen } from '@exercises/fens/types/IFen';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

export type IFensRequest = IPageAbleRequest & {
  createdByUserId?: number | string;
  id?: number | string;
  messageFromKaissa?: string;
  name?: string;
  status?: FenStatusEnumKeys;
  value?: string;
  videoIds?: string[] | number[];
};

type IFensResponse = AxiosResponse<IPageAbleResponse<IFen>>;

export const fensRequest = async (params?: IFensRequest): Promise<IFensResponse> =>
  await axios.get('/fen-records', { params });

export function useQueryFens(params?: IFensRequest) {
  return useQuery<IFensResponse, AxiosError>([fenQueryKeys.fens, params], () =>
    fensRequest(params),
  );
}
