import { FC } from 'react';
import { Button, Modal } from 'antd';
import { QuestionOutlined, SearchOutlined } from '@ant-design/icons';

import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { FaChessKnightIcon } from '@components/atoms/icons';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { useQueryExercise } from '@exercises/exercises/api/useQueryExercise';

import { CustomGameEditFormik } from '../molecules/CustomGameEditFormik';
import { ProblemEditFormik } from '../molecules/ProblemEditFormik';
import { TestEditFormik } from '../molecules/TestEditFormik';

export const ExerciseEditModal: FC<{
  id?: number;
  isOpen: boolean;
  onCancel: () => void;
  openView: () => void;
}> = ({ id = 0, isOpen, onCancel, openView }) => {
  const exerciseQuery = useQueryExercise({ id });
  const exercise = exerciseQuery.data?.data;

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3 top-10"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        exercise?.type && (
          <div className="flex flex-row gap-4 truncate">
            <h1 className="text-xl m-0 flex items-center gap-1">
              {exercise.type == 'TEST' && (
                <>
                  <QuestionOutlined className="text-ant-orange-6" /> Тест #{id}
                </>
              )}
              {exercise.type == 'PROBLEM' && (
                <>
                  <FaChessKnightIcon /> Упражнение #{id}
                </>
              )}
              {exercise.type == 'CUSTOM_PROBLEM' && (
                <>
                  <FaChessKnightIcon /> Кастомное упражнение #{id}
                </>
              )}
            </h1>
            <Button icon={<SearchOutlined />} onClick={openView} shape="circle" />
          </div>
        )
      }
    >
      {exerciseQuery.isLoading && <BaseSkeleton />}
      {!exercise && <NotFoundResult title="Упражнение не найдено" />}
      {exercise && exercise?.type == 'PROBLEM' && exercise.problem && (
        <ProblemEditFormik
          exercise={{ ...exercise, problem: exercise.problem }}
          onCancel={onCancel}
        />
      )}
      {exercise && exercise?.type == 'TEST' && exercise.test && (
        <TestEditFormik exercise={{ ...exercise, test: exercise.test }} onCancel={onCancel} />
      )}
      {exercise && exercise?.type == 'CUSTOM_PROBLEM' && exercise.customProblem && (
        <CustomGameEditFormik
          exercise={{ ...exercise, customProblem: exercise.customProblem }}
          onCancel={onCancel}
        />
      )}
    </Modal>
  );
};
