import { FC } from 'react';
import { Button, Modal, Switch as AntSwitch, Upload } from 'antd';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select, Switch } from 'formik-antd';
import { LinkOutlined, UploadOutlined } from '@ant-design/icons';

import { FaChessKnightIcon } from '@components/atoms/icons';
import {
  exerciseProblemCreateInitial,
  exerciseProblemCreateValidation,
  IExerciseProblemCreateRequest,
  useMutateExerciseProblemCreate,
} from '@exercises/exercises/api/useMutateExerciseProblemCreate';
import { ExerciseGamePhaseEnum } from '@exercises/exercises/types/enums/ExerciseGamePhaseEnum';
import { exerciseKeys } from '@exercises/exercises/types/IExercise';
import { IUserPlayLevelNumber, userPlayLevels } from '@users/types/enums/UserPlayLevel';
import { notify } from '@utils/notify';
import { useQueryVideos } from '@videos/api/useQueryVideos';

export const ExerciseProblemCreateModal: FC<{
  initialValue?: IExerciseProblemCreateRequest;
  isGroupNameDisabled?: boolean;
  isOpen: boolean;
  onCancel: () => void;
}> = ({ initialValue, isGroupNameDisabled, isOpen, onCancel }) => {
  const videos = useQueryVideos({ isCartoon: false });
  const problemCreate = useMutateExerciseProblemCreate();

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <h1 className="text-xl m-0 flex items-center gap-1">
          <FaChessKnightIcon /> Загрузить PGN
        </h1>
      }
    >
      <Formik<IExerciseProblemCreateRequest>
        initialValues={initialValue ? initialValue : exerciseProblemCreateInitial}
        onSubmit={(values, { resetForm, setFieldError }) => {
          problemCreate
            .mutateAsync(values)
            .then((data) => {
              if (data.status == 201 || data.status == 200) {
                notify('success', 'PGN загружен');
                onCancel();
                resetForm();
              }
            })
            .catch((e: AxiosError) => {
              if (e.response?.status == 422) {
                setFieldError('file', 'Неверный формат pgn файла');
              }
            });
        }}
        validationSchema={exerciseProblemCreateValidation}
      >
        {({ errors, setFieldValue, touched, values }) => (
          <Form className="mt-8 space-y-8" layout="vertical">
            <FormItem label="PGN" name="file">
              <Upload.Dragger
                accept=".pgn"
                beforeUpload={(file) => {
                  setFieldValue('file', file);
                  return false;
                }}
                className={errors.file ? 'border-ant-red-5' : ''}
                multiple={false}
                name="file"
                showUploadList={false}
              >
                <p className="ant-upload-drag-icon">
                  <UploadOutlined
                    className={
                      errors.file ? 'text-ant-red-5' : values.file ? '' : 'text-ant-gray-5'
                    }
                  />
                </p>

                {values.file ? (
                  <p
                    className={`font-bold truncate ${
                      errors.file ? 'text-ant-red-5' : 'text-ant-blue-5'
                    }`}
                  >
                    <LinkOutlined className="mr-1" />
                    {values.file.name}
                  </p>
                ) : (
                  <p className={touched.file && errors.file ? 'text-ant-red-3' : 'ant-upload-hint'}>
                    Загрузите PGN
                  </p>
                )}
              </Upload.Dragger>
            </FormItem>

            <FormItem label="Название группы упражнений" name="json.groupName">
              <Input
                disabled={isGroupNameDisabled}
                maxLength={150}
                name="json.groupName"
                placeholder="Название"
                suffix
              />
            </FormItem>

            <FormItem label="Уровень" name={`json.${exerciseKeys.playLevels}`}>
              <Select
                mode="multiple"
                name={`json.${exerciseKeys.playLevels}`}
                optionFilterProp="label"
                options={Object.keys(userPlayLevels)
                  .map(Number)
                  .map((playLevel) => ({
                    disabled:
                      values.json.playLevels.length > 0 &&
                      !values.json.playLevels.includes(playLevel as IUserPlayLevelNumber),
                    label: userPlayLevels[playLevel],
                    value: playLevel,
                  }))}
                placeholder="Уровень"
                showSearch={false}
              />
            </FormItem>

            <FormItem label="Видеоурок" name="json.videoId">
              <Select
                allowClear
                className="gap-y-2"
                name="json.videoId"
                optionFilterProp="label"
                options={
                  videos.data?.data.content.map((video) => ({
                    label: video.localizations.en?.fullTitle,
                    value: video.id,
                  })) || []
                }
                placeholder="Видео"
                showSearch
              />
            </FormItem>

            <FormItem label="Стадия игры" name={`json.${exerciseKeys.gamePhase}`}>
              <Select
                name={`json.${exerciseKeys.gamePhase}`}
                options={Object.entries(ExerciseGamePhaseEnum).map(([value, label]) => ({
                  label,
                  value,
                }))}
              />
            </FormItem>

            <div className="grid md:grid-cols-5 place-items-center text-center">
              <FormItem
                label={<span className="text-center">Мозговой штурм</span>}
                name="useForPuzzleRush"
              >
                <Switch name="useForPuzzleRush" />
              </FormItem>
              <FormItem
                label={<span className="text-center">Капитализация преимущества</span>}
                name={`json.${exerciseKeys.isCapitalization}`}
              >
                <Switch name={`json.${exerciseKeys.isCapitalization}`} />
              </FormItem>

              <FormItem
                label={<span className="text-center">Тактика</span>}
                name={`json.${exerciseKeys.isTactic}`}
              >
                <Switch name={`json.${exerciseKeys.isTactic}`} />
              </FormItem>

              <FormItem
                label={<span className="text-center">Пешечная структура</span>}
                name={`json.${exerciseKeys.isPawnStructure}`}
              >
                <Switch name={`json.${exerciseKeys.isPawnStructure}`} />
              </FormItem>

              <FormItem label={<span className="text-center">Проверено</span>} name="json.checked">
                <Switch name="json.checked" />
              </FormItem>

              <FormItem
                className="col-span-full"
                label={<span className="text-center">Запустить немедленно</span>}
                name={`json.${exerciseKeys.status}`}
              >
                <Input hidden name={`json.${exerciseKeys.status}`} suffix />

                <AntSwitch
                  checked={values.json[exerciseKeys.status] == 'ACTIVE'}
                  onChange={(checked) =>
                    setFieldValue(`json.${exerciseKeys.status}`, checked ? 'ACTIVE' : 'DRAFT')
                  }
                />
              </FormItem>
            </div>

            <div className="grid md:grid-cols-2 gap-5">
              <Button block onClick={onCancel}>
                Отменить
              </Button>

              <Button block htmlType="submit" loading={problemCreate.isLoading} type="primary">
                <span className="max-w-full overflow-ellipsis overflow-hidden">
                  {values['json']['status'] == 'ACTIVE'
                    ? 'Создать и запустить'
                    : 'Сохранить как черновик'}
                </span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
