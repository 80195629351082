import { IExercise } from '@exercises/exercises/types/IExercise';
import { IFen } from '@exercises/fens/types/IFen';
import { IVideo } from '@videos/types/IVideo';

import { MapStageStatusEnumKeys } from './enums/MapStageStatus';
import { MapStageTypeEnumKeys } from './enums/MapStageType';

export interface IMapStage {
  exerciseIds: number[];
  exercises?: IExercise[];
  fenRecord?: IFen;
  fenRecordId?: number;
  id: number;
  playLevel: number;
  playLevels?: number[] | null;
  stageNumber: number;
  status: MapStageStatusEnumKeys;
  type: MapStageTypeEnumKeys;
  video?: IVideo;
  videoId?: number | null;
}

export const mapStageKeys: Record<keyof IMapStage, string> = {
  exerciseIds: 'exerciseIds',
  exercises: 'exercises',
  fenRecord: 'fenRecord',
  fenRecordId: 'fenRecordId',
  id: 'id',
  playLevel: 'playLevel',
  playLevels: 'playLevels',
  stageNumber: 'stageNumber',
  status: 'status',
  type: 'type',
  video: 'video',
  videoId: 'videoId',
};
