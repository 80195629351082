import type { FC } from 'react';
import { Button, Modal, Switch as AntSwitch, Tag } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  createBoardGroupInitialValue,
  createBoardGroupValidation,
  IBoardGroupCreateRequest,
  useMutateBoardGroupCreate,
} from '@collections/board-groups/api/useMutateBoardGroupCreate';
import { useQueryBoards } from '@collections/boards/api/useQueryBoards';
import { CountryImageText } from '@components/atoms/CountryImageText';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const BoardGroupCreateModal: FC<Props> = ({ isOpen, onClose }) => {
  const createBoardGroup = useMutateBoardGroupCreate();
  const boards = useQueryBoards();

  return (
    <Modal footer={null} onCancel={onClose} open={isOpen} title="Создать группу досок">
      <Formik<IBoardGroupCreateRequest>
        enableReinitialize
        initialValues={createBoardGroupInitialValue}
        onSubmit={(values) =>
          createBoardGroup
            .mutateAsync({
              ...values,
            })
            .then(() => {
              onClose();
            })
        }
        validateOnBlur={false}
        validationSchema={createBoardGroupValidation}
      >
        {({ setFieldValue, values: { items, status } }) => (
          <Form className="gap-4 md:gap-6" layout="vertical">
            <FormItem
              label={<CountryImageText src="ru" text="Название на русском" />}
              name="localizations.ru.name"
            >
              <Input name="localizations.ru.name" prefix />
            </FormItem>
            <FormItem
              label={<CountryImageText src="en" text="Название на английском" />}
              name="localizations.en.name"
            >
              <Input name="localizations.en.name" prefix />
            </FormItem>
            <FormItem label="Доски" name="items">
              <Select
                loading={boards.isFetching}
                mode="multiple"
                name="items"
                onChange={(items: number[]) =>
                  setFieldValue(
                    'items',
                    items.map((item, idx) => ({ chessBoardId: item, priority: idx + 1 })),
                  )
                }
                optionFilterProp="label"
                options={
                  boards.data?.data.content.map((tag) => ({
                    label: tag.localizations.en.name,
                    value: tag.id,
                  })) || []
                }
                placeholder="Доски"
                tagRender={
                  boards.isFetching
                    ? () => (
                        <Tag>
                          <LoadingOutlined />
                        </Tag>
                      )
                    : undefined
                }
                value={items?.map((item) => item.chessBoardId)}
              />
            </FormItem>

            <div className="flex justify-center text-center">
              <FormItem label={<span className="text-center">Запустить</span>} name="status">
                <Input hidden name="status" suffix />

                <AntSwitch
                  checked={status == 'ACTIVE'}
                  onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
                />
              </FormItem>
            </div>

            <div className="flex gap-4 justify-end">
              <Button onClick={onClose}>Отмена</Button>
              <Button htmlType="submit" loading={createBoardGroup.isLoading} type="primary">
                <span className="max-w-full overflow-ellipsis overflow-hidden">
                  {status == 'ACTIVE' ? 'Создать и запустить' : 'Сохранить как черновик'}
                </span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
