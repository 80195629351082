import { FC } from 'react';
import { Button, Modal, Switch as AntSwitch } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';

import { CountryImageText } from '@components/atoms/CountryImageText';
import {
  fenCreateInitial,
  fenCreateValidation,
  IFenCreateRequest,
  useMutateFenCreate,
} from '@exercises/fens/api/useMutateFenCreate';
import { fenKeys } from '@exercises/fens/types/IFen';
import { notify } from '@utils/notify';
import { useQueryVideos } from '@videos/api/useQueryVideos';

export const FenCreateModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
}> = ({ isOpen, onCancel }) => {
  const videos = useQueryVideos({ isCartoon: false });
  const fenCreate = useMutateFenCreate();

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3 top-10"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={<h1 className="text-xl m-0 flex items-center gap-1">Создать FEN</h1>}
    >
      <Formik<IFenCreateRequest>
        initialValues={fenCreateInitial}
        onSubmit={(values, { resetForm }) => {
          fenCreate.mutateAsync(values).then((data) => {
            if (data.status == 201 || data.status == 200) {
              notify('success', 'FEN создан');
              onCancel();
              resetForm();
            }
          });
        }}
        validationSchema={fenCreateValidation}
      >
        {({ setFieldValue, values: { status } }) => (
          <Form className="space-y-8" layout="vertical">
            <FormItem label="FEN" name={fenKeys.value}>
              <Input maxLength={100} name={fenKeys.value} placeholder="Введите FEN" suffix />
            </FormItem>

            <FormItem label="Название FEN" name={fenKeys.name}>
              <Input maxLength={150} name={fenKeys.name} placeholder="Название FEN" suffix />
            </FormItem>

            <FormItem label="Видеоуроки" name="videoIds">
              <Select
                allowClear
                className="gap-y-2"
                mode="multiple"
                name="videoIds"
                optionFilterProp="label"
                options={
                  videos.data?.data.content.map((video) => ({
                    label: video.localizations.en?.fullTitle,
                    value: video.id,
                  })) || []
                }
                placeholder="Видео"
                showSearch
              />
            </FormItem>

            <FormItem
              label={<CountryImageText src="ru" text="Сообщение Каиссы" />}
              name="localizations.ru.messageFromKaissa"
            >
              <Input.TextArea
                maxLength={400}
                name="localizations.ru.messageFromKaissa"
                placeholder="Сообщение Каиссы"
              />
            </FormItem>

            <FormItem
              label={<CountryImageText src="en" text="Kaissa's message" />}
              name="localizations.en.messageFromKaissa"
            >
              <Input.TextArea
                maxLength={400}
                name="localizations.en.messageFromKaissa"
                placeholder="Kaissa's message"
              />
            </FormItem>

            <div className="flex justify-center text-center">
              <FormItem
                label={<span className="text-center">Запустить</span>}
                name={fenKeys.status}
              >
                <Input hidden name={fenKeys.status} suffix />

                <AntSwitch
                  checked={status == 'ACTIVE'}
                  onChange={(checked) =>
                    setFieldValue(fenKeys.status, checked ? 'ACTIVE' : 'DRAFT')
                  }
                />
              </FormItem>
            </div>

            <div className="grid md:grid-cols-2 gap-5">
              <Button block onClick={onCancel}>
                Отменить
              </Button>

              <Button block htmlType="submit" loading={fenCreate.isLoading} type="primary">
                <span className="max-w-full overflow-ellipsis overflow-hidden">
                  {status == 'ACTIVE' ? 'Создать и запустить' : 'Создать'}
                </span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
