import '@utils/axiosConfig';
import '@utils/yupConfig';
import './assets/index.scss';
import 'antd/dist/reset.css';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import { PageMetaContextProvider } from '@context/PageMetaContext';
import { ignoreConsoleErrors } from '@utils/consoleError';

import App from './App';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'development') {
  ignoreConsoleErrors();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const root = document.getElementById('root');
if (root) {
  createRoot(root).render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <PageMetaContextProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PageMetaContextProvider>
        </HelmetProvider>
        <ReactQueryDevtools initialIsOpen={false} panelProps={{ className: 'min-h-10' }} />
      </QueryClientProvider>
    </StrictMode>,
  );
}

reportWebVitals();
