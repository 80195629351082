import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, number, object, ObjectSchema, string } from 'yup';

import { boardCommonKeys } from '@collections/boards/api/boardCommonKeys';
import { ChessPiecesSetStatusEnumKeys } from '@collections/chess-pieces/types/enums/ChessPiecesSetEnum';

import { boardGroupQueryKeys } from './boardGroupQueryKeys';
import { IBoardGroupCreateRequest } from './useMutateBoardGroupCreate';

export type IBoardGroupEditRequest = IBoardGroupCreateRequest & {
  id: number;
  priority: number;
};

type IBoardGroupEditResponse = AxiosResponse<void>;

export const boardGroupEditValidation: ObjectSchema<IBoardGroupEditRequest> = object({
  id: number().required(),
  items: array()
    .of(object({ chessBoardId: number().required(), priority: number().required() }))
    .required(),
  localizations: object({
    en: object({ name: string().required() }),
    ru: object({ name: string().required() }),
  }),
  priority: number().required(),
  status: string<ChessPiecesSetStatusEnumKeys>().nullable().required(),
});

export const boardGroupEditRequest = async (
  payload: IBoardGroupEditRequest,
): Promise<IBoardGroupEditResponse> =>
  await axios.patch(`/chess-board-groups/${payload.id}`, payload);

export function useMutateBoardGroupEdit() {
  const queryClient = useQueryClient();

  return useMutation<IBoardGroupEditResponse, AxiosError, IBoardGroupEditRequest>(
    boardGroupEditRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(boardGroupQueryKeys.boardGroup);
        queryClient.invalidateQueries([boardGroupQueryKeys.boardsGroups]);
        queryClient.invalidateQueries(boardCommonKeys.boards);
        queryClient.invalidateQueries(boardCommonKeys.board);
      },
    },
  );
}
