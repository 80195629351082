import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, number, object, ObjectSchema, string } from 'yup';

import { staffsQueryKeys } from '@staff/api/staffsQueryKeys';
import { IStaffInviteRequest } from '@staff/api/useMutateStaffInvite';
import { IStaff } from '@staff/types/IStaff';
import { IPermission } from '@typess/permissions/IPermission';
import { notify } from '@utils/notify';

export type IStaffEditRequest = Pick<IStaffInviteRequest, 'authorities'> & Pick<IStaff, 'id'>;

type IStaffEditResponse = AxiosResponse<IStaff>;

export const staffEditValidation: ObjectSchema<IStaffEditRequest> = object({
  authorities: array().of(string<IPermission>().required()).required(),
  id: number().required(),
});

export const staffEditRequest = async (payload: IStaffEditRequest): Promise<IStaffEditResponse> => {
  return await axios.put('/staff', {
    ...payload,
    authorities: payload.authorities.filter((authority) => authority != 'block'),
  });
};

export function useMutateStaffEdit() {
  const queryClient = useQueryClient();

  return useMutation<IStaffEditResponse, AxiosError, IStaffEditRequest>(staffEditRequest, {
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries(staffsQueryKeys.staffs);
      await queryClient.invalidateQueries([staffsQueryKeys.staff, { id: variables.id }]);
      if (data.status == 201 || data.status == 200) {
        notify('success', 'Персонал успешно изменен');
      }
    },
  });
}
