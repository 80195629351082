import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPayingUserStat } from '@dashboards/types/IPayingUserStat';

import { dashboardQueryKeys } from './dashboardQueryKeys';

export type IPayingUsersStatsRequest = {
  from?: string;
  to?: string;
};

type IPayingUsersStatsResponse = AxiosResponse<IPayingUserStat>;

export const payingUsersStatsRequest = async (
  params?: IPayingUsersStatsRequest,
): Promise<IPayingUsersStatsResponse> => {
  return await axios.get('/stats/payment-stats', { params });
};

export function useQueryPayingUsersStats(params?: IPayingUsersStatsRequest) {
  return useQuery<IPayingUsersStatsResponse, AxiosError>(
    [dashboardQueryKeys.payingUsers, params],
    () => payingUsersStatsRequest(params),
  );
}
