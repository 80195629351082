import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '@components/molecules/routes/ProtectedRoute';
import NotFoundPage from '@pages/errors/NotFoundPage';
import { dashboardsPermission } from '@typess/permissions/IAnalitycsPermission';

import AppDashboardsPage from './components/pages/AppDashboardsPage';
import ByCountryDashboardPage from './components/pages/ByCountryDashboardPage';
import ByPlayLevelDashboardPage from './components/pages/ByPlayLevelDashboardPage';
import ChangesDashboardPage from './components/pages/ChangesDashboardPage';
import PayingUsersCohortDashboardPage from './components/pages/PayingUsersCohortDashboardPage';
import PayingUsersFinancialDashboardPage from './components/pages/PayingUsersFinancialDashboardPage';
import PayingUsersPage from './components/pages/PayingUsersPage';
import PayingUsersTotalDashboardPage from './components/pages/PayingUsersTotalDashboardPage';

export const DashboardRouter = () => {
  return (
    <ProtectedRoute permissions={Object.values(dashboardsPermission)}>
      <Routes>
        <Route element={<AppDashboardsPage />} index />
        <Route element={<NotFoundPage />} path="*" />
        <Route element={<ByCountryDashboardPage />} path="by-country" />
        <Route element={<ByPlayLevelDashboardPage />} path="by-play-level" />
        <Route element={<ChangesDashboardPage />} path="changes" />
        <Route element={<PayingUsersPage />} path="paying-users" />
        <Route element={<PayingUsersTotalDashboardPage />} path="/paying-users-stats/total" />
        <Route element={<PayingUsersCohortDashboardPage />} path="paying-users-stats/cohort" />
        <Route
          element={<PayingUsersFinancialDashboardPage />}
          path="paying-users-stats/financial"
        />
      </Routes>
    </ProtectedRoute>
  );
};
