import { BoardStatusEnumKeys } from './enums/boardStatusEnum';
import { BoardTypeEnumKeys } from './enums/boardTypeEnum';

export interface IBoard {
  createdDate: string;
  darkPieceImgUrl: string;
  darkPieceImgUrlPng: string;
  groupIds?: number[];
  id: number;
  imgUrl: string;
  imgUrlPng: string;
  lightPieceImgUrl: string;
  lightPieceImgUrlPng: string;
  localizations: Record<'en' | 'ru', { name: string }>;
  price?: number | null;
  priority: number;
  status?: BoardStatusEnumKeys;
  type: BoardTypeEnumKeys;
  updatedDate: string;
}

export const boardKeys: Record<keyof IBoard, string> = {
  createdDate: 'createdDate',
  darkPieceImgUrl: 'darkPieceImgUrl',
  darkPieceImgUrlPng: 'darkPieceImgUrlPng',
  groupIds: 'groupIds',
  id: 'id',
  imgUrl: 'imgUrl',
  imgUrlPng: 'imgUrlPng',
  lightPieceImgUrl: 'lightPieceImgUrl',
  lightPieceImgUrlPng: 'lightPieceImgUrlPng',
  localizations: 'localizations',
  price: 'price',
  priority: 'priority',
  status: 'status',
  type: 'type',
  updatedDate: 'updatedDate',
};
