import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';

import { BrainstormLevelEnum } from '@users/types/enums/BrainstormLevelEnum';
import { IUserBrainstorm, userBrainstormKeys } from '@users/types/IUserBrainstorm';

export const userBrainStormColumns = ({
  onClickView,
}: {
  onClickView?: ({ id }: { id: number }) => void;
}): ColumnsType<IUserBrainstorm> => {
  const formatSeconds = (totalSeconds) => {
    const duration = moment.duration(totalSeconds, 'seconds');
    return moment.utc(duration.asMilliseconds()).format('mm:ss');
  };

  return [
    {
      dataIndex: userBrainstormKeys.id,
      render: (id: IUserBrainstorm['id']) =>
        onClickView ? (
          <Button onClick={() => onClickView({ id })} type="link">
            {id}
          </Button>
        ) : (
          id
        ),
      title: 'ID',
    },
    {
      dataIndex: userBrainstormKeys.mode,
      render: (outcome: IUserBrainstorm['mode']) => BrainstormLevelEnum[outcome],
      title: 'Режим игры',
    },

    {
      dataIndex: userBrainstormKeys.type,
      render: (outcome: IUserBrainstorm['type']) => (outcome == 'MIN3' ? '3 мин' : '5 мин'),
      title: 'Время',
    },
    {
      dataIndex: userBrainstormKeys.passedTimeInSeconds,
      render: (outcome: IUserBrainstorm['passedTimeInSeconds']) =>
        outcome ? formatSeconds(outcome) : '-',
      title: 'Фактическое время',
    },
    {
      dataIndex: userBrainstormKeys.problemsCount,
      title: 'Количество упражнений',
    },
    {
      dataIndex: userBrainstormKeys.solvedCount,
      title: 'Правильно решенные',
    },
    {
      dataIndex: userBrainstormKeys.useType,
      title: 'Статус использования игры',
    },
    {
      dataIndex: userBrainstormKeys.createdDate,
      render: (createdDate: IUserBrainstorm['createdDate']) =>
        createdDate ? (
          <div className="flex flex-row">
            <CalendarOutlined className="mr-1" />
            <p className="mb-0">{createdDate}</p>
          </div>
        ) : (
          <span>-</span>
        ),
      title: 'Дата игры',
    },
  ];
};
