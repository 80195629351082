import { Button, message, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { ValidationError } from 'yup';
import {
  CalendarOutlined,
  CaretRightOutlined,
  CarryOutOutlined,
  DeleteOutlined,
  FileOutlined,
  FolderOpenOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import { PencilIcon } from '@components/atoms/icons/PencilIcon';
import { IAppPromotionDeleteRequest } from '@shop/appPromotions/api/useMutateAppPromotionDelete';
import { appPromotionEditValidation } from '@shop/appPromotions/api/useMutateAppPromotionEdit';
import { IAppPromotionStatusRequest } from '@shop/appPromotions/api/useMutateAppPromotionStatus';
import { AppPromotionStatusEnum } from '@shop/appPromotions/types/enums/AppPromotionStatusEnum';
import { appPromotionKeys, IAppPromotion } from '@shop/appPromotions/types/IAppPromotion';

export const promotionsColumns = ({
  canEdit,
  onClickDelete,
  onClickEdit,
  onClickPause,
}: {
  canEdit: boolean;
  onClickDelete: (request: IAppPromotionDeleteRequest) => void;
  onClickEdit: ({ id }: { id: number }) => void;
  onClickPause: (request: IAppPromotionStatusRequest) => void;
}): ColumnsType<IAppPromotion> => {
  const columns: ColumnsType<IAppPromotion> = [
    {
      dataIndex: appPromotionKeys.localizations,
      render: (localizations: IAppPromotion['localizations']) => localizations.en?.title,
      title: 'Название акции',
    },

    {
      dataIndex: appPromotionKeys.products,
      render: (products: IAppPromotion['products']) => (
        <div className="space-y-2">
          {products
            ? products.map((product) => (
                <p
                  className="mb-0 first-letter:capitalize whitespace-nowrap"
                  key={`appProduct${product.id}`}
                >
                  {product.appStoreId} - {`${product.coins}CC - ${product.price}KZT`}
                </p>
              ))
            : '-'}
        </div>
      ),
      title: 'Товары',
    },
    {
      dataIndex: appPromotionKeys.startDate,
      render: (startDate: Date, appPromotion) => (
        <div
          className={`space-y-2 ${
            appPromotion.status == 'ARCHIVED'
              ? 'text-ant-gray-6'
              : appPromotion.status == 'ACTIVE'
              ? 'text-ant-green-6'
              : appPromotion.status == 'APPROVED'
              ? 'text-ant-blue-6'
              : ''
          }`}
        >
          <p className="mb-0 whitespace-nowrap">
            <CalendarOutlined /> {startDate ? moment(startDate).format('LL') : ' - '}
          </p>
          <p className="mb-0  whitespace-nowrap">
            <CarryOutOutlined />{' '}
            {appPromotion.endDate ? moment(appPromotion.endDate).format('LL') : ' - '}
          </p>
        </div>
      ),
      title: 'Период проведения',
    },
    {
      dataIndex: appPromotionKeys.status,
      render: (status: IAppPromotion['status']) => (
        <div
          className={`flex flex-row items-center ${
            status == 'ARCHIVED'
              ? 'text-ant-gray-6'
              : status == 'ACTIVE'
              ? 'text-ant-green-6'
              : status == 'APPROVED'
              ? 'text-ant-blue-6'
              : ''
          }`}
        >
          {status == 'ACTIVE' ? (
            <PlayCircleOutlined />
          ) : status == 'APPROVED' ? (
            <PauseCircleOutlined />
          ) : status == 'DRAFT' ? (
            <FileOutlined />
          ) : (
            <FolderOpenOutlined />
          )}
          <span className="ml-2">{status ? AppPromotionStatusEnum[status] : ''}</span>
        </div>
      ),
      title: 'Статус',
    },
  ];

  if (!canEdit) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: (appPromotion: IAppPromotion) =>
        ['ACTIVE', 'APPROVED', 'DRAFT'].includes(appPromotion.status || '') && (
          <div className="flex gap-x-1.5">
            <Popconfirm
              cancelText="Отменить"
              icon={
                appPromotion.status == 'APPROVED' ? (
                  <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                ) : appPromotion.status == 'ACTIVE' ? (
                  <FolderOpenOutlined className="text-ant-blue-5 text-base" />
                ) : (
                  <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                )
              }
              okText={
                appPromotion.status == 'DRAFT'
                  ? 'Запустить'
                  : appPromotion.status == 'APPROVED'
                  ? 'Остановить'
                  : 'Архивировать'
              }
              okType="primary"
              onConfirm={() =>
                appPromotion.status == 'DRAFT'
                  ? appPromotionEditValidation
                      .validate(
                        {
                          ...appPromotion,
                          status: 'APPROVED',
                        },
                        { abortEarly: false },
                      )
                      .then(function () {
                        onClickPause({
                          id: appPromotion.id,
                          status: 'APPROVED',
                        });
                      })
                      .catch(function (err: ValidationError) {
                        onClickEdit({ id: appPromotion.id });
                        err.errors.forEach((error) => message.warning(error));
                      })
                  : onClickPause({
                      id: appPromotion.id,
                      status: appPromotion.status == 'APPROVED' ? 'DRAFT' : 'ARCHIVED',
                    })
              }
              placement="topLeft"
              title={
                <p className="text-sm mb-0">
                  Вы уверены, что хотите
                  <span className="font-semibold">
                    {appPromotion.status == 'DRAFT'
                      ? ' запустить кампанию '
                      : appPromotion.status == 'APPROVED'
                      ? ' остановить '
                      : ' архивировать '}
                    &quot;
                    {appPromotion.localizations.en?.title}&quot;?
                  </span>
                </p>
              }
            >
              <Button
                ghost
                icon={
                  appPromotion.status == 'ACTIVE' ? (
                    <FolderOpenOutlined />
                  ) : appPromotion.status == 'APPROVED' ? (
                    <PauseOutlined />
                  ) : (
                    <CaretRightOutlined />
                  )
                }
                type="primary"
              />
            </Popconfirm>

            {(appPromotion.status == 'APPROVED' || appPromotion.status == 'DRAFT') && (
              <Button
                disabled={appPromotion.status != 'DRAFT' && appPromotion.status != 'APPROVED'}
                ghost
                icon={<PencilIcon />}
                onClick={() => onClickEdit({ id: appPromotion.id })}
                type="primary"
              />
            )}

            {(appPromotion.status == 'APPROVED' || appPromotion.status == 'DRAFT') && (
              <Popconfirm
                cancelText="Отменить"
                icon={<DeleteOutlined className="text-ant-red-5" />}
                okText="Удалить"
                okType="danger"
                onConfirm={() => onClickDelete({ id: appPromotion.id })}
                placement="topLeft"
                title={
                  <span className="text-sm">
                    Вы уверены, что хотите
                    <span className="font-bold">
                      {' '}
                      удалить кампанию &quot;{appPromotion.localizations.en?.title}&quot;
                    </span>
                    ?
                  </span>
                }
              >
                <Button danger icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          </div>
        ),
      title: 'Действия',
    },
  ];
};
