import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { videoQueryKeys } from '@videos/api/videoQueryKeys';
import { IVideo } from '@videos/types/IVideo';

export type IVideoStatusUpdateRequest = Pick<IVideo, 'id' | 'status'>;

type IVideoStatusUpdateResponse = AxiosResponse<void>;

export const videoStatusUpdateRequest = async (
  payload: IVideoStatusUpdateRequest,
): Promise<IVideoStatusUpdateResponse> => {
  return await axios.patch('videos/3', payload);
};

export function useMutateVideoStatusUpdate() {
  const queryClient = useQueryClient();
  return useMutation<IVideoStatusUpdateResponse, AxiosError, IVideoStatusUpdateRequest>(
    videoStatusUpdateRequest,
    {
      onSuccess: (data, variables) => {
        queryClient.refetchQueries(videoQueryKeys.videos);
        queryClient.refetchQueries([videoQueryKeys.video, { id: variables.id }]);
        queryClient.refetchQueries([videoQueryKeys.video, { id: variables.id.toString() }]);
      },
    },
  );
}
