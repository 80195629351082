import { FC } from 'react';
import { Image, Result, ResultProps } from 'antd';

import { PageMeta } from '@context/PageMetaContext';

const NotFoundPage: FC<ResultProps> = ({
  extra = '',
  subTitle = <code>Фигура не найдена</code>,
  title = '404',
}) => {
  return (
    <>
      <PageMeta selectedMenuKeys={[]} title="404" />
      <Result
        extra={extra}
        icon={
          <Image
            className="object-cover h-32 sm:h-48 md:h-64 lg:h-80"
            preview={false}
            src={'/images/notfound.png'}
          />
        }
        subTitle={subTitle}
        title={title}
      />
    </>
  );
};
export default NotFoundPage;
