import { FC, useState } from 'react';
import { Button, DatePicker, Modal } from 'antd';
import { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { Formik } from 'formik';
import { Form, FormItem, Input, InputNumber, Select } from 'formik-antd';

import {
  IPromocodeGroupCreateRequest,
  promocodeGroupCreateInitial,
  promocodeGroupCreateValidation,
  useMutatePromocodeGroupCreate,
} from '@settings/promocodes/api/useMutatePromocodeGroupCreate';
import { promoCodeTypes } from '@settings/promocodes/types/enums/PromocodeTypeEnum';
import { IBackendError } from '@typess/IBackendError';
import { notify } from '@utils/notify';

type PromocodeGroupCreateModalType = {
  isOpen: boolean;
  onCancel: () => void;
};

export const PromocodeGroupCreateModal: FC<PromocodeGroupCreateModalType> = ({
  isOpen,
  onCancel,
}) => {
  const promocodeGroupCreate = useMutatePromocodeGroupCreate();
  const [expireDate, setExpireDate] = useState<Dayjs | null>();

  return (
    <Modal
      className="px-4 md:px-0 md:w-2/3 lg:w-1/2 pt-0 mt-0"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title="Создать группу промокодов"
    >
      <Formik<IPromocodeGroupCreateRequest>
        enableReinitialize
        initialValues={promocodeGroupCreateInitial}
        onSubmit={(values, { resetForm, setErrors }) => {
          promocodeGroupCreate
            .mutateAsync({
              ...values,
              expireDate: expireDate?.toDate(),
            })
            .then((data) => {
              if (data.status == 201 || data.status == 200) {
                notify('success', `Группа промокодов "${data.data.groupName}" создан`);
                onCancel();
                resetForm();
              }
            })
            .catch((error: AxiosError<IBackendError>) => {
              if (
                error.response?.status == 422 &&
                error.response.data.message == 'Promocode.exists'
              ) {
                setErrors({ groupName: 'Промокод с данным названием уже существует' });
              }
            });
        }}
        validationSchema={promocodeGroupCreateValidation}
      >
        {({ setFieldValue }) => (
          <Form layout="vertical">
            <FormItem className="pt-4" label="Название группы промокодов" name="groupName">
              <Input name="groupName" placeholder="Название группы" suffix type="text" />
            </FormItem>
            <FormItem label="Тип" name="type">
              <Select
                name="type"
                options={Object.entries(promoCodeTypes).map(([value, label]) => ({
                  label: label,
                  value: value,
                }))}
                placeholder="Тип"
                showSearch={false}
              />
            </FormItem>
            <FormItem label="Бесплатное количество месяцев" name="freeUseMonths">
              <InputNumber
                className="w-full"
                name="freeUseMonths"
                placeholder="Бесплатное количество месяцев"
                prefix
                type="limitation"
              />
            </FormItem>

            <FormItem label="Количество промокодов" name="codeCount">
              <InputNumber
                className="w-full"
                name="codeCount"
                placeholder="Количество"
                prefix
                type="limitation"
              />
            </FormItem>

            <FormItem label="Дата окончания (опционально)" name="expireDate">
              <DatePicker
                className="w-full"
                disabledDate={(current) => dayjs().startOf('day').isAfter(current.startOf('day'))}
                name="expireDate"
                onChange={(date) => {
                  setExpireDate(date);
                  setFieldValue('expireDate', date);
                }}
                placeholder="Дата окончания"
                value={expireDate}
              />
            </FormItem>

            <div className="grid md:grid-cols-2 gap-5">
              <Button block onClick={onCancel}>
                Отменить
              </Button>

              <Button htmlType="submit" loading={promocodeGroupCreate.isLoading} type="primary">
                Создать
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
