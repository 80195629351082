import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IAssessmentExercise } from '../types/IAssessmentExercise';
import { assessmentExerciseQueryKeys } from './assessmentExerciseQueryKeys';

export type AssessmentExerciseStatusUpdateRequest = Pick<IAssessmentExercise, 'id' | 'status'>;

type AssessmentExerciseStatusUpdateResponse = AxiosResponse<void>;

export const assessmentExerciseStatusUpdateRequest = async ({
  id,
  ...payload
}: AssessmentExerciseStatusUpdateRequest): Promise<AssessmentExerciseStatusUpdateResponse> => {
  return await axios.patch(`/admin/assessment-exercises/test/${id}`, payload);
};

export function useMutateAssessmentExerciseStatusUpdate() {
  const queryClient = useQueryClient();

  return useMutation<
    AssessmentExerciseStatusUpdateResponse,
    AxiosError,
    AssessmentExerciseStatusUpdateRequest
  >(assessmentExerciseStatusUpdateRequest, {
    onSuccess: () => queryClient.invalidateQueries(assessmentExerciseQueryKeys.assessmentExercises),
  });
}
