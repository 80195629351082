import { FC } from 'react';
import { Button, Modal } from 'antd';
import { Formik } from 'formik';
import { Checkbox, Form, FormItem, Input, Radio } from 'formik-antd';

import { CountryImageText } from '@components/atoms/CountryImageText';
import {
  IVersionCreateRequest,
  useMutateVersionCreate,
  versionCreateCreateInitial,
  versionCreateValidation,
} from '@settings/versions/api/useMutateVersionCreate';
import { VersionsPlatformEnum } from '@settings/versions/types/enums/VersionsPlatformEnum';
import { notify } from '@utils/notify';

type VersionCreateModalType = {
  isOpen: boolean;
  onCancel: () => void;
};

export const VersionCreateModal: FC<VersionCreateModalType> = ({ isOpen, onCancel }) => {
  const versionCreate = useMutateVersionCreate();

  return (
    <Modal
      className="px-4 md:px-0 md:w-2/3 lg:w-1/2 pt-0 mt-0"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title="Добавить версию"
    >
      <Formik<IVersionCreateRequest>
        enableReinitialize
        initialValues={versionCreateCreateInitial}
        onSubmit={(values, { resetForm }) => {
          versionCreate.mutateAsync(values).then((data) => {
            if (data.status == 201 || data.status == 200) {
              notify('success', `Версия "${data.data.version}" создана`);
              onCancel();
              resetForm();
            }
          });
        }}
        validationSchema={versionCreateValidation}
      >
        <Form className="space-y-8" layout="vertical">
          <FormItem label="Версия" name="version">
            <Input name="version" placeholder="Версия" suffix type="text" />
          </FormItem>

          <FormItem label="Тип устройства" name="platform">
            <Radio.Group
              name="platform"
              options={Object.entries(VersionsPlatformEnum).map(([value, label]) => ({
                label,
                value,
              }))}
            />
          </FormItem>

          <FormItem label="Тип обновления" name="isForced">
            <Checkbox defaultChecked={false} name="isForced">
              Обязательное
            </Checkbox>
          </FormItem>

          <FormItem
            label={<CountryImageText src="ru" text="Заголовок" />}
            name="localizations.ru.title"
          >
            <Input name="localizations.ru.title" placeholder="Заголовок" />
          </FormItem>

          <FormItem
            label={<CountryImageText src="ru" text="Сообщение" />}
            name="localizations.ru.message"
          >
            <Input.TextArea
              maxLength={400}
              name="localizations.ru.message"
              placeholder="Сообщение"
            />
          </FormItem>

          <FormItem
            label={<CountryImageText src="en" text="Title" />}
            name="localizations.en.title"
          >
            <Input name="localizations.en.title" placeholder="Title" />
          </FormItem>

          <FormItem
            label={<CountryImageText src="en" text="Message" />}
            name="localizations.en.message"
          >
            <Input.TextArea maxLength={400} name="localizations.en.message" placeholder="Message" />
          </FormItem>

          <div className="grid md:grid-cols-2 gap-5">
            <Button block onClick={onCancel}>
              Отменить
            </Button>

            <Button htmlType="submit" loading={versionCreate.isLoading} type="primary">
              Добавить
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};
