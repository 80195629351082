import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IUserPlayLevelNumber } from '@users/types/enums/UserPlayLevel';

import { assessmentExerciseQueryKeys } from './assessmentExerciseQueryKeys';

export type IAssessmentExerciseLastPriorityRequest = { playLevel?: IUserPlayLevelNumber };
type IAssessmentExerciseLastPriorityResponse = AxiosResponse<{ nextPriority: number }>;

export const assessmentExerciseLastPriorityRequest = async (
  params?: IAssessmentExerciseLastPriorityRequest,
): Promise<IAssessmentExerciseLastPriorityResponse> =>
  await axios.get(`/admin/assessment-exercises/next`, { params });

export function useQueryAssessmentExerciseLastPriority(
  params: IAssessmentExerciseLastPriorityRequest,
) {
  return useQuery<IAssessmentExerciseLastPriorityResponse, AxiosError>(
    [assessmentExerciseQueryKeys.assessmentExercise, params],
    () => assessmentExerciseLastPriorityRequest(params),
    { enabled: !!params.playLevel },
  );
}
