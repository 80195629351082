import { boardGroupKeys } from '@collections/board-groups/types/IBoardGroup';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { IFilters } from '@typess/IFilters';

export const boardGroupsFilters: IFilters = {
  ...paginationBaseFilters,
  [boardGroupKeys.id]: {
    sort: true,
  },
  sortBy: { queries: [{ default: 'priority' }] },
  sortDirection: { queries: [{ default: 'ASC' }] },
};
