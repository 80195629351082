export enum AppSettingNameEnum {
  'ABOUT_URL' = 'Ссылка - О нас',
  'BATTLE_ENGINE_PLAY_LEVEL_MAP' = 'Уровень компьютера в “Battle“',
  'PLAY_LEVEL_ENGINE_LEVEL_MAP' = 'Уровень для Stockfish',
  'PLAY_LEVEL_ENGINE_RATING_MAP' = 'Рейтинг для Stockfish',
  'SUPPORT_EMAIL' = 'Почта службы поддержки',
  'TERMS_URL' = 'Ссылка - Условия и положения',
}

export type AppSettingsNameEnumKeys = keyof typeof AppSettingNameEnum;
