import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPageAbleRequest } from '@typess/IPageAbleRequest';

import { IOnboardingItem } from '../types/IOnboardingItem';
import { onboardingItemsKeys } from './onboardingItemsKeys';

export type IOnboardingItemsRequest = IPageAbleRequest;

type OnboardingItemsResponse = AxiosResponse<IOnboardingItem[]>;

export const onboardingImagesRequestRequest = async (
  params?: IOnboardingItemsRequest,
): Promise<OnboardingItemsResponse> => await axios.get('/onboarding', { params });

export function useQueryOnboardingItems(params?: IOnboardingItemsRequest) {
  return useQuery<OnboardingItemsResponse, AxiosError>(
    [onboardingItemsKeys.onboardingItems, params],
    () => onboardingImagesRequestRequest(params),
  );
}
