import { AppSettingsNameEnumKeys } from './enums/AppSettingNameEnum';

export type IAppSetting = {
  name: AppSettingsNameEnumKeys;
  number?: number | null;
  string?: string | null;
  variables?: object | null;
};

export const appSettingKeys: Record<keyof IAppSetting, string> = {
  name: 'name',
  number: 'number',
  string: 'string',
  variables: 'variables',
};
