import { FC, useState } from 'react';
import { Button, Upload } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import axios from 'axios';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';
import { CheckOutlined, MinusOutlined, UploadOutlined } from '@ant-design/icons';

import { useQueryTags } from '@tags/api/useQueryTags';
import {
  IVideoUploadFinishRequest,
  useMutateVideoUploadFinish,
  videoUploadFinishInitial,
  videoUploadFinishValidation,
} from '@videos/api/useMutateVideoUploadFinish';
import { useMutateVideoUploadLink } from '@videos/api/useMutateVideoUploadLink';

export const VideoFileUpload: FC<{
  disabled: boolean;
  onRemove: () => void;
  onSuccess: () => void;
}> = ({ disabled, onRemove, onSuccess }) => {
  const uploadLink = useMutateVideoUploadLink();
  const uploadFinish = useMutateVideoUploadFinish();
  const [fileList, setFileList] = useState<UploadFile[] | undefined>();
  const tagsAuthor = useQueryTags({ type: 'AUTHOR' });

  return (
    <Formik<IVideoUploadFinishRequest>
      enableReinitialize
      initialValues={videoUploadFinishInitial}
      onSubmit={(values) => {
        uploadFinish.mutateAsync(values).then(({ status }) => status == 200 && onSuccess());
      }}
      validationSchema={videoUploadFinishValidation}
    >
      {({ setFieldValue, values }) => (
        <Form className="space-y-4" layout="vertical">
          <FormItem label="Название видео" name="title">
            <Input name="title" suffix />
          </FormItem>
          <FormItem label="Автор" name={`authorTagId`}>
            <Select
              name={`authorTagId`}
              options={
                tagsAuthor.data?.data.content.map((tag) => ({
                  label: tag.authorRank ? (
                    <>
                      <span className="font-bold text-ant-red-7 mr-1">{tag.authorRank}</span>
                      {tag.localizations.ru}
                    </>
                  ) : (
                    tag.localizations.ru
                  ),
                  value: tag.id,
                })) || []
              }
              placeholder="Автор"
            />
          </FormItem>
          <FormItem label="Видеофайл" name="uuid">
            <Upload.Dragger
              accept=".mp4,.mov"
              action={(file) =>
                uploadLink.mutateAsync({ videoName: file.name }).then((upload) => upload.data.url)
              }
              className={fileList?.length ? 'border-none' : ''}
              customRequest={async (rcRequest) => {
                const videoFile = await new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.readAsArrayBuffer(rcRequest.file as RcFile);
                  reader.onload = () => {
                    resolve(reader.result);
                  };
                  reader.onerror = (error) => reject(error);
                });
                axios
                  .put(rcRequest.action, videoFile, {
                    headers: rcRequest.headers,
                    onUploadProgress: (re) =>
                      rcRequest.onProgress?.({
                        ...re,
                        percent: (re.loaded * 100) / (re.total || 10000),
                      }),
                  })
                  .then(rcRequest.onSuccess)
                  .catch(rcRequest.onError);
              }}
              fileList={fileList}
              headers={{ 'Content-type': 'video/mp4' }}
              listType="picture"
              maxCount={1}
              method="PUT"
              multiple={false}
              onChange={(info) => {
                setFileList(info.fileList);
                if (!info.file.status || info.file.status == 'removed') {
                  setFieldValue('videoFileName', '');
                  setFieldValue('uuid', '');
                }
                if (info.file.status == 'done') {
                  setFieldValue('videoFileName', info.file.name);
                  if (!values.title) {
                    setFieldValue('title', info.file.name);
                  }
                  setFieldValue('uuid', uploadLink.data?.data.id);
                }
              }}
              previewFile={(file) => {
                return new Promise((resolve) => {
                  const canvas = document.createElement('canvas');
                  const video = document.createElement('video');

                  video.autoplay = true;
                  video.muted = true;
                  video.src = URL.createObjectURL(file);

                  video.onloadeddata = () => {
                    const ctx = canvas.getContext('2d');

                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;

                    ctx?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                    video.pause();
                    return resolve(canvas.toDataURL('image/png'));
                  };
                });
              }}
              showUploadList={{
                downloadIcon: undefined,
                showDownloadIcon: false,
                showPreviewIcon: false,
              }}
            >
              {!fileList?.length && (
                <div className="p-2">
                  <p className="ant-upload-drag-icon">
                    <UploadOutlined className="text-ant-gray-7" />
                  </p>
                  Загрузить
                </div>
              )}
            </Upload.Dragger>
          </FormItem>

          <div className="flex justify-end gap-1">
            {!disabled && (
              <Button
                className="flex items-center justify-center"
                onClick={onRemove}
                shape="circle"
                size="small"
                type="default"
              >
                <MinusOutlined className="text-tiny text-black" />
              </Button>
            )}
            <Button
              className="bg-ant-green-6 text-white hover:bg-ant-green-5 hover:border-ant-green-7 flex items-center justify-center"
              disabled={uploadFinish.isLoading ? true : undefined}
              htmlType="submit"
              shape="circle"
              size="small"
              type="default"
            >
              <CheckOutlined className="text-tiny" />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
