import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';
import { IUserPlayLevelNumber } from '@users/types/enums/UserPlayLevel';
import { videoQueryKeys } from '@videos/api/videoQueryKeys';
import { IVideo } from '@videos/types/IVideo';

export type IVideosRequest = IPageAbleRequest & {
  authors?: string[];
  isCartoon?: boolean;
  playLevels?: IUserPlayLevelNumber[];
  priceFrom?: number;
  priceTo?: number;
  solvedUsersPercentageFrom?: number;
  solvedUsersPercentageTo?: number;
  tagIds?: number[];
  viewCountFrom?: number;
  viewCountTo?: number;
};

type IVideosResponse = AxiosResponse<IPageAbleResponse<IVideo>>;

export const videosRequest = async (params?: IVideosRequest): Promise<IVideosResponse> => {
  return await axios.get('/videos', { params });
};

export function useQueryVideos(params?: IVideosRequest) {
  return useQuery<IVideosResponse, AxiosError>([videoQueryKeys.videos, params], () =>
    videosRequest(params),
  );
}
