import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  CaretRightOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  QuestionOutlined,
} from '@ant-design/icons';

import { FaChessKnightIcon } from '@components/atoms/icons';
import { PencilIcon } from '@components/atoms/icons/PencilIcon';
import {
  ExerciseStatusEnum,
  ExerciseStatusEnumKeys,
} from '@exercises/exercises/types/enums/ExerciseStatusEnum';
import { ExerciseTypeEnum } from '@exercises/exercises/types/enums/ExerciseTypeEnum';
import { AssessmentExerciseTypeEnum } from '@exercises/levelTesting/types/enums/AssessmentExerciseTypeEnum';
import {
  assessmentExerciseKeys,
  IAssessmentExercise,
} from '@exercises/levelTesting/types/IAssessmentExercise';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';

export const assessmentExerciseColumns = ({
  canEdit,
  onClickDelete,
  onClickEdit,
  onClickPause,
  onClickView,
}: {
  canEdit: boolean;
  onClickDelete?: ({ id }: { id: number }) => void;
  onClickEdit?: ({ id }: { id: number }) => void;
  onClickPause?: ({ id, status }: { id: number; status: ExerciseStatusEnumKeys }) => void;
  onClickView?: ({ id }: { id: number }) => void;
}): ColumnsType<IAssessmentExercise> => {
  const columns: ColumnsType<IAssessmentExercise> = [
    {
      dataIndex: assessmentExerciseKeys.id,
      render: (id: IAssessmentExercise['id']) =>
        onClickView ? (
          <Button onClick={() => onClickView({ id })} type="link">
            {id}
          </Button>
        ) : (
          id
        ),
      title: 'ID',
    },

    {
      dataIndex: assessmentExerciseKeys.type,
      render: (type: IAssessmentExercise['type']) => (
        <p
          className={`flex items-center gap-1 mb-0 ${
            type == AssessmentExerciseTypeEnum.TEST ? 'text-ant-orange-6' : ''
          }`}
        >
          {type == AssessmentExerciseTypeEnum.TEST ? <QuestionOutlined /> : <FaChessKnightIcon />}
          {ExerciseTypeEnum[type]}
        </p>
      ),
      title: 'Тип',
    },

    {
      dataIndex: assessmentExerciseKeys.playLevel,
      render: (playLevel: IAssessmentExercise['playLevel']) => userPlayLevels[playLevel],
      title: 'Уровень',
    },
    {
      dataIndex: assessmentExerciseKeys.priority,
      title: 'Порядковый номер',
    },
    {
      dataIndex: assessmentExerciseKeys.status,
      render: (status: IAssessmentExercise['status']) => ExerciseStatusEnum[status],
      title: 'Статус',
    },
  ];

  if (!canEdit || (!onClickDelete && !onClickEdit && !onClickDelete)) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: (exercise: IAssessmentExercise) => (
        <div className="flex gap-x-1.5">
          {onClickPause && (
            <Popconfirm
              cancelText="Отменить"
              icon={
                exercise.status == 'ACTIVE' ? (
                  <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                ) : (
                  <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                )
              }
              okText={exercise.status == 'ACTIVE' ? 'Приостановить' : 'Запустить'}
              okType="primary"
              onConfirm={() =>
                onClickPause({
                  id: exercise.id,
                  status: exercise.status == 'ACTIVE' ? 'PAUSED' : 'ACTIVE',
                })
              }
              placement="topLeft"
              title={
                <p className="text-sm mb-0">
                  Вы уверены, что хотите
                  <span className="font-semibold">
                    {exercise.status == 'ACTIVE' ? ' приостановить ' : ' запустить '}
                    {exercise.type == AssessmentExerciseTypeEnum.TEST ? 'тест' : 'упражнение'} #
                    {exercise.id}?
                  </span>
                </p>
              }
            >
              <Button
                ghost
                icon={exercise.status == 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
                type="primary"
              />
            </Popconfirm>
          )}
          {onClickEdit && (
            <Button
              ghost
              icon={<PencilIcon />}
              onClick={() => onClickEdit({ id: exercise.id })}
              type="primary"
            />
          )}
          {onClickDelete && (
            <Popconfirm
              cancelText="Отменить"
              icon={<DeleteOutlined className="text-ant-red-5" />}
              okText="Удалить"
              okType="danger"
              onConfirm={() => onClickDelete({ id: exercise.id })}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что хотите
                  <span className="font-bold">
                    {' '}
                    удалить{' '}
                    {exercise.type == AssessmentExerciseTypeEnum.TEST ? 'тест' : 'упражнение'} #
                    {exercise.id}
                  </span>
                  ?
                </span>
              }
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </div>
      ),
      title: 'Действия',
    },
  ];
};
