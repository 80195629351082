import { FC, useMemo } from 'react';
import { Button, Table } from 'antd';

import { useMutateBoardGroupDelete } from '@collections/board-groups/api/useMutateBoardGroupDelete';
import { useMutateBoardGroupStatusUpdate } from '@collections/board-groups/api/useMutateBoardGroupStatusUpdate';
import { boardGroupsFilters } from '@collections/board-groups/consts/filters/boardGroupsFilters';
import { useMutateChessPiecesGroupEdit } from '@collections/chess-pieces-groups/api/useMutateChessPiecesGroupEdit';
import { useMutateChessPiecesGroupItemDelete } from '@collections/chess-pieces-groups/api/useMutateChessPiecesGroupItemDelete';
import {
  IChessPiecesGroupsRequest,
  useQueryChessPiecesGroups,
} from '@collections/chess-pieces-groups/api/useQueryChessPiecesGroups';
import { chessPiecesGroupColumns } from '@collections/chess-pieces-groups/consts/columns/chessPiecesGroupColumns';
import {
  chessPiecesGroupsPageActions,
  IChessPiecesGroupPageActions,
} from '@collections/chess-pieces-groups/consts/pageActions/IChessPiecesGroupsPageActions';
import { CollectionTabs } from '@collections/components/molecules/CollectionTabs';
import { DraggableTableRow } from '@components/organisms/adminTable/organismComponents/DraggableTableRow';
import { PageMeta } from '@context/PageMetaContext';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';

import { ChessPiecesGroupCreateModal } from '../organisms/ChessPiecesGroupCreateModal';
import { ChessPiecesGroupEditModal } from '../organisms/ChessPiecesGroupEditModal';
import { ChessPiecesGroupViewModal } from '../organisms/ChessPiecesGroupViewModal';

export const ChessPiecesGroupsPage: FC = () => {
  const { actioned, clearParams, filtered, setActioned } = useUrlFilteredActioned<
    IChessPiecesGroupsRequest,
    IChessPiecesGroupPageActions
  >(boardGroupsFilters, chessPiecesGroupsPageActions);

  const chessPiecesGroupsQuery = useQueryChessPiecesGroups(filtered);
  const chessPiecesGroups = chessPiecesGroupsQuery.data?.data.content || [];
  const updateChessPiecesGroup = useMutateChessPiecesGroupEdit();
  const statusUpdate = useMutateBoardGroupStatusUpdate();
  const groupDelete = useMutateBoardGroupDelete();

  const deleteChessPiecesGroupItemMutation = useMutateChessPiecesGroupItemDelete();

  const title = useMemo(() => {
    if (actioned.action === 'createChessPiecesGroup') {
      return 'Создать группу фигур';
    } else if (actioned.action === 'view') {
      return `Группа ${actioned.actionId}`;
    }

    return 'Группа';
  }, [actioned.action]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active && over && active.id !== over?.id) {
      const previousBoardGroups = [...chessPiecesGroups];

      const activeIndex = previousBoardGroups.findIndex(({ id }) => id == active.id);
      const overIndex = previousBoardGroups.findIndex(({ id }) => id == over.id);

      updateChessPiecesGroup.mutate({
        ...previousBoardGroups[activeIndex],
        items: undefined,
        priority: overIndex + 1,
      });
    }
  };

  return (
    <>
      <PageMeta selectedMenuKeys={['collections']} title={title} />
      <CollectionTabs active="figures" />
      <div className="flex justify-end gap-4 mb-4">
        <Button
          className="sm:px-12"
          onClick={() => setActioned({ action: 'createChessPiecesGroup' })}
          size="large"
          type="primary"
        >
          Создать группу
        </Button>
      </div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={chessPiecesGroups?.map(({ id }) => id)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            columns={chessPiecesGroupColumns({
              onClickDelete: groupDelete.mutateAsync,
              onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
              onClickPause: statusUpdate.mutateAsync,
              onClickView: ({ id }) => setActioned({ action: 'view', actionId: id }),
            })}
            components={{
              body: {
                row: DraggableTableRow,
              },
            }}
            dataSource={chessPiecesGroups}
            loading={chessPiecesGroupsQuery.isFetching || updateChessPiecesGroup.isLoading}
            pagination={false}
            rowKey="id"
            scroll={{ x: '100%' }}
          />
        </SortableContext>
      </DndContext>

      <ChessPiecesGroupCreateModal
        isOpen={actioned.action == 'createChessPiecesGroup'}
        onClose={clearParams}
      />

      <ChessPiecesGroupViewModal
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onCancel={clearParams}
        onOpenEdit={() => setActioned({ action: 'edit', actionId: actioned.actionId })}
      />

      <ChessPiecesGroupEditModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
        onItemDelete={deleteChessPiecesGroupItemMutation.mutateAsync}
      />
    </>
  );
};
