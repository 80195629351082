import { FenStatusEnumKeys } from '@exercises/fens/types/enums/FenStatusEnum';
import { IUserPlayLevelNumber } from '@users/types/enums/UserPlayLevel';
import { IVideo } from '@videos/types/IVideo';

export interface IFen {
  id: number;
  localizations: Record<'en' | 'ru', { messageFromKaissa?: string }>;
  name: string;
  playLevel?: IUserPlayLevelNumber;
  status: FenStatusEnumKeys;
  value: string;
  videos?: IVideo[];
}

export const fenKeys: Record<keyof IFen, string> = {
  id: 'id',
  localizations: 'localizations',
  name: 'name',
  playLevel: 'playLevel',
  status: 'status',
  value: 'value',
  videos: 'videos',
};
