import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import {
  ChessPiecesSetStatusEnumKeys,
  ChessPiecesSetTypeEnumKeys,
} from '@collections/chess-pieces/types/enums/ChessPiecesSetEnum';
import { IChessPiecesSet } from '@collections/chess-pieces/types/IChessPiecesSet';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

import { chessPiecesQueryKeys } from './chessPiecesQueryKeys';

export type IChessPiecesSetRequest = IPageAbleRequest & {
  groupId?: number;
  status?: ChessPiecesSetStatusEnumKeys;
  statusNot?: ChessPiecesSetStatusEnumKeys;
  type?: ChessPiecesSetTypeEnumKeys;
};

type IChessPiecesResponse = AxiosResponse<
  IPageAbleResponse<IChessPiecesSet & { priority: number }>
>;

export const chessPiecesRequest = async (
  params?: IChessPiecesSetRequest,
): Promise<IChessPiecesResponse> => {
  return await axios.get('/chess-piece-sets', { params });
};

export function useQueryChessPiecesSet(params?: IChessPiecesSetRequest) {
  return useQuery<IChessPiecesResponse, AxiosError>(
    [chessPiecesQueryKeys.chessPieces, params],
    () => chessPiecesRequest(params),
  );
}
