import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema } from 'yup';

import { exerciseQueryKeys } from '@exercises/exercises/api/exerciseQueryKeys';
import {
  exerciseTestCreateValidation,
  IExerciseTestCreateRequest,
} from '@exercises/exercises/api/useMutateExerciseTestCreate';

export type IExerciseTestEditRequest = IExerciseTestCreateRequest & { id: number };

type IExerciseTestEditResponse = AxiosResponse<void>;

export const exerciseTestEditValidation: ObjectSchema<IExerciseTestEditRequest> = object({
  id: number().required(),
}).concat(exerciseTestCreateValidation);

export const exerciseTestEditRequest = async (
  payload: IExerciseTestEditRequest,
): Promise<IExerciseTestEditResponse> => {
  return await axios.patch(`/exercises/test/${payload.id}`, payload);
};

export function useMutateExerciseTestEdit() {
  const queryClient = useQueryClient();

  return useMutation<IExerciseTestEditResponse, AxiosError, IExerciseTestEditRequest>(
    exerciseTestEditRequest,
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(exerciseQueryKeys.exercises);
        queryClient.invalidateQueries([exerciseQueryKeys.exercise, { id: variables.id }]);
      },
    },
  );
}
