import { FC, useMemo } from 'react';
import { Button } from 'antd';

import { CollectionTabs } from '@collections/components/molecules/CollectionTabs';
import { useMutateLecturerCardDelete } from '@collections/lecturer-cards/api/useMutateLecturerCardDelete';
import { useMutateLecturerCardStatusUpdate } from '@collections/lecturer-cards/api/useMutateLecturerCardStatusUpdate';
import {
  ILecturerCardsRequest,
  useQueryLecturerCards,
} from '@collections/lecturer-cards/api/useQueryLecturerCards';
import { lecturerCardColumns } from '@collections/lecturer-cards/consts/columns/lecturerCardColumns';
import {
  ILecturerCardsPageActions,
  lecturerCardPageActions,
} from '@collections/lecturer-cards/consts/pageActions/ILecturerCardPageActions';
import { lecturerCardFilters } from '@collections/lecturer-cards/filters/lecturerCardFilters';
import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';

import { CreateLecturerCardModal } from '../organisms/CreateLecturerCardModal';
import { EditLecturerCardModal } from '../organisms/EditLecturerCardModal';
import { ViewLecturerCardModal } from '../organisms/ViewLecturerCardModal';

export const LecturerCards: FC = () => {
  const deleteCardMutation = useMutateLecturerCardDelete();
  const cardStatusUpdate = useMutateLecturerCardStatusUpdate();

  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    ILecturerCardsRequest,
    ILecturerCardsPageActions
  >(lecturerCardFilters, lecturerCardPageActions);

  const lecturerCardsQuery = useQueryLecturerCards(filtered);

  const title = useMemo(() => {
    if (actioned.action === 'create') {
      return 'Создать карточку лектора';
    } else if (actioned.action === 'view') {
      return `Карточка лектора ${actioned.actionId}`;
    }

    return 'Карточки лектора';
  }, [actioned.action]);

  return (
    <>
      <PageMeta selectedMenuKeys={['lecturer-cards']} title={title} />
      <CollectionTabs active="lecturer-cards" />
      <div className="flex justify-end gap-4 mb-4">
        <Button
          className="sm:px-12"
          onClick={() => setActioned({ action: 'create' })}
          size="large"
          type="primary"
        >
          Создать карточку лектора
        </Button>
      </div>

      <CreateLecturerCardModal isOpen={actioned.action == 'create'} onClose={clearParams} />

      <EditLecturerCardModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onClose={() => {
          clearParams();
        }}
      />

      <ViewLecturerCardModal
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onClose={() => {
          clearParams();
        }}
      />

      <AdminTable
        columns={lecturerCardColumns({
          onClickDelete: deleteCardMutation.mutateAsync,
          onClickPause: cardStatusUpdate.mutateAsync,
          onEditCard: (card) => {
            setActioned({ action: 'edit', actionId: card.id });
          },
          onOpenCard: (card) => {
            setActioned({ action: 'view', actionId: card.id });
          },
        })}
        query={lecturerCardsQuery}
        tableFilter={{ filtered, filters: lecturerCardFilters, onFilter }}
      />
    </>
  );
};
