import { ColumnsType } from 'antd/lib/table';
import moment from 'moment/moment';

import { IUserCoinPurchase, userCoinPurchasesKeys } from '@users/types/IUserCoinPurchase';

export const userPurchasesColumns: ColumnsType<IUserCoinPurchase> = [
  {
    dataIndex: userCoinPurchasesKeys.id,
    title: 'ID',
  },
  {
    dataIndex: userCoinPurchasesKeys.amount,
    title: 'Кол-во коинов',
  },
  {
    dataIndex: userCoinPurchasesKeys.price,
    title: 'Цена',
  },
  {
    dataIndex: userCoinPurchasesKeys.eventTime,
    render: (eventTime?: IUserCoinPurchase['eventTime']) => {
      const activityDate = moment(eventTime);
      return activityDate.isValid() ? activityDate.format('DD/MM/y HH:mm') : '';
    },
    title: 'Дата',
  },
];
