import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '@components/molecules/routes/ProtectedRoute';
import NotFoundPage from '@pages/errors/NotFoundPage';
import StaffsPage from '@staff/components/pages/StaffsPage';
import { staffPermissions } from '@typess/permissions/IStaffPermission';

export const StaffRouter = () => {
  return (
    <ProtectedRoute permissions={Object.values(staffPermissions)}>
      <Routes>
        <Route element={<StaffsPage />} index />
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </ProtectedRoute>
  );
};
