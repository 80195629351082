import { FC, useState } from 'react';
import { Card, DatePicker, Table, Typography } from 'antd';
import { DatePickerProps } from 'antd/lib';
import dayjs, { Dayjs } from 'dayjs';
import { PieConfig } from '@ant-design/charts';
import { G2, Pie } from '@ant-design/plots';

import { PageMeta } from '@context/PageMetaContext';
import { useQueryUsersCountryStats } from '@dashboards/api/useQueryUsersCountryStats';
import { usersCountryStatsColumns } from '@dashboards/consts/columns/usersCountryStatsColumn';

import { DashboardTabs } from '../molecules/DashboardTabs';

const ByCountryDashboardPage: FC = () => {
  const dateFormat = 'YYYY-MM-DD';

  const [startDate, setStartDate] = useState<Dayjs>(dayjs('2022/09/26', dateFormat));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const { data, isLoading } = useQueryUsersCountryStats({
    from: startDate?.format('YYYY-MM-DD HH:mm:ss'),
    to: endDate?.format('YYYY-MM-DD HH:mm:ss'),
  });

  const G = G2.getEngine('canvas');

  const transformedData = (data) => {
    if (data) {
      data?.sort((a, b) => b.usersCount - a.usersCount);
      const topCountries = data?.slice(0, 5);
      const totalOtherUsers = data
        ?.slice(5)
        .reduce((total, country) => total + country.usersCount, 0);
      const otherCountry = {
        countryName: 'Другие',
        usersCount: totalOtherUsers,
      };
      return [...topCountries, otherCountry];
    } else return [];
  };

  const config: PieConfig = {
    angleField: 'usersCount',
    colorField: 'countryName',
    data: transformedData(data?.data) ?? [],

    label: {
      formatter: (data) => {
        const group = new G.Group({});
        group.addShape({
          attrs: {
            fill: '#000',
            fontSize: 14,
            text: `${data.countryName}`,
            x: 0,
            y: 8,
          },
          type: 'text',
        });
        group.addShape({
          attrs: {
            fill: 'rgba(0, 0, 0, 0.45)',
            fontSize: 14,
            fontWeight: 700,
            text: `${(data.percent * 100).toFixed(2)}%`,
            x: 0,
            y: 25,
          },

          type: 'text',
        });
        return group;
      },
      labelHeight: 40,
      type: 'spider',
    },
    legend: false,
    radius: 0.75,
  };

  const disabledStartDate: DatePickerProps['disabledDate'] = (current) => {
    return (
      current &&
      (current <= dayjs('2022/09/25').endOf('day') || current > dayjs(endDate).endOf('day'))
    );
  };

  const disabledEndDate: DatePickerProps['disabledDate'] = (current) => {
    return current && (current <= dayjs(startDate).endOf('day') || current > dayjs().endOf('day'));
  };

  return (
    <>
      <PageMeta selectedMenuKeys={['dashboards']} title="По странам" />
      <DashboardTabs active="by-country" />
      <div className="flex flex-row items-center mb-6">
        <DatePicker
          defaultValue={startDate}
          disabledDate={disabledStartDate}
          onChange={(v) => v && setStartDate(v)}
          placeholder="from"
        />
        <Typography className="text-gray-700 mx-2">—</Typography>
        <DatePicker
          defaultValue={endDate}
          disabledDate={disabledEndDate}
          onChange={(v) => v && setEndDate(v)}
          placeholder="to"
        />
      </div>
      <div className="flex justify-between mb-6">
        <div className="w-[20%] mr-8 h-[calc(100vh-300px)]">
          <Table
            columns={usersCountryStatsColumns}
            dataSource={data?.data ?? []}
            loading={isLoading}
            pagination={false}
            scroll={{ y: 'calc(100vh - 335px)' }}
            tableLayout="fixed"
          />
        </div>
        <Card bodyStyle={{ height: '100%' }} className="w-[80%]">
          <Pie {...config} loading={isLoading} />
        </Card>
      </div>
    </>
  );
};

export default ByCountryDashboardPage;
