import { FC } from 'react';
import { Button, Modal } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, InputNumber } from 'formik-antd';

import { QueryModalWrapper } from '@components/molecules/QueryModalWrapper';
import {
  classifierUpdateValidation,
  IClassifierUpdateRequest,
  useMutateClassifierUpdate,
} from '@settings/classifierSkillTypes/api/useMutateClassifierSkillTypeUpdate';
import { useQueryClassifier } from '@settings/classifierSkillTypes/api/useQueryClassifierSkillTypes';
import { classifierSkillTypeKeys } from '@settings/classifierSkillTypes/types/IClassifierSkillType';
import { notify } from '@utils/notify';

export const ClassifierUpdateModal: FC<{
  classifierName?: string;
  isOpen: boolean;
  onCancel: () => void;
}> = ({ classifierName, isOpen, onCancel }) => {
  const classifierTypes = useQueryClassifier();
  const classifier = classifierTypes.data?.data.find((type) => type.name == classifierName);

  const classifierUpdate = useMutateClassifierUpdate();

  return (
    <Modal
      className="px-4 md:px-0 md:w-2/3 lg:w-1/2 pt-0 mt-0"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
    >
      <QueryModalWrapper
        isFound={!!classifier}
        isLoading={classifierTypes.isLoading}
        notFoundTitle="Классификатор не найден"
      >
        {!!classifier && (
          <Formik<IClassifierUpdateRequest>
            enableReinitialize
            initialValues={classifier}
            onSubmit={(values, { resetForm }) => {
              classifierUpdate.mutateAsync(values).then((data) => {
                if (data.status == 201 || data.status == 200) {
                  notify('success', `Классификатор ${classifier.name} изменен`);
                  onCancel();
                  resetForm();
                }
              });
            }}
            validationSchema={classifierUpdateValidation}
          >
            <Form className="space-y-6" layout="vertical">
              <h1 className="text-xl m-0">{classifier.name}</h1>
              <FormItem label="Название классификатора" name={classifierSkillTypeKeys.name}>
                <Input
                  disabled
                  name={classifierSkillTypeKeys.name}
                  placeholder="Название"
                  suffix
                  type="text"
                />
              </FormItem>

              {classifier.tacticEvalDifference && (
                <FormItem
                  label="Тактика разница eval"
                  name={classifierSkillTypeKeys.tacticEvalDifference}
                >
                  <InputNumber
                    className="w-full"
                    name={classifierSkillTypeKeys.tacticEvalDifference}
                    placeholder="Разница"
                    prefix
                  />
                </FormItem>
              )}

              <div className="grid md:grid-cols-2 gap-2">
                <FormItem
                  label="Практика в процентах"
                  name={classifierSkillTypeKeys.practicePartInPercent}
                >
                  <InputNumber
                    className="w-full"
                    name={classifierSkillTypeKeys.practicePartInPercent}
                    placeholder="Практика"
                    prefix
                  />
                </FormItem>

                <FormItem
                  label="Теория в процентах"
                  name={classifierSkillTypeKeys.theoryPartInPercent}
                >
                  <InputNumber
                    className="w-full"
                    name={classifierSkillTypeKeys.theoryPartInPercent}
                    placeholder="Теория"
                    prefix
                  />
                </FormItem>
              </div>

              {classifier.practiceEvaluationListPrimary?.length && (
                <div className="rounded-lg border p-2 md:px-4 shadow">
                  <h2 className="text-lg mb-4">Практика основная</h2>
                  {classifier.practiceEvaluationListPrimary.map((evaluation, index) => (
                    <div
                      className="grid grid-cols-3 gap-2 lg:gap-3"
                      key={`evaluationPrimaryInput${index}`}
                    >
                      <FormItem label="Начало" name={`practiceEvaluationListPrimary.${index}.from`}>
                        <InputNumber
                          className="w-full"
                          name={`practiceEvaluationListPrimary.${index}.from`}
                          prefix
                        />
                      </FormItem>
                      <FormItem label="Конец" name={`practiceEvaluationListPrimary.${index}.to`}>
                        <InputNumber
                          className="w-full"
                          name={`practiceEvaluationListPrimary.${index}.to`}
                          prefix
                        />
                      </FormItem>
                      <FormItem
                        label="Значение"
                        name={`practiceEvaluationListPrimary.${index}.value`}
                      >
                        <InputNumber
                          className="w-full"
                          name={`practiceEvaluationListPrimary.${index}.value`}
                          prefix
                        />
                      </FormItem>
                    </div>
                  ))}
                </div>
              )}

              {classifier.practiceEvaluationListSecondary?.length && (
                <div className="rounded-lg border p-2 md:p-4 shadow">
                  <h2 className="text-lg mb-4">Практика второстепенная</h2>
                  {classifier.practiceEvaluationListSecondary.map((evaluation, index) => (
                    <div
                      className="grid grid-cols-3 gap-2 lg:gap-3"
                      key={`evaluationPrimaryInput${index}`}
                    >
                      <FormItem
                        label="Начало"
                        name={`practiceEvaluationListSecondary.${index}.from`}
                      >
                        <InputNumber
                          className="w-full"
                          name={`practiceEvaluationListSecondary.${index}.from`}
                          prefix
                        />
                      </FormItem>
                      <FormItem label="Конец" name={`practiceEvaluationListSecondary.${index}.to`}>
                        <InputNumber
                          className="w-full"
                          name={`practiceEvaluationListSecondary.${index}.to`}
                          prefix
                        />
                      </FormItem>
                      <FormItem
                        label="Значение"
                        name={`practiceEvaluationListSecondary.${index}.value`}
                      >
                        <InputNumber
                          className="w-full"
                          name={`practiceEvaluationListSecondary.${index}.value`}
                          prefix
                        />
                      </FormItem>
                    </div>
                  ))}
                </div>
              )}

              <div className="grid md:grid-cols-2 gap-5">
                <Button block onClick={onCancel}>
                  Отменить
                </Button>

                <Button htmlType="submit" loading={classifierUpdate.isLoading} type="primary">
                  Сохранить
                </Button>
              </div>
            </Form>
          </Formik>
        )}
      </QueryModalWrapper>
    </Modal>
  );
};
