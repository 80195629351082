/**
 * ⚠️ DONT EDIT MANUALLY
 * This file is generated with `./src/cli/generateIcons.js`, check it for the detailed information
 */
import { FC } from 'react';

import { ReactComponent as ChessPieceBishopIconSvg } from '@assets/icons/chess-piece-bishop.svg';

import BaseIcon from './_BaseIcon';

type Props = React.ComponentPropsWithoutRef<'span'>;

export const ChessPieceBishopIcon: FC<Props> = (props) => {
  return <BaseIcon component={ChessPieceBishopIconSvg} style={{ fontSize: '1.5em' }} {...props} />;
};
