import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';

type ILinkStateRequest = {
  link: string;
};

type ILinkStateResponse = void;

export const linkStateRequest = async (
  params: ILinkStateRequest,
): Promise<AxiosResponse<ILinkStateResponse>> => {
  return await axios.get<ILinkStateResponse>('auth/link/state', { params });
};

export function useQueryLinkState(params: ILinkStateRequest) {
  const navigate = useNavigate();

  return useQuery<AxiosResponse<ILinkStateResponse>, AxiosError>(
    ['linkState', params],
    () => linkStateRequest(params),
    {
      enabled: !!params.link,
      onError: () => {
        message.warning(
          { className: 'font-semibold', content: 'Срок действия истек или неверный формат' },
          4,
        );
        navigate('/reset/email');
      },
      retry: false,
    },
  );
}
