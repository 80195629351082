import { ICountry } from '@typess/ICountry';
import { UserGenderEnumKeys } from '@users/types/enums/UserGenderEnum';
import { UserLanguageEnumKeys } from '@users/types/enums/UserLanguageEnum';
import { UserSkillTypeEnumKeys } from '@users/types/enums/UserSkillTypeEnum';
import { UserSubscriptionEnumKeys } from '@users/types/enums/UserSubscriptionEnum';

import { IUserPlayLevelNumber } from './enums/UserPlayLevel';
import { UserPurchaseTypeEnumKeys } from './enums/UserPurchaseTypeEnum';

export interface IUser {
  age?: number;
  ageCategory: string;
  appVersion: string;
  birthDate?: string;
  coins?: number;
  country?: ICountry;
  deviceKey?: string;
  devicePlatform: 'string';
  email?: string;
  firstName?: string;
  gameSoundOn?: boolean;
  gender?: UserGenderEnumKeys;
  hintCount: number;
  id: number;
  isParent: boolean | null;
  language?: UserLanguageEnumKeys;
  lastName?: string;
  moveBackCount: number;
  nickname: string;
  notificationsOn?: boolean;
  playLevel?: IUserPlayLevelNumber;
  playLevelEngineMap?: number | null;
  rating?: number;
  skillStats: {
    practiceScore: number;
    skillType: UserSkillTypeEnumKeys;
    theoryScore: number;
    totalScore: number;
  }[];
  subscription: {
    type: UserPurchaseTypeEnumKeys;
  };
  subscriptionStatus: UserSubscriptionEnumKeys;
  systemRating?: number;
  systemRatingChange?: number;
}

export const userKeys: Record<keyof IUser, string> = {
  age: 'age',
  ageCategory: 'ageCategory',
  appVersion: 'appVersion',
  birthDate: 'birthDate',
  coins: 'coins',
  country: 'country',
  deviceKey: 'deviceKey',
  devicePlatform: 'devicePlatform',
  email: 'email',
  firstName: 'firstName',
  gameSoundOn: 'gameSoundOn',
  gender: 'gender',
  hintCount: 'hintCount',
  id: 'id',
  isParent: 'isParent',
  language: 'language',
  lastName: 'lastName',
  moveBackCount: 'moveBackCount',
  nickname: 'nickname',
  notificationsOn: 'notificationsOn',
  playLevel: 'playLevel',
  playLevelEngineMap: 'playLevelEngineMap',
  rating: 'rating',
  skillStats: 'skillStats',
  subscription: 'subscription',
  subscriptionStatus: 'subscriptionStatus',
  systemRating: 'systemRating',
  systemRatingChange: 'systemRatingChange',
};
