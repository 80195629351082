import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

import { IBoardGroup } from '../types/IBoardGroup';
import { boardGroupQueryKeys } from './boardGroupQueryKeys';

export type IBoardGroupsRequest = IPageAbleRequest & { name?: string };

type IBoardGroupsResponse = AxiosResponse<IPageAbleResponse<IBoardGroup>>;

export const boardGroupsRequest = async (
  params?: IBoardGroupsRequest,
): Promise<IBoardGroupsResponse> => await axios.get('/chess-board-groups', { params });

export function useQueryBoardGroups(params?: IBoardGroupsRequest) {
  return useQuery<IBoardGroupsResponse, AxiosError>(
    [boardGroupQueryKeys.boardsGroups, params],
    () => boardGroupsRequest(params),
  );
}
