import type { FC } from 'react';
import { Button, Modal, Radio, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, InputNumber } from 'formik-antd';

import {
  IAvatarUpdateRequest,
  updateAvatarValidation,
  useMutateAvatarUpdate,
} from '@collections/avatars/api/useMutateAvatarUpdate';
import { useQueryAvatar } from '@collections/avatars/api/useQueryAvatar';
import { AvatarTypeEnum } from '@collections/avatars/types/enums/avatarTypeEnum';
import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { FileUpload } from '@components/molecules/files/FileUpload';

type EditAvatarModalProps = {
  id?: number;
  isOpen: boolean;
  onClose: () => void;
};

export const EditAvatarModal: FC<EditAvatarModalProps> = ({ id = 0, isOpen, onClose }) => {
  const updateAvatarMutation = useMutateAvatarUpdate();
  const avatarQuery = useQueryAvatar({ id });
  const avatar = avatarQuery.data?.data;

  return (
    <Modal footer={null} onCancel={onClose} open={isOpen} title="Редактировать аватар">
      {avatarQuery.isFetching && <BaseSkeleton />}

      {avatarQuery.isFetching ? (
        <BaseSkeleton />
      ) : !avatar ? (
        <NotFoundResult title="Аватар не найден" />
      ) : (
        <Formik<IAvatarUpdateRequest>
          enableReinitialize
          initialValues={{
            id: avatar.id,
            imgUrl: avatar.imgUrl,
            localizations: avatar.localizations,
            price: avatar.price,
            status: avatar.status,
            type: avatar.type,
          }}
          onSubmit={(values) =>
            updateAvatarMutation
              .mutateAsync({ ...values, price: avatar.type != 'PAID' ? 0 : values.price })
              .then(() => {
                onClose();
              })
          }
          validateOnBlur={false}
          validationSchema={updateAvatarValidation}
        >
          {({ setFieldValue, values: { type } }) => (
            <Form className="gap-4 md:gap-6" layout="vertical">
              <FormItem
                label={<CountryImageText src="ru" text="Название на русском" />}
                name="localizations.ru.name"
              >
                <Input name="localizations.ru.name" prefix />
              </FormItem>
              <FormItem
                label={<CountryImageText src="ru" text="Название на английском" />}
                name="localizations.en.name"
              >
                <Input name="localizations.en.name" prefix />
              </FormItem>

              <div>
                <FormItem label="Тип" name="type">
                  <Radio.Group
                    className="w-full"
                    onChange={(e) => {
                      setFieldValue('type', e.target.value);
                    }}
                    options={Object.entries(AvatarTypeEnum).map(([key, value]) => ({
                      label: value,
                      value: key,
                    }))}
                    value={type}
                  />
                </FormItem>
              </div>

              {type === 'PAID' && (
                <FormItem label="Стоимость" name="price">
                  <InputNumber
                    className="w-full"
                    controls={false}
                    max={999999}
                    min={1}
                    name="price"
                    prefix
                  />
                </FormItem>
              )}
              <Typography className="mb-4">Картинка</Typography>
              <div className="grid grid-cols-2 gap-6">
                <FormItem className="upload-form-item" label="SVG" name="imgUrl">
                  <FileUpload
                    accept=".svg"
                    defaultFileList={
                      avatar.imgUrl
                        ? [
                            {
                              name: '',
                              thumbUrl: avatar.imgUrl,
                              uid: avatar.imgUrl,
                              url: avatar.imgUrl,
                            },
                          ]
                        : undefined
                    }
                    folderName="imgUrl"
                    isSvg={true}
                    maxCount={1}
                    onChange={(info, linkInfo) => {
                      if (!info.file.status || info.file.status == 'removed')
                        setFieldValue('imgUrl', '');
                      if (info.file.status == 'done') setFieldValue('imgUrl', linkInfo.downloadUrl);
                    }}
                  />
                </FormItem>
                <FormItem className="upload-form-item" label="PNG" name="imgUrlPng">
                  <FileUpload
                    accept=".png"
                    defaultFileList={
                      avatar.imgUrlPng
                        ? [
                            {
                              name: '',
                              thumbUrl: avatar.imgUrlPng,
                              uid: avatar.imgUrlPng,
                              url: avatar.imgUrlPng,
                            },
                          ]
                        : undefined
                    }
                    folderName="imgUrlPng"
                    maxCount={1}
                    onChange={(info, linkInfo) => {
                      if (!info.file.status || info.file.status == 'removed')
                        setFieldValue('imgUrlPng', '');
                      if (info.file.status == 'done')
                        setFieldValue('imgUrlPng', linkInfo.downloadUrl);
                    }}
                  />
                </FormItem>
              </div>

              <div className="flex gap-4 justify-end">
                <Button onClick={onClose}>Отмена</Button>
                <Button htmlType="submit" loading={updateAvatarMutation.isLoading} type="primary">
                  Сохранить
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
