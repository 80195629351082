import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema } from 'yup';

import { boardCommonKeys } from '@collections/boards/api/boardCommonKeys';
import { notify } from '@utils/notify';

import { boardGroupQueryKeys } from './boardGroupQueryKeys';

export type IBoardGroupAddItemRequest = { chessBoardId?: number; id: number };

type IBoardGroupAddItemResponse = AxiosResponse<void>;

export const boardGroupAddItemInitialValue: IBoardGroupAddItemRequest = {
  chessBoardId: undefined,
  id: 0,
};

export const boardGroupAddItemValidation: ObjectSchema<IBoardGroupAddItemRequest> = object({
  chessBoardId: number().required(),
  id: number().required(),
});
export const boardGroupAddItemRequest = async ({
  chessBoardId,
  id,
}: IBoardGroupAddItemRequest): Promise<IBoardGroupAddItemResponse> =>
  await axios.post(`/chess-board-groups/${id}/item/${chessBoardId}`);

export function useMutateBoardGroupAddItem() {
  const queryClient = useQueryClient();

  return useMutation<IBoardGroupAddItemResponse, AxiosError, IBoardGroupAddItemRequest>(
    boardGroupAddItemRequest,
    {
      onSuccess: () => {
        notify('success', 'Доска добавлена в группу');
        queryClient.invalidateQueries(boardGroupQueryKeys.boardGroup);
        queryClient.invalidateQueries(boardCommonKeys.boards);
      },
    },
  );
}
