import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { promocodesQueryKeys } from '@settings/promocodes/api/promocodesQueryKeys';
import { IPromocodeGroup } from '@settings/promocodes/types/IPromocodeGroup';

export type IPromocodeGroupRequest = {
  id: number;
};

type IPromocodeGroupResponse = AxiosResponse<IPromocodeGroup>;

export const promocodeGroupRequest = async ({
  id,
}: IPromocodeGroupRequest): Promise<IPromocodeGroupResponse> => {
  return await axios.get(`/promocode-groups/${id}`);
};

export function useQueryPromocodeGroup(params: IPromocodeGroupRequest) {
  return useQuery<IPromocodeGroupResponse, AxiosError>(
    [promocodesQueryKeys.promocodeGroup, params],
    () => promocodeGroupRequest(params),
    { enabled: params.id > 0 },
  );
}
