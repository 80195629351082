import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '@components/molecules/routes/ProtectedRoute';
import NotFoundPage from '@pages/errors/NotFoundPage';
import { userPermissions } from '@typess/permissions/IUserPermission';
import { UserPage } from '@users/components/pages/UserPage';

import { UserDashboardPage } from './components/pages/UserDashboardPage';
import { UsersPage } from './components/pages/UsersPage';

export const UserRouter = () => {
  return (
    <ProtectedRoute permissions={Object.values(userPermissions)}>
      <Routes>
        <Route element={<UsersPage />} index />
        <Route element={<UserPage />} path=":id" />
        <Route element={<UserDashboardPage />} path=":id/dashboard" />

        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </ProtectedRoute>
  );
};
