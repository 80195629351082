import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { UserBrainstormExercise, userBrainstormExerciseKeys } from '@users/types/IUserBrainstorm';

type Props = {
  onView: (id: number) => void;
};

export const brainstormExerciseColumns = ({
  onView,
}: Props): ColumnsType<UserBrainstormExercise> => {
  return [
    {
      dataIndex: userBrainstormExerciseKeys.id,
      render: (id: UserBrainstormExercise['id']) => {
        return onView ? (
          <Button onClick={() => onView(id)} type="link">
            {id}
          </Button>
        ) : (
          id
        );
      },
      title: 'ID',
    },
    {
      dataIndex: userBrainstormExerciseKeys.isSolved,
      render: (isSolved: UserBrainstormExercise['isSolved']) =>
        isSolved ? 'Правильно' : 'Неправильно',

      title: 'Результат',
    },
    {
      dataIndex: userBrainstormExerciseKeys.rating,
      render: (id: UserBrainstormExercise['id']) => {
        return onView ? (
          <Button onClick={() => onView(id)} type="link">
            {id}
          </Button>
        ) : (
          id
        );
      },
      title: 'Уровень упражненя',
    },
  ];
};
