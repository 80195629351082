import { FC } from 'react';
import { Button, Modal } from 'antd';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';

import { CountryImageText } from '@components/atoms/CountryImageText';
import { FileUpload } from '@components/molecules/files/FileUpload';
import { AuthorRankEnum } from '@tags/types/AuthorRankEnum';
import { IBackendError } from '@typess/IBackendError';
import { notify } from '@utils/notify';

import {
  ITagAuthorCreateRequest,
  tagAuthorCreateInitial,
  tagAuthorCreateValidation,
  useMutateTagAuthorCreate,
} from '../../api/useMutateTagAuthorCreate';

type TagCreateModalType = {
  isOpen: boolean;
  onCancel: () => void;
  previewKey?: string;
};

export const TagAuthorCreateModal: FC<TagCreateModalType> = ({ isOpen, onCancel, previewKey }) => {
  const authorCreate = useMutateTagAuthorCreate();

  return (
    <Modal
      className="px-4"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={<h1 className="text-xl m-0">Создание автора</h1>}
    >
      <Formik<ITagAuthorCreateRequest>
        initialValues={tagAuthorCreateInitial}
        onSubmit={(values, { resetForm }) => {
          authorCreate
            .mutateAsync(values)
            .then((data) => {
              if (data.status == 201 || data.status == 200) {
                notify('success', 'Тег автора создан');
                onCancel();
                resetForm();
              }
            })
            .catch((error: AxiosError<IBackendError>) => {
              if (
                error.response?.status == 422 &&
                error.response.data.message == 'Tag.alreadyExist'
              ) {
                resetForm({
                  errors: {
                    localizations: {
                      en: 'Такое название уже существует',
                      ru: 'Такое название уже существует',
                    },
                  },
                  values,
                });
              }
            });
        }}
        validationSchema={tagAuthorCreateValidation}
      >
        {({ setFieldValue }) => (
          <Form className="space-y-8" layout="vertical">
            <FormItem label="Звание автора" name="authorRank">
              <Select
                name="authorRank"
                options={Object.keys(AuthorRankEnum).map((rank) => ({
                  label: rank,
                  value: rank,
                }))}
              />
            </FormItem>

            <FormItem
              label={<CountryImageText src="en" text="Имя автора на английском" width={14} />}
              name="localizations.en"
            >
              <Input
                maxLength={60}
                name="localizations.en"
                placeholder="Имя автора"
                showCount
                suffix
                type="text"
              />
            </FormItem>

            <FormItem
              label={<CountryImageText src="ru" text="Имя автора на русском" width={14} />}
              name="localizations.ru"
            >
              <Input
                maxLength={60}
                name="localizations.ru"
                placeholder="Имя автора"
                showCount
                suffix
                type="text"
              />
            </FormItem>
            <FormItem className="upload-form-item text-center custom-picture" name={`imgUrl`}>
              <FileUpload
                accept=".png,.jpeg,.jpg"
                defaultFileList={undefined}
                folderName="previews"
                key={previewKey}
                label="Изображение автора"
                listType="picture-card"
                maxCount={1}
                onChange={(info, linkInfo) => {
                  if (!info.file.status || info.file.status == 'removed')
                    setFieldValue(`imgUrl`, '');
                  if (info.file.status == 'done') setFieldValue(`imgUrl`, linkInfo.downloadUrl);
                }}
              />
            </FormItem>

            <div className="grid md:grid-cols-2 gap-5">
              <Button block onClick={onCancel}>
                Отменить
              </Button>

              <Button htmlType="submit" loading={authorCreate.isLoading} type="primary">
                Создать автора
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
