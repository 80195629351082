import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';

import { PencilIcon } from '@components/atoms/icons/PencilIcon';
import { StaffStateEnum } from '@staff/types/enums/StaffStateEnum';
import { IStaff, staffKeys } from '@staff/types/IStaff';

export const staffsColumns = ({
  canEdit,
  onClickDelete,
  onClickEdit,
}: {
  canEdit: boolean;
  onClickDelete: ({ id }: { id: number }) => void;
  onClickEdit: ({ id }: { id: number }) => void;
}): ColumnsType<IStaff> => {
  const columns: ColumnsType<IStaff> = [
    {
      dataIndex: staffKeys.fullName,
      title: 'ФИО',
    },
    {
      dataIndex: staffKeys.email,
      title: 'Почта',
    },
    {
      dataIndex: staffKeys.lastActivity,
      render: (lastActivity?: IStaff['lastActivity']) => {
        const activityDate = moment(lastActivity);
        return activityDate.isValid() ? activityDate.format('DD/MM/y HH:mm') : '';
      },
      title: 'Последняя активность',
    },
    {
      dataIndex: staffKeys.state,
      render: (state: IStaff['state']) => StaffStateEnum[state],
      title: 'Статус',
    },
  ];

  if (!canEdit) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: ({ email, id }: IStaff) => (
        <div className="flex gap-1.5">
          <Button ghost icon={<PencilIcon />} onClick={() => onClickEdit({ id })} type="primary" />
          <Popconfirm
            cancelText="Отменить"
            icon={<DeleteOutlined className="text-ant-red-5" color="red" />}
            okText="Удалить"
            okType="danger"
            onConfirm={() => onClickDelete({ id })}
            placement="topLeft"
            title={
              <span className="text-sm">
                Вы уверены, что хотите удалить <span className="font-bold">{email}</span>?
              </span>
            }
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
      title: 'Действия',
    },
  ];
};
