import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { appPromotionsQueryKeys } from '@shop/appPromotions/api/appPromotionsQueryKeys';
import {
  AppPromotionStatusEnum,
  AppPromotionStatusEnumKeys,
} from '@shop/appPromotions/types/enums/AppPromotionStatusEnum';
import { IAppPromotion } from '@shop/appPromotions/types/IAppPromotion';
import { notify } from '@utils/notify';

export type IAppPromotionStatusRequest = { id: number; status: AppPromotionStatusEnumKeys };

type IAppPromotionStatusResponse = AxiosResponse<IAppPromotion>;

export const appPromotionStatusRequest = async ({
  id,
  ...payload
}: IAppPromotionStatusRequest): Promise<IAppPromotionStatusResponse> => {
  return await axios.put(`iap-promotions/${id}/status`, payload);
};

export function useMutateAppPromotionStatus() {
  const queryClient = useQueryClient();

  return useMutation<IAppPromotionStatusResponse, AxiosError, IAppPromotionStatusRequest>(
    appPromotionStatusRequest,
    {
      onSuccess: async (data, variables) => {
        if (data.data.status == variables.status) {
          notify('success', `Статус изменен на "${AppPromotionStatusEnum[variables.status]}"`);
        } else {
          notify('error', 'Статус не изменен');
        }
        await queryClient.invalidateQueries(appPromotionsQueryKeys.appPromotions);
      },
    },
  );
}
