import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  CaretRightOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import { boardGroupKeys, IBoardGroup } from '@collections/board-groups/types/IBoardGroup';
import { BoardStatusEnumKeys } from '@collections/boards/types/enums/boardStatusEnum';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { PencilIcon } from '@components/atoms/icons/PencilIcon';

export const boardGroupColumns = ({
  onClickDelete,
  onClickEdit,
  onClickPause,
  onClickView,
}: {
  onClickDelete: ({ id }: { id: number }) => void;
  onClickEdit: ({ id }: { id: number }) => void;
  onClickPause: ({ id, status }: { id: number; status: BoardStatusEnumKeys }) => void;
  onClickView: ({ id }: { id: number }) => void;
}): ColumnsType<IBoardGroup> => {
  const columns: ColumnsType<IBoardGroup> = [
    {
      align: 'center' as const,
      key: 'sort',
      width: 50,
    },
    {
      dataIndex: boardGroupKeys.id,
      render: (id: IBoardGroup['id']) => (
        <Button onClick={() => onClickView({ id })} type="link">
          {id}
        </Button>
      ),
      title: 'ID',
    },
    {
      dataIndex: boardGroupKeys.localizations,
      render: (localizations: IBoardGroup['localizations']) => {
        return (
          <div>
            <CountryImageText src="en" text={localizations?.en?.name || '-'} />
            <CountryImageText src="ru" text={localizations?.ru?.name || '-'} />
          </div>
        );
      },
      title: 'Название группы',
    },
    {
      dataIndex: boardGroupKeys.itemsCount,
      title: 'Количество досок',
    },
    // {
    //   dataIndex: boardGroupKeys.createdDate,
    //   render: (createdDate: IBoardGroup['createdDate']) => (
    //     <span className="whitespace-nowrap">{moment(createdDate).format('LL')}</span>
    //   ),
    //   title: 'Дата создания',
    // },
  ];

  return [
    ...columns,
    {
      key: 'action',
      render: (boardGroup: IBoardGroup) => (
        <div className="flex gap-x-1.5">
          <Popconfirm
            cancelText="Отменить"
            icon={
              boardGroup.status == 'ACTIVE' ? (
                <PauseCircleOutlined className="text-ant-blue-5 text-base" />
              ) : (
                <PlayCircleOutlined className="text-ant-blue-5 text-base" />
              )
            }
            okText={boardGroup.status === 'ACTIVE' ? 'Приостановить' : 'Запустить'}
            okType="primary"
            onConfirm={() =>
              onClickPause({
                id: boardGroup.id,
                status: boardGroup.status == 'ACTIVE' ? 'DRAFT' : 'ACTIVE',
              })
            }
            placement="topLeft"
            title={
              <p className="text-sm mb-0">
                Вы уверены, что хотите
                <span className="font-semibold">
                  {boardGroup.status == 'ACTIVE' ? ' приостановить ' : ' запустить '}
                  {boardGroup.localizations.ru?.name}?
                </span>
              </p>
            }
          >
            <Button
              ghost
              icon={boardGroup.status === 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
              type="primary"
            />
          </Popconfirm>
          <Button
            ghost
            icon={<PencilIcon />}
            onClick={() => onClickEdit({ id: boardGroup.id })}
            type="primary"
          />
          <Popconfirm
            cancelText="Отменить"
            icon={<DeleteOutlined className="text-ant-red-5" />}
            okText="Удалить"
            okType="danger"
            onConfirm={() => onClickDelete({ id: boardGroup.id })}
            placement="topLeft"
            title={
              <span className="text-sm">
                Вы уверены, что хотите удалить группу досок
                <span className="font-bold"> &quot;{boardGroup.localizations.ru?.name}&quot;</span>?
              </span>
            }
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
      title: 'Действия',
    },
  ];
};
