import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { CalendarOutlined, LinkOutlined } from '@ant-design/icons';

import { promoCodeTypes } from '@settings/promocodes/types/enums/PromocodeTypeEnum';
import { IPromocodeGroup, promocodeGroupKeys } from '@settings/promocodes/types/IPromocodeGroup';

export const promocodeGroupsColumns = ({
  onClickDownload,
  onClickView,
}: {
  onClickDownload: ({ id }: { id: number }) => void;
  onClickView: ({ id }: { id: number }) => void;
}): ColumnsType<IPromocodeGroup> => [
  {
    dataIndex: promocodeGroupKeys.id,
    render: (id: IPromocodeGroup['id']) => (
      <Button onClick={() => onClickView({ id })} type="link">
        {id}
      </Button>
    ),
    title: 'ID',
  },
  {
    dataIndex: promocodeGroupKeys.groupName,
    render: (name: IPromocodeGroup['groupName'], promocodeGroup) => (
      <span
        className={
          promocodeGroup.expireDate && moment(promocodeGroup.expireDate) < moment()
            ? 'text-ant-gray-6'
            : ''
        }
      >
        {name}
      </span>
    ),
    title: 'Название группы',
  },
  {
    dataIndex: promocodeGroupKeys.type,
    render: (type: IPromocodeGroup['type']) => promoCodeTypes[type] ?? '-',
    title: 'Тип',
  },
  {
    dataIndex: promocodeGroupKeys.promocodeCount,
    render: (count: IPromocodeGroup['promocodeCount'], promocodeGroup) => (
      <span>{`${promocodeGroup.promocodeUsedCount}/${count}`}</span>
    ),
    title: 'Использовано',
  },
  {
    dataIndex: promocodeGroupKeys.expireDate,
    render: (expireDate: IPromocodeGroup['expireDate']) =>
      expireDate ? (
        <div className={`space-y-2 ${moment(expireDate) > moment() ? '' : 'text-ant-gray-6'}`}>
          <p className="mb-0">
            <CalendarOutlined /> {moment(expireDate).format('LL')}
          </p>
        </div>
      ) : (
        <span>-</span>
      ),
    title: 'Дата окончания',
  },
  {
    key: 'action',
    render: (promocodeGroup: IPromocodeGroup) => (
      <div className="flex gap-x-1.5">
        <Tooltip title="Скачать группу промокодов в Excel формате">
          <Button
            ghost
            icon={<LinkOutlined />}
            onClick={() => onClickDownload({ id: promocodeGroup.id })}
            type="primary"
          />
        </Tooltip>
      </div>
    ),
    title: 'Действия',
  },
];
