import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { boolean, object, ObjectSchema, string } from 'yup';

import { versionsQueryKeys } from '@settings/versions/api/versionsQueryKeys';
import { VersionsPlatformEnumKeys } from '@settings/versions/types/enums/VersionsPlatformEnum';
import { IVersion } from '@settings/versions/types/IVersion';

export type IVersionCreateRequest = Pick<
  IVersion,
  'version' | 'isForced' | 'platform' | 'localizations'
>;

type IVersionCreateResponse = AxiosResponse<IVersion>;

export const versionCreateCreateInitial: IVersionCreateRequest = {
  isForced: false,
  localizations: { en: { message: '', title: '' }, ru: { message: '', title: '' } },
  platform: 'ANDROID',
  version: '',
};

export const versionCreateValidation: ObjectSchema<IVersionCreateRequest> = object({
  isForced: boolean().required(),
  localizations: object({
    en: object({ message: string(), title: string() }),
    ru: object({ message: string(), title: string() }),
  }),
  platform: string<VersionsPlatformEnumKeys>().required(),
  version: string()
    .nullable()
    .required('Версия обязательна для заполнения')
    .min(1, 'Название должно быть не менее 1 символа')
    .max(9, 'Название должно быть не более 9 символов'),
});

export const versionCreateRequest = async (
  payload: IVersionCreateRequest,
): Promise<IVersionCreateResponse> => {
  return await axios.post('/v2/mobile-versions', {
    ...payload,
  });
};

export function useMutateVersionCreate() {
  const queryClient = useQueryClient();

  return useMutation<IVersionCreateResponse, AxiosError, IVersionCreateRequest>(
    versionCreateRequest,
    {
      onSuccess: () => queryClient.invalidateQueries(versionsQueryKeys.versions),
    },
  );
}
