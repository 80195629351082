import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

import { IAvatar } from '../types/IAvatar';
import { avatarCommonKeys } from './avatarCommonKeys';

export type IAvatarsRequest = IPageAbleRequest & {
  groupId?: number;
};

type IAvatarsResponse = IPageAbleResponse<IAvatar>;

export const avatarsGetRequest = async (params?: IAvatarsRequest) =>
  axios.get<IAvatarsResponse>(`/avatars`, { params });

export function useQueryAvatars(params?: IAvatarsRequest) {
  return useQuery<AxiosResponse<IAvatarsResponse>, AxiosError>(
    [avatarCommonKeys.avatars, params],
    () => avatarsGetRequest(params),
  );
}
