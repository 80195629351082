import { Children, FC } from 'react';

import { FilterDropdown } from '@components/molecules/filters/FilterDropdown';
import { TableHeaderSortIcon } from '@components/organisms/adminTable/organismComponents/TableHeaderSortIcon';
import { TableFilter } from '@typess/IFilters';

interface HeaderInterface {
  props: {
    children: { props: { column: { dataIndex: string; key: string; title: string | object } } }[];
  };
  tableFilterProps: TableFilter;
}

export const TableHeader: FC<HeaderInterface> = ({ props, tableFilterProps }) => {
  const { filtered, filters, onFilter } = tableFilterProps;

  return (
    <tr>
      {Children.map(props.children, (child, ind) => {
        const { dataIndex, key, title } = child.props.column;
        const filter = filters?.[dataIndex || key];
        if (filter && filter.input) {
          return (
            <FilterDropdown
              dataIndex={dataIndex || key}
              filter={filter}
              filtered={filtered}
              onFilter={onFilter}
              placement={
                ind == 0 ? 'bottomLeft' : ind + 1 < props.children.length ? 'bottom' : 'bottomRight'
              }
              title={title}
            />
          );
        } else if (filter && filter.sort) {
          return (
            <th>
              <div className="flex justify-between ">
                <span>{filter.title || title?.toString()}</span>
                <TableHeaderSortIcon
                  dataIndex={dataIndex}
                  onFilter={onFilter}
                  orderedBy={filtered['sortDirection']}
                  sortOrderReversed={filter.sortOrderReversed}
                  sortedBy={filtered['sortBy']}
                />
              </div>
            </th>
          );
        }

        return <th>{title?.toString()}</th>;
      })}
    </tr>
  );
};
