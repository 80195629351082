import { Navigate } from 'react-router-dom';

import { useQueryMe } from '@auth/api/useQueryMe';

const AuthRoute = ({ auth, children }: { auth: boolean; children: JSX.Element }) => {
  const me = useQueryMe();

  if (auth && (!localStorage.getItem('token') || me.error?.response?.status == 401)) {
    return <Navigate to="/login" />;
  }

  if (!auth && localStorage.getItem('token')) {
    return <Navigate to="/" />;
  }

  return children;
};

export default AuthRoute;
