import { ITag } from '@tags/types/ITag';

import { LecturerCardStatusEnumKeys } from './enums/LecturerCardStatusEnum';

export interface ILecturerCard {
  authorTag: ITag;
  id: number;
  imgUrl: string;
  localizations: Record<'en' | 'ru', { description: string; title: string }>;
  status?: LecturerCardStatusEnumKeys;
}

export const lecturerCardKeys: Record<keyof ILecturerCard, string> = {
  authorTag: 'authorTag',
  id: 'id',
  imgUrl: 'imgUrl',
  localizations: 'localizations',
  status: 'status',
};
