import { AuthorRankEnum } from '@tags/types/AuthorRankEnum';
import { IFilters } from '@typess/IFilters';

export const tagsFilters: IFilters = {
  authorRank: {
    queries: [{ allowed: (value) => Object.keys(AuthorRankEnum).includes(value) }],
  },
  name: {},
  page: {
    queries: [{ default: 1 }],
  },
  pageSize: {
    queries: [
      {
        allowed: (value) => {
          return ['30'].includes(value);
        },
        default: 30,
      },
    ],
  },
  sortBy: {},
  sortDirection: {},
};
