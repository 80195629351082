import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '@components/molecules/routes/ProtectedRoute';
import NotFoundPage from '@pages/errors/NotFoundPage';
import { videoPermissions } from '@typess/permissions/IVideoPermission';
import { VideoFilesPage } from '@videos/components/pages/VideoFilesPage';

import { VideosPage } from './components/pages/VideosPage';

export const VideoRouter = () => {
  return (
    <ProtectedRoute permissions={Object.values(videoPermissions)}>
      <Routes>
        <Route element={<VideosPage />} index />
        <Route element={<VideoFilesPage />} path="/files" />
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </ProtectedRoute>
  );
};
