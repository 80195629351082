import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { notify } from '@utils/notify';

import { boardCommonKeys } from './boardCommonKeys';

type IDeleteBoardRequest = {
  id: number;
};

export const deleteBoardRequest = ({ id }: IDeleteBoardRequest) =>
  axios.delete(`/chess-boards/${id}`);

export const useMutateBoardDelete = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, IDeleteBoardRequest>(deleteBoardRequest, {
    onError: () => {
      notify('error', 'Повторите попытку позже');
    },
    onSuccess: () => {
      notify('success', 'Доска успешно удалена');

      queryClient.invalidateQueries(boardCommonKeys.boards);
    },
  });
};
