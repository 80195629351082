import { FC } from 'react';
import { Button } from 'antd';

import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { videoPermissions } from '@typess/permissions/IVideoPermission';
import { checkEditPermission } from '@utils/checkEditPermission';
import { useMutateVideoDelete } from '@videos/api/useMutateVideoDelete';
import { useMutateVideoStatusUpdate } from '@videos/api/useMutateVideoStatusUpdate';
import { IVideosRequest, useQueryVideos } from '@videos/api/useQueryVideos';
import { VideoTabs } from '@videos/components/molecules/VideoTabs';
import { VideoCreateModal } from '@videos/components/organisms/VideoCreateModal';
import { VideoEditModal } from '@videos/components/organisms/VideoEditModal/VideoEditModal';
import { VideoViewModal } from '@videos/components/organisms/VideoViewModal';
import { videosColumns } from '@videos/consts/columns/videosColumns';
import { videosFilters } from '@videos/consts/filters/videosFilters';
import {
  IVideosPageActions,
  videosPageActions,
} from '@videos/consts/pageActions/IVideosPageActions';

export const VideosPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IVideosRequest,
    IVideosPageActions
  >(videosFilters, videosPageActions);

  const videos = useQueryVideos(filtered);

  const videoStatusUpdate = useMutateVideoStatusUpdate();
  const videoDelete = useMutateVideoDelete();

  const canEditVideo = checkEditPermission(videoPermissions);

  return (
    <div>
      <PageMeta
        selectedMenuKeys={['videos']}
        subTitle={
          actioned.action == 'view' && actioned.actionId
            ? `Видео #${actioned.actionId}`
            : actioned.action == 'create' && !actioned.actionId
            ? 'Создать видео'
            : actioned.action == 'edit' && actioned.actionId
            ? `Изменить видео #${actioned.actionId}`
            : ''
        }
        title="Видеобиблиотека"
      />
      <VideoTabs active="videos" />

      {canEditVideo && (
        <div className="flex justify-end gap-4">
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'create' })}
            size="large"
            type="primary"
          >
            Новое видео
          </Button>
        </div>
      )}

      <AdminTable
        columns={videosColumns({
          canEdit: canEditVideo,
          onClickDelete: videoDelete.mutateAsync,
          onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
          onClickPause: videoStatusUpdate.mutateAsync,
          onClickView: ({ id }) => setActioned({ action: 'view', actionId: id }),
        })}
        emptyDescription={
          <div>
            <p>Видео не найдено.</p>
            <p>Вы можете создать видео, нажав на кнопку “Новое видео”</p>
          </div>
        }
        query={videos}
        tableFilter={{ filtered, filters: videosFilters, onFilter }}
      />

      <VideoViewModal
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onCancel={clearParams}
        openEdit={() => setActioned({ action: 'edit', actionId: actioned.actionId })}
      />

      <VideoCreateModal
        isOpen={actioned.action == 'create'}
        onCancel={clearParams}
        onSubmit={(id) => setActioned({ action: 'edit', actionId: id, actionStep: 1 })}
      />

      <VideoEditModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
        setStep={(step) =>
          setActioned({ action: actioned.action, actionId: actioned.actionId, actionStep: step })
        }
        step={
          typeof actioned.actionStep == 'string'
            ? parseInt(actioned.actionStep)
            : actioned.actionStep
        }
      />
    </div>
  );
};
