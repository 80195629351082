import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPayingUser } from '@dashboards/types/IPayingUser';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

import { dashboardQueryKeys } from './dashboardQueryKeys';

export type IPayingUsersRequest = IPageAbleRequest;

type IPayingUsersResponse = IPageAbleResponse<IPayingUser>;

export const payingUsersRequest = async (params?: IPayingUsersRequest) => {
  return await axios.get<IPayingUsersResponse>('/stats/purchase-events', { params });
};

export function useQueryPayingUsers(params?: IPayingUsersRequest) {
  return useQuery<AxiosResponse<IPayingUsersResponse>, AxiosError>(
    [dashboardQueryKeys.payingUsers, params],
    () => payingUsersRequest(params),
  );
}
