import { FC, useEffect } from 'react';
import { Modal, Steps } from 'antd';

import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { useQueryVideo } from '@videos/api/useQueryVideo';
import { checkAllowedSteps } from '@videos/utils/checkAllowedSteps';

import { StepOne } from './StepOne';
import { StepThree } from './StepThree';
import { StepTwo } from './StepTwo';

export const VideoEditModal: FC<{
  id?: number;
  isOpen: boolean;
  onCancel: () => void;
  setStep: (step: number) => void;
  step?: number;
}> = ({ id = 0, isOpen, onCancel, setStep, step = 0 }) => {
  const videoQuery = useQueryVideo({ id });
  const video = videoQuery.data?.data;

  const steps = checkAllowedSteps(video);

  useEffect(() => {
    if (!videoQuery.isFetching && steps[step].disabled !== false) {
      setStep(0);
    }
  }, [videoQuery, step]);

  return (
    <Modal
      className="px-4 md:px-0 w-full md:w-5/6 top-8"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        video?.isCartoon == false && (
          <Steps
            className="site-navigation-steps"
            current={step}
            items={[
              { disabled: steps[0].disabled, status: steps[0].status, title: 'Шаг 1' },
              { disabled: steps[1].disabled, status: steps[1].status, title: 'Шаг 2' },
              { disabled: steps[2].disabled, status: steps[2].status, title: 'Шаг 3' },
            ]}
            onChange={setStep}
            type="navigation"
          />
        )
      }
      wrapClassName="video-modal"
    >
      {videoQuery.isFetching ? (
        <BaseSkeleton />
      ) : !video ? (
        <NotFoundResult title="Видео не найдено" />
      ) : step == 0 ? (
        <StepOne changeStep={setStep} onCancel={onCancel} steps={steps} video={video} />
      ) : step == 1 ? (
        <StepTwo changeStep={setStep} onCancel={onCancel} steps={steps} video={video} />
      ) : (
        <StepThree changeStep={setStep} onCancel={onCancel} video={video} />
      )}
    </Modal>
  );
};
