import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema, string } from 'yup';

import { ExerciseStatusEnumKeys } from '@exercises/exercises/types/enums/ExerciseStatusEnum';

import { IAssessmentExerciseProblem } from '../types/IAssessmentExerciesProblem';
import { assessmentExerciseQueryKeys } from './assessmentExerciseQueryKeys';

export type IAssessmentExerciseProblemEditRequest = Pick<
  IAssessmentExerciseProblem,
  'status' | 'id' | 'localizations'
>;

type IAssessmentExerciseProblemEditResponse = AxiosResponse<void>;

export const assessmentExerciseProblemEditValidation: ObjectSchema<IAssessmentExerciseProblemEditRequest> =
  object({
    id: number().required(),
    localizations: object().required(),
    status: string<ExerciseStatusEnumKeys>().required('Статус обязателен для заполнения'),
  });

export const exerciseProblemEditRequest = async (
  payload: IAssessmentExerciseProblemEditRequest,
): Promise<IAssessmentExerciseProblemEditResponse> =>
  await axios.patch(`/admin/assessment-exercises/problem/${payload.id}`, payload);

export function useMutateAssessmentExerciseProblemEdit() {
  const queryClient = useQueryClient();

  return useMutation<
    IAssessmentExerciseProblemEditResponse,
    AxiosError,
    IAssessmentExerciseProblemEditRequest
  >(exerciseProblemEditRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(assessmentExerciseQueryKeys.assessmentExercises);
    },
  });
}
