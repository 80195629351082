import { FC, useMemo, useState } from 'react';
import { Button, Modal } from 'antd';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';

import { IAvatarsRequest, useQueryAvatars } from '@collections/avatars/api/useQueryAvatars';
import { avatarColumns } from '@collections/avatars/consts/columns/avatarColumns';
import { avatarFilters } from '@collections/avatars/filters/avatarFilters';
import { useQueryAvatarGroup } from '@collections/avatars-groups/api/useQueryAvatarGroup';
import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { TitleAndText } from '@components/atoms/TitleAndText';
import { AdminTable } from '@components/organisms/adminTable/AdminTable';

import { AvatarGroupAddItemModal } from './AvatarGroupAddItemModal';

export const AvatarGroupViewModal: FC<{
  id?: number;
  isOpen: boolean;
  onCancel: () => void;
  onItemDelete: ({ avatarId, id }: { avatarId: number; id: number }) => void;
  onOpenEdit: () => void;
}> = ({ id = 0, isOpen, onCancel, onOpenEdit }) => {
  const [filtered, setFiltered] = useState<IAvatarsRequest>({
    page: 1,
    pageSize: 10,
    sortBy: 'id',
    sortDirection: 'ASC',
  });

  const avatarsQuery = useQueryAvatars({ ...filtered, groupId: id });
  const allAvatarsQuery = useQueryAvatars();
  const filteredBoards = useMemo(
    () =>
      allAvatarsQuery.data?.data.content.filter(
        (item) => !avatarsQuery.data?.data.content.some((refItem) => refItem.id === item.id),
      ),
    [allAvatarsQuery, avatarsQuery],
  );

  const [addAvatarModalOpen, setAddAvatarModalOpen] = useState(false);

  const avatarGroupQuery = useQueryAvatarGroup({ id });
  const avatarGroup = avatarGroupQuery.data?.data;

  return (
    <Modal
      className="px-4 md:px-0 md:w-5/6 lg:w-4/5 pt-0 mt-0 top-8"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <div className="flex flex-row gap-4">
          <h2 className="text-2xl">Группа аватаров - #{id}</h2>

          <Button className="hidden md:block" icon={<EditOutlined />} onClick={onOpenEdit}>
            Редактировать
          </Button>

          <Button className="md:hidden" icon={<EditOutlined />} onClick={onOpenEdit} />
        </div>
      }
    >
      {avatarGroupQuery.isLoading ? (
        <BaseSkeleton />
      ) : !avatarGroup ? (
        <NotFoundResult title="Группа аватаров не найдена" />
      ) : (
        <div className="space-y-8">
          <TitleAndText light text={avatarGroup.id} title="ID группы" />
          <TitleAndText
            light
            text={<CountryImageText src="ru" text={avatarGroup.localizations?.ru?.name || '-'} />}
            title="Название на русском"
          />
          <TitleAndText
            light
            text={<CountryImageText src="en" text={avatarGroup.localizations?.en?.name || '-'} />}
            title="Название на английском"
          />{' '}
          <TitleAndText
            light
            text={moment(avatarGroup.createdDate).format('LLL')}
            title="Дата создания"
          />
          <TitleAndText light text={avatarGroup.itemsCount} title="Количество досок" />
          <AdminTable
            columns={avatarColumns({})}
            emptyDescription={<p>Аватары не найдены</p>}
            position={['bottomLeft']}
            query={avatarsQuery}
            tableFilter={{ filtered, filters: avatarFilters, onFilter: setFiltered }}
          />
          <AvatarGroupAddItemModal
            avatarGroupId={id}
            avatarGroupName={avatarGroup.localizations.ru?.name ?? ''}
            avatars={filteredBoards ?? []}
            isGroupNameDisabled={true}
            isLoading={allAvatarsQuery.isFetching}
            isOpen={addAvatarModalOpen}
            onClose={() => setAddAvatarModalOpen(false)}
          />
        </div>
      )}
    </Modal>
  );
};
