import { FC, useState } from 'react';
import { Button, Switch as AntSwitch } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, InputNumber, Radio, Select } from 'formik-antd';

import { FileUpload } from '@components/molecules/files/FileUpload';
import { assessmentExerciseTestCreateInitial } from '@exercises/levelTesting/api/useMutateAssessmentExerciseTestCreate';
import {
  assessmentExerciseTestEditValidation,
  IAssessmentExerciseTestEditRequest,
  useMutateAssessmentExerciseTestEdit,
} from '@exercises/levelTesting/api/useMutateAssessmentExerciseTestEdit';
import { IAssessmentExercise } from '@exercises/levelTesting/types/IAssessmentExercise';
import { IAssessmentExerciseTest } from '@exercises/levelTesting/types/IAssessmentExerciseTest';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';
import { notify } from '@utils/notify';

import { AssessmentQuestionAnswersForm } from './AssessmentQuestionAnswersForm';

export const AssessmentTestEditFormik: FC<{
  exercise: IAssessmentExercise & { test: IAssessmentExerciseTest };
  onCancel: () => void;
}> = ({ exercise, onCancel }) => {
  const testEdit = useMutateAssessmentExerciseTestEdit();

  const [questionsNum, setQuestionsNum] = useState(
    exercise.test.localizations?.en?.answerOptions?.length || 2,
  );

  return (
    <Formik<IAssessmentExerciseTestEditRequest>
      enableReinitialize
      initialValues={{
        ...assessmentExerciseTestCreateInitial,
        ...exercise,
        ...exercise.test,
        correctOption: exercise.test.correctOption || 1,
      }}
      onSubmit={(values, { resetForm }) => {
        testEdit.mutateAsync(values).then((data) => {
          if (data.status == 201 || data.status == 200) {
            notify('success', 'Тест изменен');
            onCancel();
            resetForm();
          }
        });
      }}
      validationSchema={assessmentExerciseTestEditValidation}
    >
      {({ setFieldValue, values }) => (
        <Form className="space-y-6" layout="vertical">
          <AssessmentQuestionAnswersForm
            localization="en"
            maxQuestionsNum={4}
            nameStart="localizations.en"
            questionsNum={questionsNum}
            setQuestionsNum={setQuestionsNum}
            test={values.localizations.en}
            text="Вопрос на английском языке"
          />
          <AssessmentQuestionAnswersForm
            localization="ru"
            maxQuestionsNum={4}
            nameStart="localizations.ru"
            questionsNum={questionsNum}
            setQuestionsNum={setQuestionsNum}
            test={values.localizations.ru}
            text="Вопрос на русском языке"
          />
          <FormItem label="Правильный вариант" name="correctOption">
            <Radio.Group
              name="correctOption"
              options={Array.from(Array(questionsNum)).map((_, index) => ({
                label: `${index + 1}-й`,
                value: index + 1,
              }))}
            />
          </FormItem>
          <FormItem className="upload-form-item text-center" name="imageUrl">
            <FileUpload
              accept=".jpeg,.png,.jpg"
              defaultFileList={
                values.imageUrl
                  ? [
                      {
                        name: values.imageUrl,
                        uid: values.imageUrl,
                        url: values.imageUrl,
                      },
                    ]
                  : undefined
              }
              folderName="testImages"
              label="Картинка"
              maxCount={1}
              onChange={(info, linkInfo) => {
                if (!info.file.status || info.file.status == 'removed')
                  setFieldValue('imageUrl', '');
                if (info.file.status == 'done') setFieldValue('imageUrl', linkInfo.downloadUrl);
              }}
            />
          </FormItem>
          <FormItem label="Уровень" name="playLevels">
            <Select
              name="playLevel"
              options={Object.keys(userPlayLevels)
                .map(Number)
                .map((playLevel) => ({
                  label: userPlayLevels[playLevel],
                  value: playLevel,
                }))}
              showSearch={false}
            />
          </FormItem>

          <div className="grid md:grid-cols-2 place-items-start text-start">
            <FormItem label="Порядковый номер" name="priority">
              <InputNumber autoFocus className="w-full" controls={false} name="priority" />
            </FormItem>
            <Form.Item
              label={<span className="text-center">Запустить немедленно</span>}
              name="status"
            >
              <Input hidden name="status" />
              <AntSwitch
                checked={values.status == 'ACTIVE'}
                onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
              />
            </Form.Item>
          </div>

          <div className="grid md:grid-cols-2 gap-5">
            <Button block onClick={onCancel}>
              Отменить
            </Button>
            <Button block htmlType="submit" loading={testEdit.isLoading} type="primary">
              <span className="max-w-full overflow-ellipsis overflow-hidden">
                {values['status'] == 'ACTIVE' ? 'Изменить и запустить' : 'Сохранить как черновик'}
              </span>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
