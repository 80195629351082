import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';
import { userQueryKeys } from '@users/api/userQueryKeys';
import { IUserMapStage } from '@users/types/IUserMapStage';

export type IUserMapStagesRequest = IPageAbleRequest & { id: number };

type IUserMapStagesResponse = AxiosResponse<IPageAbleResponse<IUserMapStage>>;

export const userMapStagesRequest = async ({
  id,
  ...params
}: IUserMapStagesRequest): Promise<IUserMapStagesResponse> => {
  return await axios.get(`/customers/${id}/map-stages`, { params });
};

export function useQueryUserMapStages(params: IUserMapStagesRequest) {
  return useQuery<IUserMapStagesResponse, AxiosError>([userQueryKeys.userMapStages, params], () =>
    userMapStagesRequest(params),
  );
}
