import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { notify } from '@utils/notify';

import { mapStageQueryKeys } from './mapStagesQueryKeys';

type IMapStageDeleteRequest = { id: number };

type IMapStageDeleteResponse = AxiosResponse<void>;

export const MapStageDeleteRequest = async ({
  id,
}: IMapStageDeleteRequest): Promise<IMapStageDeleteResponse> =>
  await axios.delete(`/map-stage-definitions/${id}`);

export function useMutateMapStageDelete() {
  const queryClient = useQueryClient();

  return useMutation<IMapStageDeleteResponse, AxiosError, IMapStageDeleteRequest>(
    MapStageDeleteRequest,
    {
      onSuccess: () => {
        notify('error', 'Шаг на карте удален');

        queryClient.invalidateQueries(mapStageQueryKeys.mapStages);
      },
    },
  );
}
