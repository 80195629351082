import { FC } from 'react';
import { Modal, Statistic } from 'antd';

import { useMutateVersionActivate } from '@settings/versions/api/useMutateVersionActivate';

type VersionActivateModalType = {
  isOpen: boolean;
  onCancel: () => void;
  versionId: number;
};

const { Countdown } = Statistic;

export const VersionActivateModal: FC<VersionActivateModalType> = ({
  isOpen,
  onCancel,
  versionId,
}) => {
  const targetTime = Date.now() + 10 * 1000;

  const versionActivate = useMutateVersionActivate();

  const handleActivate = () => {
    versionActivate.mutateAsync({ id: versionId });
    onCancel();
  };

  return (
    <Modal
      cancelText="Отменить"
      className="px-4 md:px-0 md:w-2/3 lg:w-1/2 pt-0 mt-0"
      destroyOnClose
      okButtonProps={{ style: { display: 'none' } }}
      okText={null}
      onCancel={onCancel}
      open={isOpen}
    >
      <Countdown
        format={'s секунд'}
        onFinish={handleActivate}
        title="Вы можете отменить активацию данной версии в течении "
        value={targetTime}
      />
    </Modal>
  );
};
