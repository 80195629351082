import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { ILecturerCard } from '../types/ILecturerCard';
import { lecturerCardCommonKeys } from './lecturerCardKeys';

export type LecturerCardStatusUpdateRequest = Pick<ILecturerCard, 'id'>;

type LecturerCardStatusUpdateResponse = AxiosResponse<void>;

export const lecturerCardStatusUpdateRequest = async ({
  id,
  ...payload
}: LecturerCardStatusUpdateRequest): Promise<LecturerCardStatusUpdateResponse> => {
  return await axios.patch(`/admin/author-cards/${id}`, payload);
};

export function useMutateLecturerCardStatusUpdate() {
  const queryClient = useQueryClient();

  return useMutation<LecturerCardStatusUpdateResponse, AxiosError, LecturerCardStatusUpdateRequest>(
    lecturerCardStatusUpdateRequest,
    {
      onSuccess: () => queryClient.invalidateQueries(lecturerCardCommonKeys.lecturerCards),
    },
  );
}
