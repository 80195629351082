import { FC } from 'react';
import { Button, Tooltip } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Radio } from 'formik-antd';
import { FileOutlined } from '@ant-design/icons';

import { QuestionAnswersForm } from '@exercises/exercises/components/molecules/QuestionAnswersForm';
import { notify } from '@utils/notify';
import {
  IVideoStepThreeRequest,
  useMutateVideoStepThree,
  videoStepThreeInitial,
  videoStepThreeValidation,
} from '@videos/api/useMutateVideoStepThree';
import { IVideo } from '@videos/types/IVideo';

type StepThreeType = {
  changeStep: (value) => void;
  onCancel: () => void;
  video: IVideo;
};

export const StepThree: FC<StepThreeType> = ({ changeStep, onCancel, video }) => {
  const videoThreeUpdate = useMutateVideoStepThree();

  const submitDraft = (values: IVideoStepThreeRequest) => {
    videoThreeUpdate.mutateAsync({ ...values, status: 'DRAFT' }).then((data) => {
      if (data.status == 201 || data.status == 200) {
        notify('success', 'Видео сохранено как черновик');
        onCancel();
      }
    });
  };

  return (
    <Formik<IVideoStepThreeRequest>
      enableReinitialize
      initialValues={{
        id: video.id,
        status: video.status,
        test: video.test || videoStepThreeInitial.test,
      }}
      onReset={() => {
        changeStep(1);
      }}
      onSubmit={(values) => {
        videoThreeUpdate.mutateAsync({ ...values, status: 'ACTIVE' }).then((data) => {
          if (data.status == 201 || data.status == 200) {
            notify('success', 'Шаг 3 сохранен');
            onCancel();
          }
        });
      }}
      validationSchema={videoStepThreeValidation}
    >
      {({ values }) => (
        <Form className="space-y-4 md:space-y-8" layout="vertical">
          <h1 className="text-2xl">Тест</h1>
          <QuestionAnswersForm
            localization="en"
            nameStart="test.localizations.en"
            test={values.test?.localizations}
            text="Вопрос на английском языке"
          />
          <QuestionAnswersForm
            localization="ru"
            nameStart="test.localizations.ru"
            test={values.test?.localizations}
            text="Вопрос на русском языке"
          />
          <FormItem label="Правильный вариант" name="test.correctOption">
            <Radio.Group
              name="test.correctOption"
              options={[1, 2, 3, 4].map((correct) => ({
                label: `${correct}-й`,
                value: correct,
              }))}
            />
          </FormItem>

          <div className="grid sm:grid-cols-2 gap-5">
            <Button block htmlType="reset">
              Назад
            </Button>
            <div className="flex flex-row gap-3">
              <Button block htmlType="submit" loading={videoThreeUpdate.isLoading} type="primary">
                <span className="max-w-full overflow-ellipsis overflow-hidden">Запустить</span>
              </Button>
              <Tooltip title="Сохранить как черновик">
                <Button
                  className="px-2 border-dashed border-ant-gray-6 hover:border-ant-blue-5 shadow-none"
                  ghost
                  icon={<FileOutlined />}
                  onClick={() => submitDraft(values)}
                  type="primary"
                />
              </Tooltip>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
