import { notification } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification/interface';

export function notify(
  type: 'success' | 'info' | 'error' | 'warning',
  message: string,
  placement: NotificationPlacement = 'topRight',
  duration = 2.5,
) {
  notification[type]({
    className: 'font-semibold',
    duration: duration,
    message: message,
    placement: placement,
  });
}
