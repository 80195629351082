import { FC, useEffect, useMemo, useState } from 'react';
import { Button, Table } from 'antd';

import { useMutateAvatarGroupDelete } from '@collections/avatars-groups/api/useMutateAvatarGroupDelete';
import { useMutateAvatarGroupEdit } from '@collections/avatars-groups/api/useMutateAvatarGroupEdit';
import { useMutateAvatarGroupItemDelete } from '@collections/avatars-groups/api/useMutateAvatarGroupItemDelete';
import { useMutateAvatarGroupStatusUpdate } from '@collections/avatars-groups/api/useMutateAvatarGroupStatusUpdate';
import {
  IAvatarGroupsRequest,
  useQueryAvatarGroups,
} from '@collections/avatars-groups/api/useQueryAvatarGroups';
import { avatarGroupColumns } from '@collections/avatars-groups/consts/columns/avatarGroupColumns';
import { avatarGroupsFilters } from '@collections/avatars-groups/consts/filters/avatarGroupsFilters';
import {
  avatarGroupsPageActions,
  IAvatarGroupsPageActions,
} from '@collections/avatars-groups/consts/pageActions/IAvatarGroupsPageActions';
import { CollectionTabs } from '@collections/components/molecules/CollectionTabs';
import { DraggableTableRow } from '@components/organisms/adminTable/organismComponents/DraggableTableRow';
import { PageMeta } from '@context/PageMetaContext';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';

import { AvatarGroupCreateModal } from '../organisms/AvatarGroupCreateModal';
import { AvatarGroupEditModal } from '../organisms/AvatarGroupEditModal';
import { AvatarGroupViewModal } from '../organisms/AvatarGroupViewModal';

export const AvatarsGroupsPage: FC = () => {
  const { actioned, clearParams, filtered, setActioned } = useUrlFilteredActioned<
    IAvatarGroupsRequest,
    IAvatarGroupsPageActions
  >(avatarGroupsFilters, avatarGroupsPageActions);

  const avatarGroupsQuery = useQueryAvatarGroups(filtered);
  const avatarGroups = avatarGroupsQuery.data?.data.content ?? [];
  const deleteAvatarGroupItemMutation = useMutateAvatarGroupItemDelete();
  const updateAvatarGroup = useMutateAvatarGroupEdit();
  const [dataSource, setDataSource] = useState<any>([]);

  const statusUpdate = useMutateAvatarGroupStatusUpdate();
  const groupDelete = useMutateAvatarGroupDelete();

  const title = useMemo(() => {
    if (actioned.action === 'createAvatarGroup') {
      return 'Создать группу аватаров';
    } else if (actioned.action === 'view') {
      return `Группа ${actioned.actionId}`;
    }

    return 'Группа';
  }, [actioned.action]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const changedItem = previous.find((i) => i.priority === active?.id);
        const overIndex = previous.findIndex((i) => i.priority === over?.id);
        const activeIndex = previous.findIndex((i) => i.priority === active.id);
        changedItem && updateAvatarGroup.mutateAsync({ ...changedItem, priority: overIndex + 1 });
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    if (avatarGroups) {
      setDataSource(
        avatarGroups
          .map((item, index) => ({
            ...item,
            key: item?.priority ?? index,
            priority: item.priority ?? index,
          }))
          .sort((a, b) => a.key - b.key),
      );
    }
  }, [avatarGroups]);

  return (
    <>
      <PageMeta selectedMenuKeys={['collections']} title={title} />
      <CollectionTabs active="avatars" />
      <div className="flex justify-end gap-4 mb-4">
        <Button
          className="sm:px-12"
          onClick={() => setActioned({ action: 'createAvatarGroup' })}
          size="large"
          type="primary"
        >
          Создать группу
        </Button>
      </div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource?.map((i) => i.priority)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            columns={avatarGroupColumns({
              onClickDelete: groupDelete.mutateAsync,
              onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
              onClickPause: statusUpdate.mutateAsync,
              onClickView: ({ id }) => setActioned({ action: 'view', actionId: id }),
            })}
            components={{
              body: {
                row: DraggableTableRow,
              },
            }}
            dataSource={dataSource}
            loading={avatarGroupsQuery.isLoading}
            pagination={false}
            rowKey="key"
            scroll={{ x: '100%' }}
          />
        </SortableContext>
      </DndContext>

      <AvatarGroupCreateModal
        isOpen={actioned.action == 'createAvatarGroup'}
        onClose={clearParams}
      />

      <AvatarGroupViewModal
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onCancel={clearParams}
        onItemDelete={deleteAvatarGroupItemMutation.mutate}
        onOpenEdit={() => setActioned({ action: 'edit', actionId: actioned.actionId })}
      />

      <AvatarGroupEditModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
        onItemDelete={deleteAvatarGroupItemMutation.mutate}
      />
    </>
  );
};
