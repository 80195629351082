import { FC, useMemo } from 'react';
import { Button, Table } from 'antd';

import { useMutateBoardGroupDelete } from '@collections/board-groups/api/useMutateBoardGroupDelete';
import { useMutateBoardGroupEdit } from '@collections/board-groups/api/useMutateBoardGroupEdit';
import { useMutateBoardGroupItemDelete } from '@collections/board-groups/api/useMutateBoardGroupItemDelete';
import { useMutateBoardGroupStatusUpdate } from '@collections/board-groups/api/useMutateBoardGroupStatusUpdate';
import {
  IBoardGroupsRequest,
  useQueryBoardGroups,
} from '@collections/board-groups/api/useQueryBoardGroups';
import { boardGroupColumns } from '@collections/board-groups/consts/columns/boardGroupColumns';
import { boardGroupsFilters } from '@collections/board-groups/consts/filters/boardGroupsFilters';
import {
  boardGroupsPageActions,
  IBoardGroupsPageActions,
} from '@collections/board-groups/consts/pageActions/IBoardGroupsPageActions';
import { CollectionTabs } from '@collections/components/molecules/CollectionTabs';
import { DraggableTableRow } from '@components/organisms/adminTable/organismComponents/DraggableTableRow';
import { PageMeta } from '@context/PageMetaContext';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';

import { BoardGroupCreateModal } from '../organisms/BoardGroupCreateModal';
import { BoardGroupEditModal } from '../organisms/BoardGroupEditModal';
import { BoardGroupViewModal } from '../organisms/BoardGroupViewModal';

export const BoardGroupsPage: FC = () => {
  const { actioned, clearParams, filtered, setActioned } = useUrlFilteredActioned<
    IBoardGroupsRequest,
    IBoardGroupsPageActions
  >(boardGroupsFilters, boardGroupsPageActions);

  const boardGroupsQuery = useQueryBoardGroups(filtered);
  const boardGroups = boardGroupsQuery.data?.data.content ?? [];
  const deleteBoardGroupItemMutation = useMutateBoardGroupItemDelete();
  const updateBoardGroup = useMutateBoardGroupEdit();

  const statusUpdate = useMutateBoardGroupStatusUpdate();
  const groupDelete = useMutateBoardGroupDelete();

  const title = useMemo(() => {
    if (actioned.action === 'createBoardGroup') {
      return 'Создать группу досок';
    } else if (actioned.action === 'view') {
      return `Группа ${actioned.actionId}`;
    }

    return 'Группа';
  }, [actioned.action]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active && over && active.id !== over?.id) {
      const previousBoardGroups = [...boardGroups];

      // previousBoardGroups.forEach(async (board, i) => {
      //   console.log(board.priority, i + 1, board.id);
      //   if (board.priority != i + 1) {
      //     await updateBoardGroup.mutateAsync({
      //       ...board,
      //       items: undefined,
      //       priority: i + 1,
      //     });
      //   }
      // });

      const activeIndex = previousBoardGroups.findIndex(({ id }) => id == active.id);
      const overIndex = previousBoardGroups.findIndex(({ id }) => id == over.id);

      updateBoardGroup.mutate({
        ...previousBoardGroups[activeIndex],
        items: undefined,
        priority: overIndex + 1,
      });
    }
  };

  return (
    <>
      <PageMeta selectedMenuKeys={['collections']} title={title} />
      <CollectionTabs active="board" />
      <div className="flex justify-end gap-4 mb-4">
        <Button
          className="sm:px-12"
          onClick={() => setActioned({ action: 'createBoardGroup' })}
          size="large"
          type="primary"
        >
          Создать группу
        </Button>
      </div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={boardGroups?.map(({ id }) => id)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            columns={boardGroupColumns({
              onClickDelete: groupDelete.mutateAsync,
              onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
              onClickPause: statusUpdate.mutateAsync,
              onClickView: ({ id }) => setActioned({ action: 'view', actionId: id }),
            })}
            components={{
              body: {
                row: DraggableTableRow,
              },
            }}
            dataSource={boardGroups}
            loading={boardGroupsQuery.isFetching || updateBoardGroup.isLoading}
            pagination={false}
            rowKey="id"
            scroll={{ x: '100%' }}
          />
        </SortableContext>
      </DndContext>
      <BoardGroupCreateModal isOpen={actioned.action == 'createBoardGroup'} onClose={clearParams} />
      <BoardGroupViewModal
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onCancel={clearParams}
        onItemDelete={deleteBoardGroupItemMutation.mutateAsync}
        onOpenEdit={() => setActioned({ action: 'edit', actionId: actioned.actionId })}
      />
      <BoardGroupEditModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
        onItemDelete={deleteBoardGroupItemMutation.mutateAsync}
      />
    </>
  );
};
