import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema, string } from 'yup';

import { IBoard } from '@collections/boards/types/IBoard';
import { notify } from '@utils/notify';

import { BoardStatusEnumKeys } from '../types/enums/boardStatusEnum';
import { BoardTypeEnumKeys } from '../types/enums/boardTypeEnum';
import { boardCommonKeys } from './boardCommonKeys';

export type IBoardCreateRequest = Pick<
  IBoard,
  'darkPieceImgUrl' | 'lightPieceImgUrl' | 'status' | 'type' | 'localizations' | 'price' | 'imgUrl'
>;

export const createBoardInitialValue: IBoardCreateRequest = {
  darkPieceImgUrl: '',
  imgUrl: '',
  lightPieceImgUrl: '',
  localizations: { en: { name: '' }, ru: { name: '' } },
  price: null,
  status: 'DRAFT',
  type: 'FREE',
};

export const createBoardValidation: ObjectSchema<IBoardCreateRequest> = object({
  darkPieceImgUrl: string().required(),
  imgUrl: string().required(),
  lightPieceImgUrl: string().required(),
  localizations: object({
    en: object({ name: string().required() }),
    ru: object({ name: string().required() }),
  }),
  price: number().nullable(),
  status: string<BoardStatusEnumKeys>().nullable().required(),
  type: string<BoardTypeEnumKeys>().nullable().required(),
});

export const createBoardRequest = (payload: IBoardCreateRequest) =>
  axios.post(`/chess-boards`, payload);

export const useMutateBoardCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, IBoardCreateRequest>(createBoardRequest, {
    onError: (error) => {
      notify('error', 'Повторите попытку позже');
      notify('error', error.message);
    },
    onSuccess: () => {
      notify('success', 'Доска успешно создана');

      queryClient.invalidateQueries(boardCommonKeys.boards);
    },
  });
};
