import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { chessPiecesQueryKeys } from '@collections/chess-pieces/api/chessPiecesQueryKeys';
import { notify } from '@utils/notify';

import { chessPiecesGroupQueryKeys } from './chessPiecesGroupQueryKeys';

type IChessPiecesGroupItemDeleteRequest = { chessPiecesSetId: number; id: number };

type IChessPiecesGroupItemDeleteResponse = AxiosResponse<void>;

export const chessPiecesGroupItemDeleteRequest = async ({
  chessPiecesSetId,
  id,
}: IChessPiecesGroupItemDeleteRequest): Promise<IChessPiecesGroupItemDeleteResponse> =>
  await axios.delete(`/chess-piece-set-groups/${id}/item/${chessPiecesSetId}`);

export function useMutateChessPiecesGroupItemDelete() {
  const queryClient = useQueryClient();

  return useMutation<
    IChessPiecesGroupItemDeleteResponse,
    AxiosError,
    IChessPiecesGroupItemDeleteRequest
  >(chessPiecesGroupItemDeleteRequest, {
    onSuccess: () => {
      notify('error', 'Фигуры удалены из группы');
      queryClient.invalidateQueries(chessPiecesGroupQueryKeys.chessPiecesGroups);
      queryClient.invalidateQueries(chessPiecesGroupQueryKeys.chessPiecesGroup);
      queryClient.invalidateQueries(chessPiecesQueryKeys.chessPieces);
      queryClient.invalidateQueries(chessPiecesQueryKeys.chessPiecesById);
    },
  });
}
