import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { exerciseQueryKeys } from '@exercises/exercises/api/exerciseQueryKeys';
import { notify } from '@utils/notify';

type IExerciseDeleteRequest = { id: number };

type IExerciseDeleteResponse = AxiosResponse<void>;

export const ExerciseDeleteRequest = async ({
  id,
}: IExerciseDeleteRequest): Promise<IExerciseDeleteResponse> =>
  await axios.delete(`/exercises/${id}`);

export function useMutateExerciseDelete() {
  const queryClient = useQueryClient();

  return useMutation<IExerciseDeleteResponse, AxiosError, IExerciseDeleteRequest>(
    ExerciseDeleteRequest,
    {
      onSuccess: (data, variables) => {
        notify('error', 'Задача удалена');

        queryClient.invalidateQueries(exerciseQueryKeys.exercises);
        queryClient.invalidateQueries([exerciseQueryKeys.exercise, { id: variables.id }]);
      },
    },
  );
}
