import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { object, ObjectSchema, ref, string } from 'yup';

export type IResetPasswordRequest = {
  link: string;
  password: string;
};
type IResetPasswordResponse = void;

export type IResetConfirmPasswordType = IResetPasswordRequest & { confirmPassword: string };

export const resetPasswordInitial: IResetConfirmPasswordType = {
  confirmPassword: '',
  link: '',
  password: '',
};

export const resetPasswordValidation: ObjectSchema<IResetConfirmPasswordType> = object({
  confirmPassword: string()
    .oneOf([ref('password')], 'Пароли должны совпадать')
    .required(' '),
  link: string().required(' '),
  password: string()
    .required('Пароль обязателен для заполнения')
    .max(30, 'Пароль должен быть не более 30 символов')
    .min(6, 'Пароль должен быть не менее 6 символов'),
});

export const resetPasswordRequest = async (
  payload: IResetPasswordRequest,
): Promise<AxiosResponse<IResetPasswordResponse>> => {
  return await axios.post<IResetPasswordResponse>(`/auth/link/finish`, payload);
};

export function useMutateResetPassword() {
  const navigate = useNavigate();

  return useMutation<AxiosResponse<IResetPasswordResponse>, AxiosError, IResetPasswordRequest>(
    'resetPassword',
    resetPasswordRequest,
    {
      onError: (error) => {
        if (error.response?.status == 422) {
          message.warning('Срок действия истек или неверный формат, попробуйте снова позже', 5);
          navigate('/reset/email');
        }
      },
      onSuccess: () => {
        message.success('Пароль успешно сброшен');
        navigate('/login');
      },
    },
  );
}
