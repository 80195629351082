import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { CountryImageText } from '@components/atoms/CountryImageText';
import { UserPurchaseTypeEnum } from '@users/types/enums/UserPurchaseTypeEnum';
import { UserSubscriptionEnum } from '@users/types/enums/UserSubscriptionEnum';
import { IUser, userKeys } from '@users/types/IUser';

import { userPlayLevels } from '../../types/enums/UserPlayLevel';

export const usersColumns: ColumnsType<IUser> = [
  {
    dataIndex: userKeys.id,
    render: (id: IUser['id']) => <Link to={`/users/${id}`}>{id}</Link>,
    title: 'ID',
  },
  {
    dataIndex: userKeys.nickname,
    title: 'Никнейм',
  },
  {
    dataIndex: userKeys.email,
    title: 'Почта',
  },
  {
    dataIndex: userKeys.subscriptionStatus,
    render: (subscription: IUser['subscriptionStatus']) => UserSubscriptionEnum[subscription],
    title: 'Подписка',
  },
  {
    dataIndex: userKeys.subscription,
    render: (subscription: IUser['subscription']) =>
      subscription && subscription.type ? UserPurchaseTypeEnum[subscription.type] : '-',
    title: 'Тип покупки',
  },
  {
    dataIndex: userKeys.country,
    render: (country?: IUser['country']) =>
      country ? (
        <Tooltip title={country.name.ru}>
          <CountryImageText src={country.iso} text={country.iso} />
          {''}
        </Tooltip>
      ) : (
        <span />
      ),
    title: 'Страна',
  },
  {
    dataIndex: userKeys.ageCategory,
    title: 'Категория возраста',
  },
  {
    dataIndex: userKeys.age,
    title: 'Возраст',
  },
  {
    dataIndex: userKeys.playLevel,
    render: (value: IUser['playLevel']) => (value ? userPlayLevels[value] : '-'),
    title: 'Уровень',
  },
  {
    dataIndex: userKeys.systemRating,
    title: 'Сис.Рейтинг',
  },
  {
    dataIndex: userKeys.rating,
    title: 'Игровой рейтинг',
  },
  {
    dataIndex: userKeys.language,
    title: 'Язык',
  },
  {
    dataIndex: userKeys.devicePlatform,
    title: 'Тип устройства',
  },
  {
    dataIndex: userKeys.appVersion,
    title: 'Версия приложения',
  },
  {
    dataIndex: userKeys.coins,
    title: 'Кол-во коинов',
  },
  {
    dataIndex: userKeys.isParent,
    render: (value: IUser['isParent']) => (value === null ? '-' : value ? 'Родитель' : 'Я'),
    title: 'Зарегестрировался',
  },
];
