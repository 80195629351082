import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { appProductKeys } from '@shop/appProducts/types/IAppProduct';
import { IFilters } from '@typess/IFilters';

export const productsFilters: IFilters = {
  ...paginationBaseFilters,
  [appProductKeys.coins]: { sort: true },
  [appProductKeys.price]: { sort: true },
  [appProductKeys.status]: { sort: true },
};
