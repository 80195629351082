import { FC, useState } from 'react';
import { Button, Modal, Switch as AntSwitch } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, InputNumber, Radio, Select } from 'formik-antd';
import { QuestionOutlined } from '@ant-design/icons';

import { FileUpload } from '@components/molecules/files/FileUpload';
import {
  assessmentExerciseTestCreateInitial,
  assessmentExerciseTestCreateValidation,
  IAssessmentExerciseTestCreateRequest,
  useMutateAssessmentExerciseTestCreate,
} from '@exercises/levelTesting/api/useMutateAssessmentExerciseTestCreate';
import { useQueryAssessmentExerciseLastPriority } from '@exercises/levelTesting/api/useQueryLastPriority';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';
import { notify } from '@utils/notify';

import { AssessmentQuestionAnswersForm } from '../molecules/AssessmentQuestionAnswersForm';

export const AssessmentExerciseTestCreateModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
}> = ({ isOpen, onCancel }) => {
  const testCreate = useMutateAssessmentExerciseTestCreate();
  const [selectedPlayLevel, setSelectedPlayLevel] = useState();
  const { data } = useQueryAssessmentExerciseLastPriority({
    playLevel: selectedPlayLevel,
  });
  const [questionsNum, setQuestionsNum] = useState(2);
  const [imageKey, setImageKey] = useState('image');

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3"
      footer={null}
      onCancel={() => {
        onCancel();
      }}
      open={isOpen}
      title={
        <h1 className="text-xl m-0 space-x-1">
          <QuestionOutlined className="text-ant-orange-6" />
          <span>Создать тест</span>
        </h1>
      }
    >
      <Formik<IAssessmentExerciseTestCreateRequest>
        enableReinitialize
        initialValues={{
          ...assessmentExerciseTestCreateInitial,
        }}
        onSubmit={(values, { resetForm }) => {
          testCreate.mutateAsync(values).then((data) => {
            if (data.status == 201 || data.status == 200) {
              notify('success', 'Тест создан');
              onCancel();
              resetForm();
              setImageKey(imageKey + 1);
            }
          });
        }}
        validationSchema={assessmentExerciseTestCreateValidation}
      >
        {({ setFieldValue, values }) => {
          return (
            <Form className="space-y-6" layout="vertical">
              <AssessmentQuestionAnswersForm
                localization="en"
                maxQuestionsNum={4}
                nameStart="localizations.en"
                questionsNum={questionsNum}
                setQuestionsNum={setQuestionsNum}
                test={values.localizations.en}
                text="Вопрос на английском языке"
              />

              <AssessmentQuestionAnswersForm
                localization="ru"
                maxQuestionsNum={4}
                nameStart="localizations.ru"
                questionsNum={questionsNum}
                setQuestionsNum={setQuestionsNum}
                test={values.localizations.ru}
                text="Вопрос на русском языке"
              />

              <FormItem label="Правильный вариант" name="correctOption">
                <Radio.Group
                  name="correctOption"
                  options={Array.from(Array(questionsNum)).map((_, index) => ({
                    label: `${index + 1}-й`,
                    value: index + 1,
                  }))}
                />
              </FormItem>

              <FormItem className="upload-form-item text-center" name="imageUrl">
                <FileUpload
                  accept=".jpeg,.png,.jpg"
                  defaultFileList={
                    values.imageUrl
                      ? [
                          {
                            name: '',
                            uid: values.imageUrl,
                            url: values.imageUrl,
                          },
                        ]
                      : undefined
                  }
                  folderName="testImages"
                  key={imageKey}
                  label="Картинка"
                  maxCount={1}
                  onChange={(info, linkInfo) => {
                    if (!info.file.status || info.file.status == 'removed')
                      setFieldValue('imageUrl', '');
                    if (info.file.status == 'done') setFieldValue('imageUrl', linkInfo.downloadUrl);
                  }}
                />
              </FormItem>

              <FormItem label="Уровень" name="playLevel">
                <Select
                  name="playLevel"
                  onChange={(v) => {
                    setSelectedPlayLevel(v);
                    setFieldValue('priority', data?.data.nextPriority);
                  }}
                  optionFilterProp="label"
                  options={Object.keys(userPlayLevels)
                    .map(Number)
                    .map((playLevel) => ({
                      label: userPlayLevels[playLevel],
                      value: playLevel,
                    }))}
                  showSearch={false}
                />
              </FormItem>

              <div className="grid grid-cols-2 place-items-start text-start">
                <FormItem label="Порядковый номер" name="priority">
                  <InputNumber autoFocus className="w-full" controls={false} name="priority" />
                </FormItem>
                <Form.Item
                  label={<span className="text-center">Запустить немедленно</span>}
                  name="status"
                >
                  <Input hidden name="status" suffix />

                  <AntSwitch
                    checked={values.status == 'ACTIVE'}
                    onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
                  />
                </Form.Item>
              </div>

              <div className="grid md:grid-cols-2 gap-5">
                <Button block onClick={onCancel}>
                  Отменить
                </Button>

                <Button block htmlType="submit" loading={testCreate.isLoading} type="primary">
                  <span className="max-w-full overflow-ellipsis overflow-hidden">
                    {values['status'] == 'ACTIVE'
                      ? 'Создать и запустить'
                      : 'Сохранить как черновик'}
                  </span>
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
