import { CheckboxFilter } from '@components/molecules/filters/static/CheckboxFilter';
import { InputFilter } from '@components/molecules/filters/static/InputFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { promoCodeTypes } from '@settings/promocodes/types/enums/PromocodeTypeEnum';
import { promocodeGroupKeys } from '@settings/promocodes/types/IPromocodeGroup';
import { IFilters } from '@typess/IFilters';

export const promocodeGroupsFilters: IFilters = {
  ...paginationBaseFilters,
  [promocodeGroupKeys.groupName]: {
    input: <InputFilter placeholder="Введите название" />,
  },
  [promocodeGroupKeys.id]: { sort: true },
  [promocodeGroupKeys.type]: {
    input: (
      <CheckboxFilter
        options={Object.entries(promoCodeTypes).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
  },
  [promocodeGroupKeys.expireDate]: { sort: true },
};
