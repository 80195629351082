import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema, string } from 'yup';

import { notify } from '@utils/notify';

import { AvatarStatusEnumKeys } from '../types/enums/avatarStatusEnum';
import { AvatarTypeEnumKeys } from '../types/enums/avatarTypeEnum';
import { IAvatar } from '../types/IAvatar';
import { avatarCommonKeys } from './avatarCommonKeys';

export type IAvatarCreateRequest = Pick<
  IAvatar,
  'imgUrl' | 'status' | 'price' | 'type' | 'localizations'
>;

export const createAvatarInitialValue: IAvatarCreateRequest = {
  imgUrl: '',
  localizations: { en: { name: '' }, ru: { name: '' } },
  price: 0,
  status: 'DRAFT',
  type: 'FREE',
};

export const createAvatarValidation: ObjectSchema<IAvatarCreateRequest> = object({
  imgUrl: string().required(),
  localizations: object({
    en: object({ name: string().required() }),
    ru: object({ name: string().required() }),
  }),
  price: number().when('type', {
    is: (type: AvatarTypeEnumKeys) => type == 'PAID',
    otherwise: (s) => s.notRequired(),
    then: (s) => s.required(),
  }),

  status: string<AvatarStatusEnumKeys>(),
  type: string<AvatarTypeEnumKeys>().required(),
});

export const createAvatarRequest = (payload: IAvatarCreateRequest) =>
  axios.post(`/avatars`, payload);

export const useMutateAvatarCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, IAvatarCreateRequest>(createAvatarRequest, {
    onError: () => {
      notify('error', 'Повторите попытку позже');
    },
    onSuccess: () => {
      notify('success', 'Аватар успешно создан');

      queryClient.invalidateQueries(avatarCommonKeys.avatars);
    },
  });
};
