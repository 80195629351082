export enum UserMapStageTypeEnum {
  CONTENT = 'контент',
  EXERCISE = 'задачи',
  GAME_MATCH = 'игра',
  GIFT = 'подарок',
  GIFT_VIDEO = 'подарок',
  VIDEO = 'видео',
}

export type UserMapStageTypeEnumKeys = keyof typeof UserMapStageTypeEnum;
