import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPaymentStatByDay } from '@dashboards/types/IPaymentStatByDay';

import { dashboardQueryKeys } from './dashboardQueryKeys';

export type IPaymentStatsByDayRequest = {
  from?: string;
  period?: string;
  to?: string;
};

type IPaymentStatsByDayResponse = AxiosResponse<IPaymentStatByDay[]>;

export const paymentStatsByDayRequest = async (
  params?: IPaymentStatsByDayRequest,
): Promise<IPaymentStatsByDayResponse> => {
  return await axios.get(`/stats/${params?.period}`, { params });
};

export function useQueryPaymentStatsByDay(params?: IPaymentStatsByDayRequest) {
  return useQuery<IPaymentStatsByDayResponse, AxiosError>(
    [dashboardQueryKeys.paymentStatsByDay, params],
    () => paymentStatsByDayRequest(params),
  );
}
