import { ReactSVG } from 'react-svg';
import { Button, Image, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {
  CalendarOutlined,
  CaretRightOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import {
  AvatarStatusEnum,
  AvatarStatusEnumKeys,
} from '@collections/avatars/types/enums/avatarStatusEnum';
import { AvatarTypeEnum } from '@collections/avatars/types/enums/avatarTypeEnum';
import { avatarKeys, IAvatar } from '@collections/avatars/types/IAvatar';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { PencilIcon } from '@components/atoms/icons';

type AvatarColumnsProps = {
  isSortable?: boolean;
  onClickDelete?: ({ id }: { id: number }) => void;
  onClickPause?: ({ id, status }: { id: number; status: AvatarStatusEnumKeys }) => void;
  onEditAvatar?: (avatar: IAvatar) => void;
  onOpenAvatar?: (avatar: IAvatar) => void;
};

export const avatarColumns = ({
  isSortable,
  onClickDelete,
  onClickPause,
  onEditAvatar,
  onOpenAvatar,
}: AvatarColumnsProps): ColumnsType<IAvatar> => {
  return [
    isSortable
      ? {
          align: 'center' as const,
          key: 'sort',
          width: 50,
        }
      : {},
    {
      dataIndex: avatarKeys.id,
      render: (id: IAvatar['id'], avatar) => {
        return (
          <Button onClick={() => onOpenAvatar && onOpenAvatar(avatar)} type="link">
            {id}
          </Button>
        );
      },
      title: 'ID',
    },
    {
      dataIndex: avatarKeys.localizations,
      render: (localizations: IAvatar['localizations']) => {
        return (
          <div>
            <CountryImageText src="en" text={localizations?.en?.name || '-'} />
            <CountryImageText src="ru" text={localizations?.ru?.name || '-'} />
          </div>
        );
      },
      title: 'Название аватара',
    },
    {
      dataIndex: avatarKeys.imgUrl,
      render: (imgUrl: IAvatar['imgUrl'], record) => (
        <div className="flex flex-row">
          <div className="mr-3 border border-grey p-2 w-[100px] box-content">
            <ReactSVG
              className="wrapper-class-name"
              desc="Description"
              renumerateIRIElements={false}
              src={imgUrl}
              width="100%"
            />

            <p className="font-bold text-center mt-1">SVG</p>
          </div>
          <div className="border border-grey p-2">
            <Image src={record.imgUrlPng} width={100} />
            <p className="font-bold text-center mt-1">PNG</p>
          </div>
        </div>
      ),

      title: 'Аватар',
    },
    {
      dataIndex: avatarKeys.price,
      render: (price: IAvatar['price']) => {
        return price ?? '-';
      },
      title: 'Цена',
    },
    {
      dataIndex: avatarKeys.type,
      render: (type: IAvatar['type']) => {
        return type ? AvatarTypeEnum[type] : '-';
      },
      title: 'Тип',
    },
    {
      dataIndex: avatarKeys.createdDate,
      render: (createdDate: IAvatar['createdDate']) =>
        createdDate ? (
          <div>
            <p className="mb-0">
              <CalendarOutlined /> {moment(createdDate).format('LL')}
            </p>
          </div>
        ) : (
          <span>-</span>
        ),
      title: 'Дата создания',
    },
    {
      dataIndex: avatarKeys.status,
      render: (status: IAvatar['status']) => {
        return status ? AvatarStatusEnum[status] : '-';
      },
      title: 'Статус',
    },
    {
      key: 'action',
      render: ({ id, status }: IAvatar, avatar) => {
        return (
          <div className="flex items-center space-x-2">
            {onClickPause && (
              <Popconfirm
                cancelText="Отменить"
                icon={
                  status === 'ACTIVE' ? (
                    <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                  ) : (
                    <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                  )
                }
                okText={status === 'ACTIVE' ? 'Приостановить' : 'Запустить'}
                okType="primary"
                onConfirm={() =>
                  onClickPause({
                    id: id,
                    status: status === 'ACTIVE' ? 'DRAFT' : 'ACTIVE',
                  })
                }
                placement="topLeft"
                title={
                  <p className="text-sm mb-0">
                    Вы уверены, что хотите
                    <span className="font-semibold">
                      {status === 'ACTIVE' ? ' приостановить ' : ' запустить '}
                      {id}?
                    </span>
                  </p>
                }
              >
                <Button
                  ghost
                  icon={status === 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
                  type="primary"
                />
              </Popconfirm>
            )}
            {onEditAvatar && (
              <Button
                ghost
                icon={<PencilIcon />}
                onClick={() => onEditAvatar(avatar)}
                type="primary"
              />
            )}
            {onClickDelete && (
              <Popconfirm
                cancelText="Отменить"
                icon={<DeleteOutlined className="text-ant-red-5" />}
                okText="Удалить"
                okType="danger"
                onConfirm={() => onClickDelete({ id })}
                placement="topLeft"
                title={
                  <span className="text-sm">
                    Вы уверены, что хотите удалить аватар
                    <span className="font-bold"> &quot;{avatar.localizations?.ru?.name}&quot;</span>
                    ?
                  </span>
                }
              >
                <Button danger icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          </div>
        );
      },
      title: 'Действия',
    },
  ];
};
