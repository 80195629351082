export type MapStageTypeEnumKeys = keyof typeof MapStageTypeEnum;

export enum MapStageTypeEnum {
  EXERCISE = 'Задачи',
  GAME_MATCH = 'Игра в шахматы',
  GIFT = 'Подарок',
  VIDEO = 'Видео',
}

const mapStagesWithNames = Object.keys(MapStageTypeEnum).map((key) => ({
  name: MapStageTypeEnum[key],
  value: key,
}));

const customOrder = [
  MapStageTypeEnum.VIDEO,
  MapStageTypeEnum.EXERCISE,
  MapStageTypeEnum.GAME_MATCH,
  MapStageTypeEnum.GIFT,
];

export const sortedMapStages = customOrder.map((value) =>
  mapStagesWithNames.find((item) => item.name === value),
);
