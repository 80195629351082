import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { QuestionOutlined } from '@ant-design/icons';

import { FaChessKnightIcon, TbVideoIcon, VscPlayIcon } from '@components/atoms/icons';
import { ExerciseTypeEnum } from '@exercises/exercises/types/enums/ExerciseTypeEnum';
import { UserMapStageTypeEnum } from '@users/types/enums/UserMapStageTypeEnum';
import { IUserMapStage, userMapStageKeys } from '@users/types/IUserMapStage';

export const userMapStagesColumns: ColumnsType<IUserMapStage> = [
  {
    dataIndex: userMapStageKeys.stageNumber,
    title: 'Уровень',
  },
  {
    dataIndex: userMapStageKeys.type,
    render: (type: IUserMapStage['type'], { exercises, gift, video }) => (
      <div className="grid gap-2">
        <span>{`${UserMapStageTypeEnum[type]?.[0]?.toUpperCase()}${UserMapStageTypeEnum[type]
          ?.substring(1)
          ?.toLowerCase()}`}</span>

        {type == 'VIDEO' && (
          <Link
            className={`flex items-center gap-1 text-center text-ant-purple-6`}
            to={`/videos?action=view&actionId=${video.id}`}
          >
            <TbVideoIcon className="min-w-fit" />
            {video.title}
          </Link>
        )}

        {type == 'EXERCISE' &&
          exercises.map(({ id, type }) => (
            <Link
              className="flex items-center gap-1 text-center text-black"
              key={id}
              to={`/exercises?action=view&actionId=${id}`}
            >
              <p
                className={`flex items-center gap-1 mb-0 ${
                  type == 'TEST' ? 'text-ant-orange-6' : ''
                }`}
              >
                {type == 'TEST' ? <QuestionOutlined /> : <FaChessKnightIcon />}
                {ExerciseTypeEnum[type]} #{id}
              </p>
            </Link>
          ))}

        {(type == 'GIFT_VIDEO' || type == 'GIFT') && (
          <div className="grid gap-2">
            {!!gift.customerVideos?.length &&
              gift.customerVideos.map(({ id, isCartoon, title }) => (
                <Link
                  className={`flex items-center gap-1 ${
                    isCartoon ? 'text-ant-magenta-6' : 'text-ant-purple-6'
                  }`}
                  key={id}
                  to={`/videos?action=view&actionId=${id}`}
                >
                  {isCartoon ? <VscPlayIcon /> : <TbVideoIcon />}
                  {title}
                </Link>
              ))}
            {!!gift.hintCount && <span>Подсказки: {gift.hintCount}</span>}
            {!!gift.moveBackCount && <span>Ходы назад: {gift.moveBackCount}</span>}
            {!!gift.coinTransaction && <span>Коины: {gift.coinTransaction.amount}</span>}
          </div>
        )}
      </div>
    ),
    title: 'Тип уровня',
  },
  {
    dataIndex: userMapStageKeys.stars,
    render: (stars: IUserMapStage['stars']) => stars ?? '-',
    title: 'Звезды',
  },
  {
    dataIndex: userMapStageKeys.numberToPresent,
    render: (numberToPresent: IUserMapStage['numberToPresent']) => numberToPresent ?? '-',
    title: 'Уровень на карте',
  },
];
