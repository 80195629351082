import { ExerciseGamePhaseEnumKeys } from '@exercises/exercises/types/enums/ExerciseGamePhaseEnum';
import { ITag } from '@tags/types/ITag';
import { ILocalization } from '@typess/ILocalization';
import { UserMapStageTypeEnumKeys } from '@users/types/enums/UserMapStageTypeEnum';

export type IUserMapStage = {
  allowedToProceed: boolean;
  customerId: number;
  exercises: {
    id: number;
    problem: {
      boardState: string;
      moveSequence: string;
      name: string;
    };
    tags: ITag;
    test: object;
    type: 'PROBLEM' | 'TEST';
  }[];
  fenForGameMatch: string;
  gift: {
    coinTransaction: {
      amount: number;
      balance: number;
      createdDate: string;
      id: number;
      updatedDate: string;
    };
    customerVideos: {
      author: ITag;
      gamePhase: ExerciseGamePhaseEnumKeys;
      id: number;
      isCapitalization: boolean;
      isCartoon: boolean;
      isTactic: boolean;
      locked: boolean;
      mainUrl: string;
      playLevel: number;
      previewUrl: string;
      price: number;
      streamingUrl: string;
      subtitlesUrl: Record<ILocalization, string>;
      tags: ITag[];
      title: string;
      wrapped: boolean;
    }[];
    giftSetValue: number;
    hintCount: number;
    id: number;
    moveBackCount: number;
    type: 'CARTOON' | 'COIN' | 'GAME_HINT' | 'GAME_MOVE_BACK' | 'MIXED' | 'VIDEO';
  };
  isPassed: boolean;
  newStage: boolean;
  numberToPresent: number;
  prevStars: number;
  proceedRemainingTime: number;
  stageNumber: number;
  stars: number;
  type: UserMapStageTypeEnumKeys;
  video: {
    author: ITag;
    id: number;
    previewUrl: string;
    streamingUrl: string;
    subtitlesUrl: Record<ILocalization, string>;
    tags: ITag[];
    test: { answerOptions: string[]; correctOption: number; imageUrl: string; question: string };
    title: string;
  };
};

export const userMapStageKeys: Record<keyof IUserMapStage, string> = {
  allowedToProceed: 'allowedToProceed',
  customerId: 'customerId',
  exercises: 'exercises',
  fenForGameMatch: 'fenForGameMatch',
  gift: 'gift',
  isPassed: 'isPassed',
  newStage: 'newStage',
  numberToPresent: 'numberToPresent',
  prevStars: 'prevStars',
  proceedRemainingTime: 'proceedRemainingTime',
  stageNumber: 'stageNumber',
  stars: 'stars',
  type: 'type',
  video: 'video',
};
