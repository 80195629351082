import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { dashboardQueryKeys } from './dashboardQueryKeys';

export type IUsersByPlayLevelStatsRequest = {
  //
};

type IUsersByPlayLevelStatsResponse = AxiosResponse<Record<string, { totalNumber: number }>>;

export const usersByPlayLevelStatsRequest = async (
  params?: IUsersByPlayLevelStatsRequest,
): Promise<IUsersByPlayLevelStatsResponse> => {
  return await axios.get('/stats/play-level', { params });
};

export function useQueryByPlayLevelStats(params?: IUsersByPlayLevelStatsRequest) {
  return useQuery<IUsersByPlayLevelStatsResponse, AxiosError>(
    [dashboardQueryKeys.userPlayLevel, params],
    () => usersByPlayLevelStatsRequest(params),
  );
}
