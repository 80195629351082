import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, number, object, ObjectSchema, string } from 'yup';

import { videoQueryKeys } from '@videos/api/videoQueryKeys';
import { VideoStatusEnumKeys } from '@videos/types/enums/VideoStatusEnum';
import { IVideo } from '@videos/types/IVideo';

export type IVideoStepThreeRequest = Pick<IVideo, 'status' | 'test' | 'id'>;

type IVideoStepThreeResponse = AxiosResponse<IVideo>;
export const videoStepThreeInitial: IVideoStepThreeRequest = {
  id: 0,
  status: 'DRAFT',
  test: {
    correctOption: 0,
    localizations: {
      en: { answerOptions: ['', '', '', ''], question: '' },
      ru: { answerOptions: ['', '', '', ''], question: '' },
    },
  },
};

export const videoStepThreeValidation: ObjectSchema<IVideoStepThreeRequest> = object({
  id: number().required(' '),
  status: string<VideoStatusEnumKeys>().required(),
  test: object()
    .shape({
      correctOption: number()
        .nullable()
        .required('Правильный вариант обязателен для заполнения')
        .min(1, 'Правильный вариант обязателен для заполнения')
        .max(4, 'Правильный вариант обязателен для заполнения'),
      localizations: object({
        en: object({
          answerOptions: array()
            .required()
            .of(
              string()
                .nullable()
                .required('Ответ обязателен для заполнения')
                .max(100, 'Ответ должен быть не более 60 символов'),
            ),
          question: string()
            .required('Вопрос обязателен для заполнения')
            .max(250, 'Вопрос должен быть не более 200 символов'),
        }),
        ru: object({
          answerOptions: array()
            .required()
            .of(
              string()
                .nullable()
                .required('Ответ обязателен для заполнения')
                .max(100, 'Ответ должен быть не более 60 символов'),
            ),
          question: string()
            .required('Вопрос обязателен для заполнения')
            .max(250, 'Вопрос должен быть не более 200 символов'),
        }),
      }),
    })
    .required(),
});

export const videoStepThreeRequest = async (
  payload: IVideoStepThreeRequest,
): Promise<IVideoStepThreeResponse> => {
  return await axios.patch('videos/3', payload);
};

export function useMutateVideoStepThree() {
  const queryClient = useQueryClient();

  return useMutation<IVideoStepThreeResponse, AxiosError, IVideoStepThreeRequest>(
    videoStepThreeRequest,
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(videoQueryKeys.videos);
        queryClient.invalidateQueries([videoQueryKeys.video, { id: variables.id }]);
        queryClient.invalidateQueries([videoQueryKeys.video, { id: variables.id.toString() }]);
      },
    },
  );
}
