import { IFilters } from '@typess/IFilters';
import { userGameMatchesKeys } from '@users/types/IUserGameMatch';

export const userPurchasesFilters: IFilters = {
  [userGameMatchesKeys.id]: { sort: true },
  page: {},
  pageSize: {},
  sortBy: {},
  sortDirection: {},
};
