import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { boardCommonKeys } from '@collections/boards/api/boardCommonKeys';
import { notify } from '@utils/notify';

import { boardGroupQueryKeys } from './boardGroupQueryKeys';

type IBoardGroupDeleteRequest = { id: number };

type IBoardGroupDeleteResponse = AxiosResponse<void>;

export const BoardGroupDeleteRequest = async ({
  id,
}: IBoardGroupDeleteRequest): Promise<IBoardGroupDeleteResponse> =>
  await axios.delete(`/chess-board-groups/${id}`);

export function useMutateBoardGroupDelete() {
  const queryClient = useQueryClient();

  return useMutation<IBoardGroupDeleteResponse, AxiosError, IBoardGroupDeleteRequest>(
    BoardGroupDeleteRequest,
    {
      onSuccess: () => {
        notify('error', 'Группа досок удалена');

        queryClient.invalidateQueries(boardGroupQueryKeys.boardsGroups);
        queryClient.invalidateQueries(boardGroupQueryKeys.boardGroup);
        queryClient.invalidateQueries(boardCommonKeys.board);
        queryClient.invalidateQueries(boardCommonKeys.boards);
      },
    },
  );
}
