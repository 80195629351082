import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IMapStage } from '../types/IMapStage';
import { mapStageQueryKeys } from './mapStagesQueryKeys';

export type MapStageStatusUpdateRequest = Pick<IMapStage, 'id' | 'status'>;

type MapStageStatusUpdateResponse = AxiosResponse<void>;

export const mapStageStatusUpdateRequest = async ({
  id,
  ...payload
}: MapStageStatusUpdateRequest): Promise<MapStageStatusUpdateResponse> => {
  return await axios.patch(`/map-stage-definitions/${id}`, payload);
};

export function useMutateMapStageStatusUpdate() {
  const queryClient = useQueryClient();

  return useMutation<MapStageStatusUpdateResponse, AxiosError, MapStageStatusUpdateRequest>(
    mapStageStatusUpdateRequest,
    {
      onSuccess: () => queryClient.invalidateQueries(mapStageQueryKeys.mapStages),
    },
  );
}
