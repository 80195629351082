import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema } from 'yup';

import { assessmentExerciseQueryKeys } from './assessmentExerciseQueryKeys';
import {
  assessmentExerciseTestCreateValidation,
  IAssessmentExerciseTestCreateRequest,
} from './useMutateAssessmentExerciseTestCreate';

export type IAssessmentExerciseTestEditRequest = IAssessmentExerciseTestCreateRequest & {
  id: number;
};

type IAssessmentExerciseTestEditResponse = AxiosResponse<void>;

export const assessmentExerciseTestEditValidation: ObjectSchema<IAssessmentExerciseTestEditRequest> =
  object({
    id: number().required(),
  }).concat(assessmentExerciseTestCreateValidation);

export const assessmentExerciseTestEditRequest = async (
  payload: IAssessmentExerciseTestEditRequest,
): Promise<IAssessmentExerciseTestEditResponse> => {
  return await axios.patch(`/admin/assessment-exercises/test/${payload.id}`, payload);
};

export function useMutateAssessmentExerciseTestEdit() {
  const queryClient = useQueryClient();

  return useMutation<
    IAssessmentExerciseTestEditResponse,
    AxiosError,
    IAssessmentExerciseTestEditRequest
  >(assessmentExerciseTestEditRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(assessmentExerciseQueryKeys.assessmentExercises);
    },
  });
}
