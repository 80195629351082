import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { appPromotionsQueryKeys } from '@shop/appPromotions/api/appPromotionsQueryKeys';
import { notify } from '@utils/notify';

export type IAppPromotionDeleteRequest = { id: number };

type IAppPromotionDeleteResponse = AxiosResponse<void>;

export const appPromotionDeleteRequest = async ({
  id,
}: IAppPromotionDeleteRequest): Promise<IAppPromotionDeleteResponse> => {
  return await axios.delete(`iap-promotions/${id}`);
};

export function useMutateAppPromotionDelete() {
  const queryClient = useQueryClient();

  return useMutation<IAppPromotionDeleteResponse, AxiosError, IAppPromotionDeleteRequest>(
    appPromotionDeleteRequest,
    {
      onSuccess: async () => {
        notify('warning', 'Кампания удалена');
        await queryClient.invalidateQueries(appPromotionsQueryKeys.appPromotions);
      },
    },
  );
}
