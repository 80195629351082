import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum VideoFilesPageActionEnum {
  'create',
  'view',
}

export type IVideoFilesPageActions = {
  action: keyof typeof VideoFilesPageActionEnum;
  actionId: string;
};

export const videoFilesPageActions: IPageActions<IVideoFilesPageActions> = {
  action: {
    allowed: (value) => Object.keys(VideoFilesPageActionEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
};
