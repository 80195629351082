import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { promocodesQueryKeys } from '@settings/promocodes/api/promocodesQueryKeys';
import { notify } from '@utils/notify';

type IPromocodeDeleteRequest = { id: number };

type IPromocodeDeleteResponse = AxiosResponse<void>;

export const promocodeDeleteRequest = async (
  payload: IPromocodeDeleteRequest,
): Promise<IPromocodeDeleteResponse> => await axios.delete(`/promocodes/${payload.id}`);

export function useMutatePromocodeDelete() {
  const queryClient = useQueryClient();

  return useMutation<IPromocodeDeleteResponse, AxiosError, IPromocodeDeleteRequest>(
    promocodeDeleteRequest,
    {
      onSuccess: () => {
        notify('error', 'Промокод удален');

        queryClient.invalidateQueries(promocodesQueryKeys.promocodes);
      },
    },
  );
}
