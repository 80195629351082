import { useMutation } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

import { IPageAbleRequest } from '@typess/IPageAbleRequest';

type IPurchaseEventsExportRequest = IPageAbleRequest;

type IPurchaseEventsExportResponse = AxiosResponse<Blob>;

export const purchaseEventsExport = async (
  params: IPurchaseEventsExportRequest,
): Promise<IPurchaseEventsExportResponse> => {
  return await axios.get('/stats-xlsx/purchase-events', { params: params, responseType: 'blob' });
};

export function useMutatePurchaseEventsExport() {
  return useMutation<IPurchaseEventsExportResponse, AxiosError, IPurchaseEventsExportRequest>(
    purchaseEventsExport,
    {
      onSuccess: (data) => {
        saveAs(
          data.data,
          data.headers['content-disposition']?.split('"')[1] ??
            `Chess-legends-purchase-events.xlsx`,
        );
      },
    },
  );
}
