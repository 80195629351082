export enum VersionsPlatformEnum {
  ANDROID = 'Android',
  IOS = 'IOS',
}

export type VersionsPlatformEnumKeys = keyof typeof VersionsPlatformEnum;

export enum VersionsForcedEnum {
  OPTIONAL = 'Опциональное',
  REQUIRED = 'Обязательное',
}

export type VersionsForcedEnumKeys = keyof typeof VersionsForcedEnum;
