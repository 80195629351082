import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { appSettingsQueryKeys } from '@settings/appSettings/api/appSettingsQueryKeys';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

import { AppSettingsNameEnumKeys } from '../types/enums/AppSettingNameEnum';
import { IAppSetting } from '../types/IAppSetting';

export type IAppSettingsRequest = IPageAbleRequest & {
  settingNames?: AppSettingsNameEnumKeys[];
};

type IAppSettingsResponse = AxiosResponse<IPageAbleResponse<IAppSetting>>;

export const appSettingsRequest = async (
  params?: IAppSettingsRequest,
): Promise<IAppSettingsResponse> => await axios.get('/app-settings', { params });

export function useQueryAppSettings(params?: IAppSettingsRequest) {
  return useQuery<IAppSettingsResponse, AxiosError>(
    [appSettingsQueryKeys.appSettings, params],
    () => appSettingsRequest(params),
  );
}
