import { FC } from 'react';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

type SortType = {
  dataIndex: string;
  onFilter: (key: object) => void;
  orderedBy?: string;
  sortOrderReversed?: boolean;
  sortedBy?: string;
};

export const TableHeaderSortIcon: FC<SortType> = ({
  dataIndex,
  onFilter,
  orderedBy,
  sortOrderReversed,
  sortedBy,
}) => {
  const isActive = sortedBy == dataIndex;
  const orders = sortOrderReversed ? ['DESC', 'ASC'] : ['ASC', 'DESC'];
  const nextOrderBy =
    isActive && orderedBy == orders[0]
      ? orders[1]
      : isActive && orderedBy == orders[1]
      ? undefined
      : orders[0];

  return (
    <span
      className=" ant-table-filter-trigger inline-flex flex-col justify-center text-xs"
      onClick={(e) => {
        e.stopPropagation();
        onFilter({
          ['sortBy']: nextOrderBy ? dataIndex : undefined,
          ['sortDirection']: nextOrderBy,
        });
      }}
    >
      <CaretUpOutlined className={`h-2.5 ${orderedBy == 'ASC' && isActive && 'text-ant-blue-6'}`} />
      <CaretDownOutlined
        className={`h-2.5 ${orderedBy == 'DESC' && isActive && 'text-ant-blue-6'}`}
      />
    </span>
  );
};
