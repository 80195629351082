import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { object, ObjectSchema, string } from 'yup';

import { exerciseQueryKeys } from '@exercises/exercises/api/exerciseQueryKeys';

import {
  exerciseCustomGameCreateValidation,
  IExerciseCustomGameCreateRequest,
} from './useMutateExerciseCustomGameCreate';

export type IExerciseCustomGameEditRequest = IExerciseCustomGameCreateRequest & { id: string };

type IExerciseCustomGameEditResponse = AxiosResponse<void>;

export const exerciseCustomGameEditValidation: ObjectSchema<IExerciseCustomGameEditRequest> =
  object({
    id: string().required(),
  }).concat(exerciseCustomGameCreateValidation);

export const exerciseCustomGameEditRequest = async (
  payload: IExerciseCustomGameEditRequest,
): Promise<IExerciseCustomGameEditResponse> => {
  return await axios.patch(`/exercises/customProblem/${payload.id}`, payload);
};

export function useMutateExerciseCustomGameEdit() {
  const queryClient = useQueryClient();

  return useMutation<IExerciseCustomGameEditResponse, AxiosError, IExerciseCustomGameEditRequest>(
    exerciseCustomGameEditRequest,
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(exerciseQueryKeys.exercises);
        queryClient.invalidateQueries([exerciseQueryKeys.exercise, { id: variables.id }]);
      },
    },
  );
}
