import { IPermissions } from '@typess/permissions/IPermissions';

export type IMapStagePermission =
  | 'PERM_MAP_STAGE_DEFINITION_EDIT'
  | 'PERM_MAP_STAGE_DEFINITION_VIEW';

export const mapStagePermissions: IPermissions = {
  edit: 'PERM_MAP_STAGE_DEFINITION_EDIT',
  view: 'PERM_MAP_STAGE_DEFINITION_VIEW',
};
