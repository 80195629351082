import { IClassifierSkillTypeRangeEvaluation } from '@settings/classifierSkillTypes/types/IClassifierSkillTypeRangeEvaluation';

export type IClassifierSkillType = {
  name: string;
  practiceEvaluationListPrimary?: IClassifierSkillTypeRangeEvaluation[] | null;
  practiceEvaluationListSecondary?: IClassifierSkillTypeRangeEvaluation[] | null;
  practicePartInPercent?: number;
  tacticEvalDifference?: number | null;
  theoryPartInPercent?: number;
};

export const classifierSkillTypeKeys: Record<keyof IClassifierSkillType, string> = {
  name: 'name',
  practiceEvaluationListPrimary: 'practiceEvaluationListPrimary',
  practiceEvaluationListSecondary: 'practiceEvaluationListSecondary',
  practicePartInPercent: 'practicePartInPercent',
  tacticEvalDifference: 'tacticEvalDifference',
  theoryPartInPercent: 'theoryPartInPercent',
};
