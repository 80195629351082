import {
  ChessPiecesSetStatusEnum,
  ChessPiecesSetTypeEnum,
} from '@collections/chess-pieces/types/enums/ChessPiecesSetEnum';
import { chessPiecesSetKeys } from '@collections/chess-pieces/types/IChessPiecesSet';
import { RadioFilter } from '@components/molecules/filters/static/RadioFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { IFilters } from '@typess/IFilters';

export const chessPiecesFilters: IFilters = {
  ...paginationBaseFilters,

  [chessPiecesSetKeys.updatedDate]: { sort: true },
  [chessPiecesSetKeys.createdDate]: { sort: true },
  [chessPiecesSetKeys.type]: {
    input: (
      <RadioFilter
        options={Object.entries(ChessPiecesSetTypeEnum).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },
  [chessPiecesSetKeys.status]: {
    input: (
      <RadioFilter
        options={Object.entries(ChessPiecesSetStatusEnum).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },
  [chessPiecesSetKeys.price]: {
    sort: true,
  },
};
