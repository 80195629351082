import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IChessPiecesGroup } from '../types/IChessPiecesGroup';
import { chessPiecesGroupQueryKeys } from './chessPiecesGroupQueryKeys';

export type IChessPiecesGroupRequest = { id: number };
type IChessPiecesGroupResponse = AxiosResponse<IChessPiecesGroup>;

export const chessPiecesGroupRequest = async (
  params: IChessPiecesGroupRequest,
): Promise<IChessPiecesGroupResponse> => await axios.get(`/chess-piece-set-groups/${params.id}`);

export function useQueryChessPiecesGroup(params: IChessPiecesGroupRequest) {
  return useQuery<IChessPiecesGroupResponse, AxiosError>(
    [chessPiecesGroupQueryKeys.chessPiecesGroup, params],
    () => chessPiecesGroupRequest(params),
    { enabled: params.id > 0 },
  );
}
