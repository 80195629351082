import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { boolean, number, object, ObjectSchema, string } from 'yup';

import { notify } from '@utils/notify';

import { onboardingItemsKeys } from './onboardingItemsKeys';

export type IOnboardingItemUpdateRequest = {
  id: number;
  imgUrl: string;
  order?: number;
  visible?: boolean;
};

export const updateOnboardingItemValidation: ObjectSchema<IOnboardingItemUpdateRequest> = object({
  id: number().required(),
  imgUrl: string().required(),
  order: number().required(),
  visible: boolean().required(),
});

export const updateOnboardingItemRequest = ({ id, ...payload }: IOnboardingItemUpdateRequest) =>
  axios.patch(`/onboarding/${id}`, payload);

export const useMutateOnboardingItemUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, IOnboardingItemUpdateRequest>(
    updateOnboardingItemRequest,
    {
      onError: () => {
        notify('error', 'Повторите попытку позже');
      },
      onSuccess: () => {
        notify('success', 'Изображение успешно обновлено');

        queryClient.invalidateQueries(onboardingItemsKeys.onboardingItems);
      },
    },
  );
};
