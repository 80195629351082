import { useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';

import { tagQueryKeys } from '@tags/api/tagQueryKeys';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

import { ITag } from '../types/ITag';

export type ITagRequest = {
  id: number;
};

export const tagRequest = async (params: ITagRequest): Promise<ITag> => {
  return await axios
    .get<IPageAbleResponse<ITag>>(`/tags`, { params })
    .then((data) => data.data?.content[0]);
};

export function useQueryTag(params: ITagRequest) {
  return useQuery<ITag, AxiosError>([tagQueryKeys.tag, params], () => tagRequest(params), {
    enabled: params.id > 0,
  });
}
