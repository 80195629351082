import { FC } from 'react';
import { Button, Form as AntForm } from 'antd';
import { FormItem, Input } from 'formik-antd';
import { DeleteOutlined } from '@ant-design/icons';

import { CountryImageText } from '@components/atoms/CountryImageText';
import { ILocalization } from '@typess/ILocalization';

export const AssessmentQuestionAnswersForm: FC<{
  localization: ILocalization;
  maxQuestionsNum?: number;
  nameStart: string;
  questionsNum?: number;
  setQuestionsNum?: (questionsNum: number) => void;
  test?: { answerOptions: string[]; question: string };
  text: string;
}> = ({
  localization,
  maxQuestionsNum = 4,
  nameStart,
  questionsNum = 4,
  setQuestionsNum,
  test,
  text,
}) => {
  return (
    <div className="md:grid md:grid-cols-2 gap-x-5 space-y-3">
      <FormItem
        className="col-span-2 mb-2"
        help={
          (test?.question?.length || 0) > 56 ? (
            <span>Рекомендуемое количество символов 56</span>
          ) : (test?.question?.length || 0) == 0 ? (
            <span>Вопрос обязателен для заполнения</span>
          ) : (
            ''
          )
        }
        label={<CountryImageText src={localization} text={text} width={15} />}
        name={`${nameStart}.question`}
        validateStatus={(test?.question?.length || 0) > 56 ? 'error' : ''}
      >
        <Input.TextArea
          maxLength={250}
          name={`${nameStart}.question`}
          placeholder="Вопрос"
          showCount
        />
      </FormItem>

      {Array.from(Array(questionsNum)).map((_, index) => (
        <FormItem
          help={
            (test?.answerOptions?.[index]?.length || 0) > 26 ? (
              <span>Рекомендуемое количество символов 26</span>
            ) : (test?.answerOptions?.[index]?.length || 0) == 0 ? (
              <span>Ответ обязателен для заполнения</span>
            ) : (
              ''
            )
          }
          key={`answer${index + 1}`}
          label={`Вариант ${index + 1}`}
          name={`${nameStart}.answerOptions.${index}`}
          validateStatus={(test?.answerOptions?.[index]?.length || 0) > 26 ? 'error' : ''}
        >
          <Input
            maxLength={100}
            name={`${nameStart}.answerOptions.${index}`}
            placeholder="Ответ"
            prefix
            showCount
          />
          {index > 1 && setQuestionsNum && (
            <Button
              className="absolute top-0 right-0 top-[-32px] border-none"
              icon={<DeleteOutlined />}
              onClick={() => {
                test?.answerOptions.splice(index, 1);
                setQuestionsNum(questionsNum - 1);
              }}
            />
          )}
        </FormItem>
      ))}

      {maxQuestionsNum > questionsNum && setQuestionsNum && (
        <AntForm.Item label="Вариант">
          <Button block onClick={() => setQuestionsNum(questionsNum + 1)}>
            Добавить
          </Button>
        </AntForm.Item>
      )}
    </div>
  );
};
