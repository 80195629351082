import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '@components/molecules/routes/ProtectedRoute';
import NotFoundPage from '@pages/errors/NotFoundPage';
import { tagPermissions } from '@typess/permissions/ITagPermission';

import TagsPage from './components/pages/TagsPage';

export const TagRouter = () => {
  return (
    <ProtectedRoute permissions={Object.values(tagPermissions)}>
      <Routes>
        <Route element={<TagsPage />} index />
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </ProtectedRoute>
  );
};
