import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { boardCommonKeys } from '@collections/boards/api/boardCommonKeys';
import { notify } from '@utils/notify';

import { boardGroupQueryKeys } from './boardGroupQueryKeys';

type IBoardGroupItemDeleteRequest = { chessBoardId: number; id: number };

type IBoardGroupItemDeleteResponse = AxiosResponse<void>;

export const boardGroupItemDeleteRequest = async ({
  chessBoardId,
  id,
}: IBoardGroupItemDeleteRequest): Promise<IBoardGroupItemDeleteResponse> =>
  await axios.delete(`/chess-board-groups/${id}/item/${chessBoardId}`);

export function useMutateBoardGroupItemDelete() {
  const queryClient = useQueryClient();

  return useMutation<IBoardGroupItemDeleteResponse, AxiosError, IBoardGroupItemDeleteRequest>(
    boardGroupItemDeleteRequest,
    {
      onSuccess: () => {
        notify('error', 'Доска удалена из группы');
        queryClient.invalidateQueries(boardGroupQueryKeys.boardGroup);
        queryClient.invalidateQueries(boardCommonKeys.boards);
      },
    },
  );
}
