import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { PencilIcon } from '@components/atoms/icons/PencilIcon';
import {
  classifierSkillTypeKeys,
  IClassifierSkillType,
} from '@settings/classifierSkillTypes/types/IClassifierSkillType';
import { UserSkillTypeEnum } from '@users/types/enums/UserSkillTypeEnum';

export const classifierColumns = ({
  canEdit,
  onClickEdit,
}: {
  canEdit: boolean;
  onClickEdit: (data: { name: string }) => void;
}): ColumnsType<IClassifierSkillType> => {
  const columns: ColumnsType<IClassifierSkillType> = [
    {
      dataIndex: classifierSkillTypeKeys.name,
      render: (name: IClassifierSkillType['name']) => UserSkillTypeEnum[name] || name,
      title: 'Название',
    },
    {
      dataIndex: classifierSkillTypeKeys.practiceEvaluationListPrimary,
      render: (evaluationPrimary?: IClassifierSkillType['practiceEvaluationListPrimary']) => (
        <ul className="list-disc">
          {evaluationPrimary?.map((evaluation, index) => (
            <li
              className="mb-0 whitespace-nowrap"
              key={`evaluationPrimary${index}`}
            >{`Начало: ${evaluation.from}, Конец: ${evaluation.to}, Значение: ${evaluation.value}`}</li>
          ))}
        </ul>
      ),
      title: 'Практика основная',
    },
    {
      dataIndex: classifierSkillTypeKeys.practiceEvaluationListSecondary,
      render: (evaluationSecondary?: IClassifierSkillType['practiceEvaluationListSecondary']) => (
        <ul className="list-disc">
          {evaluationSecondary?.map((evaluation, index) => (
            <li
              className="mb-0 whitespace-nowrap"
              key={`evaluationSecondary${index}`}
            >{`Начало: ${evaluation.from}, Конец: ${evaluation.to}, Значение: ${evaluation.value}`}</li>
          ))}
        </ul>
      ),
      title: 'Практика второстепенная',
    },
    {
      dataIndex: classifierSkillTypeKeys.tacticEvalDifference,
      title: 'Оценка тактики',
    },
    {
      dataIndex: classifierSkillTypeKeys.practicePartInPercent,
      render: (practice: IClassifierSkillType['practicePartInPercent']) => `${practice}%`,
      title: 'Практика',
    },
    {
      dataIndex: classifierSkillTypeKeys.theoryPartInPercent,
      render: (theory: IClassifierSkillType['theoryPartInPercent']) => `${theory}%`,
      title: 'Теория',
    },
  ];

  if (!canEdit) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: (classifierSkill: IClassifierSkillType) => (
        <div className="flex gap-x-1.5">
          <Button
            ghost
            icon={<PencilIcon />}
            onClick={() => onClickEdit({ name: classifierSkill.name })}
            type="primary"
          />
        </div>
      ),
      title: 'Действия',
    },
  ];
};
