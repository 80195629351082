import { FC } from 'react';

import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { SettingTabs } from '@settings/appSettings/components/molecules/SettingTabs';
import { settingPermissions } from '@typess/permissions/ISettingPermission';
import { checkEditPermission } from '@utils/checkEditPermission';

import { IAppSettingsRequest, useQueryAppSettings } from '../../api/useQueryAppSettings';
import { appSettingsColumns } from '../../consts/columns/appSettingsColumns';
import { appSettingsFilters } from '../../consts/filters/appSettingsFilters';
import {
  AppSettingsPageActions,
  appSettingsPageActions,
} from '../../consts/pageActions/AppSettingsPageActions';
import { AppSettingUpdateModal } from '../organisms/AppSettingUpdateModal';

const AppSettingsPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IAppSettingsRequest,
    AppSettingsPageActions
  >(appSettingsFilters, appSettingsPageActions);

  const appSettings = useQueryAppSettings({
    settingNames: [
      'ABOUT_URL',
      'SUPPORT_EMAIL',
      'TERMS_URL',
      'PLAY_LEVEL_ENGINE_LEVEL_MAP',
      'PLAY_LEVEL_ENGINE_RATING_MAP',
      'BATTLE_ENGINE_PLAY_LEVEL_MAP',
    ],
  });

  const canEditSettings = checkEditPermission(settingPermissions);

  return (
    <>
      <PageMeta
        selectedMenuKeys={['settings']}
        subTitle={actioned.action == 'edit' ? 'Изменение настройки' : ''}
        title="Настройки"
      />

      <SettingTabs active="settings" />

      <AdminTable
        columns={appSettingsColumns({
          canEdit: canEditSettings,
          onClickEdit: ({ name }) => setActioned({ action: 'edit', actionName: name }),
        })}
        emptyDescription={<p>Настройки не найдены</p>}
        query={appSettings}
        rowKey="name"
        tableFilter={{ filtered, filters: appSettingsFilters, onFilter }}
      />

      <AppSettingUpdateModal
        isOpen={actioned.action == 'edit'}
        name={actioned.actionName}
        onCancel={clearParams}
      />
    </>
  );
};

export default AppSettingsPage;
