import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum MapStagesPageActionEnum {
  'createStage',
  'edit',
  'view',
  'viewExercise',
  'viewFen',
  'viewVideo',
}

export type MapStagesPageActions = {
  action: keyof typeof MapStagesPageActionEnum;
  actionId: number;
  exerciseId: number;
  fenId: number;
  videoId: number;
};

export const mapStagesPageActions: IPageActions<MapStagesPageActions> = {
  action: {
    allowed: (value) => Object.keys(MapStagesPageActionEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
};
