import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment/moment';
import {
  CaretRightOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import { PencilIcon } from '@components/atoms/icons/PencilIcon';
import { exerciseGroupKeys, IExerciseGroup } from '@exercises/exerciseGroups/types/IExerciseGroup';
import { ExerciseStatusEnumKeys } from '@exercises/exercises/types/enums/ExerciseStatusEnum';

export const exerciseGroupColumns = ({
  canEdit,
  onClickDelete,
  onClickEdit,
  onClickPause,
  onClickView,
}: {
  canEdit: boolean;
  onClickDelete: ({ id }: { id: number }) => void;
  onClickEdit: ({ id }: { id: number }) => void;
  onClickPause: ({ id, status }: { id: number; status: ExerciseStatusEnumKeys }) => void;
  onClickView: ({ id }: { id: number }) => void;
}): ColumnsType<IExerciseGroup> => {
  const columns: ColumnsType<IExerciseGroup> = [
    {
      dataIndex: exerciseGroupKeys.id,
      render: (id: IExerciseGroup['id']) => (
        <Button onClick={() => onClickView({ id })} type="link">
          {id}
        </Button>
      ),
      title: 'ID',
    },
    {
      dataIndex: exerciseGroupKeys.groupName,
      title: 'Название группы',
    },
    {
      dataIndex: exerciseGroupKeys.exercisesCount,
      title: 'Количество задач',
    },
    {
      dataIndex: exerciseGroupKeys.createdDate,
      render: (createdDate: IExerciseGroup['createdDate']) => (
        <span className="whitespace-nowrap">{moment(createdDate).format('LL')}</span>
      ),
      title: 'Дата создания',
    },
  ];

  if (!canEdit) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: (exerciseGroup: IExerciseGroup) => (
        <div className="flex gap-x-1.5">
          <Popconfirm
            cancelText="Отменить"
            icon={<PauseCircleOutlined className="text-ant-blue-5 text-base" />}
            okText="Приостановить"
            okType="primary"
            onConfirm={() =>
              onClickPause({
                id: exerciseGroup.id,
                status: 'PAUSED',
              })
            }
            placement="topLeft"
            title={
              <p className="text-sm mb-0">
                Вы уверены, что хотите
                <span className="font-semibold">
                  {' '}
                  приостановить группу упражнений &quot;{exerciseGroup.groupName}&quot;?
                </span>
              </p>
            }
          >
            <Button ghost icon={<PauseOutlined />} type="primary" />
          </Popconfirm>
          <Popconfirm
            cancelText="Отменить"
            icon={<PlayCircleOutlined className="text-ant-blue-5 text-base" />}
            okText="Запустить"
            okType="primary"
            onConfirm={() =>
              onClickPause({
                id: exerciseGroup.id,
                status: 'ACTIVE',
              })
            }
            placement="topLeft"
            title={
              <p className="text-sm mb-0">
                Вы уверены, что хотите
                <span className="font-semibold">
                  {' '}
                  запустить группу упражнений &quot;{exerciseGroup.groupName}&quot;?
                </span>
              </p>
            }
          >
            <Button ghost icon={<CaretRightOutlined />} type="primary" />
          </Popconfirm>
          <Button
            ghost
            icon={<PencilIcon />}
            onClick={() => onClickEdit({ id: exerciseGroup.id })}
            type="primary"
          />
          <Popconfirm
            cancelText="Отменить"
            icon={<DeleteOutlined className="text-ant-red-5" />}
            okText="Удалить"
            okType="danger"
            onConfirm={() => onClickDelete({ id: exerciseGroup.id })}
            placement="topLeft"
            title={
              <span className="text-sm">
                Вы уверены, что хотите удалить группу упражнений
                <span className="font-bold"> &quot;{exerciseGroup.groupName}&quot;</span>?
              </span>
            }
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
      title: 'Действия',
    },
  ];
};
