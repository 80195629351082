import type { FC } from 'react';
import { Button, Modal, Tag } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Select } from 'formik-antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  boardGroupAddItemInitialValue,
  boardGroupAddItemValidation,
  IBoardGroupAddItemRequest,
  useMutateBoardGroupAddItem,
} from '@collections/board-groups/api/useMutateBoardGroupAddItem';
import { IBoard } from '@collections/boards/types/IBoard';

type Props = {
  boardGroupId: number;
  boardGroupName: string;
  boards: IBoard[];
  isGroupNameDisabled?: boolean;
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
};

export const BoardGroupAddItemModal: FC<Props> = ({
  boardGroupId,
  boardGroupName,
  boards,
  isLoading,
  isOpen,
  onClose,
}) => {
  const boardGroupAddItemMutation = useMutateBoardGroupAddItem();

  return (
    <Modal
      destroyOnClose
      footer={null}
      onCancel={onClose}
      open={isOpen}
      title={`Добавить доску в группу "${boardGroupName}"`}
    >
      <Formik<IBoardGroupAddItemRequest>
        enableReinitialize
        initialValues={boardGroupAddItemInitialValue}
        onSubmit={(values) =>
          boardGroupAddItemMutation.mutateAsync({ ...values, id: boardGroupId }).then(() => {
            onClose();
          })
        }
        validateOnBlur={false}
        validationSchema={boardGroupAddItemValidation}
      >
        {() => (
          <Form className="gap-4 md:gap-6" layout="vertical">
            <FormItem label="Доски" name="items">
              <Select
                loading={isLoading}
                name="chessBoardId"
                optionFilterProp="label"
                options={
                  boards.map((tag) => ({
                    label: tag.localizations.en.name,
                    value: tag.id,
                  })) || []
                }
                placeholder="Доски"
                tagRender={
                  isLoading
                    ? () => (
                        <Tag>
                          <LoadingOutlined />
                        </Tag>
                      )
                    : undefined
                }
              />
            </FormItem>
            <div className="flex gap-4 justify-end">
              <Button onClick={onClose}>Отмена</Button>
              <Button
                htmlType="submit"
                loading={boardGroupAddItemMutation.isLoading}
                type="primary"
              >
                <span className="max-w-full overflow-ellipsis overflow-hidden">Добавить</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
