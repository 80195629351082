/**
 * ⚠️ DONT EDIT MANUALLY
 * This file is generated with `./src/cli/generateIcons.js`, check it for the detailed information
 */
import { FC } from 'react';

import { ReactComponent as MdVideoSettingsIconSvg } from '@assets/icons/md-video-settings.svg';

import BaseIcon from './_BaseIcon';

type Props = React.ComponentPropsWithoutRef<'span'>;

export const MdVideoSettingsIcon: FC<Props> = (props) => {
  return <BaseIcon component={MdVideoSettingsIconSvg} style={{ fontSize: '1.5em' }} {...props} />;
};
