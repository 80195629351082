import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { preSubscribersQueryKeys } from '@settings/preSubscribers/api/preSubscribersQueryKeys';
import { IPreSubscriber } from '@settings/preSubscribers/types/IPreSubscriber';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

export type IPreSubscribersRequest = IPageAbleRequest & {
  email?: string;
};

type IPreSubscribersResponse = AxiosResponse<IPageAbleResponse<IPreSubscriber>>;

export const preSubscribersRequest = async (
  params?: IPreSubscribersRequest,
): Promise<IPreSubscribersResponse> => {
  return await axios.get('/pre-subscribe', { params });
};

export function useQueryPreSubscribers(params?: IPreSubscribersRequest) {
  return useQuery<IPreSubscribersResponse, AxiosError>(
    [preSubscribersQueryKeys.preSubscribers, params],
    () => preSubscribersRequest(params),
  );
}
