import { FC, useEffect, useState } from 'react';
import { Button, Modal, Tabs } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { TbVideoIcon, VscPlayIcon } from '@components/atoms/icons';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { TitleAndText } from '@components/atoms/TitleAndText';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';
import { useQueryVideo } from '@videos/api/useQueryVideo';
import { VideoViewLocalization } from '@videos/components/molecules/VideoViewLocalization';
import { VideoStatusEnum } from '@videos/types/enums/VideoStatusEnum';

export const VideoViewModal: FC<{
  id?: number;
  isOpen: boolean;
  onCancel: () => void;
  openEdit?: () => void;
}> = ({ id = 0, isOpen, onCancel, openEdit }) => {
  const videoQuery = useQueryVideo({ id });
  const video = videoQuery.data?.data;

  const [activeTab, setActiveTab] = useState('en');

  useEffect(() => {
    setActiveTab('en');
  }, [isOpen]);

  return (
    <Modal
      className="px-4 md:px-0 md:w-4/5 lg:w-3/4"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      style={{ top: 40 }}
    >
      {videoQuery.isFetching ? (
        <BaseSkeleton />
      ) : !video ? (
        <NotFoundResult title="Видео не найдено" />
      ) : (
        <div className="gap-6">
          <div className="flex flex-col sm:flex-row gap-4 p-4">
            <h2 className="text-2xl">Видео #{id}</h2>
            {openEdit && (
              <>
                <Button className="hidden md:block" icon={<EditOutlined />} onClick={openEdit}>
                  Редактировать
                </Button>

                <Button className="md:hidden" icon={<EditOutlined />} onClick={openEdit} />
              </>
            )}
          </div>

          <div className="divide-y">
            <TitleAndText
              text={
                video.isCartoon ? (
                  <span className="flex items-center gap-1 text-ant-magenta-6">
                    <VscPlayIcon className="text-base" />
                    Мультфильм
                  </span>
                ) : (
                  <span className="flex items-center gap-1 text-ant-purple-6">
                    <TbVideoIcon className="text-base" />
                    Видеоурок
                  </span>
                )
              }
              title="Тип видео"
            />
            {!video.isCartoon && (
              <>
                <TitleAndText
                  text={
                    video.tags?.length ? (
                      <div className="space-y-2">
                        {video.tags.map((tag) => (
                          <p className="m-0" key={`videoTag${tag.id}`}>
                            {tag.localizations.en}
                          </p>
                        ))}
                      </div>
                    ) : (
                      '-'
                    )
                  }
                  title="Темы"
                />

                <TitleAndText
                  text={
                    <p className="mb-0.5">
                      {video.playLevel ? userPlayLevels[video.playLevel] : ''}
                    </p>
                  }
                  title="Уровень"
                />
              </>
            )}

            <TitleAndText text={video.price} title="Стоимость" />

            <TitleAndText text={video.viewTotal} title="Общее кол-во просмотров" />

            <TitleAndText text={VideoStatusEnum[video.status]} title="Статус" />
          </div>

          {video.isCartoon ? (
            <h2 className="flex items-center gap-2 text-ant-magenta-6 p-4 text-xl">
              <VscPlayIcon className="text-base" />
              Мультфильм
            </h2>
          ) : (
            <span className="flex items-center gap-2 text-ant-purple-6 p-4 text-xl">
              <TbVideoIcon /> Видеоурок
            </span>
          )}

          <Tabs
            activeKey={activeTab}
            destroyInactiveTabPane
            items={[
              {
                children: <VideoViewLocalization localization="en" video={video} />,
                className: 'pl-0 pb-4',
                key: 'en',
                label: <CountryImageText src="en" width={20} />,
              },
              {
                children: <VideoViewLocalization localization="ru" video={video} />,
                className: 'pl-0 pb-4',
                key: 'ru',
                label: <CountryImageText src="ru" width={20} />,
              },
              { children: null, key: '', label: '' },
            ]}
            onChange={(active) => setActiveTab(active)}
            tabPosition="left"
            type="line"
          />
        </div>
      )}
    </Modal>
  );
};
