import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { PencilIcon } from '@components/atoms/icons/PencilIcon';
import { BattlePlayLevelEnum } from '@settings/appSettings/types/enums/BattlePlayLevelEnum';

import { AppSettingNameEnum, AppSettingsNameEnumKeys } from '../../types/enums/AppSettingNameEnum';
import { appSettingKeys, IAppSetting } from '../../types/IAppSetting';

export const appSettingsColumns = ({
  canEdit,
  onClickEdit,
}: {
  canEdit: boolean;
  onClickEdit: ({ name }: { name: AppSettingsNameEnumKeys }) => void;
}): ColumnsType<IAppSetting> => {
  const columns: ColumnsType<IAppSetting> = [
    {
      dataIndex: appSettingKeys.name,
      render: (name: IAppSetting['name']) => AppSettingNameEnum[name],
      title: 'Настройка',
    },
    {
      dataIndex: appSettingKeys.string,
      render: (string: IAppSetting['string'], appSetting) => {
        if (appSetting.name == 'PLAY_LEVEL_ENGINE_RATING_MAP') {
          return typeof appSetting.variables == 'object' ? (
            <ul>
              {Object.entries(appSetting.variables || {}).map(([engine_level, rating]) => (
                <li
                  key={`engine_level_${appSetting.name}_${engine_level}`}
                >{`${engine_level}. ${rating}`}</li>
              ))}
            </ul>
          ) : (
            '-'
          );
        } else if (appSetting.name == 'PLAY_LEVEL_ENGINE_LEVEL_MAP') {
          return typeof appSetting.variables == 'object' ? (
            <ul>
              {Object.entries(appSetting.variables || {}).map(([engine_level, levels]) => (
                <li key={`engine_level_${appSetting.name}_${engine_level}`}>{`${engine_level}. ${
                  Array.isArray(levels)
                    ? `IOS: ${levels[0] || '0'}, Android: ${levels[1] || '0'}`
                    : ''
                }`}</li>
              ))}
            </ul>
          ) : (
            '-'
          );
        } else if (appSetting.name == 'BATTLE_ENGINE_PLAY_LEVEL_MAP') {
          return typeof appSetting.variables == 'object' ? (
            <ul className="list-none pl-0">
              {Object.entries(appSetting.variables || {}).map(([engine_level, levels]) => (
                <li key={`engine_level_${appSetting.name}_${engine_level}`}>
                  <span className="font-bold">{`${BattlePlayLevelEnum[engine_level]}:`} </span>
                  {Array.isArray(levels)
                    ? `IOS: ${levels[0] || '0'}, Android: ${levels[1] || '0'}`
                    : ''}
                </li>
              ))}
            </ul>
          ) : (
            '-'
          );
        } else if (Object.keys(AppSettingNameEnum).includes(appSetting.name)) {
          return string;
        }
        return '-';
      },
      title: 'Значение',
    },
  ];

  if (!canEdit) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: (appSetting: IAppSetting) => (
        <div className="flex gap-x-1.5">
          <Button
            ghost
            icon={<PencilIcon />}
            onClick={() => onClickEdit({ name: appSetting.name })}
            type="primary"
          />
        </div>
      ),
      title: 'Действия',
    },
  ];
};
