import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment/moment';
import { number, object, ObjectSchema } from 'yup';

import { appPromotionsQueryKeys } from '@shop/appPromotions/api/appPromotionsQueryKeys';
import {
  appPromotionCreateInitial,
  appPromotionCreateValidation,
  IAppPromotionCreateRequest,
} from '@shop/appPromotions/api/useMutateAppPromotionCreate';
import { IAppPromotion } from '@shop/appPromotions/types/IAppPromotion';

export type IAppPromotionEditRequest = IAppPromotionCreateRequest & Pick<IAppPromotion, 'id'>;

type IAppPromotionEditResponse = AxiosResponse<void>;

export const appPromotionEditInitial: IAppPromotionEditRequest = {
  ...appPromotionCreateInitial,
  id: 0,
};

export const appPromotionEditValidation: ObjectSchema<IAppPromotionEditRequest> =
  appPromotionCreateValidation.concat(
    object({
      id: number().nullable().required(' '),
    }),
  );

export const appPromotionEditRequest = async (
  payload: IAppPromotionEditRequest,
): Promise<IAppPromotionEditResponse> => {
  return await axios.patch(`iap-promotions/${payload.id}`, {
    ...payload,
    endDate: payload.endDate ? moment(payload.endDate).format('YYYY-MM-DD') : payload.endDate,
    id: undefined,
    productIds: undefined,
    products: payload.productIds.map((id) => ({
      id,
    })),
    startDate: payload.startDate
      ? moment(payload.startDate).format('YYYY-MM-DD')
      : payload.startDate,
  });
};

export function useMutateAppPromotionEdit() {
  const queryClient = useQueryClient();

  return useMutation<IAppPromotionEditResponse, AxiosError, IAppPromotionEditRequest>(
    appPromotionEditRequest,
    {
      onSuccess: () => queryClient.invalidateQueries(appPromotionsQueryKeys.appPromotions),
    },
  );
}
