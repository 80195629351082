import { useEffect } from 'react';
import { Modal } from 'antd';
import LichessPgnViewer from 'lichess-pgn-viewer';

import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { ChessPgnViewer } from '@components/atoms/ChessPgnViewer';
import { NotFoundResult } from '@components/atoms/NotFoundResult';

import { useQueryMatchPvp } from '../../api/userQueryMatchPvp';

const gameMatchViewerId = 'game-match-viewer';
export default function ViewMatchPvpModal({
  id,
  isOpen,
  onClose,
}: {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
}) {
  const matchQuery = useQueryMatchPvp({ id });
  const match = matchQuery.data?.data;

  useEffect(() => {
    if (match) {
      setTimeout(() => {
        const pgnViewerElement = document.querySelector(`#${gameMatchViewerId}`);

        if (pgnViewerElement) {
          LichessPgnViewer(pgnViewerElement as HTMLElement, {
            pgn: match.movesPgn,
          });
        }
      }, 200);
    }
  }, [match]);

  return (
    <Modal destroyOnClose footer={null} onCancel={onClose} open={isOpen} width={850}>
      {matchQuery.isFetching && <BaseSkeleton />}

      {matchQuery.isFetching ? (
        <BaseSkeleton />
      ) : !match ? (
        <NotFoundResult title="Доска не найдена" />
      ) : (
        <>
          <div className="flex flex-col sm:flex-row gap-4">
            <h2 className="text-2xl">Игра #{match.id}</h2>
          </div>
          {/*<div className="p-4 space-y-4 min-w-[350px]">*/}
          {/*<TitleAndText light text={match.whitePlayer?.nickname} title="Белые" />*/}
          {/*<TitleAndText light text={match.blackPlayer?.nickname} title="Черные" />*/}
          {/*<TitleAndText*/}
          {/*  light*/}
          {/*  text={*/}
          {/*    match.winnerColor == 'white'*/}
          {/*      ? 'Белые'*/}
          {/*      : match.winnerColor == 'black'*/}
          {/*      ? 'Черные'*/}
          {/*      : ''*/}
          {/*  }*/}
          {/*  title="Победа"*/}
          {/*/>*/}
          {/*</div>*/}
          <ChessPgnViewer className="mt-4" id={gameMatchViewerId} sideHeight={475} width={475} />
        </>
      )}
    </Modal>
  );
}
