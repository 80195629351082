import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { notify } from '@utils/notify';
import { videoQueryKeys } from '@videos/api/videoQueryKeys';

type IVideoDeleteRequest = { id: number };

type IVideoDeleteResponse = AxiosResponse<void>;

export const videoDeleteRequest = async ({
  id,
}: IVideoDeleteRequest): Promise<IVideoDeleteResponse> => {
  return await axios.delete(`/videos/${id}`);
};

export function useMutateVideoDelete() {
  const queryClient = useQueryClient();

  return useMutation<IVideoDeleteResponse, AxiosError, IVideoDeleteRequest>(videoDeleteRequest, {
    onSuccess: (data, variables) => {
      notify('error', 'Видео удалено');

      queryClient.refetchQueries(videoQueryKeys.videos);
      queryClient.refetchQueries([videoQueryKeys.video, variables.id]);
    },
  });
}
