import { FC } from 'react';
import { Tabs } from 'antd';

import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { ITagsRequest } from '@tags/api/useQueryTags';
import { tagsFilters } from '@tags/consts/tagsFilters';
import { TagsPageActions, tagsPageActions } from '@tags/consts/TagsPageActions';

import { TabTags } from '../organisms/TabTags';

const TagsPage: FC = () => {
  const { actioned, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    ITagsRequest,
    TagsPageActions
  >(tagsFilters, tagsPageActions);

  return (
    <>
      <PageMeta selectedMenuKeys={['tags']} title="Теги" />

      <Tabs
        activeKey={actioned.type}
        centered
        destroyInactiveTabPane
        items={[
          {
            children: (
              <TabTags
                filtered={filtered}
                onFilter={onFilter}
                paramed={actioned}
                setParamed={setActioned}
                type="TOPIC"
              />
            ),
            key: 'TOPIC',
            label: 'Темы',
          },
          {
            children: (
              <TabTags
                filtered={filtered}
                onFilter={onFilter}
                paramed={actioned}
                setParamed={setActioned}
                type="AUTHOR"
              />
            ),
            key: 'AUTHOR',
            label: 'Авторы',
          },
        ]}
        onChange={(tab) => {
          setActioned({ type: tab == 'AUTHOR' ? 'AUTHOR' : 'TOPIC' });
          onFilter({ page: undefined });
        }}
      />
    </>
  );
};

export default TagsPage;
