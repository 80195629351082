import { InputFilter } from '@components/molecules/filters/static/InputFilter';
import { RadioFilter } from '@components/molecules/filters/static/RadioFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { IFilters } from '@typess/IFilters';

import { IEnumMatchResultType } from '../../types/enums/IEnumMatchResultType';
import { IEnumMatchSubType } from '../../types/enums/IEnumMatchSubType';
import { IEnumMatchType } from '../../types/enums/IEnumMatchType';
import { matchesPvpKeys } from '../../types/IMatchPvp';

export const matchesPvpFilters: IFilters = {
  ...paginationBaseFilters,
  [matchesPvpKeys.id]: {
    sort: true,
  },
  [matchesPvpKeys.beginDate]: {
    sort: true,
  },
  [matchesPvpKeys.endDate]: {
    sort: true,
  },
  [matchesPvpKeys.numberOfMoves]: {
    sort: true,
  },
  [matchesPvpKeys.gameDurationMillis]: {
    sort: true,
  },
  [matchesPvpKeys.winnerColor]: {
    sort: true,
  },
  [matchesPvpKeys.rated]: {
    input: (
      <RadioFilter
        options={[
          { label: 'Да', value: true },
          { label: 'Нет', value: false },
        ]}
      />
    ),
    queries: [{ key: 'isRated' }],
    sort: true,
  },
  [matchesPvpKeys.whitePlayer]: {
    input: <InputFilter placeholder="Введите ID пользователя" />,
    queries: [{ key: 'customerId' }],
  },
  [matchesPvpKeys.resultType]: {
    input: (
      <RadioFilter
        options={Object.entries(IEnumMatchResultType).map(([value, label]) => ({ label, value }))}
      />
    ),
  },
  [matchesPvpKeys.type]: {
    input: (
      <RadioFilter
        options={Object.entries(IEnumMatchType).map(([value, label]) => ({ label, value }))}
      />
    ),
  },
  [matchesPvpKeys.subType]: {
    input: (
      <RadioFilter
        options={Object.entries(IEnumMatchSubType).map(([value, label]) => ({ label, value }))}
      />
    ),
  },
  sortBy: { queries: [{ default: matchesPvpKeys.beginDate }] },
  sortDirection: { queries: [{ default: 'DESC' }] },
};
