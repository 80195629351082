import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { exerciseGroupQueryKeys } from '@exercises/exerciseGroups/api/exerciseGroupQueryKeys';
import { IExerciseGroup } from '@exercises/exerciseGroups/types/IExerciseGroup';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

export type IExerciseGroupsRequest = IPageAbleRequest & { name?: string };

type IExerciseGroupsResponse = AxiosResponse<IPageAbleResponse<IExerciseGroup>>;

export const exerciseGroupsRequest = async (
  params?: IExerciseGroupsRequest,
): Promise<IExerciseGroupsResponse> => await axios.get('/exercise-groups', { params });

export function useQueryExerciseGroups(params?: IExerciseGroupsRequest) {
  return useQuery<IExerciseGroupsResponse, AxiosError>(
    [exerciseGroupQueryKeys.exercisesGroups, params],
    () => exerciseGroupsRequest(params),
  );
}
