import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { exerciseQueryKeys } from '@exercises/exercises/api/exerciseQueryKeys';
import { IExercise } from '@exercises/exercises/types/IExercise';

export type ExerciseStatusUpdateRequest = Pick<IExercise, 'id' | 'status'>;

type ExerciseStatusUpdateResponse = AxiosResponse<void>;

export const exerciseStatusUpdateRequest = async ({
  id,
  ...payload
}: ExerciseStatusUpdateRequest): Promise<ExerciseStatusUpdateResponse> => {
  return await axios.patch(`/exercises/${id}`, payload);
};

export function useMutateExerciseStatusUpdate() {
  const queryClient = useQueryClient();

  return useMutation<ExerciseStatusUpdateResponse, AxiosError, ExerciseStatusUpdateRequest>(
    exerciseStatusUpdateRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(exerciseQueryKeys.exercises);
        queryClient.invalidateQueries(exerciseQueryKeys.exercise);
      },
    },
  );
}
