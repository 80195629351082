import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';

import { dashboardQueryKeys } from './dashboardQueryKeys';

export type IUsersMonthlyStatsRequest = {
  from?: Dayjs | null;
  to?: Dayjs | null;
};

type IUsersMonthlyStatsResponse = AxiosResponse<
  Array<{
    activeUsersCount: number;
    deletedUsersCount: number;
    monthNum: number;
    yearNum: number;
  }>
>;

export const usersMonthlyStatsRequest = async (
  params?: IUsersMonthlyStatsRequest,
): Promise<IUsersMonthlyStatsResponse> => {
  return await axios.get('/stats/monthly-users', { params });
};

export function useQueryUsersMonthlyStats(params?: IUsersMonthlyStatsRequest) {
  return useQuery<IUsersMonthlyStatsResponse, AxiosError>(
    [dashboardQueryKeys.usersMonthlyStats, params],
    () => usersMonthlyStatsRequest(params),
  );
}
