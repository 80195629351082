export type IUserCoinPurchase = {
  amount: number;
  eventTime: string;
  id: number;
  price: number;
};

export const userCoinPurchasesKeys: Record<keyof IUserCoinPurchase, string> = {
  amount: 'amount',
  eventTime: 'eventTime',
  id: 'id',
  price: 'price',
};
