import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum StaffsPageActionsEnum {
  'edit',
  'invite',
}

export type IStaffsPageActions = {
  action: keyof typeof StaffsPageActionsEnum;
  actionId: number;
};

export const staffsPageActions: IPageActions<IStaffsPageActions> = {
  action: {
    allowed: (value) => Object.keys(StaffsPageActionsEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
};
