import { isInteger } from 'formik';

import { TagTypeEnum, TagTypeEnumKeys } from '@tags/types/TagTypeEnum';
import { IPageActions } from '@typess/IPageActions';

enum TagsPageActionsEnum {
  'view',
  'create',
  'edit',
}

export type TagsPageActions = {
  action: keyof typeof TagsPageActionsEnum;
  actionId: number;
  type: TagTypeEnumKeys;
};

export const tagsPageActions: IPageActions<TagsPageActions> = {
  action: {
    allowed: (value) => Object.keys(TagTypeEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
  type: { allowed: (value) => Object.keys(TagTypeEnum).includes(value), default: 'TOPIC' },
};
