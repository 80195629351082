import { ExerciseStatusEnumKeys } from '@exercises/exercises/types/enums/ExerciseStatusEnum';
import { IExerciseTest } from '@exercises/exercises/types/IExerciseTest';
import { IStaff } from '@staff/types/IStaff';
import { IUserPlayLevelNumber } from '@users/types/enums/UserPlayLevel';

import { AssessmentExerciseTypeEnum } from './enums/AssessmentExerciseTypeEnum';
import { IAssessmentExerciseProblem } from './IAssessmentExerciesProblem';

export interface IAssessmentExercise {
  createdBy: IStaff;
  id: number;
  playLevel: IUserPlayLevelNumber;
  priority: number;
  problem?: IAssessmentExerciseProblem;
  problemChecked?: boolean;
  status: ExerciseStatusEnumKeys;
  test?: IExerciseTest;
  type: AssessmentExerciseTypeEnum;
}

export const assessmentExerciseKeys: Record<keyof IAssessmentExercise, string> = {
  createdBy: 'createdBy',
  id: 'id',
  playLevel: 'playLevel',
  priority: 'priority',
  problem: 'problem',
  problemChecked: 'problemChecked',
  status: 'status',
  test: 'test',
  type: 'type',
};
