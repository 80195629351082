import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

import { IChessPiecesGroup } from '../types/IChessPiecesGroup';
import { chessPiecesGroupQueryKeys } from './chessPiecesGroupQueryKeys';

export type IChessPiecesGroupsRequest = IPageAbleRequest & { name?: string };

type IChessPiecesGroupsResponse = AxiosResponse<IPageAbleResponse<IChessPiecesGroup>>;

export const chessPiecesGroupsRequest = async (
  params?: IChessPiecesGroupsRequest,
): Promise<IChessPiecesGroupsResponse> => await axios.get('/chess-piece-set-groups', { params });

export function useQueryChessPiecesGroups(params?: IChessPiecesGroupsRequest) {
  return useQuery<IChessPiecesGroupsResponse, AxiosError>(
    [chessPiecesGroupQueryKeys.chessPiecesGroups, params],
    () => chessPiecesGroupsRequest(params),
  );
}
