import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum BoardGroupsPageActionEnum {
  'edit',
  'view',
  'createBoard',
  'createBoardGroup',
}

export type IBoardGroupsPageActions = {
  action: keyof typeof BoardGroupsPageActionEnum;
  actionId: number;
};

export const boardGroupsPageActions: IPageActions<IBoardGroupsPageActions> = {
  action: {
    allowed: (value) => Object.keys(BoardGroupsPageActionEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
};
