import { IEnumMatchResultTypeKeys } from './enums/IEnumMatchResultType';
import { IEnumMatchSubTypeKeys } from './enums/IEnumMatchSubType';
import { IEnumMatchTypeKeys } from './enums/IEnumMatchType';
import { IUserPvpDetails } from './IUserPvpDetails';

export interface IMatchPvp {
  beginDate: string;
  blackPlayer: IUserPvpDetails;
  checkMate: boolean;
  endDate: string;
  endingGameFen?: string;
  fake: boolean;
  formattedGameDurationStr?: string;
  friend: boolean;
  gameDurationMillis: number;
  id: string;
  loserId: number;
  movesPgn?: string;
  movesUci?: string;
  numberOfMoves: number;
  rated: boolean;
  resultType?: IEnumMatchResultTypeKeys;
  subType?: IEnumMatchSubTypeKeys;
  type?: IEnumMatchTypeKeys;
  whitePlayer: IUserPvpDetails;
  winnerColor?: string;
  winnerId: number;
}

export const matchesPvpKeys: Record<keyof IMatchPvp, string> = {
  beginDate: 'beginDate',
  blackPlayer: 'blackPlayer',
  checkMate: 'checkMate',
  endDate: 'endDate',
  endingGameFen: 'endingGameFen',
  fake: 'fake',
  formattedGameDurationStr: 'formattedGameDurationStr',
  friend: 'friend',
  gameDurationMillis: 'gameDurationMillis',
  id: 'id',
  loserId: 'loserId',
  movesPgn: 'movesPgn',
  movesUci: 'movesUci',
  numberOfMoves: 'numberOfMoves',
  rated: 'rated',
  resultType: 'resultType',
  subType: 'subType',
  type: 'type',
  whitePlayer: 'whitePlayer',
  winnerColor: 'winnerColor',
  winnerId: 'winnerId',
};
