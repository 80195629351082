import { FC, useState } from 'react';
import { Button } from 'antd';

import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { ExerciseTabs } from '@exercises/exercises/components/molecules/ExerciseTabs';
import { useMutateFenStatusUpdate } from '@exercises/fens/api/useMutateFenStatusUpdate';
import { IFensRequest, useQueryFens } from '@exercises/fens/api/useQueryFens';
import { FenCreateModal } from '@exercises/fens/components/organisms/FenCreateModal';
import { FenEditModal } from '@exercises/fens/components/organisms/FenEditModal';
import { FenViewModal } from '@exercises/fens/components/organisms/FenViewModal';
import { fenColumns } from '@exercises/fens/consts/columns/fenColumns';
import { fenFilters } from '@exercises/fens/consts/filters/fenFilters';
import {
  FensPageActions,
  fensPageActions,
} from '@exercises/fens/consts/pageActions/FensPageActions';
import { IFen } from '@exercises/fens/types/IFen';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { exercisePermissions } from '@typess/permissions/IExercisePermission';
import { checkEditPermission } from '@utils/checkEditPermission';

const FensPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IFensRequest,
    FensPageActions
  >(fenFilters, fensPageActions);

  const fens = useQueryFens(filtered);
  const fenStatusUpdate = useMutateFenStatusUpdate();

  const [fen, setFen] = useState<undefined | IFen>();

  const canEditExercises = checkEditPermission(exercisePermissions);

  return (
    <div>
      <PageMeta
        selectedMenuKeys={['exercises']}
        subTitle={
          actioned.action == 'create'
            ? 'Создать FEN'
            : actioned.action == 'edit'
            ? `Изменить FEN #${actioned.actionId}`
            : ''
        }
        title="FEN"
      />

      <ExerciseTabs active="fens" />

      {canEditExercises && (
        <div className="flex justify-end gap-3">
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'create' })}
            size="large"
          >
            Создать FEN
          </Button>
        </div>
      )}

      <AdminTable
        columns={fenColumns({
          canEdit: canEditExercises,
          onClickDelete: ({ id }) => fenStatusUpdate.mutateAsync({ id, status: 'DELETED' }),
          onClickEdit: (fen) => {
            setFen(fen);
            setActioned({ action: 'edit', actionId: fen.id });
          },
          onClickPause: fenStatusUpdate.mutateAsync,
          onClickView: (fen) => {
            setFen(fen);
            setActioned({ action: 'view', actionId: fen.id });
          },
        })}
        emptyDescription={
          <div>
            <p>FEN не найдены.</p>
            <p>Вы можете создать задачу, нажав на кнопку “Создать FEN”</p>
          </div>
        }
        query={fens}
        tableFilter={{ filtered, filters: fenFilters, onFilter }}
      />

      <FenViewModal
        fen={fen}
        isOpen={actioned.action == 'view' && !!fen}
        onCancel={() => {
          setFen(undefined);
          clearParams();
        }}
        openEdit={() => setActioned({ action: 'edit', actionId: actioned.actionId })}
      />

      <FenCreateModal isOpen={actioned.action == 'create'} onCancel={clearParams} />

      <FenEditModal
        fen={fen}
        isOpen={actioned.action == 'edit' && !!fen}
        onCancel={() => {
          setFen(undefined);
          clearParams();
        }}
      />
    </div>
  );
};

export default FensPage;
