import type { FC } from 'react';
import { Image, Modal } from 'antd';

import { useQueryLecturerCard } from '@collections/lecturer-cards/api/useQueryLecturerCard';
import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { Description } from '@components/atoms/Description';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { TitleAndText } from '@components/atoms/TitleAndText';

type ViewLecturerCardModalProps = {
  id?: number;
  isOpen: boolean;
  onClose: () => void;
};

export const ViewLecturerCardModal: FC<ViewLecturerCardModalProps> = ({
  id = 0,
  isOpen,
  onClose,
}) => {
  const cardQuery = useQueryLecturerCard({ id });
  const card = cardQuery.data?.data;

  return (
    <Modal footer={null} onCancel={onClose} open={isOpen} width={720}>
      {cardQuery.isFetching ? (
        <BaseSkeleton />
      ) : !card ? (
        <NotFoundResult title="Карточка лектора не найдена" />
      ) : (
        <>
          <div className="flex flex-col sm:flex-row gap-4">
            <h2 className="text-2xl">Карточка лектора #{card.id}</h2>
          </div>
          <TitleAndText
            text={
              <div className="flex flex-row items-center">
                <Image className="rounded-full shadow-lg" src={card.authorTag.imgUrl} width={100} />
                <div className="ml-2">
                  <CountryImageText src="en" text={card.authorTag?.localizations.en || '-'} />
                  <CountryImageText src="ru" text={card.authorTag?.localizations.ru || '-'} />
                </div>
              </div>
            }
            title="Лектор"
          />
          <TitleAndText
            text={<img className="max-w-[300px] max-h-[300px]" src={card?.imgUrl} />}
            title="Фоновая картинка"
          />

          <div className="pl-3 pb-4">
            <Description
              description={
                <CountryImageText src="ru" text={card.localizations?.ru?.title || '-'} />
              }
              title="Заголовок на русском"
            />
            <Description
              description={
                <CountryImageText src="ru" text={card.localizations?.ru?.description || '-'} />
              }
              title="Описание на русском"
            />
            <Description
              description={
                <CountryImageText src="en" text={card.localizations?.en?.title || '-'} />
              }
              title="Заголовок на английском"
            />
            <Description
              description={
                <CountryImageText src="en" text={card.localizations?.en?.description || '-'} />
              }
              title="Описание на английском"
            />
          </div>
        </>
      )}
    </Modal>
  );
};
