import { FC } from 'react';
import { Card } from 'antd';
import { FormItem, Input } from 'formik-antd';

import { CountryImageText } from '@components/atoms/CountryImageText';
import { ILocalization } from '@typess/ILocalization';

type CreateLecturerLocaleFormCardType = {
  descriptionsNum?: number;
  localization: ILocalization;
  setDescriptionsNum?: (descriptionsNum: number) => void;
};

export const CreateLecturerLocaleFormCard: FC<CreateLecturerLocaleFormCardType> = ({
  localization,
}) => {
  return (
    <Card
      style={{ margin: '16px 0' }}
      title={<CountryImageText src={localization} width={20} />}
      type="inner"
    >
      <FormItem
        key={`localizations.${localization}.title`}
        label="Заголовок"
        name={`localizations.${localization}.title`}
      >
        <div className="flex flex-row">
          <Input name={`localizations.${localization}.title`} prefix showCount />
        </div>
      </FormItem>
      <FormItem
        key={`localizations.${localization}.description`}
        label="Описание"
        name={`localizations.${localization}.description`}
      >
        <div className="flex flex-row">
          <Input name={`localizations.${localization}.description`} prefix showCount />
        </div>
      </FormItem>
    </Card>
  );
};
