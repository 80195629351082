import { FC, useMemo, useState } from 'react';
import { Card, DatePicker, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import moment from 'moment';
import { ColumnConfig } from '@ant-design/charts';
import { Column } from '@ant-design/plots';

import { PageMeta } from '@context/PageMetaContext';
import { useQueryUsersMonthlyStats } from '@dashboards/api/useQueryUsersMonthlyStats';

import { DashboardTabs } from '../molecules/DashboardTabs';
const { RangePicker } = DatePicker;

const ChangesDashboardPage: FC = () => {
  const [min, setMin] = useState<Dayjs>();
  const [max, setMax] = useState<Dayjs>();
  const { data, isLoading } = useQueryUsersMonthlyStats({ from: min, to: max });

  const sortedArrayByDates = data
    ? data.data.slice(0, data.data.length - 1)?.sort((a, b) => {
        if (a.yearNum === b.yearNum) {
          return a.monthNum - b.monthNum;
        }
        return a.yearNum - b.yearNum;
      })
    : [];

  const transformedData = sortedArrayByDates?.map((item) => ({
    date: `${moment()
      .month(item.monthNum - 1)
      .format('MMM')} ${item.yearNum}`,
    key: item,
    usersCount: item.activeUsersCount + item.deletedUsersCount,
  }));

  const config: ColumnConfig = {
    data: transformedData,
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    meta: {
      sales: {
        alias: 'пользователи',
      },
      type: {
        alias: 'блабла',
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    xField: 'date',
    yField: 'usersCount',
  };

  const handleCalendarChange = (val) => {
    if (val) {
      setMin(val[0] && val[0].format('YYYY-MM'));
      setMax(val[1] && val[1].format('YYYY-MM'));
    }
  };

  const lastItemIndex = useMemo(() => (data ? data?.data.length - 1 : 0), [data]);

  const minUserCount = useMemo(
    () =>
      data
        ? data?.data[lastItemIndex].activeUsersCount + data?.data[lastItemIndex].deletedUsersCount
        : 0,
    [data],
  );
  const maxUserCount = useMemo(
    () => (data ? data?.data[0].activeUsersCount + data?.data[0].deletedUsersCount : 0),
    [data],
  );

  return (
    <>
      <PageMeta selectedMenuKeys={['changes']} title="Изменения" />
      <DashboardTabs active="changes" />
      <div className="flex flex-row items-center justify-between w-full mb-6">
        <RangePicker onCalendarChange={(val) => handleCalendarChange(val)} picker="month" />
        <Typography>Всего за период: {maxUserCount - minUserCount}</Typography>
      </div>
      <Card className="w-[100%]">
        <Column {...config} loading={isLoading} />
      </Card>
    </>
  );
};

export default ChangesDashboardPage;
