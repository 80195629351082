import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { boolean, number, object, ObjectSchema, string } from 'yup';

import { videoQueryKeys } from '@videos/api/videoQueryKeys';
import { VideoStatusEnumKeys } from '@videos/types/enums/VideoStatusEnum';
import { IVideo } from '@videos/types/IVideo';
import { IVideoLocalized } from '@videos/types/IVideoLocalized';

export type IVideoStepOneCreateRequest = Pick<IVideo, 'isCartoon' | 'status'> & {
  localizations: Record<
    'ru' | 'en',
    Pick<
      IVideoLocalized,
      'title' | 'subtitlesUrl' | 'uuid' | 'previewUrl' | 'fullTitle' | 'descriptions'
    > & {
      authorTagId?: number | null;
    }
  >;
  price?: number;
};

type IVideoStepOneCreateResponse = AxiosResponse<IVideo>;

export const videoStepOneCreateInitial: IVideoStepOneCreateRequest = {
  isCartoon: false,
  localizations: {
    en: {
      authorTagId: undefined,
      fullTitle: '',
      previewUrl: '',
      subtitlesUrl: '',
      title: '',
      uuid: '',
    },
    ru: {
      authorTagId: undefined,
      fullTitle: '',
      previewUrl: '',
      subtitlesUrl: '',
      title: '',
      uuid: '',
    },
  },
  price: undefined,
  status: 'DRAFT',
};
const videoLocalizationValidation = object({
  authorTagId: number().nullable(),
  fullTitle: string().nullable().required('Название обязательно для заполнения'),
  previewUrl: string().nullable().required('Обложка видео обязательна для заполнения'),
  subtitlesUrl: string().nullable(),
  title: string()
    .nullable()
    .required('Название обязательно для заполнения')
    .max(60, 'Название видео должно быть не более 60 символов'),
  uuid: string().nullable().required(),
});
export const videoStepOneCreateValidation: ObjectSchema<IVideoStepOneCreateRequest> = object({
  isCartoon: boolean().required(),
  localizations: object({
    en: videoLocalizationValidation,
    ru: videoLocalizationValidation,
  }).when('isCartoon', {
    is: (isCartoon) => !isCartoon,
    then: (s) =>
      s.shape({
        en: videoLocalizationValidation.concat(
          object({
            authorTagId: number().nullable().required('Автор обязателен для заполнения'),
          }),
        ),
        ru: videoLocalizationValidation.concat(
          object({
            authorTagId: number().nullable().required('Автор обязателен для заполнения'),
          }),
        ),
      }),
  }),
  price: number().when('isCartoon', {
    is: (isCartoon) => isCartoon == true,
    otherwise: (s) => s.notRequired().nullable(),
    then: (s) =>
      s.nullable().required('Цена обязательна для заполнения').max(999999, 'Максимум 6 символов'),
  }),
  status: string<VideoStatusEnumKeys>().required(),
});

export const videoStepOneCreateRequest = async (
  payload: IVideoStepOneCreateRequest,
): Promise<IVideoStepOneCreateResponse> => {
  return await axios.post('/videos', payload);
};

export function useMutateVideoStepOneCreate() {
  const queryClient = useQueryClient();

  return useMutation<IVideoStepOneCreateResponse, AxiosError, IVideoStepOneCreateRequest>(
    videoStepOneCreateRequest,
    {
      onSuccess: () => queryClient.refetchQueries(videoQueryKeys.videos),
    },
  );
}
