import { FC, useEffect } from 'react';
import { Modal } from 'antd';
import LichessPgnViewer from 'lichess-pgn-viewer';

import { ChessPgnViewer } from '@components/atoms/ChessPgnViewer';
import { TitleAndText } from '@components/atoms/TitleAndText';
import { UserGameMatchOutcomeEnum } from '@users/types/enums/UserGameMatchOutcomeEnum';
import { IUserGameMatch } from '@users/types/IUserGameMatch';

type Props = { isOpen: boolean; match: IUserGameMatch | null; onCancel: () => void };

const pgnViewerId = 'game-desk';

export const HistoryGameModal: FC<Props> = ({ isOpen, match, onCancel }) => {
  const configureGameHistory = (match: IUserGameMatch) => {
    const pgnViewerElement = document.querySelector(`#${pgnViewerId}`);

    if (pgnViewerElement) {
      LichessPgnViewer(pgnViewerElement as HTMLElement, {
        pgn: match.pgnFormat,
      });
    }
  };

  useEffect(() => {
    if (match) {
      configureGameHistory(match);
    }
  }, [match]);

  return (
    <Modal
      bodyStyle={{ paddingTop: 24 }}
      className="px-4 top-5"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      width={850}
    >
      <TitleAndText text={match?.id ?? '-'} title="ID" />
      <TitleAndText
        text={match && match.outcome ? UserGameMatchOutcomeEnum[match.outcome] : '-'}
        title="Результат"
      />
      <TitleAndText text={match?.computerRatingElo ?? '-'} title="Рейтинг компьютера" />
      <TitleAndText text={match?.oldRating ?? '-'} title="Рейтинг пользователя до игры" />
      <TitleAndText text={match?.newRating ?? '-'} title="Рейтинг пользователя после игры" />
      <ChessPgnViewer className="mt-4" id={pgnViewerId} sideHeight={475} width={475} />
    </Modal>
  );
};
