import { FC } from 'react';
import { CheckCircleTwoTone } from '@ant-design/icons';

import { CountryImageText } from '@components/atoms/CountryImageText';
import { IExerciseTest } from '@exercises/exercises/types/IExerciseTest';
import { ILocalization } from '@typess/ILocalization';
import { IVideoTest } from '@videos/types/IVideoTest';

export const AssessmentQuestionAnswersCard: FC<{
  localization: ILocalization;
  test?: IExerciseTest | IVideoTest;
  text: string;
}> = ({ localization, test, text }) => {
  return (
    <div className="p-4 rounded-lg shadow-md border border-ant-gray-4">
      <div className="grid md:grid-cols-3 md:space-x-4 mb-2">
        <div className="font-semibold">
          <CountryImageText src={localization} text={text} width={15} />
        </div>
        <div className="col-span-2 text-gray-700 text-base whitespace-pre-line">
          <p>{test?.localizations[localization]?.question}</p>
        </div>
      </div>
      {test?.localizations[localization]?.answerOptions.map((answer, index) => (
        <div className="grid md:grid-cols-3 md:space-x-4" key={`answer${index}`}>
          <p className="font-semibold">
            Вариант {index + 1}
            {test?.correctOption == index + 1 && (
              <CheckCircleTwoTone className="ml-2" twoToneColor="#52c41a" />
            )}
          </p>
          <div className="col-span-2 text-base whitespace-pre-line text-gray-700">
            <p>{answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
