import { IPermissions } from '@typess/permissions/IPermissions';

export type IUserPermission =
  | 'PERM_CUSTOMER_EDIT'
  | 'PERM_CUSTOMER_RESTRICTED'
  | 'PERM_CUSTOMER_VIEW';

export const userPermissions: IPermissions = {
  edit: 'PERM_CUSTOMER_EDIT',
  restricted: 'PERM_CUSTOMER_RESTRICTED',
  view: 'PERM_CUSTOMER_VIEW',
};
