import { StaffStateEnumKeys } from '@staff/types/enums/StaffStateEnum';
import { IPermission } from '@typess/permissions/IPermission';

export interface IStaff {
  authorities?: IPermission[];
  email: string;
  fullName?: string;
  id: number;
  lastActivity?: Date;
  state: StaffStateEnumKeys;
  username: string;
}

export const staffKeys: Record<keyof IStaff, string> = {
  authorities: 'authorities',
  email: 'email',
  fullName: 'fullName',
  id: 'id',
  lastActivity: 'lastActivity',
  state: 'state',
  username: 'username',
};
