import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { notify } from '@utils/notify';
import { videoQueryKeys } from '@videos/api/videoQueryKeys';

export type IVideoFileDeleteRequest = { uuid: string };

type IVideoFileDeleteResponse = AxiosResponse<void>;

export const videoFileDeleteRequest = async ({
  uuid,
}: IVideoFileDeleteRequest): Promise<IVideoFileDeleteResponse> => {
  return await axios.delete(`videos/files/${uuid}`);
};

export function useMutateVideoFileDelete() {
  const queryClient = useQueryClient();

  return useMutation<IVideoFileDeleteResponse, AxiosError, IVideoFileDeleteRequest>(
    videoFileDeleteRequest,
    {
      onSuccess: () => {
        notify('warning', 'Видеофайл удален');
        queryClient.refetchQueries(videoQueryKeys.videoFiles);
        queryClient.refetchQueries(videoQueryKeys.videoFile);
      },
    },
  );
}
