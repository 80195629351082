import { FC, ReactNode, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Button, Tooltip, Upload } from 'antd';
import { RcFile, UploadChangeParam, UploadFile, UploadListType } from 'antd/lib/upload/interface';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { CopyOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';

import { useMutateFileUploadLink } from '@api/files/useMutateFileUploadLink';

type FileUploadType = {
  accept?: string;
  children?: ReactNode;
  compressImage?: boolean;
  defaultFileList?: UploadFile[] | undefined;
  folderName: string;
  icon?: ReactNode;
  isSvg?: boolean;
  label?: string;
  listType?: UploadListType;
  maxCount: number;
  onChange: (
    info: UploadChangeParam<UploadFile>,
    linkInfo: { downloadUrl?: string; uploadUrl?: string },
  ) => void;
  onRemove?: () => void;
};

export const FileUpload: FC<FileUploadType> = ({
  accept,
  compressImage = true,
  defaultFileList,
  folderName,
  icon = <UploadOutlined className="text-ant-gray-7" />,
  isSvg = false,
  label = 'Загрузить',
  listType = 'picture',
  maxCount = 1,
  onChange,
  onRemove,
}) => {
  const uploadLink = useMutateFileUploadLink();
  const [fileList, setFileList] = useState(defaultFileList);
  const [isCompressing, setIsCompressing] = useState(false);
  const isEmpty = !fileList?.length;

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
  };

  return (
    <Upload.Dragger
      accept={accept}
      action={(file) =>
        uploadLink
          .mutateAsync({ fileName: file.name, folderName: folderName })
          .then((upload) => upload.data.uploadUrl)
      }
      className={isEmpty ? '' : 'border-none upload-border-none'}
      customRequest={async (rcRequest) => {
        const file =
          compressImage &&
          rcRequest.file instanceof Blob &&
          ['image/gif', 'image/jpeg', 'image/png'].includes(rcRequest.file?.type) &&
          compressImage
            ? await imageCompression(rcRequest.file as RcFile, {
                alwaysKeepResolution: true,
                maxSizeMB: 0.2,
                onProgress: (progress) => setIsCompressing(progress < 100),
                useWebWorker: true,
              })
            : await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(rcRequest.file as RcFile);
                reader.onload = () => {
                  resolve(reader.result);
                };
                reader.onerror = (error) => reject(error);
              });
        axios
          .put(rcRequest.action, file, {
            headers: rcRequest.headers,
            onUploadProgress: (re) =>
              rcRequest.onProgress?.({ ...re, percent: (re.loaded * 100) / (re.total || 10000) }),
          })
          .then(rcRequest.onSuccess)
          .catch(rcRequest.onError);
      }}
      fileList={fileList}
      iconRender={
        isCompressing
          ? () => (
              <Tooltip title="Изображение сжимается">
                <CopyOutlined className="compressing" />
              </Tooltip>
            )
          : undefined
      }
      isImageUrl={() => true}
      itemRender={
        isSvg
          ? (originNode, file, fileList, actions) => {
              return file.url ? (
                <div
                  className="flex flex-row items-center justify-between border rounded-[8px] p-[5px] mt-2"
                  onClick={actions.preview}
                >
                  <div className="w-[48px]">
                    <ReactSVG src={file.url ?? ''} width="100%" />
                  </div>
                  <Button icon={<DeleteOutlined />} onClick={actions.remove} />
                </div>
              ) : (
                originNode
              );
            }
          : undefined
      }
      listType={listType}
      maxCount={maxCount}
      method="PUT"
      multiple={false}
      onChange={(info) => {
        setFileList(info.fileList);

        onChange(info, {
          downloadUrl: uploadLink.data?.data.downloadUrl,
          uploadUrl: uploadLink.data?.data.uploadUrl,
        });
      }}
      onPreview={handlePreview}
      onRemove={onRemove}
      progress={isCompressing ? { style: { display: 'none' } } : undefined}
      showUploadList={{
        downloadIcon: undefined,
        showDownloadIcon: false,
        showPreviewIcon: false,
      }}
    >
      {isEmpty && (
        <div className="p-2">
          <p className="ant-upload-drag-icon w-full h-full">{icon}</p>
          {label}
        </div>
      )}
    </Upload.Dragger>
  );
};
