import { FC, useState } from 'react';

import { useQueryCountries } from '@api/country/useQueryCountries';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { SelectMultipleFilter } from '@components/molecules/filters/static/SelectMultipleFilter';
import { FilterRender } from '@typess/IFilters';

interface CountriesSelectMultipleInterface {
  filterRender?: FilterRender;
}

export const CountriesSelectMultipleFilter: FC<CountriesSelectMultipleInterface> = ({
  filterRender,
}) => {
  const [countryName, setCountryName] = useState('');
  const countries = useQueryCountries({ name: countryName });
  const countriesData = countries.data?.data;

  const options =
    countriesData?.content.map((country) => ({
      label: <CountryImageText src={country.iso} text={`${country.name.ru} (${country.iso})`} />,
      value: country.id.toString(),
    })) || [];

  return (
    <SelectMultipleFilter
      filterOption={false}
      filterRender={filterRender}
      loading={countries.isLoading}
      onSearch={(searchValue) => setCountryName(searchValue)}
      options={options}
      placeholder="Выберите страну"
    />
  );
};
