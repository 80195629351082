import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum ShopPageActionsEnum {
  'create',
  'edit',
}
export type ShopPageActions = {
  action: keyof typeof ShopPageActionsEnum;
  actionId: number;
  type: 'products' | 'campaigns';
};

export const shopPageParams: IPageActions<ShopPageActions> = {
  action: {
    allowed: (value) => Object.keys(ShopPageActionsEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
  type: { allowed: (value) => ['products', 'campaigns'].includes(value), default: 'products' },
};
