import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { dashboardQueryKeys } from './dashboardQueryKeys';

export type IUsersMapStagePassedStatsRequest = {
  from?: string;
  to?: string;
};

type IUsersMapStagePassedStatsResponse = AxiosResponse<void>;

export const usersMapStagePassedStatsRequest = async (
  params?: IUsersMapStagePassedStatsRequest,
): Promise<IUsersMapStagePassedStatsResponse> => {
  return await axios.get('/stats/map-stages', { params });
};

export function useQueryUsersMapStagePassedStats(params?: IUsersMapStagePassedStatsRequest) {
  return useQuery<IUsersMapStagePassedStatsResponse, AxiosError>(
    [dashboardQueryKeys.usersMapStagePassedStats, params],
    () => usersMapStagePassedStatsRequest(params),
  );
}
