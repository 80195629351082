import { FC } from 'react';
import { Button, Modal, Switch as AntSwitch, Upload } from 'antd';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';
import { LinkOutlined, UploadOutlined } from '@ant-design/icons';

import { FaChessKnightIcon } from '@components/atoms/icons';
import {
  assessmentExerciseProblemCreateInitial,
  assessmentExerciseProblemCreateValidation,
  IAssessmentExerciseProblemCreateRequest,
  useMutateAssessmentExerciseProblemCreate,
} from '@exercises/levelTesting/api/useMutateAssessmentExerciseProblemCreate';
import { assessmentExerciseKeys } from '@exercises/levelTesting/types/IAssessmentExercise';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';
import { notify } from '@utils/notify';

export const AssessmentExerciseProblemCreateModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
}> = ({ isOpen, onCancel }) => {
  const problemCreate = useMutateAssessmentExerciseProblemCreate();

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <h1 className="text-xl m-0 flex items-center gap-1">
          <FaChessKnightIcon /> Загрузить PGN
        </h1>
      }
    >
      <Formik<IAssessmentExerciseProblemCreateRequest>
        initialValues={assessmentExerciseProblemCreateInitial}
        onSubmit={(values, { resetForm, setFieldError }) => {
          problemCreate
            .mutateAsync(values)
            .then((data) => {
              if (data.status == 201 || data.status == 200) {
                notify('success', 'PGN загружен');
                onCancel();
                resetForm();
              }
            })
            .catch((e: AxiosError) => {
              if (e.response?.status == 422) {
                setFieldError('file', 'Неверный формат pgn файла');
              }
            });
        }}
        validationSchema={assessmentExerciseProblemCreateValidation}
      >
        {({ errors, setFieldValue, touched, values }) => (
          <Form className="mt-8 space-y-8" layout="vertical">
            <FormItem label="PGN" name="file">
              <Upload.Dragger
                accept=".pgn"
                beforeUpload={(file) => {
                  setFieldValue('file', file);
                  return false;
                }}
                className={errors.file ? 'border-ant-red-5' : ''}
                multiple={false}
                name="file"
                showUploadList={false}
              >
                <p className="ant-upload-drag-icon">
                  <UploadOutlined
                    className={
                      errors.file ? 'text-ant-red-5' : values.file ? '' : 'text-ant-gray-5'
                    }
                  />
                </p>

                {values.file ? (
                  <p
                    className={`font-bold truncate ${
                      errors.file ? 'text-ant-red-5' : 'text-ant-blue-5'
                    }`}
                  >
                    <LinkOutlined className="mr-1" />
                    {values.file.name}
                  </p>
                ) : (
                  <p className={touched.file && errors.file ? 'text-ant-red-3' : 'ant-upload-hint'}>
                    Загрузите PGN
                  </p>
                )}
              </Upload.Dragger>
            </FormItem>

            <FormItem label="Уровень" name={`json.${assessmentExerciseKeys.playLevel}`}>
              <Select
                name={`json.${assessmentExerciseKeys.playLevel}`}
                optionFilterProp="label"
                options={Object.keys(userPlayLevels)
                  .map(Number)
                  .map((playLevel) => ({
                    label: userPlayLevels[playLevel],
                    value: playLevel,
                  }))}
                placeholder="Уровень"
                showSearch={false}
              />
            </FormItem>

            <div className="grid md:grid-cols-5 place-items-center text-center">
              <FormItem
                className="col-span-full"
                label={<span className="text-center">Запустить немедленно</span>}
                name={`json.${assessmentExerciseKeys.status}`}
              >
                <Input hidden name={`json.${assessmentExerciseKeys.status}`} suffix />

                <AntSwitch
                  checked={values.json[assessmentExerciseKeys.status] == 'ACTIVE'}
                  onChange={(checked) =>
                    setFieldValue(
                      `json.${assessmentExerciseKeys.status}`,
                      checked ? 'ACTIVE' : 'DRAFT',
                    )
                  }
                />
              </FormItem>
            </div>

            <div className="grid md:grid-cols-2 gap-5">
              <Button block onClick={onCancel}>
                Отменить
              </Button>

              <Button block htmlType="submit" loading={problemCreate.isLoading} type="primary">
                <span className="max-w-full overflow-ellipsis overflow-hidden">
                  {values['json']['status'] == 'ACTIVE'
                    ? 'Создать и запустить'
                    : 'Сохранить как черновик'}
                </span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
