import { AvatarStatusEnumKeys } from './enums/avatarStatusEnum';
import { AvatarTypeEnumKeys } from './enums/avatarTypeEnum';

export interface IAvatar {
  createdDate: string;
  id: number;
  imgUrl: string;
  imgUrlPng: string;
  localizations: Record<'en' | 'ru', { name: string }>;
  price?: number | 0;
  priority: number;
  status?: AvatarStatusEnumKeys;
  type: AvatarTypeEnumKeys;
  updatedDate: string;
}

export const avatarKeys: Record<keyof IAvatar, string> = {
  createdDate: 'createdDate',
  id: 'id',
  imgUrl: 'imgUrl',
  imgUrlPng: 'imgUrlPng',
  localizations: 'localizations',
  price: 'price',
  priority: 'priority',
  status: 'status',
  type: 'type',
  updatedDate: 'updatedDate',
};
