import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum PromocodeGroupsPageActionsEnum {
  'create',
  'view',
}
export type PromocodeGroupsPageActions = {
  action: keyof typeof PromocodeGroupsPageActionsEnum;
  actionId: number;
};

export const promocodeGroupsPageParams: IPageActions<PromocodeGroupsPageActions> = {
  action: {
    allowed: (value) => Object.keys(PromocodeGroupsPageActionsEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
};
