import type { FC } from 'react';
import { Button, Modal, Tag } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Select } from 'formik-antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

import {
  ILecturerCardUpdateRequest,
  updateLecturerCardValidation,
  useMutateLecturerCardUpdate,
} from '@collections/lecturer-cards/api/useMutateLecturerCardUpdate';
import { useQueryLecturerCard } from '@collections/lecturer-cards/api/useQueryLecturerCard';
import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { FileUpload } from '@components/molecules/files/FileUpload';
import { useQueryTags } from '@tags/api/useQueryTags';

import { CreateLecturerLocaleFormCard } from '../molecules/LecturerCardLocaleForm';

type EditLecturerCardModalProps = {
  id?: number;
  isOpen: boolean;
  onClose: () => void;
};

export const EditLecturerCardModal: FC<EditLecturerCardModalProps> = ({
  id = 0,
  isOpen,
  onClose,
}) => {
  const updateCard = useMutateLecturerCardUpdate();
  const cardQuery = useQueryLecturerCard({ id });
  const card = cardQuery.data?.data;
  const tagsAuthor = useQueryTags({ type: 'AUTHOR' });

  return (
    <Modal
      className="px-4 md:px-0 w-full md:w-4/5 lg:w-3/4"
      footer={null}
      onCancel={onClose}
      open={isOpen}
      title="Редактировать карточку лектора"
    >
      {cardQuery.isFetching && <BaseSkeleton />}

      {cardQuery.isFetching ? (
        <BaseSkeleton />
      ) : !card ? (
        <NotFoundResult title="Карточка лектора не найдена" />
      ) : (
        <Formik<ILecturerCardUpdateRequest>
          enableReinitialize
          initialValues={{
            authorTagId: card.authorTag.id,
            id: card.id,
            imgUrl: card.imgUrl,
            localizations: card.localizations,
            status: card.status,
          }}
          onSubmit={(values) =>
            updateCard.mutateAsync(values).then(() => {
              onClose();
            })
          }
          validateOnBlur={false}
          validationSchema={updateLecturerCardValidation}
        >
          {({ setFieldValue }) => (
            <Form className="gap-4 md:gap-6" layout="vertical">
              <FormItem label="Лектор" name="authorTagId">
                <Select
                  loading={tagsAuthor.isFetching}
                  name="authorTagId"
                  optionFilterProp="label"
                  options={
                    tagsAuthor.data?.data.content.map((tag) => ({
                      label: tag.localizations.en,
                      value: tag.id,
                    })) || []
                  }
                  placeholder="Лектор"
                  tagRender={
                    tagsAuthor.isFetching
                      ? () => (
                          <Tag>
                            <Loading3QuartersOutlined />
                          </Tag>
                        )
                      : undefined
                  }
                />
              </FormItem>
              <FormItem className="upload-form-item" label="Фоновая картинка" name="imgUrl">
                <FileUpload
                  accept=".jpeg,.png,.jpg,.svg"
                  defaultFileList={
                    card.imgUrl
                      ? [
                          {
                            name: '',
                            uid: card.imgUrl,
                            url: card.imgUrl,
                          },
                        ]
                      : undefined
                  }
                  folderName="cards"
                  maxCount={1}
                  onChange={(info, linkInfo) => {
                    if (!info.file.status || info.file.status == 'removed')
                      setFieldValue('imgUrl', '');
                    if (info.file.status == 'done') setFieldValue('imgUrl', linkInfo.downloadUrl);
                  }}
                />
              </FormItem>
              <div className="grid sm:grid-cols-2 gap-6">
                <CreateLecturerLocaleFormCard localization="en" />
                <CreateLecturerLocaleFormCard localization="ru" />
              </div>

              <div className="flex gap-4 justify-end">
                <Button onClick={onClose}>Отмена</Button>
                <Button htmlType="submit" loading={updateCard.isLoading} type="primary">
                  Сохранить
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
