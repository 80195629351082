import { RadioFilter } from '@components/molecules/filters/static/RadioFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { IFilters } from '@typess/IFilters';

import { AvatarStatusEnum } from '../types/enums/avatarStatusEnum';
import { AvatarTypeEnum } from '../types/enums/avatarTypeEnum';
import { avatarKeys } from '../types/IAvatar';

export const avatarFilters: IFilters = {
  ...paginationBaseFilters,
  [avatarKeys.createdDate]: {
    sort: true,
  },
  [avatarKeys.status]: {
    input: (
      <RadioFilter
        options={Object.entries(AvatarStatusEnum).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },
  [avatarKeys.type]: {
    input: (
      <RadioFilter
        options={Object.entries(AvatarTypeEnum).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },
};
