import { FC } from 'react';
import { Chessboard } from 'react-chessboard';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { CountryImageText } from '@components/atoms/CountryImageText';
import { TbVideoIcon, VscPlayIcon } from '@components/atoms/icons';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { TitleAndText } from '@components/atoms/TitleAndText';
import { IFen } from '@exercises/fens/types/IFen';

export const FenViewModal: FC<{
  fen?: IFen;
  isOpen: boolean;
  onCancel: () => void;
  openEdit?: () => void;
}> = ({ fen, isOpen, onCancel, openEdit }) => {
  return (
    <Modal
      className="w-full px-2 md:px-0 md:w-4/5 top-10"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        !!fen && (
          <div className="flex flex-row gap-4 truncate">
            <h1 className="text-xl m-0 flex items-center gap-1">FEN - &quot;{fen.name}&quot;</h1>
            {openEdit && (
              <>
                <Button className="hidden md:block" icon={<EditOutlined />} onClick={openEdit}>
                  Редактировать
                </Button>

                <Button className="md:hidden" icon={<EditOutlined />} onClick={openEdit} />
              </>
            )}
          </div>
        )
      }
    >
      {!fen ? (
        <NotFoundResult title="FEN не найден" />
      ) : (
        <div className="flex flex-col lg:flex-row gap-10">
          <div className="p-4 space-y-4 min-w-[350px]">
            <TitleAndText light text={fen.value} title="FEN" />

            <TitleAndText light text={fen.name} title="Название позиции" />

            <TitleAndText
              light
              text={
                fen.videos?.length
                  ? fen.videos.map(({ id, isCartoon, localizations }) => (
                      <Link
                        className={`flex items-center gap-1 ${
                          isCartoon ? 'text-ant-magenta-6' : 'text-ant-purple-6'
                        }`}
                        key={id}
                        to={`/videos?action=view&actionId=${id}`}
                      >
                        {isCartoon ? <VscPlayIcon /> : <TbVideoIcon />}
                        {localizations.en?.fullTitle}
                      </Link>
                    ))
                  : '-'
              }
              title="Видеоуроки"
            />

            <TitleAndText
              light
              text={
                <div>
                  <CountryImageText
                    src="en"
                    text={fen.localizations?.en?.messageFromKaissa || '-'}
                  />
                  <CountryImageText
                    src="ru"
                    text={fen.localizations?.ru?.messageFromKaissa || '-'}
                  />
                </div>
              }
              title="Сообщения от Каиссы"
            />

            <TitleAndText light text={fen.status == 'ACTIVE' ? 'Да' : 'Нет'} title="Запущено" />
          </div>

          <div className="text-center flex flex-col self-center" style={{ maxWidth: 360 }}>
            <div className="shadow-md rounded-sm overflow-x-auto">
              <Chessboard arePiecesDraggable={false} boardWidth={360} position={fen.value || ''} />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
