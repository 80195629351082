import { FC, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Result } from 'antd';
import moment from 'moment';
import { LeftOutlined, UserOutlined } from '@ant-design/icons';
import { Line } from '@ant-design/plots';

import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { PageMeta } from '@context/PageMetaContext';
import { useQueryUserGameRating } from '@users/api/useQuertUserGameRating';
import { useQueryUser } from '@users/api/useQueryUser';
import { useQueryUserSysRating } from '@users/api/useQueryUserSysRating';

import { UserDetailPageTabs } from '../molecules/UserDetailPageTabs';

export const UserDashboardPage: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const userId = Number.parseInt(id || '');

  const userQuery = useQueryUser({ id: userId });
  const userSysRatingQuery = useQueryUserSysRating({ customerId: userId });
  const userGameRatingQuery = useQueryUserGameRating({
    customerId: userId,
    pageSize: 1000,
    sortBy: 'id',
    sortDirection: 'DESC',
  });
  const user = userQuery.data?.data;
  const userGameRating = userGameRatingQuery.data?.data.content ?? [];
  const userSysRating = userSysRatingQuery.data?.data ?? [];

  const formattedUserGameRating = userGameRating.map((item) => ({
    category: 'Game rating',
    date: item.createdDate,
    value: item.newRating,
  }));

  const formattedSysGameRating = userSysRating.map((item) => ({
    category: 'System rating',
    date: item.createdDate,
    value: item.newSystemRating,
  }));

  const data = useMemo(
    () =>
      [...formattedSysGameRating, ...formattedUserGameRating]
        .sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
        .map((item) => ({ ...item, date: moment(item.date).format('YYYY-MM-DD HH:mm') })),
    [formattedSysGameRating, formattedUserGameRating],
  );

  const config = {
    data: data ?? [],
    seriesField: 'category',
    slider: {
      end: 0.5,
      start: 0.1,
    },
    xAxis: {
      tickCount: 5,
    },
    xField: 'date',
    yField: 'value',
  };

  return (
    <div>
      <PageMeta
        selectedMenuKeys={['users']}
        title={`Пользователь #${id} ${user ? ` "${user.nickname}"` : ''}`}
      />
      <UserDetailPageTabs active="dashboard" />

      <Link to="/users">
        <Button className="mb-4 text-sm" icon={<LeftOutlined size={10} />} size="large" />
      </Link>

      {userQuery.isLoading ? (
        <BaseSkeleton />
      ) : !user ? (
        <div className="rounded-lg bg-white">
          <Result
            icon={<UserOutlined className="text-8xl inline self-center" />}
            title="Пользователь не найден"
          />
        </div>
      ) : (
        <div className="border bg-white rounded-lg p-4 lg:p-6 flex flex-col">
          <h2 className="text-2xl flex items-center gap-2">
            <UserOutlined /> Пользователь &quot;{user.nickname}&quot;
          </h2>
          <Line {...config} />{' '}
        </div>
      )}
    </div>
  );
};
