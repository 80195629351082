import { FC } from 'react';
import { Button, Card, Form as AntForm } from 'antd';
import { FormItem, Input, Select } from 'formik-antd';
import { toUpper } from 'lodash';
import { DeleteOutlined, FileTextOutlined } from '@ant-design/icons';

import { CountryImageText } from '@components/atoms/CountryImageText';
import { FileUpload } from '@components/molecules/files/FileUpload';
import { useQueryTags } from '@tags/api/useQueryTags';
import { ILocalization } from '@typess/ILocalization';
import { IVideoStepOneCreateRequest } from '@videos/api/useMutateVideoStepOneCreate';
import { useQueryVideoFiles } from '@videos/api/useQueryVideoFiles';
import { IVideo } from '@videos/types/IVideo';

type VideoLocaleFormCardType = {
  descriptionsNum?: number;
  isCartoon?: boolean;
  localization: ILocalization;
  previewKey?: string;
  setDescriptionsNum?: (descriptionsNum: number) => void;
  setFieldValue: (
    field: string,
    value: string | number | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
  subKey?: string;
  test?: IVideoStepOneCreateRequest['localizations']['en'];
  video?: IVideo;
};

export const VideoLocaleFormCard: FC<VideoLocaleFormCardType> = ({
  descriptionsNum,
  isCartoon,
  localization,
  previewKey,
  setDescriptionsNum,
  setFieldValue,
  subKey,
  test,
  video,
}) => {
  const videoFiles = useQueryVideoFiles({ isUploading: false });
  const tagsAuthor = useQueryTags({ type: 'AUTHOR' });

  return (
    <div className="p-5 rounded-2xl border space-y-6">
      <h1 className="text-lg">
        <CountryImageText
          src={localization}
          text={isCartoon ? 'Мультфильм' : 'Видеоурок'}
          width={20}
        />
      </h1>

      <FormItem
        label={isCartoon ? 'Название' : 'Краткое название'}
        name={`localizations.${localization}.title`}
      >
        <Input
          maxLength={isCartoon ? 42 : 21}
          name={`localizations.${localization}.title`}
          placeholder={`Название ${isCartoon ? 'мультфильма' : 'видеоурока'}`}
          showCount
          suffix
        />
      </FormItem>
      {!isCartoon && (
        <FormItem label="Полное название" name={`localizations.${localization}.fullTitle`}>
          <Input
            name={`localizations.${localization}.fullTitle`}
            placeholder={`Полное название ${isCartoon ? 'мультфильма' : 'видеоурока'}`}
            showCount
            suffix
          />
        </FormItem>
      )}
      {!isCartoon && (
        <Card style={{ marginTop: 16 }} title="Описание" type="inner">
          {Array.from(Array(descriptionsNum)).map((_, index) => (
            <FormItem
              key={`localizations.${localization}.descriptions${index + 1}`}
              name={`localizations.${localization}.descriptions.${index}`}
            >
              <div className="flex flex-row">
                <Input
                  addonBefore={index + 1}
                  name={`localizations.${localization}.descriptions.${index}`}
                  prefix
                  showCount
                />
                {index > 0 && descriptionsNum && setDescriptionsNum && (
                  <Button
                    className="border-none p-0"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      test?.descriptions?.splice(index, 1);
                      setDescriptionsNum(descriptionsNum - 1);
                    }}
                  />
                )}
              </div>
            </FormItem>
          ))}
          {setDescriptionsNum && descriptionsNum && (
            <AntForm.Item>
              <Button block onClick={() => setDescriptionsNum(descriptionsNum + 1)}>
                Добавить описание
              </Button>
            </AntForm.Item>
          )}
        </Card>
      )}
      <div className="sm:grid gap-1 text-center">
        <FormItem className="mb-1" label="Видеофайл" name={`localizations.${localization}.uuid`}>
          <Select
            name={`localizations.${localization}.uuid`}
            optionFilterProp="label"
            options={videoFiles.data?.data.content?.map((file) => ({
              label: file.title,
              value: file.uuid,
            }))}
            placeholder="Видеофайл"
            showSearch
          />
        </FormItem>
      </div>

      {!isCartoon && (
        <FormItem label="Автор" name={`localizations.${localization}.authorTagId`}>
          <Select
            name={`localizations.${localization}.authorTagId`}
            options={
              tagsAuthor.data?.data.content.map((tag) => ({
                label: tag.authorRank ? (
                  <>
                    <span className="font-bold text-ant-red-7 mr-1">{tag.authorRank}</span>
                    {tag.localizations.en}
                  </>
                ) : (
                  tag.localizations.en
                ),
                value: tag.id,
              })) || []
            }
            placeholder="Автор"
          />
        </FormItem>
      )}

      <FormItem
        className="upload-form-item text-center"
        name={`localizations.${localization}.subtitlesUrl`}
      >
        <FileUpload
          accept=".vtt"
          defaultFileList={
            video?.localizations[localization]?.subtitlesUrl
              ? [
                  {
                    name: `Субтитры ${toUpper(localization)}`,
                    status: 'done',
                    uid: `Субтитры ${localization}`,
                    url: video.localizations[localization]?.subtitlesUrl || '',
                  },
                ]
              : undefined
          }
          folderName="subs"
          icon={<FileTextOutlined className="text-ant-gray-7" />}
          key={subKey}
          label="Субтитры"
          maxCount={1}
          onChange={(info, linkInfo) => {
            if (!info.file.status || info.file.status == 'removed') {
              setFieldValue(`localizations.${localization}.subtitlesUrl`, '');
            }
            if (info.file.status == 'done') {
              setFieldValue(`localizations.${localization}.subtitlesUrl`, linkInfo.downloadUrl);
            }
          }}
        />
      </FormItem>
      <FormItem
        className="upload-form-item text-center custom-picture"
        name={`localizations.${localization}.previewUrl`}
      >
        <FileUpload
          accept=".png,.jpeg,.jpg"
          defaultFileList={
            video?.localizations[localization]?.previewUrl
              ? [
                  {
                    name: '',
                    status: 'done',
                    uid: `Обложка ${localization}`,
                    url: video.localizations[localization]?.previewUrl,
                  },
                ]
              : undefined
          }
          folderName="previews"
          key={previewKey}
          label="Обложка видео"
          listType="picture-card"
          maxCount={1}
          onChange={(info, linkInfo) => {
            if (!info.file.status || info.file.status == 'removed')
              setFieldValue(`localizations.${localization}.previewUrl`, '');
            if (info.file.status == 'done')
              setFieldValue(`localizations.${localization}.previewUrl`, linkInfo.downloadUrl);
          }}
        />
      </FormItem>
    </div>
  );
};
