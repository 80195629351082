/**
 * ⚠️ DONT EDIT MANUALLY
 * This file is generated with `./src/cli/generateIcons.js`, check it for the detailed information
 */
import { FC } from 'react';

import { ReactComponent as ChessPiecePawnIconSvg } from '@assets/icons/chess-piece-pawn.svg';

import BaseIcon from './_BaseIcon';

type Props = React.ComponentPropsWithoutRef<'span'>;

export const ChessPiecePawnIcon: FC<Props> = (props) => {
  return <BaseIcon component={ChessPiecePawnIconSvg} style={{ fontSize: '1.5em' }} {...props} />;
};
