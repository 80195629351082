import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, number, object, ObjectSchema, string } from 'yup';

import { avatarCommonKeys } from '@collections/avatars/api/avatarCommonKeys';
import { ChessPiecesSetStatusEnumKeys } from '@collections/chess-pieces/types/enums/ChessPiecesSetEnum';

import { avatarGroupQueryKeys } from './avatarGroupQueryKeys';
import { IAvatarGroupCreateRequest } from './useMutateAvatarGroupCreate';

export type IAvatarGroupEditRequest = Partial<Omit<IAvatarGroupCreateRequest, 'items'>> & {
  id: number;
  items: any;
};

type IAvatarGroupEditResponse = AxiosResponse<void>;

export const avatarGroupEditValidation: ObjectSchema<IAvatarGroupEditRequest> = object({
  id: number().required(),
  items: array().of(object({ avatarId: number(), priority: number() })),
  localizations: object({
    en: object({ name: string().required() }),
    ru: object({ name: string().required() }),
  }),
  status: string<ChessPiecesSetStatusEnumKeys>().nullable().required(),
});

export const AvatarGroupEditRequest = async (
  payload: IAvatarGroupEditRequest,
): Promise<IAvatarGroupEditResponse> => await axios.patch(`/avatar-groups/${payload.id}`, payload);

export function useMutateAvatarGroupEdit() {
  const queryClient = useQueryClient();

  return useMutation<IAvatarGroupEditResponse, AxiosError, IAvatarGroupEditRequest>(
    AvatarGroupEditRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(avatarGroupQueryKeys.avatarGroup);
        queryClient.invalidateQueries(avatarGroupQueryKeys.avatarsGroups);
        queryClient.invalidateQueries(avatarCommonKeys.avatars);
        queryClient.invalidateQueries(avatarCommonKeys.avatar);
      },
    },
  );
}
