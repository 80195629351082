import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema, string } from 'yup';

import { notify } from '@utils/notify';

import { LecturerCardStatusEnumKeys } from '../types/enums/LecturerCardStatusEnum';
import { ILecturerCard } from '../types/ILecturerCard';
import { lecturerCardCommonKeys } from './lecturerCardKeys';

export type ILecturerCardCreateRequest = Pick<
  ILecturerCard,
  'imgUrl' | 'localizations' | 'status'
> & {
  authorTagId?: number;
};

export const createLecturerCardInitialValue: ILecturerCardCreateRequest = {
  imgUrl: '',
  localizations: { en: { description: '', title: '' }, ru: { description: '', title: '' } },
  status: 'ACTIVE',
};

export const createLecturerCardValidation: ObjectSchema<ILecturerCardCreateRequest> = object({
  authorTagId: number().required(),
  imgUrl: string().required(),
  localizations: object({
    en: object({ description: string().required(), title: string().required() }),
    ru: object({ description: string().required(), title: string().required() }),
  }),
  status: string<LecturerCardStatusEnumKeys>(),
});

export const createLecturerCardRequest = (payload: ILecturerCardCreateRequest) =>
  axios.post(`/admin/author-cards`, payload);

export const useMutateLecturerCardCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, ILecturerCardCreateRequest>(
    createLecturerCardRequest,
    {
      onError: () => {
        notify('error', 'Повторите попытку позже');
      },
      onSuccess: () => {
        notify('success', 'Аватар успешно создан');

        queryClient.invalidateQueries(lecturerCardCommonKeys.lecturerCards);
      },
    },
  );
};
