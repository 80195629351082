import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, Tabs } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

export const CollectionTabs: FC<{
  active: 'board' | 'figures' | 'avatars' | 'lecturer-cards';
}> = ({ active }) => {
  const navigate = useNavigate();
  const [showBoardsMenu, setShowBoardsMenu] = useState(false);
  const [showChessPiecesMenu, setShowChessPiecesMenu] = useState(false);
  const [showAvatarsMenu, setShowAvatarsMenu] = useState(false);

  const location = useLocation();

  return (
    <div className="relative w-max m-auto">
      <Tabs
        activeKey={active}
        centered
        className="w-max"
        items={[
          {
            key: 'board',
            label: (
              <span>
                {location.pathname.includes('board-group') ? 'Группы досок' : 'Доски'}
                {showBoardsMenu ? (
                  <CaretUpOutlined className="ml-1" />
                ) : (
                  <CaretDownOutlined className="ml-1" />
                )}
              </span>
            ),
          },
          {
            key: 'figures',
            label: (
              <span>
                {location.pathname.includes('figures-group') ? 'Группы фигур' : 'Фигуры'}
                {showChessPiecesMenu ? (
                  <CaretUpOutlined className="ml-1" />
                ) : (
                  <CaretDownOutlined className="ml-1" />
                )}
              </span>
            ),
          },
          {
            key: 'avatars',
            label: 'Аватары',
            // (
            //   <span>
            //     {location.pathname.includes('avatars-group') ? 'Группы аватаров' : 'Аватары'}
            //     {showAvatarsMenu ? (
            //       <CaretUpOutlined className="ml-1" />
            //     ) : (
            //       <CaretDownOutlined className="ml-1" />
            //     )}
            //   </span>,
            // ),
          },
          { key: 'lecturer-cards', label: 'Карточки лектора' },
        ]}
        onChange={(tab) =>
          tab === 'board'
            ? setShowBoardsMenu(!showBoardsMenu)
            : tab === 'figures'
            ? setShowChessPiecesMenu(!showChessPiecesMenu)
            : navigate(`/collections/${tab}`)
        }
        onTabClick={(tab) =>
          tab === 'board'
            ? setShowBoardsMenu(!showBoardsMenu)
            : tab === 'figures'
            ? setShowChessPiecesMenu(!showChessPiecesMenu)
            : tab == 'avatars'
            ? setShowAvatarsMenu(!showChessPiecesMenu)
            : setShowBoardsMenu(!showBoardsMenu)
        }
      />
      {showBoardsMenu && (
        <Menu className="w-max left-0 absolute top-[42px] z-10 shadow-lg" mode="vertical">
          <Menu.Item key="1" onClick={() => navigate('/collections')}>
            Доски
          </Menu.Item>
          <Menu.Item key="2" onClick={() => navigate('/collections/board-group/')}>
            Группы досок
          </Menu.Item>
        </Menu>
      )}
      {showChessPiecesMenu && (
        <Menu className="w-max left-[104px] absolute top-[42px] z-10 shadow-lg" mode="vertical">
          <Menu.Item key="1" onClick={() => navigate('/collections/figures')}>
            Фигуры
          </Menu.Item>
          <Menu.Item key="2" onClick={() => navigate('/collections/figures-group/')}>
            Группы фигур
          </Menu.Item>
        </Menu>
      )}
      {showAvatarsMenu && (
        <Menu className="w-max left-[104px] absolute top-[42px] z-10 shadow-lg" mode="vertical">
          <Menu.Item key="1" onClick={() => navigate('/collections/avatars')}>
            Аватары
          </Menu.Item>
          <Menu.Item key="2" onClick={() => navigate('/collections/avatars-group/')}>
            Группы аватаров
          </Menu.Item>
        </Menu>
      )}
    </div>
  );
};
