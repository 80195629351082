import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Switch as AntSwitch, Table } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';

import { useMutateAvatarDelete } from '@collections/avatars/api/useMutateAvatarDelete';
import { useMutateAvatarStatusUpdate } from '@collections/avatars/api/useMutateAvatarStatusUpdate';
import { IAvatarsRequest, useQueryAvatars } from '@collections/avatars/api/useQueryAvatars';
import { avatarColumns } from '@collections/avatars/consts/columns/avatarColumns';
import {
  avatarGroupEditValidation,
  IAvatarGroupEditRequest,
  useMutateAvatarGroupEdit,
} from '@collections/avatars-groups/api/useMutateAvatarGroupEdit';
import { useQueryAvatarGroup } from '@collections/avatars-groups/api/useQueryAvatarGroup';
import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { DraggableTableRow } from '@components/organisms/adminTable/organismComponents/DraggableTableRow';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { AvatarGroupAddItemModal } from './AvatarGroupAddItemModal';

export const AvatarGroupEditModal: FC<{
  id?: number;
  isOpen: boolean;
  onCancel: () => void;
  onItemDelete: ({ avatarId, id }: { avatarId: number; id: number }) => void;
}> = ({ id = 0, isOpen, onCancel }) => {
  const [filtered] = useState<IAvatarsRequest>({
    page: 1,
    pageSize: 10,
    sortBy: 'id',
    sortDirection: 'ASC',
  });

  const avatarsQuery = useQueryAvatars({ ...filtered, groupId: id });
  const avatars = avatarsQuery.data?.data.content ?? [];
  const navigate = useNavigate();

  const avatarGroupQuery = useQueryAvatarGroup({ id });
  const avatarGroup = avatarGroupQuery.data?.data;
  const [dataSource, setDataSource] = useState<any>(avatars);

  useEffect(() => {
    const newArray = avatars.map((item) => {
      const priorityObject = avatarGroup?.items.find((pItem) => pItem.avatar.id === item.id);

      if (priorityObject) {
        return { ...item, priority: priorityObject.priority };
      }

      return item;
    });

    if (avatars) {
      setDataSource(
        newArray
          .sort((a, b) => a.priority - b.priority)
          .map((item) => ({
            ...item,
            key: item.priority,
          })),
      );
    }
  }, [avatars]);

  const allAvatarsQuery = useQueryAvatars();
  const filteredAvatars = useMemo(
    () =>
      allAvatarsQuery.data?.data.content.filter(
        (item) => !avatarsQuery.data?.data.content.some((refItem) => refItem.id === item.id),
      ),
    [allAvatarsQuery, avatarsQuery],
  );

  const [addAvatarModalOpen, setAddAvatarModalOpen] = useState(false);

  const avatarStatusUpdate = useMutateAvatarStatusUpdate();
  const deleteAvatarMutation = useMutateAvatarDelete();

  const editAvatarGroup = useMutateAvatarGroupEdit();

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const overIndex = previous.findIndex((i) => i.priority === over?.id);
        const activeIndex = previous.findIndex((i) => i.priority === active.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  return (
    <Modal
      className="px-4 md:px-0 md:w-5/6 lg:w-4/5 pt-0 mt-0 top-8"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <div className="flex flex-row gap-4">
          <h2 className="text-2xl">
            Редактировать группу аватаров - {avatarGroup?.localizations.ru?.name ?? id}
          </h2>
        </div>
      }
    >
      {avatarGroupQuery.isLoading ? (
        <BaseSkeleton />
      ) : !avatarGroup ? (
        <NotFoundResult title="Группа досок не найдена" />
      ) : (
        <div>
          <Formik<IAvatarGroupEditRequest>
            enableReinitialize
            initialValues={{
              id: avatarGroup.id,
              items: dataSource.map((item, index) => ({
                avatarId: item.id,
                priority: index,
              })),
              localizations: avatarGroup.localizations,
              status: avatarGroup.status,
            }}
            onSubmit={(values) =>
              editAvatarGroup.mutateAsync(values).then(() => {
                onCancel();
              })
            }
            validateOnBlur={false}
            validationSchema={avatarGroupEditValidation}
          >
            {({ setFieldValue, values }) => (
              <Form className="gap-4 md:gap-6" layout="vertical">
                <FormItem
                  label={<CountryImageText src="ru" text="Название на русском" />}
                  name="localizations.ru.name"
                >
                  <Input name="localizations.ru.name" prefix />
                </FormItem>
                <FormItem
                  label={<CountryImageText src="en" text="Название на английском" />}
                  name="localizations.en.name"
                >
                  <Input name="localizations.en.name" prefix />
                </FormItem>
                <div className="flex justify-start text-center">
                  <FormItem label={<span className="text-center">Статус</span>} name="status">
                    <Input hidden name="status" suffix />

                    <AntSwitch
                      checked={values.status == 'ACTIVE'}
                      onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
                    />
                  </FormItem>
                </div>
                <div className="flex justify-end gap-3">
                  <Button
                    className="md:block mb-4"
                    onClick={() => setAddAvatarModalOpen(true)}
                    type="primary"
                  >
                    Добавить доску
                  </Button>
                </div>

                <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                  <SortableContext
                    items={dataSource?.map((i) => i.priority)}
                    strategy={verticalListSortingStrategy}
                  >
                    <Table
                      columns={avatarColumns({
                        isSortable: true,
                        onClickDelete: deleteAvatarMutation.mutateAsync,
                        onClickPause: avatarStatusUpdate.mutateAsync,
                        onEditAvatar: (avatar) =>
                          navigate(`/collections?action=edit&actionId=${avatar.id}`),
                        onOpenAvatar: (avatar) =>
                          navigate(`/collections?action=edit&actionId=${avatar.id}`),
                      })}
                      components={{
                        body: {
                          row: DraggableTableRow,
                        },
                      }}
                      dataSource={dataSource}
                      loading={avatarsQuery.isLoading}
                      pagination={false}
                      rowKey="key"
                      scroll={{ x: '100%' }}
                    />
                  </SortableContext>
                </DndContext>

                <AvatarGroupAddItemModal
                  avatarGroupId={id}
                  avatarGroupName={avatarGroup.localizations.ru?.name ?? ''}
                  avatars={filteredAvatars ?? []}
                  isGroupNameDisabled={true}
                  isLoading={allAvatarsQuery.isFetching}
                  isOpen={addAvatarModalOpen}
                  onClose={() => setAddAvatarModalOpen(false)}
                />
                <div className="flex gap-4 justify-end mt-4">
                  <Button onClick={onCancel}>Отмена</Button>
                  <Button htmlType="submit" loading={editAvatarGroup.isLoading} type="primary">
                    <span className="max-w-full overflow-ellipsis overflow-hidden">
                      {values.status == 'ACTIVE' ? 'Сохранить' : 'Сохранить как черновик'}
                    </span>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Modal>
  );
};
