import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { userQueryKeys } from '@users/api/userQueryKeys';
import { IUserSysRating } from '@users/types/IUserSysRating';

export type IUserSysRatingRequest = { customerId: number };

type IUserSysRatingQueryResponse = AxiosResponse<IUserSysRating[]>;

export const userSysRatingRequest = async (
  params: IUserSysRatingRequest,
): Promise<IUserSysRatingQueryResponse> => {
  return await axios.get(`/sys-rating-changes`, { params });
};

export function useQueryUserSysRating(params: IUserSysRatingRequest) {
  return useQuery<IUserSysRatingQueryResponse, AxiosError>(
    [userQueryKeys.userGameRating, params],
    () => userSysRatingRequest(params),
  );
}
