import { useMutation } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

type IFileUploadLinkRequest = {
  fileName: string;
  folderName: string;
};
type IFileUploadLinkResponse = {
  downloadUrl: string;
  uploadUrl: string;
};
export const fileUploadLinkRequest = async (
  payload: IFileUploadLinkRequest,
): Promise<AxiosResponse<IFileUploadLinkResponse>> => {
  return await axios.get('/file/file-upload-link', { params: payload });
};

export function useMutateFileUploadLink(params?) {
  return useMutation<AxiosResponse<IFileUploadLinkResponse>, AxiosError, IFileUploadLinkRequest>(
    ['files', params],
    (payload) => fileUploadLinkRequest(payload),
  );
}
