import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum BoardPageActionEnum {
  'create',
  'edit',
  'view',
}

export interface IBoardsPageActions {
  action: keyof typeof BoardPageActionEnum;
  actionId: number;
}

export const boardPageActions: IPageActions<IBoardsPageActions> = {
  action: {
    allowed: (value) => Object.keys(BoardPageActionEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
};
