import { FC, useState } from 'react';
import { Button, InputNumber } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { BsArrowRightIcon } from '@components/atoms/icons';
import { FilterRender } from '@typess/IFilters';

interface InputRangeFilterInterface {
  filterRender?: FilterRender;
  placeholderMax?: string;
  placeholderMin?: string;
}

export const RangeFilter: FC<InputRangeFilterInterface> = ({
  filterRender,
  placeholderMax,
  placeholderMin,
}) => {
  if (!filterRender) {
    return null;
  }

  const { dataIndex, filtered, onFilter, queries } = filterRender;

  const keyMin = queries?.[0]?.key || `${dataIndex}From`;
  const keyMax = queries?.[1]?.key || `${dataIndex}To`;

  const [min, setMin] = useState(filtered[keyMin]);
  const [max, setMax] = useState(filtered[keyMax]);

  const onRangeSubmit = (clear = false) =>
    max && min > max
      ? onFilter({ [keyMax]: clear ? undefined : min, [keyMin]: clear ? undefined : max })
      : onFilter({ [keyMax]: clear ? undefined : max, [keyMin]: clear ? undefined : min });

  return (
    <div className="p-2 space-y-3 bg-white shadow-filter max-w-tiny">
      <div className="grid grid-flow-col auto-cols-auto range-filter">
        <InputNumber
          autoFocus
          className="border-r-0 rounded-r-none hover:border-ant-gray-5"
          controls={false}
          onChange={setMin}
          onPressEnter={() => onRangeSubmit()}
          placeholder={placeholderMin}
          value={min}
        />
        <div className="text-tiny border-x-0 border-y flex items-center text-gray-400">
          <BsArrowRightIcon />
        </div>
        <InputNumber
          className="border-l-0 rounded-l-none hover:border-ant-gray-5"
          controls={false}
          onChange={setMax}
          onPressEnter={() => onRangeSubmit()}
          placeholder={placeholderMax}
          value={max}
        />
      </div>
      <div className="grid gap-1 grid-cols-2 rounded-none">
        <Button
          className="w-full"
          disabled={(!min && !max) || (filtered[keyMin] == min && filtered[keyMax] == max)}
          icon={<SearchOutlined />}
          onClick={() => onRangeSubmit()}
          size="small"
          type="primary"
        >
          Поиск
        </Button>
        <Button className="w-full" onClick={() => onRangeSubmit(true)} size="small">
          Очистить
        </Button>
      </div>
    </div>
  );
};
