import { FC, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Result, Tabs } from 'antd';
import { LeftOutlined, UserOutlined } from '@ant-design/icons';

import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { Description } from '@components/atoms/Description';
import { PageMeta } from '@context/PageMetaContext';
import { useQueryUser } from '@users/api/useQueryUser';
import { useQueryUserStats } from '@users/api/useQueryUserStats';
import {
  UserBrainstormTab,
  UserGamesTab,
  UserMapStagesTab,
  UserPurchasesTab,
  UserSkillsTab,
} from '@users/components/molecules/UserTabs';
import { IUserGameMatch } from '@users/types/IUserGameMatch';

import { UserDetailPageTabs } from '../molecules/UserDetailPageTabs';
import { HistoryGameModal } from '../organisms/HistoryGameModal';

export const UserPage: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const userId = Number.parseInt(id || '');

  const [isHistoryModalOpen, setHistoryModalOpen] = useState(false);
  const [selectedHistoryGameMatch, setSelectedHistoryGameMatch] = useState<IUserGameMatch | null>(
    null,
  );

  const userQuery = useQueryUser({ id: userId });
  const user = userQuery.data?.data;

  const statsQuery = useQueryUserStats({ id: userId });
  const stats = statsQuery.data?.data;

  return (
    <div>
      <PageMeta
        selectedMenuKeys={['users']}
        title={`Пользователь #${id} ${user ? ` "${user.nickname}"` : ''}`}
      />
      <UserDetailPageTabs active="user" />

      <Link to="/users">
        <Button className="mb-4 text-sm" icon={<LeftOutlined size={10} />} size="large" />
      </Link>

      {userQuery.isLoading ? (
        <BaseSkeleton />
      ) : !user ? (
        <div className="rounded-lg bg-white">
          <Result
            icon={<UserOutlined className="text-8xl inline self-center" />}
            title="Пользователь не найден"
          />
        </div>
      ) : (
        <div className="lg:grid lg:grid-cols-3 gap-4 space-y-8 lg:space-y-0">
          <div className="flex-grow">
            <div className="border bg-white rounded-lg p-4 lg:p-6">
              <div className="flex flex-row justify-between border-b border-ant-blue-6 pb-4">
                <h2 className="text-2xl flex items-center gap-2">
                  <UserOutlined /> Пользователь &quot;{user.nickname}&quot;
                </h2>
              </div>

              <div className="divide-y divide-slate-200 text-base">
                <Description description={user.id} title="#ID" />
                <Description description={user.nickname} title="Никнейм" />
                <Description description={user.email} title="Почта" />
                <Description description={user.subscriptionStatus} title="Подписка" />
                <Description description={user.age} title="Возраст" />
                <Description description={user.playLevel} title="Уровень" />
                <Description description={user.systemRating} title="Сис. Рейтинг" />
                <Description description={user.rating} title="Игр. Рейтинг" />
                <Description description={user.language} title="Язык" />
                <Description description={user.coins} title="Коины" />
                <Description description={stats?.giftedCoins} title="Подарочные коины" />
                <h2 className="pt-5 pb-2 text-lg font-semibold">Траты</h2>
                <Description description={stats?.spentCoinsTotal} title="Общее" />
                <Description description={stats?.spentCoinsOnVideo} title="Видео" />
                <Description description={stats?.spentCoinsOnCartoon} title="Мультфильмы" />
                <Description
                  description={stats?.spentCoinsOnExerciseHint}
                  title="Подсказки в задачах"
                />
                <Description description={stats?.spentCoinsOnGameHint} title="Подсказки в игре" />
                <Description description={stats?.spentCoinsOnGameMoveBack} title="Ход назад" />
              </div>
            </div>
          </div>

          <div className="lg:col-span-2">
            <div className="bg-white rounded-lg p-4 lg:p-6">
              <Tabs
                className="w-full"
                items={[
                  {
                    children: <UserPurchasesTab userId={userId} />,
                    key: 'purchases',
                    label: 'Покупки коинов',
                  },
                  {
                    children: <UserMapStagesTab userId={userId} />,
                    key: 'stages',
                    label: 'Уровни',
                  },
                  {
                    children: (
                      <UserGamesTab
                        onOpenHistoryModal={(record) => {
                          setSelectedHistoryGameMatch(record);
                          setHistoryModalOpen(true);
                        }}
                        stats={stats}
                        userId={userId}
                      />
                    ),
                    key: 'games',
                    label: 'Игры',
                  },
                  {
                    children: <UserSkillsTab skillStats={user.skillStats} />,
                    key: 'skills',
                    label: 'Навыки',
                  },
                  {
                    children: <UserBrainstormTab userId={userId} />,
                    key: 'brainstorm',
                    label: 'Мозговой штурм',
                  },
                ]}
              />
            </div>
          </div>
        </div>
      )}

      {selectedHistoryGameMatch && (
        <HistoryGameModal
          isOpen={isHistoryModalOpen}
          match={selectedHistoryGameMatch}
          onCancel={() => {
            setSelectedHistoryGameMatch(null);
            setHistoryModalOpen(false);
          }}
        />
      )}
    </div>
  );
};
