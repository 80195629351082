import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '@components/molecules/routes/ProtectedRoute';
import NotFoundPage from '@pages/errors/NotFoundPage';
import { ClassifierPage } from '@settings/classifierSkillTypes/components/pages/ClassifierPage';
import PreSubscribersPage from '@settings/preSubscribers/components/pages/PreSubscribersPage';
import PromocodeGroupsPage from '@settings/promocodes/components/pages/PromocodeGroupsPage';
import { settingPermissions } from '@typess/permissions/ISettingPermission';

import AppSettingsPage from './appSettings/components/pages/AppSettingsPage';
import OnboardingPage from './onboarding/components/pages/OnboardingPage';
import VersionsPage from './versions/components/pages/VersionsPage';

export const SettingRouter = () => {
  return (
    <ProtectedRoute permissions={Object.values(settingPermissions)}>
      <Routes>
        <Route element={<AppSettingsPage />} index />
        <Route element={<PreSubscribersPage />} path="pre-subscribers" />
        <Route element={<PromocodeGroupsPage />} path="promocodes" />
        <Route element={<ClassifierPage />} path="classifier" />
        <Route element={<VersionsPage />} path="versions" />
        <Route element={<OnboardingPage />} path="onboarding" />
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </ProtectedRoute>
  );
};
