import { CheckboxFilter } from '@components/molecules/filters/static/CheckboxFilter';
import { InputFilter } from '@components/molecules/filters/static/InputFilter';
import { RadioFilter } from '@components/molecules/filters/static/RadioFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { ExerciseStatusEnum } from '@exercises/exercises/types/enums/ExerciseStatusEnum';
import { ExerciseTypeEnum } from '@exercises/exercises/types/enums/ExerciseTypeEnum';
import { exerciseKeys } from '@exercises/exercises/types/IExercise';
import { assessmentExerciseKeys } from '@exercises/levelTesting/types/IAssessmentExercise';
import { IFilters } from '@typess/IFilters';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';

export const assessmentExerciseFilters: IFilters = {
  ...paginationBaseFilters,

  id: {
    input: <InputFilter placeholder="Введите ID" />,
    sort: true,
  },
  [assessmentExerciseKeys.playLevel]: {
    input: (
      <CheckboxFilter
        options={Object.entries(userPlayLevels).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    queries: [{ key: 'playLevels' }],
    sort: true,
    sortOrderReversed: true,
  },
  [assessmentExerciseKeys.priority]: {
    sort: true,
  },
  [exerciseKeys.status]: {
    input: (
      <CheckboxFilter
        options={Object.entries(ExerciseStatusEnum).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },

  [exerciseKeys.type]: {
    input: (
      <RadioFilter
        options={Object.entries(ExerciseTypeEnum).map(([value, label]) => ({ label, value }))}
      />
    ),
    sort: true,
  },
};
