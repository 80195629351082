import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Switch as AntSwitch, Table } from 'antd';
import { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';

import {
  boardGroupEditValidation,
  IBoardGroupEditRequest,
  useMutateBoardGroupEdit,
} from '@collections/board-groups/api/useMutateBoardGroupEdit';
import { useQueryBoardGroup } from '@collections/board-groups/api/useQueryBoardGroup';
import { useMutateBoardStatusUpdate } from '@collections/boards/api/useMutateBoardStatusUpdate';
import { useQueryBoards } from '@collections/boards/api/useQueryBoards';
import { boardColumns } from '@collections/boards/consts/columns/boardColumns';
import { IBoard } from '@collections/boards/types/IBoard';
import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { DraggableTableRow } from '@components/organisms/adminTable/organismComponents/DraggableTableRow';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { BoardGroupAddItemModal } from './BoardGroupAddItemModal';

export const BoardGroupEditModal: FC<{
  id?: number;
  isOpen: boolean;
  onCancel: () => void;
  onItemDelete: ({
    chessBoardId,
    id,
  }: {
    chessBoardId: number;
    id: number;
  }) => Promise<AxiosResponse>;
}> = ({ id = 0, isOpen, onCancel, onItemDelete }) => {
  const navigate = useNavigate();

  const boardGroupQuery = useQueryBoardGroup({ id });
  const boardGroup = boardGroupQuery.data?.data;
  const [boardItems, setBoardItems] = useState<(IBoard & { priority: number })[]>([]);

  const allBoardsQuery = useQueryBoards();
  const filteredBoards = useMemo(
    () =>
      allBoardsQuery.data?.data.content.filter(
        (item) => !boardItems.some((refItem) => refItem.id === item.id),
      ),
    [allBoardsQuery, boardItems],
  );

  const [addBoardModalOpen, setAddBoardModalOpen] = useState(false);

  const boardStatusUpdate = useMutateBoardStatusUpdate();
  const editBoardGroup = useMutateBoardGroupEdit();

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setBoardItems((previous) => {
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    if (boardGroup) {
      setBoardItems(
        boardGroup.items
          .sort((a, b) => a.priority - b.priority)
          .map((item) => ({
            ...item.chessBoard,
            priority: item.priority,
          })),
      );
    }
  }, [boardGroup]);

  return (
    <Modal
      className="px-4 md:px-0 md:w-5/6 lg:w-4/5 pt-0 mt-0 top-8"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <div className="flex flex-row gap-4">
          <h2 className="text-2xl">
            Редактировать группу досок - {boardGroup?.localizations.ru?.name ?? id}
          </h2>
        </div>
      }
    >
      {boardGroupQuery.isLoading ? (
        <BaseSkeleton />
      ) : !boardGroup ? (
        <NotFoundResult title="Группа досок не найдена" />
      ) : (
        <div>
          <Formik<IBoardGroupEditRequest>
            enableReinitialize
            initialValues={{
              id: boardGroup.id,
              items: boardItems.map((item, index) => ({
                chessBoardId: item.id,
                priority: index,
              })),
              localizations: boardGroup.localizations,
              priority: boardGroup.priority ?? 1,
              status: boardGroup.status,
            }}
            onSubmit={(values) =>
              editBoardGroup.mutateAsync(values).then(() => {
                onCancel();
              })
            }
            validateOnBlur={false}
            validationSchema={boardGroupEditValidation}
          >
            {({ setFieldValue, values }) => (
              <Form className="gap-4 md:gap-6" layout="vertical">
                <FormItem
                  label={<CountryImageText src="ru" text="Название на русском" />}
                  name="localizations.ru.name"
                >
                  <Input name="localizations.ru.name" prefix />
                </FormItem>
                <FormItem
                  label={<CountryImageText src="en" text="Название на английском" />}
                  name="localizations.en.name"
                >
                  <Input name="localizations.en.name" prefix />
                </FormItem>
                <div className="flex justify-start text-center">
                  <FormItem label={<span className="text-center">Статус</span>} name="status">
                    <Input hidden name="status" suffix />

                    <AntSwitch
                      checked={values.status == 'ACTIVE'}
                      onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
                    />
                  </FormItem>
                </div>
                <div className="flex justify-end gap-3">
                  <Button
                    className="md:block mb-4"
                    onClick={() => setAddBoardModalOpen(true)}
                    type="primary"
                  >
                    Добавить доску
                  </Button>
                </div>

                <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                  <SortableContext
                    items={boardItems?.map((i) => i.id)}
                    strategy={verticalListSortingStrategy}
                  >
                    <Table
                      columns={boardColumns({
                        isSortable: true,
                        onClickDelete: ({ id: chessBoardId }) => onItemDelete({ chessBoardId, id }),
                        onClickPause: boardStatusUpdate.mutateAsync,
                        onEditBoard: (board) =>
                          navigate(`/collections?action=edit&actionId=${board.id}`),
                        onOpenBoard: (board) =>
                          navigate(`/collections?action=edit&actionId=${board.id}`),
                      })}
                      components={{
                        body: {
                          row: DraggableTableRow,
                        },
                      }}
                      dataSource={boardItems}
                      loading={boardGroupQuery.isLoading}
                      pagination={false}
                      rowKey="id"
                      scroll={{ x: '100%' }}
                    />
                  </SortableContext>
                </DndContext>

                <BoardGroupAddItemModal
                  boardGroupId={id}
                  boardGroupName={boardGroup.localizations.ru?.name ?? ''}
                  boards={filteredBoards ?? []}
                  isGroupNameDisabled={true}
                  isLoading={allBoardsQuery.isFetching}
                  isOpen={addBoardModalOpen}
                  onClose={() => setAddBoardModalOpen(false)}
                />
                <div className="flex gap-4 justify-end mt-4">
                  <Button onClick={onCancel}>Отмена</Button>
                  <Button htmlType="submit" loading={editBoardGroup.isLoading} type="primary">
                    <span className="max-w-full overflow-ellipsis overflow-hidden">
                      {values.status == 'ACTIVE' ? 'Сохранить' : 'Сохранить как черновик'}
                    </span>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Modal>
  );
};
