import type { FC } from 'react';
import { Button, Modal, Tag } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Select } from 'formik-antd';
import { LoadingOutlined } from '@ant-design/icons';

import { IChessPiecesSet } from '@collections/chess-pieces/types/IChessPiecesSet';
import {
  chessPiecesGroupAddItemInitialValue,
  chessPiecesGroupAddItemValidation,
  IChessPiecesGroupAddItemRequest,
  useMutateChessPiecesGroupAddItem,
} from '@collections/chess-pieces-groups/api/useMutateChessPiecesGroupAddItem';

type Props = {
  chessPiecesGroupId: number;
  chessPiecesGroupName: string;
  chessPiecesSets: IChessPiecesSet[];
  isGroupNameDisabled?: boolean;
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
};

export const ChessPiecesGroupAddItemModal: FC<Props> = ({
  chessPiecesGroupId,
  chessPiecesGroupName,
  chessPiecesSets,
  isLoading,
  isOpen,
  onClose,
}) => {
  const chessPiecesGroupAddItemMutation = useMutateChessPiecesGroupAddItem();

  return (
    <Modal
      destroyOnClose
      footer={null}
      onCancel={onClose}
      open={isOpen}
      title={`Добавить фигуры в группу "${chessPiecesGroupName}"`}
    >
      <Formik<IChessPiecesGroupAddItemRequest>
        enableReinitialize
        initialValues={chessPiecesGroupAddItemInitialValue}
        onSubmit={(values) =>
          chessPiecesGroupAddItemMutation
            .mutateAsync({ ...values, id: chessPiecesGroupId })
            .then(() => {
              onClose();
            })
        }
        validateOnBlur={false}
        validationSchema={chessPiecesGroupAddItemValidation}
      >
        {() => (
          <Form className="gap-4 md:gap-6" layout="vertical">
            <FormItem label="Коллекции фигур" name="chessPieceSetId">
              <Select
                loading={isLoading}
                name="chessPieceSetId"
                optionFilterProp="label"
                options={
                  chessPiecesSets.map((set) => ({
                    label: set.localizations.en.name,
                    value: set.id,
                  })) || []
                }
                placeholder="Коллекции фигур"
                tagRender={
                  isLoading
                    ? () => (
                        <Tag>
                          <LoadingOutlined />
                        </Tag>
                      )
                    : undefined
                }
              />
            </FormItem>
            <div className="flex gap-4 justify-end">
              <Button onClick={onClose}>Отмена</Button>
              <Button
                htmlType="submit"
                loading={chessPiecesGroupAddItemMutation.isLoading}
                type="primary"
              >
                <span className="max-w-full overflow-ellipsis overflow-hidden">Добавить</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
