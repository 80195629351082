import { FC } from 'react';
import { Tooltip, Typography } from 'antd';
import { FormItem, InputNumber } from 'formik-antd';
import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';

import { BattlePlayLevelEnum } from '@settings/appSettings/types/enums/BattlePlayLevelEnum';

export const AppSettingEngineBattleFormik: FC<{ variables: object }> = ({ variables }) => {
  return (
    <div className="space-y-4">
      {Object.entries(variables).map(([playLevel]) => {
        return (
          <div key={`levelFormik${playLevel}`}>
            <Typography className="font-bold mb-2">{`${BattlePlayLevelEnum[playLevel]}:`}</Typography>
            <div className="grid grid-cols-2 gap-4 item-center">
              <Tooltip placement="bottom" title="IOS">
                <FormItem label="IOS" name={`variables.${playLevel}.0`}>
                  <InputNumber
                    addonBefore={<AppleOutlined />}
                    className="w-full"
                    controls={false}
                    name={`variables.${playLevel}.0`}
                    placeholder="IOS"
                    prefix
                  />
                </FormItem>
              </Tooltip>

              <Tooltip placement="bottom" title="ANDROID">
                <FormItem label="ANDROID" name={`variables.${playLevel}.1`}>
                  <InputNumber
                    addonBefore={<AndroidOutlined />}
                    className="w-full"
                    controls={false}
                    name={`variables.${playLevel}.1`}
                    placeholder="Android"
                    prefix
                  />
                </FormItem>
              </Tooltip>
            </div>
          </div>
        );
      })}
    </div>
  );
};
