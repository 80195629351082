import { IPageActions } from '@typess/IPageActions';

enum ClassifierActionsEnum {
  'edit',
}

export type ClassifierPageActions = {
  action: keyof typeof ClassifierActionsEnum;
  actionName?: string;
};

export const classifierPageParams: IPageActions<ClassifierPageActions> = {
  action: {
    allowed: (value) => Object.keys(ClassifierActionsEnum).includes(value),
    default: '',
  },
  actionName: { default: '' },
};
