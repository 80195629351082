import { FC } from 'react';
import { Button, Modal } from 'antd';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';

import { CountryImageText } from '@components/atoms/CountryImageText';
import { IBackendError } from '@typess/IBackendError';
import { notify } from '@utils/notify';

import {
  ITagTopicCreateRequest,
  tagTopicCreateInitial,
  tagTopicCreateValidation,
  useMutateTagTopicCreate,
} from '../../api/useMutateTagTopicCreate';

type TagCreateModalType = {
  isOpen: boolean;
  onCancel: () => void;
};

export const TagTopicCreateModal: FC<TagCreateModalType> = ({ isOpen, onCancel }) => {
  const topicCreate = useMutateTagTopicCreate();

  return (
    <Modal
      className="px-4"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={<h1 className="text-xl m-0">Создание темы</h1>}
    >
      <Formik<ITagTopicCreateRequest>
        initialValues={tagTopicCreateInitial}
        onSubmit={(values, { resetForm }) => {
          topicCreate
            .mutateAsync(values)
            .then((data) => {
              if (data.status == 201 || data.status == 200) {
                notify('success', 'Тег темы создан');
                onCancel();
                resetForm();
              }
            })
            .catch((error: AxiosError<IBackendError>) => {
              if (
                error.response?.status == 422 &&
                error.response.data.message == 'Tag.alreadyExist'
              ) {
                resetForm({
                  errors: {
                    localizations: {
                      en: 'Такое название уже существует',
                      ru: 'Такое название уже существует',
                    },
                  },
                  values,
                });
              }
            });
        }}
        validationSchema={tagTopicCreateValidation}
      >
        <Form className="space-y-8" layout="vertical">
          <FormItem
            label={<CountryImageText src="en" text="Название на английском" />}
            name="localizations.en"
          >
            <Input
              maxLength={60}
              name="localizations.en"
              placeholder="Название"
              showCount
              suffix
              type="text"
            />
          </FormItem>

          <FormItem
            label={<CountryImageText src="ru" text="Название на русском" />}
            name="localizations.ru"
          >
            <Input
              maxLength={60}
              name="localizations.ru"
              placeholder="Название"
              showCount
              suffix
              type="text"
            />
          </FormItem>

          <div className="grid md:grid-cols-2 gap-5">
            <Button block onClick={onCancel}>
              Отменить
            </Button>

            <Button htmlType="submit" loading={topicCreate.isLoading} type="primary">
              Создать тему
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};
