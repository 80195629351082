import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema } from 'yup';

import { notify } from '@utils/notify';

import { boardCommonKeys } from './boardCommonKeys';
import { createBoardValidation, IBoardCreateRequest } from './useMutateBoardCreate';

export type IUpdateBoardRequest = IBoardCreateRequest & {
  id: number;
};

export const updateBoardValidation: ObjectSchema<IUpdateBoardRequest> =
  createBoardValidation.concat(
    object({
      id: number().required(),
    }),
  );

export const updateBoardRequest = ({ id, ...payload }: IUpdateBoardRequest) =>
  axios.patch(`/chess-boards/${id}`, payload);

export const useMutateBoardUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, IUpdateBoardRequest>(updateBoardRequest, {
    onError: () => {
      notify('error', 'Повторите попытку позже');
    },
    onSuccess: () => {
      notify('success', 'Доска успешно обновлена');

      queryClient.invalidateQueries(boardCommonKeys.boards);
    },
  });
};
