import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { appPromotionsQueryKeys } from '@shop/appPromotions/api/appPromotionsQueryKeys';
import { AppPromotionStatusEnumKeys } from '@shop/appPromotions/types/enums/AppPromotionStatusEnum';
import { IAppPromotion } from '@shop/appPromotions/types/IAppPromotion';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

export type IAppPromotionsRequest = IPageAbleRequest & {
  createdByUserId?: number;
  statusNot?: AppPromotionStatusEnumKeys;
};

type IAppPromotionsResponse = AxiosResponse<IPageAbleResponse<IAppPromotion>>;

export const appPromotionsRequest = async (
  params?: IAppPromotionsRequest,
): Promise<IAppPromotionsResponse> => {
  return await axios.get('/iap-promotions', { params });
};

export function useQueryAppPromotions(params?: IAppPromotionsRequest) {
  return useQuery<IAppPromotionsResponse, AxiosError>(
    [appPromotionsQueryKeys.appPromotions, params],
    () => appPromotionsRequest(params),
  );
}
