import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import axios, { AxiosError } from 'axios';

import { authQueryKeys } from '@auth/api/authQueryKeys';
import { IStaff } from '@staff/types/IStaff';

export const getMeRequest = async (): Promise<IStaff> => {
  const { data } = await axios.get<IStaff>('/staff/me');
  return data;
};

export function useQueryMe() {
  const navigate = useNavigate();

  return useQuery<IStaff, AxiosError>(authQueryKeys.me, getMeRequest, {
    enabled: !!localStorage.getItem('token'),
    onError: (error) => {
      if (error.response?.status == 401) {
        message.warning({
          content: 'Срок действия авторизации истек',
          key: 'authorizationErrorMessage',
        });
        localStorage.removeItem('token');
        navigate('/login');
      }
    },
    retry: false,
  });
}
