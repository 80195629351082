import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { exerciseGroupQueryKeys } from '@exercises/exerciseGroups/api/exerciseGroupQueryKeys';
import { exerciseQueryKeys } from '@exercises/exercises/api/exerciseQueryKeys';
import { notify } from '@utils/notify';

type IExerciseGroupDeleteRequest = { id: number };

type IExerciseGroupDeleteResponse = AxiosResponse<void>;

export const ExerciseGroupDeleteRequest = async ({
  id,
}: IExerciseGroupDeleteRequest): Promise<IExerciseGroupDeleteResponse> =>
  await axios.delete(`/exercise-groups/${id}`);

export function useMutateExerciseGroupDelete() {
  const queryClient = useQueryClient();

  return useMutation<IExerciseGroupDeleteResponse, AxiosError, IExerciseGroupDeleteRequest>(
    ExerciseGroupDeleteRequest,
    {
      onSuccess: () => {
        notify('error', 'Группа упражнений удалена');

        queryClient.invalidateQueries(exerciseGroupQueryKeys.exercisesGroups);
        queryClient.invalidateQueries(exerciseGroupQueryKeys.exerciseGroup);
        queryClient.invalidateQueries(exerciseQueryKeys.exercises);
        queryClient.invalidateQueries(exerciseQueryKeys.exercise);
      },
    },
  );
}
