import { ReactNode, useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { Empty, Skeleton, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosError, AxiosResponse } from 'axios';
import Icon from '@ant-design/icons';

import { IAvatar } from '@collections/avatars/types/IAvatar';
import { IBoardGroup } from '@collections/board-groups/types/IBoardGroup';
import { IBoard } from '@collections/boards/types/IBoard';
import { IChessPiecesSet } from '@collections/chess-pieces/types/IChessPiecesSet';
import { IChessPiecesGroup } from '@collections/chess-pieces-groups/types/IChessPiecesGroup';
import { ChessKnightIcon } from '@components/atoms/icons';
import { IPayingUser } from '@dashboards/types/IPayingUser';
import { IExerciseGroup } from '@exercises/exerciseGroups/types/IExerciseGroup';
import { IExercise } from '@exercises/exercises/types/IExercise';
import { IFen } from '@exercises/fens/types/IFen';
import { IAssessmentExercise } from '@exercises/levelTesting/types/IAssessmentExercise';
import { IMapStage } from '@exercises/map-stages/types/IMapStage';
import { IAppSetting } from '@settings/appSettings/types/IAppSetting';
import { IPreSubscriber } from '@settings/preSubscribers/types/IPreSubscriber';
import { IPromocode } from '@settings/promocodes/types/IPromocode';
import { IPromocodeGroup } from '@settings/promocodes/types/IPromocodeGroup';
import { IVersion } from '@settings/versions/types/IVersion';
import { IAppProduct } from '@shop/appProducts/types/IAppProduct';
import { IAppPromotion } from '@shop/appPromotions/types/IAppPromotion';
import { IStaff } from '@staff/types/IStaff';
import { TableFilter } from '@typess/IFilters';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';
import { IUser } from '@users/types/IUser';
import { IUserCoinPurchase } from '@users/types/IUserCoinPurchase';
import { IUserGameMatch } from '@users/types/IUserGameMatch';
import { IUserMapStage } from '@users/types/IUserMapStage';
import { IVideo } from '@videos/types/IVideo';
import { IVideoFile } from '@videos/types/IVideoFile';

import { IMatchPvp } from '../../../modules/matches/types/IMatchPvp';
import { TableHeader } from './organismComponents/TableHeader';
import { TableSizeChanger } from './organismComponents/TableSizeChanger';

type IAdminTableContent =
  | IAppProduct
  | IAppPromotion
  | IAppSetting
  | IExercise
  | IFen
  | IExerciseGroup
  | IStaff
  | IPreSubscriber
  | IPromocodeGroup
  | IPromocode
  | IVideo
  | IVideoFile
  | IUser
  | IUserMapStage
  | IUserCoinPurchase
  | IUserGameMatch
  | IVersion
  | IChessPiecesSet
  | IBoard
  | IAvatar
  | IAssessmentExercise
  | IMapStage
  | IPayingUser
  | IBoardGroup
  | IChessPiecesGroup
  | IMatchPvp;

type AdminTableType<T extends IAdminTableContent> = {
  columns: ColumnsType<T>;
  emptyDescription?: ReactNode;
  position?: ('topLeft' | 'bottomLeft')[];
  query: UseQueryResult<AxiosResponse<IPageAbleResponse<T>>, AxiosError>;
  rowClassName?: (record: T, row: number) => string;
  rowKey?: keyof T;
  tableFilter: TableFilter;
};

export const AdminTable = <T extends IAdminTableContent>({
  columns,
  emptyDescription,
  position,
  query: { data, isLoading },
  rowClassName,
  rowKey,
  tableFilter: { filtered, filters, onFilter },
}: AdminTableType<T>) => {
  const pageAble = data?.data;
  const [total, setTotal] = useState(pageAble?.totalElements || 1);

  useEffect(() => {
    if (!isLoading) {
      setTotal(pageAble?.totalElements || 1);
      if ((filtered.page || 1) > (pageAble?.totalPages || 1)) {
        onFilter({ page: pageAble?.totalPages || 1 });
      }
    }
  }, [data]);

  return (
    <Table
      className="table-custom-options"
      columns={columns}
      components={
        filters && {
          header: {
            row: (props) => (
              <TableHeader
                props={props}
                tableFilterProps={{ filtered, filters: filters, onFilter }}
              />
            ),
          },
        }
      }
      dataSource={pageAble?.content}
      loading={{
        indicator: (
          <Icon className="text-xl" component={(props) => <ChessKnightIcon {...props} />} spin />
        ),
        spinning: isLoading,
      }}
      locale={{
        emptyText: isLoading ? (
          <div className="p-2">
            <Skeleton active round />
            <Skeleton active round />
          </div>
        ) : (
          <Empty description={emptyDescription} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
      }}
      onChange={(pagination) => {
        onFilter({
          page: pagination.current || 1,
          pageSize: pagination.pageSize || 10,
        });
      }}
      pagination={{
        current: typeof filtered.page == 'string' ? parseInt(filtered.page) : filtered.page,
        defaultCurrent: 1,
        hideOnSinglePage: false,
        pageSize: filtered.pageSize,
        position: position || ['topLeft', 'bottomLeft'],
        responsive: true,
        selectComponentClass: TableSizeChanger,
        showSizeChanger: true,
        total,
      }}
      rowClassName={rowClassName}
      rowKey={rowKey?.toString() ?? 'id'}
      scroll={{ x: true }}
      showSorterTooltip={false}
    />
  );
};
