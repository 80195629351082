import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IAssessmentExercise } from '../types/IAssessmentExercise';
import { assessmentExerciseQueryKeys } from './assessmentExerciseQueryKeys';

export type IAssessmentExerciseRequest = { id: number };
type IAssessmentExerciseResponse = AxiosResponse<IAssessmentExercise>;

export const assessmentExerciseRequest = async ({
  id,
}: IAssessmentExerciseRequest): Promise<IAssessmentExerciseResponse> =>
  await axios.get(`/admin/assessment-exercises/${id}`);

export function useQueryAssessmentExercise(params: IAssessmentExerciseRequest) {
  return useQuery<IAssessmentExerciseResponse, AxiosError>(
    [assessmentExerciseQueryKeys.assessmentExercise, params],
    () => assessmentExerciseRequest(params),
    { enabled: params.id > 0 },
  );
}
