import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { ExerciseTypeEnumKeys } from '@exercises/exercises/types/enums/ExerciseTypeEnum';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';
import { IUserPlayLevelNumber } from '@users/types/enums/UserPlayLevel';

import { IAssessmentExercise } from '../types/IAssessmentExercise';
import { assessmentExerciseQueryKeys } from './assessmentExerciseQueryKeys';

export type IAssessmentExercisesRequest = IPageAbleRequest & {
  createdByUserId?: number | string;
  id?: number | string;
  playLevels?: IUserPlayLevelNumber;
  type?: ExerciseTypeEnumKeys;
};

type IAssessmentExercisesResponse = AxiosResponse<IPageAbleResponse<IAssessmentExercise>>;

export const assessmentExercisesRequest = async (
  params?: IAssessmentExercisesRequest,
): Promise<IAssessmentExercisesResponse> =>
  await axios.get('/admin/assessment-exercises', { params });

export function useQueryAssessmentExercises(params?: IAssessmentExercisesRequest) {
  return useQuery<IAssessmentExercisesResponse, AxiosError>(
    [assessmentExerciseQueryKeys.assessmentExercises, params],
    () => assessmentExercisesRequest(params),
  );
}
