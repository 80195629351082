export enum ChessPiecesSetStatusEnum {
  ACTIVE = 'Активный',
  DRAFT = 'Черновой',
}

export type ChessPiecesSetStatusEnumKeys = keyof typeof ChessPiecesSetStatusEnum;

export enum ChessPiecesSetTypeEnum {
  FREE = 'Бесплатный',
  PAID = 'Платный',
  RESTRICTED = 'Ограниченный',
}

export type ChessPiecesSetTypeEnumKeys = keyof typeof ChessPiecesSetTypeEnum;
