import { BrainstormLevelEnum } from '@users/types/enums/BrainstormLevelEnum';

export type UserBrainstormExercise = {
  boardState: string;
  id: number;
  isSolved: boolean;
  moveSequence: string;
  name: string;
  passedInMilliseconds: number;
  passedInSeconds: number;
  rating: number;
};

export const userBrainstormExerciseKeys = {
  boardState: 'boardState',
  id: 'id',
  isSolved: 'isSolved',
  moveSequence: 'moveSequence',
  name: 'name',
  passedInMilliseconds: 'passedInMilliseconds',
  passedInSeconds: 'passedInSeconds',
  rating: 'rating',
};

export type IUserBrainstorm = {
  avgPassedTimeInSeconds: number;
  createdDate: string;
  id: number;
  mode: keyof typeof BrainstormLevelEnum;
  passedTimeInSeconds: number;
  problems: UserBrainstormExercise[];
  problemsCount: number;
  solvedCount: number;
  streak: number;
  type: 'MIN3' | 'MIN5';
  useType: 'FREE' | 'COINS' | 'PREMIUM';
};

export const userBrainstormKeys: Record<keyof IUserBrainstorm, string> = {
  avgPassedTimeInSeconds: 'avgPassedTimeInSeconds',
  createdDate: 'createdDate',
  id: 'id',
  mode: 'mode',
  passedTimeInSeconds: 'passedTimeInSeconds',
  problems: 'problems',
  problemsCount: 'problemsCount',
  solvedCount: 'solvedCount',
  streak: 'streak',
  type: 'type',
  useType: 'useType',
};
