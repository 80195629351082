import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { promocodesQueryKeys } from '@settings/promocodes/api/promocodesQueryKeys';
import { IPromocode } from '@settings/promocodes/types/IPromocode';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

export type IPromocodesRequest = IPageAbleRequest & {
  code?: string;
  promocodeGroupId: number;
};

type IPromocodesResponse = AxiosResponse<IPageAbleResponse<IPromocode>>;

export const promocodesRequest = async (
  params?: IPromocodesRequest,
): Promise<IPromocodesResponse> => {
  return await axios.get('/promocodes', { params });
};

export function useQueryPromocodes(params?: IPromocodesRequest) {
  return useQuery<IPromocodesResponse, AxiosError>(
    [promocodesQueryKeys.promocodes, params],
    () => promocodesRequest(params),
    { enabled: (params?.promocodeGroupId || 0) > 0 },
  );
}
