import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { tagQueryKeys } from '@tags/api/tagQueryKeys';
import { AuthorRankEnumKeys } from '@tags/types/AuthorRankEnum';
import { TagTypeEnumKeys } from '@tags/types/TagTypeEnum';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

import { ITag } from '../types/ITag';

export type ITagsRequest = IPageAbleRequest & {
  authorRank?: AuthorRankEnumKeys;
  createdByUserId?: number | string;
  id?: number | string;
  name?: string;
  type?: TagTypeEnumKeys;
};

type TagsResponse = AxiosResponse<IPageAbleResponse<ITag>>;

export const tagsRequest = async (params?: ITagsRequest): Promise<TagsResponse> =>
  await axios.get(`/tags`, { params });

export function useQueryTags(params?: ITagsRequest) {
  return useQuery<TagsResponse, AxiosError>([tagQueryKeys.tags, params], () => tagsRequest(params));
}
