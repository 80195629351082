export const dashboardQueryKeys = {
  payingUsers: 'payingUsers',
  payingUsersStats: 'payingUsersStats',
  paymentStatsByDay: 'paymentStatsByDay',
  userPlayLevel: 'usersPlayLevel',
  usersAgeCategory: 'usersAgeCategory',
  usersAgeStats: 'usersAgeStats',
  usersCountryStats: 'usersCountryStats',
  usersMapStagePassedStats: 'usersMapStagePassedStats',
  usersMonthlyStats: 'usersMonthlyStats',
};
