import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum ExercisesPageActionEnum {
  'createTest',
  'createProblem',
  'createCustomGame',
  'edit',
  'view',
}

export type ExercisesPageActions = {
  action: keyof typeof ExercisesPageActionEnum;
  actionId: number;
};

export const exercisesPageActions: IPageActions<ExercisesPageActions> = {
  action: {
    allowed: (value) => Object.keys(ExercisesPageActionEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
};
