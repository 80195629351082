import { useQuery } from 'react-query';
import axios, { AxiosError } from 'axios';

import { staffsQueryKeys } from '@staff/api/staffsQueryKeys';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

import { IStaff } from '../types/IStaff';

type IStaffRequest = { id: number };

export const staffRequest = async (params: IStaffRequest): Promise<IStaff> => {
  return await axios
    .get<IPageAbleResponse<IStaff>>('/staff', { params })
    .then((data) => data.data.content[0]);
};

export function useQueryStaff(params: IStaffRequest) {
  return useQuery<IStaff, AxiosError>([staffsQueryKeys.staff, params], () => staffRequest(params), {
    enabled: params.id > 0,
  });
}
