import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IAvatarGroup } from '../types/IAvatarGroup';
import { avatarGroupQueryKeys } from './avatarGroupQueryKeys';

export type IAvatarGroupRequest = { id: number };
type IAvatarGroupResponse = AxiosResponse<IAvatarGroup>;

export const avatarGroupRequest = async (
  params: IAvatarGroupRequest,
): Promise<IAvatarGroupResponse> => await axios.get(`/avatar-groups/${params.id}`);

export function useQueryAvatarGroup(params: IAvatarGroupRequest) {
  return useQuery<IAvatarGroupResponse, AxiosError>(
    [avatarGroupQueryKeys.avatarGroup, params],
    () => avatarGroupRequest(params),
    { enabled: params.id > 0 },
  );
}
