import { IPromoCodeType } from './enums/PromocodeTypeEnum';

export type IPromocodeGroup = {
  createdDate: Date;
  expireDate?: Date | null;
  freeUseMonths: number;
  groupName: string;
  id: number;
  promocodeCount?: number;
  promocodeUsedCount?: number;
  type: IPromoCodeType;
  updatedDate: Date;
};

export const promocodeGroupKeys: Record<keyof IPromocodeGroup, string> = {
  createdDate: 'createdDate',
  expireDate: 'expireDate',
  freeUseMonths: 'freeUseMonths',
  groupName: 'groupName',
  id: 'id',
  promocodeCount: 'promocodeCount',
  promocodeUsedCount: 'promocodeUsedCount',
  type: 'type',
  updatedDate: 'updatedDate',
};
