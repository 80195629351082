import { useQueryMe } from '@auth/api/useQueryMe';
import { IPermissions } from '@typess/permissions/IPermissions';

export function checkEditPermission(permissions: IPermissions): boolean {
  const me = useQueryMe();

  if (me.data?.authorities) {
    return me.data.authorities.some((authority) =>
      ['ROLE_ADMIN', permissions.edit, permissions.restricted].includes(authority),
    );
  }

  return false;
}
