import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';
import { userQueryKeys } from '@users/api/userQueryKeys';
import { IUserGameMatch } from '@users/types/IUserGameMatch';

export type IUserGameMatchesRequest = IPageAbleRequest & { id: number };

type IUserGameMatchesResponse = AxiosResponse<IPageAbleResponse<IUserGameMatch>>;

export const userGameMatchesRequest = async ({
  id,
  ...params
}: IUserGameMatchesRequest): Promise<IUserGameMatchesResponse> => {
  return await axios.get(`/customers/${id}/game-matches`, { params });
};

export function useQueryUserGameMatches(params: IUserGameMatchesRequest) {
  return useQuery<IUserGameMatchesResponse, AxiosError>(
    [userQueryKeys.userGameMatches, params],
    () => userGameMatchesRequest(params),
  );
}
