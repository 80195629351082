import { CheckboxFilter } from '@components/molecules/filters/static/CheckboxFilter';
import { InputFilter } from '@components/molecules/filters/static/InputFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { MapStageStatusEnum } from '@exercises/map-stages/types/enums/MapStageStatus';
import { MapStageTypeEnum } from '@exercises/map-stages/types/enums/MapStageType';
import { mapStageKeys } from '@exercises/map-stages/types/IMapStage';
import { IFilters } from '@typess/IFilters';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';

export const mapStageFilters: IFilters = {
  ...paginationBaseFilters,

  id: {
    input: <InputFilter placeholder="Введите ID" />,
    sort: true,
  },
  [mapStageKeys.playLevel]: {
    input: (
      <CheckboxFilter
        options={Object.entries(userPlayLevels).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    queries: [{ key: 'playLevels' }],
    sort: true,
    sortOrderReversed: true,
  },
  [mapStageKeys.stageNumber]: {
    sort: true,
  },
  [mapStageKeys.status]: {
    input: (
      <CheckboxFilter
        options={Object.entries(MapStageStatusEnum).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },
  [mapStageKeys.video]: {
    input: <InputFilter placeholder="Введите название" />,
    queries: [{ key: 'title' }],
    sort: true,
  },
  [mapStageKeys.exercises]: {
    id: {
      input: <InputFilter placeholder="Введите ID" />,
      queries: [{ key: 'id' }],
      sort: true,
    },
  },
  [mapStageKeys.fenRecord]: {
    input: <InputFilter placeholder="Введите FEN" />,
  },
  [mapStageKeys.type]: {
    input: (
      <CheckboxFilter
        options={Object.entries(MapStageTypeEnum).map(([value, label]) => ({ label, value }))}
      />
    ),
    sort: true,
  },
};
