import { IVideo } from '@videos/types/IVideo';

export type AllowedSteps = Record<
  0 | 1 | 2,
  { disabled: boolean; status: 'error' | 'process' | 'finish' | 'wait' }
>;

export const checkAllowedSteps = (video: IVideo | undefined) => {
  const steps: AllowedSteps = {
    0: {
      disabled: false,
      status: 'process',
    },
    1: {
      disabled: true,
      status: 'wait',
    },
    2: {
      disabled: true,
      status: 'wait',
    },
  };

  if (
    video &&
    video.localizations?.en?.title &&
    video.localizations.en.videoFile?.uuid &&
    video.localizations.en.videoFile?.videoFileName &&
    video.localizations.en.previewUrl &&
    video.localizations.ru?.title &&
    video.localizations.ru.previewUrl &&
    ((video.localizations.ru.videoFile?.uuid && video.localizations.ru.videoFile.videoFileName) ||
      video.localizations.ru.subtitlesUrl)
  ) {
    if (video.isCartoon && video.price) {
      steps[0].status = 'finish';
      steps[1].status = 'finish';
      steps[2].status = 'finish';
      return steps;
    }
    if (!video.localizations.ru.author?.id || !video.localizations.en.author?.id) {
      return steps;
    }
    steps[0].status = 'finish';
    steps[1].status = 'process';
    steps[1].disabled = false;
  } else return steps;

  if (video.price && video.tags?.length && video.playLevel) {
    steps[1].status = 'finish';
    steps[2].status = 'process';
    steps[2].disabled = false;
  } else return steps;

  if (
    video.test?.correctOption &&
    video.test.localizations?.en?.question &&
    (video.test.localizations.en.answerOptions.length || 0) > 3 &&
    video.test.localizations?.ru?.question &&
    (video.test.localizations.ru.answerOptions.length || 0) > 3
  ) {
    steps[2].status = 'finish';
  }

  return steps;
};
