import { RadioFilter } from '@components/molecules/filters/static/RadioFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { IFilters } from '@typess/IFilters';

import { BoardStatusEnum } from '../types/enums/boardStatusEnum';
import { BoardTypeEnum } from '../types/enums/boardTypeEnum';
import { boardKeys } from '../types/IBoard';

export const boardFilters: IFilters = {
  ...paginationBaseFilters,
  [boardKeys.createdDate]: {
    sort: true,
  },
  [boardKeys.status]: {
    input: (
      <RadioFilter
        options={Object.entries(BoardStatusEnum).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },
  [boardKeys.type]: {
    input: (
      <RadioFilter
        options={Object.entries(BoardTypeEnum).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },
};
