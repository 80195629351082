import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IAvatar } from '../types/IAvatar';
import { avatarCommonKeys } from './avatarCommonKeys';

export type AvatarStatusUpdateRequest = Pick<IAvatar, 'id' | 'status'>;

type AvatarStatusUpdateResponse = AxiosResponse<void>;

export const avatarStatusUpdateRequest = async ({
  id,
  ...payload
}: AvatarStatusUpdateRequest): Promise<AvatarStatusUpdateResponse> => {
  return await axios.patch(`/avatars/${id}`, payload);
};

export function useMutateAvatarStatusUpdate() {
  const queryClient = useQueryClient();

  return useMutation<AvatarStatusUpdateResponse, AxiosError, AvatarStatusUpdateRequest>(
    avatarStatusUpdateRequest,
    {
      onSuccess: () => queryClient.invalidateQueries(avatarCommonKeys.avatars),
    },
  );
}
