import { FC } from 'react';
import { Chessboard } from 'react-chessboard';
import { Button, Switch as AntSwitch } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select, Switch } from 'formik-antd';

import { CountryImageText } from '@components/atoms/CountryImageText';
import {
  exerciseProblemEditValidation,
  IExerciseProblemEditRequest,
  useMutateExerciseProblemEdit,
} from '@exercises/exercises/api/useMutateExerciseProblemEdit';
import { ExerciseGamePhaseEnum } from '@exercises/exercises/types/enums/ExerciseGamePhaseEnum';
import { IExercise } from '@exercises/exercises/types/IExercise';
import { IExerciseProblem } from '@exercises/exercises/types/IExerciseProblem';
import { useQueryTags } from '@tags/api/useQueryTags';
import { IUserPlayLevelNumber, userPlayLevels } from '@users/types/enums/UserPlayLevel';
import { notify } from '@utils/notify';
import { useQueryVideos } from '@videos/api/useQueryVideos';

export const ProblemEditFormik: FC<{
  exercise: IExercise & { problem: IExerciseProblem };
  onCancel: () => void;
}> = ({ exercise, onCancel }) => {
  const problemEdit = useMutateExerciseProblemEdit();
  const tags = useQueryTags({ type: 'TOPIC' });
  const videos = useQueryVideos({ isCartoon: false });

  return (
    <Formik<IExerciseProblemEditRequest>
      enableReinitialize
      initialValues={{
        ...exercise.problem,
        gamePhase: exercise.problem.gamePhase || 'MIDDLEGAME',
        status: exercise.problem.status || 'ACTIVE',
        tagIds: exercise.problem.tags.map((tag) => tag.id) || [],
        useForPuzzleRush: !!exercise.useForPuzzleRush,
        videoId: exercise.video?.id,
      }}
      onSubmit={(values, { resetForm }) => {
        problemEdit.mutateAsync(values).then((data) => {
          if (data.status == 201 || data.status == 200) {
            notify('success', 'Задача изменена');
            onCancel();
            resetForm();
          }
        });
      }}
      validationSchema={exerciseProblemEditValidation}
    >
      {({ setFieldValue, values }) => {
        return (
          <Form className="space-y-5" layout="vertical">
            <div className="grid place-items-center overflow-x-auto">
              <div className="shadow-md rounded-sm">
                <Chessboard
                  arePiecesDraggable={false}
                  boardWidth={360}
                  position={exercise.problem.boardState || ''}
                />
              </div>

              <p className="mt-2 mb-0 text-center" style={{ maxWidth: 360 }}>
                {exercise.problem.moveSequence}
              </p>
            </div>

            <FormItem
              label={<CountryImageText src="ru" text="Текст в приложении на русском" width={15} />}
              name="localizations.ru.name"
            >
              <Input
                name="localizations.ru.name"
                placeholder="Текст в приложении на русском"
                suffix
              />
            </FormItem>
            <FormItem
              label={
                <CountryImageText src="en" text="Текст в приложении на английском" width={15} />
              }
              name="localizations.en.name"
            >
              <Input
                name="localizations.en.name"
                placeholder="Текст в приложении на английском"
                showCount
                suffix
              />
            </FormItem>

            <FormItem label="Уровень" name="playLevels">
              <Select
                mode="multiple"
                name="playLevels"
                options={Object.keys(userPlayLevels)
                  .map(Number)
                  .map((playLevel) => ({
                    disabled:
                      values.playLevels.length > 0 &&
                      !values.playLevels.includes(playLevel as IUserPlayLevelNumber),
                    label: userPlayLevels[playLevel],
                    value: playLevel,
                  }))}
                showSearch={false}
              />
            </FormItem>

            <FormItem label="Тема" name="tagIds">
              <Select
                mode="multiple"
                name="tagIds"
                optionFilterProp="label"
                options={
                  tags.data?.data.content.map((tag) => ({
                    label: tag.localizations.en,
                    value: tag.id,
                  })) || []
                }
              />
            </FormItem>

            <FormItem label="Видеоурок" name="videoId">
              <Select
                allowClear
                className="gap-y-2"
                name="videoId"
                optionFilterProp="label"
                options={
                  videos.data?.data.content.map((video) => ({
                    label: video.localizations.en?.fullTitle,
                    value: video.id,
                  })) || []
                }
                showSearch
              />
            </FormItem>

            <FormItem label="Стадия игры" name="gamePhase">
              <Select
                name="gamePhase"
                options={Object.entries(ExerciseGamePhaseEnum).map(([value, label]) => ({
                  label,
                  value,
                }))}
              />
            </FormItem>

            <div className="grid md:grid-cols-5 place-items-center text-center">
              <FormItem
                className="flex-row"
                label={<span className="text-center">Мозговой штурм</span>}
                name="useForPuzzleRush"
              >
                <Switch name="useForPuzzleRush" />
              </FormItem>
              <FormItem
                label={<span className="text-center">Капитализация преимущества</span>}
                name="isCapitalization"
              >
                <Switch name="isCapitalization" />
              </FormItem>

              <FormItem label={<span className="text-center">Тактика</span>} name="isTactic">
                <Switch name="isTactic" />
              </FormItem>

              <FormItem
                label={<span className="text-center">Пешечная структура</span>}
                name="isPawnStructure"
              >
                <Switch name="isPawnStructure" />
              </FormItem>

              <Form.Item
                label={<span className="text-center">Запустить немедленно</span>}
                name="status"
              >
                <Input hidden name="status" suffix />
                <AntSwitch
                  checked={values.status == 'ACTIVE'}
                  onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
                />
              </Form.Item>
            </div>

            <div className="grid md:grid-cols-2 gap-5">
              <Button block onClick={onCancel}>
                Отменить
              </Button>

              <Button block htmlType="submit" loading={problemEdit.isLoading} type="primary">
                <span className="max-w-full overflow-ellipsis overflow-hidden">
                  {values['status'] == 'ACTIVE' ? 'Изменить и запустить' : 'Сохранить как черновик'}
                </span>
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
