import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';
import { videoQueryKeys } from '@videos/api/videoQueryKeys';
import { IVideoFile } from '@videos/types/IVideoFile';

export type IVideoFilesRequest = IPageAbleRequest & {
  isUploading?: boolean;
  title?: string;
};

type IVideoFilesResponse = AxiosResponse<IPageAbleResponse<IVideoFile>>;

export const videoFilesRequest = async (
  params?: IVideoFilesRequest,
): Promise<IVideoFilesResponse> => {
  return await axios.get('/videos/files', { params });
};

export function useQueryVideoFiles(params?: IVideoFilesRequest) {
  return useQuery<IVideoFilesResponse, AxiosError>([videoQueryKeys.videoFiles, params], () =>
    videoFilesRequest(params),
  );
}
