import { FC, useState } from 'react';
import { Button, Checkbox, CheckboxOptionType, Space } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { SearchOutlined } from '@ant-design/icons';

import { FilterRender } from '@typess/IFilters';

interface CheckboxFilterInterface {
  filterRender?: FilterRender;
  options: CheckboxOptionType[];
}

export const CheckboxFilter: FC<CheckboxFilterInterface> = ({ filterRender, options }) => {
  if (!filterRender) {
    return null;
  }

  const { dataIndex, filtered, onFilter, queries } = filterRender;

  const key = queries?.[0]?.key || dataIndex;
  const [value, setValue] = useState<CheckboxValueType[]>(filtered[key]);
  const onCheckboxSubmit = (clear = false) => onFilter({ [key]: clear ? undefined : value });

  return (
    <div className="p-2 space-y-3 bg-white shadow-filter max-w-tiny">
      <Checkbox.Group
        className="text-center justify-center"
        defaultValue={value}
        onChange={(checkedValue) => {
          setValue(checkedValue);
        }}
      >
        <Space className="text-center w-full" direction="vertical">
          {options.map((option) => (
            <Checkbox
              className="w-full"
              key={`radio-${option.value}`}
              value={option.value.toString()}
            >
              {option.label}
            </Checkbox>
          ))}
        </Space>
      </Checkbox.Group>
      <div className="grid gap-1 grid-cols-2">
        <Button
          icon={<SearchOutlined />}
          onClick={() => onCheckboxSubmit()}
          size="small"
          type="primary"
        >
          Поиск
        </Button>
        <Button
          onClick={() => {
            onCheckboxSubmit(true);
          }}
          size="small"
        >
          Очистить
        </Button>
      </div>
    </div>
  );
};
