import { FC } from 'react';
import { Button } from 'antd';

import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { SettingTabs } from '@settings/appSettings/components/molecules/SettingTabs';
import { useMutatePromocodeGroupDownload } from '@settings/promocodes/api/useMutatePromocodeGroupDownload';
import {
  IPromocodeGroupsRequest,
  useQueryPromocodeGroups,
} from '@settings/promocodes/api/useQueryPromocodeGroups';
import { PromocodeGroupCreateModal } from '@settings/promocodes/components/organisms/PromocodeGroupCreateModal';
import { PromocodeGroupModal } from '@settings/promocodes/components/organisms/PromocodeGroupModal';
import { promocodeGroupsColumns } from '@settings/promocodes/consts/columns/promocodeGroupsColumns';
import { promocodeGroupsFilters } from '@settings/promocodes/consts/filters/promocodeGroupsFilters';
import { promocodesFilters } from '@settings/promocodes/consts/filters/promocodesFilters';
import {
  PromocodeGroupsPageActions,
  promocodeGroupsPageParams,
} from '@settings/promocodes/consts/pageActions/PromocodeGroupsPageActions';
import { settingPermissions } from '@typess/permissions/ISettingPermission';
import { checkEditPermission } from '@utils/checkEditPermission';

const PromocodeGroupsPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IPromocodeGroupsRequest,
    PromocodeGroupsPageActions
  >(promocodesFilters, promocodeGroupsPageParams);

  const promocodeGroups = useQueryPromocodeGroups(filtered);
  const promocodeGroupDownload = useMutatePromocodeGroupDownload();

  const canEditSettings = checkEditPermission(settingPermissions);

  return (
    <>
      <PageMeta
        selectedMenuKeys={['settings']}
        subTitle={
          actioned.action == 'create'
            ? 'Создать группу'
            : actioned.action == 'view'
            ? `Группа промокодов #${actioned.actionId}`
            : ''
        }
        title="Промокоды"
      />

      <SettingTabs active="promocodes" />

      {canEditSettings && (
        <div className="flex justify-end">
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'create' })}
            size="large"
            type="primary"
          >
            Создать промокод
          </Button>
        </div>
      )}

      <AdminTable
        columns={promocodeGroupsColumns({
          onClickDownload: promocodeGroupDownload.mutateAsync,
          onClickView: ({ id }) => setActioned({ action: 'view', actionId: id }),
        })}
        emptyDescription={<p>Группы промокодов не найдены</p>}
        query={promocodeGroups}
        tableFilter={{ filtered, filters: promocodeGroupsFilters, onFilter }}
      />

      <PromocodeGroupModal
        canEditSettings={canEditSettings}
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onCancel={clearParams}
      />

      <PromocodeGroupCreateModal isOpen={actioned.action == 'create'} onCancel={clearParams} />
    </>
  );
};

export default PromocodeGroupsPage;
