import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';
import { userQueryKeys } from '@users/api/userQueryKeys';
import { IUserCoinPurchase } from '@users/types/IUserCoinPurchase';

export type IUserCoinPurchasesRequest = IPageAbleRequest & { id: number };

type IUserCoinPurchasesResponse = AxiosResponse<IPageAbleResponse<IUserCoinPurchase>>;

export const userCoinPurchasesRequest = async ({
  id,
  ...params
}: IUserCoinPurchasesRequest): Promise<IUserCoinPurchasesResponse> => {
  return await axios.get(`/customers/${id}/coin-purchases`, { params });
};

export function useQueryUserCoinPurchases(params: IUserCoinPurchasesRequest) {
  return useQuery<IUserCoinPurchasesResponse, AxiosError>(
    [userQueryKeys.userCoinPurchases, params],
    () => userCoinPurchasesRequest(params),
  );
}
