import { FC } from 'react';
import { Button } from 'antd';

import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { useMutateAppProductStatus } from '@shop/appProducts/api/useMutateAppProductStatus';
import {
  IAppProductsRequest,
  useQueryAppProducts,
} from '@shop/appProducts/api/useQueryAppProducts';
import { ShopTabs } from '@shop/appProducts/components/molecules/ShopTabs';
import { ProductCreateModal } from '@shop/appProducts/components/organisms/ProductCreateModal';
import { productsColumns } from '@shop/appProducts/consts/columns/productsColumns';
import { productsFilters } from '@shop/appProducts/consts/filters/productsFilters';
import {
  ShopPageActions,
  shopPageParams,
} from '@shop/appProducts/consts/pageActions/ShopPageActions';
import { shopPermissions } from '@typess/permissions/IShopPermission';
import { checkEditPermission } from '@utils/checkEditPermission';

export const ShopProductsPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IAppProductsRequest,
    ShopPageActions
  >(productsFilters, shopPageParams);

  const appProducts = useQueryAppProducts(filtered);
  const appProductStatusUpdate = useMutateAppProductStatus();

  const canEditShop = checkEditPermission(shopPermissions);

  return (
    <>
      <PageMeta
        selectedMenuKeys={['shop']}
        subTitle={actioned.action == 'create' ? 'Создать товар' : ''}
        title="Товары — Магазин"
      />

      <ShopTabs active="products" />

      {canEditShop && (
        <div className="flex justify-end">
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'create' })}
            size="large"
            type="primary"
          >
            Добавить товар
          </Button>
        </div>
      )}

      <AdminTable
        columns={productsColumns({
          canEdit: canEditShop,
          onClickPause: appProductStatusUpdate.mutateAsync,
        })}
        emptyDescription={<p>Товары не найдены</p>}
        query={appProducts}
        tableFilter={{ filtered, filters: productsFilters, onFilter }}
      />

      <ProductCreateModal isOpen={actioned.action == 'create'} onCancel={clearParams} />
    </>
  );
};
