import { IAvatar } from '@collections/avatars/types/IAvatar';
import { ILocalization } from '@typess/ILocalization';

export type IAvatarGroup = {
  createdDate: Date;
  id: number;
  items: { avatar: IAvatar; priority: number }[];
  itemsCount?: number;
  localizations: Partial<Record<ILocalization, { name: string }>>;
  priority: null | number;
  status: 'DRAFT' | 'ACTIVE';
  updatedDate: Date;
};

export const avatarGroupKeys: Record<keyof IAvatarGroup, string> = {
  createdDate: 'createdDate',
  id: 'id',
  items: 'items',
  itemsCount: 'itemsCount',
  localizations: 'localizations',
  priority: 'priority',
  status: 'status',
  updatedDate: 'updatedDate',
};
