import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { notify } from '@utils/notify';

import { chessPiecesQueryKeys } from './chessPiecesQueryKeys';

type ChessPiecesSetDeleteRequest = { id: number };

type ChessPiecesSetDeleteResponse = AxiosResponse<void>;

export const chessPiecesSetDeleteRequest = async ({
  id,
}: ChessPiecesSetDeleteRequest): Promise<ChessPiecesSetDeleteResponse> => {
  return await axios.delete(`/chess-piece-sets/${id}`);
};

export function useMutateChessPiecesSetDelete() {
  const queryClient = useQueryClient();

  return useMutation<ChessPiecesSetDeleteResponse, AxiosError, ChessPiecesSetDeleteRequest>(
    chessPiecesSetDeleteRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(chessPiecesQueryKeys.chessPieces);

        notify('error', 'Коллекция фигур удалена');
      },
    },
  );
}
