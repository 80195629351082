import { FC } from 'react';

import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { SettingTabs } from '@settings/appSettings/components/molecules/SettingTabs';
import {
  IPreSubscribersRequest,
  useQueryPreSubscribers,
} from '@settings/preSubscribers/api/useQueryPreSubscribers';
import { preSubscribersColumns } from '@settings/preSubscribers/consts/columns/preSubscribersColumns';
import { preSubscribersFilters } from '@settings/preSubscribers/consts/filters/preSubscribersFilters';

import {
  AppSettingsPageActions,
  appSettingsPageActions,
} from '../../../appSettings/consts/pageActions/AppSettingsPageActions';

const PreSubscribersPage: FC = () => {
  const { filtered, onFilter } = useUrlFilteredActioned<
    IPreSubscribersRequest,
    AppSettingsPageActions
  >(preSubscribersFilters, appSettingsPageActions);

  const preSubscribers = useQueryPreSubscribers(filtered);

  return (
    <>
      <PageMeta selectedMenuKeys={['settings']} title="Пререгистрация" />

      <SettingTabs active="pre-subscribers" />

      <AdminTable
        columns={preSubscribersColumns}
        emptyDescription={<p>Пользователи пре регистрации не найдены</p>}
        query={preSubscribers}
        rowKey="email"
        tableFilter={{ filtered, filters: preSubscribersFilters, onFilter }}
      />
    </>
  );
};

export default PreSubscribersPage;
