import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, ObjectSchema } from 'yup';

import { fenQueryKeys } from '@exercises/fens/api/fenQueryKeys';
import { fenCreateValidation, IFenCreateRequest } from '@exercises/fens/api/useMutateFenCreate';
import { IFen } from '@exercises/fens/types/IFen';

export type IFenEditRequest = IFenCreateRequest & { id: number };

type IFenEditResponse = AxiosResponse<IFen>;

export const fenEditValidation: ObjectSchema<IFenEditRequest> = fenCreateValidation.shape({
  id: number().required(),
});

export const fenEditRequest = async ({
  id,
  ...payload
}: IFenEditRequest): Promise<IFenEditResponse> => await axios.patch(`/fen-records/${id}`, payload);

export function useMutateFenEdit() {
  const queryClient = useQueryClient();

  return useMutation<IFenEditResponse, AxiosError, IFenEditRequest>(fenEditRequest, {
    onSuccess: () => queryClient.invalidateQueries(fenQueryKeys.fens),
  });
}
