import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IBoardGroup } from '../types/IBoardGroup';
import { boardGroupQueryKeys } from './boardGroupQueryKeys';

export type IBoardGroupRequest = { id: number };
type IBoardGroupResponse = AxiosResponse<IBoardGroup>;

export const boardGroupRequest = async (params: IBoardGroupRequest): Promise<IBoardGroupResponse> =>
  await axios.get(`/chess-board-groups/${params.id}`);

export function useQueryBoardGroup(params: IBoardGroupRequest) {
  return useQuery<IBoardGroupResponse, AxiosError>(
    [boardGroupQueryKeys.boardGroup, params],
    () => boardGroupRequest(params),
    { enabled: params.id > 0 },
  );
}
