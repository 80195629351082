import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '@components/molecules/routes/ProtectedRoute';
import { ExerciseGroupsPage } from '@exercises/exerciseGroups/components/pages/ExerciseGroupsPage';
import ExercisesPage from '@exercises/exercises/components/pages/ExercisesPage';
import FensPage from '@exercises/fens/components/pages/FensPage';
import NotFoundPage from '@pages/errors/NotFoundPage';
import { exercisePermissions } from '@typess/permissions/IExercisePermission';

import LevelTestingPage from './levelTesting/components/pages/LevelTestingPage';
import MapStagesPage from './map-stages/components/pages/MapStagesPage';

export const ExerciseRouter = () => {
  return (
    <ProtectedRoute permissions={Object.values(exercisePermissions)}>
      <Routes>
        <Route element={<ExercisesPage />} index />
        <Route element={<ExerciseGroupsPage />} path="groups" />
        <Route element={<FensPage />} path="fens" />
        <Route element={<LevelTestingPage />} path="level-testing" />
        <Route element={<MapStagesPage />} path="map-stages" />

        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </ProtectedRoute>
  );
};
