import { CountriesSelectMultipleFilter } from '@components/molecules/filters/dynamic/CountriesSelectMultipleFilter';
import { InputFilter } from '@components/molecules/filters/static/InputFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { payingUserKeys } from '@dashboards/types/IPayingUser';
import { IFilters } from '@typess/IFilters';

export const payingUsersFilters: IFilters = {
  ...paginationBaseFilters,
  [payingUserKeys.registrationDate]: {
    sort: true,
  },
  [payingUserKeys.customerId]: {
    input: <InputFilter placeholder="Введите ID" />,
    queries: [{ key: 'customerId' }],
  },
  [payingUserKeys.countryName]: {
    input: <CountriesSelectMultipleFilter />,
    queries: [{ key: 'countryIds' }],
    sort: true,
  },
};
