import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

import { IEnumMatchSubTypeKeys } from '../types/enums/IEnumMatchSubType';
import { IEnumMatchTypeKeys } from '../types/enums/IEnumMatchType';
import { IMatchPvp } from '../types/IMatchPvp';
import { matchesQueryKeys } from './matchesQueryKeys';

export type IMatchesAllRequest = IPageAbleRequest & {
  customerId?: number;
  subType?: IEnumMatchSubTypeKeys;
  type?: IEnumMatchTypeKeys;
};
type IMatchesAllResponse = AxiosResponse<IPageAbleResponse<IMatchPvp>>;

export const matchesAllRequest = async (params: IMatchesAllRequest): Promise<IMatchesAllResponse> =>
  await axios.get(`/game-match-pvp/all`, { params });

export function useQueryMatchesAll(params: IMatchesAllRequest) {
  return useQuery<IMatchesAllResponse, AxiosError>([matchesQueryKeys.matchesAll, params], () =>
    matchesAllRequest(params),
  );
}
