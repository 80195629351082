import { PromocodeStatusEnumKeys } from '@settings/promocodes/types/enums/PromocodeStatusEnum';
import { IUser } from '@users/types/IUser';

export type IPromocode = {
  code: string;
  customer?: IUser;
  customerId?: number | null;
  id: number;
  status: PromocodeStatusEnumKeys;
  usedAt?: Date | null;
};

export const promocodeKeys: Record<keyof IPromocode, string> = {
  code: 'code',
  customer: 'customer',
  customerId: 'customerId',
  id: 'id',
  status: 'status',
  usedAt: 'usedAt',
};
