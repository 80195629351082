import { FC } from 'react';
import { Empty, Skeleton, Table } from 'antd';
import Icon from '@ant-design/icons';

import { ChessKnightIcon } from '@components/atoms/icons';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { SettingTabs } from '@settings/appSettings/components/molecules/SettingTabs';
import { useQueryClassifier } from '@settings/classifierSkillTypes/api/useQueryClassifierSkillTypes';
import { ClassifierUpdateModal } from '@settings/classifierSkillTypes/components/organisms/ClassifierUpdateModal';
import { classifierColumns } from '@settings/classifierSkillTypes/consts/columns/classifierColumns';
import {
  ClassifierPageActions,
  classifierPageParams,
} from '@settings/classifierSkillTypes/consts/pageActions/ClassifierPageActions';
import { settingPermissions } from '@typess/permissions/ISettingPermission';
import { checkEditPermission } from '@utils/checkEditPermission';

export const ClassifierPage: FC = () => {
  const { actioned, clearParams, setActioned } = useUrlFilteredActioned<
    undefined,
    ClassifierPageActions
  >(undefined, classifierPageParams);

  const classifierTypes = useQueryClassifier();

  const canEditSettings = checkEditPermission(settingPermissions);

  return (
    <>
      <PageMeta
        selectedMenuKeys={['settings']}
        subTitle={actioned.action == 'edit' ? 'Изменение классификатора' : ''}
        title="Классификатор"
      />

      <SettingTabs active="classifier" />

      <Table
        className="mt-5"
        columns={classifierColumns({
          canEdit: canEditSettings,
          onClickEdit: ({ name }) => setActioned({ action: 'edit', actionName: name }),
        })}
        dataSource={classifierTypes?.data?.data}
        loading={{
          indicator: (
            <Icon className="text-xl" component={(props) => <ChessKnightIcon {...props} />} spin />
          ),
          spinning: classifierTypes.isLoading,
        }}
        locale={{
          emptyText: classifierTypes.isLoading ? (
            <div className="p-2">
              <Skeleton active round />
              <Skeleton active round />
            </div>
          ) : (
            <Empty description="Классификаторы не найдены" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ),
        }}
        pagination={false}
        rowKey="name"
        scroll={{ x: true }}
        showSorterTooltip={false}
      />

      <ClassifierUpdateModal
        classifierName={actioned.actionName}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
      />
    </>
  );
};
