import { FC } from 'react';
import { Chessboard } from 'react-chessboard';
import { Link } from 'react-router-dom';
import { Button, Modal, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { TbVideoIcon, VscPlayIcon } from '@components/atoms/icons';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { TitleAndText } from '@components/atoms/TitleAndText';
import { useQueryMapStageDetails } from '@exercises/map-stages/api/useQueryMapStageDetails';
import { MapStageTypeEnum } from '@exercises/map-stages/types/enums/MapStageType';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';

export const MapStageViewModal: FC<{
  id?: number;
  isOpen: boolean;
  onCancel: () => void;
  openEdit: () => void;
}> = ({ id = 0, isOpen, onCancel, openEdit }) => {
  const stageQuery = useQueryMapStageDetails({ id });
  const stage = stageQuery.data?.data;
  return (
    <Modal
      className="w-full px-2 md:px-0 md:w-4/5 top-10"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <div className="flex flex-row gap-4 truncate">
          <h1 className="text-xl m-0 flex items-center gap-1">
            Шаг - #{stage?.stageNumber} |{' '}
            {stage?.playLevel ? `${userPlayLevels[stage?.playLevel]}` : '-'}
          </h1>

          <Button className="hidden md:block" icon={<EditOutlined />} onClick={openEdit}>
            Редактировать
          </Button>

          <Button className="md:hidden" icon={<EditOutlined />} onClick={openEdit} />
        </div>
      }
    >
      <div>
        {stageQuery.isLoading ? (
          <BaseSkeleton />
        ) : !stage ? (
          <NotFoundResult title="Шаг не найден" />
        ) : (
          <div className="flex flex-col lg:flex-row">
            <div className="p-4 space-y-4 min-w-[350px]">
              <TitleAndText light text={stage.stageNumber} title="Шаг на карте" />
              <TitleAndText light text={userPlayLevels[stage.playLevel]} title="Уровень" />
              <TitleAndText light text={MapStageTypeEnum[stage.type]} title="Тип" />
              {stage.type === 'VIDEO' && (
                <TitleAndText
                  light
                  text={
                    stage.video ? (
                      <Link
                        className={`flex items-center gap-1 ${
                          stage.video.isCartoon ? 'text-ant-magenta-6' : 'text-ant-purple-6'
                        }`}
                        to={`/videos?action=view&actionId=${stage.video.id}`}
                      >
                        {stage.video.isCartoon ? <VscPlayIcon /> : <TbVideoIcon />}
                        {stage.video.localizations?.en?.fullTitle}
                      </Link>
                    ) : (
                      '-'
                    )
                  }
                  title="Видео"
                />
              )}
              {stage.type === 'GAME_MATCH' && (
                <>
                  <TitleAndText
                    light
                    text={<Typography>{stage.fenRecord?.value}</Typography>}
                    title="FEN"
                  />
                  <TitleAndText
                    light
                    text={
                      <div className="shadow-md rounded-sm overflow-x-auto">
                        <Chessboard
                          arePiecesDraggable={false}
                          boardWidth={360}
                          position={stage.fenRecord?.value || ''}
                        />
                      </div>
                    }
                    title=""
                  />
                </>
              )}
              {stage.type === 'EXERCISE' &&
                stage.exercises?.map((ex) => (
                  <TitleAndText
                    key={ex.id}
                    light
                    text={
                      <Link
                        className={`flex items-center gap-1 text-ant-purple-6`}
                        to={`/exercises?action=view&actionId=${ex.id}`}
                      >
                        {ex.id}
                      </Link>
                    }
                    title="Задачи"
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
