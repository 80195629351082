import { ReactSVG } from 'react-svg';
import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {
  CalendarOutlined,
  CaretRightOutlined,
  CloseOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import {
  BoardStatusEnum,
  BoardStatusEnumKeys,
} from '@collections/boards/types/enums/boardStatusEnum';
import { BoardTypeEnum } from '@collections/boards/types/enums/boardTypeEnum';
import { boardKeys, IBoard } from '@collections/boards/types/IBoard';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { PencilIcon } from '@components/atoms/icons';

type Props = {
  isSortable?: boolean;
  onClickDelete?: ({ id }: { id: number }) => void;
  onClickPause?: ({ id, status }: { id: number; status: BoardStatusEnumKeys }) => void;
  onEditBoard?: (board: IBoard) => void;
  onOpenBoard?: (board: IBoard) => void;
};

export const boardColumns = ({
  isSortable,
  onClickDelete,
  onClickPause,
  onEditBoard,
  onOpenBoard,
}: Props): ColumnsType<IBoard> => {
  return [
    isSortable
      ? {
          align: 'center' as const,
          key: 'sort',
          width: 50,
        }
      : {},
    {
      dataIndex: boardKeys.id,
      render: (id: IBoard['id'], board) => {
        return onOpenBoard ? (
          <Button onClick={() => onOpenBoard(board)} type="link">
            {id}
          </Button>
        ) : (
          id
        );
      },
      title: 'ID',
    },
    {
      dataIndex: boardKeys.localizations,
      render: (localizations: IBoard['localizations']) => {
        return (
          <div>
            <CountryImageText src="en" text={localizations?.en?.name || '-'} />
            <CountryImageText src="ru" text={localizations?.ru?.name || '-'} />
          </div>
        );
      },
      title: 'Название доски',
    },
    {
      dataIndex: boardKeys.imgUrl,
      render: (imgUrl: IBoard['imgUrl']) => {
        return <ReactSVG src={imgUrl} />;
      },
      title: 'Доска',
    },
    {
      dataIndex: boardKeys.darkPieceImgUrl,
      render: (darkPieceImgUrl: IBoard['darkPieceImgUrl']) => {
        return <ReactSVG src={darkPieceImgUrl} />;
      },
      title: 'Черные поля',
    },
    {
      dataIndex: boardKeys.lightPieceImgUrl,
      render: (lightPieceImgUrl: IBoard['lightPieceImgUrl']) => <ReactSVG src={lightPieceImgUrl} />,

      title: 'Белые поля',
    },
    {
      dataIndex: boardKeys.price,
      render: (price: IBoard['price']) => {
        return price ?? '-';
      },
      title: 'Цена',
    },
    {
      dataIndex: boardKeys.type,
      render: (type: IBoard['type']) => {
        return type ? BoardTypeEnum[type] : '-';
      },
      title: 'Тип',
    },
    {
      dataIndex: boardKeys.createdDate,
      render: (createdDate: IBoard['createdDate']) =>
        createdDate ? (
          <div>
            <p className="mb-0">
              <CalendarOutlined /> {moment(createdDate).format('LL')}
            </p>
          </div>
        ) : (
          <span>-</span>
        ),
      title: 'Дата создания',
    },
    {
      dataIndex: boardKeys.status,
      render: (status: IBoard['status']) => {
        return status ? BoardStatusEnum[status] : '-';
      },
      title: 'Статус',
    },
    {
      key: 'action',
      render: ({ id, localizations, status }: IBoard, board) => {
        return (
          <div className="flex items-center space-x-2">
            {onClickPause && (
              <Popconfirm
                cancelText="Отменить"
                icon={
                  status == 'ACTIVE' ? (
                    <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                  ) : (
                    <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                  )
                }
                okText={status === 'ACTIVE' ? 'Приостановить' : 'Запустить'}
                okType="primary"
                onConfirm={() =>
                  onClickPause({
                    id: id,
                    status: status == 'ACTIVE' ? 'DRAFT' : 'ACTIVE',
                  })
                }
                placement="topLeft"
                title={
                  <p className="text-sm mb-0">
                    Вы уверены, что хотите
                    <span className="font-semibold">
                      {status == 'ACTIVE' ? ' приостановить ' : ' запустить '}
                      {id}?
                    </span>
                  </p>
                }
              >
                <Button
                  ghost
                  icon={status === 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
                  type="primary"
                />
              </Popconfirm>
            )}
            {onEditBoard && (
              <Button
                ghost
                icon={<PencilIcon />}
                onClick={() => onEditBoard(board)}
                type="primary"
              />
            )}
            {onClickDelete && (
              <Popconfirm
                cancelText="Отменить"
                icon={
                  isSortable ? <CloseOutlined /> : <DeleteOutlined className="text-ant-red-5" />
                }
                okText="Удалить"
                okType="danger"
                onConfirm={() => onClickDelete({ id })}
                placement="topLeft"
                title={
                  <span className="text-sm">
                    Вы уверены, что хотите {isSortable ? 'убрать' : 'удалить'} доску
                    <span className="font-bold"> &quot;{localizations?.ru?.name || '-'}&quot;</span>
                    ?
                  </span>
                }
              >
                <Button danger icon={isSortable ? <CloseOutlined /> : <DeleteOutlined />} />
              </Popconfirm>
            )}
          </div>
        );
      },
      title: 'Действия',
    },
  ];
};
