import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IUserBrainstorm } from '@users/types/IUserBrainstorm';

import { userQueryKeys } from './userQueryKeys';

export type IBrainstormRequest = { id: number };
type IBrainstormResponse = AxiosResponse<IUserBrainstorm>;

export const brainstormRequest = async ({ id }: IBrainstormRequest): Promise<IBrainstormResponse> =>
  await axios.get(`/puzzle-rush/${id}`);

export function useQueryBrainstorm(params: IBrainstormRequest) {
  return useQuery<IBrainstormResponse, AxiosError>(
    [userQueryKeys.brainstorm, params],
    () => brainstormRequest(params),
    { enabled: params.id > 0 },
  );
}
