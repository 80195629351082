import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Skeleton } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';

import {
  IResetConfirmPasswordType,
  resetPasswordInitial,
  resetPasswordValidation,
  useMutateResetPassword,
} from '@auth/api/useMutateResetPassword';
import { useQueryLinkState } from '@auth/api/useQueryLinkState';
import { PageMeta } from '@context/PageMetaContext';

const ResetPasswordPage: FC = () => {
  const { link } = useParams();
  const linkState = useQueryLinkState({ link: link || '' });

  const resetPassword = useMutateResetPassword();

  return (
    <>
      <PageMeta title="Сброс пароля" />

      {linkState.isLoading ? (
        <Skeleton active />
      ) : (
        <Formik<IResetConfirmPasswordType>
          initialValues={{ ...resetPasswordInitial, link: link || '' }}
          onSubmit={(values) => {
            resetPassword.mutate(values);
          }}
          validationSchema={resetPasswordValidation}
        >
          <Form className="space-y-8" layout="vertical">
            <h2 className="text-center text-3xl sm:text-4xl text-gray-900">Новый пароль</h2>

            <p>Установите новый пароль и подтвердите</p>

            <FormItem label="Новый пароль" name="password">
              <Input.Password maxLength={30} name="password" placeholder="********" showCount />
            </FormItem>

            <FormItem label="Повтор пароля" name="confirmPassword">
              <Input.Password
                maxLength={30}
                name="confirmPassword"
                placeholder="********"
                showCount
              />
            </FormItem>

            <Button block htmlType="submit" loading={false} size="large" type="primary">
              Сохранить
            </Button>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default ResetPasswordPage;
