import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema } from 'yup';

import { mapStageQueryKeys } from './mapStagesQueryKeys';
import { IMapStageCreateRequest, mapStageCreateValidation } from './useMutateMapStageCreate';

export type IMapStageEditRequest = IMapStageCreateRequest & {
  id: number;
};

type IMapStageEditResponse = AxiosResponse<void>;

export const mapStageEditValidation: ObjectSchema<IMapStageEditRequest> = object({
  id: number().required(),
}).concat(mapStageCreateValidation);

export const mapStageEditRequest = async (
  payload: IMapStageEditRequest,
): Promise<IMapStageEditResponse> => {
  return await axios.patch(`/map-stage-definitions/${payload.id}`, payload);
};

export function useMutateMapStageEdit() {
  const queryClient = useQueryClient();

  return useMutation<IMapStageEditResponse, AxiosError, IMapStageEditRequest>(mapStageEditRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(mapStageQueryKeys.mapStages);
    },
  });
}
