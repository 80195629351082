import { ExerciseGamePhaseEnumKeys } from '@exercises/exercises/types/enums/ExerciseGamePhaseEnum';
import { ITag } from '@tags/types/ITag';
import { ILocalization } from '@typess/ILocalization';
import { IUserPlayLevelNumber } from '@users/types/enums/UserPlayLevel';
import { VideoStatusEnumKeys } from '@videos/types/enums/VideoStatusEnum';
import { IVideoLocalized } from '@videos/types/IVideoLocalized';
import { IVideoTest } from '@videos/types/IVideoTest';

export interface IVideo {
  author?: ITag;
  gamePhase?: ExerciseGamePhaseEnumKeys | null;
  id: number;
  isCapitalization?: boolean;
  isCartoon?: boolean;
  isTactic?: boolean;
  localizations: Partial<Record<ILocalization, IVideoLocalized>>;
  playLevel?: IUserPlayLevelNumber;
  price?: number;
  sequence?: number | null;
  status: VideoStatusEnumKeys;
  tagMastering?: number;
  tags?: ITag[];
  test?: IVideoTest;
  videoFileName?: string;
  viewTotal?: number;
}

export const videoKeys: Record<keyof IVideo, string> = {
  author: 'author',
  gamePhase: 'gamePhase',
  id: 'id',
  isCapitalization: 'isCapitalization',
  isCartoon: 'isCartoon',
  isTactic: 'isTactic',
  localizations: 'localizations',
  playLevel: 'playLevel',
  price: 'price',
  sequence: 'sequence',
  status: 'status',
  tagMastering: 'tagMastering',
  tags: 'tags',
  test: 'test',
  videoFileName: 'videoFileName',
  viewTotal: 'viewTotal',
};
