import { FC } from 'react';
import { Button, Modal, Switch as AntSwitch } from 'antd';
import { AxiosError } from 'axios';
import { Formik } from 'formik';
import { Form, FormItem, Input, InputNumber } from 'formik-antd';

import {
  appProductCreateInitial,
  appProductCreateValidation,
  IAppProductCreateRequest,
  useMutateAppProductCreate,
} from '@shop/appProducts/api/useMutateAppProductCreate';
import { IBackendError } from '@typess/IBackendError';
import { notify } from '@utils/notify';

type ProductCreateModalType = {
  isOpen: boolean;
  onCancel: () => void;
};

export const ProductCreateModal: FC<ProductCreateModalType> = ({ isOpen, onCancel }) => {
  const productCreate = useMutateAppProductCreate();

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={<h1 className="text-xl m-0">Создание коинов</h1>}
    >
      <Formik<IAppProductCreateRequest>
        initialValues={appProductCreateInitial}
        onSubmit={(values, { resetForm, setErrors }) => {
          productCreate
            .mutateAsync(values)
            .then((data) => {
              if (data.status == 201 || data.status == 200) {
                notify('success', 'Товар создан');
                onCancel();
                resetForm();
              }
            })
            .catch((error: AxiosError<IBackendError>) => {
              if (
                error.response?.status == 422 &&
                error.response.data.message == 'AppProduct.suchGooglePlayIdAlreadyExist'
              ) {
                setErrors({ appStoreId: 'Товар с этим AppStore ID существует' });
              }
            });
        }}
        validationSchema={appProductCreateValidation}
      >
        {({ setFieldValue, values }) => (
          <Form className="space-y-8" layout="vertical">
            <div className="md:grid md:grid-cols-2 md:gap-x-5">
              <FormItem label="Количество коинов" name="coins">
                <InputNumber
                  className="w-full"
                  controls={false}
                  max={999999}
                  min={1}
                  name="coins"
                  placeholder="Коины"
                  prefix
                />
              </FormItem>

              <FormItem label="Стоимость" name="price">
                <InputNumber
                  className="w-full"
                  controls={false}
                  max={999999}
                  min={1}
                  name="price"
                  placeholder="Стоимость"
                  prefix
                />
              </FormItem>
            </div>

            <FormItem label="App Store ID" name="appStoreId">
              <Input name="appStoreId" placeholder="ID товара" suffix />
            </FormItem>

            <div className="md:flex md:justify-center">
              <Form.Item
                className="md:text-center"
                label={<span className="text-center">Активировать немедленно</span>}
                name="status"
              >
                <Input hidden name="status" suffix />
                <AntSwitch
                  checked={values.status == 'ACTIVE'}
                  onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'INACTIVE')}
                />
              </Form.Item>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
              <Button block onClick={onCancel}>
                Закрыть
              </Button>

              <Button block htmlType="submit" loading={productCreate.isLoading} type="primary">
                {values.status == 'ACTIVE' ? 'Создать и активировать' : 'Создать'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
