import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import Paragraph from 'antd/lib/typography/Paragraph';
import moment from 'moment/moment';

import { CountryImageText } from '@components/atoms/CountryImageText';
import { FaChessKnightIcon } from '@components/atoms/icons';
import russianPluralize from '@utils/russianPluralize';

import { IEnumMatchResultType } from '../../types/enums/IEnumMatchResultType';
import { IEnumMatchSubType } from '../../types/enums/IEnumMatchSubType';
import { IEnumMatchType } from '../../types/enums/IEnumMatchType';
import { IMatchPvp, matchesPvpKeys } from '../../types/IMatchPvp';

export const matchesPvpColumns = ({
  onView,
}: {
  onView: (id: IMatchPvp['id']) => void;
}): ColumnsType<IMatchPvp> => [
  {
    dataIndex: matchesPvpKeys.id,
    render: (id: IMatchPvp['id']) => (
      <span className="cursor-pointer text-blue-500" onClick={() => onView(id)}>
        {id}
      </span>
    ),
    title: 'ID',
  },
  {
    dataIndex: matchesPvpKeys.whitePlayer,
    render: (whitePlayer: IMatchPvp['whitePlayer']) => (
      <div className="flex flex-col gap-1">
        <Link className="flex gap-1 items-center whitespace-nowrap" to={`/users/${whitePlayer.id}`}>
          <FaChessKnightIcon className="text-neutral-200 text-tiny" />

          {whitePlayer.country?.iso ? (
            <CountryImageText
              position="right"
              src={whitePlayer.country?.iso}
              text={whitePlayer.nickname}
            />
          ) : (
            whitePlayer.nickname
          )}
          {/*{winnerColor == 'white' && <CrownFilled className="text-yellow-500 text-tiny" />}*/}
        </Link>
        <p className="text-xs">Рейтинг - {whitePlayer.rating}</p>
      </div>
    ),
    title: 'Белые',
  },
  {
    dataIndex: matchesPvpKeys.blackPlayer,
    render: (blackPlayer: IMatchPvp['blackPlayer']) => (
      <div className="flex flex-col gap-1">
        <Link
          className="flex gap-0.5 items-center whitespace-nowrap"
          to={`/users/${blackPlayer.id}`}
        >
          <FaChessKnightIcon className="text-neutral-600 text-tiny" />

          {blackPlayer.country?.iso ? (
            <CountryImageText
              position="right"
              src={blackPlayer.country?.iso}
              text={blackPlayer.nickname}
            />
          ) : (
            blackPlayer.nickname
          )}
          {/*{winnerColor == 'black' && <CrownFilled className="text-yellow-500 text-tiny" />}*/}
          {/*<FaChessKnightIcon className="text-black text-tiny" />*/}
        </Link>
        <p className="text-xs">Рейтинг - {blackPlayer.rating}</p>
      </div>
    ),
    title: 'Черные',
  },
  {
    className: 'whitespace-nowrap',
    dataIndex: matchesPvpKeys.winnerColor,
    render: (winnerColor: IMatchPvp['winnerColor']) =>
      winnerColor == 'black' ? (
        <FaChessKnightIcon className="text-neutral-800" />
      ) : winnerColor == 'white' ? (
        <FaChessKnightIcon className="text-gray-300 -scale-x-100" />
      ) : (
        <>
          <FaChessKnightIcon className="text-gray-400 -scale-x-100" />
          <FaChessKnightIcon className="text-gray-600" />
        </>
      ),
    title: 'Победа',
  },
  {
    className: 'whitespace-nowrap',
    dataIndex: matchesPvpKeys.resultType,
    render: (resultType: IMatchPvp['resultType']) =>
      resultType ? IEnumMatchResultType[resultType] : '-',
    title: 'Результат',
  },
  {
    className: 'whitespace-nowrap',
    dataIndex: matchesPvpKeys.rated,
    render: (rated: IMatchPvp['rated']) => (rated ? 'Да' : 'Нет'),
    title: 'Рейтинг',
  },
  {
    className: 'whitespace-nowrap',
    dataIndex: matchesPvpKeys.subType,
    render: (subType: IMatchPvp['subType']) => (subType ? IEnumMatchSubType[subType] : '-'),
    title: 'Время',
  },
  {
    className: 'whitespace-nowrap',
    dataIndex: matchesPvpKeys.type,
    render: (type: IMatchPvp['type']) => (type ? IEnumMatchType[type] : '-'),
    title: 'Тип',
  },
  {
    dataIndex: matchesPvpKeys.numberOfMoves,
    title: 'Ходы',
  },
  {
    dataIndex: matchesPvpKeys.gameDurationMillis,
    render: (gameDurationMillis?: IMatchPvp['gameDurationMillis']) => {
      const minutes = ((gameDurationMillis ?? 0) / 60000).toFixed();
      const seconds = (((gameDurationMillis ?? 0) % 60000) / 1000).toFixed();
      return `${
        minutes != '0'
          ? minutes +
            ' ' +
            russianPluralize(minutes, { few: 'минуты', many: 'минут', one: 'минута' }) +
            ' '
          : ''
      }${seconds} ${russianPluralize(seconds, { few: 'секунды', many: 'секунд', one: 'секунда' })}`;
    },
    title: 'Длительность игры',
  },
  // {
  //   dataIndex: matchesPvpKeys.movesUci,
  //   render: (movesUci: IMatchPvp['movesUci']) => (
  //     <div className="w-40">
  //       <Paragraph ellipsis={{ expandable: true, symbol: 'раскрыть' }}>{movesUci}</Paragraph>
  //     </div>
  //   ),
  //   title: 'Ходы',
  // },
  {
    dataIndex: matchesPvpKeys.movesPgn,
    render: (movesPgn: IMatchPvp['movesPgn']) => (
      <div className="w-40">
        <Paragraph className="pb-0 mb-0" ellipsis={{ expandable: true, symbol: 'раскрыть' }}>
          {movesPgn}
        </Paragraph>
      </div>
    ),
    title: 'PGN',
  },
  {
    className: 'whitespace-nowrap',
    dataIndex: matchesPvpKeys.beginDate,
    render: (beginDate?: IMatchPvp['beginDate']) => {
      const begin = moment(beginDate);
      return begin.isValid() ? begin.format('DD/MM/y HH:mm') : '';
    },
    title: 'Дата начала',
  },
  {
    dataIndex: matchesPvpKeys.endDate,
    render: (endDate?: IMatchPvp['endDate']) => {
      const end = moment(endDate);
      return end.isValid() ? end.format('DD/MM/y HH:mm') : '';
    },
    title: 'Дата окончания',
  },
];
