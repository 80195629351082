import type { FC } from 'react';

import styled from '@emotion/styled';

type Props = {
  className?: string;
  id: string;
  sideHeight?: number;
  width?: number;
};

const PgnViewer = styled.div<Omit<Props, 'id'>>`
  .lpv__side {
    height: ${(props) => (props.sideHeight ? `${props.sideHeight}px` : 'auto')};
  }

  .lpv__board {
    width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  }

  cg-board {
    background-image: url('/images/board.svg');
    width: 100%;
    height: 100%;
  }
`;

export const ChessPgnViewer: FC<Props> = (props) => {
  return <PgnViewer {...props} />;
};
