export type ILocalization = 'en' | 'ru' | 'kz';

export const localizations: Record<ILocalization, ILocalization> = {
  en: 'en',
  kz: 'kz',
  ru: 'ru',
};

export const localizationEmoji = {
  en: '🇬🇧',
  kz: 'kz',
  ru: '🇷🇺',
};

export enum LocalizationEnum {
  en = 'English',
  ru = 'Russian',
}
