import { Route, Routes } from 'react-router-dom';

import { FiguresSetCreatePage } from '@collections/chess-pieces/components/pages/FiguresSetCreatePage';
import { FiguresSetEditPage } from '@collections/chess-pieces/components/pages/FiguresSetEditPage';
import { FiguresSetPage } from '@collections/chess-pieces/components/pages/FiguresSetPage';
import { FiguresSetsPage } from '@collections/chess-pieces/components/pages/FiguresSetsPage';
import ProtectedRoute from '@components/molecules/routes/ProtectedRoute';
import NotFoundPage from '@pages/errors/NotFoundPage';
import { settingPermissions } from '@typess/permissions/ISettingPermission';

import { AvatarsPage } from './avatars/components/pages/AvatarsPage';
import { AvatarsGroupsPage } from './avatars-groups/components/pages/AvatarsGroupsPage';
import { BoardGroupsPage } from './board-groups/components/pages/BoardGroupPage';
import { BoardsPage } from './boards/components/pages/BoardsPage';
import { ChessPiecesGroupsPage } from './chess-pieces-groups/components/pages/ChessPiecesGroupPage';
import { LecturerCards } from './lecturer-cards/components/pages/LecturerCardsPage';

export const CollectionRouter = () => {
  return (
    <ProtectedRoute permissions={Object.values(settingPermissions)}>
      <Routes>
        <Route element={<BoardsPage />} index />
        <Route element={<BoardGroupsPage />} path="board-group" />
        <Route element={<AvatarsPage />} path="/avatars" />
        <Route element={<AvatarsGroupsPage />} path="/avatars-group" />
        <Route element={<LecturerCards />} path="/lecturer-cards" />
        <Route element={<FiguresSetsPage />} path="/figures" />
        <Route element={<FiguresSetPage />} path="/figures/:id" />
        <Route element={<FiguresSetCreatePage />} path="/figures/create" />
        <Route element={<FiguresSetEditPage />} path="/figures/edit/:id" />
        <Route element={<ChessPiecesGroupsPage />} path="/figures-group" />

        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </ProtectedRoute>
  );
};
