import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum VideosPageActionEnum {
  'create',
  'edit',
  'view',
}

export type IVideosPageActions = {
  action: keyof typeof VideosPageActionEnum;
  actionId: number;
  actionStep: number | string;
};

export const videosPageActions: IPageActions<IVideosPageActions> = {
  action: {
    allowed: (value) => Object.keys(VideosPageActionEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
  actionStep: { allowed: (value) => isInteger(value), default: 0 },
};
