import { IPermission } from '@typess/permissions/IPermission';

export function checkPermission(
  authorities?: IPermission[],
  permissions?: IPermission[] | IPermission,
): boolean {
  if (authorities?.includes('ROLE_ADMIN')) return true;

  if (typeof permissions == 'string') return authorities?.includes(permissions) || false;

  if (Array.isArray(permissions))
    return authorities?.some((authority) => permissions.includes(authority)) || false;

  return false;
}
