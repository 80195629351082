import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, number, object, ObjectSchema, string } from 'yup';

import { notify } from '@utils/notify';

import { AvatarStatusEnumKeys } from '../types/enums/avatarStatusEnum';
import { IAvatarGroup } from '../types/IAvatarGroup';
import { avatarGroupQueryKeys } from './avatarGroupQueryKeys';

export type IAvatarGroupCreateRequest = Pick<IAvatarGroup, 'localizations' | 'status'> & {
  items?: { avatarId: number; priority: number }[];
};

export const createAvatarGroupInitialValue: IAvatarGroupCreateRequest = {
  items: [],
  localizations: { en: { name: '' }, ru: { name: '' } },
  status: 'DRAFT',
};

export const createAvatarGroupValidation: ObjectSchema<
  Omit<IAvatarGroupCreateRequest, 'items'> & { items?: number[] }
> = object({
  items: array().of(number().required()).optional(),
  localizations: object({
    en: object({ name: string().required() }),
    ru: object({ name: string().required() }),
  }),
  status: string<AvatarStatusEnumKeys>().nullable().required(),
});

export const createAvatarGroupRequest = (payload: IAvatarGroupCreateRequest) =>
  axios.post(`/avatar-groups`, payload);

export const useMutateAvatarGroupCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, IAvatarGroupCreateRequest>(
    createAvatarGroupRequest,
    {
      onError: () => {
        notify('error', 'Повторите попытку позже');
      },
      onSuccess: () => {
        notify('success', 'Группа успешно создана');

        queryClient.invalidateQueries(avatarGroupQueryKeys.avatarsGroups);
      },
    },
  );
};
