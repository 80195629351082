import { FC, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'antd';
import moment from 'moment/moment';
import { LeftOutlined } from '@ant-design/icons';

import { useQueryChessPiecesSetById } from '@collections/chess-pieces/api/useQueryChessPiecesSetById';
import { ChessPieceImageBox } from '@collections/chess-pieces/components/atoms/ChessPieceImageBox';
import {
  ChessPiecesSetStatusEnum,
  ChessPiecesSetTypeEnum,
} from '@collections/chess-pieces/types/enums/ChessPiecesSetEnum';
import { CollectionTabs } from '@collections/components/molecules/CollectionTabs';
import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { Description } from '@components/atoms/Description';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { PageMeta } from '@context/PageMetaContext';

export const FiguresSetPage: FC = () => {
  const { id } = useParams<{ id: string }>();

  const chessPiecesSetById = useQueryChessPiecesSetById(id ?? '');
  const chessPiecesSet = useMemo(() => chessPiecesSetById.data?.data, [chessPiecesSetById]);

  return (
    <>
      <PageMeta selectedMenuKeys={['collections']} title="Коллекция" />

      <CollectionTabs active="figures" />

      <Link to="/collections/figures">
        <Button className="mb-4 text-sm" icon={<LeftOutlined size={10} />} size="large" />
      </Link>

      {chessPiecesSetById.isLoading ? (
        <BaseSkeleton />
      ) : !chessPiecesSet ? (
        <NotFoundResult title="Коллекция фигур не найдена" />
      ) : (
        <div className="lg:grid lg:grid-cols-3 gap-4 space-y-8 lg:space-y-0">
          <div className="">
            <div className="border bg-white rounded-lg p-4 lg:p-6">
              <div className="flex flex-row justify-between border-b border-ant-blue-6 pb-4">
                <h2 className="text-2xl flex items-center gap-2">
                  Коллекция фигур #{chessPiecesSet.id}
                </h2>
              </div>

              <div className="divide-y divide-slate-200 text-base">
                <Description
                  description={
                    <CountryImageText
                      src="ru"
                      text={chessPiecesSet.localizations?.ru?.name || '-'}
                    />
                  }
                  title="Название на русском"
                />
                <Description
                  description={
                    <CountryImageText
                      src="en"
                      text={chessPiecesSet.localizations?.en?.name || '-'}
                    />
                  }
                  title="Название на английском"
                />
                <Description description={chessPiecesSet.price} title="Цена" />
                <Description
                  description={moment(chessPiecesSet.createdDate).format('LL')}
                  title="Дата создания"
                />
                <Description
                  description={moment(chessPiecesSet.updatedDate).format('LL')}
                  title="Дата обновления"
                />
                <Description
                  description={ChessPiecesSetStatusEnum[chessPiecesSet.status]}
                  title="Статус"
                />
                <Description
                  description={ChessPiecesSetTypeEnum[chessPiecesSet.type]}
                  title="Тип"
                />
              </div>
            </div>
          </div>

          <div className="lg:col-span-2">
            <div className="border bg-white rounded-lg p-4 lg:p-6">
              <div className="flex flex-row justify-between border-b border-ant-blue-4 pb-4">
                <h2 className="text-2xl flex items-center gap-2">Картинки фигур</h2>
              </div>
              <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-8 p-3 place-items-center">
                {chessPiecesSet.chessPieces?.map((piece) => (
                  <ChessPieceImageBox key={piece.id} piece={piece} size="md" />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
