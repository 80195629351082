import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IChessPiecesSet } from '../types/IChessPiecesSet';
import { chessPiecesQueryKeys } from './chessPiecesQueryKeys';

export type ChessPiecesSetStatusUpdateRequest = Pick<IChessPiecesSet, 'id' | 'status'>;

type ChessPiecesSetStatusUpdateResponse = AxiosResponse<void>;

export const chessPiecesSetStatusUpdateRequest = async ({
  id,
  ...payload
}: ChessPiecesSetStatusUpdateRequest): Promise<ChessPiecesSetStatusUpdateResponse> => {
  return await axios.patch(`/chess-piece-sets/${id}`, payload);
};

export function useMutateChessPiecesSetStatusUpdate() {
  const queryClient = useQueryClient();

  return useMutation<
    ChessPiecesSetStatusUpdateResponse,
    AxiosError,
    ChessPiecesSetStatusUpdateRequest
  >(chessPiecesSetStatusUpdateRequest, {
    onSuccess: () => queryClient.invalidateQueries(chessPiecesQueryKeys.chessPieces),
  });
}
