import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Switch as AntSwitch, Tag } from 'antd';
import Typography from 'antd/es/typography/Typography';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';

import {
  createChessPiecesSetInitialValue,
  createChessPiecesSetValidation,
  IChessPiecesSetCreateRequest,
  useMutateChessPiecesSetCreate,
} from '@collections/chess-pieces/api/useMutateChessPiecesSetCreate';
import {
  ChessPiecesEnum,
  ChessPiecesTranslationsEnum,
} from '@collections/chess-pieces/types/enums/ChessPiecesEnum';
import { ChessPiecesSetTypeEnum } from '@collections/chess-pieces/types/enums/ChessPiecesSetEnum';
import { useQueryChessPiecesGroups } from '@collections/chess-pieces-groups/api/useQueryChessPiecesGroups';
import { CollectionTabs } from '@collections/components/molecules/CollectionTabs';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { FileUpload } from '@components/molecules/files/FileUpload';
import { PageMeta } from '@context/PageMetaContext';

export const FiguresSetCreatePage: FC = () => {
  const chessPiecesSetMutation = useMutateChessPiecesSetCreate();
  const navigate = useNavigate();
  const chessPiecesGroup = useQueryChessPiecesGroups();

  return (
    <>
      <PageMeta selectedMenuKeys={['collections']} title="Коллекция" />
      <CollectionTabs active="figures" />
      <div className="flex justify-start gap-4 mb-4">
        <Button
          className="sm:px-12"
          onClick={() => navigate('/collections/figures')}
          size="large"
          type="primary"
        >
          Назад
        </Button>
      </div>

      <Formik<IChessPiecesSetCreateRequest>
        enableReinitialize
        initialValues={createChessPiecesSetInitialValue}
        onSubmit={(values) =>
          chessPiecesSetMutation.mutateAsync(values).then(() => navigate('/collections/figures'))
        }
        validateOnBlur={false}
        validationSchema={createChessPiecesSetValidation}
      >
        {({ setFieldValue, values: { chessPieces, status, type } }) => {
          return (
            <Form className="gap-4 md:gap-6" layout="vertical">
              <Row justify="start">
                <Col className="mr-10" span={10}>
                  <FormItem
                    label={<CountryImageText src="ru" text="Название на русском" />}
                    name="localizations.ru.name"
                  >
                    <Input name="localizations.ru.name" prefix />
                  </FormItem>
                  <FormItem
                    label={<CountryImageText src="en" text="Название на английском" />}
                    name="localizations.en.name"
                  >
                    <Input name="localizations.en.name" prefix />
                  </FormItem>
                  <div className="flex flex-row items-baseline">
                    <FormItem className="mr-3 w-[50%]" label="Тип коллекции" name="type">
                      <Select
                        defaultValue="FREE"
                        name="type"
                        optionFilterProp="label"
                        options={
                          Object.entries(ChessPiecesSetTypeEnum).map(([key, value]) => ({
                            label: value,
                            value: key,
                          })) || []
                        }
                        placeholder="Тип коллекции"
                      />
                    </FormItem>
                    {type === 'PAID' && (
                      <FormItem className="w-[50%]" label="Стоимость" name="price">
                        <Input name="price" prefix type="number" />
                      </FormItem>
                    )}
                  </div>
                </Col>
                <Col span={10}>
                  <FormItem
                    className="mr-10"
                    label={<span className="text-center">Запустить</span>}
                    name="status"
                  >
                    <Input hidden name="status" suffix />

                    <AntSwitch
                      checked={status == 'ACTIVE'}
                      onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
                    />
                  </FormItem>
                  <FormItem label="Группа коллекций фигур" name="groupIds">
                    <Select
                      loading={chessPiecesGroup.isFetching}
                      mode="multiple"
                      name="groupIds"
                      optionFilterProp="label"
                      options={
                        chessPiecesGroup.data?.data.content.map((tag) => ({
                          label: tag.localizations.ru?.name,
                          value: tag.id,
                        })) || []
                      }
                      placeholder="Группа коллекций фигур"
                      tagRender={
                        chessPiecesGroup.isFetching
                          ? () => (
                              <Tag>
                                <LoadingOutlined />
                              </Tag>
                            )
                          : undefined
                      }
                    />
                  </FormItem>
                  <Typography className="font-bold">Картинка коллекции</Typography>
                  <div className="grid grid-cols-2 gap-6">
                    <FormItem className="upload-form-item" label="SVG" name="imgUrl">
                      <FileUpload
                        accept=".svg"
                        folderName="imgUrl"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status || info.file.status == 'removed')
                            setFieldValue('imgUrl', '');
                          if (info.file.status == 'done')
                            setFieldValue('imgUrl', linkInfo.downloadUrl);
                        }}
                      />
                    </FormItem>
                    <FormItem className="upload-form-item" label="PNG" name="imgUrlPng">
                      <FileUpload
                        accept=".svg"
                        folderName="imgUrlPng"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status || info.file.status == 'removed')
                            setFieldValue('imgUrlPng', '');
                          if (info.file.status == 'done')
                            setFieldValue('imgUrlPng', linkInfo.downloadUrl);
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col span={3}>
                  <Typography className="font-bold">
                    {ChessPiecesTranslationsEnum[ChessPiecesEnum.BPAWN]}
                  </Typography>
                  <div className="grid grid-cols-2 gap-6">
                    <FormItem className="upload-form-item" label="SVG" name={ChessPiecesEnum.BPAWN}>
                      <FileUpload
                        accept=".svg"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status || info.file.status == 'removed')
                            setFieldValue('chessPieces.0', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.0', {
                              ...chessPieces[0],
                              imgUrl: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.BPAWN,
                            });
                        }}
                      />
                    </FormItem>
                    <FormItem className="upload-form-item" label="PNG" name={ChessPiecesEnum.BPAWN}>
                      <FileUpload
                        accept=".png"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status || info.file.status == 'removed')
                            setFieldValue('chessPieces.0', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.0', {
                              ...chessPieces[0],
                              imgUrlPng: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.BPAWN,
                            });
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>

                <Col span={3}>
                  <Typography className="font-bold">
                    {ChessPiecesTranslationsEnum[ChessPiecesEnum.BKNIGHT]}
                  </Typography>
                  <div className="grid grid-cols-2 gap-6">
                    <FormItem
                      className="upload-form-item"
                      label="SVG"
                      name={ChessPiecesEnum.BKNIGHT}
                    >
                      <FileUpload
                        accept=".svg"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status || info.file.status == 'removed')
                            setFieldValue('chessPieces.1', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.1', {
                              ...chessPieces[1],
                              imgUrl: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.BKNIGHT,
                            });
                        }}
                      />
                    </FormItem>
                    <FormItem
                      className="upload-form-item"
                      label="PNG"
                      name={ChessPiecesEnum.BKNIGHT}
                    >
                      <FileUpload
                        accept=".png"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status || info.file.status == 'removed')
                            setFieldValue('chessPieces.1', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.1', {
                              ...chessPieces[1],
                              imgUrlPng: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.BKNIGHT,
                            });
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>

                <Col span={3}>
                  <Typography className="font-bold">
                    {ChessPiecesTranslationsEnum[ChessPiecesEnum.BBISHOP]}
                  </Typography>
                  <div className="grid grid-cols-2 gap-6">
                    <FormItem
                      className="upload-form-item"
                      label="SVG"
                      name={ChessPiecesEnum.BBISHOP}
                    >
                      <FileUpload
                        accept=".svg"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.2', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.2', {
                              ...chessPieces[2],
                              imgUrl: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.BBISHOP,
                            });
                        }}
                      />
                    </FormItem>
                    <FormItem
                      className="upload-form-item"
                      label="PNG"
                      name={ChessPiecesEnum.BBISHOP}
                    >
                      <FileUpload
                        accept=".png"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.2', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.2', {
                              ...chessPieces[2],
                              imgUrlPng: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.BBISHOP,
                            });
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>

                <Col span={3}>
                  <Typography className="font-bold">
                    {ChessPiecesTranslationsEnum[ChessPiecesEnum.BROOK]}
                  </Typography>
                  <div className="grid grid-cols-2 gap-6">
                    <FormItem className="upload-form-item" label="SVG" name={ChessPiecesEnum.BROOK}>
                      <FileUpload
                        accept=".svg"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.3', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.3', {
                              ...chessPieces[3],
                              imgUrl: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.BROOK,
                            });
                        }}
                      />
                    </FormItem>
                    <FormItem className="upload-form-item" label="PNG" name={ChessPiecesEnum.BROOK}>
                      <FileUpload
                        accept=".png"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.3', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.3', {
                              ...chessPieces[3],
                              imgUrlPng: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.BROOK,
                            });
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>

                <Col span={3}>
                  <Typography className="font-bold">
                    {ChessPiecesTranslationsEnum[ChessPiecesEnum.BQUEEN]}
                  </Typography>
                  <div className="grid grid-cols-2 gap-6">
                    <FormItem
                      className="upload-form-item"
                      label="SVG"
                      name={ChessPiecesEnum.BQUEEN}
                    >
                      <FileUpload
                        accept=".svg"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.4', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.4', {
                              ...chessPieces[4],
                              imgUrl: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.BQUEEN,
                            });
                        }}
                      />
                    </FormItem>
                    <FormItem
                      className="upload-form-item"
                      label="PNG"
                      name={ChessPiecesEnum.BQUEEN}
                    >
                      <FileUpload
                        accept=".png"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.4', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.4', {
                              ...chessPieces[4],
                              imgUrlPng: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.BQUEEN,
                            });
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>

                <Col span={3}>
                  <Typography className="font-bold">
                    {ChessPiecesTranslationsEnum[ChessPiecesEnum.BKING]}
                  </Typography>
                  <div className="grid grid-cols-2 gap-6">
                    <FormItem className="upload-form-item" label="SVG" name={ChessPiecesEnum.BKING}>
                      <FileUpload
                        accept=".svg"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.5', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.5', {
                              ...chessPieces[5],
                              imgUrl: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.BKING,
                            });
                        }}
                      />
                    </FormItem>
                    <FormItem className="upload-form-item" label="PNG" name={ChessPiecesEnum.BKING}>
                      <FileUpload
                        accept=".png"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.5', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.5', {
                              ...chessPieces[5],
                              imgUrlPng: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.BKING,
                            });
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col span={3}>
                  <Typography className="font-bold">
                    {ChessPiecesTranslationsEnum[ChessPiecesEnum.WPAWN]}
                  </Typography>
                  <div className="grid grid-cols-2 gap-6">
                    <FormItem className="upload-form-item" label="SVG" name={ChessPiecesEnum.WPAWN}>
                      <FileUpload
                        accept=".svg"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.6', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.6', {
                              ...chessPieces[6],
                              imgUrl: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.WPAWN,
                            });
                        }}
                      />
                    </FormItem>
                    <FormItem className="upload-form-item" label="PNG" name={ChessPiecesEnum.WPAWN}>
                      <FileUpload
                        accept=".png"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.6', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.6', {
                              ...chessPieces[6],
                              imgUrlPng: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.WPAWN,
                            });
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>

                <Col span={3}>
                  <Typography className="font-bold">
                    {ChessPiecesTranslationsEnum[ChessPiecesEnum.WKNIGHT]}
                  </Typography>
                  <div className="grid grid-cols-2 gap-6">
                    <FormItem
                      className="upload-form-item"
                      label="SVG"
                      name={ChessPiecesEnum.WKNIGHT}
                    >
                      <FileUpload
                        accept=".svg"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.7', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.7', {
                              ...chessPieces[7],
                              imgUrl: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.WKNIGHT,
                            });
                        }}
                      />
                    </FormItem>
                    <FormItem
                      className="upload-form-item"
                      label="PNG"
                      name={ChessPiecesEnum.WKNIGHT}
                    >
                      <FileUpload
                        accept=".png"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.7', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.7', {
                              ...chessPieces[7],
                              imgUrlPng: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.WKNIGHT,
                            });
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>

                <Col span={3}>
                  <Typography className="font-bold">
                    {ChessPiecesTranslationsEnum[ChessPiecesEnum.WBISHOP]}
                  </Typography>
                  <div className="grid grid-cols-2 gap-6">
                    <FormItem
                      className="upload-form-item"
                      label="SVG"
                      name={ChessPiecesEnum.WBISHOP}
                    >
                      <FileUpload
                        accept=".svg"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.8', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.8', {
                              ...chessPieces[8],
                              imgUrl: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.WBISHOP,
                            });
                        }}
                      />
                    </FormItem>
                    <FormItem
                      className="upload-form-item"
                      label="PNG"
                      name={ChessPiecesEnum.WBISHOP}
                    >
                      <FileUpload
                        accept=".png"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.8', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.8', {
                              ...chessPieces[8],
                              imgUrlPng: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.WBISHOP,
                            });
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>

                <Col span={3}>
                  <Typography className="font-bold">
                    {ChessPiecesTranslationsEnum[ChessPiecesEnum.WROOK]}
                  </Typography>
                  <div className="grid grid-cols-2 gap-6">
                    <FormItem className="upload-form-item" label="SVG" name={ChessPiecesEnum.WROOK}>
                      <FileUpload
                        accept=".svg"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.9', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.9', {
                              ...chessPieces[9],
                              imgUrl: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.WROOK,
                            });
                        }}
                      />
                    </FormItem>
                    <FormItem className="upload-form-item" label="PNG" name={ChessPiecesEnum.WROOK}>
                      <FileUpload
                        accept=".png"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.9', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.9', {
                              ...chessPieces[9],
                              imgUrlPng: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.WROOK,
                            });
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>

                <Col span={3}>
                  <Typography className="font-bold">
                    {ChessPiecesTranslationsEnum[ChessPiecesEnum.WQUEEN]}
                  </Typography>
                  <div className="grid grid-cols-2 gap-6">
                    <FormItem
                      className="upload-form-item"
                      label="SVG"
                      name={ChessPiecesEnum.WQUEEN}
                    >
                      <FileUpload
                        accept=".svg"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.10', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.10', {
                              ...chessPieces[10],
                              imgUrl: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.WQUEEN,
                            });
                        }}
                      />
                    </FormItem>
                    <FormItem
                      className="upload-form-item"
                      label="PNG"
                      name={ChessPiecesEnum.WQUEEN}
                    >
                      <FileUpload
                        accept=".png"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.10', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.10', {
                              ...chessPieces[10],
                              imgUrlPng: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.WQUEEN,
                            });
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>

                <Col span={3}>
                  <Typography className="font-bold">
                    {ChessPiecesTranslationsEnum[ChessPiecesEnum.WKING]}
                  </Typography>
                  <div className="grid grid-cols-2 gap-6">
                    <FormItem className="upload-form-item" label="SVG" name={ChessPiecesEnum.WKING}>
                      <FileUpload
                        accept=".svg"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.11', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.11', {
                              ...chessPieces[11],
                              imgUrl: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.WKING,
                            });
                        }}
                      />
                    </FormItem>
                    <FormItem className="upload-form-item" label="PNG" name={ChessPiecesEnum.WKING}>
                      <FileUpload
                        accept=".png"
                        folderName="pieces"
                        icon={<UploadOutlined className="text-ant-gray-7 w-[24px]" />}
                        label=""
                        maxCount={1}
                        onChange={(info, linkInfo) => {
                          if (!info.file.status) setFieldValue('chessPieces.11', []);
                          if (info.file.status == 'done')
                            setFieldValue('chessPieces.11', {
                              ...chessPieces[11],
                              imgUrlPng: linkInfo.downloadUrl,
                              type: ChessPiecesEnum.WKING,
                            });
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>
              </Row>

              <div className="flex gap-4 justify-end">
                <Button onClick={() => navigate('/collections/figures')}>Отмена</Button>
                <Button htmlType="submit" type="primary">
                  <span className="max-w-full overflow-ellipsis overflow-hidden">
                    {status == 'ACTIVE' ? 'Создать и запустить' : 'Сохранить как черновик'}
                  </span>
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
