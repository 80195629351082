import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { userQueryKeys } from '@users/api/userQueryKeys';
import { IUser } from '@users/types/IUser';

export type IUserRequest = { id: number };

type IUserResponse = AxiosResponse<IUser>;

export const userRequest = async ({ id }: IUserRequest): Promise<IUserResponse> => {
  return await axios.get(`/customers/${id}`);
};

export function useQueryUser(params: IUserRequest) {
  return useQuery<IUserResponse, AxiosError>([userQueryKeys.user, params], () =>
    userRequest(params),
  );
}
