import { dashboardsPermission } from '@typess/permissions/IAnalitycsPermission';
import { exercisePermissions } from '@typess/permissions/IExercisePermission';
import { IPermission } from '@typess/permissions/IPermission';
import { settingPermissions } from '@typess/permissions/ISettingPermission';
import { shopPermissions } from '@typess/permissions/IShopPermission';
import { staffPermissions } from '@typess/permissions/IStaffPermission';
import { tagPermissions } from '@typess/permissions/ITagPermission';
import { userPermissions } from '@typess/permissions/IUserPermission';
import { videoPermissions } from '@typess/permissions/IVideoPermission';

export interface NavbarInterface {
  icon?: JSX.Element;
  key: string;
  link: string;
  permissions?: IPermission[];
  title?: string;
}

export const navbars: NavbarInterface[] = [
  {
    key: 'users',
    link: '/users',
    permissions: Object.values(userPermissions),
    title: 'Пользователи',
  },
  {
    key: 'staff',
    link: '/staff',
    permissions: Object.values(staffPermissions),
    title: 'Персонал',
  },
  {
    key: 'videos',
    link: '/videos',
    permissions: Object.values(videoPermissions),
    title: 'Видеобиблиотека',
  },
  {
    key: 'exercises',
    link: '/exercises',
    permissions: Object.values(exercisePermissions),
    title: 'Задачи',
  },
  {
    key: 'tags',
    link: '/tags',
    permissions: Object.values(tagPermissions),
    title: 'Теги',
  },
  {
    key: 'shop',
    link: '/shop',
    permissions: Object.values(shopPermissions),
    title: 'Магазин',
  },
  {
    key: 'collections',
    link: '/collections',
    permissions: Object.values(settingPermissions),
    title: 'Коллекции',
  },
  {
    key: 'settings',
    link: '/settings',
    permissions: Object.values(settingPermissions),
    title: 'Настройки',
  },
  {
    key: 'dashboards',
    link: '/dashboards',
    permissions: Object.values(dashboardsPermission),
    title: 'Аналитика',
  },
];
