import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { staffsQueryKeys } from '@staff/api/staffsQueryKeys';
import { notify } from '@utils/notify';

type IStaffDeleteRequest = { id: number };

type IStaffDeleteResponse = AxiosResponse<void>;

export const staffDeleteRequest = async (
  payload: IStaffDeleteRequest,
): Promise<IStaffDeleteResponse> => await axios.delete(`/staff/${payload.id}`);
export function useMutateStaffDelete() {
  const queryClient = useQueryClient();

  return useMutation<IStaffDeleteResponse, AxiosError, IStaffDeleteRequest>(staffDeleteRequest, {
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries(staffsQueryKeys.staffs);
      await queryClient.invalidateQueries([staffsQueryKeys.staff, { id: variables.id }]);

      notify('error', 'Персонал удален');
    },
  });
}
