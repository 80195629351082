import type { FC } from 'react';
import { Button, Modal, Switch as AntSwitch, Tag } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useQueryAvatars } from '@collections/avatars/api/useQueryAvatars';
import {
  createAvatarGroupInitialValue,
  createAvatarGroupValidation,
  IAvatarGroupCreateRequest,
  useMutateAvatarGroupCreate,
} from '@collections/avatars-groups/api/useMutateAvatarGroupCreate';
import { CountryImageText } from '@components/atoms/CountryImageText';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const AvatarGroupCreateModal: FC<Props> = ({ isOpen, onClose }) => {
  const createAvatarGroup = useMutateAvatarGroupCreate();
  const avatars = useQueryAvatars();

  return (
    <Modal footer={null} onCancel={onClose} open={isOpen} title="Создать группу аватаров">
      <Formik<IAvatarGroupCreateRequest>
        enableReinitialize
        initialValues={createAvatarGroupInitialValue}
        onSubmit={(values) =>
          createAvatarGroup
            .mutateAsync({
              ...values,
              items: (values.items as unknown as number[])?.map((id, index) => ({
                avatarId: id,
                priority: index,
              })),
            })
            .then(() => {
              onClose();
            })
        }
        validateOnBlur={false}
        validationSchema={createAvatarGroupValidation}
      >
        {({ setFieldValue, values: { status } }) => (
          <Form className="gap-4 md:gap-6" layout="vertical">
            <FormItem
              label={<CountryImageText src="ru" text="Название на русском" />}
              name="localizations.ru.name"
            >
              <Input name="localizations.ru.name" prefix />
            </FormItem>
            <FormItem
              label={<CountryImageText src="en" text="Название на английском" />}
              name="localizations.en.name"
            >
              <Input name="localizations.en.name" prefix />
            </FormItem>
            <FormItem label="Аватары" name="items">
              <Select
                loading={avatars.isFetching}
                mode="multiple"
                name="items"
                optionFilterProp="label"
                options={
                  avatars.data?.data.content.map((tag) => ({
                    label: tag.localizations.en.name,
                    value: tag.id,
                  })) || []
                }
                placeholder="Аватары"
                tagRender={
                  avatars.isFetching
                    ? () => (
                        <Tag>
                          <LoadingOutlined />
                        </Tag>
                      )
                    : undefined
                }
              />
            </FormItem>

            <div className="flex justify-center text-center">
              <FormItem label={<span className="text-center">Запустить</span>} name="status">
                <Input hidden name="status" suffix />

                <AntSwitch
                  checked={status == 'ACTIVE'}
                  onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
                />
              </FormItem>
            </div>

            <div className="flex gap-4 justify-end">
              <Button onClick={onClose}>Отмена</Button>
              <Button htmlType="submit" loading={createAvatarGroup.isLoading} type="primary">
                <span className="max-w-full overflow-ellipsis overflow-hidden">
                  {status == 'ACTIVE' ? 'Создать и запустить' : 'Сохранить как черновик'}
                </span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
