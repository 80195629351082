import { FC } from 'react';
import { Modal } from 'antd';

import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { useQueryMapStageDetails } from '@exercises/map-stages/api/useQueryMapStageDetails';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';

import { MapStageEditFormik } from '../molecules/MapStageEditFormik';

export const MapStageEditMoal: FC<{
  id?: number;
  isOpen: boolean;
  onCancel: () => void;
  openView: () => void;
}> = ({ id = 0, isOpen, onCancel }) => {
  const stageQuery = useQueryMapStageDetails({ id });
  const stage = stageQuery.data?.data;

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3 top-10"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={`Редактировать шаг на карте - #${stage?.stageNumber} | ${
        stage?.playLevel ? userPlayLevels[stage?.playLevel] : '-'
      }`}
    >
      {stageQuery.isLoading ? (
        <BaseSkeleton />
      ) : !stage ? (
        <NotFoundResult title="Упражнение не найдено" />
      ) : (
        <MapStageEditFormik onCancel={onCancel} stage={stage} />
      )}
    </Modal>
  );
};
