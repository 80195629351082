import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IBoard } from '../types/IBoard';
import { boardCommonKeys } from './boardCommonKeys';

export type BoardStatusUpdateRequest = Pick<IBoard, 'id' | 'status'>;

type BoardStatusUpdateResponse = AxiosResponse<void>;

export const boardStatusUpdateRequest = async ({
  id,
  ...payload
}: BoardStatusUpdateRequest): Promise<BoardStatusUpdateResponse> => {
  return await axios.patch(`/chess-boards/${id}`, payload);
};

export function useMutateBoardStatusUpdate() {
  const queryClient = useQueryClient();

  return useMutation<BoardStatusUpdateResponse, AxiosError, BoardStatusUpdateRequest>(
    boardStatusUpdateRequest,
    {
      onSuccess: () => queryClient.invalidateQueries(boardCommonKeys.boards),
    },
  );
}
