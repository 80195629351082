export enum IEnumMatchResultType {
  ABORT = 'Отменено',
  DISCONNECT = 'Отключение',
  DRAW = 'Ничья',
  MATE = 'Мат',
  RESIGN = 'Сдача',
  TIME_UP = 'Истекло время',
}

export type IEnumMatchResultTypeKeys = keyof typeof IEnumMatchResultType;
