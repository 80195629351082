import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';

import { IPayingUser, payingUserKeys } from '@dashboards/types/IPayingUser';
import { getSubscriptions } from '@dashboards/types/iSubsctiptionType';

export const payingUsersColumns = (): ColumnsType<IPayingUser> => {
  return [
    {
      dataIndex: payingUserKeys.customerId,
      title: 'ID пользователя',
    },
    {
      dataIndex: payingUserKeys.eventType,
      title: 'Тип события',
    },
    {
      dataIndex: payingUserKeys.eventDate,
      render: (eventDate: IPayingUser['eventDate']) => moment(eventDate).format('DD-MM-YYYY'),
      title: 'Дата транзакции',
    },

    {
      dataIndex: payingUserKeys.price,
      render: (price: IPayingUser['price']) => `${price}$`,
      title: 'Сумма транзакции',
    },
    {
      dataIndex: payingUserKeys.priceInPurchasedCurrency,
      render: (
        priceInPurchasedCurrency: IPayingUser['priceInPurchasedCurrency'],
        row: IPayingUser,
      ) => `${priceInPurchasedCurrency} ${row.currency ?? ''}`,
      title: 'Сумма транзакции в валюте пользователя',
    },
    {
      dataIndex: payingUserKeys.productId,
      render: (productId: IPayingUser['productId']) =>
        getSubscriptions().find((subs) => subs.value === productId)?.label ?? productId,
      title: 'Купленный товар',
    },

    {
      dataIndex: payingUserKeys.countryName,
      title: 'Cтрана',
    },
    {
      dataIndex: payingUserKeys.platformName,
      render: (platformName: IPayingUser['platformName']) =>
        platformName === 'PLAY_STORE' ? 'Android' : 'Apple',
      title: 'ОС',
    },
    {
      dataIndex: payingUserKeys.registrationDate,
      render: (registrationDate: IPayingUser['registrationDate']) =>
        moment(registrationDate).format('DD-MM-YYYY'),
      title: 'Дата регистрации',
    },
  ];
};
