import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { commonQueryKeys } from '@api/commonQueryKeys';
import { ICountry } from '@typess/ICountry';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

type ICountryRequest = {
  iso?: string;
  iso3?: string;
  name?: string;
  numcode?: number;
};

export const countriesGetRequest = async (
  params?: ICountryRequest,
): Promise<AxiosResponse<IPageAbleResponse<ICountry>>> => {
  return await axios.get<IPageAbleResponse<ICountry>>('country', { params });
};

export function useQueryCountries(params?: ICountryRequest) {
  return useQuery<AxiosResponse<IPageAbleResponse<ICountry>>, AxiosError>(
    [commonQueryKeys.countries, params],
    () => countriesGetRequest(params),
  );
}
