import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { ILecturerCard } from '@collections/lecturer-cards/types/ILecturerCard';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

import { lecturerCardCommonKeys } from './lecturerCardKeys';

export type ILecturerCardsRequest = IPageAbleRequest;

type ILecturerCardsResponse = IPageAbleResponse<ILecturerCard>;

export const lecturerCardsGetRequest = async (params?: ILecturerCardsRequest) =>
  axios.get<ILecturerCardsResponse>(`/admin/author-cards`, { params });

export function useQueryLecturerCards(params?: ILecturerCardsRequest) {
  return useQuery<AxiosResponse<ILecturerCardsResponse>, AxiosError>(
    [lecturerCardCommonKeys.lecturerCards, params],
    () => lecturerCardsGetRequest(params),
  );
}
