import { FC } from 'react';
import { Button, Modal, Switch as AntSwitch } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';

import { CountryImageText } from '@components/atoms/CountryImageText';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import {
  fenEditValidation,
  IFenEditRequest,
  useMutateFenEdit,
} from '@exercises/fens/api/useMutateFenEdit';
import { fenKeys, IFen } from '@exercises/fens/types/IFen';
import { notify } from '@utils/notify';
import { useQueryVideos } from '@videos/api/useQueryVideos';

export const FenEditModal: FC<{
  fen?: IFen;
  isOpen: boolean;
  onCancel: () => void;
}> = ({ fen, isOpen, onCancel }) => {
  const videos = useQueryVideos({ isCartoon: false });
  const fenEdit = useMutateFenEdit();

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3 top-10"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={<h1 className="text-xl m-0 flex items-center gap-1">Изменить FEN</h1>}
    >
      {!fen ? (
        <NotFoundResult title="FEN не найден" />
      ) : (
        <Formik<IFenEditRequest>
          enableReinitialize
          initialValues={{
            id: fen.id,
            localizations: fen.localizations,
            name: fen.name,
            status: fen.status,
            value: fen.value,
            videoIds: fen.videos?.map(({ id }) => id) || [],
          }}
          onSubmit={(values, { resetForm }) => {
            fenEdit.mutateAsync(values).then((data) => {
              if (data.status == 201 || data.status == 200) {
                notify('success', 'FEN изменен');
                onCancel();
                resetForm();
              }
            });
          }}
          validationSchema={fenEditValidation}
        >
          {({ setFieldValue, values: { status } }) => (
            <Form className="space-y-8" layout="vertical">
              <FormItem label="FEN" name={fenKeys.value}>
                <Input maxLength={100} name={fenKeys.value} placeholder="Введите FEN" suffix />
              </FormItem>

              <FormItem label="Название FEN" name={fenKeys.name}>
                <Input maxLength={150} name={fenKeys.name} placeholder="Название FEN" suffix />
              </FormItem>

              <FormItem label="Видеоуроки" name="videoIds">
                <Select
                  allowClear
                  className="gap-y-2"
                  mode="multiple"
                  name="videoIds"
                  optionFilterProp="label"
                  options={
                    videos.data?.data.content.map((video) => ({
                      label: video.localizations.en?.fullTitle,
                      value: video.id,
                    })) || []
                  }
                  placeholder="Видео"
                  showSearch
                />
              </FormItem>

              <FormItem
                label={<CountryImageText src="ru" text="Сообщение Каиссы" />}
                name="localizations.ru"
              >
                <Input.TextArea
                  maxLength={400}
                  name="localizations.ru.messageFromKaissa"
                  placeholder="Сообщение Каиссы"
                />
              </FormItem>

              <FormItem
                label={<CountryImageText src="en" text="Kaissa's message" />}
                name="localizations.en"
              >
                <Input.TextArea
                  maxLength={400}
                  name="localizations.en.messageFromKaissa"
                  placeholder="Kaissa's message"
                />
              </FormItem>

              <div className="flex justify-center text-center">
                <FormItem
                  label={<span className="text-center">Запустить</span>}
                  name={fenKeys.status}
                >
                  <Input hidden name={fenKeys.status} suffix />

                  <AntSwitch
                    checked={status == 'ACTIVE'}
                    onChange={(checked) =>
                      setFieldValue(fenKeys.status, checked ? 'ACTIVE' : 'DRAFT')
                    }
                  />
                </FormItem>
              </div>

              <div className="grid md:grid-cols-2 gap-5">
                <Button block onClick={onCancel}>
                  Отменить
                </Button>

                <Button block htmlType="submit" loading={fenEdit.isLoading} type="primary">
                  <span className="max-w-full overflow-ellipsis overflow-hidden">
                    {status == 'ACTIVE' ? 'Изменить и запустить' : 'Изменить'}
                  </span>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
