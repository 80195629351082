import { FC } from 'react';
import { Button, Modal } from 'antd';
import { QuestionOutlined, SearchOutlined } from '@ant-design/icons';

import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { FaChessKnightIcon } from '@components/atoms/icons';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { useQueryAssessmentExercise } from '@exercises/levelTesting/api/useQueryAssessmentExercise';
import { AssessmentExerciseTypeEnum } from '@exercises/levelTesting/types/enums/AssessmentExerciseTypeEnum';

import { AssessmentProblemEditFormik } from '../molecules/AssessmentProblemEditFormik';
import { AssessmentTestEditFormik } from '../molecules/AssessmentTestEditFormik';

export const AssessmentExerciseEditModal: FC<{
  id?: number;
  isOpen: boolean;
  onCancel: () => void;
  openView: () => void;
}> = ({ id = 0, isOpen, onCancel, openView }) => {
  const exerciseQuery = useQueryAssessmentExercise({ id });
  const exercise = exerciseQuery.data?.data;

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3 top-10"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        exercise?.type && (
          <div className="flex flex-row gap-4 truncate">
            <h1 className="text-xl m-0 flex items-center gap-1">
              {exercise.type == AssessmentExerciseTypeEnum.TEST ? (
                <>
                  <QuestionOutlined className="text-ant-orange-6" /> Тест #{id}
                </>
              ) : (
                <>
                  <FaChessKnightIcon /> Упражнение #{id}
                </>
              )}
            </h1>
            <Button icon={<SearchOutlined />} onClick={openView} shape="circle" />
          </div>
        )
      }
    >
      {exerciseQuery.isLoading ? (
        <BaseSkeleton />
      ) : !exercise ? (
        <NotFoundResult title="Упражнение не найдено" />
      ) : exercise?.type == AssessmentExerciseTypeEnum.PROBLEM && exercise.problem ? (
        <AssessmentProblemEditFormik
          exercise={{
            ...exercise,
            problem: {
              ...exercise.problem,
              id: exercise.id,
              playLevel: exercise.playLevel,
              priority: exercise.priority,
              status: exercise.status,
            },
          }}
          onCancel={onCancel}
        />
      ) : (
        exercise?.type == AssessmentExerciseTypeEnum.TEST &&
        exercise.test && (
          <AssessmentTestEditFormik
            exercise={{ ...exercise, test: exercise.test }}
            onCancel={onCancel}
          />
        )
      )}
    </Modal>
  );
};
