import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment/moment';
import {
  CaretRightOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import { AvatarStatusEnumKeys } from '@collections/avatars/types/enums/avatarStatusEnum';
import { avatarGroupKeys, IAvatarGroup } from '@collections/avatars-groups/types/IAvatarGroup';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { PencilIcon } from '@components/atoms/icons/PencilIcon';

export const avatarGroupColumns = ({
  onClickDelete,
  onClickEdit,
  onClickPause,
  onClickView,
}: {
  onClickDelete: ({ id }: { id: number }) => void;
  onClickEdit: ({ id }: { id: number }) => void;
  onClickPause: ({ id, status }: { id: number; status: AvatarStatusEnumKeys }) => void;
  onClickView: ({ id }: { id: number }) => void;
}): ColumnsType<IAvatarGroup> => {
  const columns: ColumnsType<IAvatarGroup> = [
    {
      align: 'center' as const,
      key: 'sort',
      width: 50,
    },
    {
      dataIndex: avatarGroupKeys.id,
      render: (id: IAvatarGroup['id']) => (
        <Button onClick={() => onClickView({ id })} type="link">
          {id}
        </Button>
      ),
      title: 'ID',
    },
    {
      dataIndex: avatarGroupKeys.localizations,
      render: (localizations: IAvatarGroup['localizations']) => {
        return (
          <div>
            <CountryImageText src="en" text={localizations?.en?.name || '-'} />
            <CountryImageText src="ru" text={localizations?.ru?.name || '-'} />
          </div>
        );
      },
      title: 'Название группы',
    },
    {
      dataIndex: avatarGroupKeys.itemsCount,
      title: 'Количество аватаров',
    },
    {
      dataIndex: avatarGroupKeys.createdDate,
      render: (createdDate: IAvatarGroup['createdDate']) => (
        <span className="whitespace-nowrap">{moment(createdDate).format('LL')}</span>
      ),
      title: 'Дата создания',
    },
  ];

  return [
    ...columns,
    {
      key: 'action',
      render: (avatarGroup: IAvatarGroup) => (
        <div className="flex gap-x-1.5">
          <Popconfirm
            cancelText="Отменить"
            icon={
              avatarGroup.status == 'ACTIVE' ? (
                <PauseCircleOutlined className="text-ant-blue-5 text-base" />
              ) : (
                <PlayCircleOutlined className="text-ant-blue-5 text-base" />
              )
            }
            okText={avatarGroup.status === 'ACTIVE' ? 'Приостановить' : 'Запустить'}
            okType="primary"
            onConfirm={() =>
              onClickPause({
                id: avatarGroup.id,
                status: avatarGroup.status == 'ACTIVE' ? 'DRAFT' : 'ACTIVE',
              })
            }
            placement="topLeft"
            title={
              <p className="text-sm mb-0">
                Вы уверены, что хотите
                <span className="font-semibold">
                  {avatarGroup.status == 'ACTIVE' ? ' приостановить ' : ' запустить '}
                  {avatarGroup.localizations.ru?.name}?
                </span>
              </p>
            }
          >
            <Button
              ghost
              icon={avatarGroup.status === 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
              type="primary"
            />
          </Popconfirm>
          <Button
            ghost
            icon={<PencilIcon />}
            onClick={() => onClickEdit({ id: avatarGroup.id })}
            type="primary"
          />
          <Popconfirm
            cancelText="Отменить"
            icon={<DeleteOutlined className="text-ant-red-5" />}
            okText="Удалить"
            okType="danger"
            onConfirm={() => onClickDelete({ id: avatarGroup.id })}
            placement="topLeft"
            title={
              <span className="text-sm">
                Вы уверены, что хотите удалить группу аватаров
                <span className="font-bold"> &quot;{avatarGroup.localizations.ru?.name}&quot;</span>
                ?
              </span>
            }
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
      title: 'Действия',
    },
  ];
};
