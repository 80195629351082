import { FC, useEffect, useState } from 'react';
import { Button, Card, DatePicker, Table, Typography } from 'antd';
import { DatePickerProps } from 'antd/lib';
import dayjs, { Dayjs } from 'dayjs';
import { ColumnConfig } from '@ant-design/charts';
import { UploadOutlined } from '@ant-design/icons';
import { Column } from '@ant-design/plots';

import { PageMeta } from '@context/PageMetaContext';
import { useQueryUsersAgeCategoryStats } from '@dashboards/api/useQueryAgeCategoryStats';
import { usersAgeStatsColumns } from '@dashboards/consts/columns/usersAgeStatsColumn';
import { IUserAgeStat } from '@dashboards/types/IUserAgeStat';
import { useMutateUserExport } from '@users/api/useMutateExportUser';

import { DashboardTabs } from '../molecules/DashboardTabs';

const AppDashboardsPage: FC = () => {
  const dateFormat = 'YYYY-MM-DD';
  const exportStats = useMutateUserExport();

  const [startDate, setStartDate] = useState<Dayjs>(dayjs('2022/09/26', dateFormat));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs());

  const { data, isLoading, refetch } = useQueryUsersAgeCategoryStats({
    from: startDate?.format('YYYY-MM-DD HH:mm:ss'),
    to: endDate?.format('YYYY-MM-DD HH:mm:ss'),
  });

  useEffect(() => void refetch(), [startDate, endDate]);

  const transformedData: IUserAgeStat[] = [];

  for (const ageRange in data?.data) {
    if (Object.hasOwnProperty.call(data?.data, ageRange)) {
      const ageData = data?.data[ageRange];
      if (ageData) {
        ageData.ageRange = ageRange;
        transformedData.push(ageData);
      }
    }
  }

  const totalUsers = data?.data.total.totalNumber;

  const config: ColumnConfig = {
    data: transformedData.filter((data) => data.ageRange !== 'total'),
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    meta: {
      sales: {
        alias: 'пользователи',
      },
      type: {
        alias: 'блабла',
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    xField: 'ageRange',
    yField: 'totalNumber',
  };

  const disabledStartDate: DatePickerProps['disabledDate'] = (current) => {
    return (
      current &&
      (current <= dayjs('2022/09/25').endOf('day') || current > dayjs(endDate).endOf('day'))
    );
  };

  const disabledEndDate: DatePickerProps['disabledDate'] = (current) => {
    return current && (current <= dayjs(startDate).endOf('day') || current > dayjs().endOf('day'));
  };

  return (
    <>
      <PageMeta selectedMenuKeys={['dashboards']} title="Аналитика" />
      <DashboardTabs active="dashboards" />
      <div className="flex justify-between mb-6">
        <div className="flex flex-row items-center">
          <DatePicker
            defaultValue={startDate}
            disabledDate={disabledStartDate}
            onChange={(v) => v && setStartDate(v)}
            placeholder="from"
          />
          <Typography className="text-gray-700 mx-2">—</Typography>
          <DatePicker
            defaultValue={endDate}
            disabledDate={disabledEndDate}
            onChange={(v) => v && setEndDate(v)}
            placeholder="to"
          />
        </div>

        <Button
          className="sm:px-12"
          icon={<UploadOutlined />}
          loading={exportStats.isLoading}
          onClick={exportStats.mutateAsync}
          size="large"
          type="primary"
        >
          Выгрузить в excel
        </Button>
      </div>

      <Table
        className="mb-8"
        columns={usersAgeStatsColumns({ totalUsers })}
        dataSource={transformedData}
        loading={isLoading}
        pagination={false}
        rowClassName={(record, index) => (index === 0 ? 'bg-[#fafafa]' : '')}
        scroll={{ x: '100%' }}
      />
      <Card className="w-[50%]">
        <Column {...config} />
      </Card>
    </>
  );
};

export default AppDashboardsPage;
