import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, boolean, number, object, ObjectSchema, string } from 'yup';

import { exerciseQueryKeys } from '@exercises/exercises/api/exerciseQueryKeys';
import { ExerciseStatusEnumKeys } from '@exercises/exercises/types/enums/ExerciseStatusEnum';
import { IExerciseTest } from '@exercises/exercises/types/IExerciseTest';

export type IExerciseTestCreateRequest = Omit<
  IExerciseTest,
  'id' | 'createdBy' | 'tags' | 'gamePhase' | 'video' | 'gamePhase'
> & {
  tagIds: number[];
  videoId?: number | null;
};

type IExerciseTestCreateResponse = AxiosResponse<void>;

export const exerciseTestCreateInitial: IExerciseTestCreateRequest = {
  correctOption: 1,
  imageUrl: '',
  isCapitalization: false,
  isPawnStructure: false,
  isTactic: false,
  localizations: {
    en: {
      answerOptions: ['', ''],
      question: '',
    },
    ru: {
      answerOptions: ['', ''],
      question: '',
    },
  },
  playLevels: [],
  status: 'ACTIVE',
  tagIds: [],
};

export const exerciseTestCreateValidation: ObjectSchema<IExerciseTestCreateRequest> = object({
  correctOption: number().required(),
  imageUrl: string().required(),
  isCapitalization: boolean(),
  isPawnStructure: boolean().required('.'),
  isTactic: boolean().required('.'),
  localizations: object({
    en: object({
      answerOptions: array()
        .required()
        .of(string().required('Ответ обязателен для заполнения'))
        .max(100, 'Ответ должен быть не более 60 символов'),
      question: string()
        .required('Вопрос обязателен для заполнения')
        .max(250, 'Вопрос должен быть не более 200 символов'),
    }),
    ru: object({
      answerOptions: array()
        .required()
        .of(string().required('Ответ обязателен для заполнения'))
        .max(100, 'Ответ должен быть не более 60 символов'),
      question: string()
        .required('Вопрос обязателен для заполнения')
        .max(250, 'Вопрос должен быть не более 200 символов'),
    }),
  }),
  playLevels: array().required('').min(1, 'Упражнение должно содержать минимум 1 уровень'),
  status: string<ExerciseStatusEnumKeys>().required('Статус обязателен для заполнения'),
  tagIds: array().of(number().required()).required(),
  videoId: number().nullable(),
});

export const exerciseTestCreateRequest = async (
  payload: IExerciseTestCreateRequest,
): Promise<IExerciseTestCreateResponse> => await axios.post('/exercises/test', payload);

export function useMutateExerciseTestCreate() {
  const queryClient = useQueryClient();

  return useMutation<IExerciseTestCreateResponse, AxiosError, IExerciseTestCreateRequest>(
    exerciseTestCreateRequest,
    {
      onSuccess: () => queryClient.invalidateQueries(exerciseQueryKeys.exercises),
    },
  );
}
