import { FC } from 'react';
import { Button } from 'antd';

import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { ShopTabs } from '@shop/appProducts/components/molecules/ShopTabs';
import {
  ShopPageActions,
  shopPageParams,
} from '@shop/appProducts/consts/pageActions/ShopPageActions';
import { useMutateAppPromotionDelete } from '@shop/appPromotions/api/useMutateAppPromotionDelete';
import { useMutateAppPromotionStatus } from '@shop/appPromotions/api/useMutateAppPromotionStatus';
import {
  IAppPromotionsRequest,
  useQueryAppPromotions,
} from '@shop/appPromotions/api/useQueryAppPromotions';
import { PromotionCreateModal } from '@shop/appPromotions/components/organisms/PromotionCreateModal';
import { PromotionEditModal } from '@shop/appPromotions/components/organisms/PromotionEditModal';
import { promotionsColumns } from '@shop/appPromotions/consts/columns/promotionsColumns';
import { promotionsFilters } from '@shop/appPromotions/consts/filters/promotionsFilters';
import { shopPermissions } from '@typess/permissions/IShopPermission';
import { checkEditPermission } from '@utils/checkEditPermission';

export const ShopPromotionsPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IAppPromotionsRequest,
    ShopPageActions
  >(promotionsFilters, shopPageParams);

  const appPromotions = useQueryAppPromotions(filtered);
  const appPromotionStatus = useMutateAppPromotionStatus();
  const appPromotionDelete = useMutateAppPromotionDelete();

  const canEditShop = checkEditPermission(shopPermissions);

  return (
    <>
      <PageMeta
        selectedMenuKeys={['shop']}
        subTitle={
          actioned.action == 'create'
            ? 'Создать кампанию'
            : actioned.action == 'edit'
            ? `Изменить кампанию #${actioned.actionId}`
            : ''
        }
        title="Кампании — магазин"
      />

      <ShopTabs active="campaigns" />

      {canEditShop && (
        <div className="flex justify-end">
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'create' })}
            size="large"
            type="primary"
          >
            Добавить кампанию
          </Button>
        </div>
      )}

      <AdminTable
        columns={promotionsColumns({
          canEdit: true,
          onClickDelete: appPromotionDelete.mutateAsync,
          onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
          onClickPause: appPromotionStatus.mutateAsync,
        })}
        emptyDescription={<p>Магазин не найдены</p>}
        query={appPromotions}
        tableFilter={{ filtered, filters: promotionsFilters, onFilter }}
      />

      <PromotionCreateModal isOpen={actioned.action == 'create'} onCancel={clearParams} />

      <PromotionEditModal
        appPromotion={appPromotions.data?.data.content.find(
          (promotion) => promotion.id == actioned.actionId,
        )}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
      />
    </>
  );
};
