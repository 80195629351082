import { FC } from 'react';
import { useState } from 'react';
import { Button } from 'antd';

import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { SettingTabs } from '@settings/appSettings/components/molecules/SettingTabs';
import { promocodesFilters } from '@settings/promocodes/consts/filters/promocodesFilters';
import { promocodeGroupsPageParams } from '@settings/promocodes/consts/pageActions/PromocodeGroupsPageActions';
import { useMutateVersionDelete } from '@settings/versions/api/useMutateVersionDelete';
import { useQueryVersions, VersionsRequest } from '@settings/versions/api/useQueryVersions';
import { versionsColumns } from '@settings/versions/consts/columns/versionsColumns';
import { versionsFilters } from '@settings/versions/consts/filters/versionsFilters';
import { VersionsPageActions } from '@settings/versions/consts/pageActions/VersionsPageActions';
import { settingPermissions } from '@typess/permissions/ISettingPermission';
import { checkEditPermission } from '@utils/checkEditPermission';

import { VersionActivateModal } from '../organisms/VersionActivateModal';
import { VersionCreateModal } from '../organisms/VersionCreateModal';

const VersionsPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    VersionsRequest,
    VersionsPageActions
  >(promocodesFilters, promocodeGroupsPageParams);

  const versions = useQueryVersions(filtered);
  const [versionId, setVersionId] = useState<number>();
  const canEditSettings = checkEditPermission(settingPermissions);
  const versionDelete = useMutateVersionDelete();

  const handleActivate = (id: number) => {
    setActioned({ action: 'activate' });
    setVersionId(id);
  };

  return (
    <>
      <PageMeta
        selectedMenuKeys={['versions']}
        subTitle={actioned.action == 'create' ? 'Создать версию' : ''}
        title="Версионность приложения"
      />

      <SettingTabs active="versions" />

      {canEditSettings && (
        <div className="flex justify-end">
          <Button
            className="sm:px-12"
            onClick={() => setActioned({ action: 'create' })}
            size="large"
            type="primary"
          >
            Добавить версию
          </Button>
        </div>
      )}

      <AdminTable
        columns={versionsColumns({
          onClickActivate: (version) => handleActivate(version.id),
          onClickDelete: versionDelete.mutateAsync,
        })}
        emptyDescription={<p>Версии не найдены</p>}
        query={versions}
        tableFilter={{ filtered, filters: versionsFilters, onFilter }}
      />

      <VersionCreateModal isOpen={actioned.action == 'create'} onCancel={clearParams} />
      <VersionActivateModal
        isOpen={actioned.action == 'activate'}
        onCancel={clearParams}
        versionId={versionId ?? 0}
      />
    </>
  );
};

export default VersionsPage;
