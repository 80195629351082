import { FC } from 'react';
import { Button, Modal } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Select } from 'formik-antd';
import { SearchOutlined } from '@ant-design/icons';

import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { FaChessKnightIcon } from '@components/atoms/icons';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import {
  exerciseGroupEditValidation,
  IExerciseGroupEditRequest,
  useMutateExerciseGroupEdit,
} from '@exercises/exerciseGroups/api/useMutateExerciseGroupEdit';
import { useQueryExerciseGroup } from '@exercises/exerciseGroups/api/useQueryExerciseGroup';
import { ExerciseGamePhaseEnum } from '@exercises/exercises/types/enums/ExerciseGamePhaseEnum';
import { exerciseKeys } from '@exercises/exercises/types/IExercise';
import { IUserPlayLevelNumber, userPlayLevels } from '@users/types/enums/UserPlayLevel';
import { notify } from '@utils/notify';
import { useQueryVideos } from '@videos/api/useQueryVideos';

export const ExerciseGroupEditModal: FC<{
  id?: number;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}> = ({ id = 0, isOpen, onCancel, onSubmit }) => {
  const videos = useQueryVideos({ isCartoon: false });

  const exerciseGroupQuery = useQueryExerciseGroup({ id });
  const exerciseGroup = exerciseGroupQuery.data?.data;

  const exerciseGroupEdit = useMutateExerciseGroupEdit();

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <div className="flex flex-row gap-4 truncate">
          <h1 className="text-xl m-0 flex items-center gap-1">
            <FaChessKnightIcon /> Изменить группу упражнений #{id}
          </h1>
          <Button icon={<SearchOutlined />} onClick={onSubmit} shape="circle" />
        </div>
      }
    >
      {exerciseGroupQuery.isLoading ? (
        <BaseSkeleton />
      ) : !exerciseGroup ? (
        <NotFoundResult title="Группа упражнений не найдена" />
      ) : (
        <Formik<IExerciseGroupEditRequest>
          initialValues={{ groupName: exerciseGroup.groupName, id }}
          onSubmit={(values, { resetForm }) => {
            exerciseGroupEdit.mutateAsync(values).then((data) => {
              if (data.status == 201 || data.status == 200) {
                notify('success', 'Группа упражнений изменена');
                onSubmit();
                resetForm();
              }
            });
          }}
          validationSchema={exerciseGroupEditValidation}
        >
          {({ values }) => (
            <Form className="space-y-8" layout="vertical">
              <div>
                <p className="text-ant-gray-7">
                  Все поля опциональны.
                  <span className="ml-1 font-semibold">
                    Выбранные для изменения поля будут переписаны у всех упражнений в данной группе
                  </span>
                </p>
              </div>

              <FormItem label="Название группы упражнений" name="groupName">
                <Input maxLength={150} name="groupName" placeholder="Название" suffix />
              </FormItem>

              <FormItem label="Уровень" name={exerciseKeys.playLevels}>
                <Select
                  mode="multiple"
                  name={exerciseKeys.playLevels}
                  options={Object.keys(userPlayLevels)
                    .map(Number)
                    .map((playLevel) => ({
                      disabled:
                        !!values.playLevels?.length &&
                        !values.playLevels?.includes(playLevel as IUserPlayLevelNumber),
                      label: userPlayLevels[playLevel],
                      value: playLevel,
                    }))}
                  placeholder="Уровень"
                  showSearch={false}
                />
              </FormItem>

              <FormItem label="Видеоурок" name="videoId">
                <Select
                  allowClear
                  className="gap-y-2"
                  name="videoId"
                  optionFilterProp="label"
                  options={
                    videos.data?.data.content.map((video) => ({
                      label: video.localizations.en?.fullTitle,
                      value: video.id,
                    })) || []
                  }
                  placeholder="Видео"
                  showSearch
                />
              </FormItem>

              <FormItem label="Стадия игры" name={exerciseKeys.gamePhase}>
                <Select
                  allowClear
                  name={exerciseKeys.gamePhase}
                  options={Object.entries(ExerciseGamePhaseEnum).map(([value, label]) => ({
                    label,
                    value,
                  }))}
                  placeholder="Стадия игры"
                />
              </FormItem>

              <FormItem
                label={<span className="text-center">Капитализация преимущества</span>}
                name={exerciseKeys.isCapitalization}
              >
                <Select
                  allowClear
                  name={exerciseKeys.isCapitalization}
                  options={[
                    { label: 'Да', value: 'true' },
                    { label: 'Нет', value: 'false' },
                  ]}
                  placeholder="Капитализация преимущества"
                />
              </FormItem>

              <FormItem
                label={<span className="text-center">Тактика</span>}
                name={exerciseKeys.isTactic}
              >
                <Select
                  allowClear
                  name={exerciseKeys.isTactic}
                  options={[
                    { label: 'Да', value: 'true' },
                    { label: 'Нет', value: 'false' },
                  ]}
                  placeholder="Тактика"
                />
              </FormItem>

              <FormItem
                label={<span className="text-center">Пешечная структура</span>}
                name={exerciseKeys.isPawnStructure}
              >
                <Select
                  allowClear
                  name={exerciseKeys.isPawnStructure}
                  options={[
                    { label: 'Да', value: 'true' },
                    { label: 'Нет', value: 'false' },
                  ]}
                  placeholder="Пешечная структура"
                />
              </FormItem>
              <FormItem label={<span className="text-center">Проверено</span>} name="checked">
                <Select
                  allowClear
                  name="checked"
                  options={[
                    { label: 'Да', value: 'true' },
                    { label: 'Нет', value: 'false' },
                  ]}
                  placeholder="Проверено"
                />
              </FormItem>

              <div className="grid md:grid-cols-2 gap-5">
                <Button block onClick={onCancel}>
                  Отменить
                </Button>

                <Button
                  block
                  htmlType="submit"
                  loading={exerciseGroupEdit.isLoading}
                  type="primary"
                >
                  <span className="max-w-full overflow-ellipsis overflow-hidden">Изменить</span>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
