export default function russianPluralize(
  count,
  { few, many, one }: { few: string; many: string; one: string },
): string {
  const lastDigit = Number(count.toString().substr(-1));

  if (count > 10 && count < 20) {
    return many;
  }
  if (lastDigit === 1) {
    return one;
  }
  if (lastDigit >= 2 && lastDigit <= 4) {
    return few;
  }

  return many;
}
