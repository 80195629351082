import { Children, cloneElement, ReactElement, ReactNode } from 'react';
import { Button } from 'antd';

export const TableSizeChanger = (props: {
  children?: ReactNode;
  onChange?: () => void;
  value?: string;
}) => {
  return (
    <div className="space-x-1">
      {Children.map(props.children, (child) => {
        return cloneElement(child as ReactElement, {
          active: props.value,
          onClick: props.onChange,
        });
      })}
    </div>
  );
};

TableSizeChanger.Option = function Option(props: {
  active?: string;
  onClick: (value) => void;
  value?: string;
}) {
  return (
    <Button
      className="text-black"
      ghost={props.value != props.active}
      onClick={() => props.onClick(props.value)}
      style={
        props.value == props.active
          ? { padding: '4px 6px' }
          : { border: 'none', boxShadow: 'none', padding: '4px 6px' }
      }
      type={props.value == props.active ? 'default' : undefined}
    >
      {props.value}
    </Button>
  );
};
