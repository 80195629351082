import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

import { IMapStage } from '../types/IMapStage';
import { mapStageQueryKeys } from './mapStagesQueryKeys';

export type IMapStagesRequest = IPageAbleRequest & {
  id?: number | string;
};

type IMapStagesResponse = AxiosResponse<IPageAbleResponse<IMapStage>>;

export const mapStagessRequest = async (params?: IMapStagesRequest): Promise<IMapStagesResponse> =>
  await axios.get('/map-stage-definitions', { params });

export function useQueryMapStages(params?: IMapStagesRequest) {
  return useQuery<IMapStagesResponse, AxiosError>([mapStageQueryKeys.mapStages, params], () =>
    mapStagessRequest(params),
  );
}
