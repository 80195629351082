import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { appProductsQueryKeys } from '@shop/appProducts/api/appProductsQueryKeys';
import { AppProductStatusEnumKeys } from '@shop/appProducts/types/enums/AppProductStatusEnum';
import { IAppProduct } from '@shop/appProducts/types/IAppProduct';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';

export type IAppProductsRequest = IPageAbleRequest & {
  createdByUserId?: number;
  statusNot?: AppProductStatusEnumKeys;
};

type IAppProductsResponse = AxiosResponse<IPageAbleResponse<IAppProduct>>;

export const appProductsRequest = async (
  params?: IAppProductsRequest,
): Promise<IAppProductsResponse> => {
  return await axios.get('/inappproduct', { params });
};

export function useQueryAppProducts(params?: IAppProductsRequest) {
  return useQuery<IAppProductsResponse, AxiosError>(
    [appProductsQueryKeys.appProducts, params],
    () => appProductsRequest(params),
  );
}
