import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';
import { userQueryKeys } from '@users/api/userQueryKeys';
import { IUserBrainstorm } from '@users/types/IUserBrainstorm';

export type IUserBrainstormsRequest = IPageAbleRequest;

type IUserBrainstormsResponse = AxiosResponse<IPageAbleResponse<IUserBrainstorm>>;

export const userBrainstormsRequest = async ({
  ...params
}: IUserBrainstormsRequest): Promise<IUserBrainstormsResponse> => {
  return await axios.get(`/puzzle-rush`, { params });
};

export function useQueryUserBrainstorms(params: IUserBrainstormsRequest) {
  return useQuery<IUserBrainstormsResponse, AxiosError>(
    [userQueryKeys.userGameMatches, params],
    () => userBrainstormsRequest(params),
  );
}
