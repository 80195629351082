/**
 * ⚠️ DONT EDIT MANUALLY
 * This file is generated with `./src/cli/generateIcons.js`, check it for the detailed information
 */
import { FC } from 'react';

import { ReactComponent as ChessPieceWhiteQueenIconSvg } from '@assets/icons/chess-piece-white-queen.svg';

import BaseIcon from './_BaseIcon';

type Props = React.ComponentPropsWithoutRef<'span'>;

export const ChessPieceWhiteQueenIcon: FC<Props> = (props) => {
  return (
    <BaseIcon component={ChessPieceWhiteQueenIconSvg} style={{ fontSize: '1.5em' }} {...props} />
  );
};
