import type { FC } from 'react';
import { Modal } from 'antd';

import { useQueryAvatar } from '@collections/avatars/api/useQueryAvatar';
import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { Description } from '@components/atoms/Description';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { TitleAndText } from '@components/atoms/TitleAndText';

type ViewAvatarModalProps = {
  id?: number;
  isOpen: boolean;
  onClose: () => void;
};

export const ViewAvatarModal: FC<ViewAvatarModalProps> = ({ id = 0, isOpen, onClose }) => {
  const avatarQuery = useQueryAvatar({ id });
  const avatar = avatarQuery.data?.data;

  return (
    <Modal footer={null} onCancel={onClose} open={isOpen} width={720}>
      {avatarQuery.isFetching ? (
        <BaseSkeleton />
      ) : !avatar ? (
        <NotFoundResult title="Аватар не найден" />
      ) : (
        <>
          <div className="flex flex-col sm:flex-row gap-4">
            <h2 className="text-2xl">Аватар #{avatar.id}</h2>
          </div>
          <div className="pl-3 pb-4">
            <Description
              description={
                <CountryImageText src="ru" text={avatar.localizations?.ru?.name || '-'} />
              }
              title="Название на русском"
            />
            <Description
              description={
                <CountryImageText src="en" text={avatar.localizations?.en?.name || '-'} />
              }
              title="Название на английском"
            />
          </div>
          <TitleAndText
            text={<img className="max-w-[300px] max-h-[300px]" src={avatar?.imgUrl} />}
            title="Картинка"
          />
        </>
      )}
    </Modal>
  );
};
