import { SubscriptionType } from './iSubsctiptionType';

export interface IPayingUser {
  countryName: string;
  currency: string;
  customerId: number;
  eventDate: string;
  eventType: string;
  platformName: string;
  price: number;
  priceInPurchasedCurrency: number;
  productId: SubscriptionType;
  registrationDate: string;
}

export const payingUserKeys: Record<keyof IPayingUser, string> = {
  countryName: 'countryName',
  currency: 'currency',
  customerId: 'customerId',
  eventDate: 'eventDate',
  eventType: 'eventType',
  platformName: 'platformName',
  price: 'price',
  priceInPurchasedCurrency: 'priceInPurchasedCurrency',
  productId: 'productId',
  registrationDate: 'registrationDate',
};
