import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { avatarCommonKeys } from '@collections/avatars/api/avatarCommonKeys';
import { notify } from '@utils/notify';

import { avatarGroupQueryKeys } from './avatarGroupQueryKeys';

type IAvatarGroupDeleteRequest = { id: number };

type IAvatarGroupDeleteResponse = AxiosResponse<void>;

export const AvatarGroupDeleteRequest = async ({
  id,
}: IAvatarGroupDeleteRequest): Promise<IAvatarGroupDeleteResponse> =>
  await axios.delete(`/avatar-groups/${id}`);

export function useMutateAvatarGroupDelete() {
  const queryClient = useQueryClient();

  return useMutation<IAvatarGroupDeleteResponse, AxiosError, IAvatarGroupDeleteRequest>(
    AvatarGroupDeleteRequest,
    {
      onSuccess: () => {
        notify('error', 'Группа автаров удалена');

        queryClient.invalidateQueries(avatarGroupQueryKeys.avatarsGroups);
        queryClient.invalidateQueries(avatarGroupQueryKeys.avatarGroup);
        queryClient.invalidateQueries(avatarCommonKeys.avatar);
        queryClient.invalidateQueries(avatarCommonKeys.avatars);
      },
    },
  );
}
