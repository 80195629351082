import { IPermissions } from '@typess/permissions/IPermissions';

export function staffPermissionOptions(permissions: IPermissions) {
  return {
    options: permissions.restricted
      ? [
          { label: 'Просмотр', value: permissions.view },
          { label: 'Редактирование', value: permissions.edit },
          { label: 'Ограниченный доступ', value: permissions.restricted },
          { label: 'Запретить доступ', value: 'block' },
        ]
      : [
          { label: 'Просмотр', value: permissions.view },
          { label: 'Редактирование', value: permissions.edit },
          { label: 'Запретить доступ', value: 'block' },
        ],
  };
}
