export const usersMapStagePassedStatsColumns = (totalUsers?: number) => {
  return [
    {
      dataIndex: 'onMapStage',
      title: 'Level',
    },
    {
      dataIndex: 'usersCount',
      title: 'Users count',
    },
    {
      render: (row) => (totalUsers ? ((row.usersCount / totalUsers) * 100).toFixed(2) + '%' : '-'),
      title: '%',
    },
  ];
};
