import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { notify } from '@utils/notify';

import { avatarCommonKeys } from './avatarCommonKeys';

type IDeleteAvatarRequest = {
  id: number;
};

export const deleteAvatarRequest = ({ id }: IDeleteAvatarRequest) => axios.delete(`/avatars/${id}`);

export const useMutateAvatarDelete = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, IDeleteAvatarRequest>(deleteAvatarRequest, {
    onError: () => {
      notify('error', 'Повторите попытку позже');
    },
    onSuccess: () => {
      notify('success', 'Аватар успешно удален');

      queryClient.invalidateQueries(avatarCommonKeys.avatars);
    },
  });
};
