import { FC } from 'react';
import { Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { useMutateUserExport } from '@users/api/useMutateExportUser';
import { UsersTabs } from '@users/components/molecules/UsersTabs';

import { IUsersRequest, useQueryUsers } from '../../api/useQueryUsers';
import { usersColumns } from '../../consts/columns/usersColumns';
import { usersFilters } from '../../consts/filters/usersFilters';

export const UsersPage: FC = () => {
  const { filtered, onFilter } = useUrlFilteredActioned<IUsersRequest>(usersFilters);
  const exportUsers = useMutateUserExport();
  const users = useQueryUsers(filtered);

  return (
    <>
      <PageMeta selectedMenuKeys={['users']} title="Пользователи" />
      <UsersTabs active="users" />
      <div className="flex justify-end">
        <Button
          className="sm:px-12"
          icon={<UploadOutlined />}
          loading={exportUsers.isLoading}
          onClick={exportUsers.mutateAsync}
          size="large"
          type="primary"
        >
          Выгрузить в excel
        </Button>
      </div>
      <AdminTable
        columns={usersColumns}
        emptyDescription={<p>Пользователи не найдены</p>}
        query={users}
        tableFilter={{ filtered, filters: usersFilters, onFilter }}
      />
    </>
  );
};
