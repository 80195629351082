import { VideosSelectMultipleFilter } from '@components/molecules/filters/dynamic/VideosSelectMultipleFilter';
import { CheckboxFilter } from '@components/molecules/filters/static/CheckboxFilter';
import { InputFilter } from '@components/molecules/filters/static/InputFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { FenStatusEnum } from '@exercises/fens/types/enums/FenStatusEnum';
import { fenKeys } from '@exercises/fens/types/IFen';
import { IFilters } from '@typess/IFilters';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';

export const fenFilters: IFilters = {
  ...paginationBaseFilters,
  [fenKeys.id]: {
    input: <InputFilter placeholder="Введите ID" />,
    sort: true,
  },
  [fenKeys.value]: {
    input: <InputFilter placeholder="Введите FEN" />,
  },
  [fenKeys.name]: {
    input: <InputFilter placeholder="Введите название позиции" />,
  },
  [fenKeys.localizations]: {
    input: <InputFilter placeholder="Введите сообщение Каиссы" />,
    queries: [{ key: 'messageFromKaissa' }],
  },
  [fenKeys.playLevel]: {
    input: (
      <CheckboxFilter
        options={Object.entries(userPlayLevels).map(([value, label]) => ({ label, value }))}
      />
    ),
    queries: [{ key: 'playLevels' }],
    sort: true,
  },
  [fenKeys.status]: {
    input: (
      <CheckboxFilter
        options={Object.entries(FenStatusEnum).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },
  [fenKeys.videos]: {
    input: <VideosSelectMultipleFilter isCartoon={false} />,
    queries: [{ key: 'videoIds' }],
  },
};
