import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema, string } from 'yup';

import { appProductsQueryKeys } from '@shop/appProducts/api/appProductsQueryKeys';
import { AppProductStatusEnumKeys } from '@shop/appProducts/types/enums/AppProductStatusEnum';
import { IAppProduct } from '@shop/appProducts/types/IAppProduct';

export type IAppProductCreateRequest = Pick<
  IAppProduct,
  'appStoreId' | 'price' | 'status' | 'coins'
>;

type IAppProductCreateResponse = AxiosResponse<void>;

export const appProductCreateInitial: IAppProductCreateRequest = {
  appStoreId: '',
  coins: 1,
  price: 1,
  status: 'ACTIVE',
};

export const appProductCreateValidation: ObjectSchema<IAppProductCreateRequest> = object({
  appStoreId: string()
    .required('AppStore ID обязателен для заполнения')
    .min(3, 'Минимальная длина ID 3 символа'),
  coins: number()
    .nullable()
    .required('Коины обязательны для заполнения')
    .min(1, 'Минимальное количество коинов - 1')
    .max(999999, 'Минимальное количество коинов - 999 999'),
  price: number().nullable().required('Цена обязательна для заполнения'),
  status: string<AppProductStatusEnumKeys>().required('Статус обязателен для заполнения'),
});

export const appProductCreateRequest = async (
  payload: IAppProductCreateRequest,
): Promise<IAppProductCreateResponse> => {
  return await axios.post('inappproduct', payload);
};

export function useMutateAppProductCreate() {
  const queryClient = useQueryClient();

  return useMutation<IAppProductCreateResponse, AxiosError, IAppProductCreateRequest>(
    appProductCreateRequest,
    {
      onSuccess: () => queryClient.invalidateQueries(appProductsQueryKeys.appProducts),
    },
  );
}
