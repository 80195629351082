import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs } from 'antd';

export const UserDetailPageTabs: FC<{
  active: 'user' | 'dashboard';
}> = ({ active }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div className="relative w-max m-auto">
      <Tabs
        activeKey={active}
        centered
        className="w-max"
        items={[
          { key: 'user', label: 'Детали пользователя' },
          { key: 'dashboard', label: 'Аналитика по пользователю' },
        ]}
        onChange={(tab) =>
          navigate(
            tab == 'user'
              ? `/users/${id}`
              : tab == 'dashboard'
              ? `/users/${id}/dashboard`
              : `/users/${id}`,
          )
        }
      />
    </div>
  );
};
