import { ReactSVG } from 'react-svg';
import { Image, Typography } from 'antd';

import { ChessPiecesTranslationsEnum } from '@collections/chess-pieces/types/enums/ChessPiecesEnum';
import { IChessPiece } from '@collections/chess-pieces/types/IChessPiece';

export const ChessPieceImageBox = ({ piece }: { piece: IChessPiece; size: 'sm' | 'md' }) => {
  return (
    <div>
      <Typography className="font-bold mb-4">{ChessPiecesTranslationsEnum[piece.type]}</Typography>
      <div className="flex flex-row px-5 py-2">
        <div className="border border-slate-200 rounded p-2 mr-4 flex flex-col items-center">
          <ReactSVG className="[&_svg]:w-16 [&_svg]:h-16 w-16 h-16" src={piece.imgUrl} />
          <Typography>SVG</Typography>
        </div>
        <div className="border border-slate-200 rounded p-2 flex flex-col items-center">
          <Image className="w-16 h-16" src={piece.imgUrlPng} typeof="svg" />
          <Typography>PNG</Typography>
        </div>
      </div>
    </div>
  );
};
