import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { CalendarOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';

import { CountryImageText } from '@components/atoms/CountryImageText';
import { VersionsPlatformEnum } from '@settings/versions/types/enums/VersionsPlatformEnum';
import { IVersion, versionKeys } from '@settings/versions/types/IVersion';

export const versionsColumns = ({
  onClickActivate,
  onClickDelete,
}: {
  onClickActivate: ({ id }: { id: number }) => void;
  onClickDelete: ({ id }: { id: number }) => void;
}): ColumnsType<IVersion> => {
  const columns: ColumnsType<IVersion> = [
    {
      dataIndex: versionKeys.platform,
      render: (platform: IVersion['platform']) => (
        <p className="mb-0">{VersionsPlatformEnum[platform]}</p>
      ),
      title: 'Тип устройства',
    },
    {
      dataIndex: versionKeys.version,
      title: 'Версия',
    },
    {
      dataIndex: versionKeys.createdDate,
      render: (createdDate: IVersion['createdDate']) =>
        createdDate ? (
          <div>
            <p className="mb-0">
              <CalendarOutlined /> {moment(createdDate).format('LL')}
            </p>
          </div>
        ) : (
          <span>-</span>
        ),
      title: 'Дата создания',
    },
    {
      dataIndex: versionKeys.isActive,
      render: (isActive: IVersion['isActive']) => (isActive ? <CheckOutlined /> : <span>-</span>),
      title: 'Активный',
    },
    {
      dataIndex: versionKeys.isForced,
      render: (isForced: IVersion['isForced']) =>
        isForced ? <p className="mb-0">Обязательное</p> : <p className="mb-0">Опциональное</p>,
      title: 'Тип обновления',
    },
    {
      dataIndex: versionKeys.localizations,
      render: (localizations: IVersion['localizations']) => (
        <div>
          <CountryImageText src="en" text={localizations?.en?.message || '-'} />
          <CountryImageText src="ru" text={localizations?.ru?.message || '-'} />
        </div>
      ),
      title: 'Сообщения',
    },
    {
      dataIndex: versionKeys.localizations,
      render: (localizations: IVersion['localizations']) => (
        <div>
          <CountryImageText src="en" text={localizations?.en?.title || '-'} />
          <CountryImageText src="ru" text={localizations?.ru?.title || '-'} />
        </div>
      ),
      title: 'Заголовок',
    },
  ];

  return [
    ...columns,
    {
      key: 'action',
      render: ({ id, isActive, version }: IVersion) =>
        !isActive && (
          <div className="flex gap-1.5">
            <Popconfirm
              cancelText="Отменить"
              icon={<CheckOutlined />}
              okText="Подтвердить"
              onConfirm={() => onClickActivate({ id })}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что вы хотите активировать данную версию?
                </span>
              }
            >
              <Button ghost icon={<CheckOutlined />} type="primary" />
            </Popconfirm>
            <Popconfirm
              cancelText="Отменить"
              icon={<DeleteOutlined className="text-ant-red-5" color="red" />}
              okText="Удалить"
              okType="danger"
              onConfirm={() => onClickDelete({ id })}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что хотите удалить <span className="font-bold">{version}</span>?
                </span>
              }
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        ),
      title: 'Действия',
    },
  ];
};
