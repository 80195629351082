import { RadioFilter } from '@components/molecules/filters/static/RadioFilter';
import { IFilters } from '@typess/IFilters';
import { UserGameMatchOutcomeEnum } from '@users/types/enums/UserGameMatchOutcomeEnum';
import { userGameMatchesKeys } from '@users/types/IUserGameMatch';

export const userGameMatchesFilters: IFilters = {
  [userGameMatchesKeys.id]: { sort: true },
  [userGameMatchesKeys.outcome]: {
    input: (
      <RadioFilter
        options={Object.entries(UserGameMatchOutcomeEnum).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
  },
  page: {},
  pageSize: {},
  sortBy: {},
  sortDirection: {},
};
