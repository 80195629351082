import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, number, object, ObjectSchema, string } from 'yup';

import { BoardStatusEnumKeys } from '@collections/boards/types/enums/boardStatusEnum';
import { notify } from '@utils/notify';

import { IBoardGroup } from '../types/IBoardGroup';
import { boardGroupQueryKeys } from './boardGroupQueryKeys';

export type IBoardGroupCreateRequest = Pick<IBoardGroup, 'localizations' | 'status'> & {
  items?: { chessBoardId: number; priority: number }[];
};

export const createBoardGroupInitialValue: IBoardGroupCreateRequest = {
  items: [],
  localizations: { en: { name: '' }, ru: { name: '' } },
  status: 'DRAFT',
};

export const createBoardGroupValidation: ObjectSchema<IBoardGroupCreateRequest> = object({
  items: array()
    .of(object({ chessBoardId: number().required(), priority: number().required() }))
    .required(),
  localizations: object({
    en: object({ name: string().required() }),
    ru: object({ name: string().required() }),
  }),
  status: string<BoardStatusEnumKeys>().nullable().required(),
});

export const createBoardGroupRequest = (payload: IBoardGroupCreateRequest) =>
  axios.post(`/chess-board-groups`, payload);

export const useMutateBoardGroupCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, IBoardGroupCreateRequest>(
    createBoardGroupRequest,
    {
      onError: () => {
        notify('error', 'Повторите попытку позже');
      },
      onSuccess: () => {
        notify('success', 'Группа успешно создана');

        queryClient.invalidateQueries(boardGroupQueryKeys.boardsGroups);
      },
    },
  );
};
