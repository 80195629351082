import { FC, useState } from 'react';
import { Button, Modal, Steps, Tooltip } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form, FormItem, InputNumber, Switch } from 'formik-antd';
import { FileOutlined } from '@ant-design/icons';

import { notify } from '@utils/notify';
import {
  IVideoStepOneCreateRequest,
  useMutateVideoStepOneCreate,
  videoStepOneCreateInitial,
  videoStepOneCreateValidation,
} from '@videos/api/useMutateVideoStepOneCreate';
import { VideoLocaleFormCard } from '@videos/components/molecules/VideoLocaleFormCard';

export const VideoCreateModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (id: number) => void;
}> = ({ isOpen, onCancel, onSubmit }) => {
  const [isCartoon, setIsCartoon] = useState(false);
  const [fileKeys, setFileKeys] = useState({
    previewEn: 'previewEn' + Math.random(),
    previewRu: 'previewRu' + Math.random(),
    subEn: 'subEn' + Math.random(),
    subRu: 'subRu' + Math.random(),
  });
  const [descriptionsNum, setDescriptionsNum] = useState(1);

  const videoOneCreate = useMutateVideoStepOneCreate();

  const submitDraft = ({
    resetForm,
    setErrors,
    setTouched,
    values,
  }: Pick<
    FormikProps<IVideoStepOneCreateRequest>,
    'resetForm' | 'setErrors' | 'setTouched' | 'values'
  >) => {
    const titleError = values.localizations.en.title ? '' : 'Обязательно для заполнения';
    const videoRuError = values.localizations.en.uuid ? '' : 'Обязательно для заполнения';
    const videoEnError = values.localizations.ru.uuid ? '' : 'Обязательно для заполнения';

    if (titleError || videoRuError || videoEnError) {
      setErrors({
        localizations: {
          en: { title: titleError, uuid: videoEnError },
          ru: { uuid: videoRuError },
        },
      });
      setTouched({
        localizations: {
          en: { title: true, uuid: true },
          ru: { uuid: true },
        },
      });
    } else {
      videoOneCreate.mutateAsync(values).then((data) => {
        if (data.status == 201 || data.status == 200) {
          notify('success', 'Видео сохранено как черновик');
          onCancel();
          setFileKeys({
            previewEn: fileKeys.previewEn + 1,
            previewRu: fileKeys.previewRu + 1,
            subEn: fileKeys.subEn + 1,
            subRu: fileKeys.subRu + 1,
          });
          resetForm();
        }
      });
    }
  };
  return (
    <Modal
      className="px-4 md:px-0 w-full md:w-4/5 lg:w-3/4 top-8"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <Steps
          className="site-navigation-steps"
          current={undefined}
          items={[
            {
              disabled: true,
              status: 'process',
              title: 'Шаг 1',
            },
            ...(isCartoon
              ? []
              : [
                  { disabled: true, title: 'Шаг 2' },
                  { disabled: true, title: 'Шаг 3' },
                ]),
          ]}
          onChange={undefined}
          type="navigation"
        />
      }
      wrapClassName="video-modal"
    >
      <Formik<IVideoStepOneCreateRequest>
        enableReinitialize
        initialValues={videoStepOneCreateInitial}
        onSubmit={(values, { resetForm }) => {
          videoOneCreate
            .mutateAsync(values.isCartoon ? { ...values, status: 'ACTIVE' } : values)
            .then((data) => {
              if (data.status == 201 || data.status == 200) {
                if (values.isCartoon) {
                  notify('success', 'Мультфильм сохранен');
                  onCancel();
                } else {
                  notify('success', 'Шаг 1 сохранен');
                  onSubmit(data.data.id);
                }
                setFileKeys({
                  previewEn: fileKeys.previewEn + 1,
                  previewRu: fileKeys.previewRu + 1,
                  subEn: fileKeys.subEn + 1,
                  subRu: fileKeys.subRu + 1,
                });
                resetForm();
              }
            });
        }}
        validationSchema={videoStepOneCreateValidation}
      >
        {({ resetForm, setErrors, setFieldValue, setTouched, values }) => (
          <Form className="space-y-4 md:space-y-6" layout="vertical">
            <h1 className="text-2xl">
              Создание
              {values.isCartoon ? (
                <span className="text-ant-magenta-6"> мультфильма</span>
              ) : (
                <span className="text-ant-purple-6"> видеоурока</span>
              )}
            </h1>

            <div className="grid sm:grid-cols-2 gap-6">
              <VideoLocaleFormCard
                descriptionsNum={descriptionsNum}
                isCartoon={values.isCartoon}
                localization="en"
                previewKey={fileKeys.previewEn}
                setDescriptionsNum={setDescriptionsNum}
                setFieldValue={setFieldValue}
                subKey={fileKeys.subEn}
                test={values.localizations.en}
              />
              <VideoLocaleFormCard
                descriptionsNum={descriptionsNum}
                isCartoon={values.isCartoon}
                localization="ru"
                previewKey={fileKeys.previewRu}
                setDescriptionsNum={setDescriptionsNum}
                setFieldValue={setFieldValue}
                subKey={fileKeys.subRu}
                test={values.localizations.ru}
              />
            </div>

            <div className="flex flex-col sm:flex-row items-center justify-evenly text-center align-middle">
              <FormItem label="Мультфильм" name="isCartoon">
                <Switch name="isCartoon" onChange={(isCartoon) => setIsCartoon(isCartoon)} />
              </FormItem>

              {values.isCartoon && (
                <FormItem name="price">
                  <p className="mb-2">Цена</p>
                  <InputNumber controls={false} maxLength={6} min={0} name="price" prefix />
                </FormItem>
              )}
            </div>

            <div className="grid sm:grid-cols-2 gap-4 md:gap-6">
              <Button block onClick={onCancel}>
                Отменить
              </Button>
              <div className="flex flex-row gap-3">
                <Button block htmlType="submit" loading={videoOneCreate.isLoading} type="primary">
                  <span className="max-w-full overflow-ellipsis overflow-hidden">
                    {values.isCartoon ? 'Создать и запустить' : 'Следующий шаг'}
                  </span>
                </Button>
                <Tooltip title="Сохранить как черновик">
                  <Button
                    className="px-2 border-dashed border-ant-gray-6 hover:border-ant-blue-5 shadow-none"
                    ghost
                    icon={<FileOutlined />}
                    onClick={() => submitDraft({ resetForm, setErrors, setTouched, values })}
                    type="primary"
                  />
                </Tooltip>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
