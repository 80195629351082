import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, number, object, ObjectSchema, string } from 'yup';

import { classifierQueryKeys } from '@settings/classifierSkillTypes/api/classifierQueryKeys';
import { IClassifierSkillType } from '@settings/classifierSkillTypes/types/IClassifierSkillType';

export type IClassifierUpdateRequest = IClassifierSkillType;

type IClassifierUpdateResponse = AxiosResponse<IClassifierSkillType>;

export const classifierUpdateValidation: ObjectSchema<IClassifierUpdateRequest> = object({
  name: string().nullable().required(),
  practiceEvaluationListPrimary: array()
    .nullable()
    .of(
      object()
        .shape({
          from: number().nullable().required(),
          to: number().nullable().required(),
          value: number().nullable().required(),
        })
        .required(),
    ),
  practiceEvaluationListSecondary: array()
    .nullable()
    .of(
      object()
        .shape({
          from: number().nullable().required(),
          to: number().nullable().required(),
          value: number().nullable().required(),
        })
        .required(),
    ),
  practicePartInPercent: number()
    .nullable()
    .required()
    .min(1, 'Минимальное значение - 1')
    .max(99, 'Минимальное значение - 99')
    .test({
      exclusive: false,
      message: 'Теория и практика в общей сумме должны достигать 100%',
      name: 'practicePartInPercent',
      params: {},
      test: function () {
        return (
          (this.parent.practicePartInPercent || 1) + (this.parent.theoryPartInPercent || 1) == 100
        );
      },
    }),
  tacticEvalDifference: number().nullable(),
  theoryPartInPercent: number()
    .nullable()
    .required()
    .min(1, 'Минимальное значение - 1')
    .max(99, 'Максимальное значение - 99')
    .test({
      exclusive: false,
      message: 'Теория и практика в общей сумме должны достигать 100%',
      name: 'practicePartInPercent',
      params: {},
      test: function () {
        return (
          (this.parent.practicePartInPercent || 1) + (this.parent.theoryPartInPercent || 1) == 100
        );
      },
    }),
});

export const classifierUpdateRequest = async (
  payload: IClassifierUpdateRequest,
): Promise<IClassifierUpdateResponse> => await axios.patch('skill-types', payload);

export function useMutateClassifierUpdate() {
  const queryClient = useQueryClient();

  return useMutation<IClassifierUpdateResponse, AxiosError, IClassifierUpdateRequest>(
    classifierUpdateRequest,
    {
      onSuccess: () => queryClient.invalidateQueries(classifierQueryKeys.classifierSkillTypes),
    },
  );
}
