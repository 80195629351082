import { InputFilter } from '@components/molecules/filters/static/InputFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { IFilters } from '@typess/IFilters';

export const preSubscribersFilters: IFilters = {
  ...paginationBaseFilters,
  createdDate: { sort: true },
  email: { input: <InputFilter placeholder="Введите почту" /> },
  sortBy: { queries: [{ default: 'createdDate' }] },
};
