import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import { useMutateChessPiecesSetDelete } from '@collections/chess-pieces/api/useMutateChessPiecesSetDelete';
import {
  IChessPiecesSetRequest,
  useQueryChessPiecesSet,
} from '@collections/chess-pieces/api/useQueryChessPiecesSet';
import { chessPiecesColumns } from '@collections/chess-pieces/consts/columns/chessPiecesColumns';
import { chessPiecesFilters } from '@collections/chess-pieces/consts/filters/chessPiecesFilters';
import { CollectionTabs } from '@collections/components/molecules/CollectionTabs';
import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';

import { useMutateChessPiecesSetStatusUpdate } from '../../api/useMutateChessPiecesSetStatusUpdate';

export const FiguresSetsPage: FC = () => {
  const { filtered, onFilter } = useUrlFilteredActioned<IChessPiecesSetRequest>(chessPiecesFilters);

  const chessPiecesSet = useQueryChessPiecesSet(filtered);
  const chessPiecesSetDelete = useMutateChessPiecesSetDelete();
  const chessPiecesSetStatusUpdate = useMutateChessPiecesSetStatusUpdate();

  const navigate = useNavigate();

  return (
    <>
      <PageMeta selectedMenuKeys={['collections']} title="Коллекция" />
      <CollectionTabs active="figures" />
      <div className="flex justify-end gap-4 mb-4">
        <Button
          className="sm:px-12"
          onClick={() => navigate('/collections/figures/create')}
          size="large"
          type="primary"
        >
          Добавить коллекцию фигур
        </Button>
      </div>

      <AdminTable
        columns={chessPiecesColumns({
          onClickDelete: chessPiecesSetDelete.mutateAsync,
          onClickEdit: (id: number) => navigate(`/collections/figures/edit/${id}`),
          onClickPause: chessPiecesSetStatusUpdate.mutateAsync,
        })}
        emptyDescription={<p>Коллекции фигур не найдены</p>}
        query={chessPiecesSet}
        tableFilter={{ filtered, filters: chessPiecesFilters, onFilter }}
      />
    </>
  );
};
