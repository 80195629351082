import { Button, Popconfirm, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { CalendarOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';

import { IPromocode, promocodeKeys } from '@settings/promocodes/types/IPromocode';

export const promocodesColumns = ({
  canEdit,
  onClickDelete,
}: {
  canEdit: boolean;
  onClickDelete: (id: number) => void;
}): ColumnsType<IPromocode> => {
  const columns: ColumnsType<IPromocode> = [
    {
      dataIndex: promocodeKeys.id,
      title: 'ID',
    },
    {
      dataIndex: promocodeKeys.code,
      render: (code: IPromocode['code'], promocode) =>
        promocode.status == 'DELETED' ? (
          <Tooltip title="Промокод удален">
            <span className="text-ant-red-7">{code}</span>
          </Tooltip>
        ) : (
          <span>{code}</span>
        ),
      title: 'Код',
    },
    {
      dataIndex: promocodeKeys.usedAt,
      render: (usedAt: IPromocode['usedAt'], promocode) =>
        usedAt ? (
          <div>
            <p className="text-ant-gray-6 text-xs m-0">Использован</p>
            <p className="mb-0 py-2">
              <CalendarOutlined /> {moment(usedAt).format('LLL')}
            </p>
            <p className="text-ant-gray-6 text-xs m-0 mt-2">Пользователь</p>
            <p className="mb-0 py-2">
              <UserOutlined /> #{promocode.customer?.id} ({promocode.customer?.nickname})
            </p>
          </div>
        ) : (
          <span>-</span>
        ),
      title: 'Использован',
    },
  ];
  if (!canEdit) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: (promocode: IPromocode) => (
        <div className="flex gap-x-1.5">
          {promocode.status != 'DELETED' && (
            <Popconfirm
              cancelText="Отменить"
              icon={<DeleteOutlined className="text-ant-red-5" />}
              okText="Удалить"
              okType="danger"
              onConfirm={() => onClickDelete(promocode.id)}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что хотите
                  <span className="font-bold"> удалить промокод &quot;{promocode.code}&quot;</span>?
                </span>
              }
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </div>
      ),
      title: 'Действия',
    },
  ];
};
