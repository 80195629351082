import { IBoard } from '@collections/boards/types/IBoard';
import { ILocalization } from '@typess/ILocalization';

export type IBoardGroup = {
  id: number;
  items: { chessBoard: IBoard; priority: number }[];
  itemsCount?: number;
  localizations: Partial<Record<ILocalization, { name: string }>>;
  priority: null | number;
  status: 'DRAFT' | 'ACTIVE';
};

export const boardGroupKeys: Record<keyof IBoardGroup, string> = {
  id: 'id',
  items: 'items',
  itemsCount: 'itemsCount',
  localizations: 'localizations',
  priority: 'priority',
  status: 'status',
};
