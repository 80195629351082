import { avatarGroupKeys } from '@collections/avatars-groups/types/IAvatarGroup';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { IFilters } from '@typess/IFilters';

export const avatarGroupsFilters: IFilters = {
  ...paginationBaseFilters,
  [avatarGroupKeys.id]: {
    sort: true,
  },
  [avatarGroupKeys.createdDate]: {
    sort: true,
  },
};
