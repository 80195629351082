import { IPageActions } from '@typess/IPageActions';

enum MatchesPvpActionsEnum {
  'view',
}

export type IMatchesPvpActions = {
  action: keyof typeof MatchesPvpActionsEnum;
  actionId?: string;
};

export const matchesPvpPageParams: IPageActions<IMatchesPvpActions> = {
  action: {
    allowed: (value) => Object.keys(MatchesPvpActionsEnum).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
