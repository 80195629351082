import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { object, ObjectSchema, string } from 'yup';

import { tagQueryKeys } from '@tags/api/tagQueryKeys';
import { ITag } from '@tags/types/ITag';

import { ITagLocalizations } from '../types/ITagLocalizations';
import { TagTypeEnumKeys } from '../types/TagTypeEnum';

export type ITagTopicCreateRequest = {
  localizations: ITagLocalizations;
  type: TagTypeEnumKeys;
};

type ITagTopicCreateResponse = AxiosResponse<ITag>;

export const tagTopicCreateInitial: ITagTopicCreateRequest = {
  localizations: { en: '', ru: '' },
  type: 'TOPIC',
};

export const tagTopicCreateValidation: ObjectSchema<ITagTopicCreateRequest> = object({
  localizations: object({
    en: string()
      .required('Название на английском обязательно для заполнения')
      .max(60, 'Тег должен быть не более 60 символов')
      .matches(
        /^[A-Za-z\d ]*$/,
        'Название на английском должно содержать только цифры и английские символы',
      )
      .trim('Название не должно содержать пробелы в начале или в конце')
      .strict(),
    ru: string()
      .required('Название на русском обязательно для заполнения')
      .max(60, 'Тег должен быть не более 60 символов')
      .matches(
        /^[А-Яа-я\dёЁ ]*$/,
        'Название на русском должно содержать только цифры и русские символы',
      )
      .trim('Название не должно содержать пробелы в начале или в конце')
      .strict(),
  }),
  type: string<TagTypeEnumKeys>().required(),
});

export const tagTopicCreateRequest = async (
  payload: ITagTopicCreateRequest,
): Promise<ITagTopicCreateResponse> => await axios.post(`/tags`, payload);

export function useMutateTagTopicCreate() {
  const queryClient = useQueryClient();

  return useMutation<ITagTopicCreateResponse, AxiosError, ITagTopicCreateRequest>(
    tagTopicCreateRequest,
    { onSuccess: () => queryClient.invalidateQueries(tagQueryKeys.tags) },
  );
}
