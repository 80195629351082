import { Result, Skeleton } from 'antd';

import { useQueryMe } from '@auth/api/useQueryMe';
import { IPermission } from '@typess/permissions/IPermission';
import { checkPermission } from '@utils/checkPermission';

const ProtectedRoute = ({
  children,
  permissions,
}: {
  children: JSX.Element;
  permissions: IPermission[];
}) => {
  const { data, isFetching, isSuccess } = useQueryMe();

  if (isSuccess) {
    if (!checkPermission(data.authorities, permissions)) {
      return (
        <Result status="403" subTitle="У вас нет прав для просмотра этой страницы" title="403" />
      );
    }

    return children;
  }

  if (isFetching) {
    return (
      <div className="bg-white p-10 md:p-15 mt-5 rounded-2xl">
        <Skeleton active round />
        <Skeleton active round />
        <Skeleton active round />
      </div>
    );
  }

  return <div />;
};

export default ProtectedRoute;
