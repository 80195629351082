import { FC, useState } from 'react';
import { DatePicker, Table, Typography } from 'antd';
import { DatePickerProps } from 'antd/lib';
import dayjs, { Dayjs } from 'dayjs';

import { PageMeta } from '@context/PageMetaContext';
import { useQueryByPlayLevelStats } from '@dashboards/api/useQueryByPlayLevelStats';
import { useQueryUsersMapStagePassedStats } from '@dashboards/api/useQueryMapStagePassedStats';
import { usersMapStagePassedStatsColumns } from '@dashboards/consts/columns/usersMapStagePassedStatsColumn';
import { usersPlayLevelStatsColumns } from '@dashboards/consts/columns/usersPlayLevelStatsColumn';

import { DashboardTabs } from '../molecules/DashboardTabs';

const ByPlayLevelDashboardPage: FC = () => {
  const dateFormat = 'YYYY-MM-DD';

  const [startDate, setStartDate] = useState<Dayjs>(dayjs('2022/09/26', dateFormat));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const { data, isLoading } = useQueryByPlayLevelStats();
  const { data: mapStagePassedData, isLoading: isMapStagePassedDataLoading } =
    useQueryUsersMapStagePassedStats({
      from: startDate?.format('YYYY-MM-DD HH:mm:ss'),
      to: endDate?.format('YYYY-MM-DD HH:mm:ss'),
    });

  const transformedData =
    data?.data &&
    Object.keys(data.data).map((key) => {
      const obj = data?.data[key];
      return {
        ...obj,
        key: key,
        playLevel: key,
      };
    });

  const totalUsers = data?.data.total.totalNumber;

  const disabledStartDate: DatePickerProps['disabledDate'] = (current) => {
    return (
      current &&
      (current <= dayjs('2022/09/25').endOf('day') || current > dayjs(endDate).endOf('day'))
    );
  };

  const disabledEndDate: DatePickerProps['disabledDate'] = (current) => {
    return current && (current <= dayjs(startDate).endOf('day') || current > dayjs().endOf('day'));
  };

  return (
    <>
      <PageMeta selectedMenuKeys={['dashboards']} title="По уровню" />
      <DashboardTabs active="by-play-level" />
      <div className="flex flex-row items-center mb-6">
        <DatePicker
          defaultValue={startDate}
          disabledDate={disabledStartDate}
          onChange={(v) => v && setStartDate(v)}
          placeholder="from"
        />
        <Typography className="text-gray-700 mx-2">—</Typography>
        <DatePicker
          defaultValue={endDate}
          disabledDate={disabledEndDate}
          onChange={(v) => v && setEndDate(v)}
          placeholder="to"
        />
      </div>
      <div className="flex flex-row">
        <Table
          className="w-[30%] mr-8"
          columns={usersPlayLevelStatsColumns({ totalUsers })}
          dataSource={transformedData}
          loading={isLoading}
          pagination={false}
        />
        <Table
          className="w-[30%] mr-8"
          columns={usersMapStagePassedStatsColumns(totalUsers)}
          dataSource={mapStagePassedData?.data ?? []}
          loading={isMapStagePassedDataLoading}
          pagination={false}
          scroll={{ y: 'calc(100vh - 300px)' }}
        />
      </div>
    </>
  );
};

export default ByPlayLevelDashboardPage;
