import { IPermissions } from '@typess/permissions/IPermissions';

export type IExercisePermission =
  | 'PERM_EXERCISE_EDIT'
  | 'PERM_EXERCISE_RESTRICTED'
  | 'PERM_EXERCISE_VIEW';

export const exercisePermissions: IPermissions = {
  edit: 'PERM_EXERCISE_EDIT',
  restricted: 'PERM_EXERCISE_RESTRICTED',
  view: 'PERM_EXERCISE_VIEW',
};
