import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { appProductsQueryKeys } from '@shop/appProducts/api/appProductsQueryKeys';
import {
  AppProductStatusEnum,
  AppProductStatusEnumKeys,
} from '@shop/appProducts/types/enums/AppProductStatusEnum';
import { IAppProduct } from '@shop/appProducts/types/IAppProduct';
import { notify } from '@utils/notify';

export type IAppProductStatusRequest = { id: number; status: AppProductStatusEnumKeys };

type IAppProductStatusResponse = AxiosResponse<IAppProduct>;

export const appProductStatusRequest = async ({
  id,
  ...payload
}: IAppProductStatusRequest): Promise<IAppProductStatusResponse> => {
  return await axios.put(`inappproduct/${id}/status`, payload);
};

export function useMutateAppProductStatus() {
  const queryClient = useQueryClient();

  return useMutation<IAppProductStatusResponse, AxiosError, IAppProductStatusRequest>(
    appProductStatusRequest,
    {
      onSuccess: async (data, variables) => {
        if (data.data.status == variables.status) {
          notify('success', `Статус изменен на "${AppProductStatusEnum[variables.status]}"`);
        } else {
          notify('error', 'Статус не изменен');
        }
        await queryClient.invalidateQueries(appProductsQueryKeys.appProducts);
      },
    },
  );
}
