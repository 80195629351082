import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, number, object, ObjectSchema, string } from 'yup';

import { ChessPiecesSetStatusEnumKeys } from '@collections/chess-pieces/types/enums/ChessPiecesSetEnum';
import { notify } from '@utils/notify';

import { IChessPiecesGroup } from '../types/IChessPiecesGroup';
import { chessPiecesGroupQueryKeys } from './chessPiecesGroupQueryKeys';

export type IChessPiecesGroupCreateRequest = Pick<IChessPiecesGroup, 'localizations' | 'status'> & {
  items?: { chessPieceSetId: number; priority: number }[];
};

export const createChessPiecesGroupInitialValue: IChessPiecesGroupCreateRequest = {
  items: [],
  localizations: { en: { name: '' }, ru: { name: '' } },
  status: 'DRAFT',
};

export const createChessPiecesGroupValidation: ObjectSchema<IChessPiecesGroupCreateRequest> =
  object({
    items: array()
      .of(object({ chessPieceSetId: number().required(), priority: number().required() }))
      .required(),
    localizations: object({
      en: object({ name: string().required() }),
      ru: object({ name: string().required() }),
    }),
    status: string<ChessPiecesSetStatusEnumKeys>().nullable().required(),
  });

export const createChessPiecesGroupRequest = (payload: IChessPiecesGroupCreateRequest) =>
  axios.post(`/chess-piece-set-groups`, payload);

export const useMutateChessPiecesGroupCreate = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, IChessPiecesGroupCreateRequest>(
    createChessPiecesGroupRequest,
    {
      onError: () => {
        notify('error', 'Повторите попытку позже');
      },
      onSuccess: () => {
        notify('success', 'Группа успешно создана');

        queryClient.invalidateQueries([chessPiecesGroupQueryKeys.chessPiecesGroups]);
      },
    },
  );
};
