import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { ICountryStat } from '@dashboards/types/ICountryStat';

import { dashboardQueryKeys } from './dashboardQueryKeys';

export type IUsersCountryStatsRequest = {
  from?: string;
  to?: string;
};

type IUsersCountryStatsResponse = AxiosResponse<ICountryStat[]>;

export const usersCountryStatsRequest = async (
  params?: IUsersCountryStatsRequest,
): Promise<IUsersCountryStatsResponse> => {
  return await axios.get('/stats/countries', { params });
};

export function useQueryUsersCountryStats(params?: IUsersCountryStatsRequest) {
  return useQuery<IUsersCountryStatsResponse, AxiosError>(
    [dashboardQueryKeys.usersCountryStats, params],
    () => usersCountryStatsRequest(params),
  );
}
