export const usersPlayLevelStatsColumns = ({ totalUsers }: { totalUsers?: number }) => {
  return [
    {
      dataIndex: 'playLevel',
      title: 'Play level',
    },
    {
      dataIndex: 'totalNumber',
      title: 'Users count',
    },
    {
      render: (row) => {
        return totalUsers ? ((row.totalNumber / totalUsers) * 100).toFixed(2) + '%' : '-';
      },
      title: '%',
    },
  ];
};
