import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { avatarCommonKeys } from '@collections/avatars/api/avatarCommonKeys';
import { notify } from '@utils/notify';

import { avatarGroupQueryKeys } from './avatarGroupQueryKeys';

type IAvatarGroupItemDeleteRequest = { avatarId: number; id: number };

type IAvatarGroupItemDeleteResponse = AxiosResponse<void>;

export const avatarGroupItemDeleteRequest = async ({
  avatarId,
  id,
}: IAvatarGroupItemDeleteRequest): Promise<IAvatarGroupItemDeleteResponse> =>
  await axios.delete(`/avatar-groups/${id}/item/${avatarId}`);

export function useMutateAvatarGroupItemDelete() {
  const queryClient = useQueryClient();

  return useMutation<IAvatarGroupItemDeleteResponse, AxiosError, IAvatarGroupItemDeleteRequest>(
    avatarGroupItemDeleteRequest,
    {
      onSuccess: () => {
        notify('error', 'Аватар удален из группы');
        queryClient.invalidateQueries(avatarGroupQueryKeys.avatarGroup);
        queryClient.invalidateQueries(avatarCommonKeys.avatars);
      },
    },
  );
}
