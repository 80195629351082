import { FC, useState } from 'react';
import { Button, Divider, Modal } from 'antd';
import { cloneDeep } from 'lodash';
import { PlusOutlined } from '@ant-design/icons';

import { VideoFileUpload } from '@videos/components/molecules/VideoFileUpload';

export const VideoFileUploadModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
}> = ({ isOpen, onCancel }) => {
  const [uploadKeys, setUploadKeys] = useState<number[]>([Math.random()]);

  const onSuccess = (oldKey: number) => {
    let newKey = Math.random();
    while (uploadKeys.includes(newKey)) {
      newKey = Math.random();
    }
    setUploadKeys((prev) => prev.map((key) => (key == oldKey ? newKey : key)));
  };

  const onRemove = (oldKey: number) => {
    if (uploadKeys.length < 2) {
      let newKey = Math.random();
      while (uploadKeys.includes(newKey)) {
        newKey = Math.random();
      }
      setUploadKeys((prev) => prev.map((key) => (key == oldKey ? newKey : key)));
    } else {
      setUploadKeys((prev) => prev.filter((key) => key != oldKey));
    }
  };

  const addUpload = async () => {
    let newKey = Math.random();
    while (uploadKeys.includes(newKey)) {
      newKey = Math.random();
    }
    setUploadKeys((prev) => {
      const newPrev = cloneDeep(prev);
      newPrev.push(newKey);
      return newPrev;
    });
  };

  return (
    <Modal
      className="px-4 md:px-0 md:w-4/5 lg:w-3/4"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      style={{ top: 40 }}
      title="Загрузить видео"
    >
      <div className="space-y-8 video-modal">
        <Button icon={<PlusOutlined />} onClick={async () => await addUpload()}>
          Добавить
        </Button>
        {uploadKeys.map((key, index) => (
          <div className="upload-form-item" key={key}>
            <Divider orientation="left">Видео #{index + 1}</Divider>
            <VideoFileUpload
              disabled={uploadKeys.length < 2}
              onRemove={() => onRemove(key)}
              onSuccess={() => onSuccess(key)}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};
