export const usersCountryStatsColumns = [
  {
    dataIndex: 'countryName',
    title: 'Country',
  },
  {
    dataIndex: 'usersCount',
    title: 'Total',
  },
];
