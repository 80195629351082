import { FC } from 'react';
import { Modal } from 'antd';
import moment from 'moment';
import Plyr from 'plyr-react';

import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { TitleAndText } from '@components/atoms/TitleAndText';
import { useQueryVideoFile } from '@videos/api/useQueryVideoFile';

export const VideoFileViewModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
  uuid?: string;
}> = ({ isOpen, onCancel, uuid = '' }) => {
  const videoFileQuery = useQueryVideoFile({ uuid });
  const videoFile = videoFileQuery.data?.data;

  return (
    <Modal
      className="px-4 md:px-0 md:w-4/5 lg:w-3/4"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      style={{ top: 40 }}
    >
      {videoFileQuery.isFetching ? (
        <BaseSkeleton />
      ) : !videoFile ? (
        <NotFoundResult title="Видеофайл не найден" />
      ) : (
        <div className="gap-6">
          <div className="flex flex-col sm:flex-row gap-4 p-4">
            <h2 className="text-2xl">Видеофайл</h2>
          </div>

          <div className="divide-y">
            <TitleAndText text={videoFile.title} title="Название" />
          </div>

          <div className="divide-y">
            <TitleAndText text={videoFile.uuid} title="UUID" />
          </div>

          <div className="divide-y">
            <TitleAndText
              text={
                videoFile?.author ? (
                  <>
                    {videoFile.author?.authorRank && (
                      <span className="font-bold text-ant-red-7 mr-1">
                        {videoFile.author?.authorRank}
                      </span>
                    )}
                    {videoFile.author?.localizations.ru}
                  </>
                ) : (
                  '-'
                )
              }
              title="Автор"
            />
          </div>
          <div className="divide-y">
            <TitleAndText
              text={videoFile.createdDate ? `${moment(videoFile.createdDate).format('LLL')}` : '-'}
              title="Дата создания"
            />
          </div>

          <div className="flex justify-center px-2">
            <div className="w-full sm:px-12 md:px-0 md:w-4/5 lg:w-3/5 my-4 md:my-6 overflow-scroll rounded">
              <Plyr
                crossOrigin={'anonymous'}
                options={{
                  clickToPlay: true,
                  controls: [
                    'play-large',
                    'play',
                    'progress',
                    'mute',
                    'volume',
                    'settings',
                    'fullscreen',
                  ],
                }}
                preload="none"
                source={{
                  sources: [
                    {
                      provider: 'html5',
                      src: videoFile.mediaUrl || '',
                    },
                  ],
                  type: 'video',
                }}
                title={videoFile.title}
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
