export type IExerciseGroup = {
  createdDate: Date;
  exercisesCount?: number;
  groupName: string;
  id: number;
  updatedDate: Date;
};

export const exerciseGroupKeys: Record<keyof IExerciseGroup, string> = {
  createdDate: 'createdDate',
  exercisesCount: 'exercisesCount',
  groupName: 'groupName',
  id: 'id',
  updatedDate: 'updatedDate',
};
