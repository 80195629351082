import type { FC } from 'react';
import { Button, Modal, Switch as AntSwitch, Tag, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, InputNumber, Select } from 'formik-antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useQueryBoardGroups } from '@collections/board-groups/api/useQueryBoardGroups';
import {
  createBoardInitialValue,
  createBoardValidation,
  IBoardCreateRequest,
  useMutateBoardCreate,
} from '@collections/boards/api/useMutateBoardCreate';
import { BoardTypeEnum } from '@collections/boards/types/enums/boardTypeEnum';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { FileUpload } from '@components/molecules/files/FileUpload';

type Props = {
  initialValue?: IBoardCreateRequest;
  isOpen: boolean;
  onClose: () => void;
};

export const CreateBoardModal: FC<Props> = ({ initialValue, isOpen, onClose }) => {
  const createBoardMutation = useMutateBoardCreate();
  const boardGroups = useQueryBoardGroups();

  return (
    <Modal footer={null} onCancel={onClose} open={isOpen} title="Создать доску">
      <Formik<IBoardCreateRequest>
        enableReinitialize
        initialValues={initialValue ? initialValue : createBoardInitialValue}
        onSubmit={(values) =>
          createBoardMutation.mutateAsync(values).then(() => {
            onClose();
          })
        }
        validateOnBlur={false}
        validationSchema={createBoardValidation}
      >
        {({ setFieldValue, values: { status, type } }) => (
          <Form className="gap-4 md:gap-6" layout="vertical">
            <FormItem
              label={<CountryImageText src="ru" text="Название на русском" />}
              name="localizations.ru.name"
            >
              <Input name="localizations.ru.name" prefix />
            </FormItem>
            <FormItem
              label={<CountryImageText src="en" text="Название на английском" />}
              name="localizations.en.name"
            >
              <Input name="localizations.en.name" prefix />
            </FormItem>

            <FormItem label="Тип доски" name="type">
              <Select
                defaultValue="FREE"
                name="type"
                optionFilterProp="label"
                options={
                  Object.entries(BoardTypeEnum).map(([key, value]) => ({
                    label: value,
                    value: key,
                  })) || []
                }
                placeholder="Тип доски"
              />
            </FormItem>
            {type === 'PAID' && (
              <FormItem label="Стоимость" name="price">
                <InputNumber
                  className="w-full"
                  controls={false}
                  max={999999}
                  min={1}
                  name="price"
                  prefix
                  required
                />
              </FormItem>
            )}
            <Typography className="font-bold">Картинка доски</Typography>
            <div className="grid grid-cols-2 gap-6">
              <FormItem className="upload-form-item" label="SVG" name="imgUrl">
                <FileUpload
                  accept=".svg"
                  folderName="imgUrl"
                  maxCount={1}
                  onChange={(info, linkInfo) => {
                    if (!info.file.status || info.file.status == 'removed')
                      setFieldValue('imgUrl', '');
                    if (info.file.status == 'done') setFieldValue('imgUrl', linkInfo.downloadUrl);
                  }}
                />
              </FormItem>
              <FormItem className="upload-form-item" label="PNG" name="imgUrl">
                <FileUpload
                  accept=".png"
                  folderName="imgUrlPng"
                  maxCount={1}
                  onChange={(info, linkInfo) => {
                    if (!info.file.status || info.file.status == 'removed')
                      setFieldValue('imgUrlPng', '');
                    if (info.file.status == 'done')
                      setFieldValue('imgUrlPng', linkInfo.downloadUrl);
                  }}
                />
              </FormItem>
            </div>
            <Typography className="font-bold">Чёрные поля</Typography>
            <div className="grid grid-cols-2 gap-6">
              <FormItem className="upload-form-item" label="SVG" name="darkPieceImgUrl">
                <FileUpload
                  accept=".svg"
                  folderName="darkPieceImgUrl"
                  maxCount={1}
                  onChange={(info, linkInfo) => {
                    if (!info.file.status || info.file.status == 'removed')
                      setFieldValue('darkPieceImgUrl', '');
                    if (info.file.status == 'done')
                      setFieldValue('darkPieceImgUrl', linkInfo.downloadUrl);
                  }}
                />
              </FormItem>
              <FormItem className="upload-form-item" label="PNG" name="darkPieceImgUrl">
                <FileUpload
                  accept=".png"
                  folderName="darkPieceImgUrlPng"
                  maxCount={1}
                  onChange={(info, linkInfo) => {
                    if (!info.file.status || info.file.status == 'removed')
                      setFieldValue('darkPieceImgUrlPng', '');
                    if (info.file.status == 'done')
                      setFieldValue('darkPieceImgUrlPng', linkInfo.downloadUrl);
                  }}
                />
              </FormItem>
            </div>
            <Typography className="font-bold">Белые поля</Typography>
            <div className="grid grid-cols-2 gap-6">
              <FormItem className="upload-form-item" label="SVG" name="lightPieceImgUrl">
                <FileUpload
                  accept=".svg"
                  folderName="lightPieceImgUrl"
                  maxCount={1}
                  onChange={(info, linkInfo) => {
                    if (!info.file.status || info.file.status == 'removed')
                      setFieldValue('lightPieceImgUrl', '');
                    if (info.file.status == 'done')
                      setFieldValue('lightPieceImgUrl', linkInfo.downloadUrl);
                  }}
                />
              </FormItem>
              <FormItem className="upload-form-item" label="PNG" name="lightPieceImgUrl">
                <FileUpload
                  accept=".png"
                  folderName="lightPieceImgUrlPng"
                  maxCount={1}
                  onChange={(info, linkInfo) => {
                    if (!info.file.status || info.file.status == 'removed')
                      setFieldValue('lightPieceImgUrlPng', '');
                    if (info.file.status == 'done')
                      setFieldValue('lightPieceImgUrlPng', linkInfo.downloadUrl);
                  }}
                />
              </FormItem>
            </div>
            <FormItem label="Группа досок" name="groupIds">
              <Select
                loading={boardGroups.isFetching}
                mode="multiple"
                name="groupIds"
                optionFilterProp="label"
                options={
                  boardGroups.data?.data.content.map((tag) => ({
                    label: tag.localizations.ru?.name,
                    value: tag.id,
                  })) || []
                }
                placeholder="Группа досок"
                tagRender={
                  boardGroups.isFetching
                    ? () => (
                        <Tag>
                          <LoadingOutlined />
                        </Tag>
                      )
                    : undefined
                }
              />
            </FormItem>
            <div className="flex justify-center text-center">
              <FormItem label={<span className="text-center">Запустить</span>} name="status">
                <Input hidden name="status" suffix />

                <AntSwitch
                  checked={status == 'ACTIVE'}
                  onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
                />
              </FormItem>
            </div>

            <div className="flex gap-4 justify-end">
              <Button onClick={onClose}>Отмена</Button>
              <Button htmlType="submit" loading={createBoardMutation.isLoading} type="primary">
                <span className="max-w-full overflow-ellipsis overflow-hidden">
                  {status == 'ACTIVE' ? 'Создать и запустить' : 'Сохранить как черновик'}
                </span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
