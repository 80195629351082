import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { useQueryMe } from '@auth/api/useQueryMe';

export const logoutRequest = async (): Promise<AxiosResponse<void>> => {
  return await axios.post<void>('/auth/logout');
};

export function useMutateLogout() {
  const navigate = useNavigate();

  const me = useQueryMe();

  return useMutation<AxiosResponse<void>, AxiosError, void>('logout', logoutRequest, {
    onSuccess: (response) => {
      if (response.status == 200) {
        localStorage.removeItem('token');
        me.remove();
        navigate('/login');
      }
    },
  });
}
