import { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Skeleton } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { SearchOutlined } from '@ant-design/icons';

import { useQueryMe } from '@auth/api/useQueryMe';
import { LogoutDropdown } from '@components/organisms/navbar/organismComponents/LogoutDropdown';
import { navbars } from '@consts/navbars';
import { PageMetaContext } from '@context/PageMetaContext';
import { checkPermission } from '@utils/checkPermission';

export const Navbar: FC = () => {
  const { selectedMenuKeys } = useContext(PageMetaContext);

  const [navbarItems, setNavbarItems] = useState<ItemType[]>([]);

  const me = useQueryMe();

  useEffect(() => {
    if (me?.data?.authorities) {
      setNavbarItems(
        navbars
          .filter(
            (route) =>
              !route.permissions || checkPermission(me.data.authorities, route.permissions),
          )
          .map((route) => ({
            key: route.key,
            label: <Link to={route.link}>{route.icon || route.title}</Link>,
          }))
          .concat({ key: 'logout', label: <LogoutDropdown /> }),
      );
    }
  }, [me?.data]);

  return (
    <Layout.Header className="overflow-hidden bg-white px-5 xs:px-14">
      {me.isLoading ? (
        <div className="h-full w-3/4 sm:w-2/3 md:w-3/5 lg:w-1/2 m-auto">
          <div className="flex flex-row gap-4 sm:gap-8 md:gap-12 lg:gap-16 xl:gap-20 items-center h-full">
            {[...Array(5)].map((e, i) => (
              <Skeleton active key={`navbar${i}`} paragraph={false} round />
            ))}
          </div>
        </div>
      ) : (
        <Menu
          className="header-menu font-semibold max-w-full justify-center text-center"
          expandIcon={<SearchOutlined />}
          items={navbarItems}
          mode="horizontal"
          overflowedIndicatorPopupClassName="header-menu-overflow"
          selectedKeys={selectedMenuKeys}
          theme="light"
        />
      )}
    </Layout.Header>
  );
};
