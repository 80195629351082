import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  CaretRightOutlined,
  FolderOpenOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import { IAppProductStatusRequest } from '@shop/appProducts/api/useMutateAppProductStatus';
import { AppProductStatusEnum } from '@shop/appProducts/types/enums/AppProductStatusEnum';
import { appProductKeys, IAppProduct } from '@shop/appProducts/types/IAppProduct';

export const productsColumns = ({
  canEdit,
  onClickPause,
}: {
  canEdit: boolean;
  onClickPause: (request: IAppProductStatusRequest) => void;
}): ColumnsType<IAppProduct> => {
  const columns = [
    {
      dataIndex: appProductKeys.coins,
      title: 'Коины',
    },
    {
      dataIndex: appProductKeys.price,
      title: 'Цена',
    },
    {
      dataIndex: appProductKeys.appStoreId,
      title: 'Apple Id',
    },
    {
      dataIndex: appProductKeys.googlePlayId,
      title: 'Google ID',
    },
    {
      dataIndex: appProductKeys.status,
      render: (status: IAppProduct['status']) => (
        <div
          className={`flex flex-row items-center ${
            status == 'ARCHIVED' ? 'text-ant-gray-6' : status == 'ACTIVE' ? 'text-ant-green-6' : ''
          }`}
        >
          {status == 'ACTIVE' ? (
            <PlayCircleOutlined />
          ) : status == 'INACTIVE' ? (
            <PauseCircleOutlined />
          ) : (
            <FolderOpenOutlined />
          )}
          <span className="ml-2">{AppProductStatusEnum[status]}</span>
        </div>
      ),
      title: 'Статус',
    },
  ];

  if (!canEdit) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: (appProduct: IAppProduct) => (
        <div className="flex gap-x-1.5">
          {(appProduct.status == 'INACTIVE' || appProduct.status == 'ACTIVE') && (
            <Popconfirm
              cancelText="Отменить"
              icon={
                appProduct.status == 'ACTIVE' ? (
                  <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                ) : (
                  <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                )
              }
              okText={appProduct.status == 'ACTIVE' ? 'Остановить' : 'Запустить'}
              okType="primary"
              onConfirm={() =>
                onClickPause({
                  id: appProduct.id,
                  status: appProduct.status == 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
                })
              }
              placement="topLeft"
              title={
                <p className="text-sm mb-0">
                  Вы уверены, что хотите
                  <span className="font-semibold">
                    {appProduct.status == 'ACTIVE' ? ' остановить ' : ' запустить '} товар &quot;
                    {appProduct.appStoreId}&quot;?
                  </span>
                </p>
              }
            >
              <Button
                ghost
                icon={appProduct.status == 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
                type="primary"
              />
            </Popconfirm>
          )}

          {(appProduct.status == 'INACTIVE' || appProduct.status == 'ACTIVE') && (
            <Popconfirm
              cancelText="Отменить"
              icon={<FolderOpenOutlined className="text-ant-blue-6" />}
              okText="Архивировать"
              okType="primary"
              onConfirm={() => onClickPause({ id: appProduct.id, status: 'ARCHIVED' })}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что хотите
                  <span className="font-bold">
                    {' '}
                    архивировать товар &quot;{appProduct.appStoreId}&quot;
                  </span>
                  ?
                </span>
              }
            >
              <Button ghost icon={<FolderOpenOutlined />} type="primary" />
            </Popconfirm>
          )}
        </div>
      ),
      title: 'Действия',
    },
  ];
};
