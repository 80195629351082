import { InputFilter } from '@components/molecules/filters/static/InputFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { exerciseGroupKeys } from '@exercises/exerciseGroups/types/IExerciseGroup';
import { IFilters } from '@typess/IFilters';

export const exerciseGroupsFilters: IFilters = {
  ...paginationBaseFilters,
  [exerciseGroupKeys.groupName]: {
    input: <InputFilter placeholder="Введите название" />,
  },
  [exerciseGroupKeys.id]: {
    sort: true,
  },
  [exerciseGroupKeys.createdDate]: {
    sort: true,
  },
};
