import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum ExerciseGroupsPageActionEnum {
  'edit',
  'view',
  'createExercise',
}

export type IExerciseGroupsPageActions = {
  action: keyof typeof ExerciseGroupsPageActionEnum;
  actionId: number;
};

export const exerciseGroupsPageActions: IPageActions<IExerciseGroupsPageActions> = {
  action: {
    allowed: (value) => Object.keys(ExerciseGroupsPageActionEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
};
