import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, object, ObjectSchema, string } from 'yup';

import { staffsQueryKeys } from '@staff/api/staffsQueryKeys';
import { IStaff } from '@staff/types/IStaff';
import { exercisePermissions } from '@typess/permissions/IExercisePermission';
import { IPermission } from '@typess/permissions/IPermission';
import { tagPermissions } from '@typess/permissions/ITagPermission';
import { userPermissions } from '@typess/permissions/IUserPermission';
import { videoPermissions } from '@typess/permissions/IVideoPermission';
import { notify } from '@utils/notify';

export type IStaffInviteRequest = Pick<IStaff, 'email'> & {
  authorities: (IPermission | 'block')[];
};

type IStaffInviteResponse = AxiosResponse<void>;

export const staffInviteInitial: IStaffInviteRequest = {
  authorities: [
    userPermissions.view,
    'block',
    videoPermissions.view,
    exercisePermissions.view,
    tagPermissions.view,
    'block',
    'block',
  ],
  email: '',
};

export const staffInviteValidation: ObjectSchema<IStaffInviteRequest> = object({
  authorities: array().of(string<IPermission>().required()).required(),
  email: string()
    .email('Почта должна быть действительной')
    .required('E-mail обязателен для заполнения'),
});

export const staffInviteRequest = async (
  payload: IStaffInviteRequest,
): Promise<IStaffInviteResponse> => {
  return await axios.post('staff/register', payload);
};

export function useMutateStaffInvite() {
  const queryClient = useQueryClient();

  return useMutation<IStaffInviteResponse, AxiosError, IStaffInviteRequest>(staffInviteRequest, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(staffsQueryKeys.staffs);
      if (data.status == 201 || data.status == 200) {
        notify('success', 'Приглашение персоналу отправлено');
      }
    },
  });
}
