import '@assets/plyr-custom.css';

import { FC } from 'react';
import Plyr from 'plyr-react';

import {
  BiImageIcon,
  MdOutlineFontDownloadIcon,
  MdVideoSettingsIcon,
} from '@components/atoms/icons';
import { TitleAndText } from '@components/atoms/TitleAndText';
import { FileDownload } from '@components/molecules/files/FileDownload';
import { ILocalization, LocalizationEnum } from '@typess/ILocalization';
import { IVideo as VideoType } from '@videos/types/IVideo';

type VideoViewLocalizationType = { localization: ILocalization; video: VideoType };

export const VideoViewLocalization: FC<VideoViewLocalizationType> = ({ localization, video }) => {
  return (
    <div>
      <div className="divide-y">
        <TitleAndText
          text={
            <span className="font-bold text-xl">{video.localizations[localization]?.title}</span>
          }
          title="Краткое название"
        />
        <TitleAndText
          text={
            <span className="font-bold text-xl">
              {video.localizations[localization]?.fullTitle}
            </span>
          }
          title="Полное название"
        />
        <TitleAndText
          text={
            video.localizations[localization]?.author?.localizations[localization] ? (
              <>
                {video.localizations[localization]?.author?.authorRank && (
                  <span className="font-bold text-ant-red-7 mr-1">
                    {video.localizations[localization]?.author?.authorRank}
                  </span>
                )}
                {video.localizations[localization]?.author?.localizations[localization]}
              </>
            ) : (
              '-'
            )
          }
          title="Автор"
        />
      </div>

      <div className="flex justify-center px-2">
        <div className="w-full sm:px-12 md:px-0 md:w-4/5 lg:w-3/5 my-4 md:my-6 overflow-scroll rounded">
          <Plyr
            crossOrigin={'anonymous'}
            options={{
              clickToPlay: true,
              controls: [
                'play-large',
                'play',
                'progress',
                'mute',
                'volume',
                'settings',
                'fullscreen',
              ],
            }}
            preload="none"
            source={{
              poster: video.localizations[localization]?.previewUrl,
              sources: [
                {
                  provider: 'html5',
                  src: video.localizations[localization]?.videoFile?.mediaUrl || '',
                },
              ],
              tracks: Object.keys(video.localizations)
                .filter((localization) => video.localizations[localization].subtitlesUrl)
                .map((localization) => ({
                  key: `localization${localization}`,
                  kind: 'subtitles',
                  label: LocalizationEnum[localization],
                  src: video.localizations[localization].subtitlesUrl,
                  srcLang: localization,
                })),
              type: 'video',
            }}
            title={video.localizations[localization]?.title}
          />
        </div>
      </div>

      <div className="grid md:flex md:flex-row justify-center px-4 gap-3">
        <FileDownload
          fileName="Видео"
          icon={<MdVideoSettingsIcon />}
          link={video.localizations[localization]?.videoFile?.mediaUrl}
        />
        <FileDownload
          fileName="Субтитры"
          icon={<MdOutlineFontDownloadIcon />}
          link={video.localizations[localization]?.subtitlesUrl || ''}
        />
        <FileDownload
          fileName="Обложка"
          icon={<BiImageIcon />}
          link={video.localizations[localization]?.previewUrl}
        />
      </div>
      {!video.isCartoon && video.localizations[localization]?.descriptions?.length && (
        <div className="divide-y mt-5">
          <h2 className="text-2xl px-4">Описание</h2>
          {video.localizations[localization]?.descriptions?.map((desc, index) => (
            <TitleAndText className="py-2" key={desc} text={desc} title={`Описание ${index + 1}`} />
          ))}
        </div>
      )}
      {!video.isCartoon && (
        <div className="divide-y mt-5">
          <h2 className="text-2xl px-4">Тест</h2>
          <TitleAndText
            className="col-span-full"
            text={video.test?.localizations[localization]?.question}
            title="Вопрос"
          />
          <TitleAndText
            className="py-2"
            text={video.test?.localizations[localization]?.answerOptions?.[0]}
            title="Ответ 1"
          />

          <TitleAndText
            className="py-2"
            text={video.test?.localizations[localization]?.answerOptions?.[1]}
            title="Ответ 2"
          />
          <TitleAndText
            className="py-2"
            text={video.test?.localizations[localization]?.answerOptions?.[2]}
            title="Ответ 3"
          />

          <TitleAndText
            className="py-2"
            text={video.test?.localizations[localization]?.answerOptions?.[3]}
            title="Ответ 4"
          />
        </div>
      )}
    </div>
  );
};
