import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '@components/molecules/routes/ProtectedRoute';
import NotFoundPage from '@pages/errors/NotFoundPage';
import { userPermissions } from '@typess/permissions/IUserPermission';

import { MatchesAllPage } from './components/pages/MatchesAllPage';

export const MatchRouter = () => {
  return (
    <ProtectedRoute permissions={Object.values(userPermissions)}>
      <Routes>
        <Route element={<MatchesAllPage />} index />

        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </ProtectedRoute>
  );
};
