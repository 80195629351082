import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, number, object, ObjectSchema, string } from 'yup';

import { MapStageStatusEnumKeys } from '../types/enums/MapStageStatus';
import { MapStageTypeEnumKeys } from '../types/enums/MapStageType';
import { IMapStage } from '../types/IMapStage';
import { mapStageQueryKeys } from './mapStagesQueryKeys';

export type IMapStageCreateRequest = Pick<
  IMapStage,
  'playLevels' | 'status' | 'stageNumber' | 'type' | 'videoId'
>;

type IMapStageCreateResponse = AxiosResponse<void>;

export const mapStageCreateInitial: Partial<IMapStageCreateRequest> = {
  status: 'ACTIVE',
  type: 'VIDEO',
};

export const mapStageCreateValidation: ObjectSchema<IMapStageCreateRequest> = object({
  playLevels: array().of(number().required()).nullable(),
  stageNumber: number().required(),
  status: string<MapStageStatusEnumKeys>().required(),
  type: string<MapStageTypeEnumKeys>().required(),
  videoId: number().nullable(),
});

export const mapStageCreateRequest = async (
  payload: IMapStageCreateRequest,
): Promise<IMapStageCreateResponse> => await axios.post('/map-stage-definitions', payload);

export function useMutateMapStageCreate() {
  const queryClient = useQueryClient();

  return useMutation<IMapStageCreateResponse, AxiosError, IMapStageCreateRequest>(
    mapStageCreateRequest,
    {
      onSuccess: () => queryClient.invalidateQueries(mapStageQueryKeys.mapStages),
    },
  );
}
