import { FC, useState } from 'react';
import { Button, Modal, Switch as AntSwitch } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Radio, Select } from 'formik-antd';

import { useQueryExercises } from '@exercises/exercises/api/useQueryExercises';
import { useQueryFens } from '@exercises/fens/api/useQueryFens';
import {
  IMapStageCreateRequest,
  mapStageCreateValidation,
  useMutateMapStageCreate,
} from '@exercises/map-stages/api/useMutateMapStageCreate';
import { sortedMapStages } from '@exercises/map-stages/types/enums/MapStageType';
import { IUserPlayLevelNumber, userPlayLevels } from '@users/types/enums/UserPlayLevel';
import { notify } from '@utils/notify';
import { useQueryVideos } from '@videos/api/useQueryVideos';

export const MapStageCreateModal: FC<{
  initialValues: Partial<IMapStageCreateRequest>;
  isOpen: boolean;
  onCancel: () => void;
}> = ({ initialValues, isOpen, onCancel }) => {
  const stageCreate = useMutateMapStageCreate();
  const [stageNumber, setStageNumber] = useState<number>();
  const [exerciseSearchQuery, setExerciseSearchQuery] = useState('');
  const videos = useQueryVideos({ isCartoon: false });

  const exercises = useQueryExercises({
    id: exerciseSearchQuery,
    page: 1,
    pageSize: 10,
    sortBy: 'id',
    sortDirection: 'ASC',
  });

  const fens = useQueryFens();

  const handleChange = (event) => {
    const result = event.target.value.replace(/\D/g, '');
    setStageNumber(result);
  };

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3"
      footer={null}
      onCancel={() => {
        onCancel();
      }}
      open={isOpen}
      title={
        <h1 className="text-xl m-0 space-x-1">
          <span>Создать шаг на карте</span>
        </h1>
      }
    >
      <Formik<IMapStageCreateRequest>
        enableReinitialize
        initialValues={{
          ...initialValues,
          stageNumber: initialValues.stageNumber || 0,
          status: initialValues.status || 'DRAFT',
          type: initialValues.type || 'VIDEO',
        }}
        onSubmit={(values, { resetForm }) => {
          stageCreate.mutateAsync({ ...values, stageNumber: stageNumber || 1 }).then((data) => {
            if (data.status == 201 || data.status == 200) {
              notify('success', 'Шаг на карте создан');
              onCancel();
              resetForm();
            }
          });
        }}
        validationSchema={mapStageCreateValidation}
      >
        {({ setFieldValue, values }) => {
          return (
            <Form className="space-y-6" layout="vertical">
              <FormItem label="Шаг на карте" name="stageNumber">
                <Input
                  className="w-full"
                  name="stageNumber"
                  onChange={(event) => handleChange(event)}
                  placeholder="Введите шаг"
                  value={stageNumber}
                />
              </FormItem>
              <FormItem label="Уровенsь" name="playLevels">
                <Select
                  mode="multiple"
                  name="playLevels"
                  optionFilterProp="label"
                  options={Object.keys(userPlayLevels)
                    .map(Number)
                    .map((playLevel) => ({
                      disabled:
                        !!values.playLevels?.length &&
                        !values.playLevels.includes(playLevel as IUserPlayLevelNumber),
                      label: userPlayLevels[playLevel],
                      value: playLevel,
                    }))}
                  showSearch={false}
                />
              </FormItem>
              <FormItem label="Тип уровня" name="type">
                <Radio.Group
                  name="type"
                  onChange={(e) => {
                    setFieldValue('type', e.target.value);
                    setFieldValue('exerciseIds', undefined);
                    setFieldValue('fenRecordId', undefined);
                    setFieldValue('videoId', undefined);
                  }}
                  options={sortedMapStages.map((stage) => ({
                    label: stage?.name ?? '',
                    value: stage?.value ?? '',
                  }))}
                />
              </FormItem>

              {values.type === 'VIDEO' && (
                <FormItem label="Видеоурок" name="videoId">
                  <Select
                    allowClear
                    className="gap-y-2"
                    name="videoId"
                    optionFilterProp="label"
                    options={
                      videos.data?.data.content.map((video) => ({
                        label: video.localizations.en?.fullTitle,
                        value: video.id,
                      })) || []
                    }
                    placeholder="Видео"
                    showSearch
                  />
                </FormItem>
              )}
              {values.type === 'EXERCISE' && (
                <FormItem label="Задача" name="exerciseIds">
                  <Select
                    allowClear
                    className="gap-y-2"
                    loading={exercises.isLoading}
                    mode="multiple"
                    name="exerciseIds"
                    onSearch={(value) => setExerciseSearchQuery(value)}
                    optionFilterProp="label"
                    options={
                      exercises.data?.data.content.map((exercise) => ({
                        label: exercise.id,
                        value: exercise.id,
                      })) || []
                    }
                    placeholder="Задача"
                    showSearch
                  />
                </FormItem>
              )}
              {values.type === 'GAME_MATCH' && (
                <FormItem label="FEN" name="fenRecordId">
                  <Select
                    allowClear
                    className="gap-y-2"
                    name="fenRecordId"
                    optionFilterProp="label"
                    options={
                      fens.data?.data.content.map((fen) => ({
                        label: fen.id,
                        value: fen.id,
                      })) || []
                    }
                    placeholder="FEN"
                    showSearch
                  />
                </FormItem>
              )}

              <div className="grid grid-cols-2 place-items-start text-start">
                <Form.Item
                  label={<span className="text-center">Запустить немедленно</span>}
                  name="status"
                >
                  <Input hidden name="status" suffix />

                  <AntSwitch
                    checked={values.status == 'ACTIVE'}
                    onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
                  />
                </Form.Item>
              </div>

              <div className="grid md:grid-cols-2 gap-5">
                <Button block onClick={onCancel}>
                  Отменить
                </Button>

                <Button block htmlType="submit" loading={stageCreate.isLoading} type="primary">
                  <span className="max-w-full overflow-ellipsis overflow-hidden">
                    {values['status'] == 'ACTIVE'
                      ? 'Создать и запустить'
                      : 'Сохранить как черновик'}
                  </span>
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
