import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { notify } from '@utils/notify';

import { lecturerCardCommonKeys } from './lecturerCardKeys';

type IDeleteLecturerCardRequest = {
  id: number;
};

export const deleteLecturerCardRequest = ({ id }: IDeleteLecturerCardRequest) =>
  axios.delete(`/admin/author-cards/${id}`);

export const useMutateLecturerCardDelete = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, IDeleteLecturerCardRequest>(
    deleteLecturerCardRequest,
    {
      onError: () => {
        notify('error', 'Повторите попытку позже');
      },
      onSuccess: () => {
        notify('success', 'Карточка лектора успешно удалена');

        queryClient.invalidateQueries(lecturerCardCommonKeys.lecturerCards);
      },
    },
  );
};
