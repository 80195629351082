import { cloneElement, FC, isValidElement, useState } from 'react';
import { Dropdown } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { TableHeaderSortIcon } from '@components/organisms/adminTable/organismComponents/TableHeaderSortIcon';
import { Filter, IFiltersPropsDefault } from '@typess/IFilters';

type IFilterDropdown = {
  dataIndex: string;
  filter: Filter;
  filtered: IFiltersPropsDefault;
  onFilter: (key: object) => void;
  placement?:
    | 'bottom'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight'
    | 'top'
    | 'topLeft'
    | 'topCenter'
    | 'topRight';
  title?: JSX.Element | string | object;
};

export const FilterDropdown: FC<IFilterDropdown> = ({
  dataIndex,
  filter,
  filtered,
  onFilter,
  placement,
  title,
}) => {
  const [open, setOpen] = useState(false);
  const isActive =
    !!filtered[dataIndex] ||
    !!filter.queries?.find((query) => query.key && filtered[query.key]?.toString().length);

  return (
    <Dropdown
      dropdownRender={() =>
        filter.input ? (
          cloneElement(filter.input, {
            filterRender: {
              dataIndex: dataIndex,
              filtered: filtered,
              onFilter: (key) => {
                onFilter(key);
                setOpen(false);
              },
              queries: filter.queries,
            },
          })
        ) : (
          <></>
        )
      }
      onOpenChange={(newOpen) => setOpen(newOpen)}
      open={open}
      overlayClassName="ant-table-filter-dropdown"
      overlayStyle={{ minWidth: 0 }}
      placement={placement}
      trigger={['click']}
    >
      <th className="filter-cell ant-table-cell ant-table-cell-ellipsis">
        <div className="flex flex-nowrap justify-between">
          <span className={isActive ? 'text-ant-blue-6' : ''}>
            {filter.title || isValidElement(title) ? (title as JSX.Element) : title?.toString()}
            <SearchOutlined
              className={`ml-1.5 text-xs ${isActive ? 'text-ant-blue-6' : 'text-gray-400'}`}
            />
          </span>

          {filter.sort && (
            <TableHeaderSortIcon
              dataIndex={dataIndex}
              onFilter={onFilter}
              orderedBy={filtered['sortDirection']}
              sortOrderReversed={filter.sortOrderReversed}
              sortedBy={filtered['sortBy']}
            />
          )}
        </div>
      </th>
    </Dropdown>
  );
};
