import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Skeleton } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';

import {
  IRegisterConfirmPasswordType,
  registerInitial,
  registerValidation,
  useMutateRegister,
} from '@auth/api/useMutateRegister';
import { useQueryLinkState } from '@auth/api/useQueryLinkState';
import { PageMeta } from '@context/PageMetaContext';

const RegisterPage: FC = () => {
  const { link } = useParams();
  const linkState = useQueryLinkState({ link: link || '' });

  const register = useMutateRegister();

  return (
    <>
      <PageMeta title="Регистрация" />

      {linkState.isLoading ? (
        <div className="p-2">
          <Skeleton active round />
          <Skeleton active round />
        </div>
      ) : (
        <Formik<IRegisterConfirmPasswordType>
          initialValues={{ ...registerInitial, link: link || '' }}
          onSubmit={(values) => {
            register.mutate(values);
          }}
          validationSchema={registerValidation}
        >
          <Form className="mt-2 sm:mt-6 space-y-8" layout="vertical">
            <h2 className="text-center text-4xl text-gray-900">Добро пожаловать!</h2>

            <FormItem label="Ф.И.О" name="fullName">
              <Input maxLength={60} name="fullName" placeholder="example" showCount suffix />
            </FormItem>

            <FormItem label="Новый пароль" name="password">
              <Input.Password maxLength={30} name="password" placeholder="********" showCount />
            </FormItem>

            <FormItem label="Повтор пароля" name="confirmPassword">
              <Input.Password
                maxLength={30}
                name="confirmPassword"
                placeholder="********"
                showCount
              />
            </FormItem>

            <Button
              block
              htmlType="submit"
              loading={register.isLoading}
              size="large"
              type="primary"
            >
              Завершить регистрацию
            </Button>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default RegisterPage;
