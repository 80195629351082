import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';

export const ShopTabs: FC<{ active: 'products' | 'campaigns' }> = ({ active }) => {
  const navigate = useNavigate();

  return (
    <Tabs
      activeKey={active}
      centered
      items={[
        { key: 'products', label: 'Товары' },
        { key: 'campaigns', label: 'Кампании' },
      ]}
      onChange={(tab) => navigate(tab == 'campaigns' ? '/shop/campaigns' : '/shop')}
    />
  );
};
