import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IMatchPvp } from '../types/IMatchPvp';
import { matchesQueryKeys } from './matchesQueryKeys';

export type IMatchPvpRequest = { id?: string };
type IMatchesAllResponse = AxiosResponse<IMatchPvp>;

export const matchPvpRequest = async ({ id }: IMatchPvpRequest): Promise<IMatchesAllResponse> =>
  await axios.get(`/game-match-pvp/${id}`);

export function useQueryMatchPvp({ id }: IMatchPvpRequest) {
  return useQuery<IMatchesAllResponse, AxiosError>(
    [matchesQueryKeys.matchesAll, { id }],
    () => matchPvpRequest({ id }),
    { enabled: !!id },
  );
}
