import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, boolean, number, object, ObjectSchema, string } from 'yup';

import { exerciseGroupQueryKeys } from '@exercises/exerciseGroups/api/exerciseGroupQueryKeys';
import { exerciseQueryKeys } from '@exercises/exercises/api/exerciseQueryKeys';
import { IExerciseProblemEditRequest } from '@exercises/exercises/api/useMutateExerciseProblemEdit';
import { ExerciseGamePhaseEnumKeys } from '@exercises/exercises/types/enums/ExerciseGamePhaseEnum';
import { ExerciseStatusEnumKeys } from '@exercises/exercises/types/enums/ExerciseStatusEnum';
import { IUserPlayLevelNumber } from '@users/types/enums/UserPlayLevel';

export type IExerciseGroupEditRequest = Partial<
  Omit<IExerciseProblemEditRequest, 'localizations'>
> & {
  groupName: string;
  id: number;
};

type IExerciseGroupEditResponse = AxiosResponse<void>;

export const exerciseGroupEditValidation: ObjectSchema<IExerciseGroupEditRequest> = object({
  gamePhase: string<ExerciseGamePhaseEnumKeys>(),
  groupName: string().nullable().required(),
  id: number().required(),
  isCapitalization: boolean(),
  isPawnStructure: boolean(),
  isTactic: boolean(),
  playLevels: array().of(number<IUserPlayLevelNumber>().required()),
  status: string<ExerciseStatusEnumKeys>(),
  tagIds: array().of(number().required()),
  useForPuzzleRush: boolean().optional(),
  videoId: number().nullable(),
});

export const exerciseGroupEditRequest = async (
  payload: IExerciseGroupEditRequest,
): Promise<IExerciseGroupEditResponse> =>
  await axios.patch(`/exercise-groups/${payload.id}`, payload);

export function useMutateExerciseGroupEdit() {
  const queryClient = useQueryClient();

  return useMutation<IExerciseGroupEditResponse, AxiosError, IExerciseGroupEditRequest>(
    exerciseGroupEditRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(exerciseGroupQueryKeys.exercisesGroups);
        queryClient.invalidateQueries(exerciseGroupQueryKeys.exerciseGroup);
        queryClient.invalidateQueries(exerciseQueryKeys.exercises);
        queryClient.invalidateQueries(exerciseQueryKeys.exercise);
      },
    },
  );
}
