import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import { date, number, object, ObjectSchema, string } from 'yup';

import { promocodesQueryKeys } from '@settings/promocodes/api/promocodesQueryKeys';
import { IPromocodeGroup } from '@settings/promocodes/types/IPromocodeGroup';

export type IPromocodeGroupCreateRequest = Pick<
  IPromocodeGroup,
  'groupName' | 'expireDate' | 'freeUseMonths'
> & {
  codeCount: number;
};

type IPromocodeGroupCreateResponse = AxiosResponse<IPromocodeGroup>;

export const promocodeGroupCreateInitial: IPromocodeGroupCreateRequest = {
  codeCount: 1,
  expireDate: null,
  freeUseMonths: 1,
  groupName: '',
};

export const promocodeGroupCreateValidation: ObjectSchema<IPromocodeGroupCreateRequest> = object({
  codeCount: number()
    .nullable()
    .min(1, 'Лимит минимум 1')
    .max(999, 'Лимит максимум 999')
    .required('Количество обязательно для заполнения'),
  expireDate: date()
    .nullable()
    .min(moment().add(1, 'day').startOf('day'), 'Дата окончания минимум завтра'),
  freeUseMonths: number()
    .nullable()
    .min(1, 'Лимит минимум 1')
    .max(999, 'Лимит максимум 12')
    .required('Бесплатное количество месяцев обязательно для заполнения'),
  groupName: string()
    .nullable()
    .required('Код обязателен для заполнения')
    .min(5, 'Название должно быть не менее 5 символов')
    .max(50, 'Название должно быть не более 50 символов'),
});

export const promocodeGroupCreateRequest = async (
  payload: IPromocodeGroupCreateRequest,
): Promise<IPromocodeGroupCreateResponse> => {
  return await axios.post('/promocode-groups', {
    ...payload,
    expireDate: payload.expireDate
      ? moment(payload.expireDate).format('YYYY-MM-DD')
      : payload.expireDate,
  });
};

export function useMutatePromocodeGroupCreate() {
  const queryClient = useQueryClient();

  return useMutation<IPromocodeGroupCreateResponse, AxiosError, IPromocodeGroupCreateRequest>(
    promocodeGroupCreateRequest,
    {
      onSuccess: () => queryClient.invalidateQueries(promocodesQueryKeys.promocodeGroups),
    },
  );
}
