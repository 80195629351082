import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum ChessPiecesGroupsPageActionEnum {
  'edit',
  'view',
  'createChessPiecesSet',
  'createChessPiecesGroup',
}

export type IChessPiecesGroupPageActions = {
  action: keyof typeof ChessPiecesGroupsPageActionEnum;
  actionId: number;
};

export const chessPiecesGroupsPageActions: IPageActions<IChessPiecesGroupPageActions> = {
  action: {
    allowed: (value) => Object.keys(ChessPiecesGroupsPageActionEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
};
