import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '@components/molecules/routes/ProtectedRoute';
import NotFoundPage from '@pages/errors/NotFoundPage';
import { ShopProductsPage } from '@shop/appProducts/components/pages/ShopProductsPage';
import { ShopPromotionsPage } from '@shop/appPromotions/components/pages/ShopPromotionsPage';
import { shopPermissions } from '@typess/permissions/IShopPermission';

export const ShopRouter = () => {
  return (
    <ProtectedRoute permissions={Object.values(shopPermissions)}>
      <Routes>
        <Route element={<ShopProductsPage />} index />
        <Route element={<ShopPromotionsPage />} path="campaigns" />
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </ProtectedRoute>
  );
};
