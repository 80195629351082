import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, number, object, ObjectSchema, string } from 'yup';

import { exerciseQueryKeys } from '@exercises/exercises/api/exerciseQueryKeys';
import { ExerciseStatusEnumKeys } from '@exercises/exercises/types/enums/ExerciseStatusEnum';

import { IExerciseCustomGame } from '../types/IExerciseCustomGame';

export type IExerciseCustomGameCreateRequest = Omit<
  IExerciseCustomGame,
  'id' | 'opponentCanTake'
> & {
  videoId?: number | null;
};

type IExerciseCustomGameCreateResponse = AxiosResponse<void>;

export const exerciseCustomGameCreateInitial: IExerciseCustomGameCreateRequest = {
  boardState: '',
  localizations: {
    en: {
      name: '',
    },
    ru: {
      name: '',
    },
  },
  playLevels: [],
  status: 'ACTIVE',
  type: 'FIGURE',
};

export const exerciseCustomGameCreateValidation: ObjectSchema<IExerciseCustomGameCreateRequest> =
  object({
    boardState: string().required(),
    localizations: object({
      en: object({
        name: string()
          .required('Вопрос обязателен для заполнения')
          .max(250, 'Вопрос должен быть не более 200 символов'),
      }),
      ru: object({
        name: string()
          .required('Вопрос обязателен для заполнения')
          .max(250, 'Вопрос должен быть не более 200 символов'),
      }),
    }),
    playLevels: array().required('').min(1, 'Упражнение должно содержать минимум 1 уровень'),
    status: string<ExerciseStatusEnumKeys>().required('Статус обязателен для заполнения'),
    type: string<'FIGURE' | 'STAR'>().required(),
    videoId: number().nullable(),
  });

export const exerciseCustomGameCreateRequest = async (
  payload: IExerciseCustomGameCreateRequest,
): Promise<IExerciseCustomGameCreateResponse> =>
  await axios.post('/exercises/customProblem', payload);

export function useMutateExerciseCustomGameCreate() {
  const queryClient = useQueryClient();

  return useMutation<
    IExerciseCustomGameCreateResponse,
    AxiosError,
    IExerciseCustomGameCreateRequest
  >(exerciseCustomGameCreateRequest, {
    onSuccess: () => queryClient.invalidateQueries(exerciseQueryKeys.exercises),
  });
}
