import { Link } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  CaretRightOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import { CountryImageText } from '@components/atoms/CountryImageText';
import { TbVideoIcon, VscPlayIcon } from '@components/atoms/icons';
import { PencilIcon } from '@components/atoms/icons/PencilIcon';
import { IFenStatusUpdateRequest } from '@exercises/fens/api/useMutateFenStatusUpdate';
import { fenKeys, IFen } from '@exercises/fens/types/IFen';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';

export const fenColumns = ({
  canEdit,
  onClickDelete,
  onClickEdit,
  onClickPause,
  onClickView,
}: {
  canEdit: boolean;
  onClickDelete?: ({ id }: { id: number }) => void;
  // onClickEdit?: ({ id }: { id: number }) => void;
  onClickEdit?: (fen: IFen) => void;
  onClickPause?: ({ id, status }: IFenStatusUpdateRequest) => void;
  onClickView?: (fen: IFen) => void;
}): ColumnsType<IFen> => {
  const columns: ColumnsType<IFen> = [
    {
      dataIndex: fenKeys.id,
      render: (id: IFen['id'], fen) =>
        onClickView ? (
          <Button onClick={() => onClickView(fen)} type="link">
            {id}
          </Button>
        ) : (
          id
        ),
      title: 'ID',
    },
    {
      dataIndex: fenKeys.value,
      render: (value: IFen['value'], fen) =>
        onClickView ? (
          <Button className="whitespace-pre-wrap" onClick={() => onClickView(fen)} type="link">
            {value}
          </Button>
        ) : (
          value
        ),
      title: 'FEN',
      width: 300,
    },
    {
      dataIndex: fenKeys.playLevel,
      render: (value: IFen['playLevel']) => (value ? userPlayLevels[value] : '-'),
      title: 'Уровень',
    },
    {
      dataIndex: fenKeys.name,
      title: 'Название позиции',
    },
    {
      dataIndex: fenKeys.videos,
      render: (videos: IFen['videos']) =>
        videos?.length
          ? videos.map((video) => (
              <Link
                className={`flex items-center gap-1 text-center ${
                  video.isCartoon ? 'text-ant-magenta-6' : 'text-ant-purple-6'
                }`}
                key={video.id}
                to={`/videos?action=view&actionId=${video.id}`}
              >
                {video.isCartoon ? (
                  <VscPlayIcon className="min-w-fit" />
                ) : (
                  <TbVideoIcon className="min-w-fit" />
                )}
                {video.localizations.en?.fullTitle}
              </Link>
            ))
          : '-',
      title: 'Видеоуроки',
      width: 'auto',
    },
    {
      dataIndex: fenKeys.localizations,
      render: (localizations: IFen['localizations']) => (
        <div>
          <CountryImageText src="en" text={localizations?.en?.messageFromKaissa || '-'} />
          <CountryImageText src="ru" text={localizations?.ru?.messageFromKaissa || '-'} />
        </div>
      ),
      title: 'Сообщения от Каиссы',
    },
  ];

  if (!canEdit || !onClickEdit) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: (fen: IFen) => (
        <div className="flex gap-x-1.5">
          {onClickPause && (
            <Popconfirm
              cancelText="Отменить"
              icon={
                fen.status == 'ACTIVE' ? (
                  <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                ) : (
                  <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                )
              }
              okText={fen.status == 'ACTIVE' ? 'Приостановить' : 'Запустить'}
              okType="primary"
              onConfirm={() =>
                onClickPause({
                  id: fen.id,
                  status: fen.status == 'ACTIVE' ? 'DRAFT' : 'ACTIVE',
                })
              }
              placement="topLeft"
              title={
                <p className="text-sm mb-0">
                  Вы уверены, что хотите
                  <span className="font-semibold">
                    {fen.status == 'ACTIVE' ? ' остановить ' : ' запустить '}
                    FEN #{fen.id} &quot;{fen.name}&quot;?
                  </span>
                </p>
              }
            >
              <Button
                ghost
                icon={fen.status == 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
                type="primary"
              />
            </Popconfirm>
          )}
          {onClickEdit && (
            <Button
              ghost
              icon={<PencilIcon />}
              // onClick={() => onClickEdit({ id })}
              onClick={() => onClickEdit(fen)}
              type="primary"
            />
          )}
          {onClickDelete && (
            <Popconfirm
              cancelText="Отменить"
              icon={<DeleteOutlined className="text-ant-red-5" />}
              okText="Удалить"
              okType="danger"
              onConfirm={() => onClickDelete({ id: fen.id })}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что хотите
                  <span className="font-bold">
                    {' '}
                    удалить FEN #{fen.id} &quot;{fen.name}&quot;?
                  </span>
                  ?
                </span>
              }
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </div>
      ),
      title: 'Действия',
    },
  ];
};
