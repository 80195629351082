import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { exerciseQueryKeys } from '@exercises/exercises/api/exerciseQueryKeys';
import { ExerciseTypeEnumKeys } from '@exercises/exercises/types/enums/ExerciseTypeEnum';
import { IExercise } from '@exercises/exercises/types/IExercise';
import { IPageAbleRequest } from '@typess/IPageAbleRequest';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';
import { IUserPlayLevelNumber } from '@users/types/enums/UserPlayLevel';

export type IExercisesRequest = IPageAbleRequest & {
  createdByUserId?: number | string;
  groupId?: number;
  id?: number | string;
  playLevels?: IUserPlayLevelNumber[];
  solvedUsersCountFrom?: number | string;
  solvedUsersCountTo?: number | string;
  solvedUsersPercentageFrom?: number | string;
  solvedUsersPercentageTo?: number | string;
  tagIds?: number[] | string[];
  type?: ExerciseTypeEnumKeys;
  videoId?: number | string;
  videoIds?: number[] | string[];
};

type IExercisesResponse = AxiosResponse<IPageAbleResponse<IExercise>>;

export const exercisesRequest = async (params?: IExercisesRequest): Promise<IExercisesResponse> =>
  await axios.get('/exercises', { params });

export function useQueryExercises(params?: IExercisesRequest) {
  return useQuery<IExercisesResponse, AxiosError>([exerciseQueryKeys.exercises, params], () =>
    exercisesRequest(params),
  );
}
