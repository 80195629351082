/**
 * ⚠️ DONT EDIT MANUALLY
 * This file is generated with `./src/cli/generateIcons.js`, check it for the detailed information
 */
import { FC } from 'react';

import { ReactComponent as ChessPieceWhiteRookIconSvg } from '@assets/icons/chess-piece-white-rook.svg';

import BaseIcon from './_BaseIcon';

type Props = React.ComponentPropsWithoutRef<'span'>;

export const ChessPieceWhiteRookIcon: FC<Props> = (props) => {
  return (
    <BaseIcon component={ChessPieceWhiteRookIconSvg} style={{ fontSize: '1.5em' }} {...props} />
  );
};
