import { FC } from 'react';
import { Tooltip } from 'antd';
import { FormItem, InputNumber } from 'formik-antd';
import { AndroidOutlined, AppleOutlined, NumberOutlined } from '@ant-design/icons';

export const AppSettingEngineLevelFormik: FC<{ variables: object }> = ({ variables }) => {
  return (
    <div className="space-y-4">
      {Object.entries(variables).map(([playLevel]) => (
        <div key={`levelFormik${playLevel}`}>
          <div className="grid grid-cols-3 gap-4">
            <Tooltip placement="bottom" title="Рейтинг пользователя">
              <FormItem
                label={`${playLevel}. Рейтинг пользователя`}
                name={`variables.${playLevel}.2`}
              >
                <InputNumber
                  addonBefore={<NumberOutlined />}
                  className="w-full"
                  controls={false}
                  name={`variables.${playLevel}.2`}
                  placeholder={`Рейтинг пользователя - ${playLevel}`}
                  prefix
                />
              </FormItem>
            </Tooltip>

            <Tooltip placement="bottom" title="IOS">
              <FormItem label="IOS" name={`variables.${playLevel}.0`}>
                <InputNumber
                  addonBefore={<AppleOutlined />}
                  className="w-full"
                  controls={false}
                  name={`variables.${playLevel}.0`}
                  placeholder="IOS"
                  prefix
                />
              </FormItem>
            </Tooltip>

            <Tooltip placement="bottom" title="ANDROID">
              <FormItem label="ANDROID" name={`variables.${playLevel}.1`}>
                <InputNumber
                  addonBefore={<AndroidOutlined />}
                  className="w-full"
                  controls={false}
                  name={`variables.${playLevel}.1`}
                  placeholder="Android"
                  prefix
                />
              </FormItem>
            </Tooltip>
          </div>
        </div>
      ))}
    </div>
  );
};
