import { CountriesSelectMultipleFilter } from '@components/molecules/filters/dynamic/CountriesSelectMultipleFilter';
import { CheckboxFilter } from '@components/molecules/filters/static/CheckboxFilter';
import { InputFilter } from '@components/molecules/filters/static/InputFilter';
import { RadioFilter } from '@components/molecules/filters/static/RadioFilter';
import { RangeFilter } from '@components/molecules/filters/static/RangeFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import { IFilters } from '@typess/IFilters';
import { userAgeCategories } from '@users/types/enums/UserAgeCategoryEnum';
import { userDevicePlatforms } from '@users/types/enums/UserDevicePlatformEnum';
import { UserLanguageEnum } from '@users/types/enums/UserLanguageEnum';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';
import { UserSubscriptionEnum } from '@users/types/enums/UserSubscriptionEnum';
import { userKeys } from '@users/types/IUser';

export const usersFilters: IFilters = {
  ...paginationBaseFilters,

  [userKeys.age]: {
    input: (
      <RangeFilter placeholderMax="Максимальный возраст" placeholderMin="Минимальный возраст" />
    ),
    queries: [{ key: 'ageFrom' }, { key: 'ageTo' }],
    sort: true,
  },
  [userKeys.ageCategory]: {
    input: (
      <CheckboxFilter
        options={Object.entries(userAgeCategories).map(([value, label]) => ({ label, value }))}
      />
    ),
    queries: [{ key: 'ageCategory' }],
    sort: true,
  },
  [userKeys.appVersion]: {
    queries: [{ key: 'appVersion' }],
    sort: true,
  },
  [userKeys.subscription]: {
    input: (
      <CheckboxFilter
        options={[
          { label: 'Покупка', value: 'PURCHASE' },
          { label: 'Промокод', value: 'PROMOCODE' },
        ]}
      />
    ),
    queries: [{ key: 'subscriptionType' }],
  },
  [userKeys.devicePlatform]: {
    input: (
      <CheckboxFilter
        options={Object.entries(userDevicePlatforms).map(([value, label]) => ({ label, value }))}
      />
    ),
    queries: [{ key: 'devicePlatform' }],
    sort: true,
  },
  [userKeys.isParent]: {
    input: (
      <CheckboxFilter
        options={[
          { label: 'Я', value: false },
          { label: 'Родитель', value: true },
        ]}
      />
    ),
    queries: [{ key: 'isParent' }],
    sort: true,
  },
  [userKeys.country]: {
    input: <CountriesSelectMultipleFilter />,
    queries: [{ key: 'countryIds' }],
    sort: true,
  },
  [userKeys.id]: {
    input: <InputFilter placeholder="Введите ID" />,
    sort: true,
  },
  [userKeys.email]: {
    input: <InputFilter placeholder="Введите почту" />,
  },
  [userKeys.subscriptionStatus]: {
    input: (
      <CheckboxFilter
        options={Object.entries(UserSubscriptionEnum).map(([value, label]) => ({ label, value }))}
      />
    ),
  },
  [userKeys.language]: {
    input: (
      <RadioFilter
        options={Object.entries(UserLanguageEnum).map(([value, label]) => ({ label, value }))}
      />
    ),
  },
  [userKeys.nickname]: {
    input: <InputFilter placeholder="Введите никнейм" />,
  },
  [userKeys.playLevel]: {
    input: (
      <CheckboxFilter
        options={Object.entries(userPlayLevels).map(([value, label]) => ({ label, value }))}
      />
    ),
    queries: [{ key: 'playLevels' }],
    sort: true,
  },
  [userKeys.coins]: {
    input: <RangeFilter placeholderMax="Макс. монет" placeholderMin="Мин. монет" />,
    queries: [{ key: 'coinsFrom' }, { key: 'coinsTo' }],
    sort: true,
  },
  [userKeys.systemRating]: {
    input: (
      <RangeFilter placeholderMax="Максимальный рейтинг" placeholderMin="Минимальный рейтинг" />
    ),
    queries: [{ key: 'systemRatingFrom' }, { key: 'systemRatingTo' }],
    sort: true,
  },
  [userKeys.rating]: {
    input: (
      <RangeFilter placeholderMax="Максимальный рейтинг" placeholderMin="Минимальный рейтинг" />
    ),
    queries: [{ key: 'ratingFrom' }, { key: 'ratingTo' }],
    sort: true,
    sortOrderReversed: true,
  },
};
