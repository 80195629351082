import { FC } from 'react';
import { Button, Modal } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';

import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { AppSettingEngineLevelFormik } from '@settings/appSettings/components/molecules/AppSettingEngineLevelFormik';
import { AppSettingEngineRatingFormik } from '@settings/appSettings/components/molecules/AppSettingEngineRatingFormik';
import { notify } from '@utils/notify';

import {
  appSettingUpdateValidation,
  IAppSettingUpdateRequest,
  useMutateAppSettingUpdate,
} from '../../api/useMutateAppSettingUpdate';
import { useQueryAppSetting } from '../../api/useQueryAppSetting';
import { AppSettingNameEnum, AppSettingsNameEnumKeys } from '../../types/enums/AppSettingNameEnum';
import { AppSettingEngineBattleFormik } from '../molecules/AppSettingEngineBattleFormk';

type AppSettingUpdateModalType = {
  isOpen: boolean;
  name?: AppSettingsNameEnumKeys;
  onCancel: () => void;
};

export const AppSettingUpdateModal: FC<AppSettingUpdateModalType> = ({
  isOpen,
  name,
  onCancel,
}) => {
  const appSettingQuery = useQueryAppSetting({ name });
  const appSetting = appSettingQuery.data?.data;

  const settingUpdate = useMutateAppSettingUpdate();

  return (
    <Modal
      className="px-4 md:px-0 md:w-2/3 lg:w-1/2 pt-0 mt-0"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
    >
      {appSettingQuery.isLoading ? (
        <BaseSkeleton />
      ) : !appSetting ? (
        <NotFoundResult title="Настройка не найдена" />
      ) : (
        <Formik<IAppSettingUpdateRequest>
          enableReinitialize
          initialValues={{ ...appSetting }}
          onSubmit={(values, { resetForm }) => {
            settingUpdate.mutateAsync(values).then((data) => {
              if (data.status == 201 || data.status == 200) {
                notify('success', `Настройка ${appSetting.name} изменен`);
                onCancel();
                resetForm();
              }
            });
          }}
          validationSchema={appSettingUpdateValidation}
        >
          <Form className="space-y-8" layout="vertical">
            <h1 className="text-xl m-0">{AppSettingNameEnum[appSetting.name]}</h1>
            {appSetting.name == 'PLAY_LEVEL_ENGINE_RATING_MAP' ? (
              <AppSettingEngineRatingFormik
                variables={
                  typeof appSetting.variables == 'object' ? appSetting.variables || {} : {}
                }
              />
            ) : appSetting.name == 'PLAY_LEVEL_ENGINE_LEVEL_MAP' ? (
              <AppSettingEngineLevelFormik
                variables={
                  typeof appSetting.variables == 'object' ? appSetting.variables || {} : {}
                }
              />
            ) : appSetting.name == 'BATTLE_ENGINE_PLAY_LEVEL_MAP' ? (
              <AppSettingEngineBattleFormik
                variables={
                  typeof appSetting.variables == 'object' ? appSetting.variables || {} : {}
                }
              />
            ) : (
              Object.keys(AppSettingNameEnum).includes(appSetting.name) && (
                <FormItem name="string">
                  <Input
                    name="string"
                    placeholder={AppSettingNameEnum[appSetting.name]}
                    suffix
                    type="text"
                  />
                </FormItem>
              )
            )}

            <div className="grid md:grid-cols-2 gap-5">
              <Button block onClick={onCancel}>
                Отменить
              </Button>

              <Button htmlType="submit" loading={settingUpdate.isLoading} type="primary">
                Сохранить
              </Button>
            </div>
          </Form>
        </Formik>
      )}
    </Modal>
  );
};
