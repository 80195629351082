import { useMutation, useQueryClient } from 'react-query';
import { RcFile } from 'antd/lib/upload';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { mixed, object, ObjectSchema, string } from 'yup';

import { exerciseGroupQueryKeys } from '@exercises/exerciseGroups/api/exerciseGroupQueryKeys';
import { ExerciseStatusEnumKeys } from '@exercises/exercises/types/enums/ExerciseStatusEnum';
import { IUserPlayLevelNumber } from '@users/types/enums/UserPlayLevel';

import { assessmentExerciseQueryKeys } from './assessmentExerciseQueryKeys';

export type IAssessmentExerciseProblemCreateRequest = {
  file?: Partial<RcFile>;
  json: {
    playLevel?: IUserPlayLevelNumber;
    status: ExerciseStatusEnumKeys;
  };
};

export const assessmentExerciseProblemCreateInitial: IAssessmentExerciseProblemCreateRequest = {
  file: undefined,
  json: {
    playLevel: 1,
    status: 'ACTIVE',
  },
};

type IAssessmentExerciseProblemCreateResponse = AxiosResponse<void>;

export const assessmentExerciseProblemCreateValidation: ObjectSchema<IAssessmentExerciseProblemCreateRequest> =
  object({
    file: mixed<RcFile>().required('PGN обязателен для заполнения'),
    json: object({
      status: string<ExerciseStatusEnumKeys>().required('Статус обязателен для заполнения'),
    }),
  });

export const assessmentExerciseProblemCreateRequest = async (
  payload: IAssessmentExerciseProblemCreateRequest,
): Promise<IAssessmentExerciseProblemCreateResponse> => {
  const formData = new FormData();
  formData.append('file', payload.file as Blob);
  formData.append(
    'json',
    new Blob([JSON.stringify(payload.json)], {
      type: 'application/json',
    }),
  );

  return await axios.post(`/admin/assessment-exercises/problems/upload`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export function useMutateAssessmentExerciseProblemCreate() {
  const queryClient = useQueryClient();

  return useMutation<
    IAssessmentExerciseProblemCreateResponse,
    AxiosError,
    IAssessmentExerciseProblemCreateRequest
  >(assessmentExerciseProblemCreateRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(assessmentExerciseQueryKeys.assessmentExercises);
      queryClient.invalidateQueries(exerciseGroupQueryKeys.exercisesGroups);
    },
  });
}
