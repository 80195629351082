import { FC, useState } from 'react';
import { Button, Modal } from 'antd';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';

import { boardFilters } from '@collections/boards/filters/boardFilters';
import {
  IChessPiecesSetRequest,
  useQueryChessPiecesSet,
} from '@collections/chess-pieces/api/useQueryChessPiecesSet';
import { chessPiecesColumns } from '@collections/chess-pieces/consts/columns/chessPiecesColumns';
import { useQueryChessPiecesGroup } from '@collections/chess-pieces-groups/api/useQueryChessPiecesGroup';
import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { TitleAndText } from '@components/atoms/TitleAndText';
import { AdminTable } from '@components/organisms/adminTable/AdminTable';

export const ChessPiecesGroupViewModal: FC<{
  id?: number;
  isOpen: boolean;
  onCancel: () => void;
  onOpenEdit: () => void;
}> = ({ id = 0, isOpen, onCancel, onOpenEdit }) => {
  const [filtered, setFiltered] = useState<IChessPiecesSetRequest>({
    page: 1,
    pageSize: 10,
    sortBy: 'id',
    sortDirection: 'ASC',
  });

  const chessPiecesSetsQuery = useQueryChessPiecesSet({ ...filtered, groupId: id });

  const chessPiecesGroupQuery = useQueryChessPiecesGroup({ id });
  const chessPiecesGroup = chessPiecesGroupQuery.data?.data;

  return (
    <Modal
      className="px-4 md:px-0 md:w-5/6 lg:w-4/5 pt-0 mt-0 top-8"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <div className="flex flex-row gap-4">
          <h2 className="text-2xl">Группа фигур - #{chessPiecesGroup?.localizations.ru?.name}</h2>

          <Button className="hidden md:block" icon={<EditOutlined />} onClick={onOpenEdit}>
            Редактировать
          </Button>

          <Button className="md:hidden" icon={<EditOutlined />} onClick={onOpenEdit} />
        </div>
      }
    >
      {chessPiecesGroupQuery.isLoading ? (
        <BaseSkeleton />
      ) : !chessPiecesGroup ? (
        <NotFoundResult title="Группа фигур не найдена" />
      ) : (
        <div className="space-y-8">
          <TitleAndText light text={chessPiecesGroup.id} title="ID группы" />
          <TitleAndText
            light
            text={
              <CountryImageText src="ru" text={chessPiecesGroup.localizations?.ru?.name || '-'} />
            }
            title="Название на русском"
          />
          <TitleAndText
            light
            text={
              <CountryImageText src="en" text={chessPiecesGroup.localizations?.en?.name || '-'} />
            }
            title="Название на английском"
          />{' '}
          <TitleAndText
            light
            text={moment(chessPiecesGroup.createdDate).format('LLL')}
            title="Дата создания"
          />
          <TitleAndText light text={chessPiecesGroup.itemsCount} title="Количество сетов фигур" />
          <AdminTable
            columns={chessPiecesColumns({})}
            emptyDescription={<p>Фигуры не найдены</p>}
            position={['bottomLeft']}
            query={chessPiecesSetsQuery}
            tableFilter={{ filtered, filters: boardFilters, onFilter: setFiltered }}
          />
        </div>
      )}
    </Modal>
  );
};
