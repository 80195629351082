import { FC, useState } from 'react';
import { Button, Modal, Switch as AntSwitch } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, Radio, Select, Switch } from 'formik-antd';
import { QuestionOutlined } from '@ant-design/icons';

import { FileUpload } from '@components/molecules/files/FileUpload';
import {
  exerciseTestCreateInitial,
  exerciseTestCreateValidation,
  IExerciseTestCreateRequest,
  useMutateExerciseTestCreate,
} from '@exercises/exercises/api/useMutateExerciseTestCreate';
import { QuestionAnswersForm } from '@exercises/exercises/components/molecules/QuestionAnswersForm';
import { ExerciseGamePhaseEnum } from '@exercises/exercises/types/enums/ExerciseGamePhaseEnum';
import { useQueryTags } from '@tags/api/useQueryTags';
import { IUserPlayLevelNumber, userPlayLevels } from '@users/types/enums/UserPlayLevel';
import { notify } from '@utils/notify';
import { useQueryVideos } from '@videos/api/useQueryVideos';

export const ExerciseTestCreateModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
}> = ({ isOpen, onCancel }) => {
  const testCreate = useMutateExerciseTestCreate();
  const tags = useQueryTags({ type: 'TOPIC' });
  const videos = useQueryVideos({ isCartoon: false });

  const [questionsNum, setQuestionsNum] = useState(2);
  const [imageKey, setImageKey] = useState('image');

  return (
    <Modal
      className="px-4 md:px-0 md:w-3/4 lg:w-2/3"
      footer={null}
      onCancel={() => {
        onCancel();
      }}
      open={isOpen}
      title={
        <h1 className="text-xl m-0 space-x-1">
          <QuestionOutlined className="text-ant-orange-6" />
          <span>Создать тест</span>
        </h1>
      }
    >
      <Formik<IExerciseTestCreateRequest>
        enableReinitialize
        initialValues={exerciseTestCreateInitial}
        onSubmit={(values, { resetForm }) => {
          testCreate.mutateAsync(values).then((data) => {
            if (data.status == 201 || data.status == 200) {
              notify('success', 'Тест создан');
              onCancel();
              resetForm();
              setImageKey(imageKey + 1);
            }
          });
        }}
        validationSchema={exerciseTestCreateValidation}
      >
        {({ setFieldValue, values }) => {
          return (
            <Form className="space-y-6" layout="vertical" validateTrigger="submit">
              <QuestionAnswersForm
                localization="en"
                maxQuestionsNum={4}
                nameStart="localizations.en"
                questionsNum={questionsNum}
                setQuestionsNum={setQuestionsNum}
                test={values.localizations}
                text="Вопрос на английском языке"
              />

              <QuestionAnswersForm
                localization="ru"
                maxQuestionsNum={4}
                nameStart="localizations.ru"
                questionsNum={questionsNum}
                setQuestionsNum={setQuestionsNum}
                test={values.localizations}
                text="Вопрос на русском языке"
              />

              <FormItem label="Правильный вариант" name="correctOption">
                <Radio.Group
                  name="correctOption"
                  options={Array.from(Array(questionsNum)).map((_, index) => ({
                    label: `${index + 1}-й`,
                    value: index + 1,
                  }))}
                />
              </FormItem>

              <FormItem className="upload-form-item text-center" name="imageUrl">
                <FileUpload
                  accept=".jpeg,.png,.jpg"
                  defaultFileList={
                    values.imageUrl
                      ? [
                          {
                            name: '',
                            uid: values.imageUrl,
                            url: values.imageUrl,
                          },
                        ]
                      : undefined
                  }
                  folderName="testImages"
                  key={imageKey}
                  label="Картинка"
                  maxCount={1}
                  onChange={(info, linkInfo) => {
                    if (!info.file.status || info.file.status == 'removed')
                      setFieldValue('imageUrl', '');
                    if (info.file.status == 'done') setFieldValue('imageUrl', linkInfo.downloadUrl);
                  }}
                />
              </FormItem>

              <FormItem label="Уровень" name="playLevels">
                <Select
                  mode="multiple"
                  name="playLevels"
                  optionFilterProp="label"
                  options={Object.keys(userPlayLevels)
                    .map(Number)
                    .map((playLevel) => ({
                      disabled:
                        values.playLevels.length > 0 &&
                        !values.playLevels.includes(playLevel as IUserPlayLevelNumber),
                      label: userPlayLevels[playLevel],
                      value: playLevel,
                    }))}
                  showSearch={false}
                />
              </FormItem>

              <FormItem label="Тема" name="tagIds">
                <Select
                  className="gap-y-2"
                  mode="multiple"
                  name="tagIds"
                  optionFilterProp="label"
                  options={
                    tags.data?.data.content.map((tag) => ({
                      label: tag.localizations.en,
                      value: tag.id,
                    })) || []
                  }
                />
              </FormItem>

              <FormItem label="Видеоурок" name="videoId">
                <Select
                  allowClear
                  className="gap-y-2"
                  name="videoId"
                  optionFilterProp="label"
                  options={
                    videos.data?.data.content.map((video) => ({
                      label: video.localizations.en?.fullTitle,
                      value: video.id,
                    })) || []
                  }
                  showSearch
                />
              </FormItem>

              <FormItem label="Стадия игры" name="gamePhase">
                <Select
                  allowClear
                  name="gamePhase"
                  options={Object.entries(ExerciseGamePhaseEnum).map(([value, label]) => ({
                    label,
                    value,
                  }))}
                />
              </FormItem>

              <div className="grid md:grid-cols-4 place-items-center text-center">
                <FormItem
                  label={<span className="text-center">Капитализация преимущества</span>}
                  name="isCapitalization"
                >
                  <Switch name="isCapitalization" />
                </FormItem>

                <FormItem label={<span className="text-center">Тактика</span>} name="isTactic">
                  <Switch name="isTactic" />
                </FormItem>

                <FormItem
                  label={<span className="text-center">Пешечная структура</span>}
                  name="isPawnStructure"
                >
                  <Switch name="isPawnStructure" />
                </FormItem>

                <Form.Item
                  label={<span className="text-center">Запустить немедленно</span>}
                  name="status"
                >
                  <Input hidden name="status" suffix />

                  <AntSwitch
                    checked={values.status == 'ACTIVE'}
                    onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
                  />
                </Form.Item>
              </div>

              <div className="grid md:grid-cols-2 gap-5">
                <Button block onClick={onCancel}>
                  Отменить
                </Button>

                <Button block htmlType="submit" loading={testCreate.isLoading} type="primary">
                  <span className="max-w-full overflow-ellipsis overflow-hidden">
                    {values['status'] == 'ACTIVE'
                      ? 'Создать и запустить'
                      : 'Сохранить как черновик'}
                  </span>
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
