import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { ILecturerCard } from '../types/ILecturerCard';
import { lecturerCardCommonKeys } from './lecturerCardKeys';

type ILecturerCardRequest = { id: number };
type ILecturerCardResponse = AxiosResponse<ILecturerCard>;

export const lecturerCardGetRequest = async ({
  id,
}: ILecturerCardRequest): Promise<ILecturerCardResponse> =>
  await axios.get(`/admin/author-cards/${id}`);

export function useQueryLecturerCard(params: ILecturerCardRequest) {
  return useQuery<ILecturerCardResponse, AxiosError>(
    [lecturerCardCommonKeys.lecturerCard, params],
    () => lecturerCardGetRequest(params),
    {
      enabled: params.id > 0,
    },
  );
}
