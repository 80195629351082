import { useQuery } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

import { IChessPiecesSet } from '@collections/chess-pieces/types/IChessPiecesSet';

import { chessPiecesQueryKeys } from './chessPiecesQueryKeys';

type IChessPiecesResponse = AxiosResponse<IChessPiecesSet>;

export const chessPiecesRequest = async (id: string): Promise<IChessPiecesResponse> => {
  return await axios.get(`/chess-piece-sets/${id}`);
};

export function useQueryChessPiecesSetById(id: string) {
  return useQuery<IChessPiecesResponse, AxiosError>(
    [chessPiecesQueryKeys.chessPiecesById, id],
    () => chessPiecesRequest(id),
  );
}
