import { FC } from 'react';

import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { PageMeta } from '@context/PageMetaContext';
import { useMutateExerciseGroupDelete } from '@exercises/exerciseGroups/api/useMutateExerciseGroupDelete';
import { useMutateExerciseGroupStatusUpdate } from '@exercises/exerciseGroups/api/useMutateExerciseGroupStatusUpdate';
import {
  IExerciseGroupsRequest,
  useQueryExerciseGroups,
} from '@exercises/exerciseGroups/api/useQueryExerciseGroups';
import { ExerciseGroupEditModal } from '@exercises/exerciseGroups/components/organisms/ExerciseGroupEditModal';
import { ExerciseGroupViewModal } from '@exercises/exerciseGroups/components/organisms/ExerciseGroupViewModal';
import { exerciseGroupColumns } from '@exercises/exerciseGroups/consts/columns/exerciseGroupColumns';
import { exerciseGroupsFilters } from '@exercises/exerciseGroups/consts/filters/exerciseGroupsFilters';
import {
  exerciseGroupsPageActions,
  IExerciseGroupsPageActions,
} from '@exercises/exerciseGroups/consts/pageActions/IExerciseGroupsPageActions';
import { ExerciseTabs } from '@exercises/exercises/components/molecules/ExerciseTabs';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { exercisePermissions } from '@typess/permissions/IExercisePermission';
import { checkEditPermission } from '@utils/checkEditPermission';

export const ExerciseGroupsPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IExerciseGroupsRequest,
    IExerciseGroupsPageActions
  >(exerciseGroupsFilters, exerciseGroupsPageActions);

  const exerciseGroups = useQueryExerciseGroups(filtered);

  const statusUpdate = useMutateExerciseGroupStatusUpdate();
  const groupDelete = useMutateExerciseGroupDelete();

  const canEditExercises = checkEditPermission(exercisePermissions);

  return (
    <>
      <PageMeta
        selectedMenuKeys={['exercises']}
        subTitle={
          actioned.action == 'edit'
            ? 'Изменить группу'
            : actioned.action == 'view'
            ? `Группа упражнений #${actioned.actionId}`
            : ''
        }
        title="Промокоды"
      />

      <ExerciseTabs active="groups" />

      <AdminTable
        columns={exerciseGroupColumns({
          canEdit: canEditExercises,
          onClickDelete: groupDelete.mutateAsync,
          onClickEdit: ({ id }) => setActioned({ action: 'edit', actionId: id }),
          onClickPause: statusUpdate.mutateAsync,
          onClickView: ({ id }) => setActioned({ action: 'view', actionId: id }),
        })}
        emptyDescription={<p>Группы упражнений не найдены</p>}
        query={exerciseGroups}
        tableFilter={{ filtered, filters: exerciseGroupsFilters, onFilter }}
      />

      <ExerciseGroupViewModal
        canEditExercises={canEditExercises}
        id={actioned.actionId}
        isOpen={actioned.action == 'view'}
        onCancel={clearParams}
        onCreateExercise={() =>
          setActioned({ action: 'createExercise', actionId: actioned.actionId })
        }
        onOpenEdit={() => setActioned({ action: 'edit', actionId: actioned.actionId })}
      />

      <ExerciseGroupEditModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
        onSubmit={() => setActioned({ action: 'view', actionId: actioned.actionId })}
      />
    </>
  );
};
