export const usersAgeStatsColumns = ({ totalUsers }: { totalUsers?: number }) => {
  return [
    {
      dataIndex: 'ageRange',
      fixed: true,
      title: '',
      width: '100px',
    },
    {
      dataIndex: 'totalNumber',
      title: 'Total',
      width: '100px',
    },
    {
      render: (row) => {
        return totalUsers ? ((row.totalNumber / totalUsers) * 100).toFixed(2) + '%' : '-';
      },
      title: 'Share',
      width: '100px',
    },
    {
      dataIndex: 'withEmailNumber',
      title: 'Users with mail',
      width: '150px',
    },
    {
      render: (row) =>
        totalUsers ? ((row.withEmailNumber / totalUsers) * 100).toFixed(2) + '%' : '-',
      title: '% emails',
      width: '100px',
    },
    {
      dataIndex: 'regularNumber',
      title: 'Regular',
      width: '100px',
    },
    {
      dataIndex: 'subscribedNumber',
      title: 'Subscribed',
      width: '100px',
    },
    {
      dataIndex: 'sysRate1Number',
      title: 'Sys rate < 500',
      width: '150px',
    },
    {
      render: (row) =>
        row.sysRate1Number ? ((row.sysRate1Number / row.totalNumber) * 100).toFixed(2) + '%' : '-',
      width: '100px',
    },
    {
      dataIndex: 'sysRate2Number',
      title: 'Sys rate 500-999',
      width: '150px',
    },
    {
      render: (row) =>
        row.sysRate2Number ? ((row.sysRate2Number / row.totalNumber) * 100).toFixed(2) + '%' : '-',
      width: '100px',
    },
    {
      dataIndex: 'sysRate3Number',
      title: 'Sys rate 1000-1499',
      width: '150px',
    },
    {
      render: (row) =>
        row.sysRate3Number ? ((row.sysRate3Number / row.totalNumber) * 100).toFixed(2) + '%' : '-',
      width: '100px',
    },
    {
      dataIndex: 'sysRate4Number',
      title: 'Sys rate > 1500',
      width: '150px',
    },
    {
      render: (row) =>
        row.sysRate4Number ? ((row.sysRate4Number / row.totalNumber) * 100).toFixed(2) + '%' : '-',
      width: '100px',
    },
    {
      dataIndex: 'enLangNumber',
      title: 'En',
      width: '100px',
    },
    {
      render: (row) =>
        row.enLangNumber ? ((row.enLangNumber / row.totalNumber) * 100).toFixed(2) + '%' : '-',
      width: '100px',
    },
    {
      dataIndex: 'ruLangNumber',
      title: 'Ru',
      width: '100px',
    },
    {
      render: (row) =>
        row.ruLangNumber ? ((row.ruLangNumber / row.totalNumber) * 100).toFixed(2) + '%' : '-',
      width: '100px',
    },
    {
      dataIndex: 'mapStage1PassedNumber',
      title: 'Прошли хотябы 1 урок',
      width: '200px',
    },
    {
      render: (row) =>
        row.mapStage1PassedNumber
          ? ((row.mapStage1PassedNumber / row.totalNumber) * 100).toFixed(2) + '%'
          : '-',
      width: '100px',
    },
    {
      dataIndex: 'mapStage10PassedNumber',
      title: 'Прошли > 10 уроков',
      width: '200px',
    },
    {
      render: (row) =>
        row.mapStage10PassedNumber
          ? ((row.mapStage10PassedNumber / row.totalNumber) * 100).toFixed(2) + '%'
          : '-',
      width: '100px',
    },
  ];
};
