import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, number, object, ObjectSchema, string } from 'yup';

import { exerciseQueryKeys } from '@exercises/exercises/api/exerciseQueryKeys';
import { ExerciseStatusEnumKeys } from '@exercises/exercises/types/enums/ExerciseStatusEnum';

import { IAssessmentExerciseTest } from '../types/IAssessmentExerciseTest';

export type IAssessmentExerciseTestCreateRequest = Omit<
  IAssessmentExerciseTest,
  'id' | 'createdBy' | 'playLevel' | 'priority'
>;

type IAssessmentExerciseTestCreateResponse = AxiosResponse<void>;

export const assessmentExerciseTestCreateInitial: IAssessmentExerciseTestCreateRequest = {
  correctOption: 1,
  imageUrl: '',
  localizations: {
    en: {
      answerOptions: ['', ''],
      question: '',
    },
    ru: {
      answerOptions: ['', ''],
      question: '',
    },
  },
  status: 'ACTIVE',
};

export const assessmentExerciseTestCreateValidation: ObjectSchema<IAssessmentExerciseTestCreateRequest> =
  object({
    correctOption: number().required(),
    imageUrl: string().required(),
    localizations: object({
      en: object({
        answerOptions: array()
          .required()
          .of(string().required('Ответ обязателен для заполнения'))
          .max(100, 'Ответ должен быть не более 60 символов'),
        question: string()
          .required('Вопрос обязателен для заполнения')
          .max(250, 'Вопрос должен быть не более 200 символов'),
      }),
      ru: object({
        answerOptions: array()
          .required()
          .of(string().required('Ответ обязателен для заполнения'))
          .max(100, 'Ответ должен быть не более 60 символов'),
        question: string()
          .required('Вопрос обязателен для заполнения')
          .max(250, 'Вопрос должен быть не более 200 символов'),
      }),
    }),
    status: string<ExerciseStatusEnumKeys>().required('Статус обязателен для заполнения'),
  });

export const assessmentExerciseTestCreateRequest = async (
  payload: IAssessmentExerciseTestCreateRequest,
): Promise<IAssessmentExerciseTestCreateResponse> =>
  await axios.post('/admin/assessment-exercises/test', payload);

export function useMutateAssessmentExerciseTestCreate() {
  const queryClient = useQueryClient();

  return useMutation<
    IAssessmentExerciseTestCreateResponse,
    AxiosError,
    IAssessmentExerciseTestCreateRequest
  >(assessmentExerciseTestCreateRequest, {
    onSuccess: () => queryClient.invalidateQueries(exerciseQueryKeys.exercises),
  });
}
