import { FC } from 'react';
import { Button, Modal, Result } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input } from 'formik-antd';
import { SearchOutlined } from '@ant-design/icons';

import BaseSkeleton from '@components/atoms/BaseSkeleton';
import {
  IStaffEditRequest,
  staffEditValidation,
  useMutateStaffEdit,
} from '@staff/api/useMutateStaffEdit';
import { useQueryStaff } from '@staff/api/useQueryStaff';
import { StaffAuthoritySelects } from '@staff/components/molecules/StaffAuthoritySelects';
import { staffFindAuthority } from '@staff/utils/staffFindAuthority';
import { dashboardsPermission } from '@typess/permissions/IAnalitycsPermission';
import { exercisePermissions } from '@typess/permissions/IExercisePermission';
import { mapStagePermissions } from '@typess/permissions/IMapStagePermission';
import { settingPermissions } from '@typess/permissions/ISettingPermission';
import { shopPermissions } from '@typess/permissions/IShopPermission';
import { staffPermissions } from '@typess/permissions/IStaffPermission';
import { tagPermissions } from '@typess/permissions/ITagPermission';
import { userPermissions } from '@typess/permissions/IUserPermission';
import { videoPermissions } from '@typess/permissions/IVideoPermission';

type IStaffEditModal = {
  id?: number;
  isOpen: boolean;
  onCancel: () => void;
};

export const StaffEditModal: FC<IStaffEditModal> = ({ id = 0, isOpen, onCancel }) => {
  const staffEdit = useMutateStaffEdit();

  const staffQuery = useQueryStaff({ id });
  const staff = staffQuery.data;

  return (
    <Modal
      className="px-4 md:px-0 w-full md:w-3/4 lg:w-2/3 top-8"
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={<h1 className="text-xl m-0">Профиль персонала</h1>}
    >
      {staffQuery.isLoading ? (
        <BaseSkeleton />
      ) : !staff?.id ? (
        <Result icon={<SearchOutlined />} title="Персонал не найден" />
      ) : (
        <Formik<IStaffEditRequest>
          enableReinitialize
          initialValues={{
            authorities: [
              staffFindAuthority(userPermissions, staff.authorities),
              staffFindAuthority(staffPermissions, staff.authorities),
              staffFindAuthority(videoPermissions, staff.authorities),
              staffFindAuthority(exercisePermissions, staff.authorities),
              staffFindAuthority(tagPermissions, staff.authorities),
              staffFindAuthority(shopPermissions, staff.authorities),
              staffFindAuthority(settingPermissions, staff.authorities),
              staffFindAuthority(dashboardsPermission, staff.authorities),
              staffFindAuthority(mapStagePermissions, staff.authorities),
            ],
            id,
          }}
          onSubmit={(values) => staffEdit.mutateAsync(values).then(onCancel)}
          validationSchema={staffEditValidation}
        >
          {({ values }) => {
            return (
              <Form className="space-y-8" layout="vertical">
                <FormItem label="ФИО" name="disabled1">
                  <Input disabled name="disabled1" placeholder="ФИО" value={staff.fullName} />
                </FormItem>
                <FormItem label="Почта" name="disabled2">
                  <Input
                    disabled
                    name="disabled2"
                    placeholder="example@example.com"
                    type="email"
                    value={staff.email}
                  />
                </FormItem>

                <StaffAuthoritySelects />

                <div className="grid md:grid-cols-2 gap-5">
                  <Button block onClick={onCancel}>
                    Отменить
                  </Button>

                  <Button
                    disabled={
                      !values.authorities.filter((authority) => authority && authority != 'block')
                        .length
                    }
                    htmlType="submit"
                    loading={staffEdit.isLoading}
                    type="primary"
                  >
                    Сохранить изменения
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
};
