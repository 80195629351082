import { IPermission } from '@typess/permissions/IPermission';
import { IPermissions } from '@typess/permissions/IPermissions';

export function staffFindAuthority(
  permissions: IPermissions,
  authorities?: IPermission[],
): IPermission | 'block' {
  const authority = (authorities || []).find((permission) =>
    [permissions.view, permissions.restricted, permissions.edit].includes(permission),
  );

  return authority || 'block';
}
