import { Link } from 'react-router-dom';
import { Button, Popconfirm, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  CaretRightOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  QuestionOutlined,
} from '@ant-design/icons';

import { FaChessKnightIcon, TbVideoIcon, VscPlayIcon } from '@components/atoms/icons';
import { PencilIcon } from '@components/atoms/icons/PencilIcon';
import {
  ExerciseStatusEnum,
  ExerciseStatusEnumKeys,
} from '@exercises/exercises/types/enums/ExerciseStatusEnum';
import { ExerciseTypeEnum } from '@exercises/exercises/types/enums/ExerciseTypeEnum';
import { exerciseKeys, IExercise } from '@exercises/exercises/types/IExercise';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';

export const exerciseColumns = ({
  canEdit,
  onClickDelete,
  onClickEdit,
  onClickPause,
  onClickView,
}: {
  canEdit: boolean;
  onClickDelete?: ({ id }: { id: number }) => void;
  onClickEdit?: ({ id }: { id: number }) => void;
  onClickPause?: ({ id, status }: { id: number; status: ExerciseStatusEnumKeys }) => void;
  onClickView?: ({ id }: { id: number }) => void;
}): ColumnsType<IExercise> => {
  const columns: ColumnsType<IExercise> = [
    {
      dataIndex: exerciseKeys.id,
      render: (id: IExercise['id']) =>
        onClickView ? (
          <Button onClick={() => onClickView({ id })} type="link">
            {id}
          </Button>
        ) : (
          id
        ),
      title: 'ID',
    },
    {
      dataIndex: exerciseKeys.tags,
      render: (tags: IExercise['tags'], exercise: IExercise) => (
        <div className="space-y-2.5">
          {tags.map((tag) => (
            <p className="mb-0 first-letter:capitalize" key={`tag${tag.id}`}>
              {exercise.type == 'CUSTOM_PROBLEM' ? '-' : tag.localizations.en}
            </p>
          ))}
        </div>
      ),
      title: 'Тема',
    },
    {
      dataIndex: exerciseKeys.type,
      render: (type: IExercise['type']) => (
        <p className={`flex items-center gap-1 mb-0 ${type == 'TEST' ? 'text-ant-orange-6' : ''}`}>
          {type == 'TEST' ? (
            <QuestionOutlined />
          ) : type == 'CUSTOM_PROBLEM' ? (
            ''
          ) : (
            <FaChessKnightIcon />
          )}
          {ExerciseTypeEnum[type]}
        </p>
      ),
      title: 'Тип',
    },
    {
      dataIndex: exerciseKeys.video,
      render: (video: IExercise['video']) =>
        video ? (
          <Link
            className={`flex items-center gap-1 text-center ${
              video.isCartoon ? 'text-ant-magenta-6' : 'text-ant-purple-6'
            }`}
            to={`/videos?action=view&actionId=${video.id}`}
          >
            {video.isCartoon ? (
              <VscPlayIcon className="min-w-fit" />
            ) : (
              <TbVideoIcon className="min-w-fit" />
            )}
            {video.localizations.en?.fullTitle}
          </Link>
        ) : (
          '-'
        ),
      title: 'Видео',
    },
    {
      dataIndex: exerciseKeys.playLevels,
      render: (playLevels: IExercise['playLevels']) =>
        playLevels.map((playLevel) => (
          <p className="mb-0.5" key={playLevel}>
            {userPlayLevels[playLevel]}
          </p>
        )),
      title: 'Уровень',
    },
    {
      dataIndex: exerciseKeys.solvedUsersPercentage,
      render: (solvedUsersPercentage: IExercise['solvedUsersPercentage'], exercise: IExercise) =>
        exercise.type == 'CUSTOM_PROBLEM' ? '-' : solvedUsersPercentage,
      title: () => <Tooltip title="Точность решения">Точность</Tooltip>,
    },
    {
      dataIndex: exerciseKeys.solvedUsersCount,
      render: (solvedUsersCount: IExercise['solvedUsersPercentage'], exercise: IExercise) =>
        exercise.type == 'CUSTOM_PROBLEM' ? '-' : solvedUsersCount,
      title: () => <Tooltip title="Количество решивших пользователей">Пользователей</Tooltip>,
    },
    {
      dataIndex: exerciseKeys.problemChecked,
      render: (checked: IExercise['problemChecked'], exercise: IExercise) =>
        checked || exercise.type == 'TEST' ? (
          'Проверено'
        ) : (
          <span className="text-ant-red-4">Не проверено</span>
        ),
      title: 'Проверено',
    },
    {
      dataIndex: exerciseKeys.useForPuzzleRush,
      render: (useForPuzzleRush: IExercise['useForPuzzleRush'], exercise: IExercise) =>
        exercise.type == 'CUSTOM_PROBLEM' ? '-' : useForPuzzleRush ? 'Да' : 'Нет',
      title: 'Мозговой штурм',
    },
    {
      dataIndex: exerciseKeys.status,
      render: (status: IExercise['status']) => ExerciseStatusEnum[status],
      title: 'Статус',
    },
  ];

  if (!canEdit || (!onClickDelete && !onClickEdit && !onClickDelete)) {
    return columns;
  }

  return [
    ...columns,
    {
      key: 'action',
      render: (exercise: IExercise) => (
        <div className="flex gap-x-1.5">
          {onClickPause && (
            <Popconfirm
              cancelText="Отменить"
              icon={
                exercise.status == 'ACTIVE' ? (
                  <PauseCircleOutlined className="text-ant-blue-5 text-base" />
                ) : (
                  <PlayCircleOutlined className="text-ant-blue-5 text-base" />
                )
              }
              okText={exercise.status == 'ACTIVE' ? 'Приостановить' : 'Запустить'}
              okType="primary"
              onConfirm={() =>
                onClickPause({
                  id: exercise.id,
                  status: exercise.status == 'ACTIVE' ? 'PAUSED' : 'ACTIVE',
                })
              }
              placement="topLeft"
              title={
                <p className="text-sm mb-0">
                  Вы уверены, что хотите
                  <span className="font-semibold">
                    {exercise.status == 'ACTIVE' ? ' приостановить ' : ' запустить '}
                    {exercise.type == 'TEST' ? 'тест' : 'упражнение'} #{exercise.id}?
                  </span>
                </p>
              }
            >
              <Button
                ghost
                icon={exercise.status == 'ACTIVE' ? <PauseOutlined /> : <CaretRightOutlined />}
                type="primary"
              />
            </Popconfirm>
          )}
          {onClickEdit && (
            <Button
              ghost
              icon={<PencilIcon />}
              onClick={() => onClickEdit({ id: exercise.id })}
              type="primary"
            />
          )}
          {onClickDelete && (
            <Popconfirm
              cancelText="Отменить"
              icon={<DeleteOutlined className="text-ant-red-5" />}
              okText="Удалить"
              okType="danger"
              onConfirm={() => onClickDelete({ id: exercise.id })}
              placement="topLeft"
              title={
                <span className="text-sm">
                  Вы уверены, что хотите
                  <span className="font-bold">
                    {' '}
                    удалить {exercise.type == 'TEST' ? 'тест' : 'упражнение'} #{exercise.id}
                  </span>
                  ?
                </span>
              }
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </div>
      ),
      title: 'Действия',
    },
  ];
};
