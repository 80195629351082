import { ReactNode, useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { Empty, Skeleton, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosError, AxiosResponse } from 'axios';
import Icon from '@ant-design/icons';

import { ChessKnightIcon } from '@components/atoms/icons';
import { TableHeader } from '@components/organisms/adminTableSelfManaged/organismComponents/TableHeader';
import { IExerciseGroup } from '@exercises/exerciseGroups/types/IExerciseGroup';
import { IExercise } from '@exercises/exercises/types/IExercise';
import { IAppSetting } from '@settings/appSettings/types/IAppSetting';
import { IPreSubscriber } from '@settings/preSubscribers/types/IPreSubscriber';
import { IPromocode } from '@settings/promocodes/types/IPromocode';
import { IPromocodeGroup } from '@settings/promocodes/types/IPromocodeGroup';
import { IAppProduct } from '@shop/appProducts/types/IAppProduct';
import { IAppPromotion } from '@shop/appPromotions/types/IAppPromotion';
import { IStaff } from '@staff/types/IStaff';
import { TableFilter } from '@typess/IFilters';
import { IPageAbleResponse } from '@typess/IPageAbleResponse';
import { IUser } from '@users/types/IUser';
import { IUserBrainstorm } from '@users/types/IUserBrainstorm';
import { IUserCoinPurchase } from '@users/types/IUserCoinPurchase';
import { IUserGameMatch } from '@users/types/IUserGameMatch';
import { IUserMapStage } from '@users/types/IUserMapStage';
import { IVideo } from '@videos/types/IVideo';
import { IVideoFile } from '@videos/types/IVideoFile';

import { TableSizeChanger } from './organismComponents/TableSizeChanger';

type IAdminTableContent =
  | IAppProduct
  | IAppPromotion
  | IAppSetting
  | IExercise
  | IExerciseGroup
  | IStaff
  | IPreSubscriber
  | IPromocodeGroup
  | IPromocode
  | IVideo
  | IVideoFile
  | IUser
  | IUserMapStage
  | IUserCoinPurchase
  | IUserGameMatch
  | IUserBrainstorm;

type AdminTableType<T extends IAdminTableContent> = {
  columns: ColumnsType<T>;
  emptyDescription?: ReactNode;
  filters?: TableFilter['filters'];
  position?: ('topLeft' | 'bottomLeft')[];
  query: (params) => UseQueryResult<AxiosResponse<IPageAbleResponse<T>>, AxiosError>;
  queryData?: object;
  rowClassName?: (record: T, row: number) => string;
  rowKey?: keyof T;
};

export const AdminTableSelfManaged = <T extends IAdminTableContent>({
  columns,
  emptyDescription = 'Нет данных',
  filters,
  position,
  query,
  queryData,
  rowClassName,
  rowKey,
}: AdminTableType<T>) => {
  const [filtered, setFiltered] = useState({ page: 1, pageSize: 10 });
  const data = query({ ...queryData, ...filtered });

  const pageAble = data.data?.data;
  const [total, setTotal] = useState(pageAble?.totalElements || 1);

  const onFilter = (keys: object) => {
    setFiltered((prevState) => ({
      ...prevState,
      ...keys,
    }));
  };

  useEffect(() => {
    if (!data.isLoading) {
      setTotal(pageAble?.totalElements || 1);
      if ((filtered.page || 1) > (pageAble?.totalPages || 1)) {
        onFilter({ page: pageAble?.totalPages || 1 });
      }
    }
  }, [data]);

  return (
    <Table
      className="table-custom-options"
      columns={columns}
      components={
        filters && {
          header: {
            row: (props) => (
              <TableHeader
                props={props}
                tableFilterProps={{ filtered, filters: filters, onFilter }}
              />
            ),
          },
        }
      }
      dataSource={pageAble?.content}
      loading={{
        indicator: (
          <Icon className="text-xl" component={(props) => <ChessKnightIcon {...props} />} spin />
        ),
        spinning: data.isLoading,
      }}
      locale={{
        emptyText: data.isLoading ? (
          <div className="p-2">
            <Skeleton active round />
            <Skeleton active round />
          </div>
        ) : (
          <Empty description={emptyDescription} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
      }}
      onChange={(pagination) => {
        onFilter({
          page: pagination.current || 1,
          pageSize: pagination.pageSize || 10,
        });
      }}
      pagination={{
        current: typeof filtered.page == 'string' ? parseInt(filtered.page) : filtered.page,
        defaultCurrent: 1,
        hideOnSinglePage: false,
        pageSize: filtered.pageSize,
        position: position || ['topLeft', 'bottomLeft'],
        responsive: true,
        selectComponentClass: TableSizeChanger,
        showSizeChanger: true,
        total,
      }}
      rowClassName={rowClassName}
      rowKey={rowKey?.toString() ?? 'id'}
      scroll={{ x: true }}
      showSorterTooltip={false}
    />
  );
};
