import { FC } from 'react';
import { Dropdown } from 'antd';
import { MailOutlined } from '@ant-design/icons';

import { useMutateLogout } from '@auth/api/useMutateLogout';
import { useQueryMe } from '@auth/api/useQueryMe';
import { UserIcon } from '@components/atoms/icons';
import { LogoutIcon } from '@components/atoms/icons/LogoutIcon';

export const LogoutDropdown: FC = () => {
  const logout = useMutateLogout();
  const meQuery = useQueryMe();

  return (
    <>
      <div className="logout-dropdown">
        <Dropdown
          className="px-3 hover:text-white"
          menu={{
            items: [
              {
                className: 'pointer-events-none rounded-b-none pb-2 border-b border-b-gray mb-1',
                icon: <MailOutlined />,
                key: 'email',
                label: meQuery.data?.email,
              },
              {
                icon: <LogoutIcon className="text-xs" />,
                key: 'profile',
                label: 'Выход',
                onClick: () => logout.mutateAsync(),
              },
            ],
          }}
          placement="bottom"
        >
          <UserIcon />
        </Dropdown>
      </div>
      <div className="profile-box grid border-t border-t-gray-200 w-full">
        <span className="inline">
          <UserIcon className="text-sm pr-1" />
          {meQuery.data?.email}
        </span>
        <span className="inline profile-box-logout">
          <LogoutIcon className="text-xs pr-1" onClick={() => logout.mutateAsync()} />
          Выход
        </span>
      </div>
    </>
  );
};
