import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

export type BreadcrumbItemType = {
  link?: string;
  title: string;
};

/**
 * The PageMeta provides developers with tools to configure the state of
 * the navigation menu, breadcrumbs and title.
 */
export type PageMetaType = {
  /**
   * Set breadcrumbs in the layout of the page
   */
  breadcrumbs?: BreadcrumbItemType[];
  /**
   * Open submenu parent to display highlighted child items
   */
  openMenuKeys?: string[];

  /**
   * Highlight menu item with active state.
   */
  selectedMenuKeys?: string[];

  /**
   * Set before title of the page
   */
  subTitle?: string;

  /**
   * Set title of the page
   */
  title?: string;
};

export type PageMetaContextType = PageMetaType & {
  setBreadcrumbs: (breadcrumbs?: BreadcrumbItemType[]) => void;
  setOpenMenuKeys: (keys?: string[]) => void;
  setSelectedMenuKeys: (keys?: string[]) => void;
};

/**
 * This is a AuthContext object for PageMeta.
 * SHOULD NOT BE USED WITHOUT PageMetaContextProvider component
 */
export const PageMetaContext = createContext<PageMetaContextType>({
  breadcrumbs: [],
  openMenuKeys: [],
  selectedMenuKeys: [],

  setBreadcrumbs: () => {
    // init
  },
  setOpenMenuKeys: () => {
    // init
  },
  setSelectedMenuKeys: () => {
    // init
  },
});

/**
 * The provider wraps the context to inject the reducer into
 * the context consumers
 */
export const PageMetaContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [selectedMenuKeys, setSelectedMenuKeys] = useState<string[] | undefined>([]);
  const [openMenuKeys, setOpenMenuKeys] = useState<string[] | undefined>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItemType[] | undefined>([]);

  return (
    <PageMetaContext.Provider
      value={{
        breadcrumbs,
        openMenuKeys,
        selectedMenuKeys,
        setBreadcrumbs,
        setOpenMenuKeys,
        setSelectedMenuKeys,
      }}
    >
      {children}
    </PageMetaContext.Provider>
  );
};

/**
 * Use this component to define the PageMeta in the PageMetaContext in declarative way
 * @param props
 * @returns null. not rendered in the component
 */
export const PageMeta: FC<PageMetaType> = ({
  breadcrumbs,
  openMenuKeys,
  selectedMenuKeys,
  subTitle,
  title,
}) => {
  const pageMetaContext = useContext(PageMetaContext);

  useEffect(() => {
    pageMetaContext.setOpenMenuKeys(openMenuKeys);
  }, [openMenuKeys]);

  useEffect(() => {
    pageMetaContext.setSelectedMenuKeys(selectedMenuKeys);
  }, [selectedMenuKeys]);

  useEffect(() => {
    pageMetaContext.setBreadcrumbs(breadcrumbs);
  }, [breadcrumbs]);

  return (
    <Helmet>
      <title>
        {title && subTitle ? `${subTitle} — ${title} — ` : title ? `${title} — ` : ''} Chess Legends
      </title>
    </Helmet>
  );
};
