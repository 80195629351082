import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum UserPageActionEnum {
  'viewBrainstorm',
}

export type UserPageActions = {
  action: keyof typeof UserPageActionEnum;
  actionId: number;
};

export const userPageActions: IPageActions<UserPageActions> = {
  action: {
    allowed: (value) => Object.keys(UserPageActionEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
};
