import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema } from 'yup';

import { notify } from '@utils/notify';

import { chessPiecesQueryKeys } from './chessPiecesQueryKeys';
import {
  createChessPiecesSetValidation,
  IChessPiecesSetCreateRequest,
} from './useMutateChessPiecesSetCreate';

export type IChessPiecesSetUpdateRequest = IChessPiecesSetCreateRequest & {
  id: number;
};

export const updateChessPiecesSetValidation: ObjectSchema<IChessPiecesSetUpdateRequest> =
  createChessPiecesSetValidation.concat(
    object({
      id: number().required(),
    }),
  );

export const createChessPiecesSetRequest = ({ id, ...payload }: IChessPiecesSetUpdateRequest) =>
  axios.patch(`/chess-piece-sets/${id}`, payload);

export const useMutateChessPiecesSetUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, IChessPiecesSetUpdateRequest>(
    createChessPiecesSetRequest,
    {
      onError: () => {
        notify('error', 'Повторите попытку позже');
      },
      onSuccess: () => {
        notify('success', 'Коллекция фигур успешно обновлена');
        queryClient.invalidateQueries(chessPiecesQueryKeys.chessPieces);
      },
    },
  );
};
