import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum LecturerCardPageActionEnum {
  'create',
  'edit',
  'view',
}

export interface ILecturerCardsPageActions {
  action: keyof typeof LecturerCardPageActionEnum;
  actionId: number;
}

export const lecturerCardPageActions: IPageActions<ILecturerCardsPageActions> = {
  action: {
    allowed: (value) => Object.keys(LecturerCardPageActionEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
};
