import { FC, useState } from 'react';
import { Modal } from 'antd';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';

import { TitleAndText } from '@components/atoms/TitleAndText';
import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { useMutatePromocodeDelete } from '@settings/promocodes/api/useMutatePromocodeDelete';
import { useQueryPromocodeGroup } from '@settings/promocodes/api/useQueryPromocodeGroup';
import { IPromocodeGroupsRequest } from '@settings/promocodes/api/useQueryPromocodeGroups';
import { useQueryPromocodes } from '@settings/promocodes/api/useQueryPromocodes';
import { promocodesColumns } from '@settings/promocodes/consts/columns/promocodesColumns';
import { promocodesFilters } from '@settings/promocodes/consts/filters/promocodesFilters';

export const PromocodeGroupModal: FC<{
  canEditSettings: boolean;
  id?: number;
  isOpen: boolean;
  onCancel: () => void;
}> = ({ canEditSettings, id = 0, isOpen, onCancel }) => {
  const [filtered, setFiltered] = useState<IPromocodeGroupsRequest>({
    page: 1,
    pageSize: 10,
    sortBy: 'id',
    sortDirection: 'ASC',
  });

  const promocodeGroupQuery = useQueryPromocodeGroup({ id });
  const promocodeGroup = promocodeGroupQuery.data?.data;

  const promocodes = useQueryPromocodes({ ...filtered, promocodeGroupId: id });
  const promocodeDelete = useMutatePromocodeDelete();

  return (
    <Modal
      className="px-4 md:px-0 md:w-5/6 lg:w-4/5 pt-0 mt-0 top-8"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={<h1 className="text-2xl">Группа промокодов - #{id}</h1>}
    >
      {promocodeGroup ? (
        <div className="space-y-8">
          <TitleAndText light text={promocodeGroup.id} title="ID группы" />
          <TitleAndText light text={promocodeGroup.groupName} title="Название группы" />

          <TitleAndText
            light
            text={
              promocodeGroup.expireDate ? (
                <span
                  className={moment(promocodeGroup.expireDate) > moment() ? '' : 'text-ant-gray-6'}
                >
                  <CalendarOutlined /> {moment(promocodeGroup.expireDate).format('LL')}
                </span>
              ) : (
                <span>-</span>
              )
            }
            title="Дата окончания"
          />
          <TitleAndText
            light
            text={promocodeGroup.promocodeUsedCount}
            title="Использовано промокодов"
          />
          <TitleAndText light text={promocodeGroup.promocodeCount} title="Количество промокодов" />
          <TitleAndText
            light
            text={promocodeGroup.freeUseMonths}
            title="Количество бесплатных месяцев"
          />
          <AdminTable
            columns={promocodesColumns({
              canEdit: canEditSettings,
              onClickDelete: (id) => promocodeDelete.mutateAsync({ id }),
            })}
            emptyDescription={<p>Промокоды не найдены</p>}
            position={['bottomLeft']}
            query={promocodes}
            tableFilter={{ filtered, filters: promocodesFilters, onFilter: setFiltered }}
          />
        </div>
      ) : (
        <div />
      )}
    </Modal>
  );
};
