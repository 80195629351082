import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'antd';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';

import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { TitleAndText } from '@components/atoms/TitleAndText';
import { AdminTable } from '@components/organisms/adminTable/AdminTable';
import { useQueryExerciseGroup } from '@exercises/exerciseGroups/api/useQueryExerciseGroup';
import { useMutateExerciseDelete } from '@exercises/exercises/api/useMutateExerciseDelete';
import { IExerciseProblemCreateRequest } from '@exercises/exercises/api/useMutateExerciseProblemCreate';
import { useMutateExerciseStatusUpdate } from '@exercises/exercises/api/useMutateExerciseStatusUpdate';
import { IExercisesRequest, useQueryExercises } from '@exercises/exercises/api/useQueryExercises';
import { ExerciseProblemCreateModal } from '@exercises/exercises/components/organisms/ExerciseProblemCreateModal';
import { exerciseColumns } from '@exercises/exercises/consts/columns/exerciseColumns';
import { exerciseFilters } from '@exercises/exercises/consts/filters/exerciseFilters';

export const ExerciseGroupViewModal: FC<{
  canEditExercises: boolean;
  id?: number;
  isOpen: boolean;
  onCancel: () => void;
  onCreateExercise: () => void;
  onOpenEdit: () => void;
}> = ({ canEditExercises, id = 0, isOpen, onCancel, onOpenEdit }) => {
  const [filtered, setFiltered] = useState<IExercisesRequest>({
    page: 1,
    pageSize: 10,
    sortBy: 'id',
    sortDirection: 'ASC',
  });

  const [createExerciseModalOpen, setCreateExerciseModalOpen] = useState(false);

  const navigate = useNavigate();

  const exerciseGroupQuery = useQueryExerciseGroup({ id });
  const exerciseGroup = exerciseGroupQuery.data?.data;

  const exercises = useQueryExercises({ ...filtered, groupId: id });

  const exercisePause = useMutateExerciseStatusUpdate();
  const exerciseDelete = useMutateExerciseDelete();

  const exerciseInitialValue: IExerciseProblemCreateRequest = {
    file: undefined,
    json: {
      checked: false,
      gamePhase: 'MIDDLEGAME',
      groupName: exerciseGroup?.groupName || '',
      isCapitalization: false,
      isPawnStructure: false,
      isTactic: false,
      playLevels: exercises.data?.data.content?.[0]?.playLevels ?? [],
      status: 'ACTIVE',
      useForPuzzleRush: true,
      videoId: exercises.data?.data?.content[0]?.video?.id,
    },
  };

  return (
    <Modal
      className="px-4 md:px-0 md:w-5/6 lg:w-4/5 pt-0 mt-0 top-8"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <div className="flex flex-row gap-4">
          <h2 className="text-2xl">Группа упражнений - #{id}</h2>

          <Button className="hidden md:block" icon={<EditOutlined />} onClick={onOpenEdit}>
            Редактировать
          </Button>

          <Button className="md:hidden" icon={<EditOutlined />} onClick={onOpenEdit} />
        </div>
      }
    >
      {exerciseGroupQuery.isLoading ? (
        <BaseSkeleton />
      ) : !exerciseGroup ? (
        <NotFoundResult title="Группа упражнений не найдена" />
      ) : (
        <div className="space-y-8">
          <TitleAndText light text={exerciseGroup.id} title="ID группы" />
          <TitleAndText light text={exerciseGroup.groupName} title="Название группы" />
          <TitleAndText
            light
            text={moment(exerciseGroup.createdDate).format('LLL')}
            title="Дата создания"
          />
          <TitleAndText light text={exerciseGroup.exercisesCount} title="Количество упражнений" />
          <div className="flex justify-end gap-3">
            <Button
              className="md:block"
              onClick={() => setCreateExerciseModalOpen(true)}
              type="primary"
            >
              Добавить упражнение
            </Button>
          </div>
          <AdminTable
            columns={exerciseColumns({
              canEdit: canEditExercises,
              onClickDelete: exerciseDelete.mutateAsync,
              onClickEdit: ({ id }) => navigate(`/exercises?action=edit&actionId=${id}`),
              onClickPause: exercisePause.mutateAsync,
              onClickView: ({ id }) => navigate(`/exercises?action=view&actionId=${id}`),
            })}
            emptyDescription={<p>Промокоды не найдены</p>}
            position={['bottomLeft']}
            query={exercises}
            tableFilter={{ filtered, filters: exerciseFilters, onFilter: setFiltered }}
          />
          <ExerciseProblemCreateModal
            initialValue={exerciseInitialValue}
            isGroupNameDisabled={true}
            isOpen={createExerciseModalOpen}
            onCancel={() => setCreateExerciseModalOpen(false)}
          />
        </div>
      )}
    </Modal>
  );
};
