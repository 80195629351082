import { isInteger } from 'formik';

import { IPageActions } from '@typess/IPageActions';

enum AssessmentExercisesPageActionEnum {
  'createTest',
  'createProblem',
  'edit',
  'view',
}

export type AssessmentExercisesPageActions = {
  action: keyof typeof AssessmentExercisesPageActionEnum;
  actionId: number;
};

export const assessmentExercisesPageActions: IPageActions<AssessmentExercisesPageActions> = {
  action: {
    allowed: (value) => Object.keys(AssessmentExercisesPageActionEnum).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
};
