import { FC, useState } from 'react';
import { Button, Radio, Switch as AntSwitch } from 'antd';
import { Formik } from 'formik';
import { Form, FormItem, Input, InputNumber, Select } from 'formik-antd';

import { useQueryExercises } from '@exercises/exercises/api/useQueryExercises';
import { useQueryFens } from '@exercises/fens/api/useQueryFens';
import {
  mapStageEditValidation,
  useMutateMapStageEdit,
} from '@exercises/map-stages/api/useMutateMapStageEdit';
import { sortedMapStages } from '@exercises/map-stages/types/enums/MapStageType';
import { IMapStage } from '@exercises/map-stages/types/IMapStage';
import { userPlayLevels } from '@users/types/enums/UserPlayLevel';
import { notify } from '@utils/notify';
import { useQueryVideos } from '@videos/api/useQueryVideos';

export const MapStageEditFormik: FC<{
  onCancel: () => void;
  stage: IMapStage;
}> = ({ onCancel, stage }) => {
  const stageEdit = useMutateMapStageEdit();
  const videos = useQueryVideos({ isCartoon: false });
  const [exerciseSearchQuery, setExerciseSearchQuery] = useState('');

  const [type, setType] = useState(stage.type);
  const fens = useQueryFens();

  const exercises = useQueryExercises({
    id: exerciseSearchQuery,
    page: 1,
    pageSize: 10,
    sortBy: 'id',
    sortDirection: 'ASC',
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        exerciseIds: stage.exercises?.map((ex) => ex.id),
        fenRecordId: stage.fenRecord?.id,
        id: stage.id,
        playLevels: [stage.playLevel],
        stageNumber: stage.stageNumber,
        status: stage.status,
        type: stage.type,
        videoId: stage.video?.id,
      }}
      onSubmit={(values, { resetForm }) => {
        stageEdit.mutateAsync({ ...values, type }).then((data) => {
          if (data.status == 201 || data.status == 200) {
            notify('success', 'Шаг на карте изменен');
            onCancel();
            resetForm();
          }
        });
      }}
      validationSchema={mapStageEditValidation}
    >
      {({ setFieldValue, values }) => (
        <Form className="space-y-5" layout="vertical">
          <FormItem label="Шаг на карте" name="stageNumber">
            <InputNumber
              className="w-full"
              controls={false}
              disabled
              min={1}
              name="stageNumber"
              placeholder="Введите шаг"
            />
          </FormItem>
          <FormItem label="Уровень" name="playLevels">
            <Select
              disabled
              name="playLevels"
              optionFilterProp="label"
              options={Object.keys(userPlayLevels)
                .map(Number)
                .map((playLevel) => ({
                  label: userPlayLevels[playLevel],
                  value: playLevel,
                }))}
              showSearch={false}
            />
          </FormItem>
          <FormItem label="Тип уровня" name="type">
            <Radio.Group
              defaultValue={type}
              onChange={(e) => {
                setType(e?.target.value);
                setFieldValue(
                  'exerciseIds',
                  stage.exercises?.map((ex) => ex.id),
                );
                setFieldValue('fenRecordId', stage.fenRecord?.id);
                setFieldValue('videoId', stage.video?.id);
              }}
              options={sortedMapStages.map((stage) => ({
                label: stage?.name ?? '',
                value: stage?.value ?? '',
              }))}
              value={type}
            />
          </FormItem>

          {type === 'VIDEO' && (
            <FormItem label="Видеоурок" name="videoId">
              <Select
                allowClear
                className="gap-y-2"
                name="videoId"
                optionFilterProp="label"
                options={
                  videos.data?.data.content.map((video) => ({
                    label: video.localizations.en?.fullTitle,
                    value: video.id,
                  })) || []
                }
                placeholder="Видео"
                showSearch
              />
            </FormItem>
          )}

          {type === 'EXERCISE' && (
            <FormItem label="Задача" name="exerciseIds">
              <Select
                allowClear
                className="gap-y-2"
                loading={exercises.isFetching}
                mode="multiple"
                name="exerciseIds"
                onSearch={(value) => setExerciseSearchQuery(value)}
                optionFilterProp="label"
                options={
                  exercises.data?.data.content.map((exercise) => ({
                    label: exercise.id,
                    value: exercise.id,
                  })) || []
                }
                placeholder="Задача"
                showSearch
              />
            </FormItem>
          )}
          {type === 'GAME_MATCH' && (
            <FormItem label="FEN" name="fenRecordId">
              <Select
                allowClear
                className="gap-y-2"
                name="fenRecordId"
                optionFilterProp="label"
                options={
                  fens.data?.data.content.map((fen) => ({
                    label: fen.id,
                    value: fen.id,
                  })) || []
                }
                placeholder="FEN"
                showSearch
              />
            </FormItem>
          )}
          <div className="grid grid-cols-2 place-items-start text-start">
            <Form.Item
              label={<span className="text-center">Запустить немедленно</span>}
              name="status"
            >
              <Input hidden name="status" suffix />
              <AntSwitch
                checked={values.status == 'ACTIVE'}
                onChange={(checked) => setFieldValue('status', checked ? 'ACTIVE' : 'DRAFT')}
              />
            </Form.Item>
          </div>

          <div className="grid md:grid-cols-2 gap-5">
            <Button block onClick={onCancel}>
              Отменить
            </Button>

            <Button block htmlType="submit" loading={stageEdit.isLoading} type="primary">
              <span className="max-w-full overflow-ellipsis overflow-hidden">
                {values['status'] == 'ACTIVE' ? 'Изменить и запустить' : 'Сохранить как черновик'}
              </span>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
