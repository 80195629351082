import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { array, boolean, number, object, ObjectSchema, string } from 'yup';

import { ExerciseGamePhaseEnumKeys } from '@exercises/exercises/types/enums/ExerciseGamePhaseEnum';
import { IUserPlayLevelNumber } from '@users/types/enums/UserPlayLevel';
import { videoQueryKeys } from '@videos/api/videoQueryKeys';
import { VideoStatusEnumKeys } from '@videos/types/enums/VideoStatusEnum';
import { IVideo } from '@videos/types/IVideo';

export type IVideoStepTwoRequest = Pick<
  IVideo,
  'price' | 'id' | 'playLevel' | 'isCapitalization' | 'isTactic' | 'gamePhase' | 'sequence'
> & {
  status?: VideoStatusEnumKeys;
  tagIds: number[];
};

type IVideoStepTwoResponse = AxiosResponse<IVideo>;

export const videoStepTwoValidation: ObjectSchema<IVideoStepTwoRequest> = object({
  gamePhase: string<ExerciseGamePhaseEnumKeys>().nullable(),
  id: number().nullable().required(),
  isCapitalization: boolean().nullable().required(),
  isTactic: boolean().nullable().required(),
  playLevel: number<IUserPlayLevelNumber>()
    .nullable()
    .required('Уровень обязателен для заполнения'),
  price: number().nullable().required('Цена обязательна для заполнения'),
  sequence: number().nullable(),
  status: string<VideoStatusEnumKeys>().nullable().required(),
  tagIds: array()
    .min(1, 'Видео должно содержать минимум 1 тему')
    .of(number().required())
    .required(),
});

export const videoStepTwoRequest = async (
  payload: IVideoStepTwoRequest,
): Promise<IVideoStepTwoResponse> =>
  await axios.patch<IVideo>('videos/2', {
    ...payload,
    tags: payload.tagIds?.map((id) => ({ id: id })),
  });

export function useMutateVideoStepTwo() {
  const queryClient = useQueryClient();

  return useMutation<IVideoStepTwoResponse, AxiosError, IVideoStepTwoRequest>(videoStepTwoRequest, {
    onSuccess: (data, variables) => {
      queryClient.refetchQueries(videoQueryKeys.videos);
      queryClient.refetchQueries([videoQueryKeys.video, { id: variables.id }]);
      queryClient.refetchQueries([videoQueryKeys.video, { id: variables.id.toString() }]);
    },
  });
}
