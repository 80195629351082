import { FC, useState } from 'react';
import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { FilterRender } from '@typess/IFilters';

interface InputFilterInterface {
  filterRender?: FilterRender;
  placeholder?: string;
}

export const InputFilter: FC<InputFilterInterface> = ({ filterRender, placeholder }) => {
  if (!filterRender) {
    return null;
  }

  const { dataIndex, filtered, onFilter, queries } = filterRender;

  const key = queries?.[0]?.key || dataIndex;
  const [value, setValue] = useState(filtered[key]);

  const onInputSubmit = (clear = false) => {
    setValue(value.trim());
    onFilter({ [key]: clear ? undefined : value.trim() });
  };

  return (
    <div className="p-2 space-y-3 bg-white shadow-filter max-w-tiny">
      <Input
        autoFocus
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onPressEnter={() => onInputSubmit()}
        placeholder={placeholder}
        value={value}
      />
      <div className="grid gap-1 grid-cols-2">
        <Button
          className="w-full"
          disabled={!value || value == filtered[key]}
          icon={<SearchOutlined />}
          onClick={() => onInputSubmit()}
          size="small"
          type="primary"
        >
          Поиск
        </Button>
        <Button className="w-full" onClick={() => onInputSubmit(true)} size="small">
          Очистить
        </Button>
      </div>
    </div>
  );
};
