import { FC, useMemo, useState } from 'react';
import { Button, Modal } from 'antd';
import { AxiosResponse } from 'axios';
import { EditOutlined } from '@ant-design/icons';

import { useQueryBoardGroup } from '@collections/board-groups/api/useQueryBoardGroup';
import { useQueryBoards } from '@collections/boards/api/useQueryBoards';
import { IBoardsRequest } from '@collections/boards/api/useQueryBoards';
import { boardColumns } from '@collections/boards/consts/columns/boardColumns';
import { boardFilters } from '@collections/boards/filters/boardFilters';
import BaseSkeleton from '@components/atoms/BaseSkeleton';
import { CountryImageText } from '@components/atoms/CountryImageText';
import { NotFoundResult } from '@components/atoms/NotFoundResult';
import { TitleAndText } from '@components/atoms/TitleAndText';
import { AdminTable } from '@components/organisms/adminTable/AdminTable';

import { BoardGroupAddItemModal } from './BoardGroupAddItemModal';

export const BoardGroupViewModal: FC<{
  id?: number;
  isOpen: boolean;
  onCancel: () => void;
  onItemDelete: ({
    chessBoardId,
    id,
  }: {
    chessBoardId: number;
    id: number;
  }) => Promise<AxiosResponse>;
  onOpenEdit: () => void;
}> = ({ id = 0, isOpen, onCancel, onOpenEdit }) => {
  const [filtered, setFiltered] = useState<IBoardsRequest>({
    page: 1,
    pageSize: 10,
    sortBy: 'id',
    sortDirection: 'ASC',
  });

  const boardsQuery = useQueryBoards({ ...filtered, groupId: id });
  const allBoardsQuery = useQueryBoards();
  const filteredBoards = useMemo(
    () =>
      allBoardsQuery.data?.data.content.filter(
        (item) => !boardsQuery.data?.data.content.some((refItem) => refItem.id === item.id),
      ),
    [allBoardsQuery, boardsQuery],
  );

  const [addBoardModalOpen, setAddBoardModalOpen] = useState(false);

  const boardGroupQuery = useQueryBoardGroup({ id });
  const boardGroup = boardGroupQuery.data?.data;

  return (
    <Modal
      className="px-4 md:px-0 md:w-5/6 lg:w-4/5 pt-0 mt-0 top-8"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
      open={isOpen}
      title={
        <div className="flex flex-row gap-4">
          <h2 className="text-2xl">Группа досок - #{id}</h2>

          <Button className="hidden md:block" icon={<EditOutlined />} onClick={onOpenEdit}>
            Редактировать
          </Button>

          <Button className="md:hidden" icon={<EditOutlined />} onClick={onOpenEdit} />
        </div>
      }
    >
      {boardGroupQuery.isLoading ? (
        <BaseSkeleton />
      ) : !boardGroup ? (
        <NotFoundResult title="Группа досок не найдена" />
      ) : (
        <div className="space-y-8">
          <TitleAndText light text={boardGroup.id} title="ID группы" />
          <TitleAndText
            light
            text={<CountryImageText src="ru" text={boardGroup.localizations?.ru?.name || '-'} />}
            title="Название на русском"
          />
          <TitleAndText
            light
            text={<CountryImageText src="en" text={boardGroup.localizations?.en?.name || '-'} />}
            title="Название на английском"
          />{' '}
          {/*<TitleAndText*/}
          {/*  light*/}
          {/*  text={moment(boardGroup.createdDate).format('LLL')}*/}
          {/*  title="Дата создания"*/}
          {/*/>*/}
          <TitleAndText light text={boardGroup.itemsCount} title="Количество досок" />
          <AdminTable
            columns={boardColumns({})}
            emptyDescription={<p>Доски не найдены</p>}
            position={['bottomLeft']}
            query={boardsQuery}
            tableFilter={{ filtered, filters: boardFilters, onFilter: setFiltered }}
          />
          <BoardGroupAddItemModal
            boardGroupId={id}
            boardGroupName={boardGroup.localizations.ru?.name ?? ''}
            boards={filteredBoards ?? []}
            isGroupNameDisabled={true}
            isLoading={allBoardsQuery.isFetching}
            isOpen={addBoardModalOpen}
            onClose={() => setAddBoardModalOpen(false)}
          />
        </div>
      )}
    </Modal>
  );
};
