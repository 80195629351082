import { IPageActions } from '@typess/IPageActions';

import { AppSettingNameEnum, AppSettingsNameEnumKeys } from '../../types/enums/AppSettingNameEnum';

enum AppSettingsActionEnum {
  'edit',
}

export type AppSettingsPageActions = {
  action: keyof typeof AppSettingsActionEnum;
  actionName?: AppSettingsNameEnumKeys;
};

export const appSettingsPageActions: IPageActions<AppSettingsPageActions> = {
  action: {
    allowed: (value) => Object.keys(AppSettingsActionEnum).includes(value),
    default: '',
  },
  actionName: { allowed: (value) => Object.keys(AppSettingNameEnum).includes(value) },
};
