import { Navigate } from 'react-router-dom';
import { Skeleton } from 'antd';

import { useQueryMe } from '@auth/api/useQueryMe';
import { checkPermission } from '@utils/checkPermission';

import { navbars } from '../../../consts/navbars';

const IndexRoute = () => {
  const me = useQueryMe();

  const navbar = navbars.find((navbar) => {
    return checkPermission(me.data?.authorities, navbar.permissions);
  });

  if (navbar?.link) return <Navigate to={navbar.link} />;

  if (me.isFetching) {
    return (
      <div className="bg-white p-10 md:p-15 mt-5 rounded-2xl">
        <Skeleton active round />
        <Skeleton active round />
        <Skeleton active round />
      </div>
    );
  }

  return <div />;
};

export default IndexRoute;
