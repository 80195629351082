import { AppProductStatusEnumKeys } from '@shop/appProducts/types/enums/AppProductStatusEnum';

export type IAppProduct = {
  appStoreId: string;
  coins: number;
  createdOnAppStore: boolean;
  createdOnGooglePlay: boolean;
  googlePlayId: string;
  id: number;
  price: number;
  status: AppProductStatusEnumKeys;
};

export const appProductKeys: Record<keyof IAppProduct, string> = {
  appStoreId: 'appStoreId',
  coins: 'coins',
  createdOnAppStore: 'createdOnAppStore',
  createdOnGooglePlay: 'createdOnGooglePlay',
  googlePlayId: 'googlePlayId',
  id: 'id',
  price: 'price',
  status: 'status',
};
