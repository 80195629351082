import { RadioFilter } from '@components/molecules/filters/static/RadioFilter';
import { paginationBaseFilters } from '@consts/paginationBaseFilters';
import {
  VersionsForcedEnum,
  VersionsPlatformEnum,
} from '@settings/versions/types/enums/VersionsPlatformEnum';
import { versionKeys } from '@settings/versions/types/IVersion';
import { IFilters } from '@typess/IFilters';

export const versionsFilters: IFilters = {
  ...paginationBaseFilters,

  [versionKeys.platform]: {
    input: (
      <RadioFilter
        options={Object.entries(VersionsPlatformEnum).map(([value, label]) => ({
          label,
          value,
        }))}
      />
    ),
    sort: true,
  },
  [versionKeys.createdDate]: { sort: true },
  [versionKeys.isForced]: {
    input: (
      <RadioFilter
        options={Object.entries(VersionsForcedEnum).map(([label]) => ({
          label,
          value: label === VersionsForcedEnum.REQUIRED,
        }))}
      />
    ),
    sort: true,
  },
};
